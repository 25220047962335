<template>
  <div class="lefttreeBox">
    <div class="lefttreeBoxtop">
      <div class="discipline">
        <p class="disciplineTitle">{{ mainlefttree.title }}</p>
        <div class="handleBtn">
          <el-button class='topBtn' ref='allBtn' @click='handleALl' size="mini">全选</el-button>
          <el-button class='topBtn' @click='handleCheckClear' size="mini">清除</el-button>
        </div>
      </div>
      <div style="width:100%;overflow-y:scroll;" id="myElement">
        <el-tree :render-after-expand='false' highlight-current class="treeBox" :data="treedata" show-checkbox
          node-key="id" ref="tree" :props="defaultProps" @check-change="handleCheckChange"
          :default-expanded-keys="secondLevelList" :default-checked-keys="secondLevelList">
          <span class="custom-tree-node ischecked" slot-scope="{node,data}">
            <span v-if='data.parentCode === null' ref='treeNodeLable' :class='{ "ischecked": data.checked }'
              class='treeNode' :title='data.label + "(" + data.count + "种)"'>{{
                data.label
              }}</span>
            <span v-else ref='treeNodeLable' :class='{ "ischecked": data.checked }' class='childNode'
              :title='data.label + "(" + data.count + "种)"'>{{
                data.label
              }}</span>
            <span v-if='data.parentCode === null' :title='data.count' :class='{ "ischecked": data.checked }'
              class="treecount">({{ data.count }}种)</span>
            <span v-else :title='data.count' :class='{ "ischecked": data.checked }' class="childNode">({{ data.count
              }}种)</span>
          </span>
        </el-tree>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  props: ['mainlefttree'],
  data () {
    return {
      clear: false,
      ischecked: false,
      content: '',
      subjectCount: 0,
      checkAllCount: 0,
      isLoading: false,
      checkAll: false,
      firstselectedTree: [], // 左侧选中的一级学科code列表
      hotwordsearch: '', // 热搜词
      orgCode: '', // 机构code
      page: 1, // 页码
      pageRow: 10, // 每页显示的条目数
      total: 0,
      period: '', // 期刊期次
      startTime: '',
      endTime: '',
      pubYear: [],
      resultContent: '', // 结果搜索内容
      searchContent: '', // 检索内容
      secondLevelList: [], // 左侧选中的二级学科code列表
      tabIndex: 0, // tab切换 0-发表年份 1-被引频次 2-分享量 3-评论量 4-收藏量 5-下载量 6-点击量
      type: 0, // 0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅
      isExpand: false,
      selectedKeys: [],
      treedata: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      secondSubject: [],
      firstSubject: [],
      categoryList: []
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    // 全选按钮
    handleALl () {
      // this.ischecked = true
      // this.checkAll = true
      this.checkAllCount = 0
      this.handleCheckAll()
    },
    handleCheckAll () {
      this.$refs.tree.setCheckedNodes(this.treedata)
    },
    // 取消全选
    handleCheckClear () {
      this.ischecked = false
      this.clear = true
      this.checkAll = true
      this.checkAllCount = 0
      this.$refs.tree.setCheckedKeys([])
      this.isExpand = false
      for (let i = 0; i < this.treedata.length; i++) {
        this.$refs.tree.store.nodesMap[this.treedata[i].id].expanded = this.isExpand
      }
    },
    // 点击遴选
    handleselectlx () {
      const routeData = this.$router.resolve({
        path: '/selectlx'
      })
      window.open(routeData.href, '_blank')

      // location.reload()
    },
    handleCheckChange (data, checked) {
      if (this.checkAll === false) {
        this.ischecked = checked
        data.checked = checked
        // 选中值包含父级
        const includeparent = this.$refs.tree.getCheckedNodes(false, true)
        // 选中值不包含父元素==全部选中二级
        const nocludeparent = this.$refs.tree.getCheckedNodes(true)
        // 需要将所有选中的二级值的id放到数组中
        this.secondLevelList = nocludeparent.map(item => item.id)
        // 存值
        // 将nameListpush到secondSubject中
        const arr = nocludeparent.map(item => item.nameList)
        this.secondSubject = arr.flat()

        /**
         *这里写是因为当首次点击清除,并且选择零篇期刊的学科后,他的存值都会一样,导致无法触发监听,接口不会请求,
         所以给他赋值一个学科id,这样当选择期刊为零篇的时候,会存id,这样就不会和清除按钮冲突,就不会有无法触发监听到情况
         并且也没有纯数字命名的期刊
         */
        if (this.secondSubject.length === 0) {
          this.secondSubject = this.secondLevelList
        }

        // console.log('hhhh', arr.flat())
        // 然后存起来

        this.firstLevelList = nocludeparent.map(item => {
          return item.id
        })

        // 选中值只有父元素
        const parentArrar = includeparent.filter(
          obj => !nocludeparent.includes(obj)
        )
        this.firstLevelList = parentArrar.map(item => {
          return item.id
        })
        // 存值
        this.$store.commit('setJournalSecond', this.secondLevelList)
        this.$store.commit('setJournalFirst', this.firstLevelList)
        this.$store.commit('setJourNalSubjectList', this.secondSubject)
        // this.getDatatree()
      } else {
        data.checked = checked
        // 全选
        this.checkAllCount++
        if (this.checkAllCount === 1) {
          this.subjectCount = 0
          this.firstLevelList = []
          this.secondLevelList = []
          this.secondSubject = []
          this.treedata.forEach(item => {
            // this.firstLevelList.push(item.id)
            this.subjectCount++
            if (item.children) {
              if (item.children.length > 0) {
                for (let i = 0; i < item.children.length; i++) {
                  // this.secondLevelList.push(item.children[i].id)
                  this.subjectCount++
                }
              }
            }
          })
          // 存值
          this.$store.commit('setJournalSecond', this.secondLevelList)
          this.$store.commit('setJournalFirst', this.firstLevelList)
          this.$store.commit('setJourNalSubjectList', this.secondSubject)
          // this.checkAllCount = 0
          this.checkAll = false
        }
        if (this.checkAllCount === this.subjectCount) {
          this.checkAll = false
        }
      }
    },
    // sendIsChenk (data) {
    //   this.$emit('sendIsCheck', data)
    // },
    // sendIsChenkAll (data) {
    //   this.$emit('sendIsCheckAll', data)
    // },
    // 获取详情接口
    async getDatatree () {
      const { data: res } = await this.$http({
        url: this.mainlefttree.url,
        method: 'post',
        data: {
          content: this.content,
          resultContent: this.resultContent, // 搜索结果内容
          categoryList: this.categoryList
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.treedata = res.data
      this.$emit('nameList', this.treedata, this.firstLevelList, this.secondLevelList)
      const element = document.querySelector('#myElement')
      const x = res.data.length
      var height = 39 * x
      element.style.maxHeight = height + 'px'
    }

  },
  watch: {
    // 监听热词
    '$store.state.hotwordsearch.search': {
      deep: true,
      immediate: true,
      handler (data, oldData) {
        // if (oldData !== undefined && data !== null && oldData !== null) {
        this.content = data
        this.page = 1
        this.pageRow = 10

        const journalSearchEl = document.querySelector('.journalSearchBox')
        if (journalSearchEl) { this.resultContent = journalSearchEl.children[0].value }
        this.getDatatree()
        // }
      }
    },
    '$store.state.categoryList': {
      deep: true,
      // immediate: true,
      handler (data, oldData) {
        this.categoryList = data
        this.page = 1
        this.pageRow = 10
        this.getDatatree()
      }
    },
    // ischecked: {
    //   handler (data) {
    //     console.log('传递')
    //     this.sendIsChenk(data)
    //   }
    // },
    // checkAll: {
    //   handler (data) {
    //     console.log('传递')
    //     this.sendIsChenkAll(data)
    //   }
    // },
    // '$store.state.journalSecond': {
    //   deep: true,
    //   immediate: true,
    //   handler (data, oldData) {
    //     console.log(data, oldData)

    //     // console.log('二级学科', data)
    //   }
    // },
    // '$store.state.journalFirst': {
    //   deep: true,
    //   // immediate: true,
    //   handler (data) {
    //     this.firstselectedTree = data
    //     // console.log('watch', this.firstselectedTree)
    //   }
    // },
    // 监听多个年和搜索结果g
    '$store.state.journalLittleSearch': {
      deep: true,
      // immediate: true, // 首次
      handler (data) {
        // this.pubYear = data.pubYear
        this.resultContent = data
        this.getDatatree()
      }
    }
  }
}
</script>

<style scoped lang="less">
// /deep/.el-scrollbar__thumb {
//   background-color: #E6E6E6 !important;
//   border-radius: 3px !important;
// }

// /deep/.el-scrollbar__thumb {
//   width: 6px !important;
//   height: 50% !important;
// }
// .el-scrollbar__wrap {
//   overflow-x: hidden;
// }
// .el-scrollbar__bar.is-vertical {
//   width: 6px;
//   height: 80px;
//   background: #E6E6E6;
//   border-radius: 3px;
//   margin-right: 8px;
// }

.custom-tree-node {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.lefttreeBox {
  width: 300px;
  height: 100%;
  // height: 770px;

  .lefttreeBoxtop {
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;

    .discipline {
      display: flex;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
      width: 100%;
      height: 60px;
      background: #f5f5f5;
      border-radius: 10px 10px 0 0;
      justify-content: space-between;

      .handleBtn {
        .topBtn {
          background-color: transparent;
          margin-left: 0;
          margin-right: 5px;
          width: 37px;
          height: 21px;
          background: #F5F5F5;
          border: 1px solid #CCCCCC;
          border-radius: 4px;
          text-align: center;
          // font-size: 12px;
          padding: 0;

          /deep/span {
            font-size: 12px;
          }
        }
      }

      @media screen and (min-width: 1600px) {
        .disciplineTitle {
          font-size: 22px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // 1300~1600

      @media screen and (max-width: 1600px) and (min-width: 1300px) {
        .disciplineTitle {
          font-size: 20px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // 小于1300
      @media screen and (max-width: 1300px) {
        .disciplineTitle {
          font-size: 18px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // .selectAllBox {
      //   .selectAllBtn,
      //   .selectemptyBtn {
      //     text-align: center;
      //     width: 48px;
      //     height: 24px;
      //     background: #f5f5f5;
      //     border: 1px solid #e0e0e0;
      //     border-radius: 4px;
      //     padding: 0;
      //     > span {
      //       color: #666666;
      //     }
      //   }
      //   .selectemptyBtn {
      //   }
      // }
    }

    /deep/.el-tree {
      width: 100%;
      background: #ffffff;
      border-radius: 0 0 10px 10px;
      padding: 18px 20px 0 20px;
      box-sizing: border-box;
    }

    .treecount {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #666;
    }
  }

  .selectzb {
    width: 300px;
    cursor: pointer;
  }

  // .selectzb {
  //   cursor: pointer;
  //   text-align: center;
  //   width: 5.2083vw;
  //   height: 2.0833vw;
  //   line-height: 2.0833vw;
  //   background: #9c2526;
  //   border-radius: .2083vw;

  //   .selectzbIcon {
  //     display: inline-block;
  //     // background: url("../../assets/images/home/");
  //   }

  //   .selectzbTxt {
  //     font-size: .7292vw;
  //     color: #ffffff;
  //   }
  // }
}

/deep/.el-tree-node__content {
  margin-bottom: 15px;
  height: 14px;
}

//一级节点选择器
/deep/ .el-tree>.el-tree-node>.el-tree-node__content {
  margin-bottom: 20px;
  height: 17px;

}

//二级节点选择器
/deep/ .el-tree>.el-tree-node>.el-tree-node__children>.el-tree-node>.el-tree-node__content {
  margin-bottom: 15px;
  padding-left: 8px !important;
  // color: #666666;
}

// /deep/ .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
//   // 设置颜色
//   background-color: orange !important;
// }
// /deep/ .el-tree-node:focus>.el-tree-node__content {
//   color: #9c2526 !important;
// }
// .el-tree-node.is-current>.el-tree-node__content {
//   background-color: red !important;
// }
.treeNode {
  font-size: 18px;
  font-weight: 500;
  color: #1A1A1A;
}

.childNode {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  // margin: 0;
}

.treeNode.ischecked {
  color: #9c2526;
}

.childNode.ischecked {
  color: #9c2526;
}

.treecount.ischecked {
  color: #9c2526;
}

.el-icon-document {
  color: #fff;
}
</style>
