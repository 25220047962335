<template>
  <div class="mianhome">
    <div class="contenttop">
      <div class="swipersearchBox">
        <headswiper></headswiper>
        <headsearch></headsearch>
      </div>
      <headmenu></headmenu>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import headsearch from '../components/head/search.vue'
import headmenu from '../components/head/headmenu.vue'
import headswiper from '../components/head/headswiper.vue'

export default {
  components: {
    headsearch,
    headmenu,
    headswiper
  }
}
</script>
<style lang="less" scoped>
.mianhome {
  .contenttop {
    .swipersearchBox {
      position: relative;
    }
  }

  @media screen and (min-width: 1600px) {
    .maincontent {
      width: 1440px;
      margin: 0 auto;
    }
  }

  // 1300~1600
  @media screen and (max-width: 1600px) and (min-width: 1300px) {
    .maincontent {
      width: 1200px;
      margin: 0 auto;
    }
  }

  // 小于1300
  @media screen and (max-width: 1300px) {
    .maincontent {
      width: 1200px;
      margin: 0 auto;
    }
  }
}
</style>
