<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
export default {
  mounted () {
    if (this.$route.name !== 'looking') {
      console.log('===')
    }
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement('script')
      hm.src = 'https://hm.baidu.com/hm.js?7ebe4ea6e87116531590d39b49324014'
      var s = document.getElementsByTagName('script')[0]

      s.parentNode.insertBefore(hm, s)
    })()
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

body {
  -webkit-text-size-adjust: 100% !important;
}

li {
  list-style: none;
}

.el-popover.user-popover {
  background-color: #9c2526;
  color: #fff !important;
  border: none;
  min-width: 160px;
  max-width: 260px;
  max-height: 260px;
  min-height: 160px !important;
  overflow: auto;
  border-radius: 10px;
  z-index: 999 !important;
}

.el-popover.user-popover[x-placement^="top"] .popper__arrow {
  /*el-popover上边三角表层的颜色---也就是视觉上的角*/
  border-top-color: #9c2526;
}

.el-popover.user-popover[x-placement^="top"] .popper__arrow::after {
  /*el-popover上边三角表层的颜色---也就是视觉上的角*/
  border-top-color: #9c2526;
}
</style>
