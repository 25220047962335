<template>
  <div>
    <div>
      <signagureHead></signagureHead>
    </div>
    <div class="top">
      <div class="title">隐私协议</div>
      <img src="../../assets/images/home/intimg.png" class="topimg" />
    </div>
    <div class="contain" style='position: relative;'>
      <div class="goback">
        <el-button @click='$router.go(-1)'
          style='background-color: #9c2526; position: absolute;left: 0;top: 0; color: #fff;'>返回</el-button>
      </div>
      <div class="data">
        <div>更新日期：2024年【 2 】月【 18 】日</div>
        <div>生效日期：2024年【 2 】月【 18 】日</div>
      </div>
      <div class="box">
        <div class="boxtitle">引言</div>
        <div class="boxtitle" style="margin-top: 41px;">欢迎您使用&nbsp;<span>哲学社会科学主文献平台（简称“哲社主文献”）。</span></div>
        <p class="text" style="margin-top: 41px;">
          哲社主文献是由中国人民大学建设，该平台为中国人民大学中国哲学社会科学自主知识体系数字哲社主文献平台（简称“学术世界”）的子平台，并由中国人民大学书报资料中心承建运营，集中展现中国哲学社会科学各学科最优秀的哲社主文献资源并提供知识服务（注册地址：北京市海淀区中关村大街59号）。哲社主文献平台通过网站（https://zszwx.cn/）为您提供服务。
          <br />
          <br />
        </p>
        <p class="text">
          我们非常重视保护您的个人信息和隐私。您可以通过《中国哲学社会科学自主知识体系数字哲社主文献平台隐私政策》（以下简称“本隐私政策”）了解我们收集、使用、存储用户个人信息的情况，以及您所享有的相关权利。在您开始使用哲社主文献平台前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以
          <strong>粗体/粗体下划线</strong> 标识的条款，确保您充分理解和同意之后再开始使用。
          <strong>请知悉，如拒绝提供非必要个人信息或附加功能所需信息时，您仍然能够使用哲社主文献平台基本功能。</strong>
          <br />
          <br />
        </p>
        <p class="text" style="text-indent: 0;">
          <strong>本政策将帮助您了解以下内容：</strong>
          <br />
        <p class="tl"><strong>一．我们如何收集和使用您的个人信息</strong></p>
        <p class="tl"><strong>二．我们如何委托处理、转让、公开披露您的个人信息</strong></p>
        <p class="tl"><strong>三．我们如何使用Cookie和同类技术</strong></p>
        <p class="tl"><strong>四．我们如何存储您的个人信息</strong></p>
        <p class="tl"><strong>五．我们如何保护您的个人信息</strong></p>
        <p class="tl"><strong>六．您如何管理您的个人信息</strong></p>
        <p class="tl"><strong>七．未成年人信息收集与使用</strong></p>
        <p class="tl"><strong>八．我们对去世用户的个人信息保护</strong></p>
        <p class="tl"><strong>九．本政策如何更新</strong></p>
        <p class="tl"><strong>十．如何联系我们</strong></p>
        </p>
        <p class="text">
          如您有任何疑问、建议或意见，请通过以下方式与我们联系：<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;电子邮件：【zszwx@ruc.edu.cn】<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;电话：【010-62519375】
        </p>
        <div class="title" style="margin-top: 49px;">一. 我们如何收集和使用您的个人信息</div>
        <p class="indent" style="margin-top: 36px;">
          您在使用哲社主文献平台提供的服务时，为了更好地实现您的目的，我们会根据合法正当、必要、透明的原则，基于本政策所述的目的，收集和使用您的个人信息。
        </p>
        <p class="indent">1. 当您注册、登录平台账户时，我们需要收集您的姓名、手机号、验证码、笔名（自愿填写）。如您拒绝提供手机号码、验证码、姓名，您将无法注册登录。
        </p>
        <p class="indent">2. 当您认领文献时，我们会收集您的真实姓名、作者单位、认领文献信息，以核对具体信息的准确性。</p>
        <p class="indent">3. 当您授权文献时，我们会收集您的姓名、手机号、和身份证号以用于实名认证和电子签约。
        </p>
        <p class="indent">4.
          您知悉并同意，我们在运营中可能会通过你在使用服务的过程中所提供的联系方式（如手机号码），向你同时发送一种或多种类型的通知，例如消息告知、身份验证、安全验证、用户调研；此外，我们也可能会以短信的方式，为您提供您可能感兴趣的服务、功能或活动等商业性信息。但请放心，如您不愿接受这些信息，您可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。
        </p>
        <p class="indent">5.
          当您通过我们的联系方式向我们咨询时，为了方便与您联系、帮助您解决问题，确保您的账号安全，我们可能需要您提供部分信息或资料，您可以自行决定是否向我们提供，这些资料可能包括您的账号实名信息和账号使用信息，收集这些信息是为了确保您的账号不在被盗或未被授权的情况下发起申诉或咨询，保障您的账号和财产安全。
        </p>
        <p class="indent">6. 在符合法律法规前提下，为了保障您的账号安全和更好地为您提供优质的服务，我们会收集和使用您在使用哲社主文献平台产品与/或服务过程中产生的如下设备信息：</p>
        <p class="indent">7. 需要提醒您注意，以下情形中，收集使用您的个人信息无需您的同意：<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1) 与我们履行法律法规规定的义务相关的；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2) 与国家安全、国防安全直接相关的；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3) 与公共安全、公共卫生、重大公共利益直接相关的；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4) 与刑事侦查、起诉、审判和判决执行等直接相关的；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5) 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6) 所涉及的个人信息是个人信息主体自行向社会公众公开的；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7)
          使用从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道收集到您的个人信息；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(8) 根据个人信息主体要求签订和履行合同所必需的；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(9) 维护平台的安全稳定运行所必需的，如发现、处置产品或服务的故障；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(10) 法律法规规定的其他情形。<br />
        </p>
        <div class="title" style="margin-top: 35px;">二.我们如何委托处理、转让、公开披露您的个人信息</div>
        <p class="text2" style="margin-top: 36px;">（一）、委托处理</p>
        <p class="indent">
          1. 基于履行本平台的服务需要，我们可能会委托第三方服务提供者代表我们处理您的信息，以便第三方服务提供者代表我们为您提供某些服务，包括平台安全等服务。
        </p>
        <p class="indent">
          2.
          对我们委托的第三方，我们将会根据法律法规的要求与其签署书面协议约定委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等，并对其个人信息处理活动进行监督，要求他们按照我们的要求、本政策中所声明的约束及其他任何相关书面协议和安全措施处理您的个人信息，禁止其将受托处理的信息用于委托处理目的之外的用途。
        </p>
        <!-- <p class="text2" style="margin-top: 36px;">（二）、共享</p>
        <p class="indent">
          <span style='font-weight: 550;'>您清楚并同意，为完成您对授权文件的电子签约，我们会将您的姓名、手机号、身份证号提供给我们合作的第三方电子签约服务机构，以完成电子签约。</span>
          除此之外，我们不会与我们以外的公司、组织和个人共享您的个人信息，但以下情况除外：
        </p>
        <p class="indent">
          1. 在获取单独同意的情况下共享：获得您的单独同意后，我们会与其他方共享您的个人信息。
        </p>
        <p class="indent">
          2. 我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
        </p>
        <p class="indent">
          3.
          与我们的关联方共享：您的个人信息可能会与我们的关联方共享，以便我们更好提供服务。我们只会共享必要的个人信息，并对您的个人信息进行必要的加密、匿名化处理，且受本隐私政策中所声明目的的约束。前述各方如要改变个人信息的处理目的，将再次征求您的授权同意。
        </p> -->
        <p class="text2" style="margin-top: 36px;">（二）、转让</p>
        <p class="indent">
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </p>
        <p class="indent">
          &nbsp;&nbsp;&nbsp;1. 在获取同意的情况下转让：获得您的同意后，我们会向其他方转让您的个人信息；
        </p>
        <p class="indent">
          &nbsp;&nbsp;&nbsp;2.
          在我们发生合并、分立、解散或被宣告破产清算情形，或其他涉及合并、分立、解散或被宣告破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私权政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
        </p>
        <p class="text2" style="margin-top: 36px;">（三）、公开披露</p>
        <p class="indent">
          我们仅会在以下情况下，公开披露您的个人信息：<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1) 获得您单独同意后；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2) 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
        </p>
        <p class="text2" style="margin-top: 36px;">（四）、转让、公开披露个人信息时事先征得同意的例外</p>
        <p class="indent">
          根据法律法规，以下情形中，我们转让、公开披露个人信息不必事先征得您的授权同意：<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1) 与我们履行法律法规规定的义务相关的；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2) 与国家安全、国防安全直接相关的；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3) 与公共安全、公共卫生、重大公共利益直接相关的；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4) 与刑事侦查、起诉、审判和判决执行等直接相关的；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6) 您自行向社会公众公开的个人信息；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(8) 法律法规规定的其他情形。
        </p>
        <div class="title" style="margin-top: 35px;">三. 我们如何使用Cookie和同类技术</div>
        <p class="text2" style="margin-top: 36px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为确保本平台正常运转，我们会在您的计算机或移动设备上存储名为
          Cookie 的小数据文件。Cookie
          通常包含标识符、站点名称以及一些号码和字符。Cookie主要的功能是便于您使用本平台服务，以及帮助网站统计独立访客数量等。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们不会将Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。您可以清除计算机上保存的所有
          Cookie，大部分网络浏览器都设有阻止Cookie
          的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。但您可能因为该等修改，无法登录或使用依赖于Cookie的我们提供的服务或功能。
        </p>
        <div class="title" style="margin-top: 35px;">四. 我们如何存储您的个人信息</div>
        <p class="text2" style="margin-top: 36px;">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们会将在中国境内收集和产生的个人信息存储在中国境内。我们承诺，除非法律法规另有要求，您的个人信息的存储时间将处于实现您授权使用的目的所必需的最短时间。
          如果哲社主文献平台产品与/或服务因业务战略等原因出现停止运营的情况，除非法律法规另有要求，我们会将此类情况向您告知，停止对您个人信息的收集，并删除已收集的个人信息或做匿名化处理。
        </p>
        <div class="title" style="margin-top: 35px;">五. 我们如何保护您的个人信息</div>
        <p class="indent" style="margin-top: 36px;">
          我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
        </p>
        <p class="indent">
          &nbsp;&nbsp;&nbsp;1. 我们采取了行业通行的数据保护技术与管理措施（例如加密技术与身份验证、防火墙与入侵检测、数据脱敏），以防止其遭到未经授权的访问、披露、篡改、丢失或毁坏。
        </p>
        <p class="indent" style='font-weight: bold;'>
          &nbsp;&nbsp;&nbsp;2. 请您知悉，虽然我们将尽力确保或担保您发送给我们的任何信息的安全性，但互联网环境并非百分之百安全，我们亦不对由此产生或与之相关的任何风险、损失承担责任。
        </p>
        <p class="indent" style='font-weight: bold;'>
          &nbsp;&nbsp;&nbsp;3.
          在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以网站公告、电子邮件、短信、电话等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
        </p>
        <div class="title" style="margin-top: 35px;">六. 您如何管理您的个人信息</div>
        <p class="indent" style="margin-top: 36px;">
          在您使用哲社主文献平台期间，您可以通过相应页面提示或本隐私政策载明的方式联系我们，以访问、更正、删除您的个人信息，以及行使改变授权同意的范围与注销账号的权利，您的意见会及时得到处理。
        </p>
        <p class="indent">
          <strong>1. 查阅、复制及更正您的个人信息</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如您希望查阅、复制或更正您的个人信息，您可以通过本隐私政策载明的方式与我们联系，我们将及时响应您的请求。
        </p>
        <p class="indent">
          <strong>2. 删除您的个人信息</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在以下情形中，您可以通过本隐私政策载明的方式向我们提出删除个人信息的请求：<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>• 我们违反法律、行政法规或与您的约定收集、使用个人信息；</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>•
            我们违反法律、行政法规或与您的约定与第三方共享或转让您的个人信息，我们将立即停止共享、转让行为，并通知第三方及时删除；</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>•
            我们违反法律、行政法规规定或与您的约定，公开披露您的个人信息，我们将立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息；</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>• 您不再使用我们的产品或服务，或您注销了账号，或我们终止服务及运营。</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您在此同意，一旦删除请求被响应，您的个人信息将被删除，此前您已提供的各类信息及进行的操作记录将不可恢复。我们可能不会将相应的信息立即从备份系统中删除，但会在备份系统更新时进行删除。
        </p>
        <p class="indent">
          <strong>3. 改变您授权同意的范围</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您可以通过设备权限设置或本隐私政策载明的方式与我们联系，以改变同意范围或撤回您的授权。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请您理解，哲社主文献平台注册用户的服务可能需要开启部分权限并收集必要的个人信息才能得以实现。当您撤回您的同意时，我们将无法继续为您提供撤回同意所对应的服务，但不会影响此前基于您的授权而开展的个人信息处理服务。
        </p>
        <p class="indent">
          4. 转移您的个人信息<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;除法律法规规定的例外情况，您有权请求将您的个人信息转移至其他指定的个人信息处理者。在符合国家网信部门规定条件的，我们将向您提供转移协助，您可以通过本隐私政策载明的方式与我们联系。

        </p>
        <p class="indent">
          <strong>5. 注销账号</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如果您希望注销您的账户，您可以通过本隐私政策载明的方式与我们联系，我们将及时响应您的请求。<br />
          <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请您知悉，您注销上述账户的行为是不可逆的，我们将停止为您提供产品或服务，不再收集您的个人信息，并依据您的要求删除与您账户相关的个人信息或做匿名化处理。</strong>

        </p>
        <p class="indent">
          <strong>6.响应您的上述请求</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如您无法按照上述方式行使权利的，您可以采取本隐私政策载明的联系方式与我们联系。为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。<br />
          <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</strong>
        </p>
        <p class="indent">
          <strong>7.响应情形的例外</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在以下情形中，我们将无法响应您的请求:<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>• 与我们履行法律法规规定的义务相关的;</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>• 与国家安全、国防安全直接相关的; </strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>• 与公共安全、公共卫生、重大公共利益直接相关的; </strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>• 与刑事侦查、起诉、审判和执行判决等直接相关的; </strong><br />
          &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>• 我们有充分证据表明个人信息主体存在主观恶意或滥用权利的;</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>•
            出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>•
            响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的;</strong><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>• 涉及商业秘密的。</strong>

        </p>
        <div class="title" style="margin-top: 42px;">七. 未成年人信息收集与使用</div>
        <p class='indent'>
          我们将不满18周岁的自然人视为未成年人。我们的产品和服务主要面向成年人。如果没有父母或监护人的同意，未成年人不应创建自己的账号。已经创建账号的，我们推定您是具有相应的民事行为能力的成年人。
        </p>
        <p class='indent'>
          对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情況，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、委托、共享、转让或披露此信息。
        </p>
        <p class='indent'>
          如果我们发现自己在未经事先获得可证实的父母或监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。如果您作为父母或监护人发现我们在未经事先可证实的情况下收集了被监护的未成年人的个人信息，您可以通过本政策标明的联系方式告知我们删除相关信息。
        </p>
        <div class="title" style="margin-top: 35px;">八. 我们对去世用户的个人信息保护</div>
        <p class="indent" style="margin-top: 36px;">
          哲社主文献平台将根据《中华人民共和国个人信息保护法》的相关规定加强对死者个人信息的保护。哲社主文献平台用户（仅限自然人）去世后，其近亲属为了自身的合法、正当利益，可以通过本政策公布的联系方式与我们联系，对去世用户的相关个人信息行使查阅、复制、更正、删除等权利，但是去世用户生前另有安排的除外。
        </p>

        <div class="title" style="margin-top: 35px;">九. 本政策如何更新</div>
        <p class="indent" style="margin-top: 36px;">
          我们可能时不时更新或修改本政策。但是<strong>未经您明确同意，我们不会削减您按照本政策所应享有的权利。</strong>该等变更构成本政策的一部分。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于重大变更，我们还会提供更为显著的通知，如明显位置提示、弹框等方式。在此情况下，若您选择继续使用我们的服务，即表示同意受经修订的本政策的约束。<br />
          本隐私政策所指的重大变更包括但不限于：
        </p>
        <p class='indent' style="font-weight: bold;">&nbsp;&nbsp;&nbsp;1. 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
        </p>
        <p class='indent' style="font-weight: bold;">&nbsp;&nbsp;&nbsp;2. 我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；</p>
        <p class='indent' style="font-weight: bold;">&nbsp;&nbsp;&nbsp;3. 个人信息共享、转让或公开披露的主要对象发生变化；</p>
        <p class='indent' style="font-weight: bold;">&nbsp;&nbsp;&nbsp;4. 您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p class='indent' style="font-weight: bold;">&nbsp;&nbsp;&nbsp;5. 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
        <p class='indent' style="font-weight: bold;">&nbsp;&nbsp;&nbsp;6. 个人信息安全影响评估报告表明存在高风险时。</p>
        <div class="title" style="margin-top: 35px;">十. 如何联系我们</div>
        <p class="indent" style="margin-top: 36px;">
          如果您对本政策或个人信息保护有任何问题，您可以将您的书面疑问、意见或建议通过以下地址寄联系我们：<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话：【010-62519375】<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;邮箱：【zszwx@ruc.edu.cn】
        </p>
        <p class="indent">
          一般情况下，我们将在十五个工作日内回复。
        </p>
        <div class="goback" style='margin-top: 10px; text-align: center;'>
          <el-button @click='$router.go(-1)' style='background-color: #9c2526; color: #fff; '>返回</el-button>
        </div>
      </div>

    </div>
    <!-- <footerComponent></footerComponent> -->
  </div>
</template>

<script>

import signagureHead from '@/components/head/signagureHead'
export default {
  components: {
    signagureHead
  },
  mounted() {
    document.title = '隐私协议' + this.$route.meta.title
  }
}
</script>
<style lang="less" scoped>
* {
  font-size: 16px;
}

.top {
  margin-top: 40px;

  .title {
    margin-left: 851px;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    letter-spacing: 19px;
  }

  .topimg {
    margin-left: 915px;
    width: 89px;
    height: 7px;
    margin-top: 5px;
  }
}

.contain {
  width: 1440px;
  margin-left: 240px;

  .data {
    text-align: right;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
  }

  .box {
    margin-top: 25px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding: 50px 67px 67px 62px;

    .boxtitle {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      text-indent: 2em;

      span {
        color: #9C2526;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        text-indent: 2em;
      }
    }

    .text {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      text-indent: 2em;

      strong {
        font-size: 18px;
        font-family: Source Han Sans CN;
        color: #000000;
        line-height: 30px;
      }

      a {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9C2526;
        line-height: 30px;
      }
    }

    .tl {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      text-indent: 2em;

      strong {
        font-size: 18px;
        font-family: Source Han Sans CN;
        color: #000000;
        line-height: 30px;
      }
    }

    .title {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      line-height: 30px;
    }

    .text2 {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 30px;

    }

    .indent {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      text-indent: 2em;

      span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9C2526;
        line-height: 30px;
      }
    }
  }
}
</style>
