<template>
  <div class="statistics">
    <div class="statistics_echarts">
      <div class="echarts_title">
        <p>发文趋势</p>
        <div>
          <el-date-picker style="width:0.6rem;" v-model="startyear" type="year" placeholder="开始时间" value-format="yyyy"
            :picker-options="pickerOptions" @change="handlestartyear"></el-date-picker>
          <span style="margin:0 10px;">-</span>
          <el-date-picker style="width: 0.6rem;" v-model="endtyear" type="year" placeholder="结束时间" value-format="yyyy"
            :picker-options="pickerOptions" @change="handleendtyear"></el-date-picker>
        </div>
      </div>
      <!-- <el-scrollbar style="height:100%;width:100%"> -->
      <div class="linechart" ref="linechart01"></div>
      <!-- </el-scrollbar> -->
    </div>
  </div>
</template>
<script>
import elementResizeDetectorMaker from 'element-resize-detector'
const erd = elementResizeDetectorMaker()
export default {
  data () {
    return {
      requestUrl: '',
      url: '',
      startyear: new Date().getFullYear() - 10 + '', // 关注开始时间
      endtyear: new Date().getFullYear() - 1 + '', // 关注结束时间
      hotWords: '',
      lineyearData: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], // 入选趋势x轴
      linecount: [20, 32, 1, 34, 90, 30, 20], // 入选趋势大小
      secondtenhot: [],
      firstenhot: [],
      literatureCount: '', // 发文
      authorCount: '', // 涉及作者
      orgCount: '', // 涉及机构
      pickerOptions: {
        disabledDate: time => {
          const date = new Date().getFullYear()
          const yearEnd = time.getFullYear()
          return date < yearEnd
        }
      }
    }
  },
  computed: {},
  beforeDestroy () {
    erd.uninstall(this.$refs.linechart01)
  },
  mounted () {
    if (this.$route.fullPath.split('?')[0] === '/keyWord') {
      this.url = '/keyWordDetail/publicationTrend'
    } else {
      this.url = '/hotWordStatistics/getYearTrends'
    }
    this.secondtenhot = this.$route.query.secondLevelDiscipline
    this.firstenhot = this.$route.query.firstLevelDiscipline
    this.hotWords = this.$route.query.name

    this.getsendData()
    this.getdetail()
  },
  methods: {
    handlestartyear (start) {
      this.startyear = start
      if (this.startyear > this.endtyear) {
        return this.$message({
          type: 'warning',
          message: '建议开始时间选择大于结束时间,结束时间早于开始时间'
        })
      }
      this.getsendData()
    },
    handleendtyear (end) {
      this.endtyear = end
      if (this.startyear > this.endtyear) {
        return this.$message({
          type: 'warning',
          message: '建议开始时间选择大于结束时间,结束时间早于开始时间'
        })
      }
      this.getsendData()
    },
    // 选择年份
    handleyear () {
      if (parseInt(this.endtyear) < parseInt(this.startyear)) {
        return this.$message.error('输入的日期不正确，请稍后重试!')
      }
      this.getsendData()
    },
    async getsendData () {
      const { data: res } = await this.$http({
        url: this.url,
        method: 'POST',
        data: {
          endYear: parseInt(this.endtyear),
          startYear: parseInt(this.startyear),
          hotWord: this.hotWords,
          keyWord: this.hotWords
        }
      })
      const rest = res
      var seri = []
      for (var i = 0; i < rest.data.countList.length; i++) {
        seri.push({
          name: rest.data.countList[i].discName,
          type: 'line',
          data: rest.data.countList[i].list
        })
      }
      this.lineyearData = res.data.yearList.map(item => {
        return item
      })
      this.linecount = res.data.countList.map(item => {
        return item
      })
      var myChartline = this.$echarts.init(this.$refs.linechart01)
      var option
      option = {
        xAxis: {
          type: 'category',
          data: this.lineyearData,
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        tooltip: {
          trigger: 'axis'
        },
        series: seri
      }

      myChartline.setOption(option)
      erd.listenTo(this.$refs.linechart01, function () {
        myChartline.resize()
      })
    },
    // 详情
    async getdetail () {
      // console.log('this.$router', this.$route)
      // if (this.$route.name = 'keyword') {

      // }
      const { data: res } = await this.$http({
        url: '/hotWordStatistics/getHotWordDetail',
        method: 'post',
        data: {
          hotWord: this.hotWords,
          secondLevelDiscipline: this.secondtenhot,
          firstLevelDiscipline: this.firstenhot,
          orgName: '1'
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.orgCount = res.data.orgCount
      this.authorCount = res.data.authorCount
      this.literatureCount = res.data.literatureCount
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-date-editor.el-input {
  width: 100px;
}

/deep/.el-date-picker::placeholder {
  color: #9c2526;
  font-size: 12px;
}

.statistics {
  // height: 29.1667vw;
  width: 1080px;
  height: 330px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;
  // box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  // border-radius: 10px;

  .statistics_count {
    display: flex;
    width: 100%;
    height: 120px;
    background: #f7f7f7;
    border-radius: 4px;
    align-items: center;
    justify-content: space-around;

    .statistics_count_class {
      text-align: center;

      .statistics_count_title {
        font-size: 36px;
        font-weight: 500;
        color: #9c2526;
      }

      .statistics_count_txt {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .linechartTitlebox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >p {
      font-size: 18px;
      font-size: 18px;
      font-weight: 500;
      color: #9c2526;
    }

    /deep/.el-range-editor.el-input__inner {
      width: 200px;
    }
  }

  .linechart {
    width: 100%;
    height: 296.5997px;

    // height: 100%;
    // overflow: hidden;
    // overflow: auto;
    /deep/canvas {
      width: 100% !important;
      height: 300px !important;
    }
  }

  .statistics_echarts {
    .echarts_title {
      // padding: 1.0417vw 0;
      display: flex;
      justify-content: space-between;

      >p {
        font-size: 18px;
        font-size: 18px;
        font-weight: 500;
        color: #9c2526;
      }
    }
  }
}
</style>
