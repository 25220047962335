<template>
  <div class="hotList">
    <div class="hot_header">
      <div class="name">热词演变
        <el-select @change='handleSelect' value-key='firstLevelCode' v-model="value" placeholder="请选择">
          <template slot='prefix'>
            <i style='color: #9c2526;' class="el-icon-s-management"></i>
          </template>
          <el-option v-for="item in options" :key="item.firstLevelCode" :label="item.firstLevelName" :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="selectData">

      </div>
      <div class="date">
        <div>
          <el-date-picker style="width: 119.1994px;" v-model="startyear" type="year" placeholder="开始时间" value-format="yyyy"
            :picker-options="pickerOptions" @change="handleyear"></el-date-picker>
          <span style="margin:0 10px;">-</span>
          <el-date-picker style="width: 119.1994px;" v-model="endtyear" type="year" placeholder="结束时间" value-format="yyyy"
            :picker-options="pickerOptions" @change="handleyear"></el-date-picker>
        </div>
      </div>
    </div>
    <!-- 热词演变列表 -->
    <div class="hotListContextgroup" v-if="hotevolutionList.length !== 0">
      <div class="hotListContext" v-for="(item, index) in hotevolutionList" :key="index">
        <div>
          <div class="hotList_itemtitle">
            <span class="year">{{ item.year }}年</span>
            <div class="yearrt">
              <span class="yearrtTitle">年度入选主文献量：</span>
              <span class="yearcout">{{ item.count }}</span>
            </div>
          </div>
          <div class="hotListContextbox">
            <div class="hotListContext_header">
              <span style="width: 36.6336px;">排名</span>
              <span style="width:140px;text-align:center;">热词</span>
              <span style="width:58.6406px;text-align:center;">主文献量</span>
              <span style="width:100px;text-align:center;">占比</span>
            </div>
            <div v-if="item.list.length !== 0">
              <div class="item" v-for="(item2, index2) in item.list" :key="index2">
                <span class="item_index redPercent">{{ index2 + 1 }}</span>
                <span class="namebox">
                  <span class="keyName redName" :class="{ searchword: item2.name == hotWords }">{{ item2.name }}</span>
                  <span class="new" v-if="item2.isNew === 1">NEW</span>
                </span>

                <span class="contributeCount">
                  <span class="squre"></span>
                  {{ item2.matchCount }}
                </span>
                <span class="propBox">
                  <span class="percent redPercent">{{ item2.prop }}</span>
                  <span class="propimg" v-if="item2.yoy.indexOf('+') != -1">
                    <img src="../../assets/images/home/shagnsheng.png" alt />
                    <span>{{ item2.yoy.slice(1) }}</span>
                  </span>
                  <span class="propimg" v-if="item2.yoy.indexOf('-') != -1">
                    <img src="../../assets/images/home/xiajiang.png" alt />
                    <span>{{ item2.yoy.slice(1) }}</span>
                  </span>
                </span>
              </div>
            </div>
            <div v-else class="itemListContextgroup">
              <img src="../../assets/images/home/nodatasmall.png" class="img" alt />
              <p>没有查询到数据哦</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="hotListContextgroup">
      <div class="empty">
        <img src="../../assets/images/home/nodatasmall.png" class="img" alt />
        <p>没有查询到数据哦</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data () {
    return {
      options: [],
      value: '',
      startyear: new Date().getFullYear() - 4 + '', // 关注开始时间
      endtyear: new Date().getFullYear() - 2 + '', // 关注结束时间
      hotevolutionList: [],
      hotevolutionnum: [],
      num: [],
      isnull: '',
      pickerOptions: {
        disabledDate: time => {
          const date = new Date().getFullYear()
          const yearEnd = time.getFullYear()
          return date < yearEnd
        }
      }
    }
  },
  mounted () {
    this.hotWords = this.$route.query.name
    this.handleEvoluSelect()
  },
  methods: {
    // 下拉框选择改变学术热词
    handleSelect () {
      this.firstenhot = this.value.firstLevelCode
      this.secondtenhot = this.value.secondLevelCode
      this.gethotevolution()
    },
    // 热词演变下拉选择框
    async handleEvoluSelect () {
      const res = await this.$http({
        url: '/hotWordStatistics/getDiscplineList',
        method: 'POST',
        data: {
          hotWord: this.hotWords
        }
      })
      this.options = res.data.data
      this.value = this.options[0]
      this.firstenhot = this.value.firstLevelCode
      this.secondtenhot = this.value.secondLevelCode
      this.gethotevolution()
    },
    // 选择年份
    handleyear () {
      if (parseInt(this.endtyear) < parseInt(this.startyear)) {
        return this.$message.error('输入的日期不正确，请稍后重试!')
      }
      this.gethotevolution()
    },
    async gethotevolution () {
      const { data: res } = await this.$http({
        url: '/hotWordStatistics/getHotWordEvolution',
        method: 'post',
        data: {
          hotWord: this.hotWords,
          endYear: this.endtyear,
          startYear: this.startyear,
          firstLevelDiscipline: this.firstenhot,
          secondLevelDiscipline: this.secondtenhot
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.hotevolutionList = res.data
    }
  },
  watch: {}
}
</script>
<style scoped lang="less">
/*内层轨道，滚动条中间部分 */
::-webkit-scrollbar-track-piece {
  background-color: #f2f2f2;
  border-radius: 10px;
}

/* 滑块 */
::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 5px;
  background: #9c2526;
}

:hover::-webkit-scrollbar-thumb {
  background: #9c2526;
}

.hotList {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;

  .hot_header {
    display: flex;
    justify-content: space-between;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;

    .name {
      font-size: 18px;
      font-weight: 500;
      color: #9c2526;

      .el-select {
        margin-left: 10px;
      }

      /deep/.el-input__inner {
        border: 1px solid #9c2526 !important;
        color: #9c2526 !important;
      }
    }
  }

  .hotListContextgroup {
    overflow-x: scroll;
    display: flex;

    .hotListContext {
      width: 350px;
      margin-right: 15px;

      border-radius: 4px;

      .hotList_itemtitle {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        align-items: center;

        .year {
          font-size: 18px;
          font-weight: 500;
          color: #000000;
        }

        .yearrt {
          font-size: 12px;
          font-weight: 400;
          color: #000000;

          .yearrtTitle {
            font-size: 12px;
          }

          .yearcout {
            color: @my-color;
          }
        }
      }

      .hotListContextbox {
        border: 1px solid #e8e8e8;
        padding-bottom: 20px;
      }

      .hotListContext_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        // width: 334px;
        height: 50px;
        background: #f7f7f7;
        border-radius: 3.9994px;

        span {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
        }
      }

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 0 10px;
        // height: 28px;
        margin-top: 28px;
        margin-right: 28px;
        position: relative;

        // line-height: 28px
        .propBox {
          position: relative;
          width: 24%;
          text-align: right;

          .propimg {
            position: absolute;
            width: 30px;
            top: -5px;
            right: 275px;
          }
        }

        .keyName {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
        }

        .new {
          // position: absolute;
          color: #ff0002;
          font-size: 12px;
          // top: -28%;
          // right: 40%;
        }

        // 部分红色的关键词
        .redName {
          // width: 7.2917vw;
          font-size: 14px;

          // color: #9c2526;
          text-align: left;
          position: relative;
        }

        .namebox {
          width: 50%;
          text-align: left;
        }

        .searchword {
          font-size: 18px;
          color: @my-color;
        }

        .contributeCount {
          font-weight: 400;
          color: #000000;

          .squre {
            display: inline-block;
            border: 5px solid #ff7100;
          }
        }

        // 部分红色的占比和序号
        .redPercent {
          color: #000;
          text-align: center;
          margin-right: 10px;
        }

        &:nth-child(-n + 3) {
          .contributeCount {
            .squre {
              border: 5px #9c2526 solid;
            }
          }

          .item_index {
            color: #9c2526;
          }

          .propBox {
            .redPercent {
              color: #9c2526;
            }
          }

          // background-color: orange;
        }

        // .item_index {
        //   // color: #9c2526;
        // }
      }
    }

    .empty {
      margin: 80px 350px;

      .img {
        width: 276px;
        height: 137px;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
      }

      p {
        text-align: center;
        color: #666;
      }
    }
  }

  // .hotList_header {
  //   display: flex;
  //   justify-content: space-between;
  //   .year {
  //     font-size: 18px;
  //     font-weight: 600;
  //     color: #000000;
  //     width: 60px;
  //   }
  //   .yearCount {
  //     font-size: 12px;
  //     font-weight: 400;
  //     color: #000000;
  //   }
  // }
}

.itemListContextgroup {
  width: 100%;
  // height: 100%;
  // height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  // .empty {
  height: 520.32px;
  //   width: 10px;
  //   height: 10px;
  // }
}

.el-icon-s-management {
  width: 20px;
  font-size: 20px;
  margin-top: 10px;
}
</style>
