<template>
  <div class="home">
    <div class="middleContainer">
      <headerComponent></headerComponent>
      <div class="main" style="position: relative">
        <router-view>
          <mainhome></mainhome>
        </router-view>
        <div class="recommendOptions" @click="handleRecommond" :class="{ recommondFixed: recommondFixed === true, recommondPosAbso: recommondFixed === false }">
          <span class="icon"></span>
          <div class="opintip">我要荐稿</div>
        </div>
        <div class="opinion" :class="{ isfixed: isFixed === true, posAbso: isFixed === false }">
          <!-- <div @click="handleopinion2" style="margin-bottom: 10px">
            <span class="icon icon1"></span>
            <div class="opintip">
              <div>原创学术</div>
              <div>理论征集</div>
            </div>
          </div> -->
          <div @click="handleopinion">
            <span class="icon icon2"></span>
            <div class="opintip">意见反馈</div>
          </div>
        </div>
        <recommended-articles class="recommends" ref="recommends" @opinionsopen="recommendopen"></recommended-articles>
        <opinions class="optionsss" ref="opinions" @opinionsopen="opinionsopen"></opinions>
      </div>
    </div>
    <login ref="login" class="homeLoginBigBox" @registeropen="registeropen"></login>
    <register ref="register" @loginopen="loginopen" @emitSuccessRegister="$refs.login.modelopen = true"></register>
    <el-footer style="height: auto">
      <footerComponent></footerComponent>
    </el-footer>
  </div>
</template>

<script>
import platform from 'platform';
import headerComponent from '../components/common/header.vue';
import footerComponent from '../components/common/footer.vue';
import mainhome from '../page/mainhome';
import { IsLoginV2, threeHandleLogin } from '../utils/threeLogin';
import opinions from '../components/head/opinions.vue';
import recommendedArticles from '@/components/head/recommendedArticles';
import login from '../page/login.vue';
import register from '../page/register';
export default {
  components: {
    headerComponent,
    footerComponent,
    mainhome,
    opinions,
    login,
    register,
    recommendedArticles,
  },
  data() {
    return {
      isFixed: true,
      opinionsopen: false,
      isOpinion: true,
      recommondFixed: true,
      recommendopen: false,
    };
  },
  async created() {
    // // 页面加载判断

    const isLogin = await IsLoginV2(this.THREE_LOGIN_OR);
    // console.log('自动登传入的值', isLogin)
    if (isLogin) {
      console.log('自动登录成功', isLogin);
      threeHandleLogin(this.THREE_LOGIN_OR);
    } else {
      console.log('自动登录失败');
    }
    this.mainbrowse = this.$route.query;
    // 存储code做比较
    // if (
    //   (!localStorage.getItem('thirdcode') && this.$route.query.code) ||
    //   (this.$route.query.code &&
    //     this.$route.query.code != localStorage.getItem('thirdcode'))
    // ) {
    //   // 访问认证登录接口
    //   // await this.thirdLogin()
    // }
    if (this.$route.query.code && this.$route.query.code !== localStorage.getItem('thirdcode')) {
      // 访问认证登录接口
      await this.thirdLogin();
    }
  },
  mounted() {
    window.addEventListener('scroll', this.fixedActiveBtn);
    this.open();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.fixedActiveBtn, true);
  },
  methods: {
    handleGoPlatformInfo() {
      this.$router.push('/platformNotice');
    },
    fixedActiveBtn() {
      const bottom = document.documentElement.scrollHeight - document.documentElement.clientHeight - 300;
      const scrollTop = document.documentElement.scrollTop;
      if (scrollTop >= bottom) {
        this.isFixed = false;
        this.recommondFixed = false;
      } else {
        this.isFixed = true;
        this.recommondFixed = true;
      }
    },
    handleopinion() {
      if (localStorage.getItem('token')) {
        this.$refs.opinions.opinionsopen = !this.$refs.opinions.opinionsopen;
        if (this.$refs.recommends.recommendopen) {
          this.$refs.recommends.recommendopen = !this.$refs.recommends.recommendopen;
        }
      } else {
        // 未登录
        this.loginhandle();
      }
    },
    handleopinion2() {
      const routeUrl = this.$router.resolve({
        name: 'collectIndex',
      });
      window.open(routeUrl.href, '_blank');
      // if (localStorage.getItem('token')) {
      //   const routeUrl = this.$router.resolve({
      //     name: 'collectIndex',
      //   });
      //   window.open(routeUrl.href, '_blank');
      // } else {
      //   // 未登录
      //   this.loginhandle();
      // }
    },
    // 打开作者荐稿
    handleRecommond() {
      if (localStorage.getItem('token')) {
        if (this.$refs.opinions.opinionsopen) {
          this.$refs.opinions.opinionsopen = !this.$refs.opinions.opinionsopen;
        }
        this.$refs.recommends.recommendopen = !this.$refs.recommends.recommendopen;
      } else {
        // 未登录
        this.loginhandle();
      }
    },
    open() {
      document.addEventListener('click', this.close, false);
    },
    close(e) {
      const boxEl = document.querySelector('.optionsss');

      const recommendsEl = document.querySelector('.recommends');
      if (boxEl) {
        if (!boxEl.contains(e.target) && e.target.className !== 'opintip' && e.target.className !== 'opinion isfixed' && e.target.className !== 'icon' && e.target.className !== 'el-input__icon el-icon-circle-close el-input__clear' && e.target.className !== 'opinion posAbso') {
          this.$refs.opinions.opinionsopen = !this.$refs.opinions.opinionsopen;
        }
      }
      if (recommendsEl) {
        if (!recommendsEl.contains(e.target) && e.target.className !== 'opintip' && e.target.className !== 'recommendOptions recommondFixed' && e.target.className !== 'icon' && e.target.className !== 'el-input__icon el-icon-circle-close el-input__clear' && e.target.className !== 'recommendOptions recommondPosAbso') {
          this.$refs.recommends.recommendopen = !this.$refs.recommends.recommendopen;
        }
      }
    },
    removeListener() {
      document.removeEventListener('click', this.close, false);
    },
    loginhandle() {
      threeHandleLogin(this.THREE_LOGIN_OR);
      // this.$refs.login.modelopen = true
    },
    registeropen(value) {
      this.$refs.register.registershow = value;
    },
    loginopen(value) {
      this.$refs.login.modelopen = value;
    },
    // 访问认证登录接口:
    async thirdLogin() {
      const { data: res } = await this.$http({
        url: '/authentication/login',
        method: 'post',
        data: {
          code: this.mainbrowse.code,
          state: this.mainbrowse.state,
          operatingSystem: platform.family,
          browserType: platform.name,
        },
      });
      if (res.code !== 200) return this.$message.error(res.msg);
      // 登录成功之后token
      localStorage.setItem('thirdcode', this.mainbrowse.code);
      localStorage.setItem('token', res.data.jwt);
      this.$store.commit('setUserToken', res.data.jwt);
      this.$store.commit('userName', res.data.userName);
      // history.pushState({}, '11112', '/')
      // this.$router.push(this.$store.state.threeUrl)
      // location.reload()
      history.pushState({}, '11112', this.$store.state.threeUrl);
      // location.href = this.$store.state.threeUrl
      // 人中中心修改密码
      this.$store.commit('setthreeReset', true);
    },
  },
  watch: {
    '$store.state.userName': {
      immediate: true,
      handler(newVal) {
        this.userName = newVal;
      },
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-footer {
  padding: 0;
}

.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 921px) {
  .isfixed {
    bottom: 480px;
    position: fixed;
  }
  .recommondFixed {
    bottom: 650px;
    position: fixed;
  }

  .posAbso {
    bottom: 100px;
    position: absolute;
  }

  .recommondPosAbso {
    bottom: 200px;
    position: absolute;
  }
}

@media screen and (min-width: 920px) {
  .isfixed {
    bottom: 30px;
    position: fixed;
  }
  .recommondFixed {
    bottom: 170px;
    position: fixed;
  }
}

.posAbso {
  bottom: 20px;
  position: absolute;
}

.recommondPosAbso {
  bottom: 160px;
  position: absolute;
}

.platformNotice {
  width: 194px;
  height: 218px;
  background-color: transparent;
  position: fixed;
  z-index: 1005;
  top: 294px;
  right: 71px;
  animation: bounce-up 1.5s linear infinite;
  border-radius: 6px;
  cursor: pointer;
  background-image: url('../assets/images/home/noticeBg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .platformTitle {
    margin-top: 37px;
    text-align: center;

    img {
      width: 16px;
      height: 17px;
      margin-right: 4px;
    }

    span {
      font-size: 16px;
      color: #fff;
    }
  }

  .platformNoticeInfo {
    width: 143px;
    height: 84px;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    line-height: 18px;
    margin: 19px auto 0;
  }
}

@keyframes bounce-up {
  25% {
    transform: translateY(10px);
  }

  50%,
  100% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(-10px);
  }
}

// @media screen and (max-width: 921px) {
.opinion {
  // box-sizing: border-box;
  right: 5px;
  // width: 60px;
  // height: 60px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px 10px 10px 10px;
  padding-top: 10px;
  padding-bottom: 8px;
  text-align: center;
  cursor: pointer;

  .icon {
    display: inline-block;
    width: 21px;
    height: 20px;
    &.icon1 {
      background: url('../assets/images/head/ycllxszj.png') no-repeat;
      background-size: cover;
    }
    &.icon2 {
      background: url('../assets/images/head/space.png') no-repeat;
      background-size: cover;
    }
  }

  .opintip {
    font-size: 15px;
    transform: scale(0.8);
    font-family: PingFang SC;
    font-weight: 500;
    color: #666;
    text-align: center;

    // text-align: center;
    // margin-top: 8px;
  }
}

.opinion > div:hover {
  .icon {
    display: inline-block;
    width: 21px;
    height: 20px;
    &.icon1 {
      background: url('../assets/images/head/ycllxszj-act.png') no-repeat;
      background-size: cover;
    }
    &.icon2 {
      background: url('../assets/images/head/space_red.png') no-repeat;
      background-size: cover;
    }
  }

  .opintip {
    font-size: 15px;
    transform: scale(0.8);
    font-family: PingFang SC;
    font-weight: 500;
    color: #9c2526;
    text-align: center;
    // margin-top: 8px;
    // margin-top: 8px;
  }
}

.recommendOptions {
  // box-sizing: border-box;
  right: 5px;
  // width: 60px;
  // height: 60px;
  background: #9c2526;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 30px 30px 10px 10px;
  padding-top: 10px;
  padding-bottom: 8px;
  text-align: center;
  cursor: pointer;

  .icon {
    display: inline-block;
    width: 21px;
    height: 20px;
    background: url('../assets/images/head/iWantRecommend.png') no-repeat;
    background-size: cover;
  }

  .opintip {
    font-size: 15px;
    transform: scale(0.8);
    font-family: PingFang SC;
    font-weight: 500;
    color: #fff;
    text-align: center;
    // margin-top: 8px;
  }
}

.recommendOptions:hover {
  right: 5px;
  // width: 60px;
  // height: 60px;
  background: #9c2526;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 30px 30px 10px 10px;
  padding-top: 10px;
  padding-bottom: 8px;
  text-align: center;
  cursor: pointer;

  .icon {
    display: inline-block;
    width: 21px;
    height: 20px;
    background: url('../assets/images/head/iWantRecommendHover.png') no-repeat;
    background-size: cover;
  }

  .opintip {
    font-size: 15px;
    transform: scale(0.8);
    font-family: PingFang SC;
    font-weight: 500;
    color: #fff;
    text-align: center;
    // margin-top: 8px;
  }
}
</style>
