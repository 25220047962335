<template>
  <!-- 笔记 -->
  <div class="notice">
    <div class="search">

      <!-- <el-form-item>
            <el-select v-model="mineNotesSearchDto.type" placeholder="请选择批注类型">
              <el-option v-for="item in Comments" :key="item.type" :label="item.value" :value="item.type"></el-option>
            </el-select>
          </el-form-item> -->
      <div @keydown.enter="getNoteList()">
        <el-input class="noteInfo" v-model="mineNotesSearchDto.markValue" placeholder="请输入笔记内容" clearable></el-input>
      </div>

      <el-button style="margin-left: 15px;" type="primary" @click="getNoteList()">查询</el-button>

    </div>
    <div class="container" v-if="noteList.length != 0">
      <div v-for="(item, index) in noteList" :key="item.id" class="box-card">
        <div class="top">
          <!-- 标题 -->
          <div class="title">
            {{ (mineNotesSearchDto.page - 1) * mineNotesSearchDto.pageRow + index + 1 }}.{{ item.authorship }}
            <span class="title" v-if='item.authorship !== "" && item.authorship !== null'>：</span>
            <span class="retitle" @click="showInfomation(item)">{{ item.title }}
              <div class='directionBox'>
                <div class="direction"></div>
                <div class="dirInfo">查看文献详情</div>
              </div>
            </span>
          </div>
          <button class="topbutton" @click="showReading(item)">查看笔记详情</button>
          <!-- <el-button type="primary" @click="showInfomation(item)">查看详情</el-button> -->
        </div>

        <!-- 编辑弹窗 -->
        <div class="show-mark" id="show-mark">
          <div class="marktitle">
            <span class="mark">记笔记</span>
            <!-- <span class="save">支持自动保存</span> -->
          </div>
          <TextEditor :edithtml="edithtml" @html="gethtml" class="mark-edit" v-if="open"></TextEditor>
          <!-- <div contenteditable="true" class="mark-edit" id="mark-edit"></div> -->
          <div class="mark-btn">
            <div class="switchBox">
              <el-switch v-model="opencontent" active-text="内容是否公开" :active-value="0" :inactive-value="1"></el-switch>
            </div>
            <div class="switchBox">
              <el-switch v-model="anonymous" v-if="!opencontent" active-text="匿名" :active-value="0"
                :inactive-value="1"></el-switch>
            </div>
            <div class="mark-btnbox">
              <span class="mark-btn-cancel" id="mark-btn-cancel" style="background-color: #F7F7F7; color: black;"
                @click="handleCancelClick()">取消</span>
              <span class="mark-btn-ok" id="mark-btn-ok" @click="handleSureClick()">确定</span>
            </div>
          </div>
        </div>

        <!-- 大标题下面内容 -->
        <div class="noteShow">
          <ul class="noteShowInfo">
            <li v-for="el in item.markList" :key="el.id">
              <div class="left">
                <div class="left-top">
                  <div v-if="el.type === 0" class="heightLight">
                    <img src="../../assets/images/main/note/editFile.png" alt />
                    <span>笔记</span>
                  </div>
                  <div v-else-if="el.type === 1" class="heightLight">
                    <img src="../../assets/images/main/note/line.png" alt />
                    <span>下划线</span>
                  </div>
                  <div v-else class="heightLight">
                    <img src="../../assets/images/main/note/high-light.png" alt />
                    <span>高亮</span>
                  </div>
                  <div class="originalAndnote">
                    <div class="note">
                      <span style="font-size: 14px;color:#9C2526;">笔记内容:</span>
                      <div class="notehtml" ref='titleCount' v-html='el.markValue'>
                      </div>
                      <span class="iconfont icon-biyanjing ispublic" v-if="el.isPublic == 1">不公开</span>
                      <span class="iconfont icon-biyanjing ispublic" v-if="el.isAnonymous == 0">匿名</span>
                    </div>
                    <div class="original">
                      <span>原文:</span>
                      <h3 :title="el.textValue">{{ el.textValue }}</h3>
                    </div>
                  </div>
                </div>
                <!-- <div class="left-bottom">
                    <img src="../../assets/images/main/note/tag.png" alt />
                    <span>标签:</span> -->
                <!-- <el-tag>扫黑除恶</el-tag>
                  <el-tag>扫黑除恶</el-tag>
                    <el-tag>扫黑除恶</el-tag>-->
                <!-- </div> -->
              </div>
              <div class="right">
                <div class="rightTop">
                  <el-button size="small" @click='edit(el)'>编辑</el-button>
                  <el-button size="small" @click='deleteNode(el)'>删除</el-button>
                </div>
                <div class="rightBottom">
                  <span>{{ el.createTime }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <empty v-loading.fullscreen.lock="loading" element-loading-spinner=none
      element-loading-background="rgba(0, 0, 0, 0.1)" v-if='noteList.length === 0' :noteList="noteList"></empty>
    <!-- <div v-loading="loading" v-if="noteList.length === 0" class="empty">
      <img src="../../assets/images/home/nodataside.png" alt="">
      <span>没有查询到数据哦</span> -->
    <!-- </div> -->
    <div v-if="noteList.length != 0">
      <el-pagination :page-sizes="[1, 2, 3, 4]" :page-size="mineNotesSearchDto.pageRow"
        :current-page="mineNotesSearchDto.page" layout="total, sizes, prev, pager, next, jumper" :total="total"
        @current-change="pageChange" @size-change="sizeChange"></el-pagination>
    </div>

  </div>
</template>

<script>
import TextEditor from '../../components/main/Editor.vue'
import empty from '../../components/common/empty'
export default {
  components: {
    empty,
    TextEditor
  },
  data () {
    return {
      loading: false,
      titleValue: '',
      formInline: {
        user: '',
        region: ''
      },
      path: '',
      // 批注类型下拉列表框
      Comments: [
        {
          type: '',
          value: '请选择批注类型'
        },
        {
          type: 0,
          value: '笔记'
        },
        {
          type: 1,
          value: '下划线'
        },
        {
          type: 2,
          value: '高亮'
        }
      ],
      // 请求到的数据放这里
      noteList: [],
      // 请求列表参数
      mineNotesSearchDto: {
        markValue: '', // 笔记内容
        page: 1, // 页码
        pageRow: 2, // 默认一页两条
        title: '', // 标题
        type: '' // 批注类型
      },
      total: 0,
      edithtml: '',
      open: true, // 富文本重新渲染
      referenceNumber: '',
      markDatasend: '',
      opencontent: 0,
      anonymous: 1
    }
  },
  mounted () {
    document.title = this.$route.meta.title
    this.getNoteList()
    this.path = this.$route.query.path
  },
  methods: {
    handleCancelClick () {
      const showMark = document.getElementById('show-mark')
      showMark.style.display = 'none'
      this.open = false
      this.edithtml = ''
    },
    async handleSureClick () {
      const showMark = document.getElementById('show-mark')
      if (this.edithtml === '<p><br></p>') {
        return this.$message.error('笔记内容不得为空')
      }
      showMark.style.display = 'none'
      this.open = false
      this.markDatasend.message = this.edithtml
      const { data: res } = await this.$http({
        url: '/takeNotes/addTakeNotes',
        method: 'post',
        data: {
          id: this.id,
          isAnonymous: this.opencontent == 0 ? this.anonymous : 1,
          isPublic: this.opencontent,
          markContentDto: [this.markDatasend],
          referenceNumber: this.referenceNumber
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.$message({
        message: res.msg,
        type: 'success'
      })
      this.edithtml = ''
      this.markDatasend = ''
      this.opencontent = 0
      this.anonymous = 1
      this.getNoteList()
    },
    handleTitle (event) {
      this.titleValue = event.target.textContent
    },
    // 获取笔记数据列表
    async getNoteList () {
      this.loading = true
      // this.mineNotesSearchDto.markValue = this.mineNotesSearchDto.markValue.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      const res = await this.$http({
        url: '/mineNotes/getMineNotesList',
        method: 'POST',
        data: this.mineNotesSearchDto
      })

      this.noteList = res.data.data.list
      this.total = res.data.data.count
      this.loading = false
    },

    // 编辑
    edit (el) {
      const showMark = document.getElementById('show-mark')
      showMark.style.display = 'block'
      this.open = true
      this.referenceNumber = el.referenceNumber
      this.opencontent = el.isPublic
      this.anonymous = el.isAnonymous
      this.markDatasend = JSON.parse(el.markJson)
      this.edithtml = el.markValue
    },
    gethtml (data) {
      this.edithtml = data
    },
    // 删除
    deleteNode (el) {
      this.$confirm('此操作将永久删除该笔记, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        lockScroll: false
      }).then(async () => {
        const { data: res } = await this.$http({
          url: '/takeNotes/deleteRightNote',
          method: 'post',
          data: {
            id: el.id
          }
        })
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.getNoteList()
      }).catch(() => {
      })
    },
    showInfomation (item) {
      if (item.isAuthorized === 0) {
        return this.$message({
          type: 'warning',
          message: '文献未被授权，暂无权限查看详情！'
        })
      }
      const routeData = this.$router.resolve({
        path:
          '/mainDetails?path=' +
          this.path +
          '&referenceNumber=' +
          item.referenceNumber +
          '&id=' +
          item.id
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    showReading (item) {
      if (item.isAuthorized === 0) {
        return this.$message({
          type: 'warning',
          message: '文献未被授权，暂无权限查看详情！'
        })
      }
      const routeData = this.$router.resolve({
        path:
          '/reading?referenceNumber=' +
          item.referenceNumber

        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    // 换页
    pageChange (current) {
      this.mineNotesSearchDto.page = current
      this.getNoteList()
    },
    sizeChange (current) {
      this.mineNotesSearchDto.pageRow = current
      this.getNoteList()
    }
  }
}
</script>
<style scoped lang="less">
.empty {
  margin-top: 65px;
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 232px;
    height: 114px;
  }

  span {
    color: #999;
    font-size: 14px;
  }
}

.search {
  display: flex;

  .noteInfo {
    width: 500px;
  }
}

.container {
  width: 100%;

  .box-card {
    .mark-btn {
      padding: 10px 20px 20px;
      box-sizing: border-box;
    }

    .switchBox {
      margin-top: 10px;
    }

    .mark-btnbox {
      text-align: right;
    }

    .mark-info span,
    .mark-btn span {
      display: inline-block;
      margin-right: 10px;
      padding: 4px 20px;
      background-color: #9c2526;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      border-radius: 6px;
      cursor: pointer;
    }

    .show-mark {
      position: fixed;
      right: 1%;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      border-radius: 6px;
      background-color: #fff;
      display: none;
      border: 1px solid #E6E6E6;
      box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    }

    .marktitle {
      width: 100%;
      height: 50px;
      background: #F5F5F5;
      border: 1px solid #E6E6E6;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      position: relative;

      .mark {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #9C2526;
        padding-left: 100px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .save {
        margin-left: 90px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #5F5F5F;
        line-height: 58px;
      }
    }

    .top {
      display: flex;
      justify-content: space-between;
      height: 32px;

      .title {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 32px;
      }

      .retitle {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #9C2526;
        line-height: 32px;
        cursor: pointer;
        position: relative;

        &:hover .directionBox {
          opacity: 1;
        }
      }

      .topbutton {
        width: 100px;
        height: 32px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        background: #9C2526;
        font-family: Source Han Sans CN;
        color: #FFFFFF;
        border: #9C2526 1px;
        cursor: pointer;
      }
    }

    .noteShowInfo {
      // width: 1000px;
      // height: 298px;
      background: #f7f7f7;
      border-radius: 6px;
      margin-top: 13px;
      margin-left: 8px;
      margin-bottom: 14px;
      padding: 0px 20px 5px 20px;
      // padding: 1.0417vw;

      li {
        // height: 5.1734vw;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        justify-content: space-between;
        background: #f7f7f7;

        &:nth-child(n + 1):not(:nth-last-child(-n + 1)) {
          padding-top: 20px;
        }

        &:last-child {
          border: none;
          padding-top: 20px;
        }

        .left {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .left-top {
            display: flex;
            margin-bottom: 12px;

            .heightLight {
              width: 54px;
              height: 20px;
              text-align: center;
              background-color: #f9e2c6;
              border-radius: 4px;
              margin-right: 10px;
              line-height: 20px;

              span {
                font-size: 12px;
                color: #ff9000;
                margin-left: 5px;
              }

              img {
                width: 9px;
                height: 10px;
              }
            }

            .originalAndnote {
              display: flex;
              flex-direction: column;

              .note {
                display: flex;

                .notehtml {
                  max-width: 700px;
                  margin-left: 5px;
                  overflow-y: auto;
                  word-wrap: break-word; //换行
                }

                .ispublic {
                  font-size: 10px;
                  font-weight: 400;
                  color: #fff;
                  width: 52px;
                  height: 18px;
                  margin-left: 10px;
                  text-align: center;
                  line-height: 18px;
                  background-color: #fabd49;
                  border-radius: 4px;
                }
              }

              .original {
                display: flex;
                margin-top: 14px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;

                span {
                  width: 38px;
                }

                h3 {
                  max-width: 860px;
                  overflow: hidden; // 溢出隐藏
                  white-space: nowrap; // 强制一行
                  text-overflow: ellipsis; // 文字溢出显示省略号
                }
              }
            }

            h3 {
              font-size: 14px;
              font-weight: normal;
            }
          }

          .left-bottom {
            span {
              color: #9c2526;
              font-size: 12px;
            }

            .el-tag {
              margin-left: 10px;
              background-color: transparent;
              border-radius: 10px;
            }
          }
        }

        .right {
          .el-button {
            color: #9c2526;
            border: 1px solid #9c2526;
          }

          .rightBottom {
            text-align: center;
            margin-top: 10px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}

.directionBox {
  width: 80px;
  position: absolute;
  left: 50%;
  bottom: -100%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity .3s;

  .direction {
    display: block;
    width: 0px;
    height: 1px;
    // background-color: green;
    border: 5px solid transparent;
    border-bottom: 7.5px solid #9C2526;
    margin: 0 auto;
  }

  .dirInfo {

    width: 100px;
    height: 25px;
    background-color: #9C2526;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    line-height: 25px;
  }
}
</style>
