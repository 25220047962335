<template>
  <div>
    <div class="header">
      <signagure-head></signagure-head>
    </div>
    <div class="content">
      <div class="alertImg">
        <img src="../../assets/images/home/feedback.png" alt="">
      </div>

      <h3>温馨提示</h3>
      <p>
        如果您在认领中遇到问题，可进行意见反馈，我们收到信息会及时给您反馈。
      </p>
      <div class="phone">
        <img class="phoneIcon" src="../../assets/images/home/phone.png" />
        <span class="phonenum">010-62519375</span>
      </div>
      <div class="feedBackInfo">
        <h3>反馈与建议</h3>
        <el-input class='textFeedBack' maxlength='200' show-word-limit type="textarea" placeholder="您填写的信息越全，问题越有效解决哟~"
          v-model="textarea">
        </el-input>
        <div class="inputEls">
          <el-form label-position="left" label-width="70px" :model="form">
            <el-form-item label="联系人:">
              <el-input v-model="form.name" maxlength='20' placeholder='请输入联系人'></el-input>
            </el-form-item>
            <el-form-item label="手机号码:">
              <el-input maxlength='11' v-model="form.phone" type="number" placeholder='请输入联系人手机号码'></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary" @click='handleFeedBack'>提交反馈</el-button>
        <el-button @click='$router.go(-1)' style='color: #9c2526; margin: 10px 0;'>返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import signagureHead from '@/components/head/signagureHead'
export default {
  name: 'SignaturesFeedBack',
  components: {
    signagureHead
  },
  data() {
    return {
      form: {
        name: '',
        phone: ''

      },
      textarea: ''
    }
  },
  mounted() {
    document.title = '意见反馈' + this.$route.meta.title
  },
  watch: {
    // 限制手机号只能输入11位
    "form.phone"(value, oldValue) {
      this.form.phone = value.slice(0, 11)
    },
  },
  methods: {
    async handleFeedBack() {
      const res = await this.$http({
        url: '/claimApplet/saveAdvice',
        method: 'POST',
        data: {
          username: this.form.name,
          phone: this.form.phone,
          problemDesc: this.textarea,
          type: '1'
        }
      })
      console.log(res)
      if (res.data.code !== 200) {
        this.$message({
          type: 'warning',
          message: res.data.msg
        })
      } else {
        this.$message({
          type: 'success',
          message: res.data.msg
        })
        this.$router.go(-1)
      }
      // console.log(res)
    }
  }
}
</script>
<style lang='less' scoped>
/deep/input::-webkit-outer-spin-button,
/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/input[type='number'] {
  line-height: 1;
  -moz-appearance: textfield !important;
}
.content {
  height: 714px;
  width: 600px;
  margin: 20px auto;

  .alertImg {
    width: 162px;
    height: 162px;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  h3 {
    margin-top: 21px;
    text-align: center;
    color: #9C2526;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    text-align: center;
  }

  p {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 550;
    color: #666666;
    line-height: 24px;
    text-indent: 2rem;
    margin-top: 20px;
  }

  .phone {
    width: 600px;
    height: 40px;
    background: #F7F7F7;
    border-radius: 4px;
    line-height: 40px;
    margin-top: 10px;

    .phoneIcon {
      margin-left: 10px;
      margin-right: 9px;
      width: 16px;
      height: 16px;
      line-height: 40px;
    }

    .phonenum {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FF7100;
      line-height: 40px;
    }
  }

  .feedBackInfo {
    h3 {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #000000;
      text-align: left;
      margin-bottom: 10px;
    }

    .textFeedBack {
      width: 600px;
      height: 200px;
      // margin-top: (20/100rem);
      background-color: #F7F7F7;
      border-radius: 10px;
      overflow: hidden;

      /deep/.el-textarea__inner {
        width: 100% !important;
        height: 100% !important;
        background-color: #F7F7F7;
        border: none;
        outline: none;
      }
    }
  }
}

/deep/.el-form-item__label {
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  padding: 0;
}

.inputEls {
  margin-top: 20px;
}

/deep/.el-input__inner {
  background-color: #F7F7F7;
  outline: none;
  border: none;
}

.el-button {
  transform: translateX(68px);
  margin-top: 24px;
  width: 460px;
  height: 50px;
  // background: #9C2526;
  border-radius: 25px;
}</style>
