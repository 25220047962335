<template>
  <div class="lefttreeBox">
    <div class="lefttreeBoxtop">
      <div class="discipline">
        <p class="disciplineTitle">{{ mainlefttree.title }}</p>
        <div class="handleBtn">
          <el-button class='topBtn' @click='handleCheckAll' size="mini">全选</el-button>
          <el-button class='topBtn' @click='handleCheckClear' size="mini">清除</el-button>
        </div>
      </div>
      <div style="width:100%;overflow:scroll" id="myElement">
        <el-tree :render-after-expand='false' highlight-current class="treeBox" :data="treedata" show-checkbox
          node-key="code" ref="tree" :props="defaultProps" @check-change="handleCheckChange"
          :default-checked-keys='secondselecterTree'>
          <span class="custom-tree-node ischecked" slot-scope="{node,data}">
            <span v-if='data.parentCode === null' ref='treeNodeLable' :class='{ "ischecked": data.checked }'
              class='treeNode' :title='data.name + "(" + data.peoplenum + "人)"'>{{
                data.name
              }}</span>
            <span v-else ref='treeNodeLable' :class='{ "ischecked": data.checked }' class='childNode'
              :title='data.name + "(" + data.peoplenum + "人)"'>{{
                data.name
              }}</span>
            <span v-if='data.parentCode === null' :title='data.peoplenum' :class='{ "ischecked": data.checked }'
              class="treecount">({{ data.peoplenum }}人)</span>
            <span v-else :title='data.peoplenum' :class='{ "ischecked": data.checked }' class="childNode">({{
              data.peoplenum
            }}人)</span>
          </span>
        </el-tree>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  props: ['mainlefttree'],
  data () {
    return {
      subjectCount: 0,
      checkAllCount: 0,
      checkedAll: false,
      firstselectedTree: [], // 左侧选中的一级学科code列表
      page: 1, // 页码
      pageRow: 10, // 每页显示的条目数
      total: 0,
      secondselecterTree: [], // 左侧选中的二级学科code列表
      treedata: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      bigSearch: '',
      discSearch: '',
      isExpand: false
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {

    // 全选
    handleCheckAll () {
      this.checkedAll = true
      this.checkAllCount = 0
      this.$refs.tree.setCheckedNodes(this.treedata)
    },
    // 取消全选
    handleCheckClear () {
      this.checkedAll = true
      this.checkAllCount = 0
      this.$refs.tree.setCheckedKeys([])
      this.isExpand = false
      for (let i = 0; i < this.treedata.length; i++) {
        this.$refs.tree.store.nodesMap[this.treedata[i].code].expanded = this.isExpand
      }
      this.firstselectedTree = []
      this.secondselecterTree = []
      this.getDatatree()
    },
    handleCheckChange (data, checked) {
      if (this.checkedAll === false) {
        // this.$refs.treeNodeLable.style.color = 'red'
        data.checked = checked
        // 选中值包含父级
        const includeparent = this.$refs.tree.getCheckedNodes(false, true)
        // 选中值不包含父元素==全部选中二级
        const nocludeparent = this.$refs.tree.getCheckedNodes(true)
        // 需要将所有选中的二级值的id放到数组中
        this.secondselecterTree = nocludeparent.map(item => item.code)
        // 存值

        this.firstselectedTree = nocludeparent.map(item => {
          return item.code
        })

        // 选中值只有父元素
        const parentArrar = includeparent.filter(
          obj => !nocludeparent.includes(obj)
        )
        this.firstselectedTree = parentArrar.map(item => {
          return item.code
        })
        // 存值
        this.$store.commit('secondauthor', this.secondselecterTree)
        this.$store.commit('firstauthor', this.firstselectedTree)
      } else {
        data.checked = checked
        // 全选
        this.checkAllCount++

        if (this.checkAllCount === 1) {
          this.subjectCount = 0
          this.firstselectedTree = []
          this.secondselecterTree = []
          this.treedata.forEach(item => {
            // this.firstselectedTree.push(item.code)
            this.subjectCount++
            if (item.children.length > 0) {
              for (let i = 0; i < item.children.length; i++) {
                // this.secondselecterTree.push(item.children[i].code)
                this.subjectCount++
              }
            }
          })
          // 存值
          this.$store.commit('secondauthor', this.secondselecterTree)
          this.$store.commit('firstauthor', this.firstselectedTree)
          this.checkedAll = false
          this.checkAllCount = 0
        }
        if (this.checkAllCount === this.subjectCount) {
          this.checkedAll = false
        }
      }

      // this.getDatatree()
    },
    // 获取详情接口
    async getDatatree () {
      const { data: res } = await this.$http({
        url: this.mainlefttree.url,
        method: 'post',
        data: {
          firstLevelList: [],
          page: this.page,
          pageRow: this.pageRow,
          secondLevelList: [],
          bigSearch: this.bigSearch,
          discSearch: this.discSearch
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.treedata = res.data
      const element = document.querySelector('#myElement')
      const x = res.data.length
      var height = 39 * x
      element.style.maxHeight = height + 'px'
    }
  },
  watch: {
    // 监听热词
    '$store.state.hotwordsearch.timestamp': {
      deep: true,
      // immediate: true,
      handler (data, oldData) {
        console.log(data)
        this.bigSearch = this.$store.state.hotwordsearch.search
        const searchEl = document.querySelector('.authorSearchBoxEl')
        console.log('获取', searchEl.children[0].value)
        this.discSearch = searchEl.children[0].value
        // console.log('hhhhh', this.discSearch)
        this.getDatatree()
      }
    },
    '$store.state.secondauthor': {
      deep: true,
      // immediate: true,
      handler (data) {
        this.secondselecterTree = data
      }
    },
    '$store.state.firstauthor': {
      deep: true,
      handler (data) {
        this.firstselectedTree = data
      }
    },
    '$store.state.authorSearchContent.time': {
      deep: true,
      // immediate: true,
      handler (newData, oldData) {
        this.discSearch = this.$store.state.authorSearchContent.search
        if (JSON.stringify(newData) !== JSON.stringify(oldData) && oldData !== undefined) {
          console.log('走这里')
          this.getDatatree()
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
.custom-tree-node {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.lefttreeBox {
  width: 300px;
  // height: 35.625vw;

  .lefttreeBoxtop {
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;

    .discipline {
      display: flex;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
      width: 100%;
      height: 60px;
      background: #f5f5f5;
      border-radius: 10px 10px 0 0;
      display: flex;
      justify-content: space-between;

      .handleBtn {
        .topBtn {
          background-color: transparent;
          margin-left: 0;
          margin-right: 5px;
          width: 37px;
          height: 21px;
          background: #F5F5F5;
          border: 1px solid #CCCCCC;
          border-radius: 4px;
          text-align: center;
          // font-size: 12px;
          padding: 0;

          /deep/span {
            font-size: 12px;
          }
        }
      }

      @media screen and (min-width: 1600px) {
        .disciplineTitle {
          font-size: 22px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // 1300~1600

      @media screen and (max-width: 1600px) and (min-width: 1300px) {
        .disciplineTitle {
          font-size: 20px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // 小于1300
      @media screen and (max-width: 1300px) {
        .disciplineTitle {
          font-size: 18px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // .selectAllBox {
      //   .selectAllBtn,
      //   .selectemptyBtn {
      //     text-align: center;
      //     width: 48px;
      //     height: 24px;
      //     background: #f5f5f5;
      //     border: 1px solid #e0e0e0;
      //     border-radius: 4px;
      //     padding: 0;
      //     > span {
      //       color: #666666;
      //     }
      //   }
      //   .selectemptyBtn {
      //   }
      // }
    }

    /deep/.el-tree {
      width: 100%;
      // min-height: 35.625vw;
      background: #ffffff;
      border-radius: 0 0 10px 10px;
      padding: 18px 20px 0 20px;
      box-sizing: border-box;
      // height: 32.5vw;
    }

    .treecount {
      margin-left: 11px;
      font-size: 14px;
      color: #666;
    }
  }

  .selectzb {
    width: 300px;
    cursor: pointer;
  }

  // .selectzb {
  //   cursor: pointer;
  //   text-align: center;
  //   width: 5.2083vw;
  //   height: 2.0833vw;
  //   line-height: 2.0833vw;
  //   background: #9c2526;
  //   border-radius: .2083vw;

  //   .selectzbIcon {
  //     display: inline-block;
  //     // background: url("../../assets/images/home/");
  //   }

  //   .selectzbTxt {
  //     font-size: .7292vw;
  //     color: #ffffff;
  //   }
  // }
}

/deep/.el-tree-node__content {
  margin-bottom: 14px;
}

// /deep/ .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
//   // 设置颜色
//   background-color: orange !important;
// }
// /deep/ .el-tree-node:focus>.el-tree-node__content {
//   color: #9c2526 !important;
// }
// .el-tree-node.is-current>.el-tree-node__content {
//   background-color: red !important;
// }
.treeNode {
  font-size: 18px;
  font-weight: 500;
  color: #1A1A1A;
}

.childNode {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  // margin: 0;
}

.treeNode.ischecked {
  color: #9c2526 !important;
}

.childNode.ischecked {
  color: #9c2526;
}

.treecount.ischecked {
  color: #9c2526;
}

.el-icon-document {
  color: #fff;
}

//一级节点选择器
/deep/ .el-tree>.el-tree-node>.el-tree-node__content {
  margin-bottom: 20px;
  height: 17px;
}

//二级节点选择器
/deep/ .el-tree>.el-tree-node>.el-tree-node__children>.el-tree-node>.el-tree-node__content {
  margin-bottom: 15px;
  // color: #666666;
  height: 14px;
  padding-left: 8px !important;
}
</style>
