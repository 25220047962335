<template>
  <div>
    <div class="header">
      <signagureHead></signagureHead>
    </div>
    <img src="../../assets/images/home/claimsuccess.png" class="titleimage" alt="">
    <div class="title">恭喜完成文献授权</div>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;复印报刊资料数据库知名专家账号（使用期一年）将于平台上线后启用，届时会再次为您发送短信提醒。
      <!-- 尊敬的作者：<br />
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您已完成合同签署。如果您在2013年-2022年间有更优秀的代表主文献替换，您可点击下方链接登录系统替换所认领的主文献。<br />
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;感谢您的鼎力支持！ -->
    </p>
    <div class='bottomBtn'>
      <!-- <el-button type="primary" @click='handleLookContract'>查看合同</el-button> -->
      <el-button type="primary" v-if="userCode" @click='handleList'>查看认领的文献</el-button>
      <el-button class='lookMine' type="primary" @click='handleMain'>前往主文献平台</el-button>
    </div>
    <!-- <div class="linkbox">
      <div class="linktitle">哲学社会科学主文献：</div>
      <div class="link">https://zwx.tianguogong.com:8082/literatureServer/page/index.html#/main/index.html</div>
    </div> -->
  </div>
</template>
<script>
import signagureHead from '@/components/head/signagureHead'
export default {
  name: 'signatureLogin',
  components: {
    signagureHead
  },
  data() {
    return {
      userCode: this.$store.state.userCodeh5 || localStorage.getItem('userCodeh5')
    }
  },
  mounted() {
    document.title = '主文献-传播学术精品'
    if (this._isMobile() && this.BASE_URL != '/api/') {
      window.location.href = this.BASE_URL + 'mobile/index.html#/success'
      this.loading = false
    } else {
      this.loading = false
    }
    // this.handleSuccess()
  },
  methods: {
    // 查看认领文献
    handleList() {
      this.$router.push('/signaturesLookList')
    },
    handleMain() {
      const routeData = this.$router.resolve({
        path: '/main'
        // query: params
      })
      window.open(routeData.href, '_blank')
      // const res = await this.$http({
      //   url: ''
      // })
    },
    _isMobile() {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    async handleLookContract() {
      console.log(this.userCode)
      const res = await this.$http({
        url: `/anxinsign/view?userCode=${this.userCode}`,
        method: 'GET'
      })
      if (res.data.code !== 200) {
        return this.$message({
          type: 'warning',
          message: res.data.msg
        })
      }
      // const url = res.data.data.replace('111.198.56.79:28082', '192.168.2.159:8888')
      console.log(res.data.data)
      window.open(res.data.data)
      // console.log(res.data.data)
    }
    // async handleSuccess () {
    //   const res = await this.$http({
    //     url: '/claimApplet/updateAuthorSign',
    //     method: 'POST',
    //     data: {
    //       userCode: this.userCode
    //     }
    //   })
    //   console.log(res)
    // }
  }
}
</script>
<style lang='less' scoped>
.titleimage {
  width: 162px;
  height: 162px;
  margin-left: 879px;
  margin-top: 111px;
}

.title {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #9C2526;
}

p {
  margin-top: 72px;
  width: 619px;
  margin-left: 653px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  line-height: 24px;
}

.linkbox {
  width: 600px;
  height: 50px;
  background: #FFEEE0;
  border-radius: 2px;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;

  .linktitle {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FF7100;
    width: 145px;
    margin-top: 10px;
    margin-left: 20px;
  }

  .link {
    width: 419px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FF7100;
    align-self: center;
    word-wrap: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
}

.bottomBtn {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .el-button {
    width: 460px;
    height: 50px;
    margin: 0;
    border-radius: 25px;
  }

  .lookMine {
    margin-top: 20px;
    background-color: #fff;
    color: #9C2526;
  }
}
</style>
