<template>
  <div class="main">
    <img class="left" src="../../assets/images/main/maintenance/left.png" alt />
    <div class="right">
      <img src="../../assets/images/main/maintenance/logo.png" class="logo" alt />
      <div class="title">系统升级中...</div>
      <div class="text" style="margin-top: 39px">亲爱的用户:</div>
      <div
        class="text"
        style="text-indent: 2em"
      >为了更好的提供学术服务，我们正在对平台进行升级，升级期间暂时无法访问。本次计划升级时间：{{ startTime }}至{{ endTime }}。</div>
      <div class="text" style="text-indent: 2em">给您带来的不便，敬请谅解!</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      startTime: "",
      endTime: ""
    };
  },
  methods: {},
  mounted() {
    document.title = "系统升级中";
    var sh = this.START_TIME.getHours()
    var sm = this.START_TIME.getMinutes();
    var eh = this.END_TIME.getHours();
    var em = this.END_TIME.getMinutes();
    sh = sh < 10 ? "0" + sh : sh;
    sm = sm < 10 ? "0" + sm : sm;

    eh = eh < 10 ? "0" + eh : eh;
    em = em < 10 ? "0" + em : em;
    this.startTime = sh + "时" + sm + "分";
    this.endTime = eh + "时" + em + "分";
  }
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  padding: 20vh 300px 0 410px;

  .left {
    width: 547px;
    height: 361px;
  }

  .right {
    margin-left: 61px;

    .logo {
      margin-left: 162px;
      margin-top: 34px;
      width: 275px;
      height: 58px;
    }

    .title {
      margin-top: 62px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 28px;
      color: #9c2526;
    }

    .text {
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      line-height: 40px;
    }
  }
}
</style>
