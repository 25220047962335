<template>
  <div>
    <div class="content">
      <div class="container">
        <div class="containerImg"></div>
        <div class="copyBox">
          <div class="copyTitle">
            <h3>哲学社会科学主文献平台常见问题解答</h3>
            <span class="copyLine"></span>
          </div>
          <div class="copyInfo">
            <div>
              <div>
                <div class="title">一、 平台功用</div>
                <div style="margin-top: 0.20833rem;">
                  <div class="title2">1. 主文献和哲学社会科学主文献平台是什么？</div>
                  <div class="text">
                    <p>主文献（Classic Literature）是指各学科领域中主流、经典、必读的学术文献。</p>
                    <p>
                      哲学社会科学主文献平台（以下简称“哲社主文献”）是一个高效研读学术精品的指南，辅助学习、教学、科研的工具。该平台是中国特色哲学社会科学自主知识体系数字创新平台（简称“学术世界”）的子平台，由中国人民大学书报资料中心具体承建。
                    </p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">2. “哲社主文献”有什么价值？</div>
                  <div class="text">
                    <p style="text-indent: 0;">
                      （1） 文献精选价值，从海量文献中去粗取精、去伪存真、层层筛选、沉淀精华；
                      <!-- <br />（2） 导向引领价值，立足中国式现代化发展中的问题和需求，倡导知识创新，服务中国哲学社会科学自主知识体系建构； -->
                      <!-- <br />（3） 学术评价价值，依靠并发挥学术共同体作用，为 “破五唯、立新标”的学术评价改革开辟新的路径； -->
                      <br />（2） 人才培养价值，既传授主流、权威知识，又确保学生的学习效果和效率，还培养学术规范和独立研究能力；
                      <br />（3） 知识传播价值，专注于文献精选和知识精加工，为精华知识提供了高效精准的传播渠道，加速权威知识传播；
                      <br />（4） 学术评价价值，充分发挥学术共同体作用，提供了新的学术评价维度，遴选结果为各类管理和评估活动提供新的参考依据；
                      <br />（5） 导向引领价值，关注中国发展中的实际问题和需求，强调中国特色的理论框架和方法，突出中国学者和机构的学术贡献，为建构中国哲学社会科学自主知识体系发挥导向引领作用；
                      <br />（6） 原创助力价值，为后续研究树立原创标杆、提供原创启发，激发原创动力。
                      <br />
                    </p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">3. “哲社主文献”还有哪些功能？</div>
                  <div class="text">
                    <p>
                      “哲社主文献”是一个全面、权威、开放、动态的知识发现平台，主要提供以下功能与服务：（1）提供主文献的检索、导航和全文阅读功能；（2）对文献知识元素进行精加工，并开发相关知识服务和数据智能挖掘功能；（3）提炼分析文献中的知识点，为学术科研提供知识分析、知识体系建构等辅助服务。
                    </p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">4. “哲社主文献”是否拟推出面向基地、硕博点教学一线的教辅功能？</div>
                  <div class="text">
                    <p>辅助教学科研是“哲社主文献”的主要应用价值之一，后续将逐步推广“哲社主文献”在教学科研等场景中的应用。</p>
                  </div>
                </div>
              </div>
              <div style="margin-top: 0.20833rem">
                <div class="title">二、 遴选范围</div>
                <div style="margin-top: 0.20833rem;">
                  <div class="title2">5. “哲社主文献”一期遴选范围是什么？</div>
                  <div class="text">
                    <p>
                      “哲社主文献”一期的遴选范围是 2013—2022 年间公开发表的约 400
                      万篇哲学社会科学学术论文，覆盖所有一级、二级学科。通过大数据筛选，参考复印报刊资料和《新华文摘》等二次文献、各大学术数据库高被引论文、高等学校科学研究优秀成果奖（人文社会科学）获奖论文等，并通过专家推荐、机构调研等形式广泛征集学界意见，遴选出约
                      14 万篇初始备选文献，最终由各学科主文献评审组遴选出约 2 万篇主文献入库。
                    </p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">6. “哲社主文献”是否会回溯2012年之前的论文？</div>
                  <div class="text">
                    <p>根据发展计划，“哲社主文献”拟按学术发展史，逐步回溯2012年之前的论文文献。</p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">7. “哲社主文献”是否只包含论文，之后是否将继续扩大遴选来源范围，使之包括专著、外文文献？</div>
                  <div class="text">
                    <p>“哲社主文献”一期建设重点遴选学术论文精品，之后将扩大遴选范围，不仅包括学术论文，也会纳入专著。是否包括外文文献，后续将进一步研究再定。</p>
                  </div>
                </div>
              </div>
              <div style="margin-top: 0.20833rem">
                <div class="title">三、 遴选标准</div>
                <div style="margin-top: 0.20833rem;">
                  <div class="title2">8. “哲社主文献”的遴选标准是什么？</div>
                  <div class="text">
                    <p>
                      按照主流、经典、必读的要求，综合考量政治立场、学术诚信、理论创新、知识贡献、学科影响、实践引领等方面，根据各学科特点分别形成各学科遴选主文献的基本标准。各学科遴选标准已公布在“哲社主文献”主页面学科导航下方“遴选标准”中。
                    </p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">9. 哪些文献不被纳入“哲社主文献”遴选范围？</div>
                  <div class="text">
                    <p>涉及以下情况的文献不作为遴选对象：政治导向错误的；学术不端的；介绍性、综述性、时效性强的；政策、法规解读分析类（尤其是已修改、废止的）；党政机关文件、领导人讲话等。</p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">10. “哲社主文献”遴选是否必须刊登在核心期刊上？</div>
                  <div class="text">
                    <p>“哲社主文献”遴选摈弃“以刊评文”，强调“不问出身、以文评文”，按照论文质量而非所载期刊级别或作者影响力来评选。</p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">11. 为什么某些机构的主文献入选篇数较多？</div>
                  <div class="text">
                    <p>
                      作者所属机构并非主文献遴选的参考标准之一，“哲社主文献”所呈现的作者机构入选主文献数量只是对遴选结果的客观呈现。从结果分布看，各机构的主文献入选情况与其核心期刊发文情况、学科评估水平等其他评价体系所反映的数据规律基本一致。
                    </p>
                  </div>
                </div>
              </div>
              <div style="margin-top: 0.20833rem">
                <div class="title">四、 遴选机制</div>
                <div style="margin-top: 0.20833rem;">
                  <div class="title2">12. “哲社主文献”的学科分类依据是什么？</div>
                  <div class="text">
                    <p>
                      依据国务院学位委员会、教育部发布的《研究生教育学科专业目录（2022 年）》并结合遴选实际需要设置主文献学科分类方案，共分为 25 个一级学科、107
                      个二级学科开展分学科遴选。遴选结果也按此学科分类体系展示，详见各学科主文献遴选标准页面。
                    </p>
                  </div>
                </div>
                <div style="margin-top:  0.15104rem">
                  <div class="title2">13. “哲社主文献”的基本遴选方式是什么？</div>
                  <div class="text">
                    <p>
                      “哲社主文献”遴选邀请各学科领域的权威专家参与评审，围绕政治立场、学术诚信、理论创新、知识贡献、学科影响、实践引领等主要遴选标准，由各学科学术共同体分别构建评价指标体系。以哲学社会科学所有学科公开发表的学术成果作为遴选对象，进行全量化大数据遴选，通过层层优选将学术文献按质量分为主文献、优质文献、扩展参考文献和一般文献四级，最终形成“以全为基础、以精为特征”的主文献体系。其中，主文献处于最高层级，占所有学术文献数量的比例不超过
                      1%。
                    </p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">14. “哲社主文献”遴选涉及的专家范围和评审周期是怎样的？</div>
                  <div class="text">
                    <p>
                      2023年4月-2024年1月，共邀请全国范围内 1300 余名专家组成各学科主文献评审组，分别进行了通讯评审、会议评审、终审评定，共遴选出约
                      2万篇文献进入“哲社主文献”一期数据库。二期将适当拓展专家类别和范围，并酌情与各学科学会开展合作。
                    </p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">15. “哲社主文献”遴选将来是否参考大众意见？</div>
                  <div class="text">
                    <p>
                      “哲社主文献”一期遴选与部分作者进行了互动交流，二期遴选将考虑扩大遴选意见的征集范围，调动学术共同体广泛参与围绕主文献的学术交流互动，充分发挥学术共同体在“哲社主文献”评选和文献资源开发利用中的作用。目前学者荐稿功能已在开发中，但评价主体仍以各学科专家为主。
                    </p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">16. “哲社主文献”遴选是否会建立动态调整机制？</div>
                  <div class="text">
                    <p>“哲社主文献”将根据不同学科领域的特点，每 1—3 年进行一次主文献动态调整。如在运行过程中发现个别已入选主文献需要调整的特殊情况，也会及时研讨和调整。</p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">17. “哲社主文献”遴选是如何分学科评审的？</div>
                  <div class="text">
                    <p>“哲社主文献”一期遴选主要以一级学科进行分类遴选，基于学科特性决定是否细分到二级学科进行评审。二期遴选将酌情关注并优化学科细分问题，以及交叉学科和新兴学科的评审。</p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">18. “哲社主文献”一期遴选对同一作者入选主文献的论文数量有无控制？</div>
                  <div class="text">
                    <p>无严格统一标准控制同一作者论文数量，遴选时主要依据论文质量，必要时也会征求作者本人的意见。</p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">19. “哲社主文献”是否会提供专家在线评审功能？</div>
                  <div class="text">
                    <p>正在开发“哲社主文献”的线上专家评审功能，拟于二期正式启用并引入更多类别人员参与到评审中。</p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">20. “哲社主文献”是否借助算法和大数据手段？</div>
                  <div class="text">
                    <p>“哲社主文献”已设计了自然评价模式，基于算法识别优秀文献，并提供大数据支撑专家评审，之后将持续推进落地应用。</p>
                  </div>
                </div>
              </div>
              <div style="margin-top: 0.20833rem">
                <div class="title">五、 其他</div>
                <div style="margin-top: 0.20833rem;">
                  <div class="title2">21. 用户是否可以围绕主文献进行学术交流与互动？</div>
                  <div class="text">
                    <p>目前“哲社主文献”已开设主文献论文、年度十大学术热点、中国原创学术概念示例、热门专题的评论功能，欢迎广大读者学者参与讨论与交流。其他模块功能后续将逐步尝试。</p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">22. 如果发现“哲社主文献”个别文献内容有差错，该如何反馈？</div>
                  <div class="text">
                    <p>
                      “哲社主文献”获取文献的原始版本目前有极少数存在瑕疵，平台方正在陆续修正。如果作者或用户发现某篇文献内容有差错，请及时与平台方联系。平台配置了信息纠正和意见反馈功能，收到用户提交的意见后会及时处理。
                    </p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">23. 为什么“哲社主文献”有些论文目前不能下载和在线阅读？</div>
                  <div class="text">
                    <p>
                      “哲社主文献”尊重著作权，所有入选文献均联系作者进行认领、授权。已获作者认领、授权的入选论文，可在平台下载或在线阅读全文；作者尚未认领、授权的入选论文，暂缓显示全文。个别已认领论文还在收集获取中，获取后将开启下载和在线阅读功能。
                    </p>
                  </div>
                </div>
                <div style="margin-top: 0.15104rem">
                  <div class="title2">24. 用户如何与平台方取得联系？</div>
                  <div class="text">
                    <p>可点击“哲社主文献”的“意见反馈”按钮提出意见，也可通过首页下方邮箱、电话与我们取得联系。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div style="text-align: center;font-size: 20px;margin-top: 20px;">知识产权声明</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  mounted () {
    document.title = '常见问题解答' + this.$route.meta.title
  }
}
</script>
<style lang="less" scoped>
.container {
  position: relative;
  margin-bottom: 3800px;

  .containerImg {
    height: 500px;
    background-image: url("../../assets/images/head/answer.png");
    background-position: center center;
    background-size: cover;
  }

  .copyBox {
    position: absolute;
    transform: translate(-50%, -0%);
    left: 50%;
    top: 30%;

    .copyInfo {
      width: 1440px;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
      border-radius: 10px;

      // bottom: -100%;
      padding: 75px 77px;
      box-sizing: border-box;

      .title {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #9c2526;
        line-height: 24px;
        text-align: center;
      }

      .title2 {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        line-height: 24px;
      }

      .text {
        margin-top: 30px;

        p {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 32px;
          text-indent: 2em;
        }
      }
    }

    .copyTitle {
      margin-bottom: 60px;
      text-align: center;

      h3 {
        // width: 220px;
        height: 38px;
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        letter-spacing: 15px;
        color: #333333;
      }

      .copyLine {
        margin-top: 27px;
        display: inline-block;
        width: 89px;
        height: 7px;
        background-image: url("../../assets/images/home/Dot 01.png");
        background-position: center center;
        background-size: contain; // // background: #9C2526;
        // transform: translate(40%);
        // color: #9c2526;
        // font-size: 40px;
      }
    }
  }
}

// .bottom {
//   position: absolute;
//   bottom: 0;
//   left: 50%;
//   transform: translate(-50%);
// }

// .bottom {
//   width: 1920px;
//   // position: absolute;
//   // bottom: 0;
//   // left: 50%;
//   // transform: translate(-50%);
// }</style>
