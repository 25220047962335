<template>
  <!-- 学科专题 -->
  <div class="content">
    <div style="background: #F7F7F7;padding-bottom: 8px;">
      <img src="../../assets/images/home/discipline2.png" width="100%" alt="">
      <disciplineListHead></disciplineListHead>
    </div>
    <div class="disciplineList">
      <div class="disciplineListLeft">
        <disciplineTree :mainlefttree="mainlefttree" :searchInfo='searchInfo'></disciplineTree>
      </div>
      <div class="disciplineListRight">
        <disciplineListRight @discip='discip' @disciplieSearchInfo='disciplieSearchInfo'></disciplineListRight>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import disciplineTree from '../../components/main/disciplineTree.vue'
import disciplineListRight from '../../components/main/disciplineListRight'
import disciplineListHead from '../../components/main/disciplineListHead.vue'
export default {
  components: { disciplineListRight, disciplineListHead, disciplineTree },
  data () {
    return {
      mainlefttree: {
        url: '/topicList/getDisc',
        title: '学科领域'
      },
      imgList: '',
      searchInfo: ''
    }
  },
  mounted () {
    document.title = '热门专题' + this.$route.meta.title
  },
  methods: {
    disciplieSearchInfo (data) {
      this.searchInfo = data
    },
    discip (val) {
      this.imgList = val
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  // max-height: 1600px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.headBannd {
  // width: 100vw;
  height: 120px;
  margin: 0;
  padding: 0;

  img {
    width: 100%;
    height: 120px;
  }
}

.imgBanner {
  // width: 100vw;
  width: 1440px;
  height: 530px;
  // background-color: orange;
  margin: 0 auto;
  // position: relative
  position: relative;
  overflow: hidden;

  /*滚动区 包裹所有图片的盒子 宽度要足够宽可以放下所有的图片*/
  .images {
    position: absolute;
    top: 0;
    left: 0;
    width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-shrink: 0;
  }

  /* 对图片使用浮动 使其排列在一行*/
  .images li {
    // float: left;

    width: 322px;
    height: 270px;
    background-color: green;
    margin-right: 11px;

    img {
      width: 302px;
      height: 150px;
    }
  }

  /* 图片大小会撑大盒子,设置图片大小 */
  // .banners img {
  //   width: 520px;
  //   height: 280px;
  // }

  /*左右箭头相同样式*/
  .prev,
  .next {
    display: block;
    position: absolute;
    /* 绝对定位的盒子垂直居中 */
    top: 50%;
    margin-top: -15px;
    width: 20px;
    height: 30px;
    background: rgba(0, 0, 0, .3);
    text-align: center;
    line-height: 30px;
    color: #fff;
    text-decoration: none;
  }

  /*左箭头靠左定位*/
  .prev {
    left: 0;
    /* 设置圆角 */
    background-color: red;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    z-index: 9999;
  }

  /*右箭头靠右定位*/
  .next {
    right: 0;
    /* 设置圆角 */
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  /* 小圆点外层盒子的样式 */
  .dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -35px;
    height: 13px;
    background: rgba(255, 255, 255, .3);
    border-radius: 7px;
  }

  /* 小圆点样式*/
  .dots li {
    float: left;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    margin: 3px;
  }

  /* 小圆点被点击的样式 */
  .dots .active {
    background-color: #ff5000;
  }

  // .el-carousel-item {
  //   width: 100px !important;
  //   height: 100px;
  // }
}

.disciplineList {
  width: 1440px;
  margin: 20px auto;
  display: flex;
  align-items: flex-start;

  .disciplineListLeft {
    width: 300px;
  }

  .disciplineListRight {
    flex: 1;
    padding: 0 20px;
    box-sizing: border-box;
  }
}

.swiper-button-next,
.swiper-button-prev {
  content: "";
  width: 48px;
  height: 48px;
  border-radius: 6px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-next {
  top: 40%;
  right: 12%;
  background-image: url("../../assets/images/head/rightjt.jpg");
  background-size: cover;
  /* 设置左箭头图标 */
}

.swiper-button-prev {
  top: 40%;
  left: 12%;
  background-image: url("../../assets/images/head/leftjt.jpg");
  background-size: cover;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
}

@media screen and (min-width: 1600px) {}

// 1300~1600
@media screen and (max-width: 1600px) and (min-width: 1300px) {
  .swiper-slide {
    height: 340px;
    position: relative;
  }

  .imgcenter {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    // width: 100% !important;
    // margin-left: calc(-1920px / 2);
  }
}

// 小于1300
@media screen and (max-width: 1300px) {
  .swiper-slide {
    height: 340px;
    position: relative;
  }

  .imgcenter {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    // margin-left: calc(-1920px / 2);
  }
}

.disciplineListRight {
  min-height: 800px;
}
</style>
