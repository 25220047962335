<template>
  <div class="sidelistBox">
    <div class="journalTitle">
      <p>{{ jgsidename.name }}</p>
      <span class="journalTotal">({{ total }}个)</span>
    </div>
    <div style="height:100%;width:100%;overflow:scroll" v-infinite-scroll="load" infinite-scroll-disabled="busy"
      infinite-scroll-immediate='false' infinite-scroll-distance="1">
      <div class="sidelistltgroup">
        <div v-for="(item, index) in institutionSide" :key="index">
          <template v-if='item && item.code !== "to_be_improved"'>
            <div class="sidelistltItem" @click="handleinstitutiondetail(item)">
              <div class="sidelistlt">
                <span class="sidelistindex">{{ index + 1 }}</span>
                <template v-if='item.cover'>
                  <span v-if="item.cover.substr(-4) !== 'null'">
                    <img class="imgmiddle" :src="item.cover" alt />
                  </span>
                  <span v-else>
                    <span class="imgmiddle2" :style="randomRgb()">{{ item.name.substring(0, 1) }}</span>
                  </span>
                </template>
                <span v-if="item.photo">
                  <img class="imgmiddle" :src="item.photoPrefix + item.photo" alt />
                </span>
                <span v-if="!item.cover && !item.photo">
                  <span class="imgmiddle2" :style="randomRgb()">{{ item.name.substring(0, 1) }}</span>
                </span>
              </div>
              <div class="sidelistrt">
                <p class="sidelistrtTitle overflow-dot" v-if="item.name" :title='item.name'>{{
                  item.name }}</p>
                <p class="sidelistrtTitle overflow-dot" v-else :title='item.companyName'>{{
                  item.companyName }}</p>
                <p class="sidelistrtNum">主文献数：{{ item.count }}</p>
              </div>
            </div>
          </template>
          <div class="line"></div>
        </div>
        <div v-if='busy && institutionSide.length > 0' class="busyload">加载中...</div>
        <empty :authorlistSide="institutionSide"></empty>
      </div>
    </div>
  </div>
</template>

<script>
import empty from '../../components/common/emptyside.vue'
// @ is an alias to /src
export default {
  components: { empty },
  props: {
    jgsidename: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      busy: false,
      loading: true,
      showFlag: false,
      institutionSide: [],
      page: '',
      pageRow: '',
      isLoad: false,
      current: 1,
      size: 10,
      total: 0
    }
  },
  mounted () {
    // 当前页
    this.page = this.$route.query.page
    // 每页多少条
    this.pageRow = this.$route.query.pageRow
  },
  methods: {
    load () {
      if (this.total === this.institutionSide.length) {
        return
      }
      this.current++
      this.getinstitutionside()
    },
    randomRgb (item) {
      const colorList = [
        '#f1af10',
        '#f1a871',
        '#23a1b8',
        '#82bcca',
        '#afb4db',
        '#78a355',
        '#df9464',
        '#7bbfea'
      ]
      const index = Math.floor(Math.random() * colorList.length)
      return {
        // backgroundImage: colorList[index]
        backgroundColor: colorList[index]
      }
    },
    // 获取数据
    async getinstitutionside () {
      this.isLoad = true
      // 热词
      if (this.jgsidename.hotWord) {
        const { data: res } = await this.$http({
          url: this.jgsidename.url,
          method: 'POST',
          data: {
            current: this.current,
            size: this.size,
            firstLevelDiscipline: this.jgsidename.firstLevelDiscipline,
            secondLevelDiscipline: this.jgsidename.secondLevelDiscipline,
            hotWord: this.jgsidename.hotWord,
            orgName: this.jgsidename.orgName
          }
        })
        this.isLoad = false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.institutionSide.push(...res.data.representOrgList)
        this.total = res.data.representOrgCount
      } else if (this.jgsidename.keyWord) {
        // 关键词
        const { data: res } = await this.$http({
          url: this.jgsidename.url,
          method: 'POST',
          data: {
            current: this.current,
            size: this.size,
            keyWord: this.jgsidename.keyWord
          }
        })
        this.isLoad = false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.institutionSide.push(...res.data.rows)
        this.total = res.data.total
      } else {
        this.busy = true
        const { data: res } = await this.$http({
          url: this.jgsidename.url,
          method: 'POST',
          data: {
            orgName: this.jgsidename.orgName,
            current: this.current,
            size: this.size
          }
        })
        this.isLoad = false
        if (res.code !== 200) return this.$message.error(res.msg)
        if (res.data.orgListPage) {
          this.institutionSide.push(...res.data.orgListPage)
          this.total = res.data.sum
        } else if (res.data.rows) {
          this.institutionSide.push(...res.data.rows)
          this.total = res.data.total
        }
        this.busy = false
      }
      this.showFlag = true
      this.loading = false
    },
    // 跳转详情
    handleinstitutiondetail (item) {
      if (item.code) {
        const routeData = this.$router.resolve({
          path:
            '/institutiondetail?name=' +
            item.code +
            '&page=' +
            1 +
            '&pageRow=' +
            10
          // query: params
        })
        window.open(routeData.href, '_blank')
      } else {
        const routeData = this.$router.resolve({
          path:
            '/institutiondetail?name=' +
            item.company +
            '&page=' +
            1 +
            '&pageRow=' +
            10
          // query: params
        })
        window.open(routeData.href, '_blank')
      }
    }
  },
  watch: {
    jgsidename: {
      deep: true,
      handler () {
        this.getinstitutionside()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.sidelistBox {
  min-height: 570px;
  padding-top: 20px;
  padding-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;

  .journalTitle {
    margin: 0 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    >p {
      font-size: 18px;
      font-weight: 500;
      color: #9c2526;
    }

    .journalTotal {
      margin-left: 10px;
      font-weight: 600;
      color: @my-color;
    }
  }

  .sidelistltgroup {
    margin-left: 19px;
    margin-right: 20px;
    max-height: 650px;

    .line {
      width: 100%;
      height: 1px;
      background: #e6e6e6;
      border-radius: 1px;
    }

    .sidelistltItem {
      overflow: hidden;
      white-space: nowrap;
      padding: 15px 0;
      display: flex;
      align-items: center;

      .sidelistlt {
        .sidelistindex {
          padding-right: 20px;
        }

        .imgmiddle {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 50%;
        }

        .imgmiddle2 {
          display: inline-block;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          color: #fff;
          font-size: 20px;
          line-height: 60px;
          text-align: center;
          border-radius: 50%;
          object-fit: cover;
          vertical-align: middle;
          font-weight: 500;
        }
      }

      .sidelistrt {
        width: 100%;
        padding-left: 20px;

        .sidelistrtTitle {
          width: 80%;
          font-size: 18px;
          font-weight: 500;
          color: #000000;
        }

        .sidelistrtNum {
          margin-top: 10px;
          border: 1px solid #e6e6e6;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 400;
          color: #9c2526;
          padding: 3px 6px;
          display: inline-block;
        }
      }
    }
  }
}

.busyload {
  height: 60px;
  text-align: center;
  color: #9c2526;
}
</style>
