<template>
  <div
    v-loading="loading"
    element-loading-spinner="none"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <div class="head">
      <signagure-head></signagure-head>
    </div>
    <div class="content">
      <div class="remind">
        <i class="el-icon-warning"></i>
        <span>为您查询到{{ total }}篇主文献</span>
      </div>
      <div class="table">
        <el-table
          max-height="600"
          :data="authorList"
          :cell-style="tableCellStyle"
          width="100%"
          :header-cell-style="tableHead"
          class="elTab"
          ref="tables"
          align="left"
        >
          <el-table-column width="60">
            <template slot="header" slot-scope="scope">
              <el-checkbox
                v-model="checkAll"
                @change="handlerCheckAll"
              ></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.checked"
                @change="handlerCheck"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="序号" width="80">
            <template #default="scope">{{
              (mineCommentListReqDto.page - 1) * 8 + scope.$index + 1
            }}</template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="title"
            label="文献题目"
            align="center"
          ></el-table-column>
          <el-table-column width="120" align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="goDetail(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 19.2px">
          为了增强学术传播影响力，您可将您的作品授权给我们。您可查阅下方的《作品使用同意函》并决定是否授权，<span
            style="font-weight: bold"
            >您点击“确认认领和授权”后，您的文献将在哲学社会科学主文献平台公开</span
          >。
          您理解并知悉，只有作者才可以认领文献并向我们作出授权。如有虚假冒领他人文献的，我们将对相关账号进行封禁惩罚，并保留追究法律责任的全部权利。
        </div>
        <div class="promise">
          <el-checkbox v-model="sure"></el-checkbox>
          <span
            >&nbsp;&nbsp;本人确认是认领和授权文献的真实作者<span
              @click.stop="goToPersonAgree"
              style="color: #9c2526; cursor: pointer"
              >《作品使用同意函》</span
            ></span
          >
        </div>
        <div class="elPagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
            :current-page="mineCommentListReqDto.page"
            :page-size="8"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="handleBtn">
      <el-button type="primary" @click="submit">确认认领和授权</el-button>
    </div>
    <!-- <el-dialog title="认领成功" :visible.sync="centerDialogVisible" width="500px" center>
      <span style="font-size: 12pt;">您已完成文献认领，为了保护您的权益，我们将为您签署一份《作品使用同意函》合同。</span>
      <span slot="footer" class="dialog-footer">
        <span style="color: #9c2526; cursor: pointer;" @click="toSignature">确定</span>
      </span>
    </el-dialog> -->
    <!-- <el-dialog :z-index="999" class="topdialog" title="文献授权" :visible.sync="centerDialogVisible"
      :close-on-click-modal="false" center>
      <p>
        您已完成文献认领，为了增强学术传播影响力，您可将您已认领的作品授权给我们。如您同意授权，您可签署下方的《作品使用同意函》。请您理解，为向您提供电子签署服务，我们需要收集您的个人信息，您可查阅《个人信息使用声明》了解更多。
      </p>
      <div class="phone" style="margin-top: 30px;">
        <img src="../../assets/images/login/usericon.png" alt="">
        <el-input v-model.trim="form.realName" maxlength='20' placeholder="请输入姓名"></el-input>
      </div>
      <div class="phone" style="margin-top: 20px;">
        <img src="../../assets/images/login/idIcon.png" alt="">
        <el-input v-model.trim="form.realId" maxlength='18' @change="onInput($event)" placeholder="请输入身份证号码"></el-input>
      </div>
      <el-checkbox v-model="agree" @change="handlerIsCheck">已阅读并同意，<span @click.stop='goToNotification'
          style="color: #9C2526;">《个人信息使用声明》</span>和<span @click.stop='goToPersonAgree'
          style="color: #9C2526;">《作品使用同意函》</span>，您理解并同意，当您点击“立即签署”，我们将为您申请CFCA数字证书用于电子签约。</el-checkbox>
      <span slot="footer" class="dialog-footer" style="display: flex;justify-content:space-around">
        <el-button :class="isCheck ? 'checksure' : 'check'" @click="handleSign">立即签署</el-button>
      </span>
    </el-dialog>
    <div v-if="tip" class="tipBox">
      <div class="tip_bg">
        <img src="../../assets/images/login/jump.png" class="tipImge" alt="" />
        <div class="tip">
          <div class="title">温馨提示</div>
          <div class="text">您即将进入中金金融认证中心有限公司(CFCA)安心签签署页面</div>
          <div class="time">{{ secode }}<span v-if="secode == 3 || secode == 2 || secode == 1 || secode == 0">s</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :z-index="999" title="温馨提示" :visible.sync="toRecognition" center :close-on-click-modal="false">
      <p>
        如果您的手机号非本人实名办理，请在手机端通过人脸识别校验的方式进行合同签署。如果您的个人信息填写错误，请重新填写个人信息。您在签署合同中遇到问题，可以在线反馈，我们收到信息会及时处理。
      </p>
      <span slot="footer" class="dialog-footer" style="display: flex;justify-content:space-around">
        <el-button @click='centerDialogVisible = true; toRecognition = false' style="width: 50%;">重新填写个人信息</el-button>
      </span>
    </el-dialog> -->
    <el-dialog
      :z-index="999"
      :visible.sync="notification"
      width="70%"
      :close-on-click-modal="false"
      center
    >
      <signatures-notification></signatures-notification>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="notification = false" class="checksure"
          >确认</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      custom-class="setDialog"
      :z-index="999"
      :visible.sync="personAgree"
      :close-on-click-modal="false"
      center
    >
      <signatures-contract class="agree"></signatures-contract>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button
          @click="
            personAgree = false;
            sure = true;
          "
          class="checksure"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import platform from "platform";
import signagureHead from "@/components/head/signagureHead";
import signaturesNotification from "./signaturesNotification";
import signaturesContract from "./signaturesContract.vue";
export default {
  components: {
    signagureHead,
    signaturesNotification,
    signaturesContract,
  },
  data() {
    return {
      flag: false,
      total: 0,
      checked: "",
      tableData: {
        title: "",
      },
      form: {
        realName:
          this.$store.state.userNameh5 || localStorage.getItem("userNameh5"),
        realId: "",
        phone:
          this.$store.state.userPhoneh5 || localStorage.getItem("userPhoneh5"),
      },
      sure: false,
      userCode: "",
      centerDialogVisible: false,
      authorList: [],
      mineCommentListReqDto: {
        page: 1,
      },
      list: [],
      checkAll: false,
      isCheck: false,
      agree: false,
      codeTimer: "",
      tip: false,
      secode: "",
      notification: false,
      personAgree: false,
      listAll: [],
      path: "",
      loading: true,
      toRecognition: false,
      referenceNumbers: [],
    };
  },
  mounted() {
    document.title = "主文献-传播学术精品";
    var index = this.$route.fullPath.indexOf("?");
    this.path = this.$route.fullPath.substr(index + 1);
    if (this._isMobile() && this.BASE_URL != "/api/") {
      window.location.href = this.BASE_URL + "mobile/index.html#/" + this.path;
      this.loading = false;
    } else {
      this.loading = false;
    }
    this.handleStatus(this.path);
  },
  activated() {
    this.$bus.$on("pch5", (val) => {
      this.listAll.forEach((item) => {
        if (item.referenceNumber == val) {
          item.checked = true;
        }
      });
      const newArr = this.listAll.filter((item) => {
        return item.checked;
      });
      if (newArr.length == this.listAll.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
      console.log(this.listAll);
      this.authorList = this.listAll.slice(
        (this.mineCommentListReqDto.page - 1) * 8,
        (this.mineCommentListReqDto.page - 1) * 8 + 8
      );
    });
  },
  methods: {
    onInput(event) {
      const reg =
        /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (!reg.test(event)) {
        this.$message.error("您输入的身份证号不正确");
      }
    },
    // 判断是否是手机
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 根据路由判断当前状态
    async handleStatus(path) {
      const res = await this.$http({
        url: "/claimApplet/isClaim",
        method: "POST",
        data: {
          link: path,
        },
      });
      if (res.data.code === 210012) {
        return this.$message.error("路径错误，请复制短信/邮箱中的链接重新进入");
      }
      if (res.data.code !== 200 && res.data.code !== 210012) {
        return this.$message.error(res.data.msg);
      }
      this.userCode = res.data.data.userCode;
      this.$store.commit("setUserCodeh5", this.userCode);
      localStorage.setItem("userCodeh5", this.userCode);
      // this.list.userCode = this.userCode
      if (
        res.data.data.status === "0" ||
        res.data.data.status === "1" ||
        res.data.data.status === "2"
      ) {
        this.getList();
        this.handleUserInfo();
      }
      if (res.data.data.status === null || res.data.data.status === "") {
        this.$router.replace(`/signaturesinvite?${path}`);
      }
    },
    // 获取用户信息
    async handleUserInfo() {
      const res = await this.$http({
        url: "/claimApplet/getWritePhone",
        method: "POST",
        data: {
          userCode: this.userCode,
        },
      });
      // this.$store.commit('setUserCodeh5', this.$route.query.userCode)
      // localStorage.setItem('userCodeh5', this.$route.query.userCode)
      if (res.data.data.real_name) {
        this.form.realName = res.data.data.real_name;
        this.$store.commit("setUserNameh5", res.data.data.real_name);
        localStorage.setItem("userNameh5", res.data.data.real_name);
      }
      if (res.data.data.write_phone) {
        this.form.phone = res.data.data.write_phone;
        this.$store.commit("setUserPhoneh5", res.data.data.write_phone);
        localStorage.setItem("userPhoneh5", res.data.data.write_phone);
      }
    },
    goToNotification() {
      this.notification = true;
      // this.$router.push('/signaturesNotification')
      // this.centerDialogVisible = false
    },
    goToPersonAgree() {
      this.personAgree = true;
      // this.$router.push('/signaturesContract')
      // this.centerDialogVisible = false
    },
    async handleUserIdCard() {
      await this.$http({
        url: "/claimApplet/saveIdCard",
        method: "POST",
        data: {
          idcard: this.id,
          userCode: this.userCode,
        },
      });
      // console.log('保存手机号', res)
    },
    async handleSign() {
      var reg =
        /^[1-9]\d{5}(19|20)\d{2}(0\d|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])\d{3}(\d|X)$/;
      if (!reg.test(this.form.realId)) {
        return this.$message.error("请输入正确的身份证号");
      }
      if (this.agree) {
        const res = await this.$http({
          url: "/anxinsign/sign",
          method: "POST",
          data: {
            name: this.form.realName,
            phone: this.form.phone,
            idCard: this.form.realId,
            userCode: this.userCode,
          },
        });
        if (res.data.code !== 200) {
          this.flag = true;
          this.centerDialogVisible = false;
          // 身份校验不通过
          this.toRecognition = true;

          return this.$message({
            type: "warning",
            message: res.data.msg,
          });
        }
        this.handleUserIdCard();
        const url = res.data.data;
        this.centerDialogVisible = false;
        this.tip = true;
        this.secode = 3;
        this.codeTimer = setInterval(() => {
          this.secode--;
          if (this.secode <= 0) {
            this.secode = "加载中…";
            clearInterval(this.codeTimer);
            window.location.replace(url);
          }
        }, 1000);
      } else {
        this.$message.error("请同意告知协议");
      }
    },
    handleCurrentChange(val) {
      this.mineCommentListReqDto.page = val;
      this.authorList = this.listAll.slice((val - 1) * 8, (val - 1) * 8 + 8);
      // this.getList()
    },
    tableHead() {
      return "background-color: #f2f2f2;text-align:center;color:#9c2526";
    },
    tableCellStyle() {
      return "border: 1px;text-align: center";
    },
    goDetail(row) {
      this.$router.push({
        name: "signaturesDetail",
        params: { referenceNumber: row.referenceNumber, path: this.path },
      });
    },
    async getList() {
      const { data: res } = await this.$http({
        url: "/claimAuth/selectAuthorClaimList",
        method: "post",
        data: {
          usercode: this.userCode,
        },
      });
      this.total = res.data.length;
      this.listAll = res.data;
      this.authorList = res.data.slice(
        (this.mineCommentListReqDto.page - 1) * 8,
        (this.mineCommentListReqDto.page - 1) * 8 + 8
      );
    },
    submit() {
      if (!this.sure) {
        return this.$message.error("请选择认领文献并确认作品使用同意函");
      }
      const newArr = this.listAll.filter((item) => {
        return item.checked;
      });
      if (newArr.length == 0) {
        return this.$message.error("请选择认领文献");
      }
      this.toSignature();
    },
    async toSignature() {
      this.handlerCheck();
      const { data: res } = await this.$http({
        url: "/claimAuth/submitClaimLiteratureSimple",
        method: "post",
        data: {
          usercode: this.userCode,
          list: this.referenceNumbers,
          operatingSystem: platform.os.family || "",
          browserType: platform.name || "",
        },
      });
      // console.log(res)
      if (res.code !== 200) {
        return this.$message.error(res.msg);
      }
      this.$store.commit("setUserCodeh5", res.data);
      localStorage.setItem("userCodeh5", res.data);
      this.$router.push("/signaturesSuccess");
    },
    // toDetail(referenceNumber) {
    //   this.$router.push('/details?referenceNumber=' + referenceNumber)
    // },
    handlerCheckAll() {
      this.listAll.forEach((item) => {
        item.checked = this.checkAll;
      });
      this.authorList = this.listAll.slice(
        (this.mineCommentListReqDto.page - 1) * 8,
        (this.mineCommentListReqDto.page - 1) * 8 + 8
      );
    },
    handlerCheck() {
      const newArr = this.listAll.filter((item) => {
        return item.checked;
      });
      if (newArr.length == this.listAll.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
      this.referenceNumbers = [];
      this.referenceNumbers = newArr;
      this.authorList = this.listAll.slice(
        (this.mineCommentListReqDto.page - 1) * 8,
        (this.mineCommentListReqDto.page - 1) * 8 + 8
      );
      // newArr.forEach(item => {
      //   this.list.referenceNumbers.push(item.referenceNumber)
      // })
    },
    handlerIsCheck() {
      if (this.form.realName && this.form.realId && this.agree) {
        this.isCheck = true;
      } else {
        this.isCheck = false;
      }
    },
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(data) {
        if (data.realName && data.realId && this.agree) {
          this.isCheck = true;
        } else {
          this.isCheck = false;
        }
      },
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.el-table tr .el-table__cell .cell {
  width: 100% !important;
}

.content {
  width: 1440px;
  // height: 800px;
  // background-color: orange;
  margin: 21px auto;

  .remind {
    background: #ffeee0;
    width: 1440px;
    height: 40px;
    line-height: 40px;

    .el-icon-warning {
      color: #ff7100;
      font-size: 16px;
      margin-left: 20px;
    }

    span {
      font-size: 16px;
      color: #ff7100;
      margin-left: 9px;
    }
  }

  .table {
    width: 1440px;
    margin-top: 10px;

    .promise {
      margin-top: 20px;
      margin-left: 9px;

      span {
        color: #999;
        font-size: 16px;
      }
    }
  }

  .elPagination {
    margin-top: 20px;
  }
}

.handleBtn {
  margin-top: 20px;
  margin: 0 auto;
  width: 460px;
  height: 50px;

  .el-button {
    width: 460px;
    height: 50px;
    // position: fixed;
    // bottom: 100px;
    // left: 50%;
    border-radius: 25px;
    // transform: translate(-50%);
  }
}

.tipBox {
  background: rgba(0, 0, 0, 0.5);
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  padding-top: 200px;

  .tip_bg {
    // background: #ffffff;
    position: relative;
    z-index: 999;
    width: 400px;
    margin: 0 auto;

    .tipImge {
      display: block;
      width: 400px;
    }

    .tip {
      // background: #ffffff;
      width: 400px;
      height: 128px;
      background: #ffffff;
      border-radius: 0px 0px 10px 10px;

      .title {
        text-align: center;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #9c2526;
      }

      .text {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        margin: 0 auto;
        margin-top: 20px;
        margin-left: 52px;
        margin-right: 52px;
      }

      .time {
        text-align: center;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #8d2b2b;
        margin-top: 9px;
      }
    }
  }
}

// /deep/.el-dialog__title {
//   float: left;
// }

/deep/.el-dialog {
  width: 460px;

  .el-checkbox {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }
}

// .el-button {
//   width: 460px;
//   height: 50px;
//   border-radius: 25px;
//   font-size: 16px;
//   font-family: PingFang SC;
//   font-weight: bold;
//   color: #FEFEFE;
// }

.phone {
  // margin-top: 10px;
  display: flex;
  align-items: center;
  width: 400px;
  height: 15px;
  background: #f7f7f7;
  border-radius: 20px;
  overflow: hidden;
  padding: 13px 10px 12px 10px;

  /deep/.el-input__inner {
    border: 0;
    background-color: #f6f6f6;
  }

  img {
    margin-left: 10px;
    width: 15px;
    height: 15px;
  }
}

/deep/.el-checkbox__label {
  white-space: break-spaces !important;
}

.checksure {
  width: 200px;
  height: 40px;
  margin-bottom: 10px;
  background: #9c2526;
  border-radius: 20px;
  border: #9c2526 solid 1px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fefefe;
}

.check {
  width: 200px;
  height: 40px;
  margin-bottom: 10px;
  background: #f7f7f7;
  pointer-events: none;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  border: #f7f7f7 solid 1px;
}

.checksure:hover {
  background: #9c2526;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fefefe;
  border: #9c2526 solid 1px;
}

.checkChange {
  width: 150px;
  height: 20px;
  font-size: 14px;
  line-height: 0;
  border-radius: 20px;
  align-self: center;
}

/deep/.setDialog {
  width: 800px;
  height: 765px;
  background-image: url("../../assets/images/claim/agreebg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  margin-top: 5vh !important;

  .el-dialog__body {
    padding: 0 !important;
  }
}

.checksure {
  width: 120px;
  height: 40px;
}

/deep/.agree {
  .text {
    width: 680px;
    height: 566px;
    border-radius: 10px;
  }
}
</style>
