<template>
  <div>
    <div class="container">
      <div class="title">
        <img src="../../assets/images/home/platformInfotag.png" alt="">
        <h3>{{ noticeList.categoryName }}</h3>
      </div>
      <div class="titleInfo">
        {{ noticeList.title }}
      </div>
      <!-- <div class="timeShow">
        开始时间: <span class='time'>{{ noticeList.startTime }}</span> <span class='partition'>|</span> 结束时间: <span
          class='time'>{{ noticeList.endTime }}</span>
      </div> -->
      <div class="noticeInfomation" v-html="noticeList.content"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlatformNotice',
  data () {
    return {
      noticeList: ''
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const res = await this.$http({
        url: '/notice/find_one/' + this.$route.query.id,
        method: 'get'
      })
      if (res.data.code !== 200) {
        return this.$message.error(res.data.msg)
      }
      this.noticeList = res.data.data
      this.noticeList.content = this.noticeList.content.replace(/<img/g, '<img style="max-width:100%"')
    }
  }
}
</script>
<style lang='less' scoped>
* {
  box-sizing: border-box;
}

.container {
  margin: 22px auto;
  width: 1440px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;
  padding: 54px 30px;

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 22px;
      height: 23px;
      margin-right: 11px;
    }

    h3 {
      font-size: 24px;
      color: #9C2526;
    }
  }

  .titleInfo {
    // width: 1332px;
    height: 60px;
    background: #F5F5F5;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 20px;
    line-height: 60px;
    padding-left: 20px;
    font-weight: 550;
  }

  .timeShow {
    font-size: 14px;
    color: #666;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E6E6E6;

    .time {
      color: #9C2526;
      margin-right: 9px;
    }

    .partition {
      margin: 0 20px;
    }
  }

  /deep/.noticeInfomation {
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    min-height: 243px;
    word-wrap: break-word;
  }
}
</style>
