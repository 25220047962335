<template>
  <div class="mainRightBox">
    <div class="mainRightBoxtop">
      <div class="topItem">
        <p class="topItemNum">{{ mianliteratureNum || 0 }}</p>
        <p class="topItemtxt">收录主文献（篇）</p>
      </div>
      <div class="topItem">
        <p class="topItemNum">{{ seconddisciplineNum || 0 }}</p>
        <p class="topItemtxt">包含二级学科数（个）</p>
      </div>
      <div class="topItem">
        <p class="topItemNum">{{ selectauthorNum || 0 }}</p>
        <p class="topItemtxt">入选作者（人）</p>
      </div>
      <div class="topItem">
        <p class="topItemNum">{{ selectinstitutionNum || 0 }}</p>
        <p class="topItemtxt">涉及机构（家）</p>
      </div>
      <div class="topItem">
        <p class="topItemNum">{{ periodicalCount || 0 }}</p>
        <p class="topItemtxt">涉及期刊（种）</p>
      </div>
      <!-- <div class="topItem">
        <p class="topItemNum">{{reviewexpertNum}}</p>
        <p class="topItemtxt">参与评审专家（人）</p>
      </div>-->
    </div>
    <div class="scienceBox">
      <!-- /subjectHot -->
      <div class="sciencewordtitle">学术热词</div>
      <div style="height:100%;width:100%;overflow-y:scroll">
        <p class="scienceword" v-for="(item, index) in sciencewordList" :key="index">
          <span class="sciencewordNum">{{ index + 1 }}</span>
          <span :title="item.name" class="sciencewordtxt" @click="goSubjectHot(item, item.index)">
            {{
              item.name }}
          </span>
        </p>
      </div>
    </div>
    <!-- <div class="subject">
    <div class="subjectBox" @click="handlexs">-->
    <!-- <img src="../../assets/images/home/problem.png" alt /> -->
    <!-- <span class="subjectTxt">学科专题</span>
      </div>
    </div>-->
    <!-- <div class="hotspotBox" @click="handlegolook">
      <div class="hotspotTxt">
        <p>年度中国十大</p>
        <p>学术热点</p>
        <p class="hotspotBtn">点击查看</p>
      </div>
    </div>-->
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {},
  props: ['setSecond'],
  data () {
    return {
      treeFalg: false,
      mainsearch: {},
      searchContent: '',
      sciencewordList: [], // 学术热词
      secondselecterTree: [], // tree选中的二级
      firstselectedTree: [], // tree选中的一级
      mianliteratureNum: '', // 主文献数量
      seconddisciplineNum: '', // 二级学科数量
      selectinstitutionNum: '', // 入选机构
      selectauthorNum: '', // 入选作者
      reviewexpertNum: '', // 评审专家
      periodicalCount: '' // 涉及期刊
    }
  },
  mounted () {
    // const path = this.$route.fullPath
    // let subname = ''
    // if (path.split('?').length > 1) {
    //   subname = path.split('?')[1].split('=')[0]
    // }
    // if (this.$store.state.hotwordsearch.search === '' && subname !== 'subjectName') {
    //   this.mianRightnum()
    // }
    // this.mianRightnum()
    // if (this.$store.state.secondselectedTree.length === 0) {
    //   this.sciencewordFun()
    // }
  },
  methods: {
    // // 点击学科跳转
    // handlexs () {
    //   const routeData = this.$router.resolve({
    //     path: '/disciplineList'
    //     // query: params
    //   })
    //   window.open(routeData.href, '_blank')
    // },
    // 学术热词跳转
    goSubjectHot (item) {
      const routeData = this.$router.resolve({
        path: '/subjectHot',
        query: {
          secondLevelDiscipline: item.secondLevelDiscipline,
          firstLevelDiscipline: item.firstLevelDiscipline,
          name: item.name
        }
      })
      window.open(routeData.href, '_blank')
    },

    // 数字统计
    async mianRightnum () {
      const { data: res } = await this.$http({
        url: '/literatureInfoList/group/statistics ',
        method: 'post',
        data: {

          lv1dcList: this.firstselectedTree,
          lv2dcList: this.secondselecterTree,
          easySearch: this.searchContent,
          startTime: this.mainsearch.startTime,
          endTime: this.mainsearch.endTime,
          search: this.mainsearch.resultContent

        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.mianliteratureNum = res.data.indexedLiteratureNum // 主文献数量
      this.seconddisciplineNum = res.data.secondDisciplineNum // 二级学科数量
      this.selectinstitutionNum = res.data.orgNum // 入选机构
      this.selectauthorNum = res.data.selectedAuthorNum // 入选作者
      this.reviewexpertNum = res.data.expertsCount // 评审专家
      this.periodicalCount = res.data.journalNum // 涉及期刊（种）
    },

    // 学术热词
    async sciencewordFun () {
      const { data: res } = await this.$http({
        url: '/literatureInfoList/getHotWordList',
        method: 'post',
        data: this.secondselecterTree
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.sciencewordList = res.data
      // 存储热词的后端返回来的一级和二级
      // this.$store.commit("firsttenhot", this.selectedTree);
    }
  },
  watch: {
    '$store.state.firstselectedTree': {
      deep: true,
      handler (data, oldData) {
        if (JSON.stringify(data) !== JSON.stringify(oldData)) {
          this.firstselectedTree = data
          // this.mianRightnum()
        }
      }
    },
    '$store.state.secondselectedTree': {
      deep: true,
      immediate: true,
      handler (data, dataOld) {
        const path = this.$route.fullPath
        let sub = ''
        if (path.split('?').length > 1) {
          sub = path.split('?')[1].split('=')[0]
        }
        const _path = path.split('?')[0]
        if (_path === '/' && sub !== 'subjectName' && (this.$store.state.hotwordsearch.search === '' || this.$store.state.hotwordsearch.search === null) && !localStorage.getItem('token')) {
          if (document.querySelector('.searchInput')) {
            const search = document.querySelector('.searchInput').children[0].value
            this.searchContent = search
          }
          this.secondselecterTree = data
          this.sciencewordFun()
          this.mianRightnum()
        } else if (JSON.stringify(data) !== JSON.stringify(dataOld) && dataOld !== undefined && sub === 'subjectName') {
          if (document.querySelector('.searchInput')) {
            const search = document.querySelector('.searchInput').children[0].value
            this.searchContent = search
          }
          this.secondselecterTree = data
          this.sciencewordFun()
          this.mianRightnum()
        } else if (localStorage.getItem('token') && dataOld === undefined && data.length === 0 && (this.$store.state.hotwordsearch.search === '' || this.$store.state.hotwordsearch.search === null) && this.$store.state.setSecond !== null) {
          if (document.querySelector('.searchInput')) {
            const search = document.querySelector('.searchInput').children[0].value
            this.searchContent = search
          }
          this.treeFalg = true
          this.secondselecterTree = data
          this.sciencewordFun()
          this.mianRightnum()
        } else if (JSON.stringify(data) !== JSON.stringify(dataOld) && localStorage.getItem('token') && (this.$store.state.hotwordsearch.search === '' || this.$store.state.hotwordsearch.search === null) && this.$store.state.setSecond !== null) {
          if (document.querySelector('.searchInput')) {
            const search = document.querySelector('.searchInput').children[0].value
            this.searchContent = search
          }
          this.secondselecterTree = data
          this.sciencewordFun()
          this.mianRightnum()
        } else if (_path === '/' && sub !== 'subjectName' && !localStorage.getItem('token') && this.$store.state.hotwordsearch.search === '') {
          if (document.querySelector('.searchInput')) {
            const search = document.querySelector('.searchInput').children[0].value
            this.searchContent = search
          }
          this.secondselecterTree = data
          this.sciencewordFun()
          this.mianRightnum()
        } else if (_path === '/' && sub !== 'subjectName' && this.$store.state.hotwordsearch.search !== '' && this.$store.state.hotwordsearch.search !== null) {
          if (document.querySelector('.searchInput')) {
            const search = document.querySelector('.searchInput').children[0].value
            this.searchContent = search
          }
          // 如果是首页,没有登录,并且不是从门户跳转过来,就执行一次
          this.secondselecterTree = data
          this.searchContent = this.$store.state.hotwordsearch.search
          this.sciencewordFun()
          this.mianRightnum()
        }
      }
    },

    '$store.state.hotwordsearch.timestamp': {
      deep: true,
      // immediate: true,
      handler (data, oldData) {
        // if (oldData !== undefined && data !== null && oldData !== null) {
        if (this.$store.state.secondselectedTree.length !== 0) {
          this.secondselecterTree = this.$store.state.secondselectedTree
        }
        this.searchContent = this.$store.state.hotwordsearch.search
        this.mianRightnum()
        // }
      }
    },

    '$store.state.mainsearch': {
      deep: true,
      handler (data) {
        if (data) {
          if (this.$store.state.secondselectedTree.length !== 0 && this.$store.state.subjectName === '') {
            this.secondselecterTree = this.$store.state.secondselectedTree
          }
          this.mainsearch = data
          this.mianRightnum()
        }
      }
    },
    setSecond: {
      handler (data) {
        if (data.length === 0) {
          if (!this.treeFalg) {
            this.sciencewordFun()
            this.mianRightnum()
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.mainRightBox {
  .mainRightBoxtop {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0);
    border-radius: 10px;

    .topItem {
      padding: 5px 20px;
      box-sizing: border-box;
      margin-bottom: 10px;
      text-align: left;
      width: 100%;
      background: #f5f5f5;
      border-radius: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      .topItemNum {
        font-size: 26px;
        font-weight: 500;
        color: @my-color;
      }

      .topItemtxt {
        color: #000000;
        font-size: 14px;
        display: block;
        width: 140px;
      }
    }
  }

  .scienceBox {
    cursor: pointer;
    box-sizing: border-box;
    margin-top: 20px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0);
    border-radius: 10px;

    @media screen and (min-width: 1600px) {
      .sciencewordtitle {
        padding: 10px 20px;
        font-size: 20px;
        font-weight: 500;
        color: #9c2526;
        border-bottom: 1px solid #e6e6e6;
      }
    }

    // 1300~1600
    @media screen and (max-width: 1600px) and (min-width: 1300px) {
      .sciencewordtitle {
        padding: 10px 20px;
        font-size: 20px;
        font-weight: 500;
        color: #9c2526;
        border-bottom: 1px solid #e6e6e6;
      }
    }

    // 小于1300
    @media screen and (max-width: 1300px) {
      .sciencewordtitle {
        padding: 10px 20px;
        font-size: 18px;
        font-weight: 500;
        color: #9c2526;
        border-bottom: 1px solid #e6e6e6;
      }
    }

    .scienceword {
      padding: 6px 20px;
      font-size: 14px;
      font-weight: 400;
      display: flex;

      &:first-child {
        padding-top: 12px;
      }

      &:last-child {
        padding-bottom: 12px;
      }

      .sciencewordNum {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        display: inline-block;
        cursor: pointer;
      }

      .sciencewordtxt {
        margin-left: 10px;
        font-size: 14px;
      }
    }

    .scienceword:nth-child(1),
    .scienceword:nth-child(2),
    .scienceword:nth-child(3) {
      .sciencewordNum {
        color: @my-color;
      }
    }
  }

  .subject {
    margin-top: 20px;
    text-align: right;

    .subjectBox {
      background: url("../../assets/images/home/xuekebg.png") no-repeat;
      background-size: cover;
      cursor: pointer;
      width: 220px;
      height: 60px;
      line-height: 60px;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      text-align: center;
      display: inline-block;
      background-color: @my-color;

      >img {
        margin-top: 10px;
        vertical-align: bottom;
      }

      .subjectTxt {
        margin-left: 5px;
        font-size: 17px;
        color: #fff;
        letter-spacing: 14px;
        font-weight: 600;
      }
    }
  }

  .hotspotBox {
    animation: myfirst 2s infinite;
    position: fixed;
    right: 1%;
    top: 70%;
    background: url("../../assets/images/home/hotspot.png") no-repeat;
    width: 144px;
    height: 188px;
    background-size: cover;
    text-align: center;
    cursor: pointer;

    .hotspotTxt {
      transform: translateY(90%);
      color: #fff;

      .hotspotBtn {
        margin-top: 10px;
        display: inline-block;
        color: #a02728;
        width: 83px;
        height: 21px;
        background: linear-gradient(0deg, #ffe6e6, #ffffff);
        border-radius: 10px;
      }
    }
  }

  @keyframes myfirst {
    0% {
      transform: translate(0px, 0px);
    }

    50% {
      transform: translate(0px, -10px);
    }

    100% {
      transform: translate(0px, 0px);
    }
  }
}

.sciencewordtxt {
  margin-left: 10px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
</style>
