<template>
  <!-- 认领文献 -->
  <div
    class="claimbox"
    v-if="claimbox"
    v-loading="load"
    element-loading-spinner="none"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <div class="claimmmainbox">
      <div class="popovertop">
        <div class="popovertoplf">认领文献</div>
        <div class="popovertoprt" @click="handleclaimclose">
          <img :src="require('../../assets/images/claim/closebtn.png')" alt />
        </div>
      </div>
      <div class="claimstepsbox">
        <div class="claimsteps">
          <el-steps :active="active" align-center class="el-steps">
            <el-step
              title="信息完善"
              @click.native="handle_click(1)"
              :class="{ active: currentTab === 1 }"
            >
              <i class="step-one" slot="icon"></i>
            </el-step>
            <el-step
              title="文献认领"
              @click.native="handle_click(2)"
              :class="{ active: currentTab >= 2 || form.isCertified === 1 }"
            >
              <i class="step-two02" v-if="active >= 2" slot="icon"></i>
              <i class="step-two" v-else slot="icon"></i>
            </el-step>
            <el-step title="当前认领文献" :class="{ active: currentTab >= 3 }">
              <i class="step-three03" v-if="active >= 3" slot="icon"></i>
              <i class="step-three" v-else slot="icon"></i>
            </el-step>
          </el-steps>
        </div>

        <div class="step01contain" v-show="currentTab === 1">
          <!-- <div class="step01leftbox"> -->
          <!-- <div class="step01left"> -->
          <!-- <el-upload class="img-uploader" :action="BASE_URL + basicunload" :show-file-list="false"
                :disabled="form.disabled" :headers="headers" :before-upload="beforeAvatarUpload"
                accept=".jpg, .jpeg, .png" :on-success="handleAvatarSuccess">
          <img v-if="form.photo" :src="fastUrl + form.photo" class="uploadimg" />-->
          <!-- <img v-if="!form.img" :src="require('../../assets/images/head/logins.png')" alt /> -->
          <!-- <img v-if="!form.photo" class="avatar" src="../../assets/images/head/Personalimg01.png" /> -->
          <!-- <i v-else class="el-icon-plus img-uploader-icon"></i> -->
          <!-- <div class="after">
                  <span class="remind one" style="display: block;">修 改</span>
                  <span class="remind two" style="display: block;">头 像</span>
                </div>
          </el-upload>-->
          <!-- <div class="claimtype">
                <img v-if="form.isCertified == 0" :src="require('../../assets/images/claim/noclaim.png')" alt />
                <img v-if="form.isCertified == 1" :src="require('../../assets/images/claim/okclaim.png')" alt />
          </div>-->
          <!-- </div> -->
          <!-- <div class="tipclaim">头像支持格式：png、jpg、jpeg</div> -->
          <!-- </div> -->
          <div class="step01right">
            <div class="step01rightbox">
              <div class="righttop">
                <el-row>
                  <el-col :span="12" style="text-align: center; ">
                    <div class="grid-content bg-purple-dark">
                      <div class="formitem">
                        <span class="labletext request">作者单位：</span>
                        <el-select
                          v-model="form.unit"
                          placeholder="请输入关键词搜索单位"
                          clearable
                          @change="handleunit"
                          filterable
                        >
                          <el-option
                            v-for="item in unitoptions"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                          ></el-option>
                        </el-select>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12" style="text-align: center;">
                    <div class="grid-content bg-purple-dark">
                      <div class="formitem">
                        <span class="labletext request">手机号：</span>
                        <el-input maxlength="11" v-model="form.phone" disabled></el-input>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" style="text-align: center;">
                    <div class="grid-content bg-purple-dark">
                      <div class="formitem realName">
                        <span class="labletext request">真实姓名：</span>
                        <el-input
                          maxlength="20"
                          v-model="form.realname"
                          :disabled="real"
                          placeholder="请输入真实姓名"
                        ></el-input>
                        <span class="realNameFlag">真实姓名和笔名填写之后无法修改，请谨慎填写。</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12" style="text-align: center;">
                    <div class="grid-content bg-purple-dark">
                      <div class="formitem">
                        <span class="labletext request02">笔名：</span>
                        <el-input
                          maxlength="20"
                          v-if="nickName"
                          v-model="form.penname"
                          placeholder="请输入笔名"
                          disabled
                        ></el-input>
                        <el-input maxlength="20" v-else v-model="form.penname" placeholder="请输入笔名"></el-input>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <!-- <el-col :span="12">
                    <div class="grid-content bg-purple-dark">
                      <div class="formitem">
                        <span class="labletext request02">笔名：</span>
                        <el-input maxlength='20' v-if='nickName' v-model="form.penname" placeholder="请输入笔名"
                          disabled></el-input>
                        <el-input maxlength='20' v-else v-model="form.penname" placeholder="请输入笔名"></el-input>
                      </div>
                    </div>
                  </el-col>-->
                  <el-col :span="12" style="text-align: right;">
                    <div class="grid-content bg-purple-dark"></div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <div class="grid-content bg-purple-dark">
                      <div class="formitem100">
                        <!-- <span class="labletext request02"></span> -->
                        <div class="areaBtn">
                          <el-button @click="handleclaimclose">取消</el-button>
                          <el-button type="primary" @click="suerBtn">确定</el-button>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div class="step02contain" v-if="currentTab === 2">
          <div class="claimcontent">
            <div class="searchBox">
              <el-input
                v-model="claimSearchs"
                placeholder="请输入文献题目搜索"
                clearable
                @keyup.enter.native="handleaddclaimSearch"
              ></el-input>
              <el-button type="primary" @click="handleaddclaimSearch">搜索</el-button>
            </div>
            <div class="claimNumbox">
              <img :src="require('../../assets/images/claim/prompticon.png')" alt />
              <span class="claimtext" v-if="claimform.realName">为您推荐真实姓名为</span>
              <span class="claimNum">{{ claimform.realName }}</span>
              <span v-if="claimform.pseudonym">，</span>
              <span class="claimtext" v-if="claimform.pseudonym">笔名为</span>
              <span class="claimNum" v-if="claimform.pseudonym">{{ claimform.pseudonym }}</span>
              <span class="claimtext" v-if="total > 0">的文献，总计</span>
              <span class="claimNum" v-if="total > 0">{{ total }}</span>
              <span class="claimtext" v-if="total > 0">篇文献未认领</span>
              <span class="claimtext" v-if="total === 0">的文献，您的文献已全部认领</span>
            </div>
            <div class="claimtable">
              <el-table
                :row-key="getRowKey"
                :header-cell-style="{ background: '#F5F5F5', color: '#9C2526', fontWeight: 400 }"
                ref="multipleTable"
                :data="claimData"
                tooltip-effect="dark"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  type="selection"
                  min-width="10%"
                  align="center"
                  :reserve-selection="true"
                ></el-table-column>
                <el-table-column label="序号" type="index" align="center" min-width="18%"></el-table-column>
                <el-table-column
                  prop="authorCompany"
                  width="180"
                  label="作者及单位"
                  align="center"
                  min-width="20%"
                >
                  <template slot-scope="scope">
                    <div
                      class="overflow_text"
                      :title="scope.row.authorCompany"
                    >{{ scope.row.authorCompany }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="title" label="文献题目" align="center" min-width="20%">
                  <template slot-scope="scope">
                    <div class="overflow_text" :title="scope.row.title">{{ scope.row.title }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="pubYear"
                  label="文献发表年份"
                  width="90"
                  align="center"
                  min-width="15%"
                ></el-table-column>
                <el-table-column
                  prop="originJournalName"
                  width="140"
                  label="原发期刊名称"
                  align="center"
                  min-width="20%"
                >
                  <template slot-scope="scope">
                    <div
                      class="overflow_text"
                      :title="scope.row.originJournalName"
                    >{{ scope.row.originJournalName }}</div>
                  </template>
                </el-table-column>
                <!-- <el-table-column fixed="right" label="操作" width="50">
                  <template slot-scope="scope">
                    <el-button @click.native.prevent="PreviewRow(scope.$index, claimData)" type="text"
                      size="small">预览</el-button>
                  </template>
                </el-table-column>-->
              </el-table>
            </div>

            <div class="claimaccepttextBox">
              <!-- <el-checkbox v-model="claimform.claimaccept"></el-checkbox> -->
              <!-- <span class="claimaccepttext">我已阅读并同意接受</span>
              <span class="claimaccepttextactive" @click="toagreementdialog">《主文献平台作者服务平台用户服务协议》</span>-->
              <span class="claimaccepttext firstText">为了增强学术传播影响力，您可将您的作品授权给我们。</span>
              <span
                style="font-weight: 600;"
                class="claimaccepttext"
              >您可查阅下方的《作品使用同意函》并确定是否授权，您点击“确定”后，您的文献将在哲学社会科学主文献平台公开。您理解并知悉，只有作者才可以认领文献并向我们做出授权。</span>
              <span class="claimaccepttext">如有虚假冒领他人文献的，我们将对相关账号进行封禁惩罚，并保留追究法律责任的全部权利。</span>
              <el-dialog
                width="30%"
                custom-class="innerDialog"
                :visible.sync="innerVisible"
                :lock-scroll="false"
                append-to-body
              >
                <p>
                  为了保障您的合法权益，请您阅读并确认
                  <span style="cursor:pointer;color:#9c2526" @click="personAgree = true">《作品使用同意函》</span>。
                </p>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="innerVisible = false">取 消</el-button>
                  <el-button
                    type="primary"
                    @click="claimform.agree = true; innerVisible = false"
                  >确 定</el-button>
                </span>
              </el-dialog>
            </div>
            <div class="claimaccepttextBox">
              <el-checkbox v-model="claimform.agree"></el-checkbox>
              <span class="claimaccepttext">已阅读并同意</span>
              <span class="claimaccepttextactive" @click="TosignaturesContract">《作品使用同意函》</span>
            </div>
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageRow"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
            <div class="claimbtnBox">
              <el-button @click="handleclaimclose">取消</el-button>
              <el-button type="primary" @click="handlesaveClaimLiterature">确定</el-button>
            </div>
          </div>
        </div>
        <div class="step02contain" v-show="currentTab === 3">
          <div class="claimcontent">
            <div class="searchBox">
              <el-input
                placeholder="请输入文献题目搜索"
                v-model="okcliamsearchs"
                clearable
                @keyup.enter.native="handleaddSearch"
              ></el-input>
              <el-button type="primary" @click="handleaddSearch">搜索</el-button>
            </div>
            <div class="ClaimedNumbox">
              <img :src="require('../../assets/images/claim/succsemain.png')" alt />
              <span class="claimtext" style="margin-left: 10px;">恭喜您！已成功认领授权</span>
              <span class="ClaimedNum">{{ totalok }}</span>
              <span class="claimtext">篇文献。您可在个人中心-我的认领查看认领授权的文献</span>
            </div>
            <div class="claimtable">
              <el-table
                :header-cell-style="{ background: '#F5F5F5', color: '#9C2526', fontWeight: 400 }"
                ref="multipleTable"
                :data="okclaimData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
                max-height="205"
              >
                <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="authorCompany" width="150" label="作者及单位" align="center">
                  <template slot-scope="scope">
                    <div
                      class="overflow_text"
                      :title="scope.row.authorCompany"
                    >{{ scope.row.authorCompany }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="title" label="文献题目" align="center">
                  <template slot-scope="scope">
                    <div class="overflow_text" :title="scope.row.title">{{ scope.row.title }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="pubYear" label="文献发表年份" width="120" align="center"></el-table-column>
                <el-table-column prop="originJournalName" width="140" label="原发期刊名称" align="center">
                  <template slot-scope="scope">
                    <div
                      class="overflow_text"
                      :title="scope.row.originJournalName"
                    >{{ scope.row.originJournalName }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination
              background
              @size-change="handleSizeChange0k"
              @current-change="handleCurrentChangeok"
              :current-page="pageok"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageRowok"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalok"
            ></el-pagination>
            <div class="claimbtnBox">
              <el-button @click="handleclaimclose">关闭</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :lock-scroll="false"
      :visible.sync="agreementdialog"
      width="30%"
      :modal="false"
      :before-close="handleClose"
      center
    >
      <span>协议</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handlecancelagreement">取 消</el-button>
        <el-button type="primary" @click="handlesureagreement">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :lock-scroll="false"
      custom-class="setDialog"
      :visible.sync="personAgree"
      :close-on-click-modal="false"
      center
      :append-to-body="true"
    >
      <signatures-contract class="agree"></signatures-contract>
      <span slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button
          @click="personAgree = false; claimform.agree = true; innerVisible = false"
          type="primary"
          class="checksure"
        >确认</el-button>
      </span>
    </el-dialog>
    <login ref="login" class="homeLoginBigBox" @registeropen="registeropen"></login>
    <register
      ref="register"
      @loginopen="loginopen"
      @emitSuccessRegister="$refs.login.modelopen = true"
    ></register>
  </div>
</template>

<script>
import login from "../../page/login";
import register from "../../page/register";
import signaturesContract from "@/page/digitalSignatures/signaturesContract";
import { threeHandleLogin } from "../../utils/threeLogin";
export default {
  components: { login, register, signaturesContract },
  props: [],
  data() {
    return {
      innerVisible: false, // 内内层的el-dialog
      claimSearchs: "",
      load: false,
      step: 1, // 代表已经走到哪一步
      personAgree: false,
      isThree: "1",
      claimbox: false,
      currentTab: 1,
      active: 1,
      headers: {},
      dialogVisible: false,
      unitoptions: [], // 作者单位
      basicunload: "claim/uploadImage",
      nickName: "",
      form: {
        // name: '',
        phone: "",
        unit: "", // 单位
        mailbox: "", // 邮箱
        realname: "", // 真实姓名
        penname: "", // 笔名
        area: "", // 领域
        wordarea: [],
        notes: "",
        img: "",
        photo: "",
        isCertified: "", // 是否认证 0-未认证 1-已认证
        id: ""
      },
      claimform: {
        agree: false,
        // claimaccept: false, // 接受
        claimSearch: "", // 搜索
        desc: "",
        pseudonym: "", // 笔名
        realName: "" // 真实姓名
      },
      okcliamsearch: "", // 已认领搜索
      okcliamsearchs: "", // 已认领搜索
      claimData: [],
      okclaimData: [],
      multipleSelection: [],
      page: 1, // 页码
      pageRow: 10, // 每页显示的条目数
      total: -1,
      agreementdialog: false, // 协议弹框
      firstLevelDiscipline: [],
      referenceNumber: "",
      secondLevelDiscipline: [],
      title: "",
      pageok: 1, // 已认领页码
      pageRowok: 10, // 已认领每页显示的条目数
      totalok: 0, // 已认领
      descok: 0,
      real: "",
      fastUrl: "" // 请求的前置头像
    };
  },
  mounted() {
    if (this.claimbox) {
      if (!localStorage.getItem("token") && !localStorage.getItem("userName")) {
        return threeHandleLogin(this.THREE_LOGIN_OR);
        // return this.loginhandle()
      }
      this.form.img = this.$store.state.imageUrl;
    }
  },

  methods: {
    getRowKey(row) {
      return row.id;
    },
    toagreementdialog() {
      const routeData = this.$router.resolve({
        path: "/agree"
        // query: params
      });
      window.open(routeData.href, "_blank");
    },
    // 去作品使用同意函

    TosignaturesContract() {
      this.personAgree = true;
    },
    // 关闭弹框
    handleclaimclose() {
      this.claimbox = false;
    },
    // handleclaimclosestep03(){

    //    this.claimbox = false;
    // },
    // 监听头像上传成功
    handleAvatarSuccess(res, file) {
      this.fastUrl = res.data.fastUrl;
      this.form.photo = res.data.path;
    },
    // 上传前校验
    beforeAvatarUpload(file) {
      const isJPG = file.type;
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (isJPG.indexOf("image/") === -1) {
        this.$message.error("上传头像图片格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 1MB!");
        return false;
      }
    },
    // 点击切换
    handle_click(e) {
      if (this.form.isCertified === 0) {
        if (e === 2 && this.currentTab === 1) {
          return this.$message.error("请先完善基础信息");
        }
      }
      if (e !== "" || e != null) {
        if (e === 1) {
          this.getinforData();
          this.active = e;
          this.currentTab = e;
        }
        if (e === 2) {
          if (this.step === 1) {
            return this.$message("请先完善基础信息");
          }
          this.multipleSelection = [];
          this.active = e;
          this.currentTab = e;
          // this.claimform.claimaccept = false
          this.claimform.agree = false;
          this.claimSearch().then(res => {
            console.log("res", res);
          });
          this.$refs.multipleTable.clearSelectionFun();
          // this.$refs.multipleTable.clearSelectionFun()
        }
        // if (e === 3) {
        //   this.okclaimSearch();
        // }
      }
    },
    // 点击添加
    handleaddBtn() {
      if (this.form.wordarea.length === 10) {
        this.$message({
          message: "研究领域最多可添加十个",
          type: "warning"
        });
        return;
      }
      if (this.form.area === "") {
        this.$message({
          message: "请输入研究领域",
          type: "warning"
        });
        return;
      }
      this.form.wordarea.push(this.form.area);
      this.form.area = "";
      this.form.wordarea = Array.from(new Set(this.form.wordarea));
    },
    // 处理标签
    handleClosetag(tag) {
      this.form.wordarea.splice(this.form.wordarea.indexOf(tag), 1);
    },
    handleitem(tag) {},
    cancelBtn() {},
    handleunit() {
      this.getunitlist();
    },
    // 作者单位数据
    async getunitlist() {
      const { data: res } = await this.$http({
        url: "/claim/getOrganiztionList",
        method: "post",
        data: {
          name: ""
        }
      });
      this.unitoptions = res.data;
    },
    // 基础信息的数据
    async getinforData() {
      this.load = true;
      const { data: res } = await this.$http({
        url: "/claim/getUserBasicInfo",
        method: "post"
      });
      if (res.code !== 200) return this.$message.error(res.msg);
      this.load = false;
      this.fastUrl = res.data.fastUrl; // 图片前置路径
      this.form.phone = res.data.phone; // 手机号
      this.form.photo = res.data.photo; // 头像
      this.form.unit = res.data.company; // 单位
      this.form.mailbox = res.data.email; // 邮箱
      this.form.realname = res.data.realName; // 真实姓名
      if (res.data.realName) {
        this.real = true;
      } else {
        this.real = false;
      }
      this.form.penname = res.data.pseudonym; // 笔名
      this.nickName = res.data.pseudonym;
      // this.form.area = res.data.researchField; //领域
      this.form.wordarea = res.data.researchFieldList;
      this.form.notes = res.data.remark;
      this.form.id = res.data.id;
      // this.form.img = this.$store.state.imageUrl
      this.form.isCertified = res.data.isCertified;
      // this.
    },
    // 基础信息的确认
    async getsurebasice() {
      if (this.form.realname && this.form.realname.length > 30) {
        return this.$message({
          type: "warning",
          message: "真实姓名长度不超过30字"
        });
      }
      if (this.form.penname && this.form.penname.length > 30) {
        return this.$message({
          type: "warning",
          message: "笔名长度不超过30字"
        });
      }

      const checkEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (this.form.mailbox && !checkEmail.test(this.form.mailbox)) {
        return this.$message({
          type: "warning",
          message: "请输入正确邮箱!"
        });
      }

      const { data: res } = await this.$http({
        url: "/claim/authenticationInfo",
        method: "post",
        data: {
          company: this.form.unit, // 单位
          email: this.form.mailbox, // 邮箱
          id: this.form.id,
          idcardBack: "",
          idcardFront: "",
          // nickName: this.form.name, // 昵称
          phone: this.form.phone,
          photo: this.form.photo,
          realName: this.form.realname, // 真实姓名
          pseudonym: this.form.penname, // 笔名
          researchFieldList: this.form.wordarea, // 领域
          remark: this.form.notes
        }
      });
      if (res.code !== 200) return this.$message.error(res.msg);
      if (this.step === 1) {
        this.step++;
      }
      this.$store.commit("setImage", this.form.img);
      this.form.isCertified = 1;
      this.form.area = ""; // 领域
      // 成功之后跳转到文献认领
      this.active = 2;
      this.currentTab = 2;
      this.claimSearch().then(res => {
        console.log("res", res);
      });
    },
    // 基础信息的确定
    suerBtn() {
      // if (this.form.name === "") return this.$message.error("请输入用户昵称");
      if (this.form.unit === "" || this.form.unit === null) {
        return this.$message.error("请输入作者单位");
      }
      // if (this.form.mailbox === "") return this.$message.error("请输入邮箱");
      if (this.form.realname === "" || this.form.realname === null) {
        return this.$message.error("请输入真实姓名");
      }
      const emailTest = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.form.mailbox && !emailTest.test(this.form.mailbox)) {
        return this.$message("您输入的邮箱格式有误,请检查后重新输入");
      }
      // if (this.form.wordarea.length === 0) {
      //   return this.$message.error("请添加研究领域");
      // }
      this.getsurebasice();
    },
    // 文献认领搜索
    handleaddclaimSearch() {
      this.claimform.claimSearch = this.claimSearchs;
      this.page = 1;
      this.claimSearch();
    },
    // 列表
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    PreviewRow(index, rows) {
      console.log("111", index, rows);
    },
    handleSizeChange(val) {
      this.pageRow = val;
      this.claimSearch();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.claimSearch();
    },
    // 文献认领
    async claimSearch() {
      const { data: res } = await this.$http({
        url: "/claim/getUnclaimedLiteratureList",
        method: "post",
        data: {
          page: this.page, // 页码
          pageRow: this.pageRow, // 每页显示的条目数
          pseudonym: this.form.penname, // 笔名
          realName: this.form.realname, // 真实姓名
          search: this.claimform.claimSearch
        }
      });
      if (res.code != 200) return this.$message.error(res.msg);

      this.claimData = res.data.pageList;
      this.total = res.data.count;
      this.claimform.desc = res.data.desc;
      this.claimform.pseudonym = res.data.pseudonym;
      this.claimform.realName = res.data.realName;
    },
    // 协议取消
    handlecancelagreement() {
      // this.claimform.claimaccept = false
      this.agreementdialog = false;
    },
    // 协议确定
    handlesureagreement() {
      // this.claimform.claimaccept = true
      this.agreementdialog = false;
    },
    // 点击协议内容
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    handlesaveClaimLiterature() {
      if (this.multipleSelection.length > 0 && this.claimform.agree === true) {
        this.saveClaimLiteratureData();
      } else if (
        this.multipleSelection.length > 0 &&
        this.claimform.agree === false
      ) {
        this.innerVisible = true;
        // this.$message({
        //   type: 'warning',
        //   message: '请选择认领文献并确认作品使用同意函'
        // })
      } else if (this.multipleSelection.length === 0) {
        this.$message({
          type: "warning",
          message: "请先选择认领文献"
        });
      }
    },
    // 认领确定
    async saveClaimLiteratureData() {
      this.$emit("refresh", true);
      const claimDataarr = this.multipleSelection.map(val => {
        return {
          firstLevelDiscipline: val.firstLevelDiscipline,
          secondLevelDiscipline: val.secondLevelDiscipline,
          referenceNumber: val.referenceNumber,
          title: val.title,
          authorship: val.authorship
        };
      });

      const { data: res } = await this.$http({
        url: "/claim/saveClaimLiterature",
        method: "post",
        data: claimDataarr
      });
      if (res.code != 200) return this.$message.error(res.msg);

      // 将返回的数据发送已认领文献
      // this.claimform.claimaccept = false
      this.sendokdata = res.data;
      this.active = 3;
      this.currentTab = 3;
      this.page = 1;
      this.okclaimSearch();
    },

    // 已认领分页
    handleSizeChange0k(val) {
      this.pageRowok = val;
      this.okclaimSearch();
    },
    handleCurrentChangeok(val) {
      this.pageok = val;
      this.okclaimSearch();
    },
    // 认领完成搜素
    handleaddSearch() {
      this.okcliamsearch = this.okcliamsearchs;
      this.okclaimSearch();
    },
    async okclaimSearch() {
      const { data: res } = await this.$http({
        url: "/claim/getClaimLiteratureSuccessList",
        method: "post",
        data: {
          list: this.sendokdata,
          page: this.pageok, // 页码
          pageRow: this.pageRowok, // 每页显示的条目数
          search: this.okcliamsearch
        }
      });
      if (res.code != 200) return this.$message.error(res.msg);
      this.okclaimData = res.data.pageList;
      this.totalok = res.data.count;
    },
    loginhandle() {
      this.$refs.login.modelopen = true;
    },
    registeropen(value) {
      this.$refs.register.registershow = value;
    },
    loginopen(value) {
      this.$refs.login.modelopen = value;
    }
  },
  watch: {
    claimbox: {
      immediate: true,
      handler(data) {
        if (data === true) {
          this.getunitlist().then(() => {
            this.getinforData();
          });
        }
      }
    },
    "$store.state.imageUrl": {
      immediate: true,
      handler(data) {
        this.form.img = data;
      }
    },

    "$store.state.token": {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.headers = {
            Authorization: "Bearer " + localStorage.getItem("token") || ""
          };
          // 基本信息
          // this.getinforData()
          // // 基础信息的作者单位
          // this.getunitlist()
        }
      }
    }
  }
};
</script>
<style scoped lang="less">
.claimbox {
  transition: all 0.5s;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.2);
}

.claimmmainbox {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -48%);
  width: 1070px;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  z-index: 999;

  .popovertop {
    display: flex;
    justify-content: space-between;

    .popovertoplf {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
    }
  }

  .claimstepsbox {
    padding: 20px 30px;

    .claimsteps {
      width: 100%;
      background: #f5f5f5;
      padding: 15px 0px;
      border-radius: 10px;

      .step-one,
      .step-two,
      .step-two02,
      .step-three,
      .step-three03 {
        width: 32px;
        height: 32px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: inline-block;
      }

      .step-one {
        background-image: url("../../assets/images/claim/information.png");
      }

      .step-two {
        background-image: url("../../assets/images/claim/renling02.png");
      }

      .step-two02 {
        background-image: url("../../assets/images/claim/renling02active.png");
      }

      .step-three {
        background-image: url("../../assets/images/claim/yirenling.png");
      }

      .step-three03 {
        background-image: url("../../assets/images/claim/yirenlingactive.png");
      }

      /deep/ .el-steps {
        // width: 80%;
        .el-step.is-horizontal .el-step__line {
          top: 50%;
        }

        // .el-step__head.is-process {
        //   border-color: #dedede;
        // }
        // .el-step__head.is-finish {
        //   border-color: #285edf;
        // }
        // .el-step__title.is-process::before {
        //   content: "";
        //   width: 11px;
        //   height: 9px;
        //   // background: url("../../assets/images/claim/downjt.png") no-repeat;
        //   background-size: cover;
        //   position: absolute;
        //   left: 48%;
        //   bottom: 0%;
        // }
        // .el-step__main {
        //   position: relative;
        // }
        // .el-step__title.is-finish::before {
        //   content: "";
        //   width: 11px;
        //   height: 9px;
        //   background: url("../../assets/images/claim/downjt.png") no-repeat;
        //   background-size: cover;
        //   position: absolute;
        //   left: 48%;
        //   bottom: -10%;
        // }
        .el-step__icon {
          width: 56px;
          height: 56px;
        }

        .el-step__icon.is-text {
          // width: 58px;
          // height: 58px;
          border-style: hidden;
          background-color: #fff !important; //文字样式
        }

        .el-step__title.is-process {
          font-size: 14px;
          font-weight: 500;
          color: #666666;
        }

        .el-step.is-horizontal .el-step__line {
          background-color: #fff;
        }
      }
    }

    .step01contain {
      padding-top: 24px;
      display: flex;

      .img-uploader {
        width: 110px;
        height: 110px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #d9d9d9;

        .after {
          display: none;
          width: 110px;
          height: 110px;
          background-color: rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 0;
          border-radius: 50%;

          .remind {
            margin: 0 auto;
            color: #fff;
            font-size: 16px;
            font-weight: 550;
          }

          .one {
            margin-top: 40px;
          }

          .two {
            // margin-top: 50px;
          }
        }

        &:hover .after {
          display: block;
        }

        img {
          width: 110px;
          height: 110px;
          object-fit: cover;
        }
      }

      .img-uploader-icon {
        border: 1px dashed red;
        border-radius: 4px;
        font-size: 28px;
        color: #8c939d;
        width: 110px;
        height: 110px;
        line-height: 110px;
        text-align: center;
      }

      .uploadimg {
        width: 110px;
        height: 110px;
        // border: 1px solid #e8e8e8;
        display: block;
        // border-radius: 50%;
        // box-sizing: border-box;
        // object-fit: contain;
      }

      .step01leftbox {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .step01left {
          position: relative;
          width: 150px;

          .claimtype {
            position: absolute;
            top: 0;
            right: 0;

            img {
              width: 75px;
              height: 26px;
            }
          }
        }

        .tipclaim {
          color: #999;
          font-size: 12px;
          font-weight: 500;
          margin-top: 12px;
        }
      }

      .step01right {
        flex: 1;
        // padding-left: 40px;

        .interestwordadd {
          margin-left: 10px;
        }

        .formitem {
          margin-top: 10px;
          margin-bottom: 10px;

          .labletext {
            width: 100px;
            text-align: left;
            display: inline-block;
          }

          /deep/.el-input {
            width: 240px;
            border-radius: 4;
          }

          .request::before {
            content: "*";
            color: #f56c6c;
            margin-right: 10px;
          }

          .unrequest::before {
            content: "*";
            color: rgba(0, 0, 0, 0);
            margin-right: 10px;
          }

          .request02::before {
            content: "*";
            color: #fff;
            margin-right: 10px;
          }
        }

        .formitem100 {
          margin-top: 10px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          .areaBtn {
            display: inline-block;
            text-align: center;
            padding: 40px 0;

            .el-button {
              width: 120px;
            }
          }

          .labletext {
            width: 100px;
            text-align: left;
            display: inline-block;
          }

          /deep/.el-input {
            width: 590px;
            border-radius: 4;
          }

          .unrequest::before {
            content: "*";
            color: rgba(0, 0, 0, 0);
            margin-right: 10px;
          }

          .request02::before {
            content: "*";
            color: #fff;
            margin-right: 10px;
          }

          .areaBox {
            display: inline-block;

            .wordtagsTitle {
              font-size: 14px;
              font-weight: 400;
              color: #9c2526;
            }

            .wordtags {
              cursor: pointer;
              // display: inline-block;
              margin-top: 10px;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .step02contain {
      padding-top: 24px;

      .claimcontent {
        // overflow: auto;

        .searchBox {
          /deep/.el-input {
            width: 540px;
            height: 40px;
            border-radius: 4;
            margin-right: 10px;
          }
        }

        .claimNumbox {
          width: 970px;
          height: 32px;
          line-height: 32px;
          border: 1px solid #fbcda8;
          border-radius: 4px;
          margin-top: 10px;
          margin-bottom: 20px;
          padding-left: 10px;
          box-sizing: border-box;
          background-color: #fffcfa;

          img {
            width: 14px;
            height: 14px;
          }

          .claimNum {
            color: @my-color;
          }

          .claimtext {
            color: #666;
          }

          > img {
            vertical-align: text-bottom;
            margin-right: 10px;
          }
        }

        .claimtable {
          /deep/ .el-table {
            max-height: 190px;
            overflow-y: scroll;

            .el-table__row:hover {
              color: @my-color;
              cursor: pointer;
            }

            th.el-table__cell > .cell {
              // padding-right: 100px;
              // white-space: nowrap
            }

            tr {
              .el-table_1_column_1 {
                .cell {
                  padding-right: 0;
                }
              }
            }

            .overflow_text {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              -webkit-line-clamp: 1;
            }
          }
        }

        .claimaccepttextBox {
          margin-top: 20px;

          .claimaccepttext {
            // margin-left: 25px;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
          }

          .firstText {
            margin-left: 25px;
          }

          .claimaccepttextactive {
            margin-left: 6px;
            font-size: 12px;
            font-weight: 400;
            color: @my-color;
            cursor: pointer;
          }
        }

        .claimbtnBox {
          text-align: center;
          padding-top: 20px;

          .el-button {
            width: 120px;
          }
        }

        .ClaimedNumbox {
          position: relative;
          margin-top: 10px;
          padding: 0 10px;
          width: 550px;
          height: 32px;
          line-height: 32px;
          color: #fff;
          background: #9c2526;
          border-radius: 4px;
          margin-bottom: 20px;

          img {
            width: 13px;
            height: 13px;
          }

          .ClaimedNum {
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
          }
        }

        .ClaimedNumbox::before {
          content: "";
          width: 16px;
          height: 6px;

          background: url("../../assets/images/claim/Claimedjt.png") no-repeat;
          background-size: cover;
          position: absolute;
          left: 10%;
          bottom: -18%;
        }
      }
    }
  }
}

.realName {
  position: relative;
}

.realNameFlag {
  position: absolute;
  display: block;
  left: 150px;
  color: #9c2526;
  margin-top: 10px;
}

/deep/.setDialog {
  width: 800px;
  height: 765px;
  background-image: url("../../assets/images/claim/agreebg.png");
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  margin-top: 5vh !important;

  .el-dialog__body {
    padding: 0 !important;
  }
}

.el-table::before {
  z-index: inherit; // 规定应该从父元素继承 z-index 属性的值。
}

.checksure {
  width: 140px;
  height: 40px;
}

.popovertoprt {
  cursor: pointer;
}

/deep/.agree {
  .text {
    width: 680px;
    height: 566px;
    border-radius: 10px;
    // line-height: 30px !important;
  }
}

/deep/.el-checkbox__inner {
  border: 1px solid #9c2526;
  width: 15px;
  height: 15px;
}

/deep/.innerDialog {
  margin-top: 30vh !important;
}
</style>
