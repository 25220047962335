<template>
  <div>
    <div class="header">
      <div class="logo">
        <img src="../../assets/images/head/logo.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'signatureHead',
  methods: {
    goIndex() {
      this.$router.push('/main')
    }
  }
}
</script>
<style lang='less' scoped>
.header {
  height: 68px;
  width: 100%;
  background-color: #9c2526;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 7.2%;
  align-items: center;

  .logo {
    height: 80px;
    position: relative;

    img {
      height: 100%;
    }
  }
}
</style>
