<template>
  <div class="detailatlasBox">
    <el-tabs ref="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane class="tabFirst" label="研究主题" name="first" ref="tabFirst">
        <div id="wordCloud" class="wordCloudBox" ref="myChart"></div>
      </el-tab-pane>
      <el-tab-pane label="知识图谱" name="second">
        <div class="relationshipBox">
          <div id="relationship" class="relationship" ref="relationship"></div>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="知识脉络" name="third">
        <div class="thirdBox">
          <div class="hotspotsbox">
            <span style="font-weight: 700;margin-bottom: 1px;color: #4670fd;">|</span>
            <span style="color: #4670fd;font-weight: 800;margin-left: 5px;">研究热点</span>
            <div id="hotspots" class="hotspots" ref="hotspots"></div>
          </div>
      <div class="SankeyDiagramBox">-->
      <!-- <div
              style='height: 600px;width: 625px;display: flex;flex-direction: column;align-items: center;justify-content: center;'
              v-if='flag.length === 0'>
              <img src="../../assets/images/home/nodatasmall.png" alt="">
              <span>暂无数据</span>
      </div>-->
      <!-- <div ref='Snkey'> -->
      <!-- <span style="font-weight: 700;margin-bottom: 1px;color: #4670fd;">|</span>
            <span style="color: #4670fd;font-weight: 800;margin-left: 5px;">知识脉络分析</span>
      <div class="SankeyDiagramTxt">-->
      <!-- <p>研究起源</p>
              <p>研究点</p>
      <p>研究趋势</p>-->
      <!-- </div>
      <div id="SankeyDiagram" class="SankeyDiagram" ref="SankeyDiagram"></div>-->
      <!-- </div> -->
      <!-- <div class="imgShow" ref="imgShow">
              <img src="../../assets/images/home/nodataside.png" alt />
              <span>没有查询到数据哦</span>
            </div>
          </div>
        </div>
      </el-tab-pane>-->
      <!-- <el-tab-pane label="作者合作网格" name="fourth">
        <div class="authorcooperateBox">
          <div class="authorcooperate" id="authorcooperate" ref="authorcooperate"></div>
          <div class="noneAuthor" ref="noneAuthor">
            <img src="../../assets/images/home/组 2.png" alt="">
            <span>没有查询到数据哦</span>
          </div>
        </div>
      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
import 'echarts-wordcloud'
import imgSrc from '../../assets/images/head/Personalimg.png'
import elementResizeDetectorMaker from 'element-resize-detector'
const erd = elementResizeDetectorMaker()
export default {
  data () {
    return {
      hotWordsLength: '',
      centerNodeIndex: '',
      flag: '',
      referenceNumber: '', // 文献编号
      id: '', // 文献id
      activeName: 'first',
      // 词云数据格式
      WordCloudData: [],
      // 关系图谱
      relationshipData: [],
      relationshipLine: [],
      // 研究热词
      hotspotsData: [],
      chartData: [],
      dataLink: []
    }
  },
  beforeDestroy () {
    erd.uninstall(this.$refs.myChart)
    erd.uninstall(this.$refs.relationship)
  },
  methods: {
    handleClick (tab, event) { },
    // 作者合作网格
    goauthorwg (option) {
      const routeData = this.$router.resolve({
        path:
          '/authorDetail?id=' +
          option.id +
          '&name=' +
          option.companyName +
          '&authorCode=' +
          option.authorCode
      })
      window.open(routeData.href, '_blank')
    },
    // 跳转作者详情
    goauthorDetail (option) {
      // console.log('option', option)

      const routeData = this.$router.resolve({
        path:
          '/authorDetail?id=' +
          option.paramId +
          '&name=' +
          option.orgCode +
          '&authorCode=' +
          option.authorCode
      })
      window.open(routeData.href, '_blank')
    },
    // 跳转文献详情
    gomainDetail (option) {
      console.log('kkk', option)
      const routeData = this.$router.resolve({
        path:
          '/mainDetails?id=' +
          option.paramId +
          '&referenceNumber=' + option.referenceNumber +
          "&path=''"
      })
      window.open(routeData.href, '_blank')
      // location.reload();
    },
    // 跳转期刊
    gojournalDetail (option) {
      const routeData = this.$router.resolve({
        path: '/journaldetail?id=' + option.paramId + '&name=' + option.name
      })
      window.open(routeData.href, '_blank')
    },
    // 跳转机构
    goinstitutionDetail (option) {
      const routeData = this.$router.resolve({
        path: '/institutiondetail?name=' + option.companyCode + '&page=1' + '&pageRow=1'
      })
      window.open(routeData.href, '_blank')
    },
    // 跳转学术热词
    gosubjectHot (name) {
      const routeData = this.$router.resolve({
        path: '/keyWord?name=' + name
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    // _this.goinstitutionDetail(option.data);
    // 点击列表标题去详情
    handlegodetail (referenceNumber, id, path) {
      // 如果是主文献
      // if (this.Listobj.type == "0") {
      // }else if(this.Listobj.type == "5"){
      // }
      const routeData = this.$router.resolve({
        path:
          '/mainDetails?id=' +
          id +
          '&referenceNumber=' +
          referenceNumber +
          '&path=' +
          path
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    // 热词
    async getWordCloud () {
      const { data: res } = await this.$http({
        url: '/detail/researchTitle',
        method: 'post',
        data: {
          id: this.id
        } // 文献编号
      })
      this.hotWordsLength = res.data
      if (this.hotWordsLength.length === 1) {
        if (this.hotWordsLength[0].name == '') {
          this.$refs.tabs.$children[0].$refs.tabs[0].style.display = 'none'
          this.activeName = 'second'
        }
      }
      if (this.hotWordsLength.length === 0) {
        this.$nextTick(() => {
          this.$refs.tabs.$children[0].$refs.tabs[0].style.display = 'none'
          // console.log(this.$refs.tabs.$children[0].$refs)
          this.activeName = 'second'
          // console.log(this.$refs.tabs.$children[0].$refs.tabs)
        })
      }
      if (res.code != 200) return this.$message.error(res.msg)
      var accessToElements = this.$refs.myChart // 绑定元素
      var themeStyle = this.$echarts.init(accessToElements)
      // 图表自适应配置
      var _that = this
      const chartNode = new ResizeObserver(() => {
        themeStyle.resize()
      })
      chartNode.observe(accessToElements)
      // 绘制图表
      var option = {
        series: [
          {
            type: 'wordCloud',
            // 要绘制的“云”的形状。任意极坐标方程都可以表示为a吗
            // 回调函数，或关键字存在。可用的礼物为圆形(默认)，
            // 心形(苹果或心形曲线，最著名的极坐标方程)，菱形(
            // alias of square)， triangle-forward, triangle， (alias of triangle- standing, pentagon, and star)
            shape: 'circle',
            // 保持maskImage或1:1的形状的纵横比
            // echarts-wordcloud@2.1.0支持该选项
            keepAspect: false,
            // 一个轮廓图像，白色区域将被排除在绘制文本之外。
            // 当云的形状增长时，形状选项将继续应用。
            // maskImage: maskImage,
            // 跟随左/顶/宽/高/右/底是用来定位字云
            // 默认放置在中心，大小为75% x 80%。
            left: 'center',
            top: 'center',
            width: '80%',
            height: '80%',
            right: null,
            bottom: null,
            // 文本大小范围，数据中的值将被映射到。
            // 默认最小12px，最大60px大小。
            sizeRange: [20, 24],
            // 文本旋转范围和步进度。文本将被rotationStep 45在[- 90,90]范围内随机旋转
            rotationRange: [-45, 90],
            rotationStep: 45,
            // 网格的大小(以像素为单位)，用于标记画布的可用性
            // 网格大小越大，单词之间的间距越大。
            gridSize: 8,
            // 设置为true允许文字部分绘制在画布外。
            // 允许文字大于画布的大小
            drawOutOfBound: false,
            // 如果执行布局动画。
            // 注意当有很多字的时候禁用它会导致UI阻塞。
            layoutAnimation: true,
            // Global text style
            textStyle: {
              fontFamily: 'sans-serif',
              fontWeight: 'bold',
              // Color can be a callback function or a color string
              color: function () {
                // Random color
                var colors = [
                  '#5a8ef2',
                  '#adf8f9',
                  '#addaf9',
                  '#adc7f9',
                  '#bbadf9'
                ] // 自定义字体颜色
                return colors[parseInt(Math.random() * 5)]
              }
            },
            emphasis: {
              focus: 'self'
            },
            // 内容，
            data: res.data
          }
        ]
      }
      themeStyle.on('click', function (params) {
        // console.log('params', params)
        // alert(params.data.name)
        const routeData = _that.$router.resolve({
          path: '/keyWord?name=' + params.data.name
          // query: params
        })
        window.open(routeData.href, '_blank')
      })
      option && themeStyle.setOption(option)
      erd.listenTo(this.$refs.myChart, function () {
        themeStyle.resize()
      })
    },
    // 关系图谱数据
    async getrelationshipData () {
      const { data: res } = await this.$http({
        url: '/detail/getKnowledgeGraph',
        method: 'post',
        data: {
          referenceNumber: this.referenceNumber
        } // 文献编号
      })
      // const arr = res.data.relationshipData
      console.log('知识图谱', res.data)
      if (res.code != 200) return this.$message.error(res.msg)
      // this.relationshipData = res.data.relationshipData;
      // this.relationshipLine = res.data.relationshipLine;
      const _this = this // 将vue的this指向赋值给变量，获取到跳转的router路由
      function myAerlt (val) {
        if (val) {
          _this.goauthorDetail()
          // 期刊
          _this.gojournalDetail()
          // 机构
          _this.goinstitutionDetail()
          // 文献详情
          _this.gomainDetail()
          _this.gosubjectHot()
        }
      }
      window.myAerlt = myAerlt
      var myChart = this.$echarts.init(this.$refs.relationship)
      var cate = res.data.relationshipData.map(function (a) {
        return a.des
      })
      const set = new Set(cate)
      cate = Array.from(set)
      var categories = []
      cate.forEach(item => {
        categories.push({ name: item })
      })
      res.data.relationshipData.forEach(item => {
        delete item.itemStyle
        for (let i = 0; i < cate.length; i++) {
          if (item.des == cate[i]) {
            item.category = i
          }
        }
      })
      function fontSize (res) {
        const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        if (!clientWidth) return
        const fontSize = 100 * (clientWidth / 1920)
        return res * fontSize
      }
      var option = {
        color: [
          '#37768F',
          '#FEA01E',
          '#9C2526',
          '#D24132',
          '#D24132',
          '#179A80',
          '#FF7100',
          '#9C2526'
        ],
        // title: { text: "人民的名义关系图谱" },
        tooltip: {
          formatter: function (x) {
            return x.data.name
          }
        },
        animationDuration: 1500, // 数据更新动画的时长。
        animationEasingUpdate: 'quinticInOut', // 数据更新动画的缓动效果。
        legend: [{
          right: fontSize(0.1),
          orient: 'horizontal',
          icon: 'circle',
          data: cate.map(function (a) {
            return a
          }),
          itemHeight: fontSize(0.14),
          itemWidth: fontSize(0.14),
          textStyle: {
            fontSize: fontSize(0.14)
          }
        }],
        series: [
          {
            type: 'graph',

            layout: 'force',
            // symbolSize: 3,
            roam: true,
            symbol: 'circle',
            // 取消箭头指示
            // edgeSymbol: ['circle', 'arrow'],
            // edgeSymbolSize: [4, 10],
            edgeLabel: {
              // 连线上的文字
              normal: {
                show: true,
                // show: true,
                formatter: function (x) {
                  return x.data.name
                },
                textStyle: {
                  fontSize: fontSize(0.1)
                }
              }
            },
            force: {
              repulsion: [fontSize(-0.02), fontSize(25)],
              // 箭头的大小
              edgeLength: [fontSize(0.1), fontSize(0.5)]
            },
            draggable: true,
            // itemStyle: {
            //   normal: {
            //     color: "#4b565b"
            //   }
            // },
            lineStyle: {
              // 线条属性
              opacity: 0.3,
              width: fontSize(0.01),
              curveness: 0.3,
              color: '#666'
            },
            // edgeLabel: {
            //   normal: {

            //   }
            // },
            label: {
              normal: {
                show: true,
                textStyle: {
                  fontSize: fontSize(0.12)
                  // color: '#666'
                }
              }
            },
            categories: categories,
            data: res.data.relationshipData.map(function (a) {
              return a
            }),
            links: res.data.relationshipLine
          }
        ]
      }
      console.log('option', option)
      myChart.setOption(option)
      // 查找要居中显示的节点的索引
      option.series[0].data.forEach((node, index) => {
        if (node.des === '文献标题') {
          this.centerNodeIndex = index
        }
      })
      // 计算出要居中显示的节点的坐标
      const chartWidth = myChart.getWidth()
      const chartHeight = myChart.getHeight()
      const nodeValueWidth = 100 // 假设node的宽度为100
      const nodeValueHeight = 100 // 假设node的高度为100
      const centerX = chartWidth / 2 - nodeValueWidth / 2
      const centerY = chartHeight / 2 - nodeValueHeight / 2
      // console.log('坐标宽高', chartWidth, chartHeight)
      // 更新节点的位置
      myChart.setOption({
        series: [
          {
            data: option.series[0].data.map((node, index) => {
              if (index === this.centerNodeIndex) {
                return {
                  ...node,
                  x: centerX,
                  y: centerY,
                  symbolSize: [fontSize(2), fontSize(0.5)],
                  symbol: 'rect'
                }
              } else {
                return node
              }
            })
          }
        ]
      })
      erd.listenTo(this.$refs.relationship, function () {
        myChart.resize()
      })
      // console.log('option', this.centerNodeIndex)

      // 点击知识图谱
      myChart.on('click', function (option) {
        //  0-文献  1-作者 2-机构 3-期刊
        if (option.data.paramId && option.data.type == '1') {
          // 跳转作者
          _this.goauthorDetail(option.data)
        }
        if (option.data.paramId && option.data.type == '0') {
          // 跳转文献详情
          console.log('666', option)
          _this.gomainDetail(option.data)
        }
        // 跳转期刊
        if (option.data.paramId && option.data.type == '3') {
          _this.gojournalDetail(option.data)
        }
        // 跳转机构
        if (option.data.paramId && option.data.type == '2') {
          _this.goinstitutionDetail(option.data)
        }
        if (option.data.des == '关键词') {
          console.log('7777')
          _this.gosubjectHot(option.data.name)
        }
      })
    },
    // 研究热点
    async gethotspots () {
      const { data: res } = await this.$http({
        url: '/detail/getResearchHotspots',
        method: 'post',
        data: {
          referenceNumber: this.referenceNumber
        } // 文献编号
      })
      if (res.code != 200) return this.$message.error(res.msg)
      var _that = this
      // console.log('1112', res)
      var themeStyle = this.$echarts.init(this.$refs.hotspots)
      // 绘制图表
      var option = {
        // title: {
        //   text: "研究热点"
        // },
        series: [
          {
            type: 'wordCloud',
            shape: 'circle',
            keepAspect: false,
            left: 'center',
            top: 'center',
            width: '80%',
            height: '80%',
            right: null,
            bottom: null,
            sizeRange: [20, 40],
            // 文本旋转范围和步进度。文本将被rotationStep 45在[- 90,90]范围内随机旋转
            rotationRange: [-45, 90],
            rotationStep: 45,
            gridSize: 8,

            // 设置为true允许文字部分绘制在画布外。
            // 允许文字大于画布的大小
            drawOutOfBound: false,

            // 如果执行布局动画。
            // 注意当有很多字的时候禁用它会导致UI阻塞。
            layoutAnimation: true,

            // Global text style
            textStyle: {
              fontFamily: 'sans-serif',
              fontWeight: 'bold',
              color: function () {
                // Random color
                var colors = [
                  '#5a8ef2',
                  '#adf8f9',
                  '#addaf9',
                  '#adc7f9',
                  '#bbadf9'
                ] // 自定义字体颜色
                return colors[parseInt(Math.random() * 5)]
              }
            },
            emphasis: {
              focus: 'self'
            },

            // 内容，
            data: res.data
          }
        ]
      }
      themeStyle.on('click', function (params) {
        // console.log('params', params)
        // alert(params.data.name)
        const routeData = _that.$router.resolve({
          path: '/keyWord?name=' + params.data.name
          // query: params
        })
        // console.log('122', routeData.href)
        window.open(routeData.href, '_blank')
      })
      themeStyle.setOption(option)
    },
    // 桑基图数据
    // async getData () {
    //   const { data: res } = await this.$http({
    //     url: '/detail/getKnowledgeVein',
    //     method: 'post',
    //     data: {
    //       referenceNumber: this.referenceNumber
    //     } // 文献编号
    //   })

    //   this.flag = res.data.nodes

    //   if (res.data.nodes.length === 0) {
    //     this.$refs.SankeyDiagram.style = 'display:none'
    //     this.$refs.imgShow.style = 'display:flex'
    //   } else {
    //     this.$refs.SankeyDiagram.style = 'display:block'
    //     this.$refs.imgShow.style = 'display:none'
    //   }

    //   var SankeyDiagram = this.$echarts.init(this.$refs.SankeyDiagram)
    //   SankeyDiagram.showLoading()
    //   SankeyDiagram.hideLoading()

    //   var option02 = {
    //     // title: {
    //     //   text: "知识脉络分析"
    //     // },
    //     // tooltip: {
    //     //   trigger: 'item',
    //     //   triggerOn: 'mousemove'
    //     // },
    //     series: [
    //       {
    //         type: 'sankey',
    //         data: res.data.nodes,
    //         links: res.data.links,
    //         layout: 'none',
    //         // emphasis: {
    //         //   focus: "adjacency"
    //         // },
    //         focusNodeAdjacency: true,
    //         levels: [
    //           {
    //             depth: 0,
    //             itemStyle: {
    //               color: '#fbb4ae'
    //             },
    //             lineStyle: {
    //               color: 'source',
    //               opacity: 0.6
    //             }
    //           },
    //           {
    //             depth: 1,
    //             itemStyle: {
    //               color: '#b3cde3'
    //             },
    //             lineStyle: {
    //               color: 'source',
    //               opacity: 0.6
    //             }
    //           },
    //           {
    //             depth: 2,
    //             itemStyle: {
    //               color: '#ccebc5'
    //             },
    //             lineStyle: {
    //               color: 'source',
    //               opacity: 0.6
    //             }
    //           },
    //           {
    //             depth: 3,
    //             itemStyle: {
    //               color: '#decbe4'
    //             },
    //             lineStyle: {
    //               color: 'source',
    //               opacity: 0.6
    //             }
    //           }
    //         ],
    //         lineStyle: {
    //           normal: {
    //             curveness: 0.5
    //           }
    //         }
    //       }
    //     ]
    //   }
    //   // if (this.flag.length !== 0) {
    //   //   this.$nextTick(() => {
    //   //     SankeyDiagram.setOption(option02)
    //   //   })
    //   // }
    //   // SankeyDiagram.setOption(option02)
    //   setTimeout(() => {
    //     const that = this
    //     SankeyDiagram.on('click', (params) => {
    //       const routeData = that.$router.resolve({
    //         path: '/keyWord?name=' + params.data.name
    //       })
    //       window.open(routeData.href, '_blank')
    //     })
    //     SankeyDiagram.setOption(option02)
    //   }, 0)
    // },
    setEcharts () {
      if (this.flag.length !== 0) {
        // this.$nextTick(() => {
        //   this.getData()
        // })
      }
    }
    // 桑基图
    // getSankeyDiagram () {
    //   this.getData()

    //   // this.setEcharts()
    // }
    // // 作者合作关系图
    // async getauthor () {
    //   const { data: res } = await this.$http({
    //     url: '/detail/getAuthorCollaboration',
    //     method: 'post',
    //     data: {
    //       referenceNumber: this.referenceNumber
    //     } // 文献编号
    //   })
    //   console.log('作者合作网格', res)
    //   if (res.code != 200) return this.$message.error(res.msg)
    //   if (res.data.chartData.length > 1) {
    //     this.$refs.noneAuthor.style.display = 'none'
    //     this.$refs.authorcooperate.style.display = 'block'
    //   } else {
    //     this.$refs.noneAuthor.style.display = 'flex'
    //     this.$refs.authorcooperate.style.display = 'none'
    //   }
    //   const _this = this // 将vue的this指向赋值给变量，获取到跳转的router路由
    //   function myAerlt (val) {
    //     if (val) {
    //       _this.goauthorwg()
    //     }
    //   }
    //   window.myAerlt = myAerlt
    //   var myChart = this.$echarts.init(this.$refs.authorcooperate)
    //   var option = {
    //     // title: { text: "人民的名义关系图谱" },
    //     tooltip: {
    //       show: false
    //     },
    //     series: [
    //       {
    //         edgeLabel: {
    //           normal: {
    //             formatter: '{c}',
    //             show: true
    //           }
    //         },
    //         edgeSymbol: 'circle',
    //         force: {
    //           repulsion: 900
    //         },
    //         layout: 'force',
    //         roam: true,
    //         itemStyle: {
    //           normal: {
    //             color: '#6495ED'
    //           }
    //         },
    //         label: {
    //           show: true,
    //           position: 'bottom',
    //           distance: 5,
    //           fontSize: 18,
    //           align: 'center'
    //         },
    //         // 头像
    //         symbol: `image://${imgSrc}`,
    //         symbolSize: 50,
    //         type: 'graph',
    //         links: res.data.dataLink,
    //         data: res.data.chartData
    //       }
    //     ]
    //   }
    //   myChart.setOption(option)
    //   myChart.on('click', function (option) {
    //     _this.goauthorwg(option.data)
    //   })
    // }
  },
  mounted () {
    // 取链接的值-文献编号
    this.referenceNumber = this.$route.query.referenceNumber
    // 取链接的值-文献id
    this.id = this.$route.query.id
    this.getWordCloud()
    this.getrelationshipData()
    this.gethotspots()
    // this.getSankeyDiagram()
    // 作者合作网络
    // this.getauthor()
  }
}
</script>
<style lang="less" scoped>
.detailatlasBox {
  margin: 20px 0;
  padding: 20px 54px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;

  /deep/.el-tabs__nav {
    padding-bottom: 20px;
  }

  /deep/.el-tabs__item {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }

  /deep/.el-tabs__item.is-active {
    color: @my-color;
  }

  .wordCloudBox {
    width: 100%;
    height: 600px;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
  }

  .relationshipBox {
    width: 100%;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    background: #ffffff;

    #relationship {
      width: 1200px;
      height: 600px;

      /deep/canvas {
        width: 1200px !important;
        height: 600px !important;
      }
    }
  }

  .authorcooperateBox {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 4px;

    #authorcooperate {
      width: 1200px;
      height: 600px;
    }

    .noneAuthor {
      display: flex;
      width: 100%;
      height: 600px;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        // width: 500px;
        height: 150px;
      }

      span {
        color: #666;
        font-size: 20px;
      }
    }
  }

  .thirdBox {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .hotspotsbox {
      border: 1px solid #e4e4e4;
      padding: 20px;
      border-radius: 4px;
      box-sizing: border-box;
      z-index: 999;
      width: 327px;
      height: 562px;

      .hotspots {
        width: 315px !important;
        height: 503px !important;

        /deep/ canvas {
          width: 305px !important;
          height: 503px !important;
        }
      }

      @media screen and (min-width: 1600px) {
        .hotspots {
          width: 305px !important;
          height: 503px !important;

          // /deep/ canvas {
          //   width: 347px !important;
          //   height: 552px !important;
          // }
        }
      }

      // 1300~1600

      @media screen and (max-width: 1600px) and (min-width: 1300px) {
        .hotspots {
          width: 305px !important;
          height: 503px !important;

          // /deep/ canvas {
          //   width: 347px !important;
          //   height: 552px !important;
          // }
        }
      }

      // 小于1300
      @media screen and (max-width: 1300px) {
        .hotspots {
          width: 305px !important;
          height: 503px !important;

          // /deep/ canvas {
          //   width: 18.0729vw !important;
          //   height: 26.1979vw !important;
          // }
        }
      }
    }

    .SankeyDiagramBox {
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      margin-left: 20px;
      flex: 1;
      padding: 16px;
      box-sizing: border-box;
      width: 861px;
      height: 562px;

      .SankeyDiagram {
        width: 850px !important;
        height: 450px !important;
      }

      /deep/canvas {
        width: 850px !important;
        height: 450px !important;
      }

      @media screen and (min-width: 1600px) {
        // .SankeyDiagram {
        //   width: 43.0208vw;
        //   height: 26.0417vw;
        // }

        .imgShow {
          width: 830.4px;
          height: 5500px;
          display: flex !important;
          flex-direction: column !important;
          align-items: cente !important;
          justify-content: center !important;
          text-align: center;

          img {
            width: 300px;
          }

          span {
            font-size: 20px;
            color: #666;
          }
        }

        .SankeyDiagramTxt {
          font-size: 14px;
          color: @my-color;
          font-weight: 500;
          width: 75%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 50px;
        }
      }

      // 1300~1600

      @media screen and (max-width: 1600px) and (min-width: 1300px) {
        // .SankeyDiagram {
        //   width: 31.25vw;
        //   height: 28.6458vw;
        // }

        .imgShow {
          width: 830.4px;
          height: 549.9994px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          img {
            width: 300px;
            // height: 400px;
          }

          span {
            font-size: 20px;
          }
        }

        .SankeyDiagramTxt {
          font-size: 14px;
          color: @my-color;
          font-weight: 500;
          width: 68%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 50px;
        }
      }

      // 小于1300
      @media screen and (max-width: 1300px) {
        .SankeyDiagram {
          width: 600px;
          height: 549.9994px;
          // padding: 20px;
        }

        .imgShow {
          width: 830.4px;
          height: 550px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          img {
            width: 300px;
            // height: 400px;
          }

          span {
            font-size: 20px;
          }
        }

        .SankeyDiagramTxt {
          font-size: 14px;
          color: @my-color;
          font-weight: 500;
          width: 68%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 50px;
        }
      }
    }
  }
}
</style>
