<template>
  <div class="empty" v-if="authorlistSide.length == 0">
    <img class="img" src="../../assets/images/home/nodataside.png" alt="">
    <p>没有查询到数据哦</p>
  </div>
</template>

<script>
export default {
  props: ['authorlistSide']
}

</script>

<style lang="less" scoped>
.empty {
  margin: 80px 0;

  .img {
    width: 178px;
    height: 88px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  p {
    text-align: center;
    color: #666;
  }
}
</style>
