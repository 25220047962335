<template>
  <div class="experTeampage">
   专家团队
  </div>
</template>

<script>
// @ is an alias to /src


export default {

  components: {

  }
}
</script>
