<template>
  <div class="linboxILBox">
    <div class="ltbox">
      <div class="discipline">
        <p class="disciplineTitle">遴选标准</p>
      </div>
      <div class="lefttreebox">
        <el-scrollbar style="height:100%;width:100%;">
          <el-menu :default-active="aindex" background-color="#fff" class="menuwidth">
            <el-menu-item :index="-1" @click="handlemunuFirst(-1)">
              <span class="select" slot="title">哲学社会科学主文献遴选说明及标准</span>
            </el-menu-item>
            <el-menu-item :index="item.code" v-for="(item, index) in linxulist" :key="index"
              @click="handlemunuitem(item.code, index)">
              <span class="select" slot="title">{{ item.name }}学科</span>
            </el-menu-item>
          </el-menu>
        </el-scrollbar>
      </div>
    </div>
    <!-- 在线阅读 -->
    <div class="selectContentBox">
      <div v-if='showFalg != -1' v-html="selectContent"></div>
      <div v-else class="showInfo">
        <h3>主文献遴选说明</h3>
        <p class='mt'>
          依据国务院学位委员会、教育部发布的《研究生教育学科专业目录(2022年)》并结合遴选实际需要设置主文献学科分类方案。“哲社主文献”一期的遴选范围是2013-2022年间公开发表的约400万篇哲学社会科学学术论文，覆盖所有一级、二级学科。通过大数据筛选，参考复印报刊资料和《新华文摘》等二次文献、各大学术数据库高被引论文、高等学校科学研究优秀成果奖(人文社会科学)获奖论文等，并通过专家推荐、机构调研等形式广泛征集学界意见，遴选出约14万篇初始备选文献。
        </p>
        <p class=' mt marginbottom'>主文献一期建设，共邀请全国范围内1300余名专家组成各学科主文献评审组，分别进行了通讯评审、会议评审、终评审定，共遴选出2万余篇文献。</p>
        <h3>主文献遴选标准</h3>
        <p class='mt'>
          “哲社主文献”按照主流、经典、必读的要求,综合考量政治立场、学术诚信、理论创新、
          知识贡献、学科影响、实践引导等方面，根据各学科特点分别形成各学科遴选主文献的基本标准，并公布在“哲社主文献”平台主页面学科导航下方的“遴选标准”中。
        </p>
        <el-table :data="tableData" border style="width: 100%"
          :header-cell-style="{ 'text-align': 'center', 'color': '#000' }" :cell-style="{ 'color': '#000' }">
          <el-table-column align='center' prop="standard" label="遴选标准" width="180">
          </el-table-column>
          <el-table-column prop="naillustrateme" label="说明">
          </el-table-column>

        </el-table>
        <span style='color: #333;'>注：各学科参照上述基本标准形成本学科的遴选标准。</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data () {
    return {
      code: '',
      linxulist: [],
      selectContent: '',
      currentItem: 0,
      itemcode: '',
      aindex: -1,
      showFalg: -1,
      tableData: [
        {
          standard: '政治立场',
          naillustrateme: '坚持马克思主义，坚持以习近平新时代中国特色社会主义思想为指导。'
        }, {
          standard: '学术诚信',
          naillustrateme: '遵循学术规范，恪守学术道德，无知识产权问题。'
        }, {
          standard: '理论创新',
          naillustrateme: '注重学理研究，发现新问题，提出新概念、新观点、新方法、新学说，揭示新规律，体现原创性、时代性，引领学术研究的创新性发展。'
        }, {
          standard: '知识贡献',
          naillustrateme: '对知识发现和知识创新有较大贡献，具有重要学术价值，是学科领域知识体系的必要组成部分，助力中国哲学社会科学自主知识体系建构。'
        }, {
          standard: '学科影响',
          naillustrateme: '在学科领域教学科研中被广泛关注和引用，被公认为必读文献，对学科的建设、发展和国际学术影响力的提升具有重要作用。'
        }, {
          standard: '实践引导',
          naillustrateme: '具有较高应用价值，对解决中国经济社会发展中的重要问题起到理论支撑和实践引领作用。'
        }
      ]
    }
  },
  beforeMount () {

  },
  mounted () {
    this.currentItem = 0

    document.title = '遴选标准' + this.$route.meta.title
    this.getDataList()
    // .then(res => {
    //   return this.getDatacontent(this.code)
    // })
  },
  created () { },

  methods: {
    handlemunuFirst () {
      this.showFalg = -1
    },
    handlemunuitem (code, index) {
      this.showFalg = index
      this.currentItem = this.linxulist[index]
      this.getDataList()
      this.getDatacontent(code)
    },
    async getDataList () {
      const { data: res } = await this.$http({
        url: '/literatureInfoList/getFirstDisciplineList',
        method: 'post',
        data: []
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.data.length > 0) {
        this.code = res.data[0].code
      }
      this.linxulist = res.data
      // this.aindex = res.data[0].code
      // res.data.forEach(element => {
      //   if(element.check){

      //   }

      // });
    },
    async getDatacontent (code) {
      const { data: res } = await this.$http({
        url: '/literatureInfoList/getInstructionsByCode',
        method: 'post',
        data: {
          code: code
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.selectContent = res.data.reviewNote
      this.selectContent = this.selectContent.replace(/<img/g, '<img style="max-width:100%"')
    }
  }
}
</script>

<style scoped lang="less">
.select {
  font-size: 18px;
}

table {
  width: 100%;
}

/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}

.showInfo {
  margin-top: 10px;

  h3 {
    margin-bottom: 10px;
    font-size: 20px;
    text-align: center;
  }

  .mt {
    text-indent: 28px;
    // margin-bottom: 30px;
  }

  .marginbottom {
    margin-bottom: 30px;
  }

}

/deep/.el-menu-item {
  // background-color: transparent !important;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 24px;
  height: 24px;
  margin-bottom: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background-color: transparent !important;
  }

  // height: 20px;
}

/deep/.el-menu-item.is-active {
  // background-color: transparent !important;
  // font-weight: 500 !important;
  // font-size: 20px;
  border-right: 2px solid #9c2526;
}

.linboxILBox {
  width: 1440px;
  margin: 20px auto;
  display: flex;
  overflow: hidden;

  .ltbox {
    width: 340px;
    max-height: 560px;
    margin-top: 35px;

    .discipline {
      padding-bottom: 36px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      box-sizing: border-box;
      width: 100%;
      height: 60px;
      // background: #f5f5f5;
      border-right: 1px solid #e6e6e6;
      // border-radius: .5208vw .5208vw 0 0;

      @media screen and (min-width: 1600px) {
        .disciplineTitle {
          font-size: 22px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // 1300~1600

      @media screen and (max-width: 1600px) and (min-width: 1300px) {
        .disciplineTitle {
          font-size: 20px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // 小于1300
      @media screen and (max-width: 1300px) {
        .disciplineTitle {
          font-size: 18px;
          font-weight: bold;
          color: #9c2526;
        }
      }
    }

    .lefttreebox {
      width: 340px;
      height: 560px;
    }
  }

  .selectContentBox {
    flex: 1;
    min-height: 600px;
    padding: 20px;
    line-height: 24px;
    text-align: justify;
    color: #333;
  }
}
</style>
