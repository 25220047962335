<template>
  <div class="lefttreeBox">
    <div class="lefttreeBoxtop">
      <div class="discipline">
        <p class="disciplineTitle">{{ treeType.title }}</p>
        <div class="handleBtn">
          <el-button class='topBtn' @click='handleALl' size="mini">全选</el-button>
          <el-button class='topBtn' @click='handleCheckClear' size="mini">清除</el-button>
        </div>
      </div>
      <div style="width:100%;overflow:scroll" id="myElement">
        <el-tree :render-after-expand='false' class="treeBox" :data="authortreedata" show-checkbox node-key="code"
          ref="tree" highlight-current :props="defaultProps" @check-change="handlegetCheckedNodes"
          :default-checked-keys="secondselecterTree">
          <span class="custom-tree-node ischecked" slot-scope="{node,data}">
            <span ref='treeName' :class='{ "ischecked": data.checked }' class='treeName'>{{
              data.name }}</span>
            <!-- <span ref='treeName' v-if="data.checked == true" class='treeName ischecked'>{{ data.name }}</span> -->
            <span class="treecount" v-if="data.number != null">({{ data.number }}篇)</span>
            <span class="treecount" v-if="data.individual != null">({{ data.individual }}个)</span>
            <span class="treecount" v-if="data.peoplenum != null">({{ data.peoplenum }}人)</span>
          </span>
        </el-tree>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  props: ['treeType'],
  data () {
    return {
      isExpand: false,
      checkAll: false,
      subjectCount: 0,
      checkAllCount: 0,
      authortreedata: [],
      secondselecterTree: [],
      opt: '',
      depopt: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      selectedTree: [] // 选中的tree值
    }
  },
  mounted () {
    // this.getauthortree()
  },
  methods: {
    // 全选按钮
    handleALl () {
      // this.checkAll = true
      this.checkAll = true
      this.checkAllCount = 0
      this.handleCheckAll()
    },
    handleCheckAll () {
      this.$refs.tree.setCheckedNodes(this.authortreedata)
    },
    // 取消全选
    handleCheckClear () {
      this.checkAll = true
      this.checkAllCount = 0
      this.$refs.tree.setCheckedKeys([])
      this.isExpand = false
      for (let i = 0; i < this.authortreedata.length; i++) {
        this.$refs.tree.store.nodesMap[this.authortreedata[i].id].expanded = this.isExpand
      }
    },
    // 选择被选中的的节点所有数组
    handlegetCheckedNodes (data, checked) {
      if (this.checkAll === false) {
        data.checked = checked
        const selectTreearray = this.$refs.tree.getCheckedNodes()
        this.selectedTree = selectTreearray.map(item => {
          return item.code
        })
        // 存到缓存中
        this.$store.commit('selectedauthor', this.selectedTree)
        const year = selectTreearray.map(item => {
          return item.name
        })
        this.$emit('year', year)
      } else {
        this.checkAllCount++
        data.checked = checked
        if (this.checkAllCount === 1) {
          this.subjectCount = 0
          this.authortreedata.forEach(item => {
            this.subjectCount++
          })

          this.selectedTree = []
          const year = []
          this.$store.commit('selectedauthor', this.selectedTree)
          this.$emit('year', year)
          this.checkAll = false
          this.checkAllCount = 0
        }
        if (this.subjectCount === this.checkAllCount) {
          this.checkAll = false
        }
      }
    },
    // 获取左侧树接口
    async getauthortree () {
      const { data: res } = await this.$http({
        url: this.treeType.url,
        method: this.treeType.method,
        data: {
          opt: this.opt,
          depOpt: this.depopt
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.authortreedata = res.data
      const element = document.querySelector('#myElement')
      const x = res.data.length
      var height = 44.1 * x
      element.style.maxHeight = height + 'px'
    }

  },
  watch: {
    // 监听大搜索
    '$store.state.hotwordsearch.search': {
      deep: true,
      immediate: true,
      handler (data, oldData) {
        // const insitSearchEl = document.querySelector('.institutionSearchBox')
        // this.depopt = insitSearchEl.textContent
        if (data !== null || this.$route.path === '/tensciencelist') {
          if (document.querySelector('.institutionSearchBox')) {
            const insitSearchEl = document.querySelector('.institutionSearchBox')
            this.depopt = insitSearchEl.children[0].value
          }
          this.opt = data
          this.getauthortree()
        }
        // }
      }
    },
    '$store.state.selectedauthor': {
      deep: true,
      // immediate: true,
      handler (data) {
        this.secondselecterTree = data
      }
    },
    // 监听小搜索
    '$store.state.institutionsearch.time': {
      deep: true,
      // immediate: true,
      handler (data) {
        this.depopt = this.$store.state.institutionsearch.search
        this.getauthortree()
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-scrollbar__thumb {
  background-color: #E6E6E6 !important;
  border-radius: 3px !important;
}

/deep/.el-scrollbar__thumb {
  width: 6px !important;
  height: 45% !important;
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-main {
  overflow: hidden;
}

.treeBox {
  width: 300px;
  // height: 628px;
}

.lefttreeBox {
  .lefttreeBoxtop {
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;
    width: 300px;

    // height: 100%;

    .discipline {
      display: flex;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
      width: 100%;
      height: 60px;
      background: #f5f5f5;
      border-radius: 10px 10px 0 0;
      justify-content: space-between;

      .handleBtn {
        .topBtn {
          background-color: transparent;
          margin-left: 0;
          margin-right: 5px;
          width: 37px;
          height: 21px;
          background: #F5F5F5;
          border: 1px solid #CCCCCC;
          border-radius: 4px;
          text-align: center;
          // font-size: 12px;
          padding: 0;

          /deep/span {
            font-size: 12px;
          }
        }
      }

      @media screen and (min-width: 1600px) {
        .disciplineTitle {
          font-size: 22px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // 1300~1600

      @media screen and (max-width: 1600px) and (min-width: 1300px) {
        .disciplineTitle {
          font-size: 20px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // 小于1300
      @media screen and (max-width: 1300px) {
        .disciplineTitle {
          font-size: 18px;
          font-weight: bold;
          color: #9c2526;
        }
      }
    }

    /deep/.el-tree {
      width: 100%;
      // min-height: 684px;
      background: #ffffff;
      border-radius: 0 0 10px 10px;
      padding: 18px 20px 0 20px;
      box-sizing: border-box;
    }

    /deep/.el-tree-node {
      // background-color: orange !important;
      margin-bottom: 26px;
    }

    .treeName {
      // width: 38px;
      // height: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #1A1A1A;
      // line-height: 74px;
    }

    // .treeName.ischecked {
    //   color: #9c2526 !important;
    // }

    .treecount {
      font-size: 14px;
      margin-left: 6px;
      color: #1A1A1A;
      ;
    }
  }
}

.treeName.ischecked {
  color: #9c2526 !important;
}

//一级节点
/deep/ .el-tree>.el-tree-node>.el-tree-node__content {
  margin-bottom: 20px;
  height: 17px;
}

//二级节点选择器
/deep/ .el-tree>.el-tree-node>.el-tree-node__children>.el-tree-node>.el-tree-node__content {
  margin-bottom: 15px;
  // color: #666666;
  padding-left: 8px !important;
}
</style>
