import Vue from 'vue'
import router from '@/router'
import vuex from '@/store'
// "1"代表认证中心
export const threeHandleLogin = data => {
  // console.log('Vue.prototype.BASE_URL', Vue.prototype)
  // console.log('router.app.$route.fullPath', router.app.$route)
  // console.log('全地址', Vue.prototype.ROUTER_BASE_URL + router.app.$route.fullPath)
  if (data === '1') {
    // console.log("99",router.app.$route.query.code)
    if (!localStorage.getItem('token') && !router.app.$route.query.code) {
      // vuex.commit('setThreeUrl', Vue.prototype.BASE_URL + 'page/index.html#' + router.app.$route.fullPath)
      // const path = Vue.prototype.ROUTER_BASE_URL + router.app.$route.fullPath

      vuex.commit('setThreeUrl', router.app.$route.fullPath)
      // vuex.commit('setThreeUrl', Vue.prototype.ROUTER_BASE_URL + router.app.$route.fullPath)
      location.href =
        Vue.prototype.BASE_URL +
        'authentication/authorizeV3?redirectURI=' +
        encodeURIComponent(
          Vue.prototype.ROUTER_BASE_URL + router.app.$route.fullPath
        )
      // location.href = Vue.prototype.BASE_URL + 'authentication/authorizeV3'
    }
  } else {
    const loginEl = document.querySelector('.homeLoginBigBox')
    // console.log(vuex.state.localLogin)
    vuex.commit('handleLocalLogin', true)
    loginEl.style.display = 'block'
  }
}

// 安心签提供页面加载的方法
export const IsLoginV2 = data => {
  if (data === '1') {
    return new Promise(resolve => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://xueshushijie.cn/oauth-api/oauth/is_login'

      script.onload = script.onreadystatechange = function (event) {
        if (event.type === 'load') {
          console.log('is_login', is_login)
          // 未登录清空登录数据
          // if (is_login == false) {
            // localStorage.removeItem('token')
            // localStorage.removeItem('userImage')
            // localStorage.removeItem('userName')
            // localStorage.removeItem('phone')
            // vuex.commit('setImage', '')
            // vuex.commit('userName', '')
            // vuex.commit('setUserToken', '')
            // vuex.commit('secondLevelList', [])
            // vuex.commit('firstLevelList', [])
          // }
          resolve(is_login)
        } else {
          resolve(false)
        }
        script.remove()
      }
      document.body.appendChild(script)
    })
    // .then(res => {
    //   return res
    //   // console.log('1res', res)
    // }).catch(err => {
    //   return err
    // })
  }
}
