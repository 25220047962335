<template>
  <div>
    <div class="middlemenu">
      <div class="channel-item" :class="{ active: currentIndex == i }" v-for="(item, i) in channelList" :key="i"
        @click="handleClick(i)">{{ item.label }}</div>
    </div>
    <div class="platformNotice" v-if="currNotice.length > 1 && isclose">
      <div class="swiper-container" ref="noticeBanner">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in currNotice" :key="item.id" :id="item.id" :data-no="item.id">
            <div style="height: 100%;width: 100%;" :data-no='item.id'>
              <div class="platformTitle" :data-no='item.id'>
                <span :data-no='item.id'>{{ item.categoryName }}</span>
              </div>
              <div class="platformNoticeInfo" :data-no='item.id'>{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <img src="../../assets/images/home/close.png" @click.stop="handleclose" class="close" alt="">
    </div>
    <div class="platformNotice" v-if="currNotice.length == 1 && isclose">
      <div v-for="item in currNotice" :key="item.id" @click="handleGoPlatformInfo(item.id)"
        style="width: 100%;height: 100%;">
        <div class="platformTitle">
          <span>{{ item.categoryName }}</span>
        </div>
        <div class="platformNoticeInfo">{{ item.title }}</div>
      </div>
      <img src="../../assets/images/home/close.png" @click.stop="handleclose" class="close" alt="">
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  components: {},
  data () {
    return {
      channelList: [
        {
          label: '主文献',
          value: 'main'
        },
        {
          label: '作者',
          value: 'author'
        },
        {
          label: '机构',
          value: 'institution'
        },
        {
          label: '期刊',
          value: 'journal'
        }
        // {
        //   label: "专家团队",
        //   value: "expertteam"
        // }
      ],
      // 默认选中的频道
      currentIndex: 0,
      currNotice: [],
      mySwiper: '',
      isclose: true,
      clientId: '',
      eventSource: ''
    }
  },
  mounted () {
    if (this.$route.path == '/' || this.$route.path == '/main') {
      this.notice()
      this.createSse()
    }
  },
  methods: {
    async handleclose () {
      this.isclose = false
      const res = await this.$http({
        url: '/notice/close?clientId=' + this.clientId,
        method: 'get'
      })
      if (res.data.code !== 200) {
        return this.$message.error(res.data.msg)
      }
      this.clientId = ''
      this.eventSource.close()
    },
    // 选中频道
    handleClick (index) {
      this.currentIndex = index
      switch (index) {
        case 0:
          this.$router.push('/main')
          break
        case 1:
          this.$router.push('/author')
          break
        case 2:
          this.$router.push('/institution')
          break
        case 3:
          this.$router.push('/journal')
          break
        case 4:
          this.$router.push('/expertteam')
          break
      }
    },
    handleGoPlatformInfo (id) {
      const routeData = this.$router.resolve({
        path: '/platformNotice?id=' + id
      })
      window.open(routeData.href, '_blank')
    },
    async createSse () {
      const res = await this.$http({
        url: '/notice/create',
        method: 'get'
      })
      if (res.data.code !== 200) {
        return this.$message.error(res.data.msg)
      }
      this.clientId = res.data.data
      this.createSseConnect()
    },
    async notice () {
      const res = await this.$http({
        url: '/notice/curr',
        method: 'get'
      })
      if (res.data.code !== 200) {
        return this.$message.error(res.data.msg)
      }
      if (res.data.data) {
        this.currNotice = res.data.data
      }
      const that = this
      if (this.currNotice.length > 1) {
        this.$nextTick(() => {
          that.mySwiper = new Swiper(that.$refs.noticeBanner, {
            loop: true,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false // 解决滑动后不能轮播的问题
            },
            on: {
              click: function (e) {
                if (e.target.dataset.no) {
                  const routeData = that.$router.resolve({
                    path: '/platformNotice?id=' + e.target.dataset.no
                  })
                  window.open(routeData.href, '_blank')
                }
              }
            }
          })
        })
      }
    },
    createSseConnect () {
      if (window.EventSource) {
        this.eventSource = new EventSource(this.BASE_URL + 'notice/connect?clientId=' + this.clientId)

        this.eventSource.onmessage = async (event) => {
          const res = await this.$http({
            url: '/notice/curr',
            method: 'get'
          })
          if (res.data.code !== 200) {
            return this.$message.error(res.data.msg)
          }
          this.mySwiper.destroy(true)
          if (res.data.data) {
            this.currNotice = res.data.data
          }
          const that = this
          if (this.currNotice.length > 1) {
            this.$nextTick(() => {
              that.mySwiper = new Swiper(that.$refs.noticeBanner, {
                loop: true,
                autoplay: {
                  delay: 3000,
                  disableOnInteraction: false // 解决滑动后不能轮播的问题
                },
                on: {
                  click: function (e) {
                    if (e.target.dataset.no) {
                      const routeData = that.$router.resolve({
                        path: '/platformNotice?id=' + e.target.dataset.no
                      })
                      window.open(routeData.href, '_blank')
                    }
                  }
                }
              })
            })
          }
        }

        this.eventSource.onopen = (event) => {
          console.log('onopen:' + this.clientId + ': ' + event)
        }

        this.eventSource.onerror = (event) => {
          console.log('onerror :' + this.clientId + ': ' + event)
        }

        this.eventSource.close = (event) => {
          console.log('close :' + this.clientId + ': ' + event)
        }
      } else {
        console.log('你的浏览器不支持SSE~')
      }
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler () {
        if (this.$route.path === '/' || this.$route.path === '/main') {
          this.currentIndex = 0
          if (document.querySelector('.platformNotice')) { document.querySelector('.platformNotice').style.display = 'inline' }
          return true
        } else if (this.$route.path === '/author') {
          this.currentIndex = 1
          if (document.querySelector('.platformNotice')) { document.querySelector('.platformNotice').style.display = 'none' }
          return true
        } else if (this.$route.path === '/institution') {
          this.currentIndex = 2
          if (document.querySelector('.platformNotice')) { document.querySelector('.platformNotice').style.display = 'none' }
          return true
        } else if (this.$route.path === '/journal') {
          this.currentIndex = 3
          if (document.querySelector('.platformNotice')) { document.querySelector('.platformNotice').style.display = 'none' }
          return true
        }
        // this.channelList.some((val, i) => {
        //   if (this.$route.path.includes(val.value)) {
        //     this.currentIndex = i
        //     return true
        //   }
        // })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  margin: 0 auto;
  display: flex;
  width: 1440px;

  .titleimg {
    margin-left: 20px;
    width: 81px;
    height: 21px;
  }

  .more {
    display: flex;
    margin-left: auto;
    margin-right: 0;
    cursor: pointer;

    .check {
      margin-right: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #9C2526;
    }

    .moreimg {
      width: 10px;
      height: 10px;
      margin-top: 5px;
    }
  }
}

.dismain {
  width: 75%;
  margin: 0 auto;
  margin-top: 19px;
  display: flex;
  padding-bottom: 20px;

  .disbox {
    // background-color: #FF7100;
    // flex: 1;
    padding: 10px;
    padding-bottom: 13px;
    background: #FFFFFF;
    border-radius: 4px;
    width: 320px;
    height: 270px;
    margin-top: 3px;
    margin-left: 15px;
    position: relative;
    bottom: 10px;

    .swimg {
      margin-top: 5px;
      margin-left: 5px;
      width: 310px;
      height: 162px;
      border-radius: 4px;
    }

    .count {
      padding-left: 5px;
      padding-right: 5px;
      position: absolute;
      right: 20px;
      top: 19px;
      height: 22px;
      line-height: 22px;
      background: #FF7100;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      color: #FFFFFF;
    }

    .slname {
      margin-top: 14px;
      margin-left: 5px;
      font-size: 16px;
      font-weight: 600;
      color: #000000;
    }

    .word {
      font-size: 14px;
      margin-left: 5px;
      color: #666666;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 16px;
      white-space: nowrap;
    }

    .totaltag {
      margin-left: 5px;
      margin-top: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .subtag {
        padding: 4px 7px 4px 9px;
        background: #F7F7F7;
        border-radius: 4px;
        font-size: 12px;
        color: #9C2526;
        margin-left: 10px;
      }

      .subtag:first-child {
        margin-left: 0px;
      }
    }

  }
}

.subject {
  width: 1401.6px;
  display: block;
  margin: 19.2px auto;
  cursor: pointer;
}

.discontainer {
  padding-top: 20px;
  margin-top: 25px;
  margin: 0 auto;
  height: auto;
  background: #F7F7F7;
  width: 100%;

}

// aside样式
.middlemenu {
  margin: 20px auto;
  width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 24px;

  .channel-item {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    width: 120px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
  }

  .active {
    transition: 1s all;
    background: @my-color;
    color: #fff;
  }
}

.platformNotice {
  width: 128px;
  height: 181px;
  background-color: transparent;
  position: fixed;
  z-index: 1005;
  top: 293px;
  right: 72px;
  padding: 40px 31px 40px 25px;
  animation: bounce-up 1.5s linear infinite;
  border-radius: 6px;
  cursor: pointer;
  background-image: url('../../assets/images/home/noticeBg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .platformTitle {
    // width: 100px;
    line-height: 50px;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    // text-shadow: 1px 1px 1px #000;
    color: #fff;
    opacity: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    img {
      width: 16px;
      height: 17px;
      margin-right: 4px;
    }

    span {
      font-size: 18px;
      font-weight: bold;
      text-shadow: 1px 1px 1px #000;
      color: #fff;
    }
  }

  .platformNoticeInfo {
    height: 111px;
    // background-color: antiquewhite;
    // overflow-y: hidden;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 18px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-all;
  }

  .swiper-container {
    width: 100%;
    height: 100%;

    .platformTitle {
      // width: 100px;
      line-height: 50px;
      height: 50px;
      font-size: 18px;
      font-weight: bold;
      // text-shadow: 1px 1px 1px #000;
      opacity: 0;
      color: #fff;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;

      img {
        width: 16px;
        height: 17px;
        margin-right: 4px;
      }

      span {
        font-size: 18px;
        font-weight: bold;
        text-shadow: 1px 1px 1px #000;
        color: #fff;
      }
    }

    .platformNoticeInfo {
      height: 111px;
      // overflow-y: hidden;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 6;
      // overflow: hidden;
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 18px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      word-break: break-all;
    }
  }

  .close {
    width: 18px;
    height: 18px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@keyframes bounce-up {
  25% {
    transform: translateY(5px)
  }

  50%,
  100% {
    transform: translateY(0)
  }

  75% {
    transform: translateY(-5px)
  }
}
</style>
