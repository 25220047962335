<template>
  <div class="ctx" v-if="showSend" :class="replay" v-loading.fullscreen.lock="loading" element-loading-spinner=none
    element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="content">
      <el-input type="textarea" style="height: 150px;" resize="none" :placeholder="placeholder" v-model="textarea"
        @input="handleCacuSize" @focus="hasfocus" id="input"></el-input>
      <div class="msg-box">
        <div class="msg" v-show="textarea.length > maxLength">
          超出
          <span class="warning">{{ caculateSize }}</span>字
        </div>
        <div class="emo" @click="handleOpenEmo()"></div>
        <picker ref='emoji' v-show="showPicker" :include="['people', 'smileys']" :showSearch="false"
          :showPreview="false" :showCategories="false" @select="addEmoji" />
      </div>
    </div>
    <div class="send">
      <div class="_left">
        <el-switch size="mini" v-model="anonymous" active-color="#9C2526"></el-switch>
        <span class="nm">匿名评论</span>
        <span class="ts">温馨提示：选择匿名后，其他用户将无法查看您的账号昵称、头像</span>
      </div>
      <div class="_right">
        <el-button type="primary" round size="mini" @click="handleApply()"
          :disabled="textarea.length == 0 || textarea.length > this.maxLength">发表</el-button>
      </div>
    </div>
    <login ref="login" class='homeLoginBigBox' @registeropen="registeropen"></login>
    <register ref="register" @loginopen="loginopen" @emitSuccessRegister="$refs.login.modelopen = true"></register>
  </div>
</template>

<script>
import login from '@/page/login'
import register from '@/page/register'
import { Picker } from 'emoji-mart-vue'
import { threeHandleLogin } from '../../utils/threeLogin'
export default {
  props: ['opts', 'closeComment', 'edit'],
  components: {
    Picker,
    login,
    register
  },
  data () {
    return {
      // 加载
      loading: false,
      // 显示评论
      showSend: false,
      // 显示表情包
      showPicker: false,
      // 评论
      replay: '',
      // 默认提示文字
      placeholder: '',
      // 评论内容
      textarea: '',
      // 最大字数
      maxLength: 500,
      // 超出字数
      caculateSize: 0,
      // 匿名
      anonymous: false,
      // 父级评论id
      parentId: 0,
      // 回复评论的id
      replyCommentId: null,
      // 编辑修改id
      id: '',
      originalScholarshipId: ''
    }
  },
  mounted () {
    // this.referenceNumber = this.$route.query.referenceNumber;
    this.originalScholarshipId = this.$route.query.id
    this.openClickEmoji()
  },
  methods: {
    openClickEmoji () {
      // console.log('创造关闭')
      document.addEventListener('click', this.closeEmoji, false)
    },
    closeEmoji (e) {
      // console.log('关闭')
      const boxEl = document.querySelector('.emoji-mart')
      console.log(boxEl)
      if (this.$refs.emoji && !boxEl.contains(e.target) && e.target.className !== 'emo') {
        console.log('进了第一层if')
        console.log('看一下showPicker', this.showPicker)
        if (this.showPicker === true) {
          this.showPicker = false
        }
      }
    },
    removeListener () {
      document.removeEventListener('click', this.closeEmoji, false)
    },
    hasfocus () {
      if (!localStorage.getItem('token') && !localStorage.getItem('userName')) {
        // this.$refs.login.modelopen = true
        return threeHandleLogin(this.THREE_LOGIN_OR)
      } else {
        document.getElementById('input').readOnly = false
      }
    },
    // 敏感词
    async existsSensitiveWords () {
      const { data: res } = await this.$http({
        url: '/originalScholarship/existsSensitiveWords',
        method: 'post',
        data: {
          desc: this.textarea
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      if (res.code == 200) {
        if (res.data.length != 0) {
          const word = res.data.join('、')
          this.$message.error(
            '您的评论中包含敏感词：' + word + '，请重新编辑，发布您的评论哦。'
          )
        }
      }
    },
    // 发表评论
    async getData () {
      this.loading = true
      const { data: res } = await this.$http({
        url: '/originalScholarship/addOrUpdate',
        method: 'post',
        data: {
          id: this.id,
          comment: this.textarea,
          isAnonymous: this.anonymous ? 1 : 0,
          parentId: this.parentId,
          replyCommentId: this.replyCommentId,
          originalScholarshipId: this.originalScholarshipId,
          secCurrent: 1,
          secSize: 2
        }
      })
      this.loading = false
      if (res.code != 200) return this.$message.error(res.msg)
      this.$message.success('评论成功！')
      this.textarea = ''
      this.anonymous = false
      this.id = ''
      if (this.opts) {
        this.close()
      }
      this.$emit('emitSendSuccess')
    },
    // 初始化
    init (replay, user, id, text) {
      this.replay = replay
      if (this.replay == 'replay') {
        this.placeholder = '回复 ' + user + ':'
      } else {
        this.placeholder = '发表您的评论'
      }
      if (this.replay == 'edit') {
        this.id = id
        this.textarea = text
      }
      this.showSend = true
    },
    // 点击发表评论
    handleApply () {
      // 如果没登陆，请登录
      if (!localStorage.getItem('token') && !localStorage.getItem('userName')) {
        // this.$refs.login.modelopen = true
        // return
        return threeHandleLogin(this.THREE_LOGIN_OR)
      }
      this.getData()
    },
    addEmoji (e) {
      this.textarea += e.native
      this.caculateSize = this.textarea.length - this.maxLength
      this.showPicker = false
    },
    // 关闭
    close () {
      this.textarea = ''
      this.showSend = false
    },
    // 计算评论数
    handleCacuSize () {
      // 如果没登陆，请登录
      if (!localStorage.getItem('token') && !localStorage.getItem('userName')) {
        // this.$refs.login.modelopen = true
        // return
        return threeHandleLogin(this.THREE_LOGIN_OR)
      }
      this.caculateSize = this.textarea.length - this.maxLength
      // 监听敏感词
      if (this.textarea) {
        this.existsSensitiveWords()
      }
    },
    // 打开表情包
    handleOpenEmo () {
      if (!localStorage.getItem('token') && !localStorage.getItem('userName')) {
        // this.$refs.login.modelopen = true
        threeHandleLogin(this.THREE_LOGIN_OR)
        document.getElementById('input').readOnly = true
      } else {
        this.showPicker = !this.showPicker
      }
    },
    registeropen (value) {
      this.$refs.register.registershow = value
    },
    loginopen (value) {
      this.$refs.login.modelopen = value
    }
  },
  beforeDestroy () {
    this.removeListener()
  },
  watch: {
    opts: {
      deep: true,
      handler (val) {
        this.parentId = val.parentId || 0
        this.replyCommentId = val.replyCommentId || null
      }
    },
    edit: {
      deep: true,
      handler (val) {
        this.id = val.id
        this.textarea = val.textarea
        this.anonymous = val.anonymous
        this.parentId = val.parentId
        this.replyCommentId = val.replyCommentId
        this.referenceNumber = val.referenceNumber
      }
    },
    closeComment (val) {
      this.close()
    }
  }
}
</script>

<style lang="less" scoped>
.ctx {
  flex: 1;

  .content {
    position: relative;
    margin-left: 20px;
    padding-bottom: 40px;
    border-radius: 10px;
    background: #f5f5f7;

    .el-textarea {
      /deep/textarea {
        height: 100%;
        border-radius: 10px;
        line-height: 24px;
        color: #000;
        padding: 14px 18px;
        background: #f5f5f7;
        border: none;
      }
    }

    .msg-box {
      position: absolute;
      bottom: 10px;
      right: 20px;
      display: flex;
      align-items: center;

      .msg {
        font-size: 14px;
        color: #999999;

        .warning {
          color: #ff0000;
          margin: 0 4px;
        }
      }

      .emo {
        width: 22px;
        height: 23px;
        background: url("../../assets/images/main/comment/emote.png") no-repeat center;
        background-size: contain;
        cursor: pointer;
        transition: all 0.3s;
        margin-left: 20px;

        &:hover {
          transform: translateY(-2px);
        }
      }
    }
  }

  .send {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    margin-top: 20px;

    ._left {
      font-size: 14px;
      display: flex;
      align-items: center;

      .nm {
        color: #333;
        margin-left: 10px;
        margin-right: 15px;
      }

      .ts {
        color: #999;
      }
    }

    ._right {
      .el-button {
        padding-left: 26px;
        padding-right: 26px;
      }
    }
  }

  &.replay {
    .content {
      margin-left: 0;
      margin-top: 20px;
    }

    .send {
      margin-left: 0;
    }
  }
}

/deep/.emoji-mart {
  position: absolute;
  width: 430px;
  height: 167px !important;
  right: 0;
  top: 0;
  z-index: 9;
  transform: translateY(-100%);

  .emoji-mart-category-label {
    display: none !important;
  }
}
</style>
