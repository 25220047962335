<template>
  <div style="display: flex; position: relative">
    <div
      ref="left"
      :class="{
        left: isLeft === true,
        left1: isLeft1 === true,
        fixed2: isFixed === true,
      }"
    >
      <div
        class="margintitle"
        @click="tab(obj.explanationMeaningContentList[0].title, 1)"
      >
        <img src="../../assets/images/home/concept.png" alt class="icon1" />
        <div v-if="tabVal === 1" class="title active">
          概念阐释及其学术意义、现实意义
        </div>
        <div v-else class="title">概念阐释及其学术意义、现实意义</div>
      </div>
      <div
        v-for="(item, index) in obj.explanationMeaningContentList"
        :class="{ backactive: currenttitle === item.title + '1' }"
        @click="tabDetail(item.title, 1)"
        :key="index + item.title + Math.random()"
      >
        <div class="title2" v-if="item.content != '<p><br></p>'">
          {{ item.title }}
        </div>
      </div>
      <div
        class="margintitle"
        @click="tab(obj.paperListContentList[0].title, 2)"
        v-if="obj.paperListContentList"
      >
        <img src="../../assets/images/home/list.png" alt class="icon2" />
        <div v-if="tabVal === 2" class="title active">论文、论著发表清单</div>
        <div v-else class="title">论文、论著发表清单</div>
      </div>
      <div
        v-for="(item, index) in obj.paperListContentList"
        :key="index + item.title + Math.random()"
        :class="{ backactive: currenttitle === item.title + '2' }"
        @click="tabDetail(item.title, 2)"
      >
        <div class="title2" v-if="item.content != '<p><br></p>'">
          {{ item.title }}
        </div>
      </div>
      <div
        class="margintitle"
        @click="tab(obj.academicFeedbackContentList[0].title, 3)"
        v-if="obj.academicFeedbackContentList"
      >
        <img src="../../assets/images/home/feed.png" alt class="icon3" />
        <div v-if="tabVal === 3" class="title active">学术界的反馈与好评</div>
        <div v-else class="title">学术界的反馈与好评</div>
      </div>
      <div
        v-for="(item, index) in obj.academicFeedbackContentList"
        :key="index + item.title + Math.random()"
        :class="{ backactive: currenttitle === item.title + '3' }"
        @click="tabDetail(item.title, 3)"
      >
        <div class="title2" v-if="item.content != '<p><br></p>'">
          {{ item.title }}
        </div>
      </div>
      <div
        class="margintitle"
        @click="tab(obj.internationalComparisonContentList[0].title, 4)"
        v-if="obj.internationalComparisonContentList"
      >
        <img src="../../assets/images/home/world.png" alt class="icon4" />
        <div v-if="tabVal === 4" class="title active">
          国际同类研究的比较与优势
        </div>
        <div v-else class="title">国际同类研究的比较与优势</div>
      </div>
      <div
        v-for="(item, index) in obj.internationalComparisonContentList"
        :key="index + item.title + Math.random()"
        :class="{ backactive: currenttitle === item.title + '4' }"
        @click="tabDetail(item.title, 4)"
      >
        <div class="title2" v-if="item.content != '<p><br></p>'">
          {{ item.title }}
        </div>
      </div>
      <div
        class="margintitle"
        @click="tab(obj.otherSituationNoteContentList[0].title, 5)"
        v-if="obj.otherSituationNoteContentList"
      >
        <img src="../../assets/images/home/other.png" alt class="icon5" />
        <div v-if="tabVal === 5" class="title active">其他情况备注</div>
        <div v-else class="title">其他情况备注</div>
      </div>
      <div
        v-for="(item, index) in obj.otherSituationNoteContentList"
        :key="index + item.title"
        :class="{ backactive: currenttitle === item.title + '5' }"
        @click="tabDetail(item.title, 5)"
      >
        <div class="title2" v-if="item.content != '<p><br></p>'">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div
      class="right"
      id="right"
      :class="{ ma2: isMa2 === true, ma3: isMa3 === true }"
    >
      <div class="empty"></div>
      <div class="top">
        <div class="back"></div>
        <p class="title" v-html="define.content"></p>
      </div>
      <div class="context">
        <div
          v-for="(item, index) in obj.explanationMeaningContentList"
          :key="index + item.title + Math.random()"
        >
          <div v-if="item.content != '<p><br></p>'">
            <div
              class="title active"
              v-if="currenttitle == item.title + '1'"
              :id="item.title + '1'"
            >
              {{ item.title }}
            </div>
            <div class="title" v-else :id="item.title + '1'">
              {{ item.title }}
            </div>
            <div v-html="item.content" class="paragraph"></div>
          </div>
        </div>
        <div class="keyword" v-if="obj.paperMarkedWordMap?.['#keyword#']">
          <div class="icon"></div>
          <span class="tiptext" v-html="obj.paperKeywordDescription"></span>
        </div>
        <div
          v-for="(item, index) in obj.paperListContentList"
          :key="index + item.title + Math.random()"
        >
          <div v-if="item.content != '<p><br></p>'">
            <div
              class="title active"
              v-if="currenttitle == item.title + '2'"
              :id="item.title + '2'"
            >
              {{ item.title }}
            </div>
            <div class="title" v-else :id="item.title + '2'">
              {{ item.title }}
            </div>
            <div v-html="item.content" class="paragraph"></div>
          </div>
        </div>
        <div
          v-for="(item, index) in obj.academicFeedbackContentList"
          :key="index + item.title + Math.random()"
        >
          <div v-if="item.content != '<p><br></p>'">
            <div
              class="title active"
              v-if="currenttitle == item.title + '3'"
              :id="item.title + '3'"
            >
              {{ item.title }}
            </div>
            <div class="title" v-else :id="item.title + '3'">
              {{ item.title }}
            </div>
            <div
              class="keyword"
              v-if="item.markedWordMap && item.markedWordMap?.['#keyword#']"
            >
              <div class="icon"></div>
              <span class="tiptext" v-html="item.keywordDescription"></span>
            </div>
            <div v-html="item.content" class="paragraph"></div>
          </div>
        </div>
        <div
          v-for="(item, index) in obj.internationalComparisonContentList"
          :key="index + item.title + Math.random()"
        >
          <div v-if="item.content != '<p><br></p>'">
            <div
              class="title active"
              v-if="currenttitle == item.title + '4'"
              :id="item.title + '4'"
            >
              {{ item.title }}
            </div>
            <div class="title" v-else :id="item.title + '4'">
              {{ item.title }}
            </div>
            <div v-html="item.content" class="paragraph"></div>
          </div>
        </div>
        <div
          v-for="(item, index) in obj.otherSituationNoteContentList"
          :key="index + item.title + Math.random()"
        >
          <div v-if="item.content != '<p><br></p>'">
            <div
              class="title active"
              v-if="currenttitle == item.title + '5'"
              :id="item.title + '5'"
            >
              {{ item.title }}
            </div>
            <div class="title" v-else :id="item.title + '5'">
              {{ item.title }}
            </div>
            <div v-html="item.content" class="paragraph"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["msg"],
  components: {},
  data() {
    return {
      id: "",
      obj: "",
      tabVal: "",
      currenttitle: "",
      define: "",
      isLeft: true,
      isLeft1: false,
      isFixed: false,
      isMa2: true,
      isMa3: false,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getdisciplinedetail();
    window.addEventListener("scroll", this.fixedActiveBtn);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.fixedActiveBtn, true);
  },
  methods: {
    fixedActiveBtn() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (scrollTop == 0) {
        // 此时滚动条处于页面的顶部
        this.isLeft = true;
        this.isLeft1 = false;
        this.isFixed = false;
        this.isMa2 = true;
        this.isMa3 = false;
        this.currenttitle = "";
        this.tabVal = "";
      }
      if (
        document.documentElement.scrollHeight -
          document.documentElement.clientHeight >
        590
      ) {
        const bottom =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight -
          271;
        const topheight = this.msg;
        const marg = parseInt(
          getComputedStyle(document.querySelector(".left")).marginTop
        );
        const margbbom = parseInt(
          getComputedStyle(document.querySelector(".left")).marginBottom
        );
        const scrollTop = document.documentElement.scrollTop;
        if (scrollTop < topheight + marg) {
          // 状态1
          this.isLeft = true;
          this.isLeft1 = false;
          this.isFixed = false;
          this.isMa2 = true;
          this.isMa3 = false;
        } else if (scrollTop >= topheight + marg && scrollTop < bottom) {
          // 状态2
          this.isLeft = true;
          this.isLeft1 = false;
          this.isFixed = true;
          this.isMa2 = false;
          this.isMa3 = true;
        } else if (scrollTop >= bottom) {
          // 状态3
          this.isLeft = false;
          this.isLeft1 = true;
          this.isFixed = false;
          this.isMa2 = false;
          this.isMa3 = true;
        }
      }
    },
    async getdisciplinedetail() {
      const { data: res } = await this.$http({
        url: "/cn_academic_concept/find_one/" + this.id,
        method: "get",
      });
      if (res.code != 200) return this.$message.error(res.msg);
      this.$emit("OriginalDeatilPost", res.data);
      this.define = res.data.explanationMeaningContentList[0];
      res.data.explanationMeaningContentList =
        res.data.explanationMeaningContentList.splice(1);
      if (res.data.paperMarkedWordMap?.["#keyword#"]) {
        res.data.paperKeywordDescription =
          res.data.paperKeywordDescription.replace(
            `“${res.data.paperMarkedWordMap["#keyword#"]}”`,
            `<span>“${res.data.paperMarkedWordMap["#keyword#"]}”</span>`
          );
      }
      if (res.data.academicFeedbackContentList) {
        res.data.academicFeedbackContentList.forEach((item) => {
          if (item.markedWordMap?.["#keyword#"]) {
            item.keywordDescription = item.keywordDescription.replace(
              `“${item.markedWordMap["#keyword#"]}”`,
              `<span>“${item.markedWordMap["#keyword#"]}”</span>`
            );
          }
          if (item.title == "转载情况") {
            if (item.markedWordMap?.["#count#"]) {
              item.keywordDescription = item.keywordDescription.replace(
                `${item.markedWordMap["#count#"]}`,
                `<span>${item.markedWordMap["#count#"]}</span>`
              );
            } else {
              item.keywordDescription = item.keywordDescription.replace(
                "概念相关论文共被转载#count#次，",
                ""
              );
            }
          }
        });
      }
      this.obj = res.data;
    },
    tab(val, num) {
      this.tabVal = num;
      window.scrollTo(0, document.getElementById(val).offsetTop);
      this.currenttitle = "";
    },
    tabDetail(title, val) {
      this.tabVal = val;
      this.currenttitle = title + val;
      window.scrollTo(0, document.getElementById(this.currenttitle).offsetTop);
    },
  },
};
</script>
<style lang="less" scoped>
.fixed2 {
  position: fixed;
}

.ma2 {
  margin-left: 20px;
}

.ma3 {
  margin-left: 328px;
}

.left1 {
  position: absolute;
  bottom: 0;
  width: 260px;
  height: 720px;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;
  margin-left: 3px;
  margin-top: 2px;
  margin-bottom: 30px;
  padding: 8px 20px 30px 20px;
  word-wrap: break-word;

  .icon1 {
    width: 14px;
    height: 16px;
    margin-top: 5px;
  }

  .icon2 {
    width: 13px;
    height: 14px;
    margin-top: 5px;
  }

  .icon3 {
    width: 15px;
    height: 13px;
    margin-top: 5px;
  }

  .icon4 {
    width: 15px;
    height: 15px;
    margin-top: 5px;
  }

  .icon5 {
    width: 13px;
    height: 16px;
    margin-top: 5px;
  }

  .title {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }

  .title:hover {
    color: #9c2526;
  }

  .active {
    color: #9c2526;
  }

  .title2 {
    background: rgba(247, 238, 238, 0);
    border-left: 2px solid rgba(247, 238, 238, 0);
    border-radius: 2px;
    margin-left: 13px;
    margin-top: 7px;
    padding-left: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
  }

  .backactive {
    .title2 {
      background: rgb(247, 238, 238);
      border-left: 2px solid #9c2526;
      border-radius: 2px;
      margin-left: 13px;
      margin-top: 7px;
      padding-left: 13px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 16px;
      font-weight: 400;
      color: #9c2526;
      cursor: pointer;
    }
  }

  .title2:hover {
    background: rgb(247, 238, 238);
    border-left: 2px solid #9c2526;
    color: #9c2526;
  }

  .margintitle {
    margin-top: 12px;
    display: flex;
    // align-items: center;
    cursor: pointer;
  }
}

.left {
  top: 140px;
  width: 260px;
  height: 720px;
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;
  margin-left: 3px;
  margin-top: 2px;
  margin-bottom: 30px;
  padding: 8px 20px 30px 20px;
  word-wrap: break-word;

  .icon1 {
    width: 14px;
    height: 16px;
    margin-top: 5px;
  }

  .icon2 {
    width: 13px;
    height: 14px;
    margin-top: 5px;
  }

  .icon3 {
    width: 15px;
    height: 13px;
    margin-top: 5px;
  }

  .icon4 {
    width: 15px;
    height: 15px;
    margin-top: 5px;
  }

  .icon5 {
    width: 13px;
    height: 16px;
    margin-top: 5px;
  }

  .title {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }

  .title:hover {
    color: #9c2526;
  }

  .active {
    color: #9c2526;
  }

  .title2 {
    background: rgba(247, 238, 238, 0);
    border-left: 2px solid rgba(247, 238, 238, 0);
    border-radius: 2px;
    margin-left: 13px;
    margin-top: 7px;
    padding-left: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
  }

  .backactive {
    .title2 {
      background: rgb(247, 238, 238);
      border-left: 2px solid #9c2526;
      border-radius: 2px;
      margin-left: 13px;
      margin-top: 7px;
      padding-left: 13px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 16px;
      font-weight: 400;
      color: #9c2526;
      cursor: pointer;
    }
  }

  .title2:hover {
    background: rgb(247, 238, 238);
    border-left: 2px solid #9c2526;
    color: #9c2526;
  }

  .margintitle {
    margin-top: 12px;
    display: flex;
    // align-items: center;
    cursor: pointer;
  }
}

.right {
  margin-bottom: 30px;

  .empty {
    height: 44px;
  }

  .top {
    width: 1090px;
    min-height: 120px;
    background: #9c2526;
    border-radius: 30px 10px 10px 10px;
    position: relative;
    padding-bottom: 30px;

    .back {
      width: 100%;
      height: 197px;
      background-image: url("../../assets/images/home/back.png");
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100%;
      bottom: 0;
    }

    .title {
      padding: 30px 70px 0 42px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
      position: relative;
      text-indent: 2ch;
      word-break: break-all;
    }

    .author {
      padding: 0 70px 27px 42px;
      font-size: 16px;
      margin-top: 16px;
      font-weight: 400;
      color: #ffffff;
      text-align: right;
      position: relative;
    }
  }

  .context {
    padding-left: 11px;
    padding-right: 5px;
    width: 1080px;

    .keyword {
      margin-top: 20px;
      display: flex;
      position: relative;

      .tiptext {
        margin-top: 4px;
        margin-left: 17px;
        padding-left: 28px;
        width: 1080px;
        height: 30px;
        background: rgba(255, 113, 0, 0.08);
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 30px;

        /deep/span {
          font-size: 16px;
          font-weight: 400;
          color: #ff7100;
        }
      }

      .icon {
        width: 34px;
        height: 37px;
        background: url("../../assets/images/home/keyword.png");
        background-size: cover;
        background-position: center center;
        position: absolute;
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #000000;
      margin-top: 30px;
    }

    .active {
      color: #9c2526;
    }

    .paragraph {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
      margin-top: 20px;
      text-indent: 2em;
      word-break: break-all;

      /deep/p {
        span {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 32px;
        }
      }
    }
  }
}
</style>
