<template>
  <div class="opinionsClose" v-if="opinionsopen">
    <div class="loginBox">
      <div class="box">
        <div class="title">意见反馈</div>
        <div class="closeBtnBox" @click="handleLogincancel">
          <img :src="require('../../assets/images/login/closeBtn.png')" alt />
        </div>
      </div>
      <el-form label-position="left" label-width="100px" class="myform">
        <el-form-item label="*问题描述:">
          <label slot="label"><span style="color: red">*</span> 问题描述:</label>
          <clearableTextInput :rows="3" :placeholder="placeholder" v-model="form.problemDesc" :values="form.problemDesc"
            :clearable="true"></clearableTextInput>
          <!-- <el-input type="textarea" v-model="form.problemDesc" :rows="3" placeholder="请输入内容" maxlength="200"
            show-word-limit>
          </el-input> -->
        </el-form-item>
        <el-form-item label="上传附件:">
          <label slot="label"><span style="color: red;opacity: 0;">*</span> 上传附件:</label>
          <el-upload ref="upload" class="upload-demo" action="action" :limit="6" :http-request="uploadFile"
            :on-preview="handlePreview" :on-exceed="handleExceed" :on-remove="handleRemove" :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="*手机账号:">
          <label slot="label">
            <span style="color: red;opacity: 0;">*</span>
            手机号:
          </label>
          <el-input v-model="form.phone" placeholder="请输入您的手机号码,以便与您联系" maxlength="11" clearable>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="邮箱账号:">
          <label slot="label"><span style="opacity: 0;">*</span> 邮箱账号:</label>
          <el-input v-model="form.email" placeholder="请输入您的邮箱号码,以便与您联系" maxlength="30">
          </el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialogfooter">
        <el-button @click="handleLogincancel">取 消</el-button>
        <el-button type="primary" @click="handleSaveBtn">确 定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import clearableTextInput from '../common/ClearableTextInput.vue'
export default {
  components: {
    clearableTextInput
  },
  data() {
    return {
      opinionsopen: false,
      form: {
        phone: '', // 手机号
        // email: '', // 邮箱
        problemDesc: '' // 问题描述
      },
      acceptType: ['jpg', 'jpeg', 'png', 'doc', 'docx', 'rtf', 'pdf'],
      fileList: [],
      attach: [],
      file: '',
      listName: [],
      placeholder: '请输入内容'
    }
  },
  mounted() {
    this.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('token') || ''
    }
  },

  methods: {

    handleExceed() {
      this.$message.error('最多上传六个附件')
    },
    handlePreview(file) {
      const link = document.createElement('a')
      link.href = this.file
      link.setAttribute('download', file.name)
      console.log(link)
      link.click()
    },
    // 点击取消
    handleLogincancel() {
      this.opinionsopen = false
      this.form = {
        phone: '', // 手机号
        // email: '', // 邮箱
        problemDesc: '' // 问题描述
      }
      this.fileList = []
      this.file = ''
      this.listName = []
      this.$refs.upload.uploadFiles = []
      this.attach = []
    },
    handleRemove(file, fileList) {
      var num = this.listName.findIndex(item => item == file.name)
      this.fileList.splice(num, 1)
      this.listName.splice(num, 1)
    },
    // 上传附件
    async uploadFile(item) {
      // 获取文件后缀
      const fileSuffix = item.file.name.substring(item.file.name.lastIndexOf('.') + 1)
      if (this.acceptType.indexOf(fileSuffix) === -1) {
        this.$message.error('只能上传pdf,word和图片')
        this.$refs.upload.uploadFiles.filter(item => item.name !== item.file.name)
        return
      }
      const up = item.file
      const upfile = new FormData()
      upfile.append('file', up)
      const res = await this.$http({
        url: '/literSuggestion/upload',
        method: 'POST',
        data: upfile
      })
      if (res.data.code !== 200) {
        // 清除缓存
        this.$refs.upload.uploadFiles.filter(item => item.name !== item.file.name)
        return this.$message.error(res.data.msg)
      }
      this.file = res.data.data.url + res.data.data.attach
      this.fileList.push(res.data.data.url + res.data.data.attach)
      this.listName.push(item.file.name)
      this.$message.success(res.data.msg)
    },
    async handleSaveBtn() {
      console.log(this.attach)
      for (let i = 0; i < this.fileList.length; i++) {
        this.attach.push(this.listName[i] + ',' + this.fileList[i])
      }
      this.attach = this.attach.join(';')
      const res = await this.$http({
        url: '/literSuggestion/saveAdvice',
        method: 'POST',
        data: {
          attachUrl: this.attach,
          // email: this.form.email,
          phone: this.form.phone,
          problemDesc: this.form.problemDesc,
          type: 0,
          username: this.$store.state.userName
        }
      })
      if (res.data.code !== 200) {
        this.attach = []
        return this.$message.error(res.data.msg)
      }
      this.$message.success(res.data.msg)
      this.opinionsopen = false
      this.form = {
        phone: '', // 手机号
        // email: '', // 邮箱
        problemDesc: '' // 问题描述
      }
      this.fileList = []
      this.file = ''
      this.listName = []
      this.$refs.upload.uploadFiles = []
      this.attach = []
    }
  }
}
</script>

<style lang="less" scoped>
.opinionsClose {
  transition: all 0.5s;
  position: fixed;
  top: 550px;
  right: 250px;
  // bottom: 0;
  // left: 0;
  z-index: 2000;
  border-radius: 10px;
  // background: rgba(0, 0, 0, 0.2);
}

.loginBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background: #ffffff;
  border-radius: 4px;
  padding-top: 20px;
  z-index: 999;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;

  .box {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
      margin-left: 20px;
    }

    .closeBtnBox {
      display: flex;
      justify-content: flex-end;
      margin-right: 21px;
      padding-bottom: 28px;

      img {
        width: 11px;
        height: 11px;
      }

      img:hover {
        content: url('../../assets/images/login/close.png')
      }
    }
  }

  .myform {
    font-size: 20px;
    font-weight: 550;
    padding-left: 60px;
    padding-right: 80px;
    margin-top: 20px;
  }

  .dialogfooter {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
  }

}

.uploadprompt {
  color: #999999;
  font-size: 14px;
  margin-top: 10px;
  font-weight: normal;
  width: 459px;
  height: 15px;
  line-height: 15px;
}
</style>
