<template>
  <div>
    <div>
      <headerComponent></headerComponent>
    </div>
    <div class="top">
      <div class="title">用户协议</div>
      <img src="../../assets/images/home/intimg.png" class="topimg" />
    </div>
    <div class="contain">
      <div class="data">
        <div>更新日期：2024年 2 月 18 日</div>
        <div>生效日期：2024年 2 月 18 日</div>
      </div>
      <div class="box">
        <div class="boxtitle">欢迎您使用&nbsp;<span>哲学社会科学主文献平台（简称“哲社主文献”）。</span></div>
        <p class="text" style="margin-top: 41px;">
          哲社主文献是由中国人民大学建设，该平台为中国人民大学中国哲学社会科学自主知识体系数字创新平台（简称“学术世界”）的子平台，并由中国人民大学书报资料中心承建运营，集中展现中国哲学社会科学各学科最优秀的哲社主文献资源并提供知识服务（注册地址：北京市海淀区中关村大街59号）。哲社主文献平台通过网站（https://zszwx.cn/）为您提供服务。
          <br />
          <br />
        </p>
        <p class="text">
          请您务必仔细阅读、充分理解本协议内容，尤其字体加粗的部分。您一旦注册哲社主文献平台，即视为您已了解并完全同意《哲学社会科学主文献平台用户协议》（下称“本协议”）的内容。如您不同意本协议或哲社主文献平台对本协议条款所做的任何修改，请您立即停止访问和/或使用哲社主文献平台提供的全部服务。
          <br />
          <br />
        </p>
        <p class="text" style="text-indent: 0;">
          <strong>本协议包括：</strong>
          <br />
        <p class="tl"><strong>一、协议范围</strong></p>
        <p class="tl"><strong>二、关于本服务</strong></p>
        <p class="tl"><strong>三、用户注册、账户管理及账户安全</strong></p>
        <p class="tl"><strong>四、用户行为规范</strong></p>
        <p class="tl"><strong>五、知识产权保护专条</strong></p>
        <p class="tl"><strong>六、数据（含个人信息）保护</strong></p>
        <p class="tl"><strong>七、免责声明</strong></p>
        <p class="tl"><strong>八、通知</strong></p>
        <p class="tl"><strong>九、其他</strong></p>
        </p>
        <div class="title" style="margin-top: 49px;">一、协议范围</div>
        <p class="text2">1. 协议适用主体范围</p>
        <p class="indent">本协议是您与哲社主文献平台之间关于您使用<strong>哲学社会科学主文献平台（网址：https://zszwx.cn/）</strong>的所有产品及服务所订立的协议。</p>
        <p class="text2">2. 用户</p>
        <p class="indent">
          “用户”指所有直接或间接获取和使用哲社主文献平台者，包括自然人、法人和其他组织等。在本协议中称为“用户”或“您”。“用户”包括注册用户及未注册用户。凡未注册我们的产品及/或服务的用户，自开始使用我们的产品及/或服务时即成为我们的“非注册用户”，在使用过程中须遵循本协议中除注册用户专属约定以外的其他所有条款。
        </p>
        <p class="text2">3. 协议关系及冲突条款</p>
        <p class="indent">
          本协议内容同时包括哲社主文献平台未来发布的关于本协议的补充、更正、更新，以及与服务相关的协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，请您及时查看更新内容并确认是否接受。</p>
        <div class="title">二、关于本服务</div>
        <p class="text2">1. 本服务的内容</p>
        <p class="indent">
          本服务是指向用户提供的使用哲社主文献平台网站的如下服务：针对用户本人入选哲社主文献平台的文献进行 <strong>认领并进行授权</strong>，以加速学术传播、扩大学术影响力。
        </p>
        <p class="text2">2. 本服务的形式</p>
        <p class="indent">您使用本服务需要哲社主文献平台网页端（网址：https://zszwx.cn/），您仅可为访问或使用哲社主文献平台的目的而使用这些产品及服务。</p>
        <div class="title">三、用户注册、账户管理及账户安全</div>
        <p class="text2">（一）账户注册</p>
        <p class="indent">
          1. 为了更好地享受哲社主文献平台所提供的服务，您应提供真实、准确、最新及完整的个人信息及其他资料进行注册。
          如您故意提供虚假的个人信息进行注册，视为严重违反本协议，哲社主文献平台有权暂停或终止该账号并停止服务。如因此给哲社主文献平台造成经济、名誉等任何损失的，哲社主文献平台将追究您的法律责任。
        </p>
        <p class="indent">
          2. <strong>您不得以营利为目的、采取任何不正当手段或以违反诚实信用原则的方式等为自己或他人开通本服务。</strong>
        </p>
        <p class="indent">
          3. <strong>您对通过您的账户所进行的所有活动、行为和事件依法享有权利和承担责任 ,
            且不得售卖、转借账户。如果您未保管好自己的账号和密码而对您自己、哲社主文献平台或第三方造成了损害，您需负全部责任。</strong>
        </p>
        <p class="text2">（二）账户管理与安全</p>
        <p class="indent">
          1.完成本服务的注册程序并成功注册之后，您可使用您的手机号码和验证码，登录到您在哲社主文献平台的账号（下称“账号”）。
        </p>
        <p class="indent">
          2. 您在使用哲社主文献平台的认领和授权功能时候均需要登录账号。
        </p>
        <p class="indent">
          3.您有义务保证密码及账号的安全。您应对利用该密码及账号所进行的一切活动负全部责任。
        </p>
        <p class="indent">
          4.当您完成哲社主文献平台账号注册程序后，获得的账号使用权归属于您个人。该账号仅供初始注册人使用，不得赠与、借用、租用、转让或出售。其他个人不得以任何方式使用您的账号，包括但不限于受赠、继承、承租、受让等方式。
        </p>
        <p class="indent">
          5. 若我们发现或合理怀疑账号使用者非初始注册人，为保障账号及平台安全，我们有权立即暂停或终止该账号的服务，并有权永久禁用该账号。您将对此造成的其他用户隐私泄露、经济损失以及平台损失负全部责任。
        </p>
        <p class="indent">
          6.哲社主文献平台采取加密等安全措施保障您信息的安全性，但请注意，在网络环境中并不存在绝对安全。若因黑客入侵或您的保管疏忽导致账号、密码被他人非法使用，哲社主文献平台不承担责任。如发现未经授权或非法使用您账户的情况，应立即通知哲社主文献平台。
        </p>
        <div class="title">四、用户行为规范</div>
        <p class="text2">（一）行为规范</p>
        <p class="indent">
          1.如您通过哲社主文献平台上传或发布属于您原创或您有权发表的文字内容。您必须保证，您拥有您所上传内容的知识产权等相关权利或已获得合法授权，您使用哲社主文献平台过程中实施的任何行为均未违反我国法律、法规、规章、监管政策等的规定，也未侵犯任何第三方的合法权益。如果您上传或发布的内容违反法律法规或者侵犯任何第三方的合法权益，您将直接承担其导致的一切不利后果。<strong>如因此给哲社主文献平台造成不利后果的，您应负责消除影响，并且赔偿哲社主文献平台因此导致的一切损失，包括但不限于罚金、财产损害赔偿金、名誉损害赔偿金、诉讼费、仲裁费、和解费、律师费、交通费等因此而产生的一切费用。</strong>
        </p>
        <p class="indent">
          <strong>
            2.哲社主文献平台有权依据法律、法规的规定对您上传、发布或传输的内容进行管理，如存在法律、法规等禁止发布或者传输的内容，或利用本服务从事包括但不限于以下行为的，哲社主文献平台将立即停止传输该内容，采取消除等处置措施，防止内容扩散，保存有关记录，并向有关主管部门报告：</strong><br />
          <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
            发布、发送、传输、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽或含有任何性或性暗示的、暴力的内容；<br /></strong>
          <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
            发布、发送、传输、储存侵害他人名誉权、肖像权、姓名权、隐私权、个人信息、知识产权、商业秘密等合法权利的内容；<br /></strong>
          <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 发布、发送、传输、储存侮辱或者诽谤他人，涉及他人隐私、个人信息或资料的内容；<br /></strong>
          <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 发布、发送、传输、储存骚扰信息、广告信息及垃圾信息；<br /></strong>
          <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• 发布、发送、传输、储存虚假信息或其他含有不实信息的内容；<br /></strong>
          <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
            发布、发送、传输、储存其他违反法律法规、政策及公序良俗、社会公德或干扰“哲社主文献平台”正常运营和侵犯其他用户或第三方合法权益内容的信息。<br /></strong>
          <strong>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如您提供的上传内容含有以上违反政策法律法规的信息或者内容的，由您直接承担以上导致的一切不利后果，如因此给哲社主文献平台造成不利后果的，您应负责消除影响，并且赔偿因此导致的一切损失。</strong>
        </p>
        <p class="indent">
          <strong>3.
            您或其他用户通过哲社主文献平台上传、发布的内容不代表哲社主文献平台的立场，哲社主文献平台也不会对该等信息的完整性、真实性和可靠性负责。因前述信息对您造成的任何损失或伤害，有相关行为主体自行承担责任。</strong>
        </p>
        <p class="text2">（二）违约处理</p>
        <p class="indent">
          <strong> 1.
            哲社主文献平台有权对您使用哲社主文献平台的情况进行审查和监督（包括但不限于对您存储在哲社主文献平台的内容进行审核），如您在使用网络服务时违反本协议任何规定，哲社主文献平台或其授权主体有权要求您改正或直接采取一切必要的措施（包括但不限于更改或删除您已发布的内容、暂停或终止您使用网络服务的权利等）以减轻因您的不当行为造成的影响。对于违反有关法律法规、侵犯他人知识产权、隐私和名誉等内容，哲社主文献平台有保存相关记录，并依法向权利人和/或有关部门提供的权利。</strong>
        </p>
        <p class="indent">
          <strong> 2.
            您理解并同意，哲社主文献平台有权依合理判断对违反有关法律法规或本协议规定的行为进行惩罚，有权对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息并向有关部门报告等，违规用户应独自承担由此而产生的一切法律责任。</strong>
        </p>
        <p class="indent">
          <strong>3.
            您同意保障和维护哲社主文献平台及其他用户的利益，如因您违反有关法律、法规或本协议项下的任何条款而给哲社主文献平台或任何其他第三人造成损失，您同意承担由此造成的全部损害赔偿责任（包括但不限于罚金、财产损害赔偿金、名誉损害赔偿金、诉讼费、仲裁费、和解费、律师费、交通费等因此而产生的一切费用）。</strong>
        </p>
        <div class="title">五、知识产权保护专条</div>
        <p class="indent">
          1. 您应确保在本平台上发表的各类信息内容均不涉及侵犯第三方肖像、隐私、知识产权或其他合法权益。否则我们有权随时采取包括但不限于屏蔽、删除、断开链接等必要措施。
        </p>
        <p class="indent">
          2.
          本平台所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是我们或其内容提供者的财产，受中国和国际版权法的保护。本平台上所有内容的汇编是我们的排他财产，受中国和国际版权法的保护。本平台上所有软件都是我们或我们关联公司或其软件供应商的财产，受中国和国际版权法的保护。
        </p>
        <p class="indent">
          3. 我们对本平台在运营过程中产生的一切数据均享有合法权益，包括但不限于用户的账号、用户在平台发布的其他信息等，以及本平台对上述数据进行加工形成的衍生数据。。
        </p>
        <p class="indent">
          4. 除法律另有强制性规定外，未经我们明确的书面同意，任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本平台的信息内容，否则，我们有权追究其法律责任。
        </p>
        <div class="title">六、数据（含个人信息）保护</div>
        <p class="indent">
          为正常使用哲社主文献平台的服务，我们需要收集您的相关信息。我们也一贯重视个人信息安全，会依据相关法律法规，严格保护您的个人信息，关于您的个人信息保护详情，请参见<span>《隐私政策》</span>。
        </p>
        <div class="title">七、免责声明</div>
        <p class="indent">
          1.
          您理解并同意，哲社主文献平台可能会受包括但不限于用户原因、网络服务质量、社会环境等多种因素影响，也可能会受包括但不限于他人非法利用用户资料进行现实中的骚扰等各种安全问题的侵扰，以及可能发生用户下载安装的其他软件或访问的其他网站中含有病毒、木马程序或其他恶意程序，威胁您的终端设备信息和数据安全，继而影响哲社主文献平台的正常使用等问题。因此，您应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。
        </p>
        <p class="indent">
          <strong>2.
            维护产品安全与正常使用是哲社主文献平台和您的共同责任，哲社主文献平台将按照行业标准合理审慎地采取必要技术措施保护您的终端设备信息和数据安全，但是您认可并同意哲社主文献平台并不能就此提供完全保证。</strong>
        </p>
        <p class="indent">
          <strong> 3.
            鉴于网络服务的特殊性，您同意哲社主文献平台有权随时变更、中断或终止部分或全部的网络服务。您对哲社主文献平台提供的网络服务承担使用风险。哲社主文献平台不保证服务一定会满足您的使用要求，也不保证服务不会中断，哲社主文献平台对服务的及时性、安全性、准确性也不作担保。</strong>
        </p>
        <p class="indent">
          <strong> 4.
            您理解并同意：哲社主文献平台需要定期或不定期地对提供服务的平台或相关的设备进行检修或者维护，如因此造成收费网络服务在合理时间内的中断，哲社主文献平台无需为此承担任何责任，但哲社主文献平台将可能事先进行通告。</strong>
        </p>
        <p class="indent">
          <strong> 5. 如发生下列任何一种情形，哲社主文献平台有权随时中断或终止向您提供本协议项下的服务而无需对您或任何第三方承担任何责任 ：</strong> <br />
          <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（1）您主动提出要求的；<br /></strong>
          <strong>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（2）在任何情况下，哲社主文献平台合理地认为您的行为可能违反中华人民共和国有关法律法规和规范性文件；<br /></strong>
          <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
            您提供的个人资料不真实；<br /></strong>
          <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
            您存在违反本协议内容的其他行为；<br /></strong>
          <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
            根据法律法规、监管政策的规定或有权机关的要求；<br /></strong>
          <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•
            为维护账号与系统安全等紧急情况之需要；<br /></strong>
          <strong>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•不可抗力（不可抗力是指不能预见、不能避免且不能克服的客观情况，鉴于互联网之特殊性质，本协议所称不可抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制而造成之暂时关闭、病毒侵袭等影响互联网正常运行之情形）；</strong>
        </p>
        <div class="title">八、通知</div>
        <p class="indent">
          哲社主文献平台所有发给您的通知将通过手机短信、电子邮件、站内通知或在网站显著位置公告的方式进行传送。我们将通过上述方法之一将消息传递给您，告知您本协议的修改、服务变更、或其它重要事情。
        </p>
        <div class="title">九、其他</div>
        <p class="indent">
          <strong> 1.
            您使用哲社主文献平台即视为您已阅读并同意受本协议的约束。哲社主文献平台有权在必要时修改本协议条款。您可以在哲社主文献平台的最新版本中查阅相关协议条款。本协议条款变更后，我们将作出相应提示，如果您继续使用哲社主文献平台，请及时查看并确认是否接受。</strong>
        </p>
        <p class="indent">
          <strong> 2.
            如您发现哲社主文献平台内存在任何侵犯您权利的内容，请立即通过以下渠道通知哲社主文献平台，提供您有相关权利的证据，哲社主文献平台将会依据相关法律规定及时处理您的投诉。在收到您的问题后，我们会在15个工作日内答复您。<br /></strong>
          <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. 邮箱：【zszwx@ruc.edu.cn】<br /></strong>
          <strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. 电话：【010-62519375】</strong>
        </p>
        <p class="indent">
          <strong>5. 本协议签订地为中华人民共和国北京市海淀区。</strong>
        </p>
        <p class="indent">
          <strong> 6. 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</strong>
        </p>
        <p class="indent">
          <strong> 7. 若您和哲社主文献平台发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</strong>
        </p>
        <p class="indent">
          8. 本协议所有条款的标题仅为阅读方便，本身并无实际含义，不能作为本协议含义解释的依据。
        </p>
        <p class="indent">
          9. 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
        </p>
      </div>
    </div>
    <footerComponent></footerComponent>
  </div>
</template>

<script>

import headerComponent from '../../components/head/headlogin.vue'
import footerComponent from '../../components/common/footer.vue'
export default {
  components: {
    headerComponent,
    footerComponent
  },
  methods: {
    goto () {
      const routeData = this.$router.resolve({
        path: '/personalagree'
        // query: params
      })
      window.open(routeData.href, '_blank')
    }
  },
  mounted () {
    document.title = '用户协议' + this.$route.meta.title
  }
}
</script>
<style lang="less" scoped>
.top {
  margin-top: 40px;

  .title {
    margin-left: 851px;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    letter-spacing: 19px;

  }

  .topimg {
    margin-left: 915px;
    width: 89px;
    height: 7px;
    margin-top: 5px;
  }
}

.contain {
  width: 1440px;
  margin-left: 240px;

  .data {
    text-align: right;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
  }

  .box {
    margin-top: 25px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding: 50px 67px 67px 62px;

    .boxtitle {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      text-indent: 2em;

      span {
        color: #9C2526;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 550;
        text-indent: 2em;
      }
    }

    .text {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      text-indent: 2em;

      a {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9C2526;
        line-height: 30px;
        text-indent: 2em;
      }

      strong {
        font-size: 18px;
        font-family: Source Han Sans CN;
        color: #000000;
        line-height: 30px;
      }
    }

    .tl {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      text-indent: 2em;

      strong {
        font-size: 18px;
        font-family: Source Han Sans CN;
        color: #000000;
        line-height: 30px;
      }
    }

    .title {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 550;
      color: #000000;
      line-height: 30px;
      margin-top: 35px;
      margin-bottom: 36px;
    }

    .text2 {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 30px;

    }

    .indent {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      text-indent: 2em;

      span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 550;
        color: #9C2526;
        line-height: 30px;
      }

      strong {
        font-size: 16px;
        font-family: Source Han Sans CN;
        line-height: 30px;
      }

      a {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 550;
        color: #9C2526;
        line-height: 30px;
      }
    }
  }
}
</style>
