<template>
  <!-- 期刊 -->
  <div class="journaldetailBox">
    <div class="detailTop">
      <div class="toplf">
        <img :src="path" v-if="path" alt />
        <div class="empjournal" v-else>
          <img src="../../assets/images/home/jqdetailimg.png" style="width: 93%;height:41%;margin-top: 55%;" alt />
        </div>
      </div>
      <div class="toprt">
        <div class="toprtTitle">{{ name }}</div>
        <div class="typeList" v-if="categoryList.length > 0">
          <span v-for="(item, index) in categoryList" :key="index">{{ item }}</span>
        </div>
        <div class="toprtunit">
          <div class="toprtgroup">
            <div class="toprtunititem toprtunititem02 toprtgroupfirst" v-if="organizer">
              <div class="title">主办单位：</div>
              <div class="titleInfo" :title="organizer">
                <p>{{ organizer }}</p>
              </div>
            </div>

            <div class="toprtunititem toprtunititem02 toprtgroupfirst" v-if="competentUnit">
              <div class="itemtitle">主管单位：</div>
              <div class="titleInfo" :title="competentUnit">{{ competentUnit }}</div>
            </div>
            <div class="toprtunititem toprtunititem02 toprtgroupfirst" v-if="period">
              <div class="title">出版周期：</div>
              <div class="titleInfo" :title="period">{{ period }}</div>
            </div>
          </div>
          <div class="toprtgroup">
            <div class="toprtunititem toprtunititem02 toprtgroupsecond" v-if="internationalSerialNumber">
              <div class="title">国际刊号：</div>
              <div class="titleInfo" :title="internationalSerialNumber">{{ internationalSerialNumber }}</div>
            </div>
            <div class="toprtunititem toprtunititem02 toprtgroupsecond" v-if="serialNumber">
              <div class="title">国内刊号：</div>
              <div class="titleInfo" :title="serialNumber">{{ serialNumber }}</div>
            </div>
          </div>
        </div>
        <div class="toprtintroduce">
          <div class="toprtintroducegroup">
            <div class="toprtintroduceItem toprtintroduceItem01" v-if="intro">
              <div class="toprtintroducetitle">
                <span style="display: inline-block;white-space: nowrap;">期刊简介:</span>
              </div>
              <div class="info">{{ intro }}</div>
            </div>
            <div class="toprtintroduceItem" v-if="website">
              <div class="toprtintroducetitle">
                <span style="display: inline-block;white-space: nowrap;">期刊官网链接:</span>
              </div>
              <!-- <div class="toprtintroduceContent" >{{website}}</div> -->
              <div class="toprtintroduceContent">
                <a class="info" :href="website" v-if="website" target="_blank">{{ website }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="literatureNum" v-if="selectedLiteratureCount">入选主文献量：{{ selectedLiteratureCount }}</div>
      </div>
    </div>
    <div class="detailmiddle">
      <div class="detailmiddlelf">
        <div class="lineBox">
          <div class="linechartTitlebox">
            <p>主文献成果统计</p>
            <div>
              <el-date-picker style="width:0.6rem;" v-model="startYear" type="year" placeholder="开始时间"
                value-format="yyyy" @change="handlestartyear" :picker-options="pickerOptions"></el-date-picker>
              <span style="margin:0 10px;">-</span>
              <el-date-picker style="width:0.6rem;" v-model="endYear" type="year" placeholder="结束时间" value-format="yyyy"
                @change="handleendtyear" :picker-options="pickerOptions"></el-date-picker>
            </div>
          </div>
          <div class="linechart" ref="linechart"></div>
        </div>
        <div class="barBox">
          <div class="linechartTitlebox">
            <p>主要学科分布</p>
          </div>
          <div class="barchart" ref="barchart"></div>
        </div>
        <div class="yearsearch">
          <yearleftmenu :yearname="name"></yearleftmenu>
        </div>
        <div class="mainlist">
          <span class="mainListInfo">入选主文献</span>
          <mainList :Listobj="Listobj"></mainList>
        </div>
      </div>
      <div class="detailmiddlert">
        <div class="authorlist">
          <!-- <p class="topTitle">代表作者</p> -->
          <authorsidelist :authorsidedata="authorsidedata"></authorsidelist>
        </div>
        <div class="jigoulist">
          <!-- 机构名称 -->
          <institutionrtside :jgsidename="jgsidename"></institutionrtside>
        </div>
        <!-- 主办单位入选期刊分布 -->
        <!-- <div class="selectedjournal">
          <journalsidelist :journalsidelistData="journalsidelistData"></journalsidelist>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
// 引入作者
// 代表机构
import institutionrtside from '../../components/institutio/institutionrightList'
import authorsidelist from '../../components/author/authorsidelsit'
import mainList from '../../components/main/mainlist'
import yearleftmenu from '../../components/journal/yeartree'
import journalsidelist from '../../components/journal/journalsidelist'
import elementResizeDetectorMaker from 'element-resize-detector'
const erd = elementResizeDetectorMaker()
export default {
  components: {
    authorsidelist,
    mainList,
    yearleftmenu,
    institutionrtside,
    journalsidelist
  },

  data () {
    return {
      categoryList: [],
      clickNumList: [],
      collectNumList: [],
      commentNumList: [],
      downloadNumList: [],
      likeNumList: [],
      literatureList: [],
      shareNumList: [],
      yearList: [],
      Listobj: {
        type: 3, /// 0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅
        url: '/literatureInfoList/getList',
        originJournalName: '',
        firstLevelList: [],
        secondLevelList: []
      },
      startYear: new Date().getFullYear() - 10 + '', // 开始时间
      endYear: new Date().getFullYear() - 1 + '', // 结束时间
      lineyearData: [], // 入选趋势x轴
      linecount: [], // 入选趋势大小
      authorsidedata: {
        url: '/periodical/selected_author',
        orgName: '',
        name: '代表作者'
      },
      // journalsidelistData: {
      //   url: "/periodical/selectedJournal",
      //   orgCode: "",
      //   name: "主管单位入选期刊分布"
      // },
      jgsidename: {
        url: '/periodical/selected_org',
        orgName: '',
        name: '相关机构'
      },
      id: '',
      name: '', // 期刊名称，
      competentUnit: '', // 主管单位
      organizer: '', //
      internationalSerialNumber: '',
      serialNumber: '',
      intro: '',
      website: '',
      selectedLiteratureCount: '',
      period: '',
      path: '',
      pickerOptions: {
        disabledDate: time => {
          const date = new Date().getFullYear()
          const yearEnd = time.getFullYear()
          return date < yearEnd
        }
      }
    }
  },
  beforeDestroy () {
    erd.uninstall(this.$refs.linechart)
    erd.uninstall(this.$refs.barchart)
  },
  methods: {
    handlegodetail (id, orgName) {
      const routeData = this.$router.resolve({
        path: 'journaldetail?id=' + id + '&name=' + orgName
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    // 选择年份
    handlestartyear (start) {
      this.startyear = start
      if (this.startyear > this.endtyear) {
        return this.$message({
          type: 'warning',
          message: '建议开始时间选择大于结束时间,结束时间早于开始时间'
        })
      }
      this.getlinelistData()
    },
    handleendtyear (end) {
      this.endtyear = end
      if (this.startyear > this.endtyear) {
        return this.$message({
          type: 'warning',
          message: '建议开始时间选择大于结束时间,结束时间早于开始时间'
        })
      }
      this.getlinelistData()
    },
    async getlinelistData () {
      if (parseInt(this.endYear) < parseInt(this.startYear)) {
        return this.$message.error('输入的日期不正确，请稍后重试!')
      }
      const { data: res } = await this.$http({
        url: '/periodical/selected_literature_trend',
        method: 'POST',
        data: {
          endYear: parseInt(this.endYear),
          startYear: parseInt(this.startYear),
          id: this.id
        }
      })
      if (res.code != 200 && res.code != 501) {
        return this.$message.error(res.msg)
      }
      this.clickNumList = res.data.clickNumList
      this.collectNumList = res.data.collectNumList
      this.commentNumList = res.data.commentNumList
      this.downloadNumList = res.data.downloadNumList
      this.likeNumList = res.data.likeNumList
      this.literatureList = res.data.literatureList
      this.shareNumList = res.data.shareNumList
      this.yearList = res.data.yearList
      // this.categoryList = res.data.categoryNameList
      // console.log(this.categoryList)
      // this.lineyearData = res.data.map(item => {
      //   return item.year
      // })
      // this.linecount = res.data.map(item => {
      //   return item.count
      // })
      this.getLineData()
    },
    getLineData () {
      var myChartline = this.$echarts.init(this.$refs.linechart)
      function fontSize (res) {
        const clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
        if (!clientWidth) return
        const fontSize = 100 * (clientWidth / 1920)
        return res * fontSize
      }
      var option
      option = {
        xAxis: {
          type: 'category',
          data: this.yearList,
          boundaryGap: false,
          axisLabel: {
            textStyle: {
              fontSize: fontSize(0.14)
            }
          }
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          min: 'dataMin', // 最小值
          max: 'dataMax', // 最大值
          axisLabel: {
            textStyle: {
              fontSize: fontSize(0.14)
            }
          }
        },
        legend: {
          type: 'scroll', // 设置图例为滚动样式
          bottom: '0%',
          left: 'left',
          itemHeight: fontSize(0.06), // 修改icon图形大小
          itemGap: fontSize(0.14), // 修改间距
          textStyle: {
            fontSize: fontSize(0.14)
          },
          pageIconSize: fontSize(0.16),
          pageTextStyle: {
            // 图例页信息的文字样式
            fontSize: fontSize(0.14)
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            name: '收藏',
            type: 'line',
            // stack: 'Total',
            data: this.collectNumList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03) // 0.1的线条是非常细的了
                }
              }
            }
          },
          {
            name: '评论',
            type: 'line',
            // stack: 'Total',
            data: this.commentNumList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03) // 0.1的线条是非常细的了
                }
              }
            }
          },
          {
            name: '下载',
            type: 'line',
            // stack: 'Total',
            data: this.downloadNumList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03) // 0.1的线条是非常细的了
                }
              }
            }
          },
          {
            name: '点赞',
            type: 'line',
            // stack: 'Total',
            data: this.likeNumList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03) // 0.1的线条是非常细的了
                }
              }
            }
          },
          {
            name: '分享',
            type: 'line',
            // stack: 'Total',
            data: this.shareNumList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03) // 0.1的线条是非常细的了
                }
              }
            }
          },
          {
            name: '文献',
            type: 'line',
            // stack: 'Total',
            data: this.literatureList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03) // 0.1的线条是非常细的了
                }
              }
            }
          },
          {
            name: '点击',
            type: 'line',
            // stack: 'Total',
            data: this.clickNumList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03) // 0.1的线条是非常细的了
                }
              }
            }
          }
        ]
      }

      myChartline.setOption(option)
      erd.listenTo(this.$refs.linechart, function () {
        myChartline.resize()
      })
    },
    // 柱状图数据
    async getpieData () {
      const { data: res } = await this.$http({
        url: '/periodical/discipline_distribution',
        method: 'POST',
        data: {
          id: this.id // 机构名称
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      // console.log("22", res.data);
      function fontSize (res) {
        const clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
        if (!clientWidth) return
        const fontSize = 100 * (clientWidth / 1920)
        return res * fontSize
      }
      var myChartbar = this.$echarts.init(this.$refs.barchart)
      var option
      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          type: 'scroll',
          bottom: '5%',
          left: 'center',
          itemHeight: fontSize(0.06), // 修改icon图形大小
          itemGap: fontSize(0.14), // 修改间距
          textStyle: {
            fontSize: fontSize(0.14)
          },
          pageIconSize: fontSize(0.16),
          pageTextStyle: {
            // 图例页信息的文字样式
            fontSize: fontSize(0.14)
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['35%', '70%'],
            center: ['50%', '40%'], // 调整饼图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 14
                // fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: res.data
          }
        ]
      }
      myChartbar.setOption(option)
      erd.listenTo(this.$refs.barchart, function () {
        myChartbar.resize()
      })
    },
    // 期刊的数据
    async getlistData () {
      const { data: res } = await this.$http({
        url: '/periodical/detail',
        method: 'post',
        data: {
          id: this.id
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.competentUnit = res.data.competentUnit // 主管单位
      this.organizer = res.data.organizer //
      this.internationalSerialNumber = res.data.internationalSerialNumber
      this.serialNumber = res.data.serialNumber
      this.intro = res.data.intro
      this.website = res.data.website
      this.selectedLiteratureCount = res.data.selectedLiteratureCount
      this.period = res.data.period
      this.path = res.data.path
      this.categoryList = res.data.categoryNameList || []
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.name = this.$route.query.name
    this.Listobj.originJournalName = this.$route.query.name
    this.getlinelistData()
    this.getlistData()
    this.getpieData()
    document.title = this.name + this.$route.meta.title
  },
  watch: {
    id: {
      deep: true,
      handler (id) {
        this.authorsidedata.orgName = id
        this.jgsidename.orgName = id
      }
    }
    // competentUnit: {
    //   deep: true,
    //   handler() {
    //     this.journalsidelistData.orgCode = this.competentUnit;
    //   }
    // }
  }
}
</script>
<style scoped lang="less">
/deep/.el-date-editor.el-input {
  width: 100px;
}

.journaldetailBox {
  width: 1440px;
  margin: 0 auto;

  .detailTop {
    padding: 20px;
    // align-items: center;
    // width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    display: flex;
    margin-top: 20px;

    .toplf {
      width: 220px;
      height: 322px;

      >img {
        width: 100%;
      }

      .empjournal {
        width: 220px;
        height: auto;
        background: #fafafc;
        border-radius: 4px;
      }
    }

    .toprt {
      padding: 20px;
      flex: 1;
      box-sizing: border-box;

      .toprtTitle {
        font-size: 24px;
        font-weight: 500;
        color: #000000;
      }

      .toprtunit {
        line-height: 24px;

        .toprtgroupsecond {
          margin-bottom: 10px;
        }

        .toprtgroupfirst {
          margin-top: 10px;
        }

        .toprtgroup {
          display: flex;

          .toprtunititem {
            display: flex;

            .title {
              // width: 70px;
              white-space: nowrap;
            }

            .titleInfo {
              width: 280px;
              display: inline-block;
              white-space: nowrap;
              // width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .itemtitle {
              font-weight: 500;
              color: #000000;
              // width: 70px;
              white-space: nowrap;
            }

            .itemname {
              color: #9c2526;
            }
          }

          .toprtunititem02 {
            width: 33%;
          }
        }
      }

      .toprtintroduce {
        background: #fbf3f3;
        border-radius: 4px;

        .toprtintroducegroup {
          // padding: 20px;

          .toprtintroduceItem01 {
            padding: 10px;
          }

          .toprtintroduceItem {
            padding: 10px;
            display: flex;

            .info {
              width: 999px;
              /*这两行代码可以解决大部分场景下的换行问题*/
              word-break: break-all;
              word-wrap: break-word;
              /*但在有些场景中，还需要加上下面这行代码*/
              white-space: normal;
              // height: 39px;
            }

            .toprtintroducetitle {
              width: 100px;
              font-weight: 500;
              color: #000000;
            }

            .toprtintroduceContent {
              flex: 1;
              margin-left: 10px;
              line-height: 20px;

              >a {
                color: #000000;
              }
            }
          }
        }
      }

      .literatureNum {
        color: #9c2526;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        padding: 6px 9px;
        display: inline-block;
        margin-top: 10px;
      }
    }
  }

  .detailmiddle {
    display: flex;
    margin-top: 20px;

    .detailmiddlelf {
      flex: 1;

      .lineBox {
        width: 530px;
        display: inline-block;
        height: 438px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
        border-radius: 10px;
        padding: 20px;

        .linechartTitlebox {
          display: flex;
          justify-content: space-between;
          align-items: center;

          >p {
            font-size: 18px;
            font-weight: 500;
            color: #9c2526;
          }

          /deep/.el-range-editor.el-input__inner {
            width: 100px;
          }
        }

        .linechart {
          width: 100%;
          height: 350px;
        }
      }

      .barBox {
        margin: 0 20px;
        width: 530px;
        display: inline-block;
        box-sizing: border-box;
        height: 438px;
        background: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
        border-radius: 10px;
        padding: 20px;

        .linechartTitlebox {
          line-height: 40px;

          >p {
            font-size: 18px;
            font-weight: 500;
            color: #9c2526;
          }
        }

        .barchart {
          width: 100%;
          height: 350px;
        }
      }

      .yearsearch {
        width: 300px;
        margin-top: 20px;
        display: inline-block;
        vertical-align: top;
      }

      .mainlist {
        // flex: 1;
        width: 760px;
        display: inline-block;
        margin: 20px 20px 0;
      }
    }

    .detailmiddlert {
      width: 340px;

      .authorlist {
        display: inline-block;
        padding-top: 20px;
        padding-bottom: 10px;
        box-sizing: border-box;
        width: 340px;
        min-height: 600px;
        background: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
        border-radius: 10px;

        .topTitle {
          margin-left: 19px;
          font-size: 18px;
          font-weight: 500;
          color: #9c2526;
          padding: 0 0 20px 0;
        }
      }

      .jigoulist {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .jglist {
      width: 340px;
    }
  }

  .detailbottom {
    display: flex;
  }
}

// .selectedjournal {
//   background: #ffffff;
//   box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
//   border-radius: 10px;
//   padding: 20px 5px;
//   margin-top: 20px;

//   .journalTitle {
//     display: flex;
//     justify-content: space-between;

//     >p {
//       font-size: 18px;
//       font-weight: 500;
//       color: #9c2526;
//     }

//     .journalTotal {
//       font-weight: 600;
//       color: @my-color;
//     }
//   }

//   .box {

//     // overflow: hidden;
//     // overflow-x: auto;
//     // width: 1000px;
//     // white-space: nowrap;
//     .selectedjournalBox {
//       width: 100%;
//       height: 50vw;
//       padding: 10px 0;

//       .journalItem {
//         // padding: 20px;
//         margin-top: 57px;
//         height: 400px;
//         width: 300px;
//         margin: 20px;
//         box-sizing: border-box;
//         border: 1px solid #e0e0e0;
//         border-radius: 4px;
//         text-align: center;
//         overflow: hidden;

//         .journalItemimg {
//           height: 330px;
//           width: 300px;
//           box-sizing: border-box;
//           overflow: hidden;

//           >img {
//             width: 100%;
//             height: auto;
//             object-fit: contain;
//             // object-fit: cover;
//           }
//         }

//         .itemTxt {
//           height: 16px;
//           font-size: 16px;
//           font-weight: 500;
//           color: #000000;
//           padding: 20px 0;
//         }
//       }
//     }
//   }
// }
.mainListInfo {
  color: #9c2526;
  font-size: 17.9904px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}

.typeList {
  margin-top: 15px;

  span {
    display: inline-block;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    padding: 0 10px;
    color: #9c2526;
    margin-right: 10px;
    background-color: #f5e9e9;
    border-radius: 4px;
  }
}
</style>
