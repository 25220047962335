<template>
  <div v-loading.lock="isLoad" element-loading-spinner=none element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="authorBox">
      <div class="authorLeft">
        <secondTree :mainlefttree="mainlefttree"></secondTree>
        <!-- <authortree :treeType="treeType"></authortree> -->
      </div>
      <div class="authorRight">
        <authorlist @isLoadingShow='isLoadingShow'></authorlist>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import authortree from '../../components/author/authorTree'
import secondTree from '../../components/main/secondTree.vue'
import authorlist from '../../components/author/authorList.vue'
export default {
  components: { authorlist, secondTree },
  data () {
    return {
      isLoad: true,
      mainlefttree: {
        url: '/authorList/count/disc', // 作者树
        title: '学科领域'
      }
    }
  },
  mounted () {
    document.title = '作者' + this.$route.meta.title
  },
  methods: {
    isLoadingShow (data) {
      this.isLoad = data
    }
  }
}
</script>
<style lang="less" scoped>
.authorBox {
  width: 75%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  min-height: 800px;

  .authorLeft {
    width: 300px;
    // height: 1502px;
  }

  .authorRight {
    flex: 1;
    padding-left: 20px;
  }
}
</style>
