<template>
  <div class="authorpage">
    <div class="authorsearchBox">
      <el-input class="searchBox authorSearchBoxEl" @keyup.enter.native='handleauthorBtn' v-model="authorsearchs"
        placeholder="输入作者名称、机构名称" clearable></el-input>
      <el-button type="primary" class="searchlistBtn" @click="handleauthorBtn">结果中搜索</el-button>
    </div>
    <div class="institutionRtnum">
      <span>为您查到</span>
      <span class="searchNum">{{ total }}</span>
      <span>条数据</span>
    </div>
    <el-alert class="authorclaimBox">
      <!-- <img src="../../assets/images/head/author.png" alt class="authorImgBox" /> -->
      <span class="claimBtn" @click="handleclaim">待认领文献</span>
      <span class="clainTxt">认领您在平台遴选的主文献</span>
    </el-alert>
    <template v-show='total > 0'>
      <div class="authorGroup" v-for="(item, index) in authorList" :key="index" @mousedown="loopZoom()"
        @mouseup="clearLoopZoom()" @click="handlegodetail(item.id, item.company, item.authorCode, item.orgCode)">
        <div class="linew100"></div>

        <div class="authorList">
          <div class="index">{{ (page - 1) * pageRow + index + 1 }}</div>
          <div class="authorlf">
            <img class="authorImgBox02" :src="item.photo" v-if="item.photo" alt />
            <div class="authorImgBox02" :style="{ backgroundColor: randomRGB((index + 1 * page) % 10) }" v-else>{{
              item.name.substring(0,
                1)
            }}</div>
          </div>
          <div class="authorrt">
            <div>
              <span class="authorName">{{ item.name }}</span>
              <!-- <span class="authorAddress" v-if="item.pseudonym">(笔名：{{ item.pseudonym }})</span> -->
              <span class="authorAddress">{{ item.company }}</span>
              <span class="authorTrpe">主文献数:{{ item.literatureNum }}</span>
            </div>
            <div class="authorrtdiscipline">
              <span v-for="(item03, index) in item.disc" :key="index">
                {{ item03 }}
                <span class="stationline"></span>
              </span>
            </div>
            <div class="authorareaBox" v-if="item.researchField">
              <span class="authorarealy">研究领域：</span>
              <span class="authorareaItem" v-for="(item02, index) in item.researchField" :key="index">{{ item02
                }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="empty" v-show='total < 0'>
      <img src="../../assets/images/home/nodatasmall.png" alt="">
      <span>暂无数据</span>
    </div>
    <div class="pageBox" v-if='total > 0'>
      <div class="listgroupLine"></div>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[10, 20, 30, 40]" :page-size="pageRow"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
    <claim ref="claim"></claim>
    <el-dialog :lock-scroll='false' custom-class="setDialog" :z-index="99999" :visible.sync="personAgree"
      :close-on-click-modal="false" center :modal-append-to-body='false'>
      <signatures-contract class="agree"></signatures-contract>
      <span slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="checksure" type='primary' class="checksure">确定</el-button>
      </span>
    </el-dialog>
    <login ref="login" class="homeLoginBigBox" @registeropen="registeropen"></login>
    <register ref="register" @loginopen="loginopen" @emitSuccessRegister="$refs.login.modelopen = true"></register>
  </div>
</template>

<script>
// @ is an alias to /src
import claim from '../../components/author/claim'
import login from '../../page/login'
import register from '../../page/register'
import { threeHandleLogin } from '../../utils/threeLogin'
import signaturesContract from '@/page/digitalSignatures/signaturesContract'
export default {
  components: {
    claim,
    login,
    register,
    signaturesContract
  },
  data () {
    return {
      test: '',
      personAgree: false,
      isThree: '1',
      authorsearch: '', // 作者搜索
      authorsearchs: '', // 作者搜索
      circleUrl: '@/assets/images/head/author.png',
      firstselecttree: [],
      secondselecttree: [],
      page: 1, // 页码
      pageRow: 10, // 每页显示的条目数
      total: 0,
      authorList: [],
      imgpath: '',
      hotwordsearch: '',
      isLoad: false,
      flag: true,
      firstTime: '',
      lastTime: '',
      maxSearch: ''
    }
  },
  mounted () {
    document.querySelector('.searchBox .searchBtn').click()
    // document.onkeydown = e => {
    //   var theEvent = window.event || e
    //   var code = theEvent.keyCode || theEvent.which || theEvent.charCode
    //   if (code == 13) {
    //     document.querySelector('.searchBox .searchBtn').click()
    //   }
    // }
  },

  computed: {

  },
  methods: {
    randomRGB (item) {
      const colorList = [
        '#f1af10',
        '#f1a871',
        '#21a2b7',
        '#83bccb',
        '#afb4db',
        '#78a254',
        '#de9462',
        '#7bbfea',
        '#e96a4e',
        '#6fb17c'
      ]
      // this.shuffle()
      // const index = Math.floor(Math.random() * colorList.length)
      return colorList[item]
      // backgroundImage: colorList[index]
    },

    // 打开认领
    checksure () {
      this.personAgree = false
      this.$refs.claim.claimbox = true
    },
    // 点击认领按钮
    handleclaim () {
      if (!this.$store.state.token) {
        return threeHandleLogin(this.THREE_LOGIN_OR)
        // return this.loginhandle()
      }
      // this.$refs.claim.claimbox = true
      this.personAgree = true
    },
    loopZoom () {
      this.firstTime = new Date().getTime()
      // this.timeOut = setTimeout(() => {
      //   this.flag = false
      // }, 500)
    },
    clearLoopZoom () {
      this.lastTime = new Date().getTime()
      if (this.lastTime - this.firstTime < 200) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    // 点击去详情
    handlegodetail (id, origin, authorCode, orgnCode) {
      if (this.flag) {
        const routeData = this.$router.resolve({
          path:
            '/authorDetail?id=' +
            id +
            '&name=' +
            orgnCode +
            '&authorCode=' +
            authorCode
          // query: params
        })
        window.open(routeData.href, '_blank')
      }
    },
    handleauthorBtn () {
      this.authorsearch = this.authorsearchs
      // this.authorsearch = this.authorsearch.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      this.page = 1
      console.log('btnSearch')
      this.$store.commit('setAuthorSearch', this.authorsearch)
      this.$store.commit('setAuthorSearchTime', Date.now())
      if (document.querySelector('.searchInput')) {
        this.maxSearch = document.querySelector('.searchInput').children[0].value
      }
      if (this.$store.state.hotwordsearch.search !== '' && this.maxSearch === '') {
        this.$store.commit('handlehotwordsearch', '')
        this.$store.commit('handlehotwordsearchTime', Date.now())
      }
      this.getauthorData()
    },
    handleSizeChange (val) {
      this.page = 1
      this.pageRow = val
      this.getauthorData()
    },
    handleCurrentChange (val) {
      this.page = val
      this.getauthorData()
    },
    async getauthorData () {
      this.isLoad = true
      this.$emit('isLoadingShow', this.isLoad)
      const { data: res } = await this.$http({
        url: '/authorList/search ',
        method: 'post',
        data: {
          bigSearch: this.hotwordsearch,
          discSearch: this.authorsearch,
          firstLevelList: this.firstselecttree,
          secondLevelList: this.secondselecttree,
          page: this.page, // 页码
          pageRow: this.pageRow // 每页显示的条目数
        }
      })

      this.authorList = res.data.rows
      this.total = res.data.total
      window.scrollTo(0, 0)
      this.isLoad = false
      this.$emit('isLoadingShow', this.isLoad)
    },
    loginhandle () {
      this.$refs.login.modelopen = true
    },
    registeropen (value) {
      this.$refs.register.registershow = value
    },
    loginopen (value) {
      this.$refs.login.modelopen = value
    }
  },
  watch: {
    '$store.state.secondauthor': {
      deep: true,
      handler (data, oldData) {
        this.page = 1
        const path = this.$route.fullPath
        let sub = ''
        if (path.split('?').length > 1) {
          sub = path.split('?')[1].split('=')[0]
        }
        const _path = path.split('?')[0]
        if (
          _path === '/author' &&
          JSON.stringify(data) !== JSON.stringify(oldData) &&
          sub !== 'subjectName'
        ) {
          this.secondselecttree = data
          console.log('  this.secondselecttree = data')
          this.getauthorData()
          return
        }
        if (
          JSON.stringify(data) !== JSON.stringify(oldData) &&
          oldData !== undefined
        ) {
          this.secondselecttree = data
          console.log('this.secondselecttree = data222')
          this.getauthorData()
        }
        // this.secondselecttree = data
      }
    },
    // '$store.state.firstauthor': {
    //   deep: true,
    //   handler (data, oldData) {
    //     this.page = 1
    //     this.pageRow = 10
    //     const path = this.$route.fullPath
    //     const _path = path.split('?')[0]
    //     if (
    //       _path === '/' &&
    //       JSON.stringify(data) !== JSON.stringify(oldData)
    //     ) {
    //       this.firstselecttree = data
    //       this.getauthorData()
    //       return
    //     }
    //     if (JSON.stringify(data) !== JSON.stringify(oldData) && oldData !== undefined) {
    //       this.firstselecttree = data
    //       this.getauthorData()
    //     }
    //     // this.firstselecttree = data
    //     // this.page = 1
    //     // this.pageRow = 10
    //     // this.getauthorData()
    //   }
    // },
    // 监听热搜词timestamp
    '$store.state.hotwordsearch': {
      deep: true,
      handler () {
        this.hotwordsearch = this.$store.state.hotwordsearch.search
        const searchEl = document.querySelector('.authorSearchBoxEl')
        this.authorsearch = searchEl.children[0].value
        this.page = 1
        this.pageRow = 10
        console.log('hotwordSearch')
        this.getauthorData()
      }
    }
    // '$store.state.hotwordsearch': {
    //   deep: true,
    //   // immediate: true,
    //   handler (data) {
    //     this.hotwordsearch = data.search
    //     // this.authorsearch = this.authorsearchs
    //     const searchEl = document.querySelector('.authorSearchBoxEl')
    //     this.authorsearch = searchEl.children[0].value
    //     // this.$store.commit('setAuthorSearch', this.authorsearch)
    //     this.page = 1
    //     this.pageRow = 10
    //     console.log('hotwordSearch')
    //     this.getauthorData()
    //   }
    // }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-alert {
  .el-alert__content {
    .el-alert__description {
      margin: 0;
    }

    .el-icon-close {
      top: 25px;
    }
  }
}

.authorpage {

  // padding: 0 20px;
  .stationline {
    display: inline-block;
    width: 2px;
    height: 16px;
    background: #cccccc;
    margin: 0 10px;
    vertical-align: text-bottom;
  }

  .authorsearchBox {
    margin: 15px 0;

    .searchBox {
      width: 400px;
      height: 40px;

      /deep/.el-input__inner {
        background: #ffffff;
        border-radius: 4px;
      }
    }

    .searchlistBtn {
      margin-left: 10px;
    }
  }

  .institutionRtnum {
    margin: 15px 0;
  }

  .searchNum {
    font-size: 14px;
    font-weight: bold;
    color: @my-color;
  }

  .authorclaimBox {
    margin: 15px 0;
    width: 100%;
    background: rgba(245, 226, 224, 0.4);
    border-radius: 4px;
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    box-sizing: border-box;

    .authorImgBox {
      width: 45px;
      height: 45px;
      object-fit: contain;
      vertical-align: middle;
    }

    .claimBtn {
      cursor: pointer;
      background: #9c2526;
      border-radius: 14px;
      color: #ffffff;
      padding: 6px 17px;
      margin: 0 20px;
      vertical-align: sub;
    }

    .clainTxt {
      font-size: 16px;
      color: #000000;
      vertical-align: sub;
    }
  }

  .authorList {
    display: flex;
    padding: 15px;

    .index {
      line-height: 60px;
      width: 35px;
      height: 20px;
    }

    .authorlf {
      .authorImgBox02 {
        margin-left: 10px;
        cursor: pointer;
        display: inline-block;
        color: #fff;
        font-size: 20px;
        width: 60px;
        height: 60px;
        // line-height: 60px;
        text-align: center;
        border-radius: 50%;
        // object-fit: cover;
        // vertical-align: middle;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;

      }
    }

    .authorrt {
      cursor: pointer;
      margin-left: 20px;

      .authorrtdiscipline {
        margin: 12px 0;
      }

      .authorrtdiscipline span:last-child {
        .stationline {
          width: 0;
        }
      }

      .authorName {
        font-size: 18px;
        font-weight: 500;
        color: #000000;
      }

      .authorAddress {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
      }

      .authorTrpe {
        margin-left: 20px;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 12px;
        font-weight: 400;
        color: @my-color;
      }

      .authorareaBox {
        .authorarealy {
          font-size: 14px;
          color: #000;
        }

        .authorareaItem {
          border: 1px solid #e0e0e0;
          border-radius: 12px;
          padding: 4px 14px;
          font-size: 12px;
          font-weight: 400;
          color: #000000;
          margin-right: 10px;
        }
      }
    }
  }

  .pageBox {
    margin-bottom: 20px;

    .listgroupLine {
      width: 100%;
      height: 1px;
      background: #e6e6e6;
      border-radius: 1px;
      margin: 15px 0;
    }
  }
}

.empty {
  margin: 20px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/deep/.setDialog {
  width: 800px;
  height: 765px;
  background-image: url("../../assets/images/claim/agreebg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  margin-top: 5vh !important;

  .el-dialog__body {
    padding: 0 !important;
  }
}

.checksure {
  width: 120px;
  height: 40px;
}

/deep/.el-dialog__close.el-icon.el-icon-close {
  color: #fff !important;
}

/deep/.agree {
  .text {
    width: 680px;
    height: 566px;
    border-radius: 10px;
  }

}
</style>
