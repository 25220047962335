<template>
  <!-- 在线阅读 -->
  <div class="readBox" style="position: relative;" v-loading.fullscreen='showLog' element-loading-spinner=none
    element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="readBoxtop" ref="readbox">
      <div class="readlf" v-loading="isRead" element-loading-spinner=none
        element-loading-background="rgba(0, 0, 0, 0.1)" style="margin-bottom: 20px;">
        <div style="position: relative;" id="word-marker" @mousedown='closeMake' @click="handleMarkerClick($event)"
          @mousemove="handleMousemove($event)">
          <!-- <TextHighlight :queries="queries" id="content"></TextHighlight> -->
          <!-- <div id="content" @mouseup="handleMouseSelect" v-html="htmlContenttxt"></div> -->
          <!-- <div v-html="text" @mouseup="handleMouseSelect"></div> -->
          <div id="content"></div>
          <div class="mark-info" id="mark-info">
            <div class="markbox">
              <!-- <span class="marktext" @click="handleLine()">
              <div class="micon huaxianicon"></div>
              <div class="mtext">划线</div>
            </span> -->
              <!-- <span class="marktext" @click.stop="handleHeightlight()">
              <div></div>
              <div class="gaoliangicon micon"></div> -->
              <!-- <el-color-picker  size="mini" v-model="lightcolor"></el-color-picker> -->
              <!-- <div class="mtext">高亮</div>
            </span> -->
              <span class="marktext" @click="handleComment()">
                <div class="bijiicon micon"></div>
                <div class="mtext">笔记</div>
              </span>
            </div>
          </div>
        </div>
        <div class="show-mark" id="show-mark">
          <div class="marktitle">
            <span class="mark">记笔记</span>
            <!-- <span class="save">支持自动保存</span> -->
          </div>
          <TextEditor :mymarklist='mymarklist' @markIndex="markIndex" :edithtml="edithtml" @html="gethtml"
            class="mark-edit" v-if="open">
          </TextEditor>
          <!-- <div contenteditable="true" class="mark-edit" id="mark-edit"></div> -->
          <div class="mark-btn">
            <div class="switchBox">
              <el-switch v-model="opencontent" active-text="内容是否公开" :active-value="0" :inactive-value="1"></el-switch>
            </div>
            <div class="switchBox">
              <el-switch v-model="anonymous" v-if="!opencontent" active-text="匿名" :active-value="0"
                :inactive-value="1"></el-switch>
            </div>
            <div class="mark-btnbox">
              <span class="mark-btn-cancel" id="mark-btn-cancel" style="background-color: #F7F7F7; color: black;"
                @click="handleCancelClick()">取消</span>
              <span class="mark-btn-ok" id="mark-btn-ok" @click="handleSureClick()">确定</span>
              <!-- <span class="mark-btn-delete" id="mark-btn-delete" @click="handleDeleteClick()">删除</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="readrt">
        <div class="markmyother" id="markmyother" :class="{ markFixed: isFixed === true, markAbo: isFixed === false }">
          <el-tabs v-model="activeName" @tab-click="handleClick" style="padding-left: 20px;padding-right: 10px;">
            <el-tab-pane class='myMark' :label='"我的笔记（" + mymarkcount + "）"' name="mymark" v-loading="isLoad"
              element-loading-background="rgba(255, 255, 255, 1)">
              <template>
                <div v-if='mymarklist.length !== 0'>
                  <div class="marklist" style="cursor:pointer" v-for="(item, index) in mymarklist" :key="index"
                    @click="clickmarklist(item)">
                    <div class="mymarkgroup" :class="{ activetab: currentId === item.id }">
                      <div class="firstline">
                        <div class="firstlinelf">
                          <img :src="item.photo" v-if="item.photo" alt />
                          <img src="../../assets/images/home/mymark.png" v-else alt />
                          <span class="mymarkName myName" :title='item.username'>{{ item.username }}</span>
                          <span class="iconfont icon-biyanjing ispublic" v-if="item.isPublic == 1">不公开</span>
                          <!-- <span class="iconfont icon-yanjing ispublic" v-if="item.isPublic == 0">公开</span> -->
                          <span class="iconfont icon-biyanjing ispublic" v-if="item.isAnonymous == 0">匿名</span>
                          <span class="mymarkTime">{{ item.createTime }}</span>
                        </div>
                        <div class="popoverBox">
                          <div class="popovercontentBox" v-if="mymargkvisible == index">
                            <div class="popovercontent">
                              <div>
                                <TextEditor :edithtml="originalmymarkeditor" @html="getmarkhtml" class="mark-edit">
                                </TextEditor>
                                <!-- <el-input type="textarea" v-model="originalmymarkeditor"></el-input> -->
                              </div>
                              <div class="switchBox">
                                <el-switch v-model="item.isPublic" active-text="内容是否公开" :active-value="0"
                                  :inactive-value="1"></el-switch>
                                <!-- <el-switch v-model="anonymous" v-if="opencontent" active-text="匿名"></el-switch> -->
                              </div>

                              <div style="text-align: right; margin-top:10px;">
                                <el-button size="mini" type="text"
                                  @click="confirmcancle(item.id, item.markValue)">取消</el-button>
                                <el-button type="primary" size="mini" @click="confirmEditor(item)">确定</el-button>
                              </div>
                            </div>
                          </div>
                          <div class="myicon">
                            <div class="mymarkedit" @click="handkemark(item, index)"></div>
                            <div class="mymarkedel" @click="handlemymarkedel(item)"></div>
                          </div>
                        </div>
                      </div>
                      <div class="firstlinert">
                        <div class="mymarkType">
                          <span class="markname">笔记</span>
                          <span class="markcontent" v-html="item.markValue"></span>
                        </div>
                        <div class="originaltext">
                          <span>原文：</span>
                          <span>{{ item.textValue }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="markline"></div>
                  </div>
                </div>
                <div class='notData' v-else>
                  <img src="../../assets/images/home/nodataside.png" alt="">
                  <span>暂无数据</span>
                </div>
              </template>
            </el-tab-pane>
            <el-tab-pane class='otherMark' :label='"他人笔记（" + othermarkcount + "）"' name="othermark" v-loading="isLoad"
              element-loading-background="rgba(255, 255, 255, 1)">

              <template>
                <div v-if='othermark.length > 0'>
                  <div class="marklist" style="cursor:pointer;" v-for="(item, index) in othermark" :key="index"
                    @click="clickmarklist(item.list[0])" :class="{ activeOther: currentOther === item.list[0].id }">
                    <div class="otherTitle">{{ item.value }}</div>
                    <div v-for="(i, j) in item.list" :key="j">
                      <div class="mymarkgroup" style="background-color: #F7F7F7;padding-left: 8px;">
                        <div style=" display: flex;">
                          <div class="firstline">
                            <div class="firstlinelf">
                              <img v-if="i.photo" :src="i.photo" alt />
                              <img v-else src="../../assets/images/home/mymark.png" alt />
                              <span class="mymarkName" :title='i.username'>{{ i.username }}</span>
                              <span class="mymarkTime">{{ i.createTime }}</span>
                            </div>
                            <div></div>
                          </div>
                          <div class="firstlinert" style="margin-left: auto;">
                            <div class="mymarkType">
                              <span class="markname">笔记</span>
                            </div>
                          </div>
                        </div>
                        <span class="markcontent" v-html="i.markValue"></span>
                        <div class="marklineother"></div>
                      </div>
                    </div>
                    <div class="markline" style="margin-top: 10px;"></div>
                  </div>
                </div>
                <div class='notData2' v-else>
                  <img src="../../assets/images/home/nodataside.png" alt="">
                  <span>暂无数据</span>
                </div>
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- <div class="readBoxbottom"></div> -->
    <!-- <div class="comment" v-if="!isOpinion" @click="handleDetails()"> -->
    <div class="comment" @click="handleDetails()" :class="{ isfixed: isFixed === true, posAbso: isFixed === false }">
      <span class="icon1"></span>
      <div class="opintip">在线评论</div>
    </div>
    <login ref="login" class='homeLoginBigBox' @registeropen="registeropen"></login>
    <register ref="register" @loginopen="loginopen" @emitSuccessRegister="$refs.login.modelopen = true"></register>
  </div>
</template>

<script>
// import Highlighter from 'web-highlighter'
import TextEditor from '../../components/main/Editor.vue'
import wordMarker from 'word-marker'
import login from '../../page/login'
import register from '../../page/register'
import { threeHandleLogin } from '../../utils/threeLogin'
export default {
  components: {
    login,
    register,
    TextEditor
  },
  props: [],
  data () {
    return {
      showLog: false,
      saveEdit: false, // 是否保存笔记内容,true为保存,false为不保存
      title: '',
      elHeight: 0,
      otherHeight: 0,
      // highlighter: null,
      str: '',
      visible: false,
      path: '',
      htmlContent: '', // 文献内容
      htmlContenttxt: '',
      markDatasend: '', // 发送给后端笔记数据
      readid: '', // 文章id
      isAnonymous: 1, // 是否匿名 0-匿名 1-不匿名
      isPublic: 0, // 是否公开 0-公开 1-不公开
      referenceNumber: '', // 文献编号
      storemark: [], // 存储当前标记
      activeName: 'mymark',
      mymarklist: [],
      mymarkcount: '',
      othermarkcount: '',
      othermark: [],
      acceptmarklist: [],
      markid: '',
      mymargkvisible: -1,
      wm: null,
      opencontent: 0, // 内容是否公开
      anonymous: 1, // 匿名
      curMarkData: null,
      isNewMark: false,
      tabName: 'mymark',
      mymark: true,
      edithtml: '',
      originalmymarkeditor: '', // 我的笔记编辑的原值
      mergecheck: false,
      selectionrang: '',
      lightcolor: '#409EFF',
      open: true, // 富文本重新渲染
      lid: '',
      isFixed: true,
      currentId: '',
      currentOther: '',
      isLoad: false,
      isRead: true
    }
  },
  async mounted () {
    // this.init(this.acceptmarklist);
    this.isLoad = true
    this.path = this.$route.query.path
    this.referenceNumber = this.$route.query.referenceNumber
    // this.highlighter = new Highlighter({
    //   $root: document.documentElement,
    //   exceptSelectors: null,
    //   style: {
    //     className: 'highlight-wrap-hover',
    //   }
    // })
    await this.getDataList()
    // 我的笔记
    this.getmymark()
    document.title = this.title + this.$route.meta.title
    window.addEventListener('scroll', this.fixedActiveBtn)
    this.openClick()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.fixedActiveBtn, true)
    // this.removeListener()
  },
  methods: {
    // openClick () {
    //   document.addEventListener('click', this.close, false)
    // },
    // close (e) {
    //   const boxEl = document.querySelector('.mark-info')
    //   if (boxEl) {
    //     if (!boxEl.contains(e.target) &&
    //       e.target.className !== 'mark-info' &&
    //       e.target.className !== 'markbox' &&
    //       e.target.className !== 'marktext' &&
    //       e.target.className !== 'bijiicon micon' &&
    //       e.target.className !== 'mtext') {
    //       if (boxEl.style.display === 'block') {
    //         console.log(111)
    //         boxEl.style.display = 'none'
    //       }
    //     }
    //   }
    // },
    // removeListener () {
    //   document.removeEventListener('click', this.close, false)
    // },
    fixedActiveBtn () {
      const bottom = document.documentElement.scrollHeight - document.documentElement.clientHeight - 190
      const scrollTop = document.documentElement.scrollTop
      if (scrollTop >= bottom) {
        this.isFixed = false
      } else {
        this.isFixed = true
      }
    },
    closeMake () {
      // const markInfoEl = document.querySelector('.mark-info')
      // if (markInfoEl) {
      //   console.log('看看', markInfoEl.style.display)
      //   if (markInfoEl.style.display === 'block') {
      //     console.log('这呢')
      //     markInfoEl.style.display = 'none'
      //   }
      // }
    },
    markIndex (index) {
      const markListEl = document.querySelectorAll('.marklist')
      this.elHeight = 0
      for (let i = 0; i < index; i++) {
        this.elHeight += markListEl[i].offsetHeight
      }
      document.querySelector('.myMark').scrollTop = this.elHeight
    },
    loginhandle () {
      // this.$refs.login.modelopen = true
      return threeHandleLogin()
    },
    registeropen (value) {
      this.$refs.register.registershow = value
    },
    loginopen (value) {
      this.$refs.login.modelopen = value
    },
    //   请求在线阅读内容
    async getDataList () {
      const { data: res } = await this.$http({
        url: '/takeNotes/readOnline',
        method: 'post',
        data: {
          referenceNumber: this.referenceNumber
        }
      })

      if (res.code != 200) {
        if (res.code === 115001) {
          // return this.loginhandle()
          return threeHandleLogin(this.THREE_LOGIN_OR)
        }
      }
      this.isRead = false
      this.title = res.data.title
      this.lid = res.data.lid
      document.getElementById('content').innerHTML = ''
      this.htmlContent = res.data
      this.htmlContenttxt = res.data.labelLiteratureContent
      this.htmlContenttxt = this.htmlContenttxt.replace('.introductionname {', '.introductionname { width:90%;')
      this.htmlContenttxt = this.htmlContenttxt.replace('text-decoration: underline;', '')
      this.htmlContenttxt = this.htmlContenttxt.replace('.contetname {', '.contetname {font-size: 16px;font-weight: 400;')
      this.htmlContenttxt = this.htmlContenttxt.replace('class="artibody" style="font-size: 14px;"', 'class="artibody" style="font-size: 16px;"')
      this.htmlContenttxt = this.htmlContenttxt.replace(/#9C2526/g, '#000000')
      this.readid = res.data.id
      // console.log(this.htmlContenttxt)
      document.getElementById('content').innerHTML = this.htmlContenttxt
      this.acceptmarklist = []
    },
    // 新增数据
    async getaddnoteData () {
      const { data: res } = await this.$http({
        url: '/takeNotes/addTakeNotes',
        method: 'post',
        data: {
          id: this.readid,
          isAnonymous: this.opencontent == 0 ? this.anonymous : 1,
          isPublic: this.opencontent,
          markContentDto: [this.markDatasend],
          referenceNumber: this.referenceNumber
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.$message({
        message: res.msg,
        type: 'success'
      })
      this.edithtml = ''

      this.opencontent = 0
      this.anonymous = 1
      this.getDataList()
      this.getmymark()
    },
    // 删除
    async delmark (id) {
      const { data: res } = await this.$http({
        url: '/takeNotes/deleteTakeNotes',
        method: 'post',
        data: {
          id: id,
          referenceNumber: this.referenceNumber
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.getDataList()
      this.getmymark()
    },

    init (acceptmarklist = []) {
      var that = this
      const container = document.getElementById('word-marker')
      const markInfo = document.getElementById('mark-info')
      const wm = wordMarker(container, {
        data: acceptmarklist,
        highlight (ctx, range) {
          ctx.fillStyle = 'rgba(224, 108, 117, 0.5)'
          ctx.fillRect(range.x, range.y, range.width, range.height)
        },
        add (data, rect) {
          if (that.isNewMark) {
            that.curMarkData && that.wm.deleteMark(that.curMarkData.id)
            that.isNewMark = false
          }
          if (data && that.mymark) {
            that.isNewMark = true
            that.curMarkData = data
            const { x, y, width, height } = rect
            markInfo.style.display = 'block'
            markInfo.style.left = `${width / 2 + x - 17}px`
            markInfo.style.top = `${y - 65}px`
          }
        }
      })
      this.wm = wm
    },
    // 鼠标点击事件
    handleMarkerClick (e) {
      if (this.mymark) {
        const { pageX, pageY } = e
        const markData = this.wm.checkMark(pageX, pageY)
        // return console.log('123', markData)
        const markInfo = document.getElementById('mark-info')
        const showMark = document.getElementById('show-mark')

        // const { pageX, pageY } = e
        // const markData = this.wm.checkMark(pageX, pageY)
        if (markData) {
          // showMark.style.display = 'block'
          this.open = true
          // showMark.style.display = 'block'
          this.edithtml = markData.message
          this.curMarkData = markData
          this.markid = this.curMarkData.id
          this.mymarklist.some(val => {
            if (this.markid == JSON.parse(val.markJson).id) {
              this.currentId = val.id
              // this.anonymous = val.isAnonymous == '0'
              this.opencontent = val.isPublic
              this.anonymous = val.isAnonymous
              return true
            }
          })
        } else if (!this.isNewMark) {
          // showMark.style.display = 'none'
          markInfo.style.display = 'none'
          this.curMarkData = null
        }
      } else {
        const { pageX, pageY } = e
        const markData = this.wm.checkMark(pageX, pageY)
        if (markData.message) { this.edithtml = markData.message }
        this.othermark.some(val => {
          if (markData.id == JSON.parse(val.list[0].markJson).id) {
            this.currentOther = val.list[0].id
            return true
          }
        })
        // return console.log('123', markData)
        // const markInfo = document.getElementById('mark-info')
        // const showMark = document.getElementById('show-mark')
      }
    },
    // 鼠标移动
    handleMousemove (e) {
      if (!this.wm) return
      const { pageX, pageY } = e
      const marker = this.wm.checkMark(pageX, pageY)
      this.wm.lighthighMark(marker?.id)
    },
    // 点击评论
    handleComment () {
      this.showLog = true
      const markInfo = document.getElementById('mark-info')
      const showMark = document.getElementById('show-mark')
      this.isNewMark = true
      showMark.style.display = 'block'
      this.open = true
      markInfo.style.display = 'none'
      // if (this.saveEdit === false) {
      this.edithtml = ''
      //  }
      this.wm.addMark(this.curMarkData)
      this.str = ''
    },
    // handleMouseSelect() {
    //   this.selectionrang = this.getSelection(window).getRangeAt(0)
    //   this.str = window.getSelection().getRangeAt(0)
    // },
    // handleLine() {
    //   // let textDom = document.getElementById('content')
    //   // let text = textDom.innerHTML
    //   // let rReg = new RegExp(`${this.str}`, "ig")
    //   // document.getElementById('content').innerHTML = text.replace(rReg, `<u>${this.str}</u>`)
    //   // console.log(document.getElementById('content'))
    //   let highter = this.highlighter.fromRange(this.selectionrang)
    //   for (let m = 0; m < this.highlighter.getDoms().length; m++) {//给高亮动态的添加颜色
    //     if (this.highlighter.getDoms()[m].dataset.highlightId == highter.id) {
    //       this.highlighter.getDoms()[m].style.textDecoration = "underline"
    //     }
    //   }
    //   tselectionranghis.highlighter.fromRange(this.selectionrang)
    //   this.str = ''
    // },
    // handleHeightlight() {
    //   // let textDom = document.getElementById('content')
    //   // let text = textDom.innerHTML
    //   // let rReg = new RegExp(`${this.str}`, "")
    //   // document.getElementById('content').innerHTML = text.replace(rReg, `<span class="artibody" style="color:red;">${this.str}</span>`)
    //   // console.log(document.getElementById('content'))
    //   let highter = this.highlighter.fromRange(this.selectionrang)
    //   for (let m = 0; m < this.highlighter.getDoms().length; m++) {//给高亮动态的添加颜色
    //     if (this.highlighter.getDoms()[m].dataset.highlightId == highter.id) {
    //       this.highlighter.getDoms()[m].style.color = this.lightcolor
    //     }
    //   }
    //   console.log('dom', this.highlighter.getDoms())
    //   this.highlighter.fromRange(this.selectionrang)
    //   this.str = ''
    // },

    // getSelection(root) {//选中的字获取
    //   let t = null;
    //   if (root.getSelection) {
    //     t = root.getSelection();
    //   } else if (root.document.getSelection) {
    //     t = root.document.getSelection();
    //   } else if (root.document.selection) {
    //     t = root.document.selection.createRange().text;
    //   }
    //   return t;
    // },

    // 点击确定
    handleSureClick () {
      this.showLog = false
      // const markEdit = document.getElementById('mark-edit')
      const showMark = document.getElementById('show-mark')
      const markInfo = document.getElementById('mark-info')
      if (this.edithtml === '<p><br></p>') {
        return this.$message.error('笔记内容不得为空')
      }
      showMark.style.display = 'none'
      this.open = false
      this.wm.modifyMark(this.curMarkData.id, this.edithtml)
      // 找到当前修改的id对象 this.markDatasend.id
      this.markDatasend = this.wm
        .getMarkData()
        .find(obj => obj.id === this.curMarkData.id)
      this.curMarkData = null
      if (!this.markDatasend) {
        markInfo.style.display = 'none'
        showMark.style.display = 'none'
        return this.$message.error('笔记未批注上，请重新批注！')
      }
      // 需要传给后端
      this.getaddnoteData()
    },
    // 点击取消
    handleCancelClick () {
      const showMark = document.getElementById('show-mark')
      showMark.style.display = 'none'
      this.open = false
      if (this.isNewMark && this.curMarkData) {
        this.wm.deleteMark(this.curMarkData.id)
      }
      this.edithtml = ''
      this.anonymous = 1
      this.opencontent = 0
      this.isNewMark = false
      this.showLog = false
      // this.curMarkData = null;
    },
    // 点击删除
    handleDeleteClick () {
      const showMark = document.getElementById('show-mark')
      showMark.style.display = 'none'
      this.open = false
      this.wm?.deleteMark(this.curMarkData.id)
      // this.curMarkData = null;
      // 调用删除接口
      this.delmark(this.curMarkData.id)
      this.anonymous = 1
      this.opencontent = 0
    },
    handleClick (tab, event) {
      this.isLoad = true
      this.currentId = ''
      this.currentOther = ''
      const showMark = document.getElementById('show-mark')
      showMark.style.display = 'none'
      this.open = false
      this.mymark = tab.name === 'mymark'
      window.scrollTo(0, 0)
      document.querySelector('.myMark').scrollTop = 0
      document.querySelector('.otherMark').scrollTop = 0
      if (this.mymark) {
        this.getmymark()
      } else {
        // 他人笔记
        this.getothermark()
      }
    },

    // 我的笔记数据
    async getmymark () {
      // 清空 我的笔记  他人笔记
      // [...this.mymarklist, ...this.othermark].forEach(val => {
      //   console.log("kkk", val);
      //   this.wm?.deleteMark(JSON.parse(val.markJson).id);
      // });
      this.isLoad = true
      const { data: res } = await this.$http({
        url: '/takeNotes/getMineLiteratureMarkRecord',
        method: 'post',
        data: {
          referenceNumber: this.referenceNumber
          // isAnonymous: this.opencontent
          // isPublic: this.anonymous ? 1 : 0
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.isLoad = false
      this.mymarklist = res.data.selectedNotes
      // 我的笔记
      this.acceptmarklist = res.data.markContentList
      this.mymarkcount = res.data.markContentList.length
      this.othermarkcount = res.data.unSelectedCount
      if (!this.mymarklist.length) {
        document.querySelector('.notData').style.display = 'flex'
      }
      // if (!this.wm) {
      //   this.init(this.acceptmarklist)
      // } else {
      //   this.wm?.clear()

      //   this.acceptmarklist.forEach(val => {
      //     // console.log(JSON.parse(val.markJson));
      //     this.wm.addMark(val);
      //   });mymarklist
      // }
      this.wm?.destory()
      // this.wm?.refresh();
      this.init(this.acceptmarklist)
      // this.$nextTick(() => {
      // setTimeout(() => {
      // }, 200);
      // });
    },
    // 他人笔记
    async getothermark () {
      // 清空 我的笔记  他人笔记
      // [...this.mymarklist, ...this.othermark].forEach(val => {
      //   this.wm?.deleteMark(JSON.parse(val.markJson).id);
      // });
      this.isLoad = true
      const { data: res } = await this.$http({
        url: '/takeNotes/getOtherLiteratureMarkRecord',
        method: 'post',
        data: {
          referenceNumber: this.referenceNumber
        }
      })

      if (res.code != 200) return this.$message.error(res.msg)
      var marklist = []
      this.isLoad = false
      for (let i = 0; i < res.data.selectedNotes.length; i++) {
        const mark = {
          value: res.data.selectedNotes[i].textValue,
          list: [res.data.selectedNotes[i]]
        }
        marklist.push(mark)
        for (let i = 0; i < marklist.length; i++) {
          for (let j = i + 1; j < marklist.length; j++) {
            if (marklist[i].value == marklist[j].value) {
              marklist[i].list.push(marklist[j].list[0])
              marklist.splice(j, 1)
            }
          }
        }
      }
      this.othermark = marklist
      if (!this.othermark.length) {
        document.querySelector('.notData2').style.display = 'flex'
      }
      this.acceptmarklist = res.data.markContentList
      this.othermarkcount = res.data.selectedNotes.length
      this.getDataList()

      // if (!this.wm) {
      //   this.init(this.acceptmarklist)
      // } else {
      //   this.wm?.clear()

      //   this.acceptmarklist.forEach(val => {
      //     // console.log(JSON.parse(val.markJson));
      //     console.log(val, this.wm)
      //     this.wm.addMark(val);
      //   });
      // }
      this.wm?.destory()
      this.init(this.acceptmarklist)
      // this.acceptmarklist.forEach(val => {
      //   // console.log(JSON.parse(val.markJson));
      //   this.wm.addMark(val);
      // });
      // this.$nextTick(() => {
      // setTimeout(() => {
      //   // 他人笔记清空我的笔记
      //   this.othermark.forEach(val => {
      //     this.wm.deleteMark(JSON.parse(val.markJson).id);
      //   });
      // }, 200);
      // });
    },
    // 我的笔记编辑
    async confirmEditor (item) {
      const { data: res } = await this.$http({
        url: '/takeNotes/updateRightNote',
        method: 'post',
        data: {
          id: item.id,
          markValue: this.originalmymarkeditor,
          isPublic: item.isPublic
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.$message({
        message: res.msg,
        type: 'success'
      })

      this.wm.modifyMark(JSON.parse(item.markJson).id, item.markValue)
      this.mymargkvisible = -1
      this.getmymark()
    },

    confirmcancle (id, markValue) {
      this.mymargkvisible = -1
    },
    handkemark (item, index) {
      this.open = true
      this.showLog = true
      const showMark = document.getElementById('show-mark')
      showMark.style.display = 'block'
      this.edithtml = item.markValue
      this.opencontent = item.isPublic
      this.anonymous = item.isAnonymous
      this.wm.getMarkData().forEach(val => {
        if (item.textValue === val.text) {
          this.curMarkData = val
        }
      })
    },
    // 我的笔记删除
    async handlemymarkedel (item) {
      this.$confirm('此操作将永久删除该笔记, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        // center: true
        lockScroll: false
      }).then(async () => {
        const { data: res } = await this.$http({
          url: '/takeNotes/deleteRightNote',
          method: 'post',
          data: {
            id: item.id
          }
        })
        if (res.code != 200) return this.$message.error(res.msg)

        this.$nextTick(() => {
          this.getDataList()
          this.getmymark()
          const _id = JSON.parse(item.markJson).id
          this.wm.deleteMark(_id)
        })
        this.$message({
          message: res.msg,
          type: 'success'
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // })
      })
    },
    gethtml (data) {
      this.edithtml = data
      // console.log('this', this.edithtml)
    },
    getmarkhtml (data) {
      this.originalmymarkeditor = data
    },
    // 点击定位
    clickmarklist (item) {
      // const showMark = document.getElementById('show-mark')
      // showMark.style.display = 'none'
      this.currentId = item.id
      this.currentOther = item.id
      this.wm.getMarkData().forEach(val => {
        if (item.textValue === val.text) {
          window.scrollTo(this.wm.getPosition(val.id).x, this.wm.getPosition(val.id).y - 10)
        }
      })
    },
    handleDetails () {
      window.open('/mainDetails?id=' + this.lid + '&referenceNumber=' + this.$route.query.referenceNumber + '&path=comment')
      // this.$router.push('/mainDetails?id=' + this.lid + '&referenceNumber=' + this.$route.query.referenceNumber + '&path=comment')
    }
    // changeColor () {
    //   this.lightcolor =
    // }
  },
  watch: {
    edithtml (newValue, oldValue) {
      // console.log('newValue', newValue)
      // console.log('他人笔记', this.othermark)
      this.othermark.forEach((item, index) => {
        if (item.list[0].markValue === newValue) {
          const markListEl = document.querySelectorAll('.marklist')
          // console.log('list', markListEl)
          this.otherHeight = 0
          // console.log('下标', index)
          for (let i = 0; i < index + this.mymarklist.length; i++) {
            // console.log('mark', markListEl[i].offsetHeight)
            this.otherHeight += markListEl[i].offsetHeight
          }
          // console.log('加的高度', this.otherHeight)

          // console.log('高度')
          document.querySelector('.otherMark').scrollTop = this.otherHeight
          // console.log('自己动的高度', document.querySelector('.otherMark').scrollTop)
        }
      })
      // console.log('oldValue', oldValue)
    }
  }
}

</script>

<style scoped lang="less">
/deep/.el-tabs__nav-wrap::after {
  height: 1px;
}

/deep/.textline {
  text-decoration: underline;
}

.highlight-wrap-hover {
  background: #ffffff; //默认选中时给的颜色
}

.notData {
  display: none;
  // display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 178px;
    height: 88px;
    margin-top: 300px;
  }
}

.notData2 {
  display: none;
  // display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 178px;
    height: 88px;
    margin-top: 300px;
  }
}

.readBox {
  background: #f5f5f5;

  .readBoxtop {
    display: flex;
    padding: 24px 24px 0 24px;

    .readlf {
      // flex: 1;
      background: #ffffff;
      border-radius: 10px;
      width: 82%;
      min-height: 86vh;

      #word-marker {
        padding: 20px;
        height: 100%;
        margin: 0 auto;
      }

      .popovercontent {
        background-color: #fff;
        width: 200px;

        .el-switch {
          margin-top: 10px;
        }
      }

      .mark-info {
        position: absolute;
        left: 0;
        top: 0;
        display: none;

        .markbox {
          width: 80px;
          height: 60px;
          background: #FFFFFF;
          border: 1px solid #E6E6E6;
          box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
          border-radius: 10px;
          display: flex;

          .marktext {
            flex: 1;
            background: #FFFFFF;
            width: auto;
            height: 100%;
            padding: 0;

            .micon {
              width: 100%;
              height: 60%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .mtext {
              height: 30%;
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #000000;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .huaxianicon {
              background: url('../../assets/images/home/xiahuaxian.png') no-repeat;
              background-position: center bottom;
            }

            .gaoliangicon {
              background: url('../../assets/images/home/gaoliang.png') no-repeat;
              background-position: center bottom;
            }

            .bijiicon {
              background: url('../../assets/images/home/biji.png') no-repeat;
              background-position: center bottom;
            }
          }
        }
      }

      .mark-btn {
        padding: 10px 20px 20px;
        box-sizing: border-box;
      }

      .switchBox {
        margin-top: 10px;
      }

      .mark-btnbox {
        text-align: right;
      }

      .mark-info span,
      .mark-btn span {
        display: inline-block;
        // margin-right: 10px;
        padding: 4px 15px;
        background-color: #9c2526;
        color: #fff;
        font-size: 12px;
        line-height: 20px;
        border-radius: 6px;
        cursor: pointer;
        z-index: 999;
      }

      .show-mark {
        position: fixed;
        right: 1%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9999;
        border-radius: 6px;
        background-color: #fff;
        display: none;
        border: 1px solid #E6E6E6;
        box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
      }

      .marktitle {
        width: 100%;
        height: 50px;
        background: #F5F5F5;
        border: 1px solid #E6E6E6;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        position: relative;

        .mark {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #9C2526;
          padding-left: 100px;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .save {
          margin-left: 90px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #5F5F5F;
          line-height: 58px;
        }
      }

      .mark-edit {
        width: 450px;
        // min-height: 200px;
        max-height: 500px;
        border: 1px solid #E6E6E6;
        outline: none;
        background-color: #fff;
        box-sizing: border-box;
      }
    }

    .readrt {
      padding-left: 20px;
      box-sizing: border-box;
      width: 325px;
      border-radius: 10px;
      // overflow: hidden;
      position: relative;

      .markFixed {
        top: 90px;
        position: fixed;
      }

      .markAbo {
        bottom: 20px;
        position: absolute;
        left: 20px;
      }

      .markmyother {
        background: #ffffff;
        width: 325px;
        height: 86vh;
        display: inline-block;
        box-sizing: border-box;
        border-radius: 10px;
        padding-top: 20px;
        padding-bottom: 20px;

        .el-tab-pane {
          height: 75vh;
          transition: height 0.5s ease;
          overflow-y: scroll;
        }

        .mymarkgroup {
          padding: 12px 5px 10px 0;
          margin: 5px;

          .firstline {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .firstlinelf {
              >img {
                width: 30px;
                height: 30px;
                object-fit: cover;
                border-radius: 50%;
                vertical-align: baseline;
              }
            }

            .popoverBox {
              position: relative;

              .myicon {
                display: flex;
                right: 0;

                .mymarkedit {
                  background: url("../../assets/images/home/fileedit.png") no-repeat;
                  width: 16px;
                  height: 17px;
                  background-size: cover;
                  display: inline-block;
                }

                .mymarkedel {
                  margin-left: 15px;
                  background: url("../../assets/images/home/narkdel.png") no-repeat;
                  width: 16px;
                  height: 17px;
                  background-size: cover;
                  display: inline-block;
                }
              }

              .popovercontentBox {
                background-color: #fff;
                padding: 20px;
                width: 500px;
                position: fixed;
                z-index: 2163;
                top: 40%;
                // right: -150px;
                transform: translate(-85%, -50%);
                /* 将气泡居中定位 */
                animation: bubble-animation 2s infinite;
                /* 应用动画 */
                box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

                .el-switch {
                  margin-top: 10px;
                }
              }
            }

            .mymarkName {
              display: inline-block;
              font-size: 16px;
              font-weight: 500;
              width: 120px;
              color: #000000;
              margin: 0 10px;
              word-break: keep-all;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .myName {
              width: 150px;
            }

            .ispublic {
              padding: 4px 8px;
              font-size: 12px;
              font-weight: 500;
              color: #fff;
              margin-right: 10px;
              background-color: #fabd49;
              border-radius: 4px;
              display: inline-block;
            }

            // .ispublic::before {
            //   content: "";
            //   background: url("../../assets/images/home/ispubilc.png") no-repeat;
            //   width: 11px;
            //   height: 5px;
            //   background-size: cover;
            //   display: inline-block;
            //   margin-right: 6px;
            //   vertical-align: middle;
            // }

            .mymarkTime {
              display: inline-block;
              font-size: 12px;
              color: #666666;
            }
          }

          .firstlinert {
            .mymarkType {
              font-size: 14px;
              line-height: 24px;

              .markname {
                background: #f9e2c6;
                color: #ff9000;
                border-radius: 4px;
                display: inline-block;
                text-align: center;
                width: 54px;
                height: 24px;
                display: inline-block;
                margin: 8px 10px 0 0;
                box-sizing: border-box;
              }

              .markname::before {
                content: "";
                background: url("../../assets/images/home/marktype.png") no-repeat;
                width: 9px;
                height: 10px;
                background-size: cover;
                display: inline-block;
                margin-right: 6px;
                vertical-align: middle;
              }

              .markcontent {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                overflow-y: auto;
                word-wrap: break-word; //换行
              }
            }

            .originaltext {
              margin-top: 10px;

              >span {
                font-size: 14px;
                color: #666666;
              }
            }
          }
        }

        .activetab {
          padding-left: 5px;
          padding-right: 8px;
          box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
        }

        .markline {
          height: 1px;
          background: #e6e6e6;
          border-radius: 1px;
        }

        // .marklineother {
        //   width: 90%;
        //   height: 1px;
        //   background: #e6e6e6;
        //   border-radius: 1px;
        //   margin-top: 10px;
        // }
        // .marklineother:last-child {
        //   background: #9C2526;
        // }
      }

      .activeOther {
        margin: 5px;
        padding-top: 5px;
        padding-left: 5px;
        padding-right: 5px;
        box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
      }

      .otherTitle {
        font-size: 16px;
        font-weight: 500;
        color: #9c2526;
        margin: 10px 0;
      }
    }
  }

  .readBoxbottom {
    width: 100%;
    height: 24px;
    background: #f5f5f5;
    position: relative;
    display: block;
  }

  .isfixed {
    bottom: 330px;
    position: fixed;
  }

  .posAbso {
    bottom: 250px;
    position: absolute;
  }

  .comment {
    right: 1px;
    width: 60px;
    background: #861D20;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 8px;
    text-align: center;
    cursor: pointer;

    .icon1 {
      display: inline-block;
      width: 21px;
      height: 20px;
      background: url("../../assets/images/head/comment.png") no-repeat;
      background-size: cover;
    }

    .opintip {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      // margin-top: 8px;
    }
  }

  .comment:hover {
    right: 1px;
    width: 60px;
    background: #9C2526;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 8px;
    text-align: center;
    cursor: pointer;

    .icon1 {
      display: inline-block;
      width: 21px;
      height: 20px;
      background: url("../../assets/images/head/comment_red.png") no-repeat;
      background-size: cover;
    }

    .opintip {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      // margin-top: 8px;
    }
  }
}
</style>
