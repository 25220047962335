<template>
  <!-- 学科专题 -->
  <div class="content">
    <!-- <div class="title">—&nbsp;热门专题&nbsp;—</div> -->
    <div class="swiper-container" ref="bannerContain">
      <div class="swiper-wrapper">
        <div class="swiper-slide" @click="handlego(item.id)" v-for="item in bannerList" :key="item.id">
          <img class="swimg" :src="item.cover" alt="">
          <div class="count">主文献量：{{ item.count }}</div>
          <div class="slname" :title='item.name'>{{ item.name }}</div>
          <div class="word">主题词：{{ item.theme_word }}</div>
          <div class="totaltag">
            <template v-if='item.disciplineList.length > 2'>
              <span class="subtag" v-for="(val, index) in item.disciplineList.slice(0, 2)" :key="index">{{ val }}</span>
              <span class="subtag" :title="item.disciplineList[3]">...</span>
            </template>
            <template v-else>
              <span class="subtag" v-for="(val, index) in item.disciplineList" :key="index">{{ val }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next">
      <img src="../../assets/images/home/next.png" alt="">
    </div>
    <div class="swiper-button-prev">
      <img src="../../assets/images/home/prev.png" alt="">
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  data () {
    return {
      bannerList: []
    }
  },
  mounted () {
    this.getdisciplinelist()
  },
  methods: {
    async getdisciplinelist () {
      const { data: res } = await this.$http({
        url: '/topicList/getBanner',
        method: 'get'
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.bannerList = res.data
      this.$nextTick(() => {
        new Swiper(this.$refs.bannerContain, {
          loop: true,
          slidesPerView: 4,
          slidesPerView: 'auto',
          spaceBetween: '0.8%',
          // loopedSlides: 7,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false // 解决滑动后不能轮播的问题
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        })
      })
    },
    handlego (id) {
      // this.$router.push('/disciplinedetail?id=' + id)
      const routeData = this.$router.resolve({
        path: '/disciplinedetail?id=' + id
        // query: params
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  padding-top: 12px;
  // height: 470px;
  background: #F7F7F7;
  position: relative;

  /deep/.swiper-button-next {
    right: 235px;
    color: rgba(255, 255, 255, 0);

    img {
      width: 13px;
      height: 22px;
      margin-left: 23px;
    }
  }

  /deep/.swiper-button-prev {
    left: 230px;
    color: rgba(0, 0, 0, 0);

    img {
      width: 13px;
      height: 22px;
      margin-left: 15px;
    }
  }

  /deep/.swiper-button-prev:hover {
    background: #9C2526;
  }

  /deep/.swiper-button-next:hover {
    background: #9C2526;
  }

  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #9C2526;
    line-height: 9px;
  }

  /deep/.swiper-button-next,
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    background: #E0E0E0;
    border-radius: 50%;
  }

  .swiper-container {
    // margin-top: 25px;
    width: 1322px;
    height: auto;

    .swiper-slide {
      background: #FFFFFF;
      border-radius: 4px;
      width: 302px;
      height: 262px;
      margin-top: 3px;
      position: relative;
      bottom: 10px;
      padding-top: 16px;
      padding-left: 10px;
      padding-bottom: 16px;
      padding-right: 10px;
      cursor: pointer;

      .swimg {
        width: 302px;
        height: 150px;
        border-radius: 4px;
      }

      .count {
        position: absolute;
        right: 20px;
        top: 27px;
        height: 22px;
        line-height: 22px;
        padding-left: 6px;
        padding-right: 8px;
        background: #FF7100;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #FFFFFF;
      }

      .slname {
        margin-top: 17px;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        // width: 63s;
        // line-height: 74px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }

      .word {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 16px;
        white-space: nowrap;
      }

      .totaltag {
        margin-top: 8px;
        overflow: hidden;
        white-space: nowrap;
        // text-overflow: ellipsis;
        // white-space: nowrap;

        // &::after {
        //   content: "...";
        //   // position: absolute;
        //   display: inline-block;
        //   background-color: red;
        //   width: 0.5em;
        //   // bottom: 0;
        //   // right: 0;
        //   // padding: 0 20px 0 10px;
        // }

        .subtag {
          padding: 4px 7px 4px 9px;
          background: #F7F7F7;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 400;
          color: #9C2526;
          margin-left: 10px;
          display: inline-block;
          white-space: nowrap;
          // width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .subtag:first-child {
          margin-left: 0px;
        }
      }

    }
  }
}
</style>
