<template>
  <div>
    <div class="content">
      <div class="container">
        <div class="copyTitle">
          <h3>版权声明</h3>
          <span class="copyLine"></span>
        </div>
        <!-- <div style="text-align: center;font-size: 20px;margin-top: 20px;">知识产权声明</div> -->
        <div class="copyInfo">
          <div>
            <p>本站所登载发布的一切内容，包括但不限于文字、图片、音像、图表、标志、标识、广告、商标、域名、软件、程序、版面设计、专栏目录与名称、内容分类标准以及为使用者提供的任何信息，均受《中华人民共和国著作权法》《中华人民共和国商标法》《中华人民共和国专利法》等法律法规以及有关国际条约的保护，相应权利由中国人民大学等相关权利人享有。</p>
            <br />
            <p>本站尊重任何个人、团体或机构所享有的著作权，所有入选文献均联系作者进行认领、授权。经作者授权允许本站进行遴选并登载后展示全文，尚未联系到作者完成认领、授权的入选文献暂缓显示全文。</p>
            <br />
            <p>为了保护各类文献资源的知识产权，本站所登载的文献仅供个人学习、研究使用，包括但不限于以个人学习、研究为目的的转发、引用、分享等。任何个人、团体或机构应自觉遵守知识产权的有关规定。</p>
            <br />
            <p>未经中国人民大学、作者等相关权利人的书面授权，任何人不得将本站所登载、发布的内容及相关服务用于商业性目的，亦不得违反本版权声明所允许的使用范围对本站的部分或全部内容或服务改动、转载、链接、复制、发行、传播等，或将之在非本站所属的服务器上作镜像。否则，中国人民大学将依法追究侵权者的法律责任。</p>
            <br />
            <p>著作权人或相关权利人如有异议，请与我们联系。</p>
            <br />
            <p>联系电话：010-62519375</p>
            <br />
            <p>电子邮箱：zszwx@ruc.edu.cn</p>
            <br />
            <p>地 址：北京市海淀区中关村大街59号文化大厦13-14层</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    document.title = "版权声明" + this.$route.meta.title;
  }
};
</script>
<style lang="less" scoped>
.container {
  // width: 1920px;
  // margin-top: 68px;
  height: 500px;
  background-image: url("../../assets/images/head/copyRight.png");
  background-position: center center;
  background-size: cover;
  position: relative;
  margin-bottom: 600px;

  .copyInfo {
    width: 1440px;
    height: 691.2px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    position: absolute;
    transform: translate(-50%, -0%);
    left: 50%;
    bottom: -100%;
    padding: 75px 77px;
    box-sizing: border-box;

    p {
      text-indent: 2em;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
    }
  }

  .copyTitle {
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    margin-top: 120px;
    text-align: center;

    h3 {
      width: 220px;
      height: 38px;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }

    .copyLine {
      margin-top: 27px;
      display: inline-block;
      width: 89px;
      height: 7px;
      background-image: url("../../assets/images/home/Dot 01.png");
      background-position: center center;
      background-size: contain; // // background: #9C2526;
      // transform: translate(40%);
      // color: #9c2526;
      // font-size: 40px;
    }
  }
}

// .bottom {
//   position: absolute;
//   bottom: 0;
//   left: 50%;
//   transform: translate(-50%);
// }

// .bottom {
//   width: 1920px;
//   // position: absolute;
//   // bottom: 0;
//   // left: 50%;
//   // transform: translate(-50%);
// }

/deep/.el-footer {
  padding: 0;
}
</style>
