
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    reducer (val) {
      return {
        // 只储存state中的token，而不会缓存uid
        token: val.token,
        userCode: val.userCode,
        // secondselectedTree: val.secondselectedTree,
        // firstselectedTree: val.firstselectedTree,
        // firstlistTree: val.firstlistTree,
        // secondlistTree: val.secondlistTree,
        // selectedauthor: val.selectedauthor, // 选中的作者值
        // // 选中的一级年份
        // firstselectyear: val.firstselectyear,
        // // 选中的二级年份期刊
        // secondselectedyear: val.secondselectedyear,
        // firstauthor: val.firstauthor,
        // secondauthor: val.secondauthor,
        // firstdiscipline: val.firstdiscipline,
        // seconddiscipline: val.seconddiscipline,
        // 主文献详情标题
        detailtitle: val.detailtitle,
        // imageUrl: localStorage.getItem('userImage') || '',
        imageUrl: val.imageUrl,
        userName: val.userName, // 用户昵称
        tagWords: val.tagWrods,
        userPhoneh5: val.userPhoneh5,
        userCodeh5: val.userCodeh5,
        userNameh5: val.userNameh5,
        threeReset: val.threeReset, //
        threeUrl: val.threeUrl// 存储当前跳转前url
      }
    }
  })],
  state: {
    // 存储当前登录用户的手机号,但没什么其他功能,只是为了监听当前手机号是否为超级号
    superPhone: '',
    subjectName: '',
    // userCode
    threeReset: false, // 人中中心修改密码
    token: '' || localStorage.getItem('token'),
    authorSearchContent: {
      search: '',
      time: ''
    },
    userCode: '',
    // 主文献热词搜索
    hotwordsearch: {
      search: null,
      timestamp: null
    },
    // hotwordsearch: {
    //   search: ''
    // },
    // 主文献选择多个年份
    mainsearch: {
      pubYear: '',
      resultContent: '',
      startTime: '',
      endTime: '',
      maintimestamp: null
    },
    // 选中的二级tree
    secondselectedTree: [],
    firstselectedTree: [],
    firstlistTree: [],
    secondlistTree: [],
    selectedauthor: [], // 选中的作者值
    // 选中的一级年份
    firstselectyear: [],
    // 选中的二级年份期刊
    secondselectedyear: [],
    // 作者列表
    firstauthor: [],
    secondauthor: [],
    firstdiscipline: [],
    seconddiscipline: [],
    // 主文献详情标题
    detailtitle: '',
    tenListEcharts: false,
    // imageUrl: localStorage.getItem('userImage') || '',
    imageUrl: '',
    userName: '', // 用户昵称
    tagWords: [],
    userPhoneh5: '',
    userCodeh5: '',
    userNameh5: '',
    userIdh5: '',
    threeUrl: '',
    localLogin: false,
    institutionsearch: {
      search: '',
      time: ''
    }, // 机构学科树
    journalFirst: [], // 机构一级学科
    journalSecond: [], // 机构二级学科
    journalLittleSearch: '', // 机构的小搜索
    journalSubjectList: [], // 机构的nameList
    categoryList: [],
    setSecond: null

    // 个人兴趣学科
    // intsubject: ''
  },
  mutations: {
    // intsubject(state, data) {
    //   state.intsubject = data
    // },
    setSetSecond (state, data) {
      state.setSecond = data
    },
    setSuperPhone (state, data) {
      state.superPhone = data
    },
    setCagegoryList (state, data) {
      state.categoryList = data
    },
    setJourNalSubjectList (state, data) {
      state.journalSubjectList = data
    },
    setJournalLittleSearch (state, data) {
      state.journalLittleSearch = data
    },
    setJournalSecond (state, data) {
      state.journalSecond = data
    },
    setJournalFirst (state, data) {
      state.journalFirst = data
    },
    setSubjectName (state, data) {
      state.subjectName = data
    },
    setTenListEcharts (state, data) {
      state.tenListEcharts = data
    },
    handleLocalLogin (state, data) {
      state.localLogin = data
    },
    setThreeUrl (state, data) {
      state.threeUrl = data
    },
    setthreeReset (state, data) {
      state.threeReset = data
    },
    setUserToken (state, data) {
      state.token = data
      // if (data) { localStorage.setItem('token', data) }
    },
    setAuthorSearch (state, data) {
      state.authorSearchContent.search = data
    },
    setAuthorSearchTime (state, data) {
      state.authorSearchContent.time = data
    },
    handlehotwordsearch (state, data) {
      state.hotwordsearch.search = data
    },
    handlehotwordsearchTime (state, data) {
      state.hotwordsearch.timestamp = data
    },
    // 主文献搜索结果
    yearsearch (state, data, payload) {
      state.mainsearch.pubYear = data.pubYear
      state.mainsearch.resultContent = data.resultContent
      state.mainsearch.startTime = data.startTime
      state.mainsearch.endTime = data.endTime
      state.mainsearch.maintimestamp = data.maintimestamp
    },
    // 二级选中tree
    secondLevelList (state, data) {
      if (JSON.stringify(state.secondselectedTree) !== JSON.stringify(data)) {
        state.secondselectedTree = data
      }
    },
    // 一级选中值
    firstLevelList (state, data) {
      state.firstselectedTree = data
    },
    firstList (state, data) {
      state.firstlistTree = data
    },
    secondList (state, data) {
      state.secondlistTree = data
    },
    selectedauthor (state, data) {
      state.selectedauthor = data
    },
    firstyear (state, data) {
      state.firstselectyear = data
    },
    secondyear (state, data) {
      state.secondselectedyear = data
    },
    firstauthor (state, data) {
      state.firstauthor = data
    },
    secondauthor (state, data) {
      state.secondauthor = data
    },
    firstdiscipline (state, data) {
      state.firstdiscipline = data
    },
    seconddiscipline (state, data) {
      state.seconddiscipline = data
    },
    maindetailTitle (state, data) {
      state.detailtitle = data
    },
    // 头像的照片
    setImage (state, data) {
      state.imageUrl = data
    },
    // 用户昵称
    userName (state, data) {
      state.userName = data
    },
    settagWords (state, data) {
      state.tagWords = data
    },
    setUserCode (state, data) {
      state.userCode = data
    },
    setUserPhoneh5 (state, data) {
      state.userPhoneh5 = data
    },
    setUserCodeh5 (state, data) {
      state.userCodeh5 = data
    },
    setUserNameh5 (state, data) {
      state.userNameh5 = data
    },
    setUserIdh5 (state, data) {
      state.userIdh5 = data
    },
    setInstitutionSearch (state, data) {
      state.institutionsearch.search = data
    },
    setInstitutionSearchTime (state, data) {
      state.institutionsearch.time = data
    }
  },
  actions: {

  },
  getters: {

  },
  modules: {
  }
})
