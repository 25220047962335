var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div'),_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"resources"},[_c('div',{staticClass:"inttitle"},[_vm._v("平台资源")]),_vm._m(3),_c('div',{staticClass:"third"},[_c('div',{staticClass:"title1"},[_vm._m(4),_c('div',{staticClass:"text"},[_vm._v("共遴选"+_vm._s(_vm.mianliteratureNum)+"篇文献")])]),_vm._m(5),_c('div',{staticClass:"title2"},[_vm._m(6),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.seconddisciplineNum)+"个二级学科")])])]),_c('div',{staticClass:"text",staticStyle:{"margin":"0 auto","width":"60%"}},[_c('p',{staticClass:"texP"},[_vm._v(" “哲社主文献”一期建设中的主文献以学术论文（2013-2022年）为主，共遴选"+_vm._s(_vm.mianliteratureNum)+"篇文献。涵盖哲学、理论经济学、应用经济学（含统计学）、法学（含公安学）、政治学、社会学、民族学、马克思主义理论、中共党史党建学、教育学、心理学、体育学、中国语言文学、外国语言文学、新闻传播学、艺术学、考古学、中国史、世界史、地理学、生态学、工商管理、农林经济管理、公共管理、信息资源管理等哲学社会科学25个一级学科、"+_vm._s(_vm.seconddisciplineNum)+"个二级学科。 ")]),_c('p',{staticClass:"texP mt30"})])]),_vm._m(7),_c('div',{staticClass:"serve"},[_vm._m(8),_vm._m(9),_c('div',{staticClass:"tip",on:{"click":_vm.goSelect}},[_vm._v("注：各学科参照上述基本标准形成本学科的遴选标准（具体可见各学科主文献遴选标准说明页面）。")]),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15)]),_c('footerComponent')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleimg"},[_c('div',{staticClass:"t1"}),_c('div',[_vm._v("中国特色哲学社会科学自主知识体系数字创新平台")]),_c('div',{staticClass:"t2"},[_vm._v("哲学社会科学主文献平台简介")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduct"},[_c('div',{staticClass:"inttitle"},[_vm._v("平台简介")]),_c('div',{staticStyle:{"text-align":"center"}},[_c('img',{staticClass:"intimg",attrs:{"src":require("../../assets/images/home/intimg.png")}})]),_c('div',{staticClass:"mainlit"},[_c('div',{staticClass:"maintext"},[_c('div',{staticClass:"maintitle"},[_vm._v("哲社主文献")]),_c('p',{staticClass:"maincontent"},[_vm._v(" 哲学社会科学主文献平台（简称“哲社主文献”）是中国特色哲学社会科学自主知识体系数字创新平台（简称“学术世界”）的子平台，由中国人民大学书报资料中心具体承建，是一个高效研读学术精品的指南，辅助学习、教学、科研的工具。 ")])]),_c('img',{staticClass:"mainimg",attrs:{"src":require("../../assets/images/home/Imagescopy.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduct"},[_c('div',{staticClass:"inttitle top"},[_vm._v("平台定位")]),_c('div',{staticStyle:{"text-align":"center"}},[_c('img',{staticClass:"intimg",attrs:{"src":require("../../assets/images/home/intimg.png")}})]),_c('div',{staticClass:"poslit"},[_c('img',{staticClass:"posimg",attrs:{"src":require("../../assets/images/home/posimg.png")}}),_c('p',{staticClass:"poscontent"},[_vm._v(" “哲社主文献”以马克思主义为指导，面向哲学社会科学自主知识体系建构和高质量教学科研创新的需求，通过一套科学标准和规范流程，基于学术共同体的专业评审，遴选和荟萃各学科主流、经典、必读的文献，进而汇集形成的精品学术资源典藏库和重要文献参考体系。 “哲社主文献”构建了一个全学科覆盖、动态更新的学术资源精品库，既是一个主流、经典、必读的精选学术文献参考体系，也是一个智能挖掘、协同创新的精加工知识服务产品。“哲社主文献”在建设导向上坚持价值引领理念，在遴选流程上坚持全量优选原则、在成果展现上坚持数智组织方式，在服务目标上坚持创新推动宗旨，力争获得学术共同体的广泛认可，彰显文献精选、人才培养、知识传播、学术评价、导向指引和原创助力六大应用价值。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"}},[_c('img',{staticClass:"intimg",attrs:{"src":require("../../assets/images/home/intimg.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon1"},[_c('img',{attrs:{"src":require("../../assets/images/home/mianliterature.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title2"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../assets/images/home/disciplinelike.png"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("25个一级学科")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../assets/images/home/seconddisciplineNum.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduct"},[_c('div',{staticClass:"inttitle"},[_vm._v("平台服务")]),_c('div',{staticStyle:{"text-align":"center"}},[_c('img',{staticClass:"intimg",attrs:{"src":require("../../assets/images/home/intimg.png")}})]),_c('div',{staticClass:"content mt38"},[_vm._v(" “哲社主文献”客观展示主文献遴选结果，以学科为基础，从作者、机构、期刊、学术热点、原创概念、热门专题、热词等多个维度为用户提供信息检索、资源导航、快速筛选、文献导读、数据统计、个性化推荐与订阅等及时、动态的精选信息资源和精加工知识服务。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sertitle"},[_c('span',{staticClass:"sericon"}),_c('span',{staticClass:"sertitletext"},[_vm._v("“哲社主文献”一期遴选标准")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{attrs:{"border":"1","cellspacing":"0"}},[_c('tr',[_c('th',{staticClass:"tabth",attrs:{"width":"21%"}},[_vm._v("遴选标准")]),_c('th',{staticClass:"tabth"},[_vm._v("说明")])]),_c('tr',[_c('td',{staticClass:"tabtd1"},[_vm._v("政治立场")]),_c('td',{staticClass:"tabtd2"},[_vm._v("坚持马克思主义，坚持以习近平新时代中国特色社会主义思想为指导。 ")])]),_c('tr',[_c('td',{staticClass:"tabtd1"},[_vm._v("学术诚信")]),_c('td',{staticClass:"tabtd2"},[_vm._v("遵循学术规范，恪守学术道德，无知识产权问题。")])]),_c('tr',[_c('td',{staticClass:"tabtd1"},[_vm._v("理论创新")]),_c('td',{staticClass:"tabtd2"},[_vm._v("注重学理研究，发现新问题，提出新概念、新观点、新方法、新学说，揭示新规律，体现原创性、时代性，引领学术研究的创新性发展。")])]),_c('tr',[_c('td',{staticClass:"tabtd1"},[_vm._v("知识贡献")]),_c('td',{staticClass:"tabtd2"},[_vm._v("对知识发现和知识创新有较大贡献，具有重要学术价值，是学科领域知识体系的必要组成部分，助力中国哲学社会科学自主知识体系建构。")])]),_c('tr',[_c('td',{staticClass:"tabtd1"},[_vm._v("学科影响")]),_c('td',{staticClass:"tabtd2"},[_vm._v("在学科领域教学科研中被广泛关注和引用，被公认为必读文献，对学科的建设、发展和国际学术影响力的提升具有重要作用。")])]),_c('tr',[_c('td',{staticClass:"tabtd1"},[_vm._v("实践引导")]),_c('td',{staticClass:"tabtd2"},[_vm._v("具有较高应用价值，对解决中国经济社会发展中的重要问题起到理论支撑和实践引领作用。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sertitle"},[_c('span',{staticClass:"sericon mt44"}),_c('span',{staticClass:"sertitletext mt38"},[_vm._v("“哲社主文献”一期遴选过程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sertxt"},[_c('p',{staticClass:"txtp"},[_vm._v(" 依据国务院学位委员会、教育部发布的《研究生教育学科专业目录(2022年)》并结合遴选实际需要设置主文献学科分类方案。“哲社主文献”一期的遴选范围是2013—2022年间公开发表的约400万篇哲学社会科学学术论文，覆盖所有一级、二级学科。"),_c('br'),_c('br'),_vm._v("        通过大数据筛选，参考复印报刊资料和《新华文摘》等二次文献、各大学术数据库高被引论文、高等学校科学研究优秀成果奖（人文社会科学）获奖论文等，并通过专家推荐、机构调研等形式广泛征集学界意见，遴选出约14万篇初始备选文献。"),_c('br'),_c('br'),_vm._v("        主文献一期建设，共邀请全国范围内1300余名专家组成各学科主文献评审组，分别进行了通讯评审、会议评审、终评审定，共遴选出2万余篇文献。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sertitle"},[_c('span',{staticClass:"sericon mt44"}),_c('span',{staticClass:"sertitletext mt38"},[_vm._v("入选更新")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sertxt"},[_c('p',{staticClass:"txtp"},[_vm._v(" “哲社主文献”将根据学术发展动态，每1—3年对主文献资源进行更新、完善：（1）对2013年以前的论文追溯遴选，对2022年以后的论文逐年补选。（2）将学术著作纳入主文献遴选范围。（3）将作者、读者推荐给平台的文献纳入遴选范围。（4）已入选文献如果存在观点错误、学术不端等不宜列为主文献的情况，发现、核实后将从平台上撤销。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sertitle"},[_c('span',{staticClass:"sericon mt44"}),_c('span',{staticClass:"sertitletext mt38"},[_vm._v("联系方式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sertxt pb20"},[_c('p',{staticClass:"txtp"},[_vm._v(" 欢迎广大读者对“哲社主文献”的遴选和平台功能提出意见与建议。"),_c('br'),_vm._v("        联系电话：010-62519375"),_c('br'),_vm._v("        电子邮箱： zszwx@ruc.edu.cn ")])])
}]

export { render, staticRenderFns }