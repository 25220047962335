<template>
  <div class="empty" v-if="quotelist.length == 0">
    <img src="../../assets/images/home/nodatasmall.png" class="img" alt />
    <p>没有查询到数据哦</p>
  </div>
</template>

<script>
export default {
  props: ["quotelist",]
};
</script>

<style lang="less" scoped>
.empty {
  margin: 80px 0;

  .img {
    width: 276px;
    height: 137px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  p {
    text-align: center;
    color: #666;
  }
}
</style>
