<template>
  <div>
    <div class="header">
      <signagure-head></signagure-head>
    </div>
    <div class="content">
      <el-descriptions border :column='1' :colon='true'>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="文献题目:">{{ name
        }}</el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="作者:">{{ authorName
        }}</el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="作者机构:">{{ authorOrg
        }}</el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="原发期刊:"
          v-if="originInfo">{{ originInfo
          }}</el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content" label="所属学科:" v-if="discs">{{
          discs
        }}</el-descriptions-item>
        <el-descriptions-item label-class-name="my-label" content-class-name="my-content-info" label="内容摘要:"
          v-if="mainContent">{{
            mainContent }}</el-descriptions-item>
      </el-descriptions>
      <p>温馨提示：以上是您认领的主文献，平台上线后，您还可以继续认领或退领。</p>
    </div>

    <div class="handleBtn">
      <el-button class='sure' type="primary" @click="sure">确认认领</el-button>
      <el-button class='goBack' @click='$router.go(-1)'>返回</el-button>
    </div>
  </div>
</template>
<script>
import signagureHead from '@/components/head/signagureHead'
export default {
  name: 'signatureDetail',
  components: {
    signagureHead
  },
  data() {
    return {
      title: '哲学社会科学主文献',
      referenceNumber: '',
      name: '',
      authorName: '',
      authorOrg: '',
      originInfo: '',
      discs: '',
      mainContent: '',
      authorList: [],
      path: ''
    }
  },
  mounted() {
    document.title = '主文献-传播学术精品'
    this.referenceNumber = this.$route.params.referenceNumber
    this.path = this.$route.params.path
    console.log(this.referenceNumber)
    this.getList()
  },
  methods: {
    async getList() {
      const { data: res } = await this.$http({
        url: '/claimAuth/getLiteratureInfoDetail',
        method: 'GET',
        params: { referenceNumber: this.referenceNumber }
      })
      this.name = res.data.title
      this.authorName = res.data.authorName
      this.authorOrg = res.data.company
      if (res.data.originInfo) {
        var journal = res.data.originInfo.match(/第([\s\S]*?)期/, '')[0]
        res.data.originInfo = res.data.originInfo.replace(/第([\s\S]*?)期/, '').replace(/第([\s\S]*?)页/, '')
        this.originInfo = res.data.originInfo + journal
      }
      this.discs = res.data.discipLineInfo
      this.mainContent = res.data.mainContent
    },
    sure() {
      this.$bus.$emit('pch5', this.referenceNumber)
      this.$router.replace('/signaturesLiteratureList?' + this.path)
    },
    goback() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
.content {
  width: 1440px;
  height: 421px;
  margin: 30px auto;

  .el-descriptions {}
}

/deep/.my-label {
  background-color: #fff !important;
  font-weight: 550 !important;
  width: 125px !important;
  color: #000 !important;
  font-size: 16px !important;
  // height: 49px !important;
}

/deep/.my-content {
  color: #333 !important;
  font-size: 16px !important;
}

/deep/.my-content-info {
  color: #666 !important;
  font-size: 16px !important;
}

/deep/.el-descriptions-row {
  height: 49px !important;
}

.handleBtn {
  width: 460px;
  height: 50px;

  .sure {
    width: 460px;
    height: 50px;
    position: fixed;
    bottom: 100px;
    left: 50%;
    border-radius: 25px;
    transform: translate(-50%);
  }

  .goBack {
    color: #9c2526;
    width: 460px;
    height: 50px;
    position: fixed;
    bottom: 40px;
    left: 50%;
    border-radius: 25px;
    transform: translate(-50%);
    margin: 0;
  }
}

p {
  text-align: left;
}
</style>
