<template>
  <div
    v-loading="loading"
    element-loading-spinner="none"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <div class="header">
      <signagureHead></signagureHead>
    </div>
    <div class="content">
      <div class="bglogo"></div>
      <div class="loginInfo">
        <div class="logoTitle">
          <img src="../../assets/images/head/logo1.png" alt />
          <img
            style="margin-left: 57.6px"
            src="../../assets/images/head/logo2_1.png"
            alt
          />
        </div>
        <div>
          <div class="text" style="margin-top: 18.4147px">
            <p class="titlesublevel">尊敬的作者：</p>
            <p class="sublevel">您好!</p>
            <p class="sublevel">
              “哲学社会科学主文献平台”是“中国哲学社会科学自主知识体系数字创新平台”（简称“学术世界”）的子平台，由中国人民大学牵头建设、中国人民大学书报资料中心具体承建。“学术世界”是一个融学术创新、学术交流、学术发表、学术资源和学术服务于一体的公益性、开放性、全学科、全链条、数智化的平台。
            </p>
            <p class="sublevel">
              “哲学社会科学主文献”旨在通过一套科学的遴选标准、规范的遴选流程、以及基于学术共同体的专业评审，荟萃各学科主流、经典、必读的文献，建设一个权威、开放、动态的精品阅读文献库和精加工的知识服务平台，传播中国学术精品，服务中国知识创新。
            </p>
            <p class="sublevel">
              “哲学社会科学主文献”具有极高的文献精选、学术评价和知识传播价值，由各学科领域的权威专家参与遴选，秉持着优中选优、层层优选的遴选原则。一期首次遴选，在2013—2022年期间约400万篇哲学社会科学领域公开发表的规范性学术论文中，最终遴选出约2万篇文献进入主文献一期数据库，占所有学术文献数量的比例不超过1%，充分展现主流、经典、必读的特征。
            </p>
            <p class="sublevel">
              <span>恭喜您的论文入选了“哲学社会科学主文献”！</span
              >现诚邀您对本人入选的主文献进行认领，并希望得到您的授权，以便我们能将您的入选文献上传至“学术世界”平台以加速学术传播、扩大学术影响力。认领并授权后，您可领取复印报刊资料数据库知名专家账号（使用期一年）。
            </p>
            <p class="sublevel">
              平台目前处于测试阶段，如发现个别信息错误，欢迎通过邮箱或电话向我们反映。感谢您的大力支持！
            </p>
            <p class="teacher">联系人：张老师，杨老师</p>
            <p class="teacher">联系电话：010-62519375</p>
            <p class="teacher">电子邮箱：zszwx@ruc.edu.cn</p>
          </div>
          <div class="inscriptionBox">
            <div class="inscription">
              <!-- <div class="name">中国人民大学书报资料中心</div> -->
              <div class="name">
                中国人民大学中国哲学社会科学自主知识体系数字创新平台
              </div>
              <div class="name">中国人民大学书报资料中心</div>
              <div class="date">{{ this.dateTime }}</div>
            </div>
          </div>
        </div>

        <el-button type="primary" @click="handleUserLogin"
          >查看我的主文献</el-button
        >
        <div class="bottom"></div>
      </div>
    </div>
  </div>
</template>
<script>
import signagureHead from "@/components/head/signagureHead";
export default {
  name: "signatureInvite",
  components: {
    signagureHead,
  },
  data() {
    return {
      // token: '' || localStorage.getItem('h5Token'),
      count: 0,
      userCode: "",
      loading: true,
      userName: "",
      dateTime: "",
      path: "",
    };
  },
  created() {
    var index = this.$route.fullPath.indexOf("?");
    this.path = this.$route.fullPath.substr(index + 1);
    // 判断当前是否是手机
    if (this._isMobile() && this.BASE_URL != "/api/") {
      this.$options.render = () => {}; // 清空 render 函数，不进行渲染
      this.$options.mounted = () => {}; // 清空 mounted 函数，不执行后续操作
      return (window.location.href =
        this.BASE_URL + "mobile/index.html#/" + this.path);
    } else {
      this.loading = false;
    }
  },
  async mounted() {
    document.title = "主文献-传播学术精品";
    this.handleTest(this.path);
    // 获取年月日
    this.getCurrentYMD();
    // const path = window.location.href
    // console.log(path)
    // this.handleUserCount(path)
    // await this.handleUserInfo()
  },

  methods: {
    // 获取当前年月日
    getCurrentYMD() {
      const date = new Date();
      const year = date.getFullYear(); // 获取完整的年份(4位)
      let month = date.getMonth() + 1; // 获取当前月份(0-11,0代表1月)
      let strDate = date.getDate(); // 获取当前日(1-31)
      if (month < 10) month = `0${month}`; // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}`; // 如果日是个位数，在前面补0
      this.dateTime = `${year}年${month}月${strDate}日`;
    },
    // 判断是否是手机
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 根据用户状态不同跳转到不同页面
    async handleTest(path) {
      const res = await this.$http({
        url: "/claimApplet/isClaim",
        method: "POST",
        data: {
          link: path,
        },
      });
      if (res.data.code === 210012) {
        return this.$message.error("路径错误，请复制短信/邮箱中的链接重新进入");
      }
      this.userCode = res.data.data.userCode;
      this.$store.commit("setUserCodeh5", this.userCode);
      localStorage.setItem("userCodeh5", this.userCode);
      if (
        res.data.data.status === "0" ||
        res.data.data.status === null ||
        res.data.data.status === ""
      ) {
        // 未登录
        // this.$router.replace(`/signaturesLogin?userCode=${this.userCode}`)
      } else {
        this.$router.replace(`/signaturesPhone?${path}`);
      }
    },
    // 跳转到下一页
    handleUserLogin() {
      // console.log(this.token)
      // 如果用户没有登录过就先去登录
      this.$store.commit("setUserCodeh5", this.userCode);
      localStorage.setItem("userCodeh5", this.userCode);
      // this.$store.commit('setUserNameh5', this.userName)
      // localStorage.setItem('userNameh5', this.userName)
      this.$router.replace(`/signaturesLogin?${this.path}`);
    },
    // 获取用户信息
    // async handleUserInfo () {
    //   const res = await this.$http({
    //     url: '/claimApplet/getWritePhone',
    //     method: 'POST',
    //     data: {
    //       userCode: this.$route.query.userCode
    //     }
    //   })
    //   this.$store.commit('setUserCodeh5', this.$route.query.userCode)
    //   localStorage.setItem('userCodeh5', this.$route.query.userCode)
    //   if (res.data.data.real_name) {
    //     this.$store.commit('setUserNameh5', res.data.data.real_name)
    //     localStorage.setItem('userNameh5', res.data.data.real_name)
    //   }
    //   if (res.data.data.write_phone) {
    //     this.$store.commit('setUserPhoneh5', res.data.data.write_phone)
    //     localStorage.setItem('userPhoneh5', res.data.data.write_phone)
    //   }
    // }
  },
};
</script>
<style lang='less' scoped>
.content {
  display: flex;
  align-items: center;
  margin-top: 30px;

  .bglogo {
    width: 660px;
    height: 676px;
    background-image: url("../../assets/images/home/bglogo.png");
    background-size: cover;
    margin-left: 200px;
  }

  .loginInfo {
    flex: 1;
    padding: 0 40px;
    box-sizing: border-box;

    .logoTitle {
      display: flex;
      width: 372px;
      margin: 0 auto;

      img {
        // width: 372px;
        height: 55px;
      }
    }

    .text {
      text-align: justify;

      .titlesublevel {
        margin-bottom: 10px;
      }

      .sublevel {
        margin-bottom: 10px;
        text-indent: 30px;
      }

      p {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
        line-height: 24px;

        span {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #9c2526;
          line-height: 24px;
        }
      }
    }

    .inscriptionBox {
      display: flex;
      justify-content: flex-end;
      text-align: right;

      .inscription {
        // width: 195px;
        margin-top: 43px;
        float: right;

        .name {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #000000;
          text-align: right;
          // margin-top: 14px;
        }

        .date {
          text-align: right;
          margin-top: 14px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #000000;
          // margin-top: 14px;
        }
      }
    }

    /deep/.el-button {
      margin-top: 15px;
      margin-left: 145px;
      width: 460px;
      height: 50px;
      background: #9c2526;
      border-radius: 25px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #fefefe;
    }
  }
}

.teacher {
  text-indent: 30px;
  // margin-top: 14px;
}

.bottom {
  height: 50px;
  background-color: transparent;
}
</style>
