<template>
  <div class="institutionrtBox">
    <div class="chartBox">
      <div class="lineBox">
        <div class="linechartTitlebox">
          <p>主文献成果统计</p>
          <div>
            <el-date-picker style="width:0.6rem;" v-model="startyear" type="year" placeholder="开始时间" value-format="yyyy"
              :picker-options="pickerOptions" @change="handlestartyear"></el-date-picker>
            <span style="margin:0 10px;">-</span>
            <el-date-picker style="width:0.6rem;" v-model="endtyear" type="year" placeholder="结束时间" value-format="yyyy"
              :picker-options="pickerOptions" @change="handleendtyear"></el-date-picker>
          </div>
        </div>
        <div class="linechart" ref="linechart"></div>
      </div>
      <div class="barBox">
        <div class="linechartTitlebox">
          <p>主要学科分布</p>
        </div>
        <div class="barchart" ref="barchart"></div>
      </div>
    </div>

    <div class="mainListBox">
      <div class="mainListName">入选主文献</div>
      <mainList :Listobj="Listobj"></mainList>
    </div>
  </div>
</template>

<script>
import mainList from '../main/mainlist'
import elementResizeDetectorMaker from 'element-resize-detector'
const erd = elementResizeDetectorMaker()
export default {
  components: {
    mainList
  },
  props: ['listname', 'chineseName', 'rnList'],
  data () {
    return {
      clickNumList: [],
      collectNumList: [],
      commentNumList: [],
      downloadNumList: [],
      likeNumList: [],
      literatureList: [],
      shareNumList: [],
      yearList: [],
      startyear: new Date().getFullYear() - 10 + '', // 开始时间
      endtyear: new Date().getFullYear() - 1 + '', // 结束时间
      lineyearData: [], // 入选趋势x轴
      linecount: [], // 入选趋势大小
      // 传给子组件
      Listobj: {
        type: 2, /// 0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅
        url: '',
        orgCode: this.listname,
        firstLevelList: [],
        secondLevelList: [],
        chineseName: '',
        rnList: []
      },
      pickerOptions: {
        disabledDate: time => {
          const date = new Date().getFullYear()
          const yearEnd = time.getFullYear()
          return date < yearEnd
        }
      }
    }
  },
  beforeDestroy () {
    erd.uninstall(this.$refs.linechart)
    erd.uninstall(this.$refs.barchart)
  },
  mounted () {
    this.Listobj.orgCode = this.listname
    this.Listobj.chineseName = this.chineseName
    this.Listobj.rnList = this.rnList
    window.addEventListener('resize', () => {
      this.getLineData()
    })
    // this.Listobj.url = "/literatureInfoList/getList";
  },
  methods: {
    // 选择年份
    handlestartyear (start) {
      this.startyear = start
      if (this.startyear > this.endtyear) {
        return this.$message({
          type: 'warning',
          message: '建议开始时间选择大于结束时间,结束时间早于开始时间'
        })
      }
      this.getlistData()
    },
    handleendtyear (end) {
      this.endtyear = end
      if (this.startyear > this.endtyear) {
        return this.$message({
          type: 'warning',
          message: '建议开始时间选择大于结束时间,结束时间早于开始时间'
        })
      }
      this.getlistData()
    },
    //   入选趋势
    async getlistData () {
      const { data: res } = await this.$http({
        url: '/organization/inclusionTrend',
        method: 'POST',
        data: {
          endTime: parseInt(this.endtyear),
          startTime: parseInt(this.startyear),
          orgCode: this.listname // 机构名称
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      // this.lineyearData = res.data.map(item => {
      //   return item.pub_year
      // })
      this.clickNumList = res.data.clickNumList
      this.collectNumList = res.data.collectNumList
      this.commentNumList = res.data.commentNumList
      this.downloadNumList = res.data.downloadNumList
      this.likeNumList = res.data.likeNumList
      this.literatureList = res.data.literatureList
      this.shareNumList = res.data.shareNumList
      this.yearList = res.data.yearList
      // this.linecount = res.data.map(item => {
      //   return item.count
      // })
      this.getLineData()
    },
    getLineData () {
      var myChartline = this.$echarts.init(this.$refs.linechart)
      var option
      function fontSize (res) {
        const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        if (!clientWidth) return
        const fontSize = 100 * (clientWidth / 1920)
        return res * fontSize
      }

      option = {
        responsive: true,

        xAxis: {
          type: 'category',
          data: this.yearList,
          boundaryGap: false,
          axisLabel: {
            textStyle: {
              fontSize: fontSize(0.14)
            }
          }
        },

        yAxis: {
          type: 'value',
          minInterval: 1,
          //  scale:true,
          //  splitNumber:50,
          //  boundaryGap:[0.2,0.2],
          // min: 'dataMin', // 最小值
          // max: 'dataMax', // 最大值
          axisLabel: {
            textStyle: {
              fontSize: fontSize(0.16)
            }
          }
        },
        grid: {
          left: '2%',
          containLabel: true
        },
        legend: {
          type: 'scroll', // 设置图例为滚动样式
          bottom: '0%',
          left: 'left',
          itemHeight: fontSize(0.06), // 修改icon图形大小
          itemGap: fontSize(0.14), // 修改间距
          textStyle: {
            fontSize: fontSize(0.14)
          },
          pageIconSize: fontSize(0.16),
          pageTextStyle: { // 图例页信息的文字样式
            fontSize: fontSize(0.14)
          }
        },

        tooltip: {
          trigger: 'axis'
        },
        series: [

          // {
          //   data: this.linecount,
          //   type: 'line',
          //   smooth: true,
          //   itemStyle: {
          //     color: '#9c2526'
          //   }
          // }
          // {
          //   name: '点击',
          //   type: 'line',
          //   stack: 'Total',
          //   data: this.clickNumList
          // },
          {
            name: '收藏',
            type: 'line',
            // stack: 'Total',
            data: this.collectNumList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03)// 0.1的线条是非常细的了
                }
              }
            }
          },
          {
            name: '评论',
            type: 'line',
            // stack: 'Total',
            data: this.commentNumList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03)// 0.1的线条是非常细的了
                }
              }
            }
          },
          {
            name: '下载',
            type: 'line',
            // stack: 'Total',
            data: this.downloadNumList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03)// 0.1的线条是非常细的了
                }
              }
            }
          },
          {
            name: '点赞',
            type: 'line',
            // stack: 'Total',
            data: this.likeNumList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03)// 0.1的线条是非常细的了
                }
              }
            }
          },
          {
            name: '分享',
            type: 'line',
            // stack: 'Total',
            data: this.shareNumList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03)// 0.1的线条是非常细的了
                }
              }
            }
          },
          {
            name: '文献',
            type: 'line',
            // stack: 'Total',
            data: this.literatureList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03)// 0.1的线条是非常细的了
                }
              }
            }
          },
          {
            name: '点击',
            type: 'line',
            // stack: 'Total',
            data: this.clickNumList,
            symbolSize: fontSize(0.05),
            itemStyle: {
              normal: {
                lineStyle: {
                  width: fontSize(0.03)// 0.1的线条是非常细的了
                }
              }
            }
          }
        ]
      }

      myChartline.setOption(option)
      // const that = this
      erd.listenTo(this.$refs.linechart, function () {
        myChartline.resize()
      })
    },
    // 柱状图数据
    async getpieData () {
      const { data: res } = await this.$http({
        url: '/organization/majorDiscipline',
        method: 'POST',
        data: {
          orgCode: this.listname // 机构名称
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      var myChartbar = this.$echarts.init(this.$refs.barchart)
      function fontSize (res) {
        const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        if (!clientWidth) return
        const fontSize = 100 * (clientWidth / 1920)
        return res * fontSize
      }
      var option
      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          type: 'scroll', // 设置图例为滚动样式
          bottom: '0%',
          left: 'left',
          itemHeight: fontSize(0.06), // 修改icon图形大小
          itemGap: fontSize(0.14), // 修改间距
          textStyle: {
            fontSize: fontSize(0.14)
          },
          pageIconSize: fontSize(0.16),
          pageTextStyle: { // 图例页信息的文字样式
            fontSize: fontSize(0.14)
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['35%', '70%'],
            center: ['50%', '40%'], // 调整饼图位置
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            // minAngle: 5, // 设置最小角度
            emphasis: {
              label: {
                show: true,
                fontSize: 14
                // fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: res.data
          }
        ]
      }
      myChartbar.setOption(option)
      erd.listenTo(this.$refs.barchart, function () {
        myChartbar.resize()
      })
    }
  },
  watch: {
    listname (val, oldVal) {
      if (val !== oldVal) {
        this.Listobj.orgCode = val
        this.getlistData()
        // 主要学科分布
        this.getpieData()
      }
    },
    rnList (newData, old) {
      this.Listobj.url = '/organization/search/literature'
      this.Listobj.rnList = newData
    }
  }
}
</script>
<style lang="less" scoped>
.institutionrtBox {
  .chartBox {
    display: flex;
    width: 1080px;
    height: 480px;

    .lineBox {
      margin-right: 10px;
      flex: 1;
      height: 400px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
      border-radius: 10px;
      padding: 20px;
      width: 530px;
      height: 480px;

      .linechartTitlebox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        >p {
          font-size: 18px;
          font-weight: 500;
          color: #9c2526;
        }

        /deep/.el-range-editor.el-input__inner {
          width: 200px;
        }
      }

      .linechart {
        width: 500px;
        height: 374.4px;

        /deep/canvas {
          width: 500px !important;
          height: 374.4px !important;
        }
      }
    }

    .barBox {
      margin-left: 10px;
      flex: 1;
      box-sizing: border-box;
      height: 400px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
      border-radius: 10px;
      padding: 20px;
      width: 530px;
      height: 480px;
      // overflow: hidden;

      .linechartTitlebox {
        >p {
          font-size: 18px;
          font-weight: 500;
          color: #9c2526;
        }
      }

      .barchart {
        width: 500px;
        height: 350px;
        z-index: 99;
        margin-top: 35px;

        /deep/canvas {
          width: 500px !important;
          height: 350px !important;
          transform: translate(9px);
        }
      }
    }
  }

  .mainListBox {
    background: #ffffff;
    // box-shadow: 0px .1042vw .5208vw 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding: 20px;
    // margin-bottom: .7813vw;

    .mainListName {
      font-size: 18px;
      font-weight: 500;
      color: #9c2526;
      margin-bottom: 10px;
    }
  }

  .selectedjournal {
    height: 480px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;

    .journalTitle {
      display: flex;
      justify-content: space-between;

      >p {
        font-size: 18px;
        font-weight: 500;
        color: #9c2526;
      }

      .journalTotal {
        font-weight: 600;
        color: @my-color;
      }
    }

    .box {
      overflow: hidden;
      overflow-x: auto;
      width: 1000px;
      white-space: nowrap;

      .selectedjournalBox {
        width: 100%;
        padding: 10px 0;

        .journalItem {
          margin: 15px 35px 0 0;
          width: 260px;
          height: 390px;
          box-sizing: border-box;
          display: inline-block;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          text-align: center;

          .journalItemimg {
            >img {
              width: 100%;
              height: 350px;
              // object-fit: contain;
              object-fit: cover;
            }
          }

          .itemTxt {
            height: 16px;
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            padding: 20px 0;
          }
        }
      }
    }
  }
}
</style>
