<template>
  <div class="noticeBox">
    <div class="tabBox">
      <div class="tabTileLeft">
        <span
          class="tabTileLeftitem"
          v-for="(item, i) in tabList"
          :key="i"
          @click="handleTabClick(i)"
          :class="{ active: currentTab == i }"
        >
          <span>{{ item.label }}</span>
        </span>
      </div>
    </div>
    <div class="tabContent">
      <el-table
        :header-cell-style="tableHead"
        :data="tableData"
        style="width: 100%"
        v-if="total > 0 &&this.currentTab==0"
      >
        <el-table-column align="center" prop="termsDesc" label="操作事项"></el-table-column>
        <el-table-column align="center" prop="value" label="获取积分"></el-table-column>
        <el-table-column align="center" prop="valueBalance" label="积分余额"></el-table-column>
        <el-table-column align="center" prop="createTime" label="获取时间"></el-table-column>
      </el-table>
      <el-table
        :header-cell-style="tableHead"
        :data="tableData"
        style="width: 100%"
        v-if="total > 0 &&this.currentTab==1"
      >
        <el-table-column align="center" prop="termsDesc" label="交易说明"></el-table-column>
        <el-table-column align="center" prop="value" label="获取经验值"></el-table-column>
        <el-table-column align="center" prop="valueBalance" label="经验值"></el-table-column>
        <el-table-column align="center" prop="createTime" label="交易时间"></el-table-column>
      </el-table>
      <div class="empty" v-if="total==0">
        <img src="../../assets/images/home/nodataside.png" alt />
        <span>暂无数据</span>
      </div>
      <el-pagination
        v-if="total > 0"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      currentTab: 0,
      tabList: [
        {
          label: "积分明细",
          value: "0"
        },
        {
          label: "经验值明细",
          value: "1"
        }
      ], //tab切换 0-积分明细 1-经验值明细
      tableData: []
    };
  },
  mounted() {
    document.title = this.$route.meta.title;
    this.getData();
  },
  methods: {
    tableHead() {
      return "background-color: #f2f2f2;text-align:center;color:#9c2526";
    },

    // 获取列表
    async getData() {
      const { data: res } = await this.$http({
        url: "/memberInfo/getMemberDetail",
        method: "post",
        data: {
          code: "",
          tabIndex: this.tabList[this.currentTab].value,
          page: this.currentPage,
          pageRow: this.pageSize
        }
      });
      if (res.code !== 200) return this.$message.error(res.msg);
      this.tableData = res.data.pageList;
      this.total = res.data.count;
    },
    // 选择tab
    handleTabClick(index) {
      this.currentTab = index;
      this.currentPage = 1;
      this.getData();
    },
    // 选择分页每页显示条数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getData();
    },
    // 选择每一页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-input {
  width: 280px;
  margin-right: 15px;
}

.noticeBox {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  // margin-top: .5208vw;
  width: 1156px;

  .searchBox {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;

    .searchBoxlf {
    }
  }

  .tabBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f4f8fb;

    .tabTileLeft {
      padding: 0 0 20px 0;

      .tabTileLeftitem {
        position: relative;
        cursor: pointer;
        font-size: 18px;
        color: #999999;
        padding: 0 0 8px 0;
        margin-right: 60px;
        display: inline-block;
        height: 28px;
        line-height: 28px;
        border: 2px solid transparent;

        > span {
          display: inline-block;
          font-weight: 500;
          font-size: 18px;
          position: relative;
        }

        .waiting-num {
          // position: absolute;
          display: inline-block;
          padding: 0 4px;
          min-width: 30px;
          height: 16px;
          font-size: 12px;
          color: #fff;
          line-height: 17px;
          margin-left: 6px;
          // right: 0vw;
          // top: 50%;
          // transform: translate(100%, -50%);
          background: #f03838;
          border-radius: 8px;
          text-align: center;
        }
      }

      .active {
        border-bottom: 2px solid @my-color;
        transition: ease-in 0.2s;
        color: @my-color;
      }
    }

    .lookmore {
      color: #999999;
      padding-right: 10px;
      cursor: pointer;
    }
  }

  .tabContent {
    margin-top: 22px;

    /deep/table {
      // margin-top: 1.0417vw;

      thead tr th,
      tr td {
        text-align: center;
        cursor: pointer;
      }

      // tr td:nth-child(2) {
      //   text-align: left;
      // }
    }

    .empty {
      margin-top: 65px;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        width: 232px;
        height: 114px;
      }

      span {
        color: #999;
        font-size: 14px;
      }
    }
  }
}

/deep/.el-table .cell.el-tooltip {
  white-space: nowrap;
  min-width: 100% !important;
  /* text-align: center; */
}

/deep/.el-table__header,
/deep/.el-table__body {
  width: 100% !important;
}

/deep/.el-table__row:hover {
  color: #9c2526;
}
</style>
