<template>
  <div>
    <!-- <div class="header">
      <signagureHead></signagureHead>
    </div> -->
    <div class="content">
      <div class="title">作品使用同意函<span></span></div>
      <div class="text">
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本人特此声明，同意中国人民大学书报资料中心（以下简称“中心”）遴选本人的文献，并将本人被遴选通过的文献全文放入“学术世界”及其子平台中予以传播。本人此处授予中心对该作品的权利包括但不限于：
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（1）汇编权；<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（2）复制权；<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（3）发行权；<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（4）信息网络传播权；<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（5）以其他传播方式传播该作品的权利，涵盖所有当前已知和未来可能出现的传播形式，例如通过光盘、磁盘等物理介质传播，或通过新兴技术如虚拟现实、增强现实等方式传播；<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（6）版式设计专用权；<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（7）翻译权；<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（8）改编权。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本人确认：拥有对上述文献进行此类授权的完整权利，并保证该作品内容和提供的授权不侵犯任何第三方的合法权益。中心有权在作品的著作权保护期内，在全世界范围内以任何方式及语言免费使用前述许可的权利。此外，中心有权将上述权利的部分或全部转授权给第三方使用。<br />
        </p>
      </div>
      <!-- <div class="date">{{ year }}年{{ month }}月{{ day }}日</div> -->
      <!-- <el-checkbox v-model="notification">此授权函的签署表明本人完全理解并同意上述所有条款。</el-checkbox> -->
    </div>
    <!-- <div class='bottomBtn'> -->
    <!-- <el-button type="primary" @click='Sign'>立即签署</el-button> -->
    <!-- <el-button type="primary" @click='$router.go(-1)'>返回</el-button> -->
    <!-- </div> -->

  </div>
</template>
<script>
// import signagureHead from '@/components/head/signagureHead'
export default {
  // components: {
  //   signagureHead
  // },
  data () {
    return {
      userCode: this.$store.state.userCodeh5 || localStorage.getItem('userCodeh5'),
      year: '',
      month: '',
      day: '',
      handleSignature: false,
      form: {
        realName: this.$store.state.userNameh5 || localStorage.getItem('userNameh5'),
        realId: '',
        phone: localStorage.getItem('userPhoneh5')
      },
      isCheck: false,
      notification: false,
      agree: false
    }
  },
  mounted () {
    document.title = '作品使用同意函' + this.$route.meta.title
    var today = new Date()
    this.year = today.getFullYear()// 获取年份
    this.month = today.getMonth() + 1// 获取月份
    this.day = today.getDate()// 获取日期
    this.userCode = this.$store.state.userCodeh5 || localStorage.getItem('userCodeh5')
  },
  methods: {
    Sign () {
      if (this.notification) {
        this.handleSignature = true
      } else {
        this.$message.error('请同意条款')
      }
    },
    handlerCheck () {
      if (this.form.realName && this.form.realId && this.agree) {
        this.isCheck = true
      } else {
        this.isCheck = false
      }
    },
    async handleSign () {
      var reg = /^[1-9]\d{5}(19|20)\d{2}(0\d|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])\d{3}(\d|X)$/
      if (!reg.test(this.form.realId)) {
        return this.$message.error('请输入正确的身份证号')
      }
      if (this.agree) {
        this.handleUserIdCard()
        const res = await this.$http({
          url: '/anxinsign/sign',
          method: 'POST',
          data: {
            name: this.form.realName,
            phone: this.form.phone,
            idCard: this.form.realId,
            userCode: this.userCode
          }
        })
        if (res.data.code !== 200) {
          return this.$message({
            type: 'warning',
            message: res.data.msg
          })
        }
        const url = res.data.data
        window.location.replace(url)
      } else {
        this.$message.error('请同意告知协议')
      }
    },
    async handleUserIdCard () {
      const res = await this.$http({
        url: '/claimApplet/saveIdCard',
        method: 'POST',
        data: {
          idcard: this.id,
          userCode: this.userCode
        }
      })
      console.log('保存手机号', res)
    }
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler (data) {
        if (data.realName && data.realId && this.agree) {
          this.isCheck = true
        } else {
          this.isCheck = false
        }
      }
    }
  }
}
</script>
<style lang='less' scoped>
.content {
  // width: 600px;
  margin: 20px auto 0;

  .title {
    // text-align: left;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 550;
    color: #9C2526;
    margin-left: 60px;

    span {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #000000;
    }
  }

  .text {
    margin: 0 auto;
    margin-top: 40px;
    font-size: 16px;
    font-family: PingFang SC;
    box-sizing: border-box;
    // font-weight: bold;
    color: #000000;
    background-color: #fff;
    padding: 40px 37px 0 31px;
    line-height: 30px;
    border-radius: 10px;
    // text-align: center;

    p {
      font-size: 16px;
      line-height: 30px;
      font-family: PingFang SC;
      // font-weight: bold;
      color: #000000;
      // line-height: 24px;
    }
  }

  .date {
    margin-top: 89px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    text-align: right;
  }
}

.el-button {
  width: 460px;
  height: 50px;
  border-radius: 25px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FEFEFE;
}

.check {
  width: 200px;
  height: 40px;
  margin-bottom: 10px;
  background: #F7F7F7;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  border: #F7F7F7 solid 1px;
}

.check:hover {
  background: #F7F7F7;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  border: #F7F7F7 solid 1px;
}

.checksure {
  width: 200px;
  height: 40px;
  margin-bottom: 10px;
  background: #9C2526;
  border-radius: 20px;
  border: #9C2526 solid 1px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FEFEFE;
}

.checksure:hover {
  background: #9C2526;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FEFEFE;
  border: #9C2526 solid 1px;
}

/deep/.el-dialog__title {
  float: left;
}

/deep/.el-dialog {
  width: 460px;
}

.phone {
  // margin-top: 10px;
  display: flex;
  align-items: center;
  width: 400px;
  height: 15px;
  background: #F7F7F7;
  border-radius: 20px;
  overflow: hidden;
  padding: 13px 10px 12px 10px;

  /deep/.el-input__inner {
    border: 0;
    background-color: #f6f6f6;
  }

  img {
    margin-left: 10px;
    width: 15px;
    height: 15px;
  }
}

/deep/.el-checkbox {
  margin-top: 40px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}

.bottomBtn {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .el-button {
    width: 460px;
    height: 50px;
    margin: 0;

  }

  .lookMine {
    margin-top: 20px;
    background-color: #fff;
    color: #9C2526;
  }
}
</style>
