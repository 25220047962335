<template>
  <div class="personalmain">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {}
};
</script>

<style lang="less" scoped>
.personalmain {
  padding: 20px;
  box-sizing: border-box;
}
</style>
