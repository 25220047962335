<template>
  <div v-if='referlist.length > 0 || quotelist.length > 0 || compotationlist.length > 0 || coessentiallist.length > 0'
    class="detailtjBox">
    <div class="detailtjTitle">
      <span class="hengLine"></span>
      <el-divider content-position="center">相关文献推荐</el-divider>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane v-if='referlist.length > 0' name="first">
        <div slot='label' style='font-size: 0.10417rem;' @click='switchTab("first")'>参考文献</div>
        <div id="wordCloud" class="wordCloudBox">
          <div class="firstgroup groupmt">
            <div v-for="(item, index) in referlist" :key="index">
              <!-- <span class="firstitemNum">{{ (pageReferenceData.current - 1) * pageReferenceData.size + index + 1 }}</span> -->
              <span  v-html="item"></span>
            </div>
            <empty :quotelist="referlist"></empty>
          </div>
          <!-- 分页器 -->
          <div v-if='referlist.length > 0' class="elPagination">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pageReferenceData.current" :page-sizes="[10, 20, 30, 40]"
              :page-size="pageReferenceData.size" layout="total, sizes, prev, pager, next, jumper"
              :total="pageReferenceData.total">
            </el-pagination>
          </div>
        </div>

      </el-tab-pane>
      <el-tab-pane v-if='quotelist.length > 0' name="second">
        <div slot='label' style='font-size: 0.10417rem;' @click='switchTab("second")'>引证文献</div>
        <div id="relationship" class="relationship groupmt">
          <div v-for="(item, index) in quotelist" :key="index">
            <span class="firstitemNum">{{ (pageCitationData.current - 1) * pageCitationData.size + index + 1 }}</span>
            <span>{{ item.title }}</span>
          </div>
          <empty :quotelist="quotelist"></empty>
          <div v-if='quotelist.length > 0' class="elPagination">
            <el-pagination background @size-change="handleSizeCitationChange"
              @current-change="handleCurrentCitationChange" :current-page="pageCitationData.current"
              :page-sizes="[10, 20, 30, 40]" :page-size="pageCitationData.size"
              layout="total, sizes, prev, pager, next, jumper" :total="pageCitationData.total">
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if='compotationlist.length > 0' name="third">
        <div slot='label' style='font-size: 0.10417rem;' @click='switchTab("third")'>共引文献</div>
        <div class="thirdBox">
          <div id="hotspots" class="groupmt">
            <div v-for="(item, index) in compotationlist" :key="index">
              <span class="firstitemNum">{{ (pageCommonData.current - 1) * pageCommonData.size + index + 1 }}</span>
              <span>{{ item.title }}</span>
            </div>
            <empty :quotelist="compotationlist"></empty>
          </div>
          <div v-if='compotationlist.length > 0' class="elPagination">
            <el-pagination background @size-change="handleCommonSizeChange" @current-change="handleCommonCurrentChange"
              :current-page="pageCommonData.current" :page-sizes="[10, 20, 30, 40]" :page-size="pageCommonData.size"
              layout="total, sizes, prev, pager, next, jumper" :total="pageCommonData.total">
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if='coessentiallist.length > 0' name="fourth">
        <div slot='label' style='font-size: 0.10417rem;' @click='switchTab("fourth")'>同被引文献</div>
        <div class="authorcooperate groupmt">
          <div v-for="(item, index) in coessentiallist" :key="index">
            <span class="firstitemNum">{{ (pageEquallyData.current - 1) * pageEquallyData.size + index + 1 }}</span>
            <span>{{ item.title }}</span>
          </div>
          <empty :quotelist="coessentiallist"></empty>
          <div v-if='coessentiallist.length > 0' class="elPagination">
            <el-pagination background @size-change="handleEquallySizeChange"
              @current-change="handleEquallyCurrentChange" :current-page="pageEquallyData.current"
              :page-sizes="[10, 20, 30, 40]" :page-size="pageEquallyData.size"
              layout="total, sizes, prev, pager, next, jumper" :total="pageEquallyData.total">
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import empty from '../../components/common/emptyheng.vue'
export default {
  components: {
    empty
  },
  data () {
    return {
      activeName: 'first',
      id: '',
      referlist: [],
      quotelist: [],
      compotationlist: [], // 共饮
      coessentiallist: [], // 同被引
      title: '', // 标题
      // 参考文献
      pageReferenceData: {
        current: 1,
        size: 10,
        total: 0
      },
      // 引证文献
      pageCitationData: {
        current: 1,
        size: 10,
        total: 0
      },
      // 共引文献
      pageCommonData: {
        current: 1,
        size: 10,
        total: 0
      },
      // 同被引文献
      pageEquallyData: {
        current: 1,
        size: 10,
        total: 0
      }

    }
  },

  methods: {
    // 切换页面
    switchTab (data) {
      this.pageReferenceData.current = 1
      this.pageCitationData.current = 1
      this.pageEquallyData.current = 1
      this.pageCommonData.current = 1
      if (data === 'first') {
        this.getreferlist()
      } else if (data === 'second') {
        this.getquotelist()
      } else if (data === 'third') {
        this.getcompotationlist()
      } else if (data === 'fourth') {
        this.getcoessential()
      }
    },
    // 参考文献
    handleCurrentChange (val) {
      this.pageReferenceData.current = val
      this.getreferlist()
    },
    handleSizeChange (val) {
      this.pageReferenceData.current = 1
      this.pageReferenceData.size = val
      this.getreferlist()
    },
    // 引证文献
    handleSizeCitationChange (val) {
      this.pageCitationData.current = 1
      this.pageCitationData.size = val
      this.getquotelist()
    },
    handleCurrentCitationChange (val) {
      this.pageCitationData.current = val
      this.getquotelist()
    },
    // 共引文献
    handleCommonSizeChange (val) {
      this.pageCommonData.current = 1
      this.pageCommonData.size = val
      this.getcompotationlist()
    },
    handleCommonCurrentChange (val) {
      this.pageCommonData.current = val
      this.getcompotationlist()
    },
    // 同被引用文献
    handleEquallySizeChange (val) {
      this.pageEquallyData.current = 1
      this.pageEquallyData.size = val
      this.getcoessential()
    },
    handleEquallyCurrentChange (val) {
      this.pageEquallyData.current = val
      this.getcoessential()
    },
    // 分页器修改list列表
    pagination (list, current, size) {
      // 取(current-1)*size 到(current-1)*size + size -1

    },
    // 获取参考文献数据
    async getreferlist () {
      const {
        data: res
      } = await this.$http({
        url: '/detail/reference',
        method: 'POST',
        data: {
          id: this.id,
          title: this.title,
          page: this.pageReferenceData.current,
          pageRow: this.pageReferenceData.size
        }
      })

      if (res.code !== 200) return this.$message.error(res.msg)
      // 获取标签
      //
      this.referlist = res.data.pageList || []
      // if (this.referlist.length === 0) {
      //   this.activeName = 'second'
      // }
      this.pageReferenceData.total = res.data.count
    },
    // 引证文献
    async getquotelist () {
      const {
        data: res
      } = await this.$http({
        url: '/detail/cite',
        method: 'POST',
        data: {
          id: this.id,
          title: this.title,
          page: this.pageCitationData.current,
          pageRow: this.pageCitationData.size
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.quotelist = res.data.pageList || []
      this.pageCitationData.total = res.data.count
    },
    // 共引文献
    async getcompotationlist () {
      const {
        data: res
      } = await this.$http({
        url: '/detail/getCoCited',
        method: 'POST',
        data: {
          id: this.id,
          title: this.title,
          page: this.pageCommonData.current,
          pageRow: this.pageCommonData.size
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.compotationlist = res.data.pageList || []
      this.pageCommonData.total = res.data.count
    },
    // 同被引用文献
    async getcoessential () {
      const {
        data: res
      } = await this.$http({
        url: '/detail/getCoByCited',
        method: 'POST',
        data: {
          id: this.id,
          title: this.title,
          page: this.pageEquallyData.current,
          pageRow: this.pageEquallyData.size
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.coessentiallist = res.data.pageList
      this.pageEquallyData.total = res.data.count
    }
  },
  mounted () {
    this.title = this.$store.state.detailtitle
    this.id = this.$route.query.id
    if (this.title) {
      this.getreferlist()
      this.getquotelist()
      this.getcompotationlist()
      this.getcoessential()
    }
    if (this.referlist.length === 0) {
      this.activeName = 'second'
    }
    if (this.quotelist.length === 0) {
      this.activeName = 'third'
    }
    if (this.compotationlist.length === 0) {
      this.activeName = 'fourth'
    }
    if (this.coessentiallist.length === 0) {
      this.activeName = 'first'
    }
  }
}

</script>
<style lang="less" scoped>
.detailtjBox {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 54px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;

  /deep/.el-tabs__nav {
    padding-bottom: 20px;
  }

  /deep/.el-tabs__item {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }

  /deep/.el-tabs__item.is-active {
    color: @my-color;
  }

  .detailtjTitle {
    /deep/.el-divider__text {
      font-size: 24px;
      color: #000000;
    }

    /deep/.el-divider__text::before {
      content: "";
      display: inline-block;
      width: 40px;
      height: 3px;
      background: #9c2526;
      border-radius: 1px;
      vertical-align: middle;
      transform: translate(-34px, -2px);
    }

    /deep/.el-divider__text::after {
      content: "";
      display: inline-block;
      width: 40px;
      height: 3px;
      background: #9c2526;
      border-radius: 1px;
      vertical-align: middle;
      transform: translate(34px, -2px);
    }
  }

  .groupmt {
    >div {
      margin-bottom: 10px;
      color: #333;
    }
  }

  .firstgroup {
    // margin: 80px 0;
    // text-align: center;
  }

  .firstitemNum {
    // padding-right: 10px;
  }

  .firstitemNum::before {
    content: "[";
    display: inline-block;
    font-size: 16px;
    color: #333333;
  }

  .firstitemNum::after {
    content: "]";
    display: inline-block;
    font-size: 16px;
    color: #333333;
  }

  .wordCloudBox {
    .elPagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
    }
  }
}
</style>
