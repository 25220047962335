<template>
  <div class="noticeBox">
    <div class="tabContent">
      <el-table :header-cell-style="tableHead" :data="tableData" style="width: 100%"  v-if="total > 0">
        <el-table-column label="序号" width="50">
          <template #default="scope">{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column show-overflow-tooltip width="200" align="center" prop="outTradeNo" label="订单编号"></el-table-column>
        <el-table-column show-overflow-tooltip width="200" align="center" prop="name" label="特色选题"></el-table-column>
        <el-table-column show-overflow-tooltip width="200" align="center" prop="periods" label="期数"></el-table-column>
        <el-table-column align="center" prop="fixedPriceValue" label="定价（元)"></el-table-column>
        <el-table-column align="center" prop="payerTotalValue" label="实付金额（元）"></el-table-column>
        <el-table-column  align="center" prop="address" label="收件地址"></el-table-column>
        <el-table-column  width="200" align="center" prop="createTime" label="下单时间"></el-table-column>
      </el-table>
      <div class="empty" v-else>
        <img src="../../assets/images/home/nodataside.png" alt />
        <span>暂无数据</span>
      </div>
      <el-pagination
        v-if="total > 0"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      currentTab: 0,
      tabList: [
        {
          label: "积分明细",
          value: "0"
        },
        {
          label: "经验值明细",
          value: "1"
        }
      ], //tab切换 0-积分明细 1-经验值明细
      tableData: []
    };
  },
  mounted() {
    document.title = this.$route.meta.title;
    this.getData();
  },
  methods: {
    tableHead() {
      return "background-color: #f2f2f2;text-align:center;color:#9c2526";
    },

    // 获取列表
    async getData() {
      const { data: res } = await this.$http({
        url: "/memberInfo/mineOrderList",
        method: "post",
        data: {
          loginCode: "", //前端不用传
          offset: "",
          page: this.currentPage,
          pageRow: this.pageSize
        }
      });
      if (res.code !== 200) return this.$message.error(res.msg);
      this.tableData = res.data.pageList;
      this.total = res.data.count;
    },
    // 选择tab
    // 选择分页每页显示条数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getData();
    },
    // 选择每一页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-input {
  width: 280px;
  margin-right: 15px;
}

.noticeBox {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  // margin-top: .5208vw;
  width: 1156px;

  .searchBox {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;

    .searchBoxlf {
    }
  }

  .tabContent {
    margin-top: 22px;

    /deep/table {
      // margin-top: 1.0417vw;

      thead tr th,
      tr td {
        text-align: center;
        cursor: pointer;
      }

      tr td:nth-child(2) {
        text-align: left;
      }
    }

    .empty {
      margin-top: 65px;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        width: 232px;
        height: 114px;
      }

      span {
        color: #999;
        font-size: 14px;
      }
    }
  }
}

/deep/.el-table .cell.el-tooltip {
  white-space: nowrap;
  min-width: 100% !important;
  /* text-align: center; */
}

/deep/.el-table__header,
/deep/.el-table__body {
  width: 100% !important;
}

/deep/.el-table__row:hover {
  color: #9c2526;
}
</style>
