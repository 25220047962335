<template>
  <div class="headlogin">
    <headlogin></headlogin>

  </div>
</template>

<script>
import headlogin from '../head/headlogin'

export default {
  components: {
    headlogin

  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style scoped>
.headlogin {
  /* margin-bottom: 20px; */
  position: sticky;
  top: 0;
  z-index: 1000;
}
</style>
