<template>
  <div>
    <!-- <div class="header">
      <signagureHead></signagureHead>
    </div> -->
    <div class="content" style='position: relative;'>

      <div class="title">个人信息使用声明</div>
      <div class="text">
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请您知悉，我们将与您通过电子签约的方式完成本次授权书的签署。根据《电子签名法》等相关法律法规的规定，使用电子签服务需要完成实名认证。为向您提供电子签服务，我们需收集您的<span>姓名、手机号和身份证号</span>，并传输给我们合作的实名认证机构（中金金融认证中心有限公司及其关联公司）以向其核实您的信息并核发专属的电子签名文件。如您不同意我们处理您的前述个人信息，您可直接退出本页面。如您主动填写姓名、身份证号信息并点击“确认”将视为您已同意我们收集您的前述个人信息并提供给我们合作的实名认证机构。<span>身份证号是敏感个人信息，一旦泄露或处理不当可能会危害您的人身、财产安全，我们将按照法律法规要求并参照行业最佳实践为您的个人信息安全提供保障</span>。
        </p>
      </div>
      <!-- <div class="goback" style='text-align: center;'>
        <el-button type='primary' @click='$router.go(-1)' style='margin-top: 10px;'>确认</el-button>
      </div> -->
    </div>
  </div>
</template>
<script>
// import signagureHead from '@/components/head/signagureHead'
export default {
  // components: {
  //   signagureHead
  // },
  data() {
    return {

    }
  },
  mounted() {
    document.title = '个人信息使用声明' + this.$route.meta.title
  },
  methods: {

  },
  watch: {

  }
}
</script>
<style lang='less' scoped>
.content {
  width: 600px;
  margin: 0 auto;

  .title {
    text-align: center;
    // margin-top: 80px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;

    span {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #000000;
    }
  }

  .text {
    margin-top: 40px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;

    p {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 24px;

      span {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
        line-height: 24px;
      }
    }
  }
}

.goback {
  margin-top: 70px;
  .el-button {
    width: 460px;
    height: 50px;
    margin: 0;
    border-radius: 25px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FEFEFE;
  }
}
</style>
