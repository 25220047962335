<template>
  <div class="discontainer">
    <div class="title">
      <img src="../../assets/images/head/originalList.png" class="titleimg1" alt />
      <div class="more" @click="handlexs1">
        <div class="check">查看更多</div>
        <img src="../../assets/images/head/more.png" class="moreimg" />
      </div>
    </div>
    <div class="dismain">
      <div class="swiper-container" ref="bannerContain" v-if="tenbannerList.length > 6">
        <div class="swiper-wrapper">
          <div class="swiper-slide" :data-no='item.id' v-for="item in tenbannerList" :key="item.id">
            <!-- <div class="swiper-slide" v-for="item in tenbannerList" :key="item.id"> -->
            <div class="imgbox" :data-no='item.id'>
              <img :data-no='item.id' class="swimg" :src="item.cover" alt="">
            </div>
            <div :data-no='item.id' class="tenbox">
              <div :data-no='item.id' v-if="item.hot === 1" class="hot">HOT</div>
              <div :data-no='item.id' v-if="item.recommend === 1" class="recommend">推荐</div>
            </div>
            <div class="slname" :data-no='item.id' :title='item.name'>{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div style="display: flex;" v-else>
        <div class="disbox" :data-no='item.id' v-for="item in tenbannerList" :key="item.id"
          @click="handlegoten(item.id)">
          <div class="imgbox" :data-no='item.id'>
            <img :data-no='item.id' class="swimg" :src="item.cover" alt="">
          </div>
          <div :data-no='item.id' class="tenbox">
            <div :data-no='item.id' v-if="item.hot === 1" class="hot">HOT</div>
            <div :data-no='item.id' v-if="item.recommend === 1" class="recommend">推荐</div>
          </div>
          <div class="slname" :data-no='item.id' :title='item.name'>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="title" style="margin-top: 20px;">
      <img src="../../assets/images/head/original.png" class="titleimg1" alt />
      <div class="more" @click="handlexs2">
        <div class="check">查看更多</div>
        <img src="../../assets/images/head/more.png" class="moreimg" />
      </div>
    </div>
    <div class="dismain">
      <div class="swiper-container" ref="originalBanner" v-if="originalList.length > 6">
        <div class="swiper-wrapper">
          <div class="swiper-slide" :data-no='item.id' v-for="item in originalList" :key="item.id">
            <div class="imgbox" :data-no='item.id'>
              <img class="swimg" :data-no='item.id' :src="item.filePrefix + item.conceptCoverUrl" alt="">
            </div>
            <div class="slname" :data-no='item.id' :title='item.conceptName'>{{ item.conceptName }}</div>
          </div>
        </div>
      </div>
      <div style="display: flex;" v-else>
        <div class="disbox" :data-no='item.id' v-for="item in originalList" :key="item.id"
          @click="handlegoorig(item.id)">
          <div class="imgbox" :data-no='item.id'>
            <img class="swimg" :data-no='item.id' :src="item.filePrefix + item.conceptCoverUrl" alt="">
          </div>
          <div class="slname" :data-no='item.id' :title='item.conceptName'>{{ item.conceptName }}</div>
        </div>
      </div>
    </div>
    <div class="title" style="margin-top: 21px;">
      <img src="../../assets/images/head/bannerList.png" class="titleimg2" alt />
      <div class="more" @click="handlexs">
        <div class="check">查看更多</div>
        <img src="../../assets/images/head/more.png" class="moreimg" />
      </div>
    </div>
    <div class="dismain ">
      <div class="swiper-container" ref="bannerList" v-if="bannerList.length > 6">
        <div class="swiper-wrapper">
          <div class="swiper-slide" :data-no='item.id' v-for="item in bannerList" :key="item.id">
            <div class="imgbox" :data-no='item.id'>
              <img class="swimg" :data-no='item.id' :src="item.filePrefix + item.conceptCoverUrl" alt="">
            </div>
            <div class="slname" :data-no='item.id' :title='item.conceptName'>{{ item.conceptName }}</div>
          </div>
        </div>
      </div>
      <div style='display: flex;' v-else>
        <div class="disbox" @click="handlego(item.id)" v-for="item in bannerList" :key="item.id">
          <div class="imgbox" :data-no='item.id'>
            <img class="swimg" :src="item.cover" alt="">
          </div>
          <div class="count">主文献量：{{ item.count }}</div>
          <div class="slname" :title='item.name'>{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  components: {},
  data () {
    return {
      bannerList: [],
      tenbannerList: [],
      originalList: []
    }
  },
  mounted () {
    this.getdisciplinelist()
    this.gettensciencelist()
    this.getoriginalList()
  },
  methods: {
    handlexs2 () {
      const routeData = this.$router.resolve({
        path: '/originalList'
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    handlexs1 () {
      const routeData = this.$router.resolve({
        path: '/tensciencelist'
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    handlexs () {
      const routeData = this.$router.resolve({
        path: '/disciplineList'
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    async getdisciplinelist () {
      const { data: res } = await this.$http({
        url: '/topicList/getBanner',
        method: 'get'
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      const that = this
      this.bannerList = res.data
      if (res.data) {
        if (this.bannerList.length >= 6) {
          this.$nextTick(() => {
            var mySwiper = new Swiper(this.$refs.bannerList, {
              loop: true,
              slidesPerView: 6,
              // slidesPerView: 'auto',
              spaceBetween: '0.55%',
              loopedSlides: 7,
              on: {
                click: function (e) {
                  // console.log(e.target.dataset.no)
                  if (e.target.dataset.no) {
                    const routeData = that.$router.resolve({
                      path: '/tenscienceInfo?id=' + e.target.dataset.no
                      // query: params
                    })
                    window.open(routeData.href, '_blank')
                  }
                }
              },
              autoplay: {
                delay: 3000,
                disableOnInteraction: false // 解决滑动后不能轮播的问题
              }
            })
            // 监听鼠标悬停事件
            // mySwiper.el.onmouseover = function () {
            //   mySwiper.autoplay.stop()
            // }

            // 监听鼠标离开事件
            // mySwiper.el.onmouseleave = function () {
            //   mySwiper.autoplay.start()
            // }
          })
        }
      }
    },
    async gettensciencelist () {
      const { data: res } = await this.$http({
        url: '/academic/getIndexAcademicYearList',
        method: 'get'
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      const that = this
      this.tenbannerList = res.data
      if (res.data) {
        if (this.tenbannerList.length >= 6) {
          this.$nextTick(() => {
            var mySwiper = new Swiper(this.$refs.bannerContain, {
              loop: true,
              slidesPerView: 6,
              // slidesPerView: 'auto',
              spaceBetween: '0.55%',
              loopedSlides: 7,
              on: {
                click: function (e) {
                  // console.log(e.target.dataset.no)
                  if (e.target.dataset.no) {
                    const routeData = that.$router.resolve({
                      path: '/tenscienceInfo?id=' + e.target.dataset.no
                      // query: params
                    })
                    window.open(routeData.href, '_blank')
                  }
                }
              },
              autoplay: {
                delay: 3000,
                disableOnInteraction: false // 解决滑动后不能轮播的问题
              }
            })
            // 监听鼠标悬停事件
            // mySwiper.el.onmouseover = function () {
            //   mySwiper.autoplay.stop()
            // }

            // 监听鼠标离开事件
            // mySwiper.el.onmouseleave = function () {
            //   mySwiper.autoplay.start()
            // }
          })
        }
      }
    },
    async getoriginalList () {
      const { data: res } = await this.$http({
        url: '/cn_academic_concept/top',
        method: 'get'
      })
      const that = this
      if (res.code != 200) return this.$message.error(res.msg)
      this.originalList = res.data.rows
      if (this.originalList) {
        if (this.originalList.length > 6) {
          this.$nextTick(() => {
            var mySwiper = new Swiper(this.$refs.originalBanner, {
              loop: true,
              slidesPerView: 6,
              // slidesPerView: 'auto',
              spaceBetween: '0.55%',
              loopedSlides: 7,
              on: {
                click: function (e) {
                  if (e.target.dataset.no) {
                    const routeData = that.$router.resolve({
                      path: '/originalDetail?id=' + e.target.dataset.no
                      // query: params
                    })
                    window.open(routeData.href, '_blank')
                  }
                }
              },
              autoplay: {
                delay: 3000,
                disableOnInteraction: false // 解决滑动后不能轮播的问题
              }
            })
            // 监听鼠标悬停事件
            // mySwiper.el.onmouseover = function () {
            //   mySwiper.autoplay.stop()
            // }

            // 监听鼠标离开事件
            // mySwiper.el.onmouseleave = function () {
            //   mySwiper.autoplay.start()
            // }
          })
        }
      }
    },
    handlego (id) {
      const routeData = this.$router.resolve({
        path: '/disciplinedetail?id=' + id
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    handlegoten (id) {
      const routeData = this.$router.resolve({
        path: '/tenscienceInfo?id=' + id
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    handlegoorig (id) {
      const routeData = this.$router.resolve({
        path: '/originalDetail?id=' + id
        // query: params
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  margin: 0 auto;
  display: flex;
  width: 1440px;

  .titleimg1 {
    width: 180px;
    height: 18px;
  }

  .titleimg2 {
    width: 75px;
    height: 19px;
  }

  .more {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    cursor: pointer;

    .check {
      margin-right: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #9C2526;
    }

    .moreimg {
      width: 10px;
      height: 10px;
    }
  }
}

.dismain {
  width: 1440px;
  margin: 0 auto;
  margin-top: 19px;
  // margin-left: 237px;
  // margin-right: 230px;
  display: flex;
  cursor: pointer;

  .swiper-container {
    width: 1440px;
    height: 174px;

    .swiper-slide {
      box-sizing: border-box;
      background: #fff;
      overflow: hidden;
      border-radius: 4px;
      width: 232px;
      height: 174px;
      margin-top: 10px;
      position: relative;
      bottom: 10px;
      padding: 20px 10px 0 10px;
      cursor: pointer;

      .imgbox {
        height: 110px;
        width: 212px;
        border-radius: 4px;
        overflow: hidden;

        .swimg {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: cover;
        }
      }

      .tenbox {
        position: absolute;
        right: 20px;
        top: 20px;
        display: flex;

        .hot {
          height: 20px;
          background: #FF0000;
          border-radius: 4px;
          line-height: 20px;
          padding-left: 6px;
          padding-right: 6px;
          font-size: 12px;
          font-weight: 400;
          color: #FFFFFF;
        }

        .recommend {
          margin-left: 10px;
          height: 20px;
          background: #FF7100;
          border-radius: 4px;
          line-height: 20px;
          padding-left: 6px;
          padding-right: 6px;
          font-size: 12px;
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .slname {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }
    }

  }

  .disbox {
    // background-color: #FF7100;
    // flex: 1;
    padding-bottom: 5px;
    margin-right: 10px;
    background: #fff;
    border-radius: 4px;
    width: 232px;
    height: 154px;
    position: relative;

    .imgbox {
      height: 110px;

      .swimg {
        margin-left: 10px;
        margin-top: 10px;
        width: 212px;
        height: 110px;
        border-radius: 4px;
      }
    }

    .count {
      padding-left: 5px;
      padding-right: 5px;
      position: absolute;
      right: 15px;
      top: 20px;
      height: 20px;
      line-height: 20px;
      background: #FF7100;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
    }

    .slname {
      margin-top: 15px;
      margin-left: 11px;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    .tenbox {
      position: absolute;
      right: 20px;
      top: 20px;
      display: flex;

      .hot {
        height: 20px;
        background: #FF0000;
        border-radius: 4px;
        line-height: 20px;
        padding-left: 6px;
        padding-right: 6px;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
      }

      .recommend {
        margin-left: 10px;
        height: 20px;
        background: #FF7100;
        border-radius: 4px;
        line-height: 20px;
        padding-left: 6px;
        padding-right: 6px;
        font-size: 12px;
        color: #FFFFFF;
      }
    }

    .word {
      font-size: 14px;
      margin-left: 5px;
      font-weight: 400;
      color: #666666;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 16px;
      white-space: nowrap;
    }

    .totaltag {
      margin-left: 5px;
      margin-top: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .subtag {
        padding: 4px 7px 4px 9px;
        background: #F7F7F7;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #9C2526;
        margin-left: 10px;
      }

      .subtag:first-child {
        margin-left: 0px;
      }
    }

  }
}

@media screen and (min-width: 1600px) {
  .subject {
    width: 1401.6px;
    display: block;
    margin: 19.2px auto;
    cursor: pointer;
  }

  .discontainer {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 25px;
    margin: 0 auto;
    height: auto;
    background: #F7F7F7;
    width: 100%;

  }

  // aside样式
}

// 1300~1600

@media screen and (max-width: 1600px) and (min-width: 1300px) {
  .subject {
    width: 1401.6px;
    display: block;
    margin: 19.2px auto;
    cursor: pointer;
  }

  .discontainer {
    margin-top: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 auto;
    height: auto;
    background: #F7F7F7;

  }

}

// 小于1300
@media screen and (max-width: 1300px) {

  .subject {
    width: 1401.6px;
    display: block;
    margin: 19.2px auto;
    cursor: pointer;
  }

  .discontainer {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 25px;
    margin: 0 auto;
    background: #F7F7F7;
    height: auto;

  }

  // aside样式
}
</style>
