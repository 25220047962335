<template>
  <div class="lefttreeBox">
    <div class="lefttreeBoxtop">
      <div class="discipline">
        <p class="disciplineTitle">年份检索</p>
      </div>
      <div style="height:100%;width:100%;overflow:scroll">
        <el-tree class="treeBox" :data="treedata" show-checkbox node-key="id" ref="tree" highlight-current
          :props="defaultProps" @check-change="handleCheckChange">
          <span class="custom-tree-node" slot-scope="{node,data}">
            <span>{{ data.label }}</span>
            <span class="treecount">({{ data.count }}篇)</span>
          </span>
        </el-tree>
      </div>
    </div>

    <!-- 遴选指标 -->
  </div>
</template>

<script>

// @ is an alias to /src

export default {
  props: ['yearname'],
  data () {
    return {
      content: '',
      startYear: '',
      endYear: '',
      secondselecteryear: [], // 左侧选中的一级学科code列表
      firstselectedyear: [], // 左侧选中的二级学科code列表
      // hotwordsearch: "", //热搜词
      // orgCode: "", //机构code
      // page: 1, //页码
      // pageRow: 10, //每页显示的条目数
      // total: 0,
      // period: "", //期刊期次
      // pubYear: [],
      // resultContent: "", //结果搜索内容
      // searchContent: "", //检索内容
      // tabIndex: 0, //tab切换 0-发表年份 1-被引频次 2-分享量 3-评论量 4-收藏量 5-下载量 6-点击量
      // type: 0, //0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅
      name: '',
      selectedKeys: [],
      treedata: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  mounted () {
    // this.getDatatree();
  },
  methods: {
    handleCheckChange (data, checked) {
      // 选中值包含父级
      const includeparent = this.$refs.tree.getCheckedNodes(false, true)
      // 选中值不包含父元素==全部选中二级
      const nocludeparent = this.$refs.tree.getCheckedNodes(true)
      // 需要将所有选中的二级值的id放到数组中
      this.secondselecteryear = nocludeparent.map(item => item.id)
      // 存值
      this.firstselectedyear = nocludeparent.map(item => {
        return item.id
      })

      // 选中值只有父元素
      const parentArrar = includeparent.filter(
        obj => !nocludeparent.includes(obj)
      )
      this.firstselectedyear = parentArrar.map(item => {
        return item.id
      })
      // // 存值
      this.$store.commit('secondyear', this.secondselecteryear)
      this.$store.commit('firstyear', this.firstselectedyear)
    },
    // 获取详情接口
    async getDatatree () {
      const { data: res } = await this.$http({
        url: '/periodical/getPeriodYearList',
        method: 'post',
        data: {
          content: this.content,
          startYear: this.startYear,
          endYear: this.endYear,
          name: this.yearname
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.treedata = res.data
    }
  },
  watch: {
    // 监听热词
    // "$store.state.hotwordsearch": {
    //   deep: true,
    //   // immediate: true,
    //   handler(data) {
    //     // console.log("1221", data);
    //     this.hotwordsearch = data;
    //     this.getDatatree();
    //   }
    // },
    '$store.state.secondselectedyear': {
      deep: true,
      // immediate: true,
      handler (data) {
        this.secondselecteryear = data
      }
    },
    '$store.state.firstselectyear': {
      deep: true,
      handler (data) {
        this.firstselectedyear = data
      }
    },
    '$store.state.mainsearch': {
      deep: true,
      // immediate: true, // 首次
      handler (data) {
        // this.pubYear = data.pubYear
        this.content = data.resultContent
        this.startYear = data.startTime
        this.endYear = data.endTime
        this.getDatatree()
      }
    },
    // // 监听多个年和搜索结果
    // "$store.state.mainsearch": {
    //   deep: true,
    //   // immediate: true,//首次
    //   handler(data) {
    //     this.pubYear = data.pubYear;
    //     this.resultContent = data.resultContent;
    //   }
    // }
    yearname: {
      deep: true,
      handler () {
        this.name = this.yearname
        this.getDatatree()
      }
    }
  }
}
</script>
<style scoped lang="less">
.el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-scrollbar__bar.is-vertical {
  opacity: 0.6;
  padding-right: 5px;
  background: #e6e6e6;
  width: 6px;
}

.lefttreeBox {
  .lefttreeBoxtop {
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;

    .discipline {
      display: flex;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
      width: 100%;
      height: 60px;
      background: #f5f5f5;
      border-radius: 10px 10px 0 0;

      @media screen and (min-width: 1600px) {
        .disciplineTitle {
          font-size: 22px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // 1300~1600

      @media screen and (max-width: 1600px) and (min-width: 1300px) {
        .disciplineTitle {
          font-size: 20px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // 小于1300
      @media screen and (max-width: 1300px) {
        .disciplineTitle {
          font-size: 18px;
          font-weight: bold;
          color: #9c2526;
        }
      }

      // .selectAllBox {
      //   .selectAllBtn,
      //   .selectemptyBtn {
      //     text-align: center;
      //     width: 48px;
      //     height: 24px;
      //     background: #f5f5f5;
      //     border: 1px solid #e0e0e0;
      //     border-radius: 4px;
      //     padding: 0;
      //     > span {
      //       color: #666666;
      //     }
      //   }
      //   .selectemptyBtn {
      //   }
      // }
    }

    /deep/.el-tree {
      width: 100%;
      min-height: 684px;
      background: #ffffff;
      border-radius: 0 0 10px 10px;
      padding: 18px 20px;
      box-sizing: border-box;
    }

    .treecount {
      margin-left: 10px;
    }
  }

  .selectzb {
    cursor: pointer;
    text-align: center;
    width: 100px;
    height: 40px;
    line-height: 40px;
    background: #9c2526;
    border-radius: 4px;

    .selectzbIcon {
      display: inline-block;
      // background: url("../../assets/images/home/");
    }

    .selectzbTxt {
      font-size: 14px;
      color: #ffffff;
    }
  }
}
</style>
