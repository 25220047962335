<template>
  <div class="authorSide">
    <div class="authorSideTop">
      <div class="authorSideTopTitle">主要合作者</div>
      <div v-show="authorData.length > 0" class="authorSideTopchartBox" id="authorSideTopchartBox" ref="cooperate">
      </div>
      <div v-show="authorData.length == 0" class="noneAuthor" ref="noneAuthor">
        <img src="../../assets/images/home/nodataside.png" alt />
        <span>暂无数据</span>
      </div>
    </div>
    <div class="authorSidebottom">
      <!-- <div class="authorSidebottomTitle">同机构作者</div> -->
      <authorsidelist :authorsidedata="authorsidedata"></authorsidelist>
    </div>
    <div class="selectedjournal" style="margin-bottom: 20px;">
      <journalsidelist :journalsidelistData="journalsidelistData"></journalsidelist>
    </div>
    <!-- 同机构入选期刊 -->
  </div>
</template>

<script>
// @ is an alias to /src
import imgSrc from '../../assets/images/head/Personalimg.png'
// 同机构作者
import authorsidelist from '../author/authorsidelsit'
import journalsidelist from '../../components/journal/journalsidelist'

export default {
  components: { authorsidelist, journalsidelist },
  props: ['acList'],
  data () {
    return {
      authorsidedata: {
        url: '',
        orgCode: '',
        authorCode: '',
        type: 'author',
        acList: '',
        name: '同机构作者'
      },
      journalsidelistData: {
        url: '/organization/selectedJournal',
        orgCode: '',
        name: '相关期刊'
      },
      circleUrl: '../../assets/images/head/author.png',
      chartData: [],
      dataLink: [],
      id: '',
      journalTotal: '',
      journalList: [], // 机构列表

      chartLinks: [], // 合作者
      authorData: [] // 合作者
    }
  },
  mounted () {
    this.id = this.$route.query.id
    // 获取列表机机构名称
    if (this.$route.query.componyName) {
      this.authorsidedata.orgCode = this.$route.query.componyName
    } else {
      this.authorsidedata.orgCode = this.$route.query.name
      this.journalsidelistData.orgCode = this.$route.query.name
    }
    if (this.$route.query.authorCode) {
      this.authorsidedata.authorCode = this.$route.query.authorCode
      this.authorsidedata.type = 'author'
    }
    this.gethotspots()
    // this.getjournalData();
  },
  methods: {
    getImgData (imgSrc) {
      var fun = function (resolve) {
        const canvas = document.createElement('canvas')
        const contex = canvas.getContext('2d')
        const img = new Image()
        img.crossOrigin = ''

        img.onload = function () {
          // 设置图形宽高比例
          var center = {
            x: img.width / 2,
            y: img.height / 2
          }
          var diameter = img.width // 半径
          canvas.width = diameter
          canvas.height = diameter
          contex.clearRect(0, 0, diameter, diameter)
          contex.save()
          contex.beginPath()
          var radius = img.width / 2
          contex.arc(radius, radius, radius, 0, 2 * Math.PI) // 画出圆
          contex.clip() // 裁剪上面的圆形
          contex.drawImage(
            img,
            center.x - radius,
            center.y - radius,
            diameter,
            diameter,
            0,
            0,
            diameter,
            diameter
          ) // 在刚刚裁剪的园上画图
          contex.restore() // 还原状态
          resolve(canvas.toDataURL('image/png', 1))
        }
        img.src = imgSrc
      }

      var promise = new Promise(fun)

      return promise
    },

    pubdata (json, links) {
      var that = this
      var myChart = this.$echarts.init(this.$refs.cooperate)
      var androidMap = json
      var picList = [] // 获取出全部图片
      for (var i = 0; i < androidMap.length; i++) {
        // 把图片路径转成canvas
        const p = this.getImgData(androidMap[i].symbol)
        picList.push(p)
      }
      Promise.all(picList).then(function (images) {
        // 取出base64 图片 然后赋值到jsondata中
        for (var i = 0; i < images.length; i++) {
          var img = 'image://' + images[i]

          androidMap[i].symbol = img
        }
        // 把数据设置到Echart中data
        myChart.setOption({
          series: [
            {
              data: androidMap,
              links: links
            }
          ]
        })
      })
    },

    handlegodetail (id, orgName) {
      const routeData = this.$router.resolve({
        path: 'journaldetail?id=' + id + '&name=' + orgName
        // query: params
      })
      window.open(routeData.href, '_blank')
    },

    // 点击关系图谱跳转详情
    goDetail (option) {
      const routeData = this.$router.resolve({
        path:
          '/authorDetail?id=' +
          option.id +
          '&name=' +
          option.orgCode +
          '&authorCode=' +
          option.authorCode
      })
      window.open(routeData.href, '_blank')

      // location.reload();
    },
    //   合作关系
    async gethotspots () {
      const { data: res } = await this.$http({
        url: '/authorShow/cooperator',
        method: 'POST',
        data: {
          id: this.id // 机构名称
        }
      })
      this.chartLinks = res.data.dataLink
      this.authorData = res.data.chartData
      if (res.code !== 200) return this.$message.error(res.msg)
      this.pubdata(this.authorData, this.chartLinks)
      const _this = this // 将vue的this指向赋值给变量，获取到跳转的router路由
      function myAerlt (val) {
        if (val) {
          _this.goDetail()
        }
      }
      window.myAerlt = myAerlt
      var myChart = this.$echarts.init(this.$refs.cooperate)
      var option = {
        // tooltip: {
        // show: false
        // },
        series: [
          {
            edgeLabel: {
              normal: {
                formatter: '{c}',
                show: true
              }
            },
            // edgeSymbol: "circle",
            force: {
              repulsion: 300, // 枝干线的长短
              edgeLength: 40
            },
            layout: 'force',
            roam: true,
            itemStyle: {
              normal: {
                color: '#6495ED'
              }
            },
            label: {
              normal: {
                show: true, // 是否显示文字
                position: 'bottom'
              }
              // show: true,
              // position: "bottom",
              // distance: 5,
              // fontSize: 14,
              // color: "#666",
              // align: "center"
            },

            symbol: 'circle',
            symbolSize: 1,
            type: 'graph'
            // // 头像
            // symbol: `image://${imgSrc}`,
            // symbolSize: 20,
            // type: "graph",
          }
        ]
      }
      myChart.setOption(option)
      myChart.on('click', function (option) {
        _this.goDetail(option.data)
      })
    }
  },
  watch: {
    acList: {
      handler (data) {
        console.log('watch', data)
        this.authorsidedata.url = '/organization/mainAuthor'
        this.authorsidedata.acList = data
      }
    }
  }
}
</script>
<style lang="less" scoped>
.authorSide {
  .authorSideTop {
    width: 340px;
    height: 320px;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;

    .authorSideTopTitle {
      font-size: 18px;
      font-weight: 500;
      color: @my-color;
    }

    .noneAuthor {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 25%;
    }

    .authorSideTopchartBox {
      width: 300px;
      height: 275px;

      /deep/canvas {
        width: 300px;
        height: 275px;
      }
    }
  }

  .authorSidebottom {
    width: 340px;
    min-height: 570px;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
    box-sizing: border-box;

    .authorSidebottomTitle {
      margin-left: 19px;
      font-size: 18px;
      font-weight: 500;
      color: @my-color;
      padding-bottom: 20px;
    }
  }
}

// .el-main {
//   overflow: hidden;
// }</style>
