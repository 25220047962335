<template>
  <div class="tenInfo">
    <!-- <div class="left"></div> -->
    <!-- <div class="context"> -->
    <div class="top">
      <tenlistrt :isInfo="true" :item="itemData" @transfer="getisClick"></tenlistrt>
    </div>
    <div class="middlemenu">
      <div class="channel-item" :class="{ active: activeName == item.value }" v-for="(item, i) in channelList" :key="i"
        @click="handleClick(item.value)">{{ item.label }}</div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="相关主文献" name="first">
        <mainList :Listobj="Listobj"></mainList>
      </el-tab-pane>
      <el-tab-pane label="学术分析" name="second">
        <hotEchart v-if="isSet"></hotEchart>
      </el-tab-pane>
      <el-tab-pane label="热点热议" name="third">
        <comment v-if="isComment"></comment>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import tenlistrt from '../../components/main/tenlistrt'
import mainList from '../../components/main/mainlist'
import hotEchart from '../../components/main/hotEchart'
import comment from '../../components/commentten/list'
export default {
  components: { tenlistrt, mainList, hotEchart, comment },
  data () {
    return {
      refresh: false,
      Listobj: {
        type: 5, /// 0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅
        url: '',
        academicList: [],
        firstLevelList: [],
        secondLevelList: [],
        hotWords: []
      },
      id: '',
      itemData: {},
      activeName: 'first',
      channelList: [
        {
          label: '入选主文献',
          value: 'first'
        },
        {
          label: '学术分析',
          value: 'second'
        },
        {
          label: '热点热议',
          value: 'third'
        }
      ],
      isSet: false,
      isComment: false
    }
  },
  async created () {
    this.id = this.$route.query.id
    this.Listobj.url = '/academic/search/literature'
    await this.getData()
    document.title = this.itemData.name + this.$route.meta.title
  },
  methods: {
    async getData () {
      // 查询十大学术热词详情
      const { data: res } = await this.$http({
        url: '/academic/getAcademicHotspotDetail',
        method: 'POST',
        data: { id: this.id }
      })
      if (res.code !== 200) return this.$message.error(res.msg)

      this.itemData = res.data
      this.Listobj.academicList = res.data.academicList
      if (res.data.tagJson) {
        this.Listobj.hotWords = JSON.parse(res.data.tagJson)
      }

      this.$store.commit('settagWords', res.data.tagWords)
    },
    handleClick (index) {
      if (index === 'second') {
        this.$store.commit('setTenListEcharts', true)
        this.isSet = true
      }
      if (index === 'third') {
        this.$store.commit('setTenListEcharts', false)
        this.isComment = true
      }
      if (index === 'first') {
        this.$store.commit('setTenListEcharts', false)
      }
      this.activeName = index
    },
    getisClick (val) {
      if (val === 'first') {
        this.$store.commit('setTenListEcharts', false)
      }
      if (val === 'second') {
        this.$store.commit('setTenListEcharts', true)
        this.refresh = true
        this.isSet = true
      }
      if (val === 'third') {
        this.$store.commit('setTenListEcharts', false)
        this.isComment = true
      }
      this.activeName = val
    }
  }
}
</script>

<style scoped lang="less">
.flex {
  display: flex;
  justify-content: space-between;
}

.tenInfo {
  // padding: 0 240px;
  // text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  // position: absolute;
  // left: 50%;
  // transform: translate(-50%);
  width: 1408px;

  .top {
    padding: 20px 20px 20px 12px;
    // padding: 1.0417vw;
    // padding-bottom:0px;
    width: 100%;
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 19px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
  }

  .middlemenu {
    margin: 20px auto;
    width: 1421px;
    height: 57px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding-left: 19px;
    padding-bottom: 3px;
    display: flex;

    .channel-item {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 60px;
      margin-right: 50px;
      cursor: pointer;
    }

    .active {
      font-weight: 500;
      color: @my-color;
      border-bottom: 2px solid @my-color;
    }
  }

  // }
}

/deep/ .el-tabs__nav {
  display: none;
}

/deep/.el-tabs__header {
  margin: 0 !important;
}

.el-tabs {
  width: 1440px;
}
</style>
