<template>
  <!-- 学科专题 -->
  <div class="characteristic">
    <img src="../../assets/images/main/characteristic/charactbanner.jpg" width="100%" alt />
    <div class="gradeBox">
      <div class="linelf"></div>
      <div>
        <img src="../../assets/images/main/characteristic/gradeimg.png" class="img" alt />
        <span class="gradeTitle">特定选题</span>
      </div>
      <div class="linert"></div>
    </div>
    <div class="characteristicontnt">
      <div class="detailtop">
        <!-- <div class="detailtoplf">
          <img :src="detailimg" alt />
        </div>-->
        <div class="detailtoprt">
          <div class="detailtoprttitle">{{ name }}</div>
          <!-- <div class="itemleft">
            <span class="rmb" v-if="priceValue">¥</span>
            <span class="monthmoney">{{ priceValue }}</span>
            <span class="monthmoney" v-if="priceValue">/</span>
            <span class="monthmoney">{{ unit }}</span>
          </div>-->
          <div class="introduce" v-show="descIntr">
            <!-- <div>服务介绍：</div> -->
            <div v-html="descIntr"></div>
          </div>
        </div>
      </div>
      <div class="monthsBox">
        <div class="monthtitlebox">
          <img src="../../assets/images/main/characteristic/gradeimg.png" class="imgtitle" alt />
          <span class="monthtitle">服务月数</span>
          <!-- <span class="monthAll">
            <span>征订整年服务</span>
          </span>-->
        </div>
        <div class="selectmonthBox">
          <div class="selectmonthitem" v-for="(item, index) in selectmonthitem" :key="index">
            <div class="selectbox" v-show="selectitemactive.includes(index)">
              <img
                src="../../assets/images/main/characteristic/selecteddui.png"
                style="vertical-align: middle"
                alt
              />
              <span style="margin-left: 10px">已选</span>
            </div>
            <div
              class="selectmonthitemlist"
              :class="{ active: selectitemactive.includes(index) }"
              @click="handleitem(item, index)"
            >
              <div class="nummonth">{{ item.period }}</div>
              <div class="nummonthmonery">
                <span class="rmb">¥</span>
                <span class="monthmoney">{{ item.priceValue }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 支付方式 -->
      <div class="paymenttype">
        <img src="../../assets/images/main/characteristic/gradeimg.png" class="imgtitle" alt />
        <span class="monthtitle">支付方式</span>
        <div class="paymentwxBox">
          <div class="wxbuyBox">
            <img src="../../assets/images/main/characteristic/wxbuyicon.png" alt />
            <span class="wxbuyTxe">微信支付</span>
          </div>
          <div class="selectbuy">
            <img src="../../assets/images/main/characteristic/selectwxicon.png" alt />
          </div>
        </div>
      </div>
      <!-- 收货地址 -->
      <div class="takeaddress">
        <img src="../../assets/images/main/characteristic/gradeimg.png" class="imgtitle" alt />
        <span class="monthtitle">收货地址</span>
        <div style="flex: 1">
          <!-- <div style="text-align: right;margin-right: 20px;">
            <div class="addressBtn" >
              <img src="../../assets/images/main/characteristic/editoricon.png" alt />
              <span class="addressBtnTxt">添加地址1</span>
            </div>
          </div>-->
          <div class="editorBox">
            <div class="editorBoxLf flex">
              <div class="addressdw" v-show="form.receiver">
                <img src="../../assets/images/main/characteristic/addressicon.png" alt />
              </div>
              <div>
                <div>
                  <span class="addressname" v-if="form.receiver">{{ form.receiver }}</span>
                  <span class="addresstel" v-if="form.tel">{{ form.tel }}</span>
                </div>
                <div class="addresstel">
                  <span v-for="(item, i) in form.selectedOptions" :key="i">{{ item }}</span>
                  <span v-if="form.detailaddress">{{ form.detailaddress }}</span>
                </div>
              </div>
            </div>
            <div class="editorBoxrt" @click="handleaddress">
              <img src="../../assets/images/main/characteristic/editoricon.png" alt />
              <span>编辑地址</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 同意购买 -->
      <div class="agreement">
        <div class="agreementBox">
          <el-checkbox v-model="agreemenchecked">我已同意</el-checkbox>
          <span class="agreementxt">《服务协议》</span>
        </div>
        <div class="buycontent">
          <div>
            <span class="buyBtn" @click="getpayment">同意协议并购买</span>
          </div>
          <div class="paymentCode" v-if="buyshow">
            <div class="wxcode">
              <img v-if="!note" :src="purchaseCode" />
              <img v-if="note" style="filter: blur(8px)" :src="purchaseCode" />
              <div class="notes">{{ notes }}</div>
            </div>
            <div class="wxmoneryBox">
              <div style="margin-bottom: 20px">
                <span class="wxmoneryname">支付金额：</span>
                <span class="wxmonerynum">{{ money }}</span>
                <span class="wxmonerynum">元</span>
              </div>
              <!-- <div>
                <span class="wxmoneryname">账号：</span>
                <span class="wxmoneryname">18009090808</span>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="填写收货信息"
      :visible.sync="dialogVisible"
      width="50%"
      @close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="请填写收货人" prop="receiver">
          <el-input v-model="form.receiver"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="tel">
          <el-input v-model="form.tel"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" prop="selectedOptions">
          <el-cascader style="width: 100%" :options="pcaTextArr" v-model="form.selectedOptions"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="detailaddress">
          <el-input v-model="form.detailaddress"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handlesure('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { pcaTextArr } from "element-china-area-data";
const math = require("mathjs");
export default {
  components: {},
  data() {
    var validatetel = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        console.log("55", value);
        const reg = /^1[3-9]\d{9}$/;
        if (!reg.test(value))
          return callback(
            new Error(
              "请输入正确的手机号,手机号码必须以数字 1 开头,第二位数字可以是 3 到 9 之间的任意一个数字,后面必须跟着 9 个数字"
            )
          );
        callback();
      }
    };
    return {
      stopclick: false, //在二维码显示期间禁止点击期数方法
      intervalId: null,
      buyshow: false, //二维码显示
      detailimg: "", //详情图片
      name: "", //详情标题
      priceValue: "",
      unit: "",
      pcaTextArr,
      descIntr: "",
      purchaseCode: "", //购买二维码
      outTradeNo: "", //当前二维码对应的内部订单编号(唯一)
      dialogVisible: false,
      form: {
        receiver: "", //收货人
        tel: "", //手机号
        selectedOptions: [],
        detailaddress: "" //详细地址
      },
      agreemenchecked: "",
      selectmonthitem: [],
      selectitemactive: [],
      selectmonth: [], // 选中的月份存储
      selectmoney: [], //选中的月份钱数存储
      arrayitem: [],
      id: "",
      rules: {
        receiver: [
          {
            required: true,
            message: "请输入收货人姓名",
            trigger: "blur"
          }
        ],
        tel: [{ required: true, validator: validatetel, trigger: "blur" }],
        selectedOptions: [
          {
            required: true,
            message: "请选择地区",
            trigger: "change"
          }
        ],
        detailaddress: [
          { required: true, message: "请输入详细地址", trigger: "blur" }
        ]
      },
      buyTimes: 0,
      money: 0,
      notes: "",
      counter: 0
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetails();
  },
  methods: {
    // 点击跳转特色
    handlecharacteristic() {
      const routeData = this.$router.resolve({
        path: "/characteristic"
        // query: params
      });
      window.open(routeData.href, "_blank");
    },
    //全选
    handleitems() {
      if (this.selectitemactive.length == this.selectmonthitem.length) {
        this.selectitemactive = [];
      } else {
        this.selectmonthitem.forEach((item, index) => {
          if (this.selectitemactive.includes(index)) {
            this.selectmonthitem = this.selectmonthitem.filter(val => {
              return val != index;
            });
          } else {
            this.selectitemactive.push(index);
          }
        });
      }
    },
    // 单选
    handleitem(item, index) {
      console.log("111", item, index);
      // 如果二维码显示，则禁止点击事件执行
      if (this.buyshow) {
        this.stopclick = true;
        this.$message.error("二维码支付期间禁止点击期数");
        return;
      } else {
        this.stopclick = false;
      }
      if (this.selectitemactive.includes(index)) {
        this.selectitemactive = this.selectitemactive.filter(val => {
          return val != index;
        });
      } else {
        this.selectitemactive.push(index);
      }
      // 选择的月份
      if (this.selectmonth.includes(item.period)) {
        this.selectmonth = this.selectmonth.filter(val => {
          return val != item.period;
        });
        this.buyTimes -= 1;
        console.log("444", this.money);
        this.money = Number(
          math.format(this.money - parseFloat(item.priceValue), 14)
        );
      } else {
        this.selectmonth.push(item.period);
        this.buyTimes += 1;
        this.money = Number(
          math.format(parseFloat(item.priceValue) + this.money),
          14
        );
      }
    },
    // 添加地址
    handleaddress() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 确认地址
    handlesure(form) {
      // 校验
      this.$refs[form].validate(valid => {
        if (valid) {
          this.dialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getDetails() {
      const { data: res } = await this.$http({
        url: `/featuredTopic/getDetails/${this.id}`,
        method: "get"
      });
      if (res.code != 200) return this.$message.error(res.msg);
      this.selectmonthitem = res.data.periods;
      this.name = res.data.name;
      this.priceValue = res.data.priceValue;
      this.unit = res.data.unit;
      this.descIntr = res.data.descIntr;
      this.detailimg = res.data.fdfsUrl + res.data.pic;
    },
    async getpayment() {
      // 我同意必须勾选
      if (!this.agreemenchecked) return this.$message.error("请勾选协议");
      if (!this.form.receiver) return this.$message.error("请先填写收货信息");
      if (this.buyTimes == 0) return this.$message.error("请选择购买期数");
      this.buyshow = true;
      const { data: res } = await this.$http({
        url: "/wechatPay/initiatePay",
        method: "post",
        data: {
          address: this.form.detailaddress,
          area: this.form.selectedOptions, //地区
          contactInfo: this.form.tel, //联系方式
          name: this.name, //商品名称
          periods: this.selectmonth,
          receiver: this.form.receiver, //收货人
          type: 0,
          code: "1001"
        }
      });
      if (res.code != 200) return this.$message.error(res.msg);

      this.purchaseCode = res.data.base64Image; //生成的二维码
      this.outTradeNo = res.data.outTradeNo; //当前二维码对应的内部订单编号(唯一)
      this.counter = 0;

      this.intervalId = setInterval(async () => {
        this.getQrcodeState();
      }, 3000);
    },
    async getQrcodeState() {
      this.counter += 1;
      if (this.counter >= 30) {
        console.log("444", this.counter);
        clearInterval(this.intervalId); // 停止定时器
        this.buyshow = false; //支付显示框
        this.counter = 0;
        // 清空选择
        this.agreemenchecked = ""; //协议同意
        this.buyTimes = 0;
        this.money = 0;
        this.selectitemactive = [];
        this.selectmonth = []; // 选中的月份存储
        this.selectmoney = []; //选中的月份钱数存储
      } else {
        const { data: res } = await this.$http({
          url: `/wechatPay/getOrderPayStatus/${this.outTradeNo}`,
          method: "GET"
        });
        if (res.data.code == 201) {
          this.notes = "正在支付";
          // this.getQrcodeState();
        } else if (res.data.code == 203) {
          console.log("支付失败203");
          this.notes = "支付失败";
          clearInterval(this.intervalId); // 停止定时器
          this.buyshow = false; //支付显示框
          this.counter = 0;
          // 清空选择
          this.agreemenchecked = ""; //协议同意
          this.buyTimes = 0;
          this.money = 0;
          this.selectitemactive = [];
          this.selectmonth = []; // 选中的月份存储
          this.selectmoney = []; //选中的月份钱数存储
        } else if (res.data.code == 204) {
          console.log("未完成支付204");
          this.notes = "未完成支付";
          // this.getQrcodeState();
        } else if (res.data.code == 500) {
          console.log("支付失败500");
          this.notes = "支付错误";
          clearInterval(this.intervalId); // 停止定时器
          this.buyshow = false; //支付显示框
          this.counter = 0;
          // 清空选择
          this.agreemenchecked = ""; //协议同意
          this.buyTimes = 0;
          this.money = 0;
          this.selectitemactive = [];
          this.selectmonth = []; // 选中的月份存储
          this.selectmoney = []; //选中的月份钱数存储
        } else if (res.data.code == 200) {
          this.notes = "支付成功";
          clearInterval(this.intervalId); // 停止定时器
          let url = this.$router.push("/user/memberorder");
          this.agreemenchecked = ""; //协议同意
          this.buyshow = false; //支付显示框
          this.buyTimes = 0;
          this.money = 0;
          this.selectitemactive = [];
          this.selectmonth = []; // 选中的月份存储
          this.selectmoney = []; //选中的月份钱数存储
          // let timer = setTimeout(url, 3000); //支付成功后3秒跳转我的订单页面
          // clearTimeout(this.timer);
        } else {
          this.getQrcodeState();
        }
      }
    }
    // async getQrcodeState() {
    //   // let counter = 0;

    // }
  },
  watch: {}
};
</script>

<style lang="less" scoped>
.characteristic {
  .gradeBox {
    margin-top: -3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .linelf {
      width: 760px;
      height: 50px;
      background: #f5f5f5;
      border-radius: 0px 0px 40px 0px;
    }
    .linert {
      width: 759px;
      height: 50px;
      background: #f5f5f5;
      border-radius: 0px 0px 0px 40px;
    }
    img {
      width: 27px;
      height: 24px;
      margin-right: 16px;
      vertical-align: sub;
      overflow: hidden;
      object-fit: cover;
    }
    .gradeTitle {
      font-weight: 500;
      font-size: 24px;
      color: #9c2526;
    }
  }
  .characteristicontnt {
    width: 1440px;
    margin: 0px auto;
    margin-top: 66px;
    .detailtop {
      display: flex;
      align-items: center;
      margin-bottom: 60px;
      .detailtoplf {
        width: 272px;
        height: 168px;
        > img {
          width: 272px;
          height: 168px;
          overflow: hidden;
          object-fit: cover;
        }
      }
      .detailtoprt {
        padding-left: 20px;
        .detailtoprttitle {
          text-align:center;
          font-weight: 500;
          font-size: 24px;
          color: #000000;
          margin-bottom: 24px;
        }
        .itemleft {
          color: #ff7100;
          margin: 20px 0;
          .rmb {
            font-size: 20px;
            color: #ff7100;
            line-height: 27px;
          }
          .monthmoney {
            padding-left: 5px;
            font-weight: 500;
            font-size: 28px;
            color: #ff7100;
            line-height: 27px;
          }
        }
        .introduce {
          font-size: 16px;
          color: #666666;
          line-height: 24px;
          /deep/.title-one {
            font-weight: 600;
            line-height: 46px;
            font-size: 16px;
            color: #666;
            margin-top: 10px;
          }
          /deep/.mian-text {
            span {
              font-weight: 600;
            }

            font-size: 14px;
            color: #666;
            margin-bottom: 10px;
          }
          /deep/.image-top {
            text-align: center;
            margin-bottom: 40px;
            img {
              text-align: center;
              width: 600px;
            }
          }
          /deep/ .image-bottom {
            text-align: center;
            margin-top: 40px;
            img {
              text-align: center;
              width: 600px;
            }
          }
        }
      }
    }
    .monthsBox {
      padding: 20px;
      box-sizing: border-box;
      height: 240px;
      background: #fff9f1;
      border-radius: 8px;
      .monthtitlebox {
        margin-bottom: 12px;
        img {
          width: 27px;
          height: 24px;
          margin-right: 16px;

          overflow: hidden;
          object-fit: cover;
          vertical-align: bottom;
        }
        .monthtitle {
          margin-left: 10px;
          font-weight: 500;
          font-size: 20px;
          color: #000000;
          line-height: 27px;
          margin-right: 10px;
        }
        .monthAll {
          display: inline-block;
          width: 166px;
          height: 32px;
          line-height: 32px;
          background-image: url("../../assets/images/main/characteristic/monthall.png");
          background-repeat: no-repeat;
          background-size: contain;
          > span {
            padding-left: 50px;
            font-weight: 500;
            font-size: 18px;
            color: #ffffff;
          }
        }
      }
      .selectmonthBox {
        display: flex;
        // justify-content: space-between;
        align-items: center;

        .selectmonthitem {
          position: relative;
          margin-right: 15px;
          .selectbox {
            width: 76px;
            height: 26px;
            line-height: 26px;
            background: #9c2526;
            border-radius: 8px;
            text-align: center;
            color: #fff;
            position: absolute;
            top: 0px;
            left: 0;
          }
          .selectmonthitemlist:hover {
            cursor: pointer;
          }
          .selectmonthitemlist {
            text-align: center;
            margin-top: 13px;
            width: 100px;
            height: 150px;
            background: #fff;
            border-radius: 8px;
            border: 1px solid #ff7100;

            &.active {
              background: #fff9f1;
            }
            .nummonth {
              padding-top: 35px;
              box-sizing: border-box;
              padding-bottom: 20px;
              font-weight: 500;
              font-size: 28px;
              color: #ff7100;
            }
            .nummonthmonery {
              font-weight: 500;
              font-size: 20px;
              color: #666666;
              .rmb {
                padding-right: 5px;
                box-sizing: border-box;
              }
              .monthmoney {
                font-weight: 500;
                font-size: 20px;
                color: #666666;
              }
            }
          }
        }
      }
    }
    .paymenttype {
      margin-top: 40px;
      display: flex;
      align-items: center;
      & > img {
        width: 27px;
        height: 24px;
        margin-right: 16px;
        overflow: hidden;
        object-fit: cover;
        vertical-align: bottom;
      }
      .monthtitle {
        margin-left: 10px;
        font-weight: 500;
        font-size: 20px;
        color: #000000;
        line-height: 27px;
        margin-right: 10px;
      }
      .paymentwxBox:hover {
        cursor: pointer;
      }
      .paymentwxBox {
        text-align: center;
        width: 200px;
        height: 70px;
        position: relative;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #ff7100;
        .wxbuyBox {
          padding-top: 10px;
          > img {
            width: 50px;
            height: 50px;
            margin-right: 16px;
            overflow: hidden;
            object-fit: cover;
            vertical-align: middle;
          }
          .wxbuyTxe {
            margin-left: 10px;
            font-weight: 500;
            font-size: 24px;
            color: #000000;
            vertical-align: middle;
          }
        }
        .selectbuy {
          position: absolute;
          bottom: -4px;
          right: -1px;
          > img {
            width: 43px;
            height: 44px;
          }
        }
      }
    }
    .takeaddress {
      margin-top: 40px;
      display: flex;
      align-items: center;
      & > img {
        width: 27px;
        height: 24px;
        margin-right: 16px;
        overflow: hidden;
        object-fit: cover;
        vertical-align: bottom;
      }
      .monthtitle {
        margin-left: 10px;
        font-weight: 500;
        font-size: 20px;
        color: #000000;
        line-height: 27px;
        margin-right: 10px;
      }
      .addressBtn:hover {
        cursor: pointer;
      }
      .addressBtn {
        display: inline-block;
        width: 120px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #9c2526;
        border-radius: 4px;

        .addressBtnTxt {
          margin-left: 10px;
          color: #ffffff;
          font-size: 16px;
        }
      }
      .editorBox {
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
        height: 90px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #e6e6e6;
        display: flex;

        .editorBoxLf {
          flex: 1;
          .addressdw {
            margin-right: 10px;
            padding-top: 5px;
          }
          .addressname {
            margin-right: 20px;
            font-weight: 500;
            font-size: 24px;
            color: #000000;
          }
          .addresstel {
            font-size: 16px;
            color: #000000;
            line-height: 27px;
          }
        }
        .editorBoxrt {
          text-align: center;
          line-height: 32px;
          width: 120px;
          height: 32px;
          background: #9c2526;
          border-radius: 4px;
          & > span {
            padding-left: 10px;
            box-sizing: border-box;
            font-size: 16px;
            color: #ffffff;
          }
          > img {
            width: 14px;
            height: 14px;
            overflow: hidden;
            object-fit: cover;
            vertical-align: middle;
          }
        }
      }
    }
    .agreement {
      margin: 40px 0;
      .agreementBox {
        .agreementxt {
          font-size: 16px;
          color: #9c2526;
        }
      }
      .buycontent {
        margin-top: 10px;
        display: flex;
        .buyBtn {
          display: inline-block;
          width: 180px;
          height: 56px;
          text-align: center;
          line-height: 56px;
          background: #9c2526;
          border-radius: 8px;
          font-size: 18px;
          color: #ffffff;
        }
        .paymentCode {
          display: flex;
          margin-left: 60px;
          width: 600px;
          height: 240px;
          background: #f5f5f5;
          border-radius: 8px;
          padding: 20px;
          box-sizing: border-box;
          align-items: center;
          .wxcode {
            width: 200px;
            height: 200px;
            padding: 10px;
            background: #ffffff;
            border-radius: 8px;
            box-sizing: border-box;
            overflow: hidden;
            object-fit: cover;
            display: flex;
            // align-items: center;
            // justify-content: center;
            position: relative;
            .notes {
              width: 200px;
              height: 200px;
              display: flex;
              z-index: 1;
              align-items: center;
              justify-content: center;
              position: absolute;
              // background-color: #999;
              font-size: 14px;
              font-weight: bold;
              top: 0;
            }
          }
          .wxmoneryBox {
            margin-left: 20px;
            .wxmoneryname {
              font-size: 16px;
              color: #000000;
            }
            .wxmonerynum {
              font-weight: 500;
              font-size: 34px;
              color: #ff7100;
            }
          }
        }
      }
    }
  }
}
</style>
