<template>
  <div>
    <Toolbar style="border-bottom: 1px solid #ccc;" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
    <Editor style=" height:100px;max-height: 250px;" v-model="html" :defaultConfig="editorConfig" :mode="mode"
      @onCreated="onCreated" @onChange="onChange" />
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import Vue from 'vue'
export default Vue.extend({
  components: { Editor, Toolbar },
  props: {
    edithtml: {
      type: String,
      required: true
    },
    mymarklist: {
      type: Array
    }
  },
  data () {
    return {
      editor: null,
      html: this.edithtml,
      toolbarConfig: {
        toolbarKeys: [
          'headerSelect', // 标题
          'bold',
          'underline',
          'italic',
          'through',
          'color', // 字体颜色
          'bgColor', // 背景色
          'justifyLeft', // 左对齐
          'justifyRight', // 右对齐
          'justifyCenter', // 居中对齐
          'justifyJustify', // 两端对齐
          'divider', // 分割线
          'emotion', // 表情
          'todo', // 待办
          'bulletedList', // 无序列表
          'numberedList', // 有序列表
          'uploadImage'
        ]
      },
      editorConfig: {
        maxLength: 500,
        MENU_CONF: {
          uploadImage: {
            customUpload: this.uploadImg,
            customInsert: this.insertImg,
            allowedFileTypes: [],
            fieldName: 'file',
            onBeforeUpload (file) {
              return file
            },
            onProgress (progress) {
              console.log('progress', progress)
            },
            onSuccess (file, res) {
              console.log(`${file.name} 上传成功`, res)
            },
            onFailed (file, res) {
              console.log(`${file.name} 上传失败`, res)
            },
            customInsert (res, insertFn) { // JS 语法
              // res 即服务端的返回结果
              console.log('insert', res)
            }
          }
        }
      },
      mode: 'simple'
    }
  },
  methods: {
    onCreated (editor) {
      editor.focus(true)
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    // 自定义上传图片
    async uploadImg (file, insertFn) {
      const photo = file
      const img = new FormData()
      img.append('photo', photo)
      console.log('file', file)
      const res = await this.$http({
        url: '/userinfo/uploadPhoto',
        method: 'POST',
        data: img
      })
      if (res.data.code !== 200) {
        return this.$message.error(res.data.msg)
      }
      this.$message.success(res.data.msg)
      insertFn(res.data.data.url + res.data.data.photo)
    },
    // 自定义插入图片
    insertImg (file) {
      console.log('323', file)
    },
    onChange (editor) {
      let article = this.editor.getHtml()
      // console.log(editor)
      article = article.replace(/(<img[^>]*style=")(.*?)("[^>]*>)/g, (match, p1, p2, p3) => {
        return p1 + 'width:auto;height:100px;cursor:pointer;vertical-align:top' + p3
      })
      article = article.replace(/<img/g, '<img preview="0"')
      // article = article.replace('</p><p><br>', '')
      console.log('article', article)
      this.$emit('html', article)
      if (this.mymarklist && this.mymarklist.length > 0) {
        for (let i = 0; i < this.mymarklist.length; i++) {
          if (this.mymarklist[i].markValue === article) {
            this.$emit('markIndex', i)
          }
        }
      }
    }
  },
  mounted () {
  },
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  watch: {
    edithtml: {
      deep: true,
      immediate: true,
      handler (data) {
        this.html = data
      }
    }
  }
})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="less"></style>
