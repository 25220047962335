<template>
  <div class="comment">
    <div class="avator">
      <img :src="avator" alt="">
    </div>
    <send ref="send" @emitSendSuccess="emitSendSuccess"></send>
  </div>
</template>

<script>
import send from './send.vue'
export default {
  components: {
    send
  },
  data () {
    return {
      // 默认头像
      avator: require('@/assets/images/main/comment/touxiang.png')
    }
  },
  mounted () {
    // let data = this.$store.state.imageUrl
    // if (data) {
    //   this.avator = data
    // }
    this.$refs.send.init()
  },
  methods: {
    // 监听评论成功
    emitSendSuccess () {
      this.$emit('emitSendSuccess')
    }
  },
  watch: {
    '$store.state.token': {
      deep: true,
      immediate: true,
      handler (newName, oldName) {
        if (localStorage.getItem('token')) {
          const data = this.$store.state.imageUrl
          if (data) {
            this.avator = data
          }
        } else {
          this.avator = require('@/assets/images/main/comment/touxiang.png')
        }
      }
    }
  }
}

</script>

<style lang="less" scoped>
.comment {
  display: flex;

  .avator {
    width: 60px;
    height: 60px;
    // object-fit: contain;
    border-radius: 50%;

    >img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
</style>
