<template>
  <div class="pdf-preview" v-loading.fullscreen="loading" element-loading-spinner=none element-loading-text="数据加载中 ..."
    element-loading-background="rgb(255, 255, 255)" element-loading-custom-class="boxStyleLoadingLook">
    <pdf class='pdf' @page-loaded="pageLoaded" v-for="i in pageTotalNum" :key="i" :page="i" :src="src"
      @num-pages="pageNum = $event" ref="pdf" style="width:50%;margin:0 auto 10px;"></pdf>
    <!-- <div class="head">
      <div class="fenye">
        <el-button @click="downPDF" class="mr10down">下载</el-button>
      </div>
    </div>-->
    <!-- <pdf class='pdf' ref="pdf" :src="src" v-for="i in pageTotalNum" :key="i" :page="i" @page-loaded="pageLoaded($event)"
    @num-pages="pageNum = $event" @error="pdfError($event)" @link-clicked="page = $event"></pdf>-->
    <!-- <div class="tools">
      <div class="fenye">
        <el-button @click="prePage" class="mr10">上一页</el-button>
        <el-button @click="nextPage" class="mr10">下一页</el-button>
        <button :class="{ select: idx == 0 }" @click="scaleD">放大</button>
        <button :class="{ select: idx == 1 }" @click="scaleX">缩小</button>

        <span class="page">{{ pageNum }}/{{ pageTotalNum }}</span>
      </div>
    </div>-->
  </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  name: 'LookPdf',
  components: {
    pdf
  },
  data () {
    return {
      loading: true,
      referenceNumber: '', // 用于请求接口的referenceNumber
      src: '', // 预览地址
      pageNum: 1, // 当前页码
      pageLoad: 0,
      pageTotalNum: 1, // 总页数
      Num: 0,
      NumA: 0, // 总个数
      Numnow: 1, // 当前个数
      scale: 50, // 放大系数
      idx: -1,
      vuePdf: null,
      url: [
        {
          fileName: '预览文献',
          fileSrc: ''
        }
      ]
    }
  },
  mounted () {
    document.querySelector('.home').style.backgroundColor = '#ededed'
    document.querySelector('.middleContainer').style.backgroundColor = '#ededed'
    // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
    this.referenceNumber = this.$route.query.referenceNumber
    this.getPdfDetail().then(() => {
      this.$nextTick(() => {
        this.NumA = this.url.length
        var url = this.url[this.Num].fileSrc
        this.src = pdf.createLoadingTask(url)
        this.src.promise.then(pdf => {
          this.pageTotalNum = pdf.numPages
        })
        // this.$refs.pdf.$el.style.width = "50%";
      })
    })
  },
  methods: {
    pageLoaded () {
      this.pageLoad++
      if (this.pageLoad >= this.pageNum) {
        this.loading = false
      } else {
        this.loading = true
      }
      console.log(this.pageLoad)
    },
    async getPdfDetail () {
      const res = await this.$http({
        url: `/detail/onlinePreviewPDF/?referenceNumber=${this.referenceNumber}`,
        method: 'GET'
      })
      if (res.data.code !== 200) {
        if (res.data.code === 115001) {
          this.$router.push({ name: 'main' })
        }
        return this.$message.error(res.data.msg)
      }
      this.url[0].fileSrc = res.data.data
    }
    // last () {
    //   this.Numnow--
    //   this.Num--
    //   var url = this.url[this.Num].fileSrc
    //   this.src = pdf.createLoadingTask(url)
    // },
    // next () {
    //   this.Numnow++
    //   this.Num++
    //   var url = this.url[this.Num].fileSrc
    //   this.src = pdf.createLoadingTask(url)
    // }, // 上一页函数，
    // prePage () {
    //   var page = this.pageNum
    //   page = page > 1 ? page - 1 : this.pageTotalNum
    //   this.pageNum = page
    // }, // 下一页函数
    // nextPage () {
    //   var page = this.pageNum
    //   page = page < this.pageTotalNum ? page + 1 : 1
    //   this.pageNum = page
    // }, // 页面加载回调函数，其中e为当前页数
    // pageLoaded (e) {
    //   console.log('e', e)
    //   this.curPageNum = e
    // } // 抛出错误的回调函数。
    // pdfError (error) {
    //   console.error(error)
    // }
    // scaleD () {
    //   this.scale += 5
    //   this.$refs.pdf.$el.style.width = parseInt(this.scale) + '%'
    // },

    // // 缩小
    // scaleX () {
    //   if (this.scale == 50) {
    //     return
    //   }
    //   this.scale += -5
    //   this.$refs.pdf.$el.style.width = parseInt(this.scale) + '%'
    // },
    // downPDF () {
    //   // 下载 pdf
    //   var url = this.url[0].fileSrc
    //   var tempLink = document.createElement('a')
    //   tempLink.style.display = 'none' // tempLink.href = url;
    //   window.open(url)
    //   tempLink.setAttribute('download', 'XXX.pdf')
    //   if (typeof tempLink.download === 'undefined') {
    //     tempLink.setAttribute('target', '_blank')
    //   }
    //   document.body.appendChild(tempLink)
    //   tempLink.click()
    //   document.body.removeChild(tempLink)
    // }
  }
}
</script>
<style scoped lang="less">
.pdf-preview {
  padding-top: 10px;

  .pdf {
    margin-bottom: 10px;
  }

  .head {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .tools {
    display: flex;
    justify-content: space-between;

    .fenye {
      margin-top: 20px;
    }
  }

  .page {
    margin-left: 10px;
  }
}
</style>
