<template>
  <div class="headsearchBox">
    <div class="searchBox">
      <div class="search" @keydown.enter="searchContentBtn">
        <el-input v-model.trim="searchContent.search" placeholder="请输入主文献题目、编号、作者名称、机构单位、期刊名称、摘要、关键词检索"
          class="searchInput" clearable></el-input>
        <div class="searchBtn" @click="searchContentBtn">
          <span class="seacrhIcon"></span>
          <span class="seacrhtxt">搜索</span>
        </div>
      </div>
      <!-- <img class="acadmic" src="../../assets//images//head/十大学术2.gif" @click="handlegolook" alt /> -->
      <div class="hotWordBox">
        <span class="hotWordTitle">
          <img src="../../assets/images/home/hotword.png" alt />
        </span>
        <span class="hotworditem" v-for="(item, index) in hotwordList" :key="index" @click="handlehotword(item)">
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // search: "",
      searchContent: {
        search: '',
        timestamp: ''
      }, // 搜索内容
      hotwordList: []
    }
  },
  mounted () {
    this.searchData()
  },
  methods: {
    // 点击热搜
    handlehotword (item) {
      // this.search = item.name;
      this.searchContent.search = item.name
    },
    // 点击搜索按钮
    searchContentBtn (event) {
      // if (event.isTrusted) { // 判断是用户点击的还是程序点击的
      //   if (this.search === '') {
      //     return this.$message({
      //       type: 'warning',
      //       message: '搜索内容不能为空'
      //     })
      //   }
      // }
      this.searchContent.timestamp = Date.now()
      // this.searchContent.search = this.searchContent.search;
      // const searchContent = this.searchContent;

      // 存到缓存里面
      // this.searchContent.search = this.searchContent.search.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      this.$store.commit('handlehotwordsearch', this.searchContent.search)
      this.$store.commit(
        'handlehotwordsearchTime',
        this.searchContent.timestamp
      )
      // handlehotwordsearchTime
    },
    async searchData () {
      const { data: res } = await this.$http({
        url: '/literatureInfoList/getTopHotWordList',
        method: 'get'
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.hotwordList = res.data
    },
    handlegolook () {
      const routeData = this.$router.resolve({
        path: '/tensciencelist'
      })
      window.open(routeData.href, '_blank')
    }
  },
  watch: {
    // 监听大搜索
    '$store.state.hotwordsearch': {
      deep: true,
      handler (data) {
        if (data.search == '') {
          this.searchContent.search = ''
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.headsearchBox {
  position: absolute;
  bottom: 0;
  z-index: 9;
  // width: 100vw;
  width: 100%;
  height: 124px;

  .searchBox {
    position: relative;
    margin: 0 auto;
    padding: 0 12%;
    width: 1390px;

    .search {
      margin-top: 10px;
      position: relative;
      width: 100%;
      height: 50px;

      .searchInput {
        width: 1390px;
        position: absolute;

        /deep/span {
          margin-right: 55px;
        }

        /deep/ .el-input__inner {
          height: 50px;
          border-radius: 25px;
          border: none;
          padding: 0 120px 0 30px;

          &::placeholder {
            font-size: 16px !important;
          }
        }
      }

      .searchBtn {
        text-align: center;
        position: absolute;
        right: 0;
        top: 6px;
        width: 100px;
        height: 38px;
        line-height: 38px;
        background: @my-color;
        border-radius: 19px;
        margin-right: 9px;

        .seacrhIcon {
          vertical-align: middle;
          background: url("../../assets/images/home/search.png") no-repeat;
          background-size: cover;
          width: 18px;
          height: 18px;
          display: inline-block;
        }

        .seacrhtxt {
          cursor: pointer;
          color: #fff;
          padding-left: 10px;
          font-size: 16px;
        }
      }
    }

    .acadmic {
      margin-top: 19px;
      width: 300px;
      margin-left: 12px;
      height: 66px;
      cursor: pointer;
    }

    .hotWordBox {
      margin-top: 15px;
      margin-bottom: 25px;
      padding-left: 30px;

      .hotWordTitle {
        display: inline-block;
        text-align: center;
        width: 58px;
        height: 26px;
        line-height: 26px;
        background: #ff7100;
        border-radius: 6px;

        >img {
          vertical-align: middle;
          width: 33px;
          height: 16px;
        }
      }

      .hotworditem {
        cursor: pointer;
        color: #fff;
        padding: 0 20px;
        font-size: 16px;
      }
    }
  }
}
</style>
