<template>
  <div class="noticeBox">
    <div class="tabBox">
      <div class="tabTileLeft">
        <span class="tabTileLeftitem" v-for="(item, i) in tabList" :key="i" @click="handleTabClick(i)"
          :class="{ active: currentTab == i }">
          <span>{{ item.label }}</span>
          <span class="waiting-num" v-if="item.data > 0 && i !== 2">{{ item.data }}</span>
        </span>
      </div>
    </div>
    <div class="searchBox">
      <div class="searchBoxlf">
        <el-input v-model="noticealls" @keyup.enter.native='handleSearch()' placeholder="请输入通知标题" clearable></el-input>
        <el-button type="primary" @click="handleSearch()">查询</el-button>
      </div>
      <div v-if='currentTab !== 2 && tableData.length > 0' class="searchBoxrt">
        <el-button type="primary" @click="handleReadAll()">全部已读</el-button>
      </div>
    </div>
    <div class="tabContent">
      <el-table :header-cell-style="tableHead" :data="tableData" style="width: 100%" v-if="total > 0">
        <el-table-column label="序号" width="50">
          <template #default="scope">{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column width='420' align='center' prop="title" label="通知标题"></el-table-column>
        <el-table-column show-overflow-tooltip align='center' prop="sendTime" label="通知时间"
          width="120"></el-table-column>
        <el-table-column show-overflow-tooltip align='center' prop="isRead" label="是否阅读" width="80">
          <template v-slot="scoped">
            <div style='color: #FF7100 ;' v-if="scoped.row.isRead == 0">未读</div>
            <div style='color:#1AB41A' v-if="scoped.row.isRead == 1">已读</div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip align='center' prop="readTime" label="阅读时间"
          width="120"></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scoped">
            <el-button @click="handlePreviewClick(scoped.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="empty" v-else>
        <img src="../../assets/images/home/nodataside.png" alt="">
        <span>暂无数据</span>
      </div>
      <el-pagination v-if="total > 0" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      currentTab: 0,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      noticeall: '',
      noticealls: '',
      tabList: [{
        label: '全部',
        value: '',
        data: 0
      }, {
        label: '未读消息',
        value: '0',
        data: 0
      }, {
        label: '已读消息',
        value: '1',
        data: 0
      }],
      tableData: []
    }
  },
  mounted () {
    document.title = this.$route.meta.title
    this.getNum()
    this.getData()
  },
  methods: {
    tableHead () {
      return 'background-color: #f2f2f2;text-align:center;color:#9c2526'
    },
    // 点击查看
    async getNum () {
      const {
        data: res
      } = await this.$http({
        url: 'userinfo/getMessageCount',
        method: 'post'
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.tabList[0].data = res.data.allCount
      this.tabList[1].data = res.data.unReadCont
      this.tabList[2].data = res.data.readCont
    },
    // 获取列表
    async getData () {
      const {
        data: res
      } = await this.$http({
        url: 'userinfo/loadMessagePage',
        method: 'post',
        data: {
          current: this.currentPage,
          size: this.pageSize,
          isRead: this.tabList[this.currentTab].value,
          mesTitle: this.noticeall
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.tableData = res.data.rows
      this.total = res.data.total
    },
    // 点击查看
    async handlePreviewClick (item) {
      const {
        data: res
      } = await this.$http({
        url: 'userinfo/readMessage',
        method: 'post',
        data: [item.id]
      })
      this.getNum()
      const url = JSON.parse(item.link).RequestURL
      const id = JSON.parse(item.link).literId
      const referenceNumber = JSON.parse(item.link).referenceNumber
      const path = JSON.parse(item.link).path
      await this.$http({
        url: '/detail/isClock',
        method: 'post',
        data: {
          referenceNumber
        }
      })
      this.$router.push(`${url}?id=${id}&referenceNumber=${referenceNumber}&path=${path}`)
    },
    // 标记全部已读
    async getReadAll () {
      const {
        data: res
      } = await this.$http({
        url: 'userinfo/readAllMessage',
        method: 'post'
      })
      this.currentPage = 1
      this.getNum()
      this.getData()
    },
    // 点击查询
    handleSearch () {
      this.noticeall = this.noticealls
      // this.noticeall = this.noticeall.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      this.currentPage = 1
      this.getNum()
      this.getData()
    },
    // 选择tab
    handleTabClick (index) {
      this.currentTab = index
      this.currentPage = 1
      this.getData()
    },
    // 选择分页每页显示条数
    handleSizeChange (val) {
      this.currentPage = 1
      this.pageSize = val
      this.getData()
    },
    // 选择每一页
    handleCurrentChange (val) {
      this.currentPage = val
      this.getData()
    },
    // 全部已读
    handleReadAll () {
      this.$confirm('您确定要标记全部已读吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.getReadAll()
      }).catch(() => { })
    }
  }
}

</script>

<style lang="less" scoped>
/deep/.el-input {
  width: 280px;
  margin-right: 15px;
}

.noticeBox {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  // margin-top: .5208vw;
  width: 1156px;

  .searchBox {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;

    .searchBoxlf {}
  }

  .tabBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f4f8fb;

    .tabTileLeft {
      padding: 0 0 20px 0;

      .tabTileLeftitem {
        position: relative;
        cursor: pointer;
        font-size: 18px;
        color: #999999;
        padding: 0 0 8px 0;
        margin-right: 60px;
        display: inline-block;
        height: 28px;
        line-height: 28px;
        border: 2px solid transparent;

        >span {
          display: inline-block;
          font-weight: 500;
          font-size: 18px;
          position: relative;

        }

        .waiting-num {
          // position: absolute;
          display: inline-block;
          padding: 0 4px;
          min-width: 30px;
          height: 16px;
          font-size: 12px;
          color: #fff;
          line-height: 17px;
          margin-left: 6px;
          // right: 0vw;
          // top: 50%;
          // transform: translate(100%, -50%);
          background: #f03838;
          border-radius: 8px;
          text-align: center;
        }
      }

      .active {
        border-bottom: 2px solid @my-color;
        transition: ease-in 0.2s;
        color: @my-color;
      }
    }

    .lookmore {
      color: #999999;
      padding-right: 10px;
      cursor: pointer;
    }
  }

  .tabContent {
    margin-top: 22px;

    /deep/table {
      // margin-top: 1.0417vw;

      thead tr th,
      tr td {
        text-align: center;
        cursor: pointer;
      }

      tr td:nth-child(2) {
        text-align: left;
      }
    }

    .empty {
      margin-top: 65px;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        width: 232px;
        height: 114px;
      }

      span {
        color: #999;
        font-size: 14px;
      }
    }

  }
}

/deep/.el-table .cell.el-tooltip {
  white-space: nowrap;
  min-width: 100% !important;
  /* text-align: center; */
}

/deep/.el-table__header,
/deep/.el-table__body {
  width: 100% !important;
}

/deep/.el-table__row:hover {
  color: #9c2526;
}
</style>
