<template>
  <div class="detailatlasBox">
    <div class="title">
      <div class="_top">
        <div class="_left">
          <i class="headtitleimg"></i>
          <div class="numbg">
            <div>共有 {{ commentNum }}次热议，{{ likeNum }}次点赞</div>
          </div>
        </div>
        <div class="_right">
          <div @click="handleSortClick('time')">
            时间
            <div class="icon">
              <span class="_t" :class="{ active: sort.time == 0 }"></span>
              <span class="_b" :class="{ active: sort.time == 1 }"></span>
            </div>
          </div>
          <div @click="handleSortClick('hot')">
            热度
            <div class="icon">
              <span class="_t" :class="{ active: sort.hot == 0 }"></span>
              <span class="_b" :class="{ active: sort.hot == 1 }"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <comment @emitSendSuccess="emitSendSuccess"></comment>
      <user ref="user" @emitSendSuccess="emitSendSuccess" :sort="sort"></user>
    </div>
  </div>
</template>
  
<script>
import comment from "./comment.vue";
import user from "./user.vue";
export default {
  components: {
    comment,
    user
  },
  data() {
    return {
      // 评论总数
      commentNum: 0,
      // 点赞数
      likeNum: 0,
      // 排序方式  null 不选择  0 上  1 下
      sort: {
        type: 1,
        hot: null,
        time: 1
      },
      academicHotWordsId: "" //专题id
    };
  },
  mounted() {
    this.academicHotWordsId = this.$route.query.id;
    this.getNumber();
  },
  methods: {
    // 获取评论列表
    async getNumber() {
      const { data: res } = await this.$http({
        url: `/academicComment/getCommentCount`,
        method: "post",
        data: {
          academicHotWordsId: this.academicHotWordsId
        }
      });
      if (res.code != 200) return this.$message.error(res.msg);
      this.commentNum = res.data.commentCount;
      this.likeNum = res.data.likeCount;
    },
    // 点击排序
    handleSortClick(type) {
      if (type == "hot") {
        this.sort.type = 2
        if (this.sort.hot == 0) this.sort.hot = 1;
        else this.sort.hot = 0;
        this.sort.time = null;
      } else {
        this.sort.type = 1
        if (this.sort.time == 0) this.sort.time = 1;
        else this.sort.time = 0;
        this.sort.hot = null;
      }
      this.$refs.currentPage = 1;
      this.$refs.user.getData();
    },
    // 监听评论成功
    emitSendSuccess() {
      this.getNumber();
      this.$refs.user.getData();
    }
  }
};
</script>
  
<style lang="less" scoped>
.detailatlasBox {
  margin: 2px 3px 20px 3px;
  padding-right: 10px;
  // padding: 20px 54px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;

  .title {
    padding: 31px 44px 20px 20px;

    ._top {
      display: flex;
      // align-items: center;
      justify-content: space-between;

      ._left {
        display: flex;
        align-items: center;

        .headtitleimg {
          width: 87px;
          height: 21px;
          background: url("../../assets/images/main/comment/hot.png") no-repeat center;
          background-size: contain;
        }

        .numbg {
          margin-left: 10px;
          display: flex;
          align-items: center;
          padding: 2px 15px;
          background: linear-gradient(267deg,
              rgba(255, 231, 71, 0) 0%,
              #ffe747 100%);
          border-radius: 4px;

          >div {
            color: #333;
            font-size: 14px;
            margin-right: 38px;
          }
        }
      }

      ._right {
        display: flex;
        align-items: center;

        >div {
          color: #000000;
          font-size: 17px;
          font-weight: 700;
          margin-left: 38px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .icon {
            margin-left: 8px;
            margin-top: 2px;

            >span {
              display: block;
              cursor: pointer;
              width: 10px;
              height: 5px;

              &._t {
                background: url("../../assets/images/main/comment/arr-t.png") no-repeat center;
                background-size: contain;
                margin-bottom: 2px;

                &.active {
                  background: url("../../assets/images/main/comment/arr-t-act.png") no-repeat center;
                  background-size: contain;
                }
              }

              &._b {
                background: url("../../assets/images/main/comment/arr-b.png") no-repeat center;
                background-size: contain;

                &.active {
                  background: url("../../assets/images/main/comment/arr-b-act.png") no-repeat center;
                  background-size: contain;
                }
              }
            }
          }
        }
      }
    }
  }
  .content{
    overflow: scroll;
    padding: 10px 54px 21px 20px;
  }
}
</style>
  