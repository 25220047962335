<template>
  <div v-loading.lock="isLoading" element-loading-spinner="none" element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="mainlitera">
      <div class="mainliteralf">
        <leftmenu :mainlefttree="mainlefttree" :subjectName="subjectName" :setSecond='setSecond'></leftmenu>
      </div>
      <div class="mainliterature">
        <div class="mainliteratureList">
          <el-skeleton :loading="loading" :rows="26" animated />
          <mainList @isLoadingShow='isLoadingShow' v-if="setSecond !== null || !token" :setSecond="setSecond"
            :Listobj="Listobj"></mainList>
        </div>
        <div class="mainliteratureHot">
          <mainright :setSecond='setSecond'></mainright>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 15px;"></div>
    <bottomdiscipline></bottomdiscipline>
  </div>
</template>

<script>
// @ is an alias to /src
import mainList from '../../components/main/mainlist'
import mainright from '../../components/main/mainright'
import leftmenu from '../../components/main/leftmenu.vue'
import bottomdiscipline from '../../components/main/bottomdiscipline.vue'
export default {
  components: { mainList, mainright, leftmenu, bottomdiscipline },
  data () {
    return {
      token: localStorage.getItem('token'),
      setSecond: null,
      subjectName: '',
      Listobj: {
        type: 0, /// 0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅
        url: ''
      },
      mainlefttree: {
        type: 0,
        url: '/literatureInfoList/count/disc',
        title: '学科领域'
      },
      secondLevelList: [],
      setSecondList: [],
      loading: true,
      isLoading: true
    }
  },
  created () {
    this.Listobj.url = '/literatureInfoList/search'
  },
  mounted () {
    // console.log('看看lodaing', this.loading)
    // this.subjectName = this.$route.query.subjectName
    // this.$store.commit('setSubjectName', this.subjectName)
    document.title = this.$route.meta.title
    this.$nextTick(() => {
      let url = document.location.href
      const q = this.getQueryObject(url)
      if (q.jwt) {
        this.$store.commit('setImage', q.photo)
        localStorage.setItem('phone', q.phone)
        this.$store.commit('userName', q.userName)
        localStorage.setItem('token', q.jwt)
        const date = new Date().getTime() + 7 * 24 * 60 * 60 * 1000
        localStorage.setItem('expiration', date)
        url = this.deleteQueryString(url, 'jwt')
        url = this.deleteQueryString(url, 'access_token')
        url = this.deleteQueryString(url, 'userName')
        url = this.deleteQueryString(url, 'photo')
        url = this.deleteQueryString(url, 'phone')
        parent.parent.history.pushState({}, null, url)
        location.reload()
      }
    })
    // 判断是否登录
    // this.getpersonal()
    // if (this.$store.state.token) {
    // this.getpersonal()
    // }
  },

  methods: {
    isLoadingShow (data) {
      this.isLoading = data
    },
    // 根据subjectName查询二级学科
    // async handleSubjectName () {
    //   // if (this.$route.query.subjectName) {
    //   //   this.subjectName = this.$route.query.subjectName
    //   //   const res = await this.$http({
    //   //     url: '/literatureInfoList/getSecondDiscByName',
    //   //     method: 'GET',
    //   //     params: {
    //   //       subjectName: this.subjectName
    //   //     }
    //   //   })
    //   //   if (res.data.data) {
    //   //     this.secondLevelList = res.data.data.map(item => {
    //   //       return item.code
    //   //     })
    //   //   }
    //   //   this.$store.commit('secondLevelList', this.secondLevelList)
    //   // }
    //   this.loading = false
    // },
    // async getpersonal () {
    //   // 查询个人信息
    //   // const { data: res } = await this.$http({
    //   //   url: '/userinfo',
    //   //   method: 'GET'
    //   // })
    //   // if (res.code === 115001 && this.$store.state.token) {
    //   //   // 删除token
    //   //   localStorage.removeItem('expiration')
    //   //   localStorage.removeItem('userImage')
    //   //   localStorage.removeItem('userName')
    //   //   localStorage.removeItem('phone')
    //   //   localStorage.removeItem('token')
    //   //   this.$store.commit('setImage', '')
    //   //   this.$store.commit('secondLevelList', [])
    //   //   this.$store.commit('firstLevelList', [])
    //   //   // this.handleSubjectName()
    //   //   return this.$message.error('登录已失效,请重新登录')
    //   // } else {
    //   //   if (res.code !== 200) {
    //   //     localStorage.removeItem('expiration')
    //   //     localStorage.removeItem('userImage')
    //   //     localStorage.removeItem('userName')
    //   //     localStorage.removeItem('phone')
    //   //     localStorage.removeItem('token')
    //   //     this.$store.commit('setImage', '')
    //   //     if (this.subjectName !== undefined) {
    //   //       this.handleSubjectName()
    //   //     } else {
    //   //       this.$store.commit('secondLevelList', [])
    //   //       this.$store.commit('firstLevelList', [])
    //   //     }

    //   //     return
    //   //   }
    //   // }

    //   // 一级学科
    //   // const firstdisciplineList = res.data.firstLevelDiscipline

    //   // // 二级学科
    //   // const seconddisciplineList = res.data.secondLevelDiscipline
    //   // const firstDisc = []
    //   // // if (this.firstdisciplineList) {
    //   // firstdisciplineList.forEach(item => {
    //   //   if (item.checked === 'true') {
    //   //     firstDisc.push({
    //   //       code: item.code,
    //   //       name: item.name
    //   //     })
    //   //   }
    //   // })
    //   // let second = []
    //   // second = firstDisc
    //   // if (seconddisciplineList) {
    //   //   seconddisciplineList.forEach(item => {
    //   //     if (item.childList) {
    //   //       item.childList.forEach(el => {
    //   //         if (el.checked === 'true') {
    //   //           second.forEach(ement => {
    //   //             if (ement.code === el.code.substring(0, 4)) {
    //   //               if (ement.childList) {
    //   //                 ement.childList.push({
    //   //                   code: el.code,
    //   //                   name: el.name
    //   //                 })
    //   //               } else {
    //   //                 ement.childList = []
    //   //                 ement.childList.push({
    //   //                   code: el.code,
    //   //                   name: el.name
    //   //                 })
    //   //               }
    //   //             }
    //   //           })
    //   //         }
    //   //       })
    //   //     }
    //   //   })
    //   // }
    //   // const firsttree = []
    //   // const secondtree = []
    //   // if (second.length) {
    //   //   second.forEach(item => {
    //   //     if (item.childList) {
    //   //       firsttree.push(item.code)
    //   //       item.childList.forEach(val => {
    //   //         secondtree.push(val.code)
    //   //       })
    //   //     }
    //   //   })
    //   // }
    //   // this.$store.commit('secondLevelList', secondtree)
    //   // this.$store.commit('firstLevelList', firsttree)
    //   // this.setSecond = secondtree
    //   this.loading = false
    //   console.log('length', this.setSecond)
    // },
    // 获取url参数 返回数组
    getQueryObject (url) {
      const str = url.replace('?', '&').split('&')
      const query = {}
      str.forEach(item => {
        if (item.includes('=')) {
          const queryArray = item.split('=')
          query[queryArray[0]] = queryArray[1]
        }
      })
      return query
    }
    // handleList(){
    //   this.$router.push('/mainDetails')
    // }
  },
  watch: {
    '$store.state.token': {
      deep: true,
      immediate: true,
      handler (newName, oldName) {
        this.token = newName
        if (!newName) {
          this.loading = false
        }
      }
    },
    '$store.state.setSecond': {
      // deep: true,
      // immediate: true,
      handler (newName, oldName) {
        this.setSecond = newName
        this.loading = false
      }
    },
    '$store.state.secondselectedTree': {
      handler () {
        this.loading = false
        // this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="less">
// 宽度小于等于920px
// @media screen and (max-width: 920px) {
//   .mainlitera {
//     width: 100%;
//     // padding:40px;
//     box-sizing: border-box;
//   }
// }
// // 宽度大于等于920px
// @media screen and (min-width: 919px) {
//   .mainlitera {
//     width: 75%;
//   }
// }
.mainlitera {
  min-height: 830px;
  display: flex;
  width: 75%;
  margin: 0 auto;

  .mainliteralf {
    width: 300px;
  }

  .mainliterature {
    display: flex;
    align-items: flex-start;
    flex: 1;

    .mainliteratureList {
      flex: 1;
      // width: 882px;
      padding: 0 20px;
      box-sizing: border-box;
    }

    .mainliteratureHot {
      width: 220px;
    }
  }
}
</style>
