<template>
  <div>
    <div class="journalTitle">
      <p>{{ authorsidedata.name }}</p>
      <span class="journalTotal">({{ total }}人)</span>
    </div>
    <div style="height:100%;overflow:scroll" v-infinite-scroll="load" infinite-scroll-disabled="busy"
      infinite-scroll-immediate='false' infinite-scroll-distance="10">
      <ul class="scroolbox">
        <li class="authorSidebottomGroup" v-for="(item, index) in authorlistSide" :key="index" ref="listscroolbox">
          <div class="authorSidebottomItem" @click="handleauthorside(item.id, item.company, item.authorCode, item)">
            <div class="authorlf">
              <span class="sidelistindex">{{ index + 1 }}</span>
              <img class="imgmiddle" :src="item.photoPrefix + item.photo" v-if="item.photoPrefix && item.photo" alt />
              <img class="imgmiddle" :src="item.photo" v-else-if="item.photo && item.photo != (ROUTER_BASE_URL + '/')"
                alt />
              <!-- <img src="../../assets/images/head/author.png" class="imgmiddle" v-else alt /> -->
              <template v-else>
                <span class="imgmiddle02" :style="randomRgb()" v-if='item.name'>{{ item.name.substring(0, 1) }}</span>
              </template>
            </div>
            <div class="authorrt">
              <div class="authorrtTitle">
                <span :title="item.name" class="authorName">{{ item.name }}</span>
                <div class="authorTrpe">
                  <span>主文献数:</span>
                  <span>{{ item.count }}</span>
                </div>
              </div>
              <div class="authorrtdiscipline overflow-dot" v-if="item.disc">
                <span :title="item02" v-for="(item02, index) in item.disc" :key="index">
                  {{ item02 }}
                  <span class="stationline"></span>
                </span>
              </div>
              <div class="authorrtdiscipline overflow-dot" v-if="!item.disc && item.discString">
                <span :title="item02" v-for="(item02, index) in item.discString" :key="index">
                  {{ item02 }}
                  <span class="stationline"></span>
                </span>
              </div>
              <div class="authorrtdiscipline overflow-dot" v-if="item.firstLevelName && !item.disc">
                <span :title="item02" v-for="(item02, index) in item.firstLevelName" :key="index">
                  {{ item02 }}
                  <span class="stationline"></span>
                </span>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </li>
        <li v-if='busy && authorlistSide.length > 0' class='busyload'>加载中...</li>
        <empty :authorlistSide="authorlistSide"></empty>
      </ul>
    </div>
  </div>
</template>

<script>
import object from 'element-resize-detector/src/detection-strategy/object'
import empty from '../../components/common/emptyside.vue'
export default {
  name: 'AuthorList',
  components: { empty },
  props: {
    authorsidedata: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      authorCode: '',
      busy: false,
      page: 1,
      pageRow: 10,
      loading: true,
      showFlag: false,
      acList: [],
      authorlistSide: [],
      total: 0,
      type: 'org'
    }
  },
  mounted () { },
  methods: {
    load () {
      if (this.total === this.authorlistSide.length) return
      this.page++
      this.getauthorside()
    },
    // 滚动加载数据
    init () {
      this.listscroolboxDom = this.$refs.listscroolbox
      var lastScrollTop = 0
      this.listscroolboxDom.addEventListener('scroll', () => {
        if (!this.allow) return
        // 滚动距离
        const scrollTop = this.listscroolboxDom.scrollTop

        // 变量windowHeight是可视区的高度
        const windowHeight =
          this.listscroolboxDom.clientHeight ||
          this.listscroolboxDom.clientHeight
        // 变量scrollHeight是滚动条的总高度
        const scrollHeight =
          this.listscroolboxDom.scrollHeight ||
          this.listscroolboxDom.scrollHeight
        if (scrollHeight - (scrollTop + windowHeight) < 10) {
          lastScrollTop = this.listscroolboxDom.scrollTop

          this.getrepresentativeauthor()
          this.allow = false
        }
        lastScrollTop = this.listscroolboxDom.scrollTop
      })
    },
    randomRgb (item) {
      const colorList = [
        '#f1af10',
        '#f1a871',
        '#23a1b8',
        '#82bcca',
        '#afb4db',
        '#78a355',
        '#df9464',
        '#7bbfea'
      ]
      const index = Math.floor(Math.random() * colorList.length)
      return {
        // backgroundImage: colorList[index]
        backgroundColor: colorList[index]
      }
    },
    // 获取作者数据
    async getauthorside () {
      this.busy = true
      if (this.authorsidedata.orgCode && this.authorsidedata.url) {
        if (this.authorsidedata.acList) {
          this.acList = this.authorsidedata.acList
        }
        if (this.authorsidedata.type) {
          this.type = this.authorsidedata.type
        }
        const { data: res } = await this.$http({
          url: this.authorsidedata.url,
          method: 'POST',
          data: {
            page: this.page,
            pageRow: this.pageRow,
            orgCode: this.authorsidedata.orgCode, // 机构名称
            authorCode: this.authorsidedata.authorCode,
            firstLevelDiscipline: this.firstenhot,
            secondLevelDiscipline: this.secondtenhot,
            hotWord: this.hotWords,
            orgName: this.hotWords,
            type: this.type,
            acList: this.acList
          }
        })
        if (res.code !== 200) return this.$message.error(res.msg)

        this.authorlistSide.push(...res.data.list)
        const map = new Map()
        this.authorlistSide = this.authorlistSide.filter(v => !map.has(v.id) && map.set(v.id, v))
        this.total = res.data.count
        this.busy = false
      }
      if (this.authorsidedata.orgName) {
        const { data: res } = await this.$http({
          url: this.authorsidedata.url,
          method: 'POST',
          data: {
            orgName: this.authorsidedata.orgName, // 机构名称
            authorCode: this.authorsidedata.authorCodem,
            current: this.page,
            size: this.pageRow

          }
        })
        if (res.code !== 200) return this.$message.error(res.msg)
        if (res.data.authorListPage) {
          this.authorlistSide.push(...res.data.authorListPage)
          this.total = res.data.sum
        } else if (res.data.rows) {
          this.authorlistSide.push(...res.data.rows)
          this.total = res.data.total
        }
        // this.total = res.data.sum
        this.busy = false
      }
      if (this.authorsidedata.orgSubj) {
        this.busy = false
        const { data: res } = await this.$http({
          url: this.authorsidedata.url,
          method: 'POST',
          data: {
            current: this.page,
            size: this.pageRow,
            firstLevelDiscipline: this.authorsidedata.firstLevelDiscipline,
            secondLevelDiscipline: this.authorsidedata.secondLevelDiscipline,
            hotWord: this.authorsidedata.hotWord,
            orgSubj: this.authorsidedata.orgSubj
          }
        })
        if (res.code !== 200) return this.$message.error(res.msg)
        res.data.representAuthorList.forEach(item => {
          item.firstLevelName = (item.firstLevelName || '').split(',')
        })
        this.authorlistSide.push(...res.data.representAuthorList)
        this.total = res.data.representAuthorCount
      }
      if (this.authorsidedata.keyWord) {
        const { data: res } = await this.$http({
          url: this.authorsidedata.url,
          method: 'POST',
          data: {
            current: this.page,
            size: this.pageRow,
            keyWord: this.authorsidedata.keyWord
          }
        })
        if (res.code !== 200) return this.$message.error(res.msg)
        res.data.rows.forEach(item => {
          item.discString = (item.discString || '').split(',')
        })
        this.busy = false
        this.authorlistSide.push(...res.data.rows)
        this.total = res.data.total
      }
      this.busy = false
      this.loading = false
      this.showFlag = true
    },
    // 点击跳转详情
    handleauthorside (id, origin, authorCode, item) {
      if (item.authorCode) {
        this.authorCode = item.authorCode
      }
      if (item.code) {
        this.authorCode = item.code
      }
      const routeData = this.$router.resolve({
        path:
          '/authorDetail?id=' +
          id +
          '&name=' +
          origin +
          '&authorCode=' +
          this.authorCode
        // query: params
      })
      window.open(routeData.href, '_blank')
    }
  },
  watch: {
    authorsidedata: {
      deep: true,
      // immediate: true,
      handler () {
        this.getauthorside()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.journalTitle {
  margin: 0 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  >p {
    font-size: 18px;
    font-weight: 500;
    color: #9c2526;
  }

  .journalTotal {
    margin-left: 10px;
    font-weight: 600;
    color: @my-color;
  }
}

.scroolbox {
  min-height: 500px;
  max-height: 710px;
  margin-left: 19px;
  width: 300px;
  // height: 100%;

  .authorSidebottomGroup {
    .authorSidebottomItem {
      display: flex;

      .authorlf {
        width: 100px;
        word-break: normal;
        white-space: nowrap;
        margin-right: 18px;

        .sidelistindex {
          padding-right: 23px;
        }

        .imgmiddle {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: cover;
        }

        .imgmiddle02 {
          cursor: pointer;
          display: inline-block;
          color: #fff;
          font-size: 20px;
          width: 60px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border-radius: 50%;
          object-fit: cover;
          vertical-align: middle;
          font-weight: 500;
        }
      }

      .authorrt {
        // margin-left: 15px;
        flex: 1;

        .authorrtTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;

          .authorName {
            cursor: pointer;
            font-size: 18px;
            font-weight: 500;
            color: #000000;
            flex: 1;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }

          .authorAddress {
            margin-left: 20px;
            font-size: 16px;
            font-weight: 400;
            color: #000000;
          }

          .authorTrpe {
            min-width: 60px;
            display: flex;
            align-items: flex-end;
            right: 1px;
            border: 1px solid #e6e6e6;
            border-radius: 2px;
            padding: 4px 10px;
            font-size: 12px;
            font-weight: 400;
            color: @my-color;
            // margin-right: 0.052vw;
          }
        }

        .authorrtdiscipline {
          width: 190px;
          margin: 12px 0;
        }

        .authorrtdiscipline span {
          .stationline {
            display: inline-block;
            width: 2px;
            height: 16px;
            background: #cccccc;
            margin: 0 9px;
            vertical-align: text-bottom;
          }
        }

        .authorrtdiscipline span:last-child {
          .stationline {
            width: 0;
          }
        }
      }
    }

    .line {
      width: 100%;
      height: 1px;
      margin: 15px 0;
      background: #e6e6e6;
      border-radius: 1px;
    }
  }
}

.busyload {
  height: 60px;
  text-align: center;
  color: #9c2526;
}
</style>
