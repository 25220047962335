<template>
  <div class="content">
    <div class="head">
      <img src="../../assets/images/home/briefIntroduction.jpg" alt="" />
      <div class="contentTop">
        <div class="briefIntroduction">
          <div class="title fontColor">年度中国十大学术热点简介</div>
          <div class="context">
            中国十大学术热点评选活动，是自2003年起由中国人民大学书报资料中心、
            光明日报社理论部、学术月刊编辑部共同主办的年度学术活动。
          </div>
          <el-popover ref='popper' popper-class='popper' placement="bottom" width="300" trigger="click">
            <div>
              中国十大学术热点评选活动，是自2003年起由中国人民大学书报资料中心、
              光明日报社理论部、学术月刊编辑部共同主办的年度学术活动。
              学术热点评选遵循三条基本原则：
            </div>
            <!-- <div> -->
            (1)本年度相对比较集中探讨的前沿性学术问题；<br />
            (2)对未来学术研究具有一定导向性和前瞻性的学术问题；<br />
            (3)具有一定公众关注度的学术问题。<br />
            <!-- </div> -->
            <div>
              评选活动程序包括年中热点预判、条目推荐征集、课题组文献调研、撰写备选条目、学界意见征询、课题组内部研讨、专家研讨、学者评议、投票确定和正式发布等。
              年度中国十大学术热点评选旨在梳理中国哲学社会科学年度学术成就，聚集学术热点议题，记录学术发展轨迹，引领中国重大学术理论创新、实践创新，营造“社会关注学术，学术贴近社会”的良好学术发展氛围，推动中国自主的知识体系建构。
            </div>
            <div class="expand fontColor" slot="reference" @click="goExpand">
              {{ "展开全部" }}&nbsp;>
            </div>
          </el-popover>

        </div>
        <div class="swiper-container" ref="bannerContain">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in swiperData" :key="index">
              <!-- <el-image :preview-src-list="srcList" class="swimg" :src="item.url"></el-image> -->
              <img class="swimg" :src="item.url" :large="item.url" preview="0" alt="">
              <div class="swYear">{{ item.year }}</div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <div class="swiper"></div>
    <div class="tenlist">
      <div class="tenlistlf">
        <authortree :treeType="treeType" @year="yearList"></authortree>
      </div>
      <div class="tenlistrt">
        <div class="searchBox">
          <el-input placeholder="请输入学术热点搜索" class="searchInput" v-model="searchDatas.name" clearable></el-input>
          <div class="searchBtn" @click="searchBtn('oneSeach')">
            <!-- <span class="seacrhIcon"></span> -->
            <span class="seacrhtxt">结果中搜索</span>
          </div>
        </div>
        <div v-if="dataList.length">
          <tenlistrt v-for="(item, index) in dataList" :key="index" :item="item" :index="index"></tenlistrt>
        </div>
        <div class="pageBox" v-if="dataList.length">
          <!-- <div class="listgroupLine"></div> -->
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="searchData.page" :page-sizes="[10, 20, 30, 40]" :page-size="searchData.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
        <div class="empty" v-else>
          <img src="../../assets/images/home/nodataside.png" alt />
          <span>暂无数据</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import authortree from '../../components/author/authorTree'
import tenlistrt from '../../components/main/tenlistrt'
export default {
  components: {
    authortree,
    tenlistrt
  },
  // props: {
  //   isInfo:{
  //     default: false
  //   }
  // },
  data () {
    return {
      srcList: [], // 点击查看大图
      treeType: {
        url: '/academic/getAcademicYearList', // 入选年份
        title: '入选年份'
      },
      searchData: {
        name: '', // 搜索条件
        page: 0, // 分页页码
        pageSize: 10, // 分页大小
        years: [] // 入选年份
      },
      searchDatas: {
        name: '', // 搜索条件
        page: 0, // 分页页码
        pageSize: 10, // 分页大小
        years: [] // 入选年份
      },
      oldSeachData: {},
      total: 0,
      allPages: 0, // 总页数
      dataList: [],
      swiperData: [],
      // 是否展开
      isExpand: false
    }
  },
  created () {
    this.search()
  },
  mounted () {
    // const previewContainer = document.querySelector('.pswp')
    // if (previewContainer) {
    //   previewContainer.style.width = '100vw'
    //   previewContainer.style.height = '100vh'
    // }
    document.title = '年度中国十大学术热点' + this.$route.meta.title
    document.onkeydown = (e) => {
      var theEvent = window.event || e
      var code = theEvent.keyCode || theEvent.which || theEvent.charCode
      if (
        code == 13 &&
        document.activeElement.parentNode.className.indexOf('searchInput') != -1
      ) {
        this.search('oneSeach')
      }
    }
    this.getSwiperData()
    this.handleImg()
  },

  methods: {
    handleImg () {
      // console.log(this.swiperData)
      // console.log(item)
      this.srcList = this.swiperData.map(item => {
        return item.url
      })
      // this.$refs.elImageRef.showViewer = true
    },
    // 搜索
    searchBtn (flag) {
      if (flag == 'oneSeach') {
        // 如果是点击了搜索按钮获取的数据重置分页和数据状态
        this.dataList = []
        this.searchData.page = 1
        this.searchData.pageSize = 10
      }
      window.scrollTo({
        top: 0
      })
      this.searchData = JSON.parse(JSON.stringify(this.searchDatas))
      // this.searchData.name = this.searchData.name.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      this.search()
    },
    async search () {
      const { data: res } = await this.$http({
        url: '/academic/search',
        method: 'post',
        data: this.searchData
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.dataList = res.data.records
      this.oldSeachData = this.searchData
      this.allPages = res.data.pages
      this.total = res.data.total
    },
    // pagesize改变触发
    handleSizeChange (val) {
      this.searchData.page = 1
      this.searchData.pageSize = val
      this.searchData = this.oldSeachData
      this.search()
    },
    // currentChange 改变触发
    handleCurrentChange (val) {
      this.searchData.page = val
      this.searchData = this.oldSeachData
      this.search()
    },
    // 选中的年份
    yearList (e) {
      this.searchData.page = 1
      this.searchData.years = e
      this.searchDatas.years = e
      this.search()
    },
    // 获取幻灯轮显的数据
    getSwiperData () {
      const swiperData = []
      for (let year = 2013; year <= 2023; year++) {
        swiperData.push({
          year,
          url: require(`@/assets/images/main/tensciencelist/swiper/${year}年.jpg`)
        })
      }
      this.swiperData = swiperData
      // this.$nextTick(() => {

      // })

      this.init()
    },
    init () {
      this.$nextTick(() => {
        new Swiper('.swiper-container', {
          loop: true,
          observer: true, // 修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, // 修改swiper的父元素时，自动初始化swiper
          slidesPerView: 7,
          spaceBetween: 14,
          autoplay: {
            delay: 2000,
            disableOnInteraction: false // 解决滑动后不能轮播的问题
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
          //   on: {
          //   click: function (e) {
          //      console.log('e',e );
          //      if(e.target.currentSrc!=null) {
          //       window.open(e.target.currentSrc)
          //      }
          //   },
          // }
        })
      })
    },
    // 展开&收起
    goExpand () {
      // this.$refs.popper.style = 'z-index:10'
      // document.querySelector('.el-popover').style.zIndex = '10'
      // console.log(document.querySelector('.el-popover'))
      const context = document.querySelector('.briefIntroduction .context')
      if (this.isExpand) {
        this.isExpand = false
        // context.style.height = "57px";
      } else {
        this.isExpand = true
        // context.style.height = "auto";
      }
    }
  },
  watch: {
    // "$store.state.firstselectedTree": {
    //   deep: true,
    //   handler(data) {
    //     this.firstselectedTree = data;
    //   }
    // },
  }
}
</script>
<style scoped lang="less">
// /deep/.pswp__img {
//   width: 100px !important;
//   height: 100px !important;
// }

.head {
  height: 366px;
  background: #f7f7f7;

  img {
    width: 100%;
    height: 120px;
  }
}

// 十大学说热点简介start
.briefIntroduction {
  width: 273px;
  height: 166px;
  margin-top: 46px;
  margin-right: 45px;

  .fontColor {
    color: #9c2526;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
  }

  .context {
    overflow: hidden;
    // height: 60px;
    line-height: 25px;
    text-indent: 2em;
    padding: 10px 0;
  }

  .expand {
    cursor: pointer;
  }
}

//十大学术热点简介end

.tenlist {
  width: 1440px;
  margin: 0 auto;
  display: flex;
  margin-top: 20px;

  .tenlistlf {
    width: 300px;
    height: 684px;
  }

  .tenlistrt {
    flex: 1;
    width: 1111px;
    padding: 0 10px;

    .searchBox {
      display: flex;
      align-items: center;
      height: 60px;
      padding-bottom: 6px;
      border-bottom: 1px solid #e6e6e6;

      .el-input {
        width: 400px;
      }

      .searchBtn {
        text-align: center;
        width: 100px;
        height: 40px;
        line-height: 38px;
        background: @my-color;
        border-radius: 4px;
        margin-left: 10px;

        .seacrhtxt {
          cursor: pointer;
          color: #fff;
          width: 100%;
          text-align: center;
          // padding-left: 10px;
        }
      }
    }

    .pageBox {
      margin-bottom: 20px;

      .listgroupLine {
        width: 100%;
        height: 1px;
        background: #e6e6e6;
        border-radius: 1px;
        margin: 15px 0;
      }
    }

    .empty {
      margin-top: 65px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        width: 232px;
        height: 114px;
      }

      span {
        color: #999;
        font-size: 14px;
      }
    }
  }
}

// 幻灯轮显start
/deep/.swiper-button-next,
.swiper-button-prev {
  width: 40px;
  height: 40px;
  background: #e0e0e0;
  border-radius: 50%;
}

/deep/.swiper-button-next {
  top: 50%;
  transform: translateY(-50%);
  right: -12px;
  // margin-right: 9px;
  color: rgba(255, 255, 255, 0);
  background-image: url("../../assets/images/home/next.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 30% 40%;
  /* 设置左箭头图标 */
}

/deep/.swiper-button-prev {
  top: 50%;
  transform: translateY(-50%);
  left: 300px;
  color: rgba(0, 0, 0, 0);
  background-image: url("../../assets/images/home/prev.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 30% 40%;
}

/deep/.swiper-button-prev:hover {
  background: #9c2526;
  background-image: url("../../assets/images/home/prev.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 30% 40%;
}

/deep/.swiper-button-next:hover {
  background: #9c2526;
  background-image: url("../../assets/images/home/next.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 30% 40%;
}

.swiper-container {
  margin-top: 20px;
  width: 1050px;
  height: auto;

  .swiper-slide {
    padding: 8px;
    background: #ffffff;
    border-radius: 4px;
    width: 137px;
    height: 206px;
    // margin-top: 3px;
    box-sizing: border-box;
    // margin: 20px 0;
    position: relative;
    bottom: 10px;

    .swimg {
      margin-top: 5px;
      width: 120px;
      height: 168px;
      border-radius: 4px;
    }

    .swYear {
      // height: 40px;
      // line-height: 40px;
      text-align: center;
      margin-top: 7px;
    }

    .totaltag {
      margin-top: 12px;

      .subtag {
        padding: 4px 7px 4px 9px;
        background: #f7f7f7;
        border-radius: 4px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9c2526;
        margin-left: 10px;
        display: inline-block;
        white-space: nowrap;
        // width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .subtag:first-child {
        margin-left: 0px;
      }
    }
  }
}

.contentTop {
  display: flex;
  width: 1440px;
  margin: 0 auto;
  position: relative;
  height: 246px;
}

// 幻灯轮显end</style>
<style>
.popper {
  z-index: 999 !important;
}

.pswp__zoom-wrap {
  transform: translate(0) !important;
}

.pswp__img {
  position: absolute !important;
  /* width: 450px !important;
  height: 100vh !important; */
  left: 50% !important;

  transform: translate(-50%, 10px) !important;
}
</style>
