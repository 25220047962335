<template>
  <div class="disciplinedetailBox">
    <div class="disciplinedetailTop">
      <div class="toplf">
        <img :src="url + cover" v-if="cover" class="imgcover" alt />
        <!-- <img src="../../assets/images/home/xuekedetail.png" v-else alt class="imgcover" /> -->
      </div>
      <div class="toprt">
        <div class="toprtTitle">{{ name }}</div>
        <div class="subtitle">{{ english }}</div>
        <div class="toprtword">
          <div class="people">提出者：</div>
          <div class="detail">
            <div class="toprtwordfl" v-for="(item, index) in proposerList" :key="index">
              <span v-if="item.author" class="fonthover" @click="goAuthor(item.author)">{{ item.name }}</span>
              <span v-else>{{ item.name }}</span>
              <span v-if="item.yearOfBirth || item.unit || item.position">（</span>
              <span v-if="item.yearOfBirth">出生年份：{{ item.yearOfBirth }}；</span>
              <span v-if="item.unit">
                当前单位/退休前单位：
                <span v-if="item.organization" class="fonthover" @click="goOrgan(item.organization)">{{ item.unit
                  }}</span>
                <span v-else>{{ item.unit }}</span>
                ；
              </span>
              <span v-if="item.position">
                当前职务/职称：{{
                  item.position
                }}
              </span>
              <span v-if="item.yearOfBirth || item.unit || item.position">）</span>
            </div>
          </div>
        </div>
        <div class="theme">提出时间：{{ proposedDate }}</div>
      </div>
    </div>
    <div class="middlemenu" :class="{ fixed: isFixed === true }">
      <!-- <template v-if="Listobj.relatedLiteratureIdList"></template> -->
      <div class="channel-item" :class="{ active: activeName == item.value }" v-for="(item, i) in channelList" :key="i"
        @click="handleClick(item.value)">{{ item.label }}</div>
    </div>

    <el-tabs v-model="activeName">
      <el-tab-pane label="概念详情" name="first">
        <OriginalInfo :msg="msg" v-if="isOriginal" @OriginalDeatilPost='OriginalDeatilPost'></OriginalInfo>
      </el-tab-pane>
      <el-tab-pane label="概念热议" name="second">
        <comment v-if="isComment"></comment>
      </el-tab-pane>
      <el-tab-pane label="相关主文献" name="third">
        <mainList :Listobj="Listobj" @originalNum="handleoriginalNum"></mainList>
      </el-tab-pane>
    </el-tabs>
    <div class="empty"></div>
  </div>
</template>
<script>
import mainList from '../../components/main/mainlist'
import hotEchart from '../../components/main/hotEchart'
import comment from '../../components/commentoriginal/list'
import OriginalInfo from '../../components/main/originalInfo'
export default {
  components: { mainList, hotEchart, comment, OriginalInfo },
  data () {
    return {
      res_: {},
      isFixed: false,
      name: '',
      literatureNum: 0,
      themeWord: '',
      themeWordarray: [],
      cover: '',
      channelList: [],
      // channelList: [
      //   {
      //     label: "概念详情",
      //     value: "first"
      //   },
      //   {
      //     label: "概念热议",
      //     value: "second"
      //   },
      //   {
      //     label: "相关主文献",
      //     value: "third"
      //   }
      // ],
      activeName: 'first',
      isComment: false,
      obj: '',
      english: '',
      proposerList: '',
      proposedDate: '',
      Listobj: {
        type: 9, /// 0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅 9-原创概念
        url: '/cn_academic_concept/search/literature',
        relatedLiteratureIdList: [],
        rnList: []
      },
      direction: '',
      tabtitle: false,
      msg: '',
      isOriginal: true
    }
  },
  mounted () {
    this.handleoriginalNum()
    this.id = this.$route.query.id
    // this.getdisciplinedetail()
    window.addEventListener('scroll', this.fixedActiveBtn)
  },
  methods: {
    OriginalDeatilPost (data) {
      console.log('抛出来的data', data)
      this.res_ = data
      this.Listobj.relatedLiteratureIdList = this.res_.relatedLiteratureIdList
      this.Listobj.rnList = this.res_.relatedLiteratureIdList
      this.name = this.res_.conceptName
      document.title = this.name + this.$route.meta.title
      this.english = this.res_.conceptEnglish
      this.proposedDate = this.res_.proposedDate
      this.cover = this.res_.conceptCoverUrl
      this.url = this.res_.filePrefix
      this.proposerList = this.res_.proposerList
      this.obj = this.res_
    },
    goAuthor (author) {
      const routeData = this.$router.resolve({
        path: '/authorDetail?id=' + author.id + '&name=' + author.orgCode + '&authorCode=' + author.authorCode
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    goOrgan (org) {
      const routeData = this.$router.resolve({
        path: '/institutiondetail?name=' + org.orgCode + '&page=1&pageRow=10'
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    handleoriginalNum (data) {
      if (!data) {
        this.channelList = [
          {
            label: '概念详情',
            value: 'first'
          },
          {
            label: '概念热议',
            value: 'second'
          }
        ]
      } else {
        this.channelList = [
          {
            label: '概念详情',
            value: 'first'
          },
          {
            label: '概念热议',
            value: 'second'
          },
          {
            label: '相关主文献',
            value: 'third'
          }
        ]
      }
    },
    fixedActiveBtn () {
      if (this.activeName == 'first') {
        const topheight = document.querySelector('.disciplinedetailTop')
          .clientHeight
        const marg = parseInt(
          getComputedStyle(document.querySelector('.middlemenu')).marginTop
        )
        const margbbom = parseInt(
          getComputedStyle(document.querySelector('.middlemenu')).marginBottom
        )
        this.msg = topheight + marg + margbbom
        const scrollTop = document.documentElement.scrollTop
        scrollTop >= topheight + marg + margbbom
          ? (this.isFixed = true)
          : (this.isFixed = false)
      }
    },
    // async getdisciplinedetail () {
    //   const { data: res } = await this.$http({
    //     url: '/cn_academic_concept/find_one/' + this.id,
    //     method: 'get'
    //   })
    //   if (res.code != 200) return this.$message.error(res.msg)
    //   this.Listobj.relatedLiteratureIdList = res.data.relatedLiteratureIdList
    //   this.name = res.data.conceptName
    //   document.title = this.name + this.$route.meta.title
    //   this.english = res.data.conceptEnglish
    //   this.proposedDate = res.data.proposedDate
    //   this.cover = res.data.conceptCoverUrl
    //   this.url = res.data.filePrefix
    //   this.proposerList = res.data.proposerList
    //   this.obj = res.data
    // },
    handleClick (index) {
      this.isFixed = false
      window.scrollTo(0, 0)
      if (index == 'first') {
        this.isComment = false
        this.isOriginal = true
      }
      if (index == 'second') {
        this.isComment = true
        this.isOriginal = false
      }
      if (index == 'third') {
        this.isComment = false
        this.isOriginal = false
      }
      this.activeName = index
    }
  }
}
</script>
<style lang="less" scoped>
.fixed {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 99;
}

.disciplinedetailBox {
  width: 1440px;
  min-height: 580px;
  margin: 0 auto;

  .disciplinedetailTop {
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    padding: 20px;

    .toplf {
      position: relative;

      .imgcover {
        width: 302px;
        height: 180px;
        border-radius: 4px;
        object-fit: cover;
        overflow: hidden;
      }
    }

    .toprt {
      margin-left: 21px;
      width: 1060px;
      word-wrap: break-word;

      .toprtTitle {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
      }

      .subtitle {
        margin-top: 9px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
      }

      .theme {
        margin-top: 18px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }

      .toprtword {
        margin-top: 19px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        display: flex;

        .people {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          white-space: nowrap;
        }

        .detail {
          max-height: 80px;
          overflow-y: scroll;

          .toprtwordfl {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;

            .fonthover:hover {
              color: @my-color;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .middlemenu {
    margin: 20px auto;
    width: 1421px;
    height: 57px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding-left: 19px;
    padding-bottom: 3px;
    display: flex;

    .channel-item {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 60px;
      margin-right: 50px;
      cursor: pointer;
    }

    .active {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: @my-color;
      border-bottom: 3px solid @my-color;
    }
  }

  .middlemenu.fixed {
    margin: 0 auto;
    width: 1421px;
    height: 57px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding-left: 19px;
    padding-bottom: 3px;
    display: flex;
    position: fixed;
    top: 68px;

    .channel-item {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 60px;
      margin-right: 50px;
      cursor: pointer;
    }

    .active {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: @my-color;
      border-bottom: 3px solid @my-color;
    }
  }

  /deep/ .el-tabs__nav {
    display: none;
  }

  /deep/.el-tabs__header {
    margin: 0 !important;
  }

  .el-tabs {
    width: 1440px;
  }
}
</style>
