<template>
  <el-dialog class="dialog" :title="'共' + total + '条回复'" :visible.sync="showDialog" :close-on-press-escape="false"
    :close-on-click-modal="false" width="60%" @close="handleclose">
    <div class="scroll">
      <div class="item">
        <div class="avator">
          <img v-if="firstComment.userImg" :src="imgPrefix + firstComment.userImg" alt />
          <img v-else :src="avator" alt />
        </div>
        <div class="_right">
          <div class="username">
            <span v-if="firstComment.isAnonymous == 0">{{ firstComment.userName }}</span>
            <span v-else>匿名用户</span>
            <i class="author" v-if="firstComment.userFlag == '1'">作者</i>
          </div>
          <div class="content">
            {{ firstComment.comment }}
            <i class="state shz" v-if="firstComment.state == 0"></i>
            <i class="state wtg" v-if="firstComment.state == 2"></i>
          </div>
          <div class="bar">
            <div class="bar-left">{{ firstComment.createTime }}</div>
            <div class="bar-right">
              <div @click="handleDetail(firstComment, 0)" v-if="firstComment.myself == '1'">
                <i class="icon delete"></i>
                <span>删除</span>
              </div>
              <div @click="handleReplayClick(firstComment, 0)">
                <i class="icon hf" :class="{ active: applyIndex == 0 && applyIndex2 == 0 }"></i>
                <span v-if="applyIndex == 0 && applyIndex2 == 0">取消回复</span>
                <span v-else>回复</span>
              </div>
              <div @click="handleLike(firstComment, 0)">
                <i class="icon dz" :class="{ active: firstComment.isLike == 1 }"></i>
                <span>{{ firstComment.isLikes }}</span>
              </div>
            </div>
          </div>
          <send :ref="'send' + 0" :opts="sendOpts" :edit="sendedit" @emitSendSuccess="emitSendSuccess"
            :closeComment="closeComment"></send>
        </div>
      </div>
      <div class="children">
        <div class="item" v-for="(item, i) in list" :key="i">
          <div class="avator">
            <img v-if="item.userImg" :src="imgPrefix + item.userImg" alt />
            <img v-else :src="avator" alt />
          </div>
          <div class="_right">
            <!-- <div class="username">
              <span v-if="item.isAnonymous==0">{{item.userName}}</span>
              <span v-else>匿名用户</span>
              <i class="author" v-if="item.userFlag=='1'">作者</i>
            </div>-->

            <div class="username">
              <span class="_u">
                {{ item.userName }}
                <i class="author" v-if="item.userFlag == '1'">作者</i>
              </span>
              <span class="_t">回复</span>
              <span class="_u">
                <span>{{ item.replyUserName }}</span>
                <!-- <span>匿名用户</span> -->
                <i class="author" v-if="item.replayUserAuthor">作者</i>
              </span>
            </div>
            <div class="content">
              {{ item.comment }}
              <i class="state shz" v-if="item.state == 0"></i>
              <i class="state wtg" v-if="item.state == 2"></i>
              <el-popover placement="top-start" trigger="hover" width="200" popper-class="popoverStyle"
                v-if="item.state == 2 && item.rejectReason">
                <div class="popcontent">
                  <i class="rejectReason"></i>
                  <p style="flex:1;">{{ item.rejectReason }}</p>
                </div>
                <i slot="reference" class="state wtgreason"></i>
              </el-popover>
              <!-- <span class="state wtgreason" v-if="item.state == 2">
                <div class="wtgreasonpop" v-if="item.rejectReason">
                  <div class="wtgreasonpopBox">
                    <i class="rejectReason"></i>
                    <p>{{ item.rejectReason }}</p>
                  </div>
                </div>
              </span> -->
            </div>
            <div class="bar">
              <div class="bar-left">{{ item.createTime }}</div>
              <div class="bar-right">
                <div @click="handleEdit(item, i)" v-if="item.state == 2 && item.myself == '1'">
                  <i class="icon edit" :class="{ active: applyIndex == i && applyIndex2 == -1 }"></i>
                  <span v-if="applyIndex == i && applyIndex2 == -1">取消编辑</span>
                  <span v-else>编辑</span>
                </div>
                <div @click="handleDetail(item, i)" v-if="item.myself == '1'">
                  <i class="icon delete"></i>
                  <span>删除</span>
                </div>
                <div @click="handleReplayClick2(item, i)" v-if="item.state != 2 && item.state != 0">
                  <i class="icon hf" :class="{ active: applyIndex == i && applyIndex2 == -1 }"></i>
                  <span v-if="applyIndex == i && applyIndex2 == -1">取消回复</span>
                  <span v-else>回复</span>
                </div>
                <div @click="handleLike(item, i)" v-if="item.state != 0 && item.state != 2">
                  <i class="icon dz" :class="{ active: item.isLike == 1 }"></i>
                  <span>{{ item.isLikes }}</span>
                </div>
              </div>
            </div>
            <send :ref="'send_' + i" :opts="sendOpts" :edit="sendedit" @emitSendSuccess="emitSendSuccess"
              :closeComment="closeComment">
            </send>
          </div>
        </div>
      </div>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageRow"
      layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
  </el-dialog>
</template>

<script>
import send from './send.vue'
export default {
  components: {
    send
  },
  data () {
    return {
      // 回复索引
      applyIndex: -1,
      applyIndex2: -1,
      // 头像前缀
      imgPrefix: '',
      // 默认头像
      avator: require('@/assets/images/main/comment/touxiang.png'),
      // 显示评论弹窗
      showDialog: false,
      // 评论总数
      applayCount: 0,
      firstComment: {},
      // 数据
      list: [],
      // 当前页
      currentPage: 1,
      pageRow: 10,
      // 总条数
      total: 0,
      // 评论列表参数
      sendOpts: {},
      sendedit: {},
      // 关闭子评论(时间戳)
      closeComment: 0,
      id: '', // 查看更多的id
      referenceNumber: ''
    }
  },
  mounted () {
    this.subjectId = this.$route.query.id
  },
  methods: {
    // 调取父组件方法
    handleclose () {
      this.showDialog = false
      this.$emit('emitlist')
    },
    // 获取二级评论
    async getData (id) {
      const { data: res } = await this.$http({
        url: 'subjectComment/loadSecondComment',
        method: 'post',
        data: {
          current: this.currentPage,
          size: this.pageRow,
          id
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.showDialog = true
      this.list = res.data.secComments || []
      this.total = res.data.total
      this.imgPrefix = res.data.imgPrefix
      this.firstComment = res.data.firstComment
    },
    init (id) {
      this.id = id
      this.getData(id)
    },
    // 点击回复
    handleReplayClick (item, index) {
      console.log('888', item)
      // 先关闭其他评论
      this.closeComment = new Date().getTime()
      if (this.applyIndex == index) {
        this.applyIndex = -1
        this.applyIndex2 = -1
        return
      }
      this.applyIndex = index
      this.applyIndex2 = 0
      const userName = item.isAnonymous == 0 ? item.userName : '匿名用户'
      this.sendOpts = {
        parentId: this.firstComment.id,
        replyCommentId: item.id
      }
      this.$nextTick(() => {
        this.$refs['send' + index].init('replay', userName)
      })
    },
    // 点击子评论回复
    handleReplayClick2 (item2, index2) {
      // console.log("999", item2, index2);
      this.closeComment = new Date().getTime()
      if (this.applyIndex == index2) {
        this.applyIndex = -1
        this.applyIndex2 = -1
        return
      }
      this.applyIndex = index2
      this.applyIndex2 = -1
      const userName = item2.isAnonymous == 0 ? item2.userName : '匿名用户'
      this.sendOpts = {
        parentId: this.firstComment.id,
        replyCommentId: item2.id
      }
      this.$nextTick(() => {
        this.$refs['send_' + index2][0].init('replay', userName)
      })
    },
    // 分页
    handleSizeChange (val) {
      this.pageRow = val
      this.getData(this.id)
    },
    handleCurrentChange (val) {
      console.log(val)
      this.currentPage = val
      this.getData(this.id)
    },
    // 监听评论成功
    emitSendSuccess () {
      this.showDialog = false
      this.applyIndex = -1
      this.applyIndex2 = -1
      this.$emit('emitSendSuccess')
    },
    // 删除评论
    async getDelete (item) {
      const { data: res } = await this.$http({
        url: 'subjectComment/delete',
        method: 'post',
        data: {
          id: item.id,
          parentId: item.parentId
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.getData(this.id)
      // this.$emit("emitSendSuccess");
    },
    // 删除二级评论
    handleDetail (item) {
      this.$confirm('您确定要删除当前评论吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.getDelete(item)
        })
        .catch(() => { })
    },
    // 编辑
    handleEdit (item, index) {
      this.closeComment = new Date().getTime()
      if (this.applyIndex == index) {
        this.applyIndex = -1
        this.applyIndex2 = -1
        return
      }
      this.applyIndex = index
      const userName = item.isAnonymous == 0 ? item.userName : '匿名用户'
      this.sendedit = {
        id: item.id,
        textarea: item.comment,
        anonymous: item.isAnonymous,
        parentId: item.parentId,
        replyCommentId: item.replyCommentId,
        referenceNumber: item.referenceNumber
      }
      this.$nextTick(() => {
        this.$refs['send_' + index][0].init(
          'edit',
          userName,
          item.id,
          item.comment
        )
      })
    },
    // 点赞 & 取消点赞
    async handleLike (item, i) {
      console.log('88', item, i)
      const { data: res } = await this.$http({
        url: '/subjectComment/commentLike',
        method: 'post',
        data: {
          id: item.id,
          type: item.isLike == 0 ? 1 : 0,
          subjectId: this.subjectId
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.getData(this.id)
    }
  }
}
</script>

<style lang="less" scoped>
.popcontent {
  display: flex;
  max-height: 260px;
  overflow-y: scroll;
  background-color: #9c2526;
  color: #fff;

  .rejectReason {
    margin-right: 10px;
    vertical-align: bottom;
    width: 16px;
    height: 19px;
    display: inline-block;
    vertical-align: bottom;
    // margin-left: 10px;
    background: url("../../assets/images/main/comment/rejectReasonicon.png") no-repeat center;
    background-size: contain;
  }
}

.dialog {
  /deep/.el-dialog__title {
    font-size: 22px;
    color: 000;
    font-weight: 700;
  }

  /deep/.el-dialog__body {
    padding-top: 10px;
  }
}

.scroll {
  height: 60vh;
  overflow-y: auto;
  padding-right: 10px;
}

.item {
  margin-top: 30px;
  display: flex;

  .avator {
    width: 60px;
    height: 60px;
    object-fit: contain;
    border-radius: 50%;

    >img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  ._right {
    flex: 1;
    margin-left: 20px;

    .username {
      color: #000;
      font-size: 16px;
      margin-top: 8px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;

      .author {
        font-style: normal;
        height: 18px;
        background: #fabd49;
        border-radius: 4px;
        color: #fff;
        line-height: 18px;
        padding: 0 8px;
        text-align: center;
        margin-left: 10px;
        box-sizing: border-box;
        font-size: 12px;
      }
    }

    .content {
      position: relative;
      font-size: 16px;
      line-height: 24px;
      word-wrap: break-word;

      .state {
        width: 74px;
        height: 25px;
        display: inline-block;
        vertical-align: bottom;
        margin-left: 10px;

        &.shz {
          background: url("../../assets/images/main/comment/shenhezhong.png") no-repeat center;
          background-size: contain;
        }

        &.wtg {
          background: url("../../assets/images/main/comment/weitongguo.png") no-repeat center;
          background-size: contain;
        }
      }

      .wtgreason {
        position: relative;
        width: 18px;
        height: 18px;
        display: inline-block;
        vertical-align: bottom;
        margin-left: 10px;
        background: url("../../assets/images/main/comment/question.png") no-repeat center;
        background-size: contain;

        .wtgreasonpop {
          color: #fff;
          display: none;
          min-width: 160px;
          max-width: 260px;
          padding: 20px;
          background-color: @my-color;
          position: absolute;
          top: 20px;
          left: 20px;
          z-index: 2;
          min-height: 50px;
          border-radius: 10px;

          .wtgreasonpopBox {
            display: flex;

            .rejectReason {
              margin-right: 10px;
              vertical-align: bottom;
              width: 16px;
              height: 19px;
              display: inline-block;
              vertical-align: bottom;
              margin-left: 10px;
              background: url("../../assets/images/main/comment/rejectReasonicon.png") no-repeat center;
              background-size: contain;
            }

            >p {
              flex: 1;
            }
          }
        }
      }

      .wtgreason:hover {
        .wtgreasonpop {
          display: block;
        }
      }
    }

    .bar {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .bar-left {
        color: #999;
      }

      .bar-right {
        display: flex;
        align-items: center;
        color: #000000;

        >div {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-left: 28px;

          .icon {
            display: inline-block;
            margin-right: 10px;

            &.hf {
              width: 18px;
              height: 16px;
              background: url("../../assets/images/main/comment/reply.png") no-repeat center;
              background-size: contain;

              &.active {
                background: url("../../assets/images/main/comment/reply-act.png") no-repeat center;
                background-size: contain;
              }
            }

            &.dz {
              width: 17px;
              height: 17px;
              background: url("../../assets/images/main/comment/like.png") no-repeat center;
              background-size: contain;

              &.active {
                background: url("../../assets/images/main/comment/like-act.png") no-repeat center;
                background-size: contain;
              }
            }

            &.delete {
              width: 17px;
              height: 17px;
              background: url("../../assets/images/main/comment/delete.png") no-repeat center;
              background-size: contain;
            }

            &.edit {
              width: 17px;
              height: 17px;
              background: url("../../assets/images/main/comment/edit.png") no-repeat center;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}

.children {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 16px;

  .item {
    margin-top: 0;
    margin-bottom: 20px;

    .avator {
      padding-top: 15px;
    }

    ._right {
      border-top: 1px solid #e3e3e6;
      padding-top: 15px;
    }

    ._u {
      font-size: 16px;
      color: #000;
      display: flex;
      align-items: center;
    }

    ._t {
      font-size: 16px;
      color: #666;
      margin: 0 13px;
      display: flex;
      align-items: center;
    }

    &.item:nth-of-type(1) {
      .avator {
        padding-top: 0;
      }

      ._right {
        border: none;
        padding-top: 0;
      }
    }
  }

  .more {
    font-size: 16px;
    color: #9c2526;
    cursor: pointer;
  }

  /deep/textarea {
    border: 1px solid #dcdfe6;
    background: #fff;
  }
}
</style>
