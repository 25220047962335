<template>
  <div class="subscribeBox">
    <!-- <div class="subscribelf">
      <div class="listgroup">
        <div class="listgroupLine"></div>
        <div class="listgroupTitleBox">
          <div>
            <span class="listgroupTitle">马克思哲学中“认识论断裂”意蕴探析</span>
            <span class="listgroupTitleType">原发刊</span>
          </div>
          <div>
            <el-button type="primary">主要按钮</el-button>
          </div>
        </div>
        <div class="listgroupnameBox">
          <span class="listgroupname">张哲宇</span>
          <span class="stationdrop">·</span>
          <span class="listgroupname">中国人民大学</span>
          <span class="stationline"></span>
        </div>
        <div class="listgroupTxt">完善自我、发展自我、超越自我是马克思主义哲学的辩证本性和实践品格的要求，只有扎根于社会实践，倾听实践的呼声，汲取实践的智慧，接</div>
        <div class="keyword">
          <span>关键词：</span>
          <span>马克思主义哲学:创新:实现: 原则</span>
        </div>
        <div class="keywordtype">
          <span>马克思哲学</span>
        </div>
        <div class="listBotttom">
          <div class="listBotttomLF">
            <span class="listBotttomtxt">文献编号：</span>
            <span class="listBotttoomActive">222</span>
            <span class="stationline"></span>
            <span class="listBotttomtxt">发表年份：2023</span>
          </div>
          <div class="listBotttomrf">
            <div class="listBotttomItem">
              <img src="../../assets/images/home/Pilot.png" alt />
              <span class="listBotttomItemactive">1223</span>
            </div>
          </div>
        </div>
      </div>
      <div class="pageBox">
        <div class="listgroupLine"></div>

        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageRow"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>-->
    <!-- <div class="subscribert">
      <div class="scienceBox">
        <div class="sciencewordtitle">兴趣词</div>
        <el-scrollbar style="height:100%;width:100%">
          <p class="scienceword">
            <span class="sciencewordNum">1</span>
            <span class="sciencewordtxt">精神文明建设</span>
          </p>
        </el-scrollbar>
      </div>
    </div>-->
    <mainList :Listobj="Listobj"></mainList>
    <!-- <div class="subscribelf">
      <mainList :Listobj="Listobj"></mainList>
    </div> -->
  </div>
</template>

<script>
import mainList from '../../components/main/mainlist'
export default {
  components: { mainList },
  data () {
    return {
      Listobj: {
        type: 8, /// 0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅
        url: ''
      },
      currentPage: 1,
      page: 1, // 页码
      pageRow: 10, // 每页显示的条目数
      total: 0
    }
  },
  mounted () {
    document.title = this.$route.meta.title
    this.Listobj.url = '/userinfo/subscriptions'
  },
  methods: {
    handleSizeChange (val) {
    },
    handleCurrentChange (val) {
    }
  }
}
</script>

<style lang="less" scoped>
.subscribeBox {
  width: 1156px;
  // display: flex;

  // .subscribelf {
  //   background-color: antiquewhite;
  //   // padding-right: 2.0833vw;
  //   // box-sizing: border-box;

  //   .listgroup {
  //     .listgroupLine {
  //       width: 100%;
  //       height: .0521vw;
  //       background: #e6e6e6;
  //       border-radius: .0521vw;
  //       margin: .7813vw 0;
  //     }

  //     .listgroupTitleBox {
  //       display: flex;
  //       justify-content: space-between;
  //     }

  //     .recommend {
  //       vertical-align: text-bottom;
  //       text-align: center;
  //       display: inline-block;
  //       width: 1.875vw;
  //       height: .9375vw;
  //       background: #ff7100;
  //       border-radius: .1042vw;
  //       font-size: .625vw;
  //       color: #ffffff;
  //       margin-right: .5208vw;
  //     }

  //     .listgroupTitleType {
  //       box-sizing: border-box;
  //       vertical-align: text-bottom;
  //       display: inline-block;
  //       text-align: center;
  //       width: 3.125vw;
  //       height: 1.0417vw;
  //       background: rgba(255, 113, 0, 0.1);
  //       border: .0521vw solid #ff7100;
  //       border-radius: .5208vw;
  //       font-size: .625vw;
  //       color: #ff7100;
  //       margin: 0 .5208vw;
  //     }

  //     @media screen and (min-width: 83.3333vw) {
  //       .listgroupTitle {
  //         font-size: 1.0417vw;
  //         font-weight: 500;
  //         color: @my-color;
  //       }
  //     }

  //     // 1300~1600
  //     @media screen and (max-width: 83.3333vw) and (min-width: 67.7083vw) {
  //       .listgroupTitle {
  //         font-size: 1.0417vw;
  //         font-weight: 500;
  //         color: @my-color;
  //       }
  //     }

  //     // 小于1300
  //     @media screen and (max-width: 67.7083vw) {
  //       .listgroupTitle {
  //         font-size: .9375vw;
  //         font-weight: 500;
  //         color: @my-color;
  //       }
  //     }

  //     .listgroupnameBox {
  //       margin: .7813vw 0;

  //       .listgroupname {
  //         font-size: .8333vw;
  //         color: #000000;
  //       }

  //       .stationdrop {
  //         margin: 0 .5208vw;
  //       }

  //       .stationline {
  //         display: inline-block;
  //         width: .1042vw;
  //         height: .8333vw;
  //         background: #cccccc;
  //         margin: 0 .5208vw;
  //         vertical-align: text-bottom;
  //       }
  //     }

  //     .listgroupTxt {
  //       color: #666;
  //     }

  //     .keyword {
  //       color: #000;
  //       margin: .7813vw 0;
  //     }

  //     .keywordtype {
  //       margin: .7813vw 0;

  //       >span {
  //         padding: .1042vw .2083vw;
  //         border: .0521vw solid #e6e6e6;
  //         border-radius: .5208vw;
  //         margin-right: .5208vw;
  //       }

  //       >span:last-child {
  //         margin-right: 0px;
  //       }
  //     }

  //     .listBotttom {
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-between;

  //       .listBotttomLF {
  //         .listBotttomtxt {
  //           color: #666;
  //         }

  //         .listBotttoomActive {
  //           color: @my-color;
  //         }
  //       }

  //       .listBotttomrf {
  //         .listBotttomItemactive {
  //           color: @my-color;
  //           margin-left: .5208vw;
  //         }
  //       }
  //     }
  //   }

  //   .pageBox {
  //     .listgroupLine {
  //       width: 100%;
  //       height: .0521vw;
  //       background: #e6e6e6;
  //       border-radius: .0521vw;
  //       margin: .7813vw 0;
  //     }
  //   }
  // }

  // .subscribert {
  //   flex: 1;

  //   .scienceBox {
  //     box-sizing: border-box;
  //     width: 100%;
  //     background: #ffffff;
  //     border: .0521vw solid #e6e6e6;
  //     box-shadow: 0px .1042vw .5208vw 0px rgba(1, 32, 121, 0);
  //     border-radius: .5208vw;

  //     @media screen and (min-width: 83.3333vw) {
  //       .sciencewordtitle {
  //         padding: .5208vw 1.0417vw;
  //         font-size: 1.0417vw;
  //         font-weight: 500;
  //         color: #9c2526;
  //         border-bottom: .0521vw solid #e6e6e6;
  //       }
  //     }

  //     // 1300~1600

  //     @media screen and (max-width: 83.3333vw) and (min-width: 67.7083vw) {
  //       .sciencewordtitle {
  //         padding: .5208vw 1.0417vw;
  //         font-size: 1.0417vw;
  //         font-weight: 500;
  //         color: #9c2526;
  //         border-bottom: .0521vw solid #e6e6e6;
  //       }
  //     }

  //     // 小于1300
  //     @media screen and (max-width: 67.7083vw) {
  //       .sciencewordtitle {
  //         padding: .5208vw 1.0417vw;
  //         font-size: .9375vw;
  //         font-weight: 500;
  //         color: #9c2526;
  //         border-bottom: .0521vw solid #e6e6e6;
  //       }
  //     }

  //     .scienceword {
  //       padding: .5208vw 1.0417vw;

  //       .sciencewordNum {
  //         color: @my-color;
  //       }

  //       .sciencewordtxt {
  //         margin-left: .5208vw;
  //       }
  //     }
  //   }
  // }
}
</style>
