<template>
  <div>
    <!-- <div>
      <headerComponent></headerComponent>
    </div> -->
    <div style="position: relative;">
      <div class="user">
        <div class="_left">
          <myMenu></myMenu>
        </div>
        <div class="_right">
          <personlmain></personlmain>
        </div>
      </div>
      <!-- <div class="recommendOptions" @click="handleRecommond"
        :class="{ recommondFixed: recommondFixed === true, recommondPosAbso: recommondFixed === false }">
        <span class="icon"></span>
        <div class="opintip">我要荐稿</div>
      </div> -->
      <!-- <div class="opinion" @click="handleopinion" :class="{ isfixed: isFixed === true, posAbso: isFixed === false }">
        <span class="icon"></span>
        <div class="opintip">意见反馈</div>
      </div> -->
      <opinions class='optionsss' ref="opinions" @opinionsopen="opinionsopen"></opinions>
      <recommended-articles class='recommends' ref='recommends' @opinionsopen="recommendopen"></recommended-articles>
    </div>
    <!-- <div class="footer">
      <footerComponent></footerComponent>
    </div> -->
  </div>
</template>

<script>
// import headerComponent from '../../components/head/headlogin.vue'
import myMenu from '../../components/user/menu.vue'
import personlmain from '../../components/user/main.vue'
// import footerComponent from '../../components/common/footer.vue'
import opinions from '../../components/head/opinions.vue'
import recommendedArticles from '@/components/head/recommendedArticles'
export default {
  components: {
    // headerComponent,
    personlmain,
    myMenu,
    // footerComponent,
    opinions,
    recommendedArticles
  },
  mounted () {
    document.querySelector('.home').style.backgroundColor = '#fff'
    document.querySelector('.middleContainer').style.backgroundColor = '#fff'
    window.addEventListener('scroll', this.fixedActiveBtn)
    this.open()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.fixedActiveBtn, true)
  },
  data () {
    return {
      recommondFixed: false,
      recommendopen: false,
      isFixed: false,
      opinionsopen: false,
      isOpinion: true
    }
  },
  methods: {
    handleopinion () {
      if (this.$refs.recommends.recommendopen) {
        this.$refs.recommends.recommendopen = !this.$refs.recommends.recommendopen
      }
      this.$refs.opinions.opinionsopen = !this.$refs.opinions.opinionsopen
    },
    // 打开作者荐稿
    handleRecommond () {
      if (this.$refs.opinions.opinionsopen) {
        this.$refs.opinions.opinionsopen = !this.$refs.opinions.opinionsopen
      }
      this.$refs.recommends.recommendopen = !this.$refs.recommends.recommendopen
    },
    removeListener () {
      document.removeEventListener('click', this.close, false)
    },
    open () {
      document.addEventListener('click', this.close, false)
    },
    close (e) {
      const boxEl = document.querySelector('.optionsss')
      const recommendsEl = document.querySelector('.recommends')
      if (boxEl) {
        if (!boxEl.contains(e.target) &&
          e.target.className !== 'opintip' &&
          e.target.className !== 'opinion isfixed' &&
          e.target.className !== 'icon' &&
          e.target.className !== 'el-input__icon el-icon-circle-close el-input__clear' &&
          e.target.className !== 'opinion posAbso') {
          this.$refs.opinions.opinionsopen = !this.$refs.opinions.opinionsopen
          // if (this.$refs.opinions.opinionsopen === false) {
          //   this.removeListener()
          // }
        }
      }
      if (recommendsEl) {
        if (!recommendsEl.contains(e.target) &&
          e.target.className !== 'opintip' &&
          e.target.className !== 'opinion isfixed' &&
          e.target.className !== 'icon' &&
          e.target.className !== 'el-input__icon el-icon-circle-close el-input__clear' &&
          e.target.className !== 'opinion posAbso') {
          this.$refs.recommends.recommendopen = !this.$refs.recommends.recommendopen
        }
      }
    },
    fixedActiveBtn () {
      const bottom = document.documentElement.scrollHeight - document.documentElement.clientHeight - 190
      const scrollTop = document.documentElement.scrollTop
      if (scrollTop >= bottom) {
        this.recommondFixed = false
        this.isFixed = false
      } else {
        this.isFixed = true
        this.recommondFixed = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.recommondPosAbso {
  bottom: 100px;
  position: absolute;
}

.recommondFixed {
  bottom: 150px;
  position: fixed;
}

.user {
  margin: 20px auto;
  width: 1440px;
  display: flex;
  justify-content: space-between;
  min-height: 600px;

  ._left {
    width: 240px;
  }

  ._right {
    flex: 1;
  }

}

.isfixed {
  bottom: 71px;
  position: fixed;
}

.posAbso {
  bottom: 20px;
  position: absolute;
}

.opinion {
  right: 1px;
  width: 60px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 8px;
  text-align: center;
  cursor: pointer;

  .icon {
    display: inline-block;
    width: 21px;
    height: 20px;
    background: url("../../assets/images/head/space.png") no-repeat;
    background-size: cover;
  }

  .opintip {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    text-align: center;
    // margin-top: 8px;
  }
}

.opinion:hover {
  right: 1px;
  width: 60px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 8px;
  text-align: center;
  cursor: pointer;

  .icon {
    display: inline-block;
    width: 21px;
    height: 20px;
    background: url("../../assets/images/head/space_red.png") no-repeat;
    background-size: cover;
  }

  .opintip {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #9c2526;
    text-align: center;
    // margin-top: 8px;
  }
}

.recommendOptions {
  box-sizing: border-box;
  right: 1px;
  width: 60px;
  height: 60px;
  background: #9c2526;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 30px 30px 10px 10px;
  padding-top: 10px;
  padding-bottom: 8px;
  text-align: center;
  cursor: pointer;

  .icon {
    display: inline-block;
    width: 21px;
    height: 20px;
    background: url("../../assets/images/head/iWantRecommend.png") no-repeat;
    background-size: cover;
  }

  .opintip {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #fff;
    text-align: center;
    // margin-top: 8px;
  }
}

.recommendOptions:hover {
  right: 1px;
  width: 60px;
  // height: 60px;
  background: #9c2526;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 30px 30px 10px 10px;
  padding-top: 10px;
  padding-bottom: 8px;
  text-align: center;
  cursor: pointer;

  .icon {
    display: inline-block;
    width: 21px;
    height: 20px;
    background: url("../../assets/images/head/iWantRecommendHover.png") no-repeat;
    background-size: cover;
  }

  .opintip {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #fff;
    text-align: center;
    // margin-top: 8px;
  }
}
</style>
