<template>
  <div class="echartBox">
    <div class="chartBox">
      <!-- <div class="showechartBox">
        <div class="echarthot" ref="echarthot"></div>
      </div>
      <div class="linechart" ref="linechart01"></div> -->
      <div class="lineBox">
        <div class="linechartTitlebox">
          <p>热点关注趋势</p>
          <div>
            <el-date-picker v-model="pourstartyear" type="year" placeholder="开始时间" value-format="yyyy"
              :picker-options="pickerOptions" @change="handlestartyear"></el-date-picker>
            <span style="margin:0 10px;">-</span>
            <el-date-picker v-model="pourendtyear" type="year" placeholder="结束时间" value-format="yyyy"
              :picker-options="pickerOptions" @change="handleendtyear"></el-date-picker>
          </div>
        </div>
        <div class="linechart" ref="linechart01"></div>
      </div>
      <div class="barBox">
        <div class="linechartTitlebox">
          <p>热点发文趋势</p>
          <div>
            <el-date-picker v-model="sendstartyear" type="year" value-format="yyyy" :picker-options="pickerOptions"
              @change="handlestartyear01"></el-date-picker>
            <span style="margin:0 10px;">-</span>
            <el-date-picker v-model="sendendtyear" type="year" value-format="yyyy" :picker-options="pickerOptions"
              @change="handleendtyear01"></el-date-picker>
          </div>
        </div>
        <div class="barchart" ref="linechart02"></div>
      </div>
    </div>
  </div>
</template>

<script>
import elementResizeDetectorMaker from 'element-resize-detector'
const erd = elementResizeDetectorMaker()
export default {
  data () {
    return {
      id: '',
      pourstartyear: new Date().getFullYear() - 10 + '', // 关注开始时间
      pourendtyear: new Date().getFullYear() - 1 + '', // 关注结束时间
      sendstartyear: new Date().getFullYear() - 10 + '', // 发文开始时间
      sendendtyear: new Date().getFullYear() - 1 + '', // 发文结束时间
      lineyearData01: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], // 入选趋势x轴
      linecount01: [20, 32, 1, 34, 90, 30, 20], // 入选趋势大小
      lineyearData02: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], // 入选趋势x轴
      linecount02: [20, 32, 1, 34, 90, 30, 20], // 入选趋势大小
      pickerOptions: {
        disabledDate: (time) => {
          const date = new Date().getFullYear()
          const yearEnd = time.getFullYear()
          return date < yearEnd
        }
      }
    }
  },
  props: ['refresh'],

  computed: {},
  beforeDestroy () {
    erd.uninstall(this.$refs.linechart01)
    erd.uninstall(this.$refs.linechart02)
  },
  methods: {
    // 选择年份
    handlestartyear (start) {
      this.pourstartyear = start
      if (this.startyear > this.endtyear) {
        return this.$message({
          type: 'warning',
          message: '建议开始时间选择大于结束时间,结束时间早于开始时间'
        })
      }
      this.getLineData()
    },
    handleendtyear (end) {
      this.pourendtyear = end
      if (this.startyear > this.endtyear) {
        return this.$message({
          type: 'warning',
          message: '建议开始时间选择大于结束时间,结束时间早于开始时间'
        })
      }
      this.getLineData()
    },
    // 发文趋势
    handlestartyear01 (start) {
      this.sendstartyear = start
      if (this.sendstartyear > this.sendendtyear) {
        return this.$message({
          type: 'warning',
          message: '建议开始时间选择大于结束时间,结束时间早于开始时间'
        })
      }
      this.getpieData()
    },
    handleendtyear01 (end) {
      this.sendendtyear = end
      if (this.sendstartyear > this.sendendtyear) {
        return this.$message({
          type: 'warning',
          message: '建议开始时间选择大于结束时间,结束时间早于开始时间'
        })
      }
      this.getpieData()
    },
    //  热点关注趋势
    async getLineData () {
      this.$nextTick(() => {
        // console.log('给getChar的', this.$store.state.tagWords)
        this.getChar(this.$store.state.tagWords)
      })

      const { data: res } = await this.$http({
        url: '/academic/interest_trend',
        method: 'POST',
        data: {
          endTime: parseInt(this.pourendtyear),
          startTime: parseInt(this.pourstartyear),
          id: this.id // 机构名称
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.lineyearData01 = res.data.map(item => {
        return item.year
      })
      this.linecount01 = res.data.map(item => {
        return item.count
      })
      var myChartline = this.$echarts.init(this.$refs.linechart01)
      var option
      option = {
        xAxis: {
          type: 'category',
          data: this.lineyearData01,
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          min: 'dataMin', // 最小值
          max: 'dataMax' // 最大值
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            data: this.linecount01,
            type: 'line',
            areaStyle: {},
            itemStyle: {
              color: '#9c2526'
            },
            emphasis: {
              label: {
                show: true
              }
            }
          }
        ]
      }
      myChartline.setOption(option)
      erd.listenTo(this.$refs.linechart01, function () {
        myChartline.resize()
      })
    },
    // 发文趋势
    async getpieData () {
      const { data: res } = await this.$http({
        url: '/academic/publish_trend',
        method: 'POST',
        data: {
          endTime: parseInt(this.sendendtyear),
          startTime: parseInt(this.sendstartyear),
          id: this.id // 机构名称
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.lineyearData02 = res.data.map(item => {
        return item.year
      })
      this.linecount02 = res.data.map(item => {
        return item.count
      })
      var myChartline = this.$echarts.init(this.$refs.linechart02)
      var option
      option = {
        xAxis: {
          type: 'category',
          data: this.lineyearData02,
          axisLabel: {
            // rotate:45
          }
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          min: 'dataMin', // 最小值
          max: 'dataMax' // 最大值
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          bottom: '5%',
          left: 'center'
        },
        series: [
          {
            data: this.linecount02,
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#9c2526'
            },
            emphasis: {
              label: {
                show: true
              }
            }
          }
        ]
      }
      myChartline.setOption(option)
      erd.listenTo(this.$refs.linechart02, function () {
        myChartline.resize()
      })
    },
    getChar (topWord) {
      // console.log('热词的热词', topWord)
      var themeStyle = this.$echarts.init(this.$refs.echarthot)
      var _that = this
      this.$nextTick(() => {
        var option = {
          title: {
            text: '相关词',
            textStyle: {
              // 文字颜色
              color: '#9c2526',
              // 字体风格,'normal','italic','oblique'
              fontStyle: 'normal',
              // 字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
              fontWeight: '500',
              // 字体系列
              fontFamily: 'sans-serif',
              // 字体大小
              fontSize: 18
            }
          },
          series: [
            {
              type: 'wordCloud',
              // 要绘制的“云”的形状。任意极坐标方程都可以表示为a吗
              // 回调函数，或关键字存在。可用的礼物为圆形(默认)，
              // 心形(苹果或心形曲线，最著名的极坐标方程)，菱形(
              // alias of square)， triangle-forward, triangle， (alias of triangle- standing, pentagon, and star)
              shape: 'circle',
              transform: 'scale', // 通过缩放来实现类似椭圆的效果
              // 保持maskImage或1:1的形状的纵横比
              // echarts-wordcloud@2.1.0支持该选项
              keepAspect: false,
              // 一个轮廓图像，白色区域将被排除在绘制文本之外。
              // 当云的形状增长时，形状选项将继续应用。
              // maskImage: maskImage,
              // 跟随左/顶/宽/高/右/底是用来定位字云
              // 默认放置在中心，大小为75% x 80%。
              left: 'center',
              top: 'center',
              width: '90%',
              height: '85%',
              right: null,
              bottom: null,
              // 文本大小范围，数据中的值将被映射到。
              // 默认最小12px，最大60px大小。
              sizeRange: [14, 45],
              // 文本旋转范围和步进度。文本将被rotationStep 45在[- 90,90]范围内随机旋转
              rotationRange: [-90, 90],
              rotationStep: 45,
              // 网格的大小(以像素为单位)，用于标记画布的可用性
              // 网格大小越大，单词之间的间距越大。
              gridSize: 8,
              // 设置为true允许文字部分绘制在画布外。
              // 允许文字大于画布的大小
              drawOutOfBound: false,
              // 如果执行布局动画。
              // 注意当有很多字的时候禁用它会导致UI阻塞。
              layoutAnimation: true,
              // Global text style
              textStyle: {
                fontFamily: 'sans-serif',
                fontWeight: 'bold',
                // Color can be a callback function or a color string
                color: function () {
                  // Random color
                  var colors = [
                    '#D24132',
                    '#FF7100',
                    '#37768F',
                    '#179A80',
                    '#FEA01E',
                    '#9C2526'
                  ] // 自定义字体颜色
                  return colors[parseInt(Math.random() * 5)]
                }
              },
              emphasis: {
                focus: 'self'
              },
              // 内容，
              data: topWord
            }
          ]
        }
        themeStyle.on('click', function (params) {
          // console.log(params)
          // alert(params.data.name)
          const routeData = _that.$router.resolve({
            path: '/subjectHot?name=' + params.data.name
            // query: params
          })
          window.open(routeData.href, '_blank')
        })
        themeStyle.setOption(option)
      })
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.getpieData()
    this.getLineData()
  },
  watch: {
    '$store.state.tagWords': {
      handler: function (newVal, oldVal) {
        this.getChar(newVal)
      }
    },
    '$store.state.tenListEcharts': {
      deep: true,
      immediate: true,
      handler (data) {
        if (data === true) {
          this.id = this.$route.query.id
          // this.getpieData()
          // this.getLineData()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-date-editor.el-input {
  width: 100px;
}

// /deep/.el-date-picker::placeholder {
//   .el-input__inner {
//     color: #9c2526;
//   }
// }

.echartBox {
  .chartBox {
    display: flex;

    .showechartBox {
      width: auto;
      padding: 20px;

      .echarthot {
        width: auto;
        height: 400px;
      }

      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
      border-radius: 10px;
      margin-bottom: 20px;
    }

    .lineBox {
      margin-left: 4px;
      margin-bottom: 3px;
      margin-top: 2px;
      flex: 1;
      height: 400px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
      border-radius: 10px;
      padding: 20px;

      .linechartTitlebox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        >p {
          font-size: 18px;
          font-weight: 500;
          color: #9c2526;
        }

        /deep/.el-range-editor.el-input__inner {
          width: 200px;
        }
      }

      .linechart {
        width: 100%;
        height: 350px;
      }
    }

    .barBox {
      margin-left: 20px;
      margin-right: 4px;
      margin-top: 2px;
      flex: 1;
      box-sizing: border-box;
      height: 400px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
      border-radius: 10px;
      padding: 20px;

      .linechartTitlebox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        >p {
          font-size: 18px;
          font-weight: 500;
          color: #9c2526;
        }
      }

      .barchart {
        width: 100%;
        height: 350px;
      }
    }
  }
}
</style>
