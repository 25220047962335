<template>
  <div class="personalBox">
    <div class="basicmemberBox">
      <div class="lfmember">
        <div class="firstfromrt">
          <!-- <span class="requiredspotimg">头像：</span> -->
          <el-upload
            title="添加头像"
            class="avatar-uploader"
            :before-upload="beforeAvatarUpload"
            :action="action"
            :show-file-list="false"
            :http-request="handleAvatarSuccess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <img v-else class="avatar" src="../../assets/images/head/Personalimg01.png" />
            <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
            <div class="after">
              <span class="remind one" style="display: block;">修 改</span>
              <span class="remind two" style="display: block;">头 像</span>
            </div>
          </el-upload>
          <p class="uploadprompt">请上传jpg或png格式的图片</p>
        </div>
      </div>
      <div class="rtmember">
        <div class="rtmemberlt">
          <div class="menbertitle">
            <div class="menbertitlefirst">
              <img src="../../assets/images/member/membervip.png" alt />
              <div class="menbertitlejb">
                <span>{{levelName}}</span>
                <span>级</span>
              </div>
            </div>
            <div class="menbertitlemun">账号：{{form.phone}}</div>
          </div>
          <div class="menberitem">
            <div class="menbergraup">
              <div>
                <img src="../../assets/images/member/jficon.png" alt />
              </div>
              <div class="menbergrauptitlebox">
                <p class="menbergrauptitle">当前积分</p>
                <p class="menbergraupnum">{{totalPoints}}</p>
              </div>
            </div>
            <div class="menbergraup">
              <div>
                <img src="../../assets/images/member/jyzicon.png" alt />
              </div>
              <div class="menbergrauptitlebox">
                <p class="menbergrauptitle">当前经验值</p>
                <p class="menbergraupnum">{{totalExp}}</p>
              </div>
            </div>
            <div class="menbergraup">
              <div>
                <img src="../../assets/images/member/jdicon.png" alt />
              </div>
              <div class="menbergrauptitlebox">
                <p class="menbergrauptitle">下一等级进度</p>
                <p class="menbergraupnum">
                  <span>{{totalExp}}</span>
                  <span v-show="totalExp">/</span>
                  <span>{{scheduleValue}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="rtmemberrt" @click="handlegomember">
          <span>查看会员细则</span>
        </div>
      </div>
    </div>
    <div class="Basicbox">
      <div class="basicTitle">
        <span class="basicLine"></span>
        <span class="basicTitle">基本信息</span>
        <span class="basiclongLine"></span>
      </div>

      <div class="firstformBox">
        <div class="firstfromlf">
          <div class="formitem">
            <span>用户昵称：</span>
            <el-input
              @input="handleInput"
              v-model.trim="form.name"
              maxlength="20"
              show-word-limit
              class="inputbox"
            ></el-input>
          </div>
          <div class="formitem">
            <span class="request">手机号：</span>
            <el-input v-model="form.phone" maxlength="11" disabled></el-input>
          </div>
          <!-- <div class="formitem">
            <span>邮箱：</span>
            <el-input v-model="form.mailbox" maxlength='20' @blur="checkEmail"></el-input>
          </div>-->
        </div>
        <!-- <div class="firstfromrt">
          <span class="requiredspotimg">头像：</span>
          <el-upload
            title="添加头像"
            class="avatar-uploader"
            :before-upload="beforeAvatarUpload"
            :action="action"
            :show-file-list="false"
            :http-request="handleAvatarSuccess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <img v-else class="avatar" src="../../assets/images/head/Personalimg01.png" />

            <div class="after">
              <span class="remind one" style="display: block;">修 改</span>
              <span class="remind two" style="display: block;">头 像</span>
            </div>
          </el-upload>
          <p class="uploadprompt">温馨提示：请上传jpg、jpeg或png格式的图片</p>
        </div>-->
      </div>
      <div class="basicTitle">
        <span class="basicLine"></span>
        <span class="basicTitle">兴趣设置</span>
        <span class="basiclongLine"></span>
      </div>
      <div class="secondformBox">
        <div class="formitem">
          <span>选择学科：</span>
          <el-cascader
            @change="handleRequire"
            v-model="subjectIds"
            placeholder="请选择学科"
            :options="options"
            :props="props"
            clearable
            filterable
          ></el-cascader>
        </div>
        <!-- <div class="formitem">
          <span>一级学科：</span>
          <el-select v-model="form.firstdiscipline" @focus="addFirst" @remove-tag="remove" placeholder="请选择" multiple
            @change="getSecond" filterable clearable ref="statusSelect1">
            <el-option v-for="(item, index) in firstdisciplineList" :key="index" :label="item.name"
              :value="item.code + '+' + item.name"></el-option>
          </el-select>
        </div>
        <div class="formitem">
          <span>二级学科：</span>
          <el-select v-model="form.seconddiscipline" @remove-tag="removeSecond" placeholder="请选择" @focus="addSecond"
            multiple @change="getSecondList" filterable clearable ref="statusSelect1">
            <el-option-group v-for="group in seconddisciplineList" :key="group.code" :label="group.name">
              <el-option v-for=" item  in  group.childList" :key="item.code" :label="item.name"
                :value="item.code + '+' + item.name"></el-option>
            </el-option-group>
          </el-select>
        </div>-->
        <div class="formitemword">
          <span>兴趣词：</span>
          <div class="interestword">
            &nbsp;&nbsp;&nbsp;
            <el-input
              v-model.trim="form.interestword"
              maxlength="10"
              show-word-limit
              placeholder="请输入兴趣词,每个兴趣词限制长度10个字"
            ></el-input>
            <el-button type="primary" class="interestwordadd" @click="handleaddBtn">添加</el-button>
          </div>
        </div>
        <div class="formitemword">
          <span></span>
          <div>
            <p class="wordtagAlert">可添加10个兴趣词</p>
            <p class="wordtagsTitle" v-if="form.wordtags.length">已添加兴趣词：</p>
            <div class="wrodBox">
              <el-tag
                class="wordtags"
                v-for=" tag  in  form.wordtags "
                :key="tag"
                closable
                :disable-transitions="false"
                @close="handleClose(tag)"
              >{{ tag }}</el-tag>
            </div>

            <p class="prompt">温馨提示:当您完成兴趣设置后，我们会为您进行主文献个性化推荐，您可在【主文献列表页】及【个人信息】一>【我的订阅】查阅哦。</p>
          </div>
        </div>
        <div class="formitem">
          <span></span>
          <div class="surebtn">
            <el-button type="primary" @click="suerBtn">保存</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      levelName: "",
      totalPoints: "", //当前积分
      totalExp: "", //经验值
      scheduleValue: "", //进度值
      threeLogin: this.THREE_LOGIN_OR, // 如果是2就是本地登录,如果是其他就是第三方登陆
      requestUrl: "",
      codeTag: "",
      second: "",
      imageUrl: "",
      action: "",
      form: {
        id: "",
        name: "",
        phone: "",
        mailbox: "",
        photo: "",
        firstdiscipline: [], // 要提交的一级学科
        seconddiscipline: [], // 二级学科
        interestword: "", // 添加
        wordtags: []
      },
      firstdisciplineList: [],
      seconddisciplineList: [],
      firstLevelDiscipline: [], // 提交接口的一级学科
      secondLevelDiscipline: [], // 提交接口用的二级学科
      firstDisc: [],
      secondeChild: [],
      flag: true,
      subjectIds: [],
      options: [],
      subjectList: [],
      firstList: [],
      props: {
        multiple: true,
        // checkStrictly: true,
        label: "label",
        value: "id",
        children: "children"
        // lazy: true,
        // lazyLoad (node, resole) {
        // }
      }
    };
  },
  mounted() {
    console.log("thishsishi", this.THREE_LOGIN_OR);
    document.title = this.$route.meta.title;
    this.memberlever();
    // 获取个人中心
    this.getpersonal().then(() => {
      this.getList();
    });

    this.action = this.BASE_URL + "userinfo/uploadPhoto";
    // 获取二级学科
  },
  methods: {
    // 个人信息查询
    async memberlever() {
      const { data: res } = await this.$http({
        url: "/memberInfo/getLoginMemberInfo",
        method: "get"
      });
      if (res.code != 200) return this.$message.error(res.msg);
      this.levelName = res.data.levelName;
      this.totalPoints = res.data.totalPoints;
      this.totalExp = res.data.totalExp;
      this.scheduleValue = res.data.preUpgradeExp;
    },
    // 点击跳转会员细则
    handlegomember() {
      const routeData = this.$router.resolve({
        path: "/memberpromotion"
        // query: params
      });
      window.open(routeData.href, "_blank");
    },

    async getList() {
      const { data: res } = await this.$http({
        url: "/literatureInfoList/getDisciplineList",
        method: "post",
        data: {
          firstLevelList: [],
          secondLevelList: []
        }
      });
      if (res.code != 200) return this.$message.error(res.msg);
      res.data.forEach(item => {
        item.children.forEach(i => {
          delete i.children;
        });
      });
      this.options = res.data;
      // this.subjectIds = []
    },
    handleInput(val) {
      // this.form.name = this.form.name.replace(/[^\u4E00-\u9FA5\w]/g, '')
    },
    // 校验是否邮箱格式
    checkEmail() {
      const checkEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!checkEmail.test(this.form.mailbox) && this.form.mailbox) {
        this.$message({
          type: "warning",
          message: "请输入正确邮箱!"
        });
        return false;
      } else {
        return true;
      }
    },
    addFirst() {
      // test
      for (let i = 0; i < this.form.firstdiscipline.length; i++) {
        this.firstdisciplineList.forEach(el => {
          if (this.form.firstdiscipline[i] === el.name) {
            // this.form.firstdiscipline[i] = el.code + '+' + el.name
            this.$set(this.form.firstdiscipline, i, el.code + "+" + el.name);
          }
        });
      }
    },
    addSecond() {
      for (let i = 0; i < this.form.seconddiscipline.length; i++) {
        this.seconddisciplineList.forEach(el => {
          el.childList.forEach(ement => {
            if (this.form.seconddiscipline[i] === ement.name) {
              // this.form.firstdiscipline[i] = el.code + '+' + el.name
              this.$set(
                this.form.seconddiscipline,
                i,
                ement.code + "+" + ement.name
              );
            }
          });
        });
      }
    },
    // 删除一级学科时删除二级学科
    remove(tag) {
      if (tag.indexOf("+") === -1) {
        // 没出现+代表一进来直接删除
        this.second = this.second.filter(item => {
          return item.name !== tag;
        });
      } else {
        // 代表点击后删除
        const code = tag.split("+")[0];
        this.second = this.second.filter(item => {
          return item.code !== code;
        });
      }
      this.form.seconddiscipline = [];
      this.second.forEach(item => {
        item.childList.forEach(el => {
          this.form.seconddiscipline.push(el.name);
        });
      });
    },
    // 移除二级学科
    removeSecond(tag) {
      if (tag.indexOf("+") === -1) {
        // 没出现+代表一进来直接删除
        this.second = JSON.parse(localStorage.getItem("two"));
        this.second.forEach(item => {
          item.childList = item.childList.filter(el => {
            return el.name !== tag;
          });
        });
      } else {
        // // 代表点击后删除
        // const code = tag.split('+')[0]
        // // console.log(this.seconddisciplineList)
        // console.log('this.second', this.second)
        // this.second = this.second.filter(item => {
        //   console.log(item)
        //   return item.code !== code
        // })
      }
    },

    clearSecond() {
      this.form.seconddiscipline = [];
    },
    // 上传照片
    async handleAvatarSuccess(res) {
      const { file } = res;
      const photo = new FormData(); // formData实例对象 内部是空的
      photo.append("photo", file);
      const re = await this.$http({
        url: "/userinfo/uploadPhoto",
        method: "POST",
        data: photo
      });
      // 回显
      this.imageUrl = re.data.data.url + re.data.data.photo;
      // this.$store.commit("setImage", this.imageUrl);
      this.form.photo = re.data.data.photo;
    },
    // 处理标签
    handleClose(tag) {
      this.form.wordtags.splice(this.form.wordtags.indexOf(tag), 1);
    },
    handleitem(tag) {},
    // 获取个人中心
    async getpersonal() {
      // 查询个人信息
      this.flag = true;
      const { data: res } = await this.$http({
        url: "/userinfo",
        method: "GET"
      });
      // 失败则报错
      // if (res.code !== 200) return this.$message.error(res.msg)
      res.data.secondLevelDiscipline.forEach(item => {
        item.childList.forEach(val => {
          if (val.checked == "true") {
            this.subjectIds.push([item.code, val.code]);
          }
        });
      });
      if (res.data.photo === "" || res.data.url === null) {
        this.imageUrl = "";
        this.form.photo = res.data.photo;
      } else {
        this.form.photo = res.data.photo;
        this.imageUrl = res.data.url + res.data.photo;
        this.$store.commit("setImage", res.data.url + res.data.photo);
      }

      this.form.id = res.data.id;
      // 用户名
      this.form.name = res.data.nickName;

      // 用户手机号
      this.form.phone = res.data.phone;
      // 邮箱
      this.form.mailbox = res.data.email;
      // 一级学科
      this.firstdisciplineList = res.data.firstLevelDiscipline;
      // 二级学科
      this.subjectList = res.data.secondLevelDiscipline;

      if (this.subjectList) {
        for (let i = 0; i < this.subjectList.length; i++) {
          this.subjectList[i].childList = this.subjectList[i].childList.filter(
            item => {
              return item.checked === "true";
            }
          );
        }
      }
      // 二级学科
      this.seconddisciplineList = res.data.secondLevelDiscipline;

      // 兴趣词
      res.data.interestWord.forEach(item => {
        if (item !== "") {
          this.form.wordtags.push(item);
        }
      });

      // if (this.firstdisciplineList) {
      this.firstdisciplineList.forEach(item => {
        if (item.checked === "true") {
          this.form.firstdiscipline.push(item.name);
          // this.firstdiscipline.push(item.code + '+' + item.name)
          this.firstDisc.push({
            code: item.code,
            name: item.name
          });
        }
      });
      // }
      this.second = [];
      this.second = this.firstDisc;
      if (this.seconddisciplineList) {
        this.seconddisciplineList.forEach(item => {
          if (item.childList) {
            item.childList.forEach(el => {
              if (el.checked === "true") {
                this.form.seconddiscipline.push(el.name);
                this.second.forEach(ement => {
                  if (ement.code === el.code.substring(0, 4)) {
                    if (ement.childList) {
                      ement.childList.push({
                        code: el.code,
                        name: el.name
                      });
                    } else {
                      ement.childList = [];
                      ement.childList.push({
                        code: el.code,
                        name: el.name
                      });
                    }
                  }
                });
              }
            });
          }
        });
      }
      // this.second = this.form.seconddiscipline
      localStorage.setItem("two", JSON.stringify(this.second));
      localStorage.setItem("first", JSON.stringify(this.firstDisc));
    },
    // 根据一级学科获取二级学科
    async getSecond(val) {
      this.flag = false;
      this.firstDisc = [];
      this.form.firstdiscipline.forEach(item => {
        if (item.indexOf("+") !== -1) {
          const arr = item.split("+");
          this.firstDisc.push({
            code: arr[0],
            name: arr[1]
          });
        } else {
          // 查找所有item

          this.firstdisciplineList.forEach(el => {
            if (el.name === item) {
              this.codeTag = el.code;
            }
          });
          this.firstDisc.push({
            code: this.codeTag,
            name: item
          });
        }
      });
      // 去重
      if (this.firstDisc) {
        this.firstDisc = this.firstDisc.filter((item, index, array) => {
          return (
            array.findIndex(
              obj => obj.code === item.code && obj.name === item.name
            ) === index
          );
        });
      }
      // // 查询所有二级学科
      const res = await this.$http({
        url: "/userinfo/selectDisc",
        method: "POST",
        data: this.firstDisc
      });
      this.secondLevelDiscipline = JSON.parse(JSON.stringify(this.firstDisc));
      this.seconddisciplineList = res.data.data; // 二级学科
    },
    getSecondList(val) {
      this.flag = false;
      if (this.secondLevelDiscipline.length === 0) {
        this.secondLevelDiscipline = JSON.parse(localStorage.getItem("first"));
      }
      const secondChildren = [];
      for (let i = 0; i < this.secondLevelDiscipline.length; i++) {
        this.secondLevelDiscipline[i].childList = [];
      }
      this.form.seconddiscipline = Array.from(val);
      this.form.seconddiscipline.forEach(item => {
        const arr = item.split("+");
        secondChildren.push({
          code: arr[0],
          name: arr[1]
        });
      });

      this.secondLevelDiscipline.forEach(item => {
        secondChildren.forEach(el => {
          if (el.code.substring(0, 4) === item.code) {
            const is = Object.keys(item);
            if (is.includes("childList")) {
              item.childList.push(el);
            } else {
              item.childList = [el];
            }
          }
        });
        if (item.childList) {
          item.childList = item.childList.filter((item, index, array) => {
            return (
              array.findIndex(
                obj => obj.code === item.code && obj.name === item.name
              ) === index
            );
          });
        }
      });
      this.second = this.secondLevelDiscipline;
    },
    // 点击添加兴趣词
    handleaddBtn() {
      if (this.form.wordtags.length === 10) {
        this.$message({
          message: "兴趣词最多可添加十个",
          type: "warning"
        });
        return;
      }
      if (this.form.interestword === "") {
        this.$message({
          message: "请输入兴趣词",
          type: "warning"
        });
        return;
      }
      this.form.wordtags.push(this.form.interestword);
      this.form.interestword = "";
      this.form.wordtags = Array.from(new Set(this.form.wordtags));
    },
    // 确认数据
    async sureData() {
      if (this.form.name == "") {
        this.form.name = this.$store.state.userName;
      }
      this.firstList = [];
      this.subjectList.forEach(val => {
        if (!this.firstList.some(item => item.code === val.code)) {
          this.firstList.push({ code: val.code, name: val.name });
        }
      });
      if (this.threeLogin === "2") {
        // 本地登录
        this.requestUrl = "/userinfo/update";
      } else {
        // 第三方登录
        this.requestUrl = "/authentication/update/user";
      }
      const { data: res } = await this.$http({
        url: this.requestUrl,
        method: "POST",
        data: {
          // id: '',
          name: this.form.name,
          // phone: this.form.phone,
          email: this.form.mailbox,
          photo: this.form.photo,
          firstLevelDiscipline: this.firstList,
          interestWord: this.form.wordtags, // 兴趣词
          secondLevelDiscipline: this.subjectList
        }
      });

      if (res.code !== 200) return this.$message.error(res.msg);
      this.$message("保存成功");

      this.$store.commit("userName", this.form.name);
      this.form.name = this.$store.state.userName;
      this.$store.commit("setImage", this.imageUrl);

      // 修改默认学科树
      const firsttree = [];
      const secondtree = [];
      this.second.forEach(val => {
        if (val.childList && val.childList.length != 0) {
          firsttree.push(val.code);
          val.childList.forEach(item => {
            secondtree.push(item.code);
          });
        }
      });
      this.$store.commit("secondLevelList", secondtree);
      this.$store.commit("firstLevelList", firsttree);
    },
    handleRequire() {
      this.subjectList = [];
      this.subjectIds.forEach(val => {
        if (!this.subjectList.some(item => item.code === val[0])) {
          this.subjectList.push({ code: val[0], childList: [] });
        }
      });
      this.subjectIds.forEach(val => {
        this.subjectList.forEach(item => {
          if (
            item.code == val[0] &&
            !item.childList.some(i => i.code === val[1])
          ) {
            item.childList.push({ code: val[1] });
          }
        });
      });
      this.subjectList.forEach(val => {
        const arr = this.options.find(i => i.id === val.code);
        val.name = arr.label;
        val.childList.forEach(item => {
          item.name = arr.children.find(j => j.id === item.code).label;
        });
      });
      // if (this.subjectIds.length > 0) {
      //   this.rules.subjectIds[0].required = false
      // } else {
      //   this.rules.subjectIds[0].required = true
      // }
    },
    suerBtn() {
      if (!this.checkEmail()) {
        return;
      }
      if (this.flag) {
        this.second = JSON.parse(localStorage.getItem("two")) || "";
      }
      const local = JSON.parse(localStorage.getItem("first"));
      if (this.firstDisc) {
        this.firstDisc.forEach(item => {
          if (item.name === undefined) {
            local.forEach(el => {
              if (item.code === el.name) {
                item.code = el.code;
                item.name = el.name;
              }
            });
          }
        });
      }
      if (this.firstDisc) {
        this.firstDisc.forEach(item => {
          const is = Object.keys(item);
          if (is.includes("childList")) {
            delete item.childList;
          }
        });
      }
      this.sureData();
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isJPG && !isPNG) {
        this.$message({
          type: "warning",
          message: "上传头像图片只能是 JPG、JPEG或PNG 格式!"
        });
        // this.$message.error('上传头像图片只能是 JPG或PNG 格式!')
      }
      if (!isLt2M) {
        this.$message({
          type: "warning",
          message: "上传头像图片大小不能超过 1MB!"
        });
        // this.$message.error('上传头像图片大小不能超过 1MB!')
      }
      return (isJPG || isPNG) && isLt2M;
    }
  },
  watch: {
    $route: "updateTitle",
    "$store.state.imageUrl": {
      immediate: true,
      handler(data) {
        this.imageUrl = data;
      }
    },
    "$store.state.nickname": {
      immediate: true,
      handler(newVal) {
        this.userName = newVal;
      }
    }
  }
};
</script>

<style lang="less" scoped>
// 上传头像样式开始
/deep/.el-tag {
  cursor: pointer;
}

.wrodBox {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  justify-content: flex-start;
}

/deep/ .avatar-uploader {
  width: 110px;
  height: 110px;
  display: inline-block;
  vertical-align: top;

  .el-upload {
    width: 100%;
    height: auto;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    .after {
      display: none;
      width: 110px;
      height: 110px;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      top: 0;

      .remind {
        margin: 0 auto;
        color: #fff;
        font-size: 16px;
        font-weight: 550;
      }

      .one {
        margin-top: 40px;
      }

      .two {
        // margin-top: 50px;
      }
    }

    &:hover .after {
      display: block;
    }
  }
}

.avatar-uploader-icon {
  background: url("../../assets/images/head/Personalimg01.png");
  background-position: center center;
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 110px;
  text-align: center;
  border-radius: 50%;
  background-size: cover;
}

.avatar-uploader-icon::before {
  content: "";
}

.avatar {
  width: 110px;
  height: 110px;
  display: block;
  object-fit: cover;
}

// 上传头像样式结束
.el-tag {
  background: #f5f5f5;
  border-radius: 4px;
  border: none;
  margin-right: 10px;
  color: #000000;

  /deep/.el-tag__close {
    color: #000000;
  }
}

.requiredspot {
  /deep/.el-form-item__label::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}

/deep/.el-input {
  width: 400px;
  border-radius: 4px;

  .el-input__inner {
    // background: #f5f5f5;
    // border: none;
  }
}

/deep/.el-input--suffix {
  width: 600px;
}

.personalBox {
  width: 1156px;
  .basicmemberBox {
    width: 100%;
    height: 200px;
    background: url("../../assets/images/member/memberbg.png") no-repeat;
    display: flex;
    align-items: center;
    .lfmember {
      width: 200px;
      text-align: center;
      .uploadprompt {
        color: #999999;
        font-size: 12px;
        margin-top: 15px;
      }
    }
    .rtmember {
      flex: 1;
      display: flex;
      justify-content: space-evenly;
      .rtmemberlt {
        .menbertitle {
          .menbertitlefirst {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            .menbertitlejb {
              margin-left: 8px;
              > span {
                font-weight: 500;
                font-size: 18px;
                color: #000000;
              }
            }
          }
          .menbertitlemun {
            font-size: 14px;
            color: #999999;
          }
        }
        .menberitem {
          margin-top: 30px;
          display: flex;
          align-items: center;
          .menbergraup {
            margin-right: 60px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 200px;
            height: 60px;
            background: #fcf2e4;
            border-radius: 8px;
            .menbergrauptitlebox {
              .menbergrauptitle {
                font-size: 12px;
                color: #999999;
              }
              .menbergraupnum {
                font-size: 18px;
                color: #000000;
              }
            }
          }
        }
      }
      .rtmemberrt {
        width: 110px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        border-radius: 13px;
        border: 1px solid #9c2526;
        > span {
          font-size: 12px;
          color: #9c2526;
        }
      }
    }
  }
  .basicTitle {
    margin-bottom: 30px;

    .basicLine {
      margin-right: 10px;
      display: inline-block;
      width: 4px;
      height: 18px;
      background: #9c2526;
      vertical-align: text-bottom;
      box-sizing: border-box;
    }

    .basicTitle {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
      margin-right: 10px;
      box-sizing: border-box;
    }

    .basiclongLine {
      vertical-align: super;
      display: inline-block;
      width: 90%;
      height: 1px;
      background: #e6e6e6;
      border-radius: 1px;
      box-sizing: border-box;
    }
  }

  .inputbox {
    /deep/.el-input__inner {
      //background: #f5f5f5;
      // border: none;
    }
  }

  .formitem {
    margin-bottom: 10px;

    // &::before {
    //   content: "*";
    // }

    > span {
      display: inline-block;
      width: 115px;
      text-align: right;
      color: #333;
    }

    .request::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }

  .firstformBox {
    display: flex;

    .firstfromlf {
    }

    .firstfromrt {
      padding-left: 65px;

      .uploadprompt {
        color: #999999;
        font-size: 12px;
        margin-top: 15px;
      }
    }
  }

  .formitemword {
    display: flex;

    > span {
      display: inline-block;
      width: 100px;
      line-height: 40px;
      text-align: right;
      color: #333;
    }

    .el-input {
      width: 515px;
    }

    .el-button {
      width: 80px;
      margin-left: 5px;
    }
  }

  // .interestword {
  //   position: relative;
  //   width: 600px;
  //   min-height: 140px;
  //   border-radius: 4px;
  //   box-sizing: border-box;

  //   /deep/.el-input {
  //     width: 100%;

  //     .el-input__inner {
  //       min-height: 140px;
  //     }
  //   }

  //   .interestwordadd {
  //     position: absolute;
  //     bottom: 10px;
  //     right: 10px;
  //   }
  // }
  .wordtagAlert {
    margin-top: 10px;
    margin-bottom: 19px;
    font-size: 14px;
    color: #999;
  }

  .wordtagsTitle {
    margin: 10px 0;
    color: #9c2526;
  }

  .wordtags {
    cursor: pointer;
    // display: inline-block;
    margin-top: 5px;
  }

  .prompt {
    margin-top: 25px;
    color: #ff0000;
  }

  .sureBtn {
    width: 100%;
  }

  .surebtn {
    display: inline-block;
    text-align: center;
    width: 600px;
    margin-top: 60px;

    .el-button {
      width: 120px;
    }
  }
}
</style>
