<template>
  <!-- 学科专题 -->
  <div class="characteristic">
    <img src="../../assets/images/main/characteristic/charactbanner.jpg" width="100%" alt />
    <div class="gradeBox">
      <div class="linelf"></div>
      <div>
        <img src="../../assets/images/main/characteristic/gradeimg.png" class="img" alt />
        <span class="gradeTitle">会员主页</span>
      </div>
      <div class="linert"></div>
    </div>
    <div class="memberbg">
      <img src="../../assets/images/main/characteristic/memberbg.png" width="100%" alt />
    </div>
    <div class="membergrade">
      <div class="membergradecontent">
        <div class="membergradelf">
          <p class="membergradeTitle">关于会员等级</p>
          <div class="membergradeTxt">
            <p>通过做经验值的任务，提高经验值，并达到下一等级的分值区间，即可实现升级。</p>
            <!-- <p>积分消费之后，不会影响会员等级的变化。</p> -->
            <p>积分不清零。</p>
            <p>经验值不清零。</p>
          </div>
        </div>
        <div class="membergradert">
          <img src="../../assets/images/main/characteristic/gradbg.png" width="100%" alt />
        </div>
      </div>
    </div>
    <div class="membertask">
      <div class="membercontent">
        <div class="membertaskTitlebox">
          <img
            src="../../assets/images/main/characteristic/gradeimg.png"
            style="vertical-align: sub;"
            alt
          />
          <span class="membertaskTitle">积分任务</span>
        </div>
        <div class="membertasgroup">
          <div class="membertaskitem" v-for="(item,index) in membertaskitem" :key="index">
            <img
              class="itemimg"
              :src="require('../../assets/images/main/characteristic/' + item.src)"
              alt
            />
            <span class="itemtitle">{{item.title}}</span>
          </div>
        </div>
      </div>
      <div class="membercontent" style="margin-bottom:60px;">
        <div class="membertaskTitlebox">
          <img
            src="../../assets/images/main/characteristic/gradeimg.png"
            style="vertical-align: sub;"
            alt
          />
          <span class="membertaskTitle">经验值任务</span>
        </div>
        <div class="membertasgroup">
          <div class="membertaskitem" v-for="(item,index) in experienceitem" :key="index">
            <img
              class="itemimg"
              :src="require('../../assets/images/main/characteristic/' + item.src)"
              alt
            />
            <span class="itemtitle">{{item.title}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      membertaskitem: [
        {
          src: "memberitem01.png",
          title: "每日登录"
        },
        {
          src: "pinglunicon.png",
          title: "评论"
        },
        {
          src: "bijiicon.png",
          title: "公开笔记"
        },
        {
          src: "memberitem01.png",
          title: "点赞"
        },
        {
          src: "pinglunicon.png",
          title: "分享"
        },
        {
          src: "bijiicon.png",
          title: "收藏"
        },
        {
          src: "memberitem01.png",
          title: "点击文献"
        },
        {
          src: "pinglunicon.png",
          title: "消费"
        }
      ],
      experienceitem: [
        {
          src: "memberitem01.png",
          title: "每日登录 "
        },
        {
          src: "pinglunicon.png",
          title: "评论"
        },
        {
          src: "bijiicon.png",
          title: "公开笔记"
        },
        {
          src: "memberitem01.png",
          title: "点赞"
        },
        {
          src: "pinglunicon.png",
          title: "分享"
        },
        {
          src: "bijiicon.png",
          title: "收藏"
        },
        {
          src: "memberitem01.png",
          title: "点击文献"
        },
        {
          src: "pinglunicon.png",
          title: "消费"
        }
      ]
    };
  },
  mounted() {},
  methods: {
    async getdisciplinelist() {
      const { data: res } = await this.$http({
        url: "/topicList/getTopicList",
        method: "post",
        data: {
          page: this.page,
          pageRow: this.pageRow,
          content: this.disciplinesearch,
          firstLevelList: this.firstLevelList,
          secondLevelList: this.secondLevelList
        }
      });
      if (res.code != 200) return this.$message.error(res.msg);
      this.disciplineList = res.data.records;
    }
  },
  watch: {}
};
</script>

<style lang="less" scoped>
.characteristic {
  .gradeBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -4px;
    .linelf {
      width: 39.8%;
      height: 50px;
      background: #f5f5f5;
      border-radius: 0px 0px 40px 0px;
    }
    .linert {
      width: 39.8%;
      height: 50px;
      background: #f5f5f5;
      border-radius: 0px 0px 0px 40px;
    }
    img {
      margin-right: 16px;
      vertical-align: sub;
    }
    .gradeTitle {
      font-weight: 500;
      font-size: 24px;
      color: #9c2526;
    }
  }
  .membergrade {
    width: 100%;
    height: 520px;
    background-color: #f5f5f5;
    background-image: url("../../assets/images/main/characteristic/membergradebg.png");
    background-repeat: no-repeat;

    .membergradecontent {
      width: 1440px;
      margin: 0px auto;
      padding-top: 5%;
      display: flex;
      align-items: center;
      .membergradelf {
        .membergradeTitle {
          margin-bottom: 40px;
          font-weight: bold;
          font-size: 34px;
          color: #fffefe;
        }
        .membergradeTxt {
          & > p {
            margin-top: 30px;
            font-size: 18px;
            color: #fffefe;
            line-height: 27px;
          }
        }
      }
      .membergradert {
        width: 940px;
        height: 340px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.85);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .membertask {
    .membercontent {
      width: 1440px;
      margin: 0px auto;
      .membertaskTitlebox {
        margin: 57px 0 30px 0;
        .membertaskTitle {
          font-weight: 500;
          font-size: 28px;
          color: #000000;
          margin-left: 10px;
          margin-bottom: 33px;
        }
      }
      .membertasgroup {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .membertaskitem {
          width: 120px;
          text-align: center;
          .itemimg {
            width: 120px;
            height: 120px;
          }
          .itemtitle {
            font-size: 20px;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
