<template>
  <div v-loading.lock="isLoad" element-loading-spinner=none element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="institutionBox">
      <div class="institutionLeft">
        <authorTree :treeType="treeType"></authorTree>
      </div>
      <div class="institutionRight">
        <institutionRight @isLoadingShow='isLoadingShow'></institutionRight>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import authorTree from '../../components/author/authorTree'
import institutionRight from '../../components/institutio/institutionRight'
export default {
  components: { authorTree, institutionRight },
  data () {
    return {
      isLoad: false,
      treeType: {
        url: '/organization/count/disc',
        method: 'post',
        title: '学科领域'
      }
    }
  },
  mounted () {
    document.title = '机构' + this.$route.meta.title
  },
  methods: {
    isLoadingShow (data) {
      this.isLoad = data
    }
  }
}
</script>
<style lang="less" scoped>
.institutionBox {
  width: 75%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;

  .institutionLeft {
    width: 300px;
    margin-bottom: 20px;
  }

  .institutionRight {
    flex: 1;
    padding-left: 20px;
  }
}
</style>
