<template>
  <div class="menuBox">
    <el-menu :default-active="currentIndex" class="el-menu-vertical-demo">
      <el-menu-item :index="item.id" v-for="(item, index) in menuList" style="padding-left: 30px;" :key="index"
        @click="handleClick(item)">
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      threeReset: '',
      menuList: [
        {
          id: '0',
          name: '个人信息',
          path: '/user/personal'
        },
        {
          id: '1',
          name: '我的收藏',
          path: '/user/collect'
        },
        // {
        //   id: '2',
        //   name: '我的笔记',
        //   path: '/user/note'
        // },
        {
          id: '3',
          name: '我的订阅',
          path: '/user/subscribe'
        },
        {
          id: '4',
          name: '我的认领',
          path: '/user/myClaim'
        },
        {
          id: '5',
          name: '我的评论',
          path: '/user/myComment'
        },
        {
          id: '6',
          name: '通知消息',
          path: '/user/notice'
        },
        {
          id: '7',
          name: '修改密码',
          path: '/user/updatePassword'
        },
        {
          id: '8',
          name: '会员详情',
          path: '/user/memberdetail'
        },
        {
          id: '9',
          name: '我的订单',
          path: '/user/memberorder'
        },
        {
          id: '10',
          name: '浏览记录',
          path: '/user/memberrecord'
        }
      ],
      currentIndex: '0'
    }
  },

  methods: {
    // 选择菜单
    handleClick (item) {
      this.$router.push(item.path)
    }
  },

  watch: {
    '$route.path': {
      immediate: true,
      handler () {
        document.documentElement.scrollTop = 0
        this.menuList.some(val => {
          if (val.path == this.$route.path) {
            this.currentIndex = val.id
          }
        })
      }
    },
    '$store.state.threeReset': {
      immediate: true,
      handler (data) {
        if (data) {
          const index = this.menuList.findIndex(item => item.id === '7')
          if (index !== -1) {
            this.menuList.splice(index, 1)
          }
          console.log('监听认证中心修改密码', data)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu {
  border-right: transparent;
}

.el-menu-item {
  transition: none;
}

.menuBox {
  width: 100%;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;
  box-sizing: border-box;
}

.el-menu-item.is-active {
  // background-color: @my-color;
  color: #fff;
  border-radius: 5px;
  background-image: url("../../assets/images/home/userTabsBg.png");
  background-size: 200px 55px;
  background-repeat: no-repeat;
  background-color: #fff;
}
</style>
