<template>
  <div class="slider-check-box">
    <div v-if='isPhone === true' class="slider-check" :class="rangeStatus ? 'success' : ''">
      <i @touchstart='handleh5Move' :class="rangeStatus ? successIcon : startIcon"></i>
      {{ rangeStatus ? successText : startText }}
    </div>
    <div v-else class="slider-check" :class="rangeStatus ? 'success' : ''">
      <i @mousedown="rangeMove" :class="rangeStatus ? successIcon : startIcon"></i>
      {{ rangeStatus ? successText : startText }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 成功之后的函数
    successFun: {
      type: Function
    },
    // 成功图标
    successIcon: {
      type: String,
      default: 'el-icon-success'
    },
    // 成功文字
    successText: {
      type: String,
      default: '验证成功'
    },
    // 开始的图标
    startIcon: {
      type: String,
      default: 'el-icon-d-arrow-right'
    },
    // 开始的文字
    startText: {
      type: String,
      default: '请拖住滑块，拖动到最右边'
    },
    // 失败之后的函数
    errorFun: {
      type: Function
    },
    // 或者用值来进行监听
    status: {
      type: String
    }
  },
  data () {
    return {
      disX: 0,
      rangeStatus: false,
      isPhone: false
    }
  },
  mounted () {
    if (!this._isMobile()) {
      // } else {
      if (this.BASE_URL !== '/api/') {
        // 电脑端
        this.isPhone = false
      }
    } else {
      // 移动端
      this.isPhone = true
    }
  },
  methods: {
    _isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    // 移动端滑块移动
    handleh5Move (e) {
      const ele = e.target
      const h5startX = e.changedTouches[0].clientX
      const eleWidth = ele.offsetWidth
      const parentWidth = ele.parentElement.offsetWidth
      const MaxX = parentWidth - eleWidth
      if (this.rangeStatus) {
        // 不运行
        return false
      }
      document.ontouchmove = e => {
        const endX = e.changedTouches[0].clientX
        this.disX = endX - h5startX
        if (this.disX <= 0) {
          this.disX = 0
        }
        if (this.disX >= MaxX - eleWidth) {
          // 减去滑块的宽度,体验效果更好
          this.disX = MaxX
        }
        ele.style.transition = '.1s all'
        ele.style.transform = 'translateX(' + this.disX + 'px)'
        e.preventDefault()
      }
      document.ontouchend = () => {
        if (this.disX !== MaxX) {
          ele.style.transition = '.5s all'
          ele.style.transform = 'translateX(0)'
          // 执行成功的函数
          this.errorFun && this.errorFun()
        } else {
          this.rangeStatus = true
          if (this.status) {
            this.$parent[this.status] = true
          }
          // 执行成功的函数
          this.successFun && this.successFun()
        }
        document.ontouchmove = null
        document.ontouchend = null
      }
    },
    // 滑块移动
    rangeMove (e) {
      const ele = e.target
      const startX = e.clientX
      const eleWidth = ele.offsetWidth
      const parentWidth = ele.parentElement.offsetWidth
      const MaxX = parentWidth - eleWidth
      if (this.rangeStatus) {
        // 不运行
        return false
      }
      document.onmousemove = e => {
        const endX = e.clientX
        this.disX = endX - startX
        if (this.disX <= 0) {
          this.disX = 0
        }
        if (this.disX >= MaxX - eleWidth) {
          // 减去滑块的宽度,体验效果更好
          this.disX = MaxX
        }
        ele.style.transition = '.1s all'
        ele.style.transform = 'translateX(' + this.disX + 'px)'
        e.preventDefault()
      }

      document.onmouseup = () => {
        if (this.disX !== MaxX) {
          ele.style.transition = '.5s all'
          ele.style.transform = 'translateX(0)'
          // 执行成功的函数
          this.errorFun && this.errorFun()
        } else {
          this.rangeStatus = true
          if (this.status) {
            this.$parent[this.status] = true
          }
          // 执行成功的函数
          this.successFun && this.successFun()
        }
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}
</script>
<style scoped>
* {
  touch-action: pinch-zoom;
}

.slider-check-box {
  border-radius: 10px;
}

.slider-check-box .slider-check {
  background-color: #f7f7f7;
  position: relative;
  transition: 1s all;
  user-select: none;
  color: #c0c4cc;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

.slider-check-box .slider-check i {
  position: absolute;
  left: 0;
  width: 50px;
  height: 100%;
  color: #9C2526;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.slider-check-box .success {
  background-color: #9C2526;
  color: #fff;
}

.slider-check-box .success i {
  color: #9C2526;
}
</style>
