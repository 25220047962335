<template>
  <div class="institutionRtBox">
    <div class="authorsearchBox">
      <el-input class="searchBox institutionSearchBox" @keydown.enter.native="handleinstitusearch" clearable
        v-model="institusearchs" placeholder="输入机构名称"></el-input>
      <el-button type="primary" class="searchlistBtn" @click="handleinstitusearch">结果中搜索</el-button>
    </div>
    <div class="tip">
      <p class="institutionRtnum">
        为您查到
        <span class="institutionRtnumActive">{{ total || 0 }}</span>
        条数据
      </p>
      <span class="hottip">
        <div class="icon"></div>
        <span class="tiptext">温馨提示：学科分布红色标签为机构的双一流学科</span>
      </span>
    </div>
    <div class="institutionList">
      <el-table :data="tableData" :header-cell-style="{ background: 'rgba(245, 226, 224, 0.4)' }"
        @row-click="handleClick" @mousedown.native="loopZoom()" @mouseup.native="clearLoopZoom()">
        <el-table-column width="40" label="序号" align="center" type="index" :index="indexMethod"></el-table-column>
        <el-table-column width="70" prop="orgCover" label="标志" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.orgCover" v-if="scope.row.orgCover" alt="Image" class="tableImg" />
            <span class="tableImg2" :style="randomRgb((scope.$index + 1 * page) % 10)" v-else>
              {{ getFirstNonSpecialChar(scope.row.orgName) }}
            </span>
            <div class="topSchool">
              <img v-if="scope.row.isFirst === 1" src="../../assets/images/home/985211.png" alt />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="orgName" label="名称" width="80" align="center" class="institutionListName">
          <template slot-scope="scope">
            <keep-alive>
              <span class="institutiontitle">{{ scope.row.orgName }}</span>
            </keep-alive>
          </template>
        </el-table-column>
        <el-table-column width="60" prop="authorSize" label="代表作者数" align="center"></el-table-column>
        <el-table-column width="60" prop="literatureSize" label="入选主文献数" align="center"></el-table-column>
        <el-table-column width="60" prop="clickCount" label="主文献点击数" align="center"></el-table-column>
        <el-table-column width="60" prop="downCount" label="主文献下载数" align="center"></el-table-column>
        <el-table-column prop="prDiscOfLiterList" width="200" label="入选主文献学科分布" align="center">
          <template slot-scope="scope">
            <div v-if='scope.row.prDiscOfLiterList'>
              <div v-if="scope.row.prDiscOfLiterList.length <= 6">
                <span class="distribution"
                  :class="{ active: scope.row.isFirstDiscName !== null && scope.row.isFirstDiscName.indexOf(item) !== -1 }"
                  v-for="(item, index ) in scope.row.prDiscOfLiterList " :key="index">{{ item }}</span>
              </div>
              <div v-else>
                <span class="distribution" v-for="( item, index ) in scope.row.prDiscOfLiterList.slice(0, 6) "
                  :key="index"
                  :class="{ active: scope.row.isFirstDiscName !== null && scope.row.isFirstDiscName.indexOf(item) !== -1 }">{{
                    item }}</span>
              </div>
            </div>
            <div v-else>
              -
            </div>
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="noData">
            <img src="../../assets/images/home/nodatasmall.png" alt="">
            <span>暂无数据</span>
          </div>
        </template>
      </el-table>
    </div>
    <div class="pageBox" style="margin-bottom: 20px;" v-if='total && total > 0'>
      <div class="listgroupLine"></div>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[10, 20, 30, 40]" :page-size="pageRow"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {},
  data () {
    return {
      isLoad: false,
      tableData: [],
      page: 1, // 页码
      pageRow: 10, // 每页显示的条目数
      total: 0,
      institutionselecttree: [], // 选中学科树
      hotwordsearch: '', // 大搜索热词
      institutionlist: [], // 列表
      institusearch: '', // 机构搜索
      institusearchs: '', // 机构搜索
      flag: true,
      firstTime: '',
      lastTime: '',
      maxSearch: ''
    }
  },
  mounted () {
    document.querySelector('.searchBox .searchBtn').click()
    document.onkeydown = e => {
      var theEvent = window.event || e
      var code = theEvent.keyCode || theEvent.which || theEvent.charCode
      if (
        code == 13 &&
        document.activeElement.parentNode.className.indexOf('searchBox') != -1
      ) {
        this.getlistData()
      }
      if (
        code == 13 &&
        document.activeElement.parentNode.className.indexOf('searchInput') != -1
      ) {
        document.querySelector('.searchBox .searchBtn').click()
      }
    }
  },
  methods: {
    // 标志《特殊字符处理
    getFirstNonSpecialChar (str) {
      const specialCharRegex = /[^\u4e00-\u9fa5a-zA-Z0-9]/
      for (let i = 0; i < str.length; i++) {
        if (!specialCharRegex.test(str[i])) {
          return str[i]
        }
      }
      return ''
    },
    randomRgb (item) {
      console.log(item)
      const colorList = [
        '#f1af10',
        '#f1a871',
        '#21a2b7',
        '#83bccb',
        '#afb4db',
        '#78a254',
        '#de9462',
        '#7bbfea',
        '#e96a4e',
        '#6fb17c'
      ]
      const index = Math.floor(Math.random() * colorList.length)
      return {
        // backgroundImage: colorList[index]
        backgroundColor: colorList[index]
      }
    },
    indexMethod (index) {
      return (this.page - 1) * this.pageRow + index + 1
    },
    handleSizeChange (val) {
      this.page = 1
      this.pageRow = val
      this.getlistData()
    },
    handleCurrentChange (val) {
      this.page = val
      this.getlistData()
    },
    loopZoom () {
      this.firstTime = new Date().getTime()
      // this.timeOut = setTimeout(() => {
      //   this.flag = false
      // }, 500)
    },
    clearLoopZoom () {
      this.lastTime = new Date().getTime()
      if (this.lastTime - this.firstTime < 200) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    handleClick (row, event, column) {
      if (this.flag) {
        const routeData = this.$router.resolve({
          path:
            '/institutiondetail?name=' +
            row.orgCode +
            '&page=' +
            this.page +
            '&pageRow=' +
            this.pageRow
          // query: params
        })
        window.open(routeData.href, '_blank')
      }
    },
    async getlistData () {
      this.isLoad = true
      this.$emit('isLoadingShow', this.isLoad)
      try {
        const { data: res } = await this.$http({
          url: '/organization/search',
          method: 'POST',
          data: {
            current: this.page,
            opt: this.hotwordsearch, // 检索条件
            depOpt: this.institusearch, // 小搜索
            // orgName: '', // 机构名称
            prDiscNamesList: this.institutionselecttree, // 学科领域
            size: this.pageRow
          }
        })
        this.isLoad = false
        this.$emit('isLoadingShow', this.isLoad)
        if (res.code != 200) return this.$message.error(res.msg)
        this.tableData = res.data.rows
        this.total = res.data.total
        window.scrollTo(0, 0)
      } catch (err) {
        this.isLoad = false
        this.$emit('isLoadingShow', this.isLoad)
      }
    },
    // 机构搜索
    handleinstitusearch () {
      this.page = 1
      this.institusearch = this.institusearchs
      // this.institusearch = this.institusearch.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      this.$store.commit('setInstitutionSearch', this.institusearch)
      this.$store.commit('setInstitutionSearchTime', Date.now())
      if (document.querySelector('.searchInput')) {
        this.maxSearch = document.querySelector('.searchInput').children[0].value
      }
      if (this.$store.state.hotwordsearch.search !== '' && this.maxSearch === '') {
        this.$store.commit('handlehotwordsearch', '')
        this.$store.commit('handlehotwordsearchTime', Date.now())
      }
      console.log('first', this.$store.state.hotwordsearch.search)
      this.getlistData()
    }
  },
  watch: {
    // 监听选择学科领域树
    '$store.state.selectedauthor': {
      deep: true,
      // immediate: true,
      handler (data, oldData) {
        this.page = 1
        this.pageRow = 10
        const path = this.$route.fullPath
        const _path = path.split('?')[0]
        if (
          _path === '/institution' &&
          JSON.stringify(data) !== JSON.stringify(oldData)
        ) {
          this.institutionselecttree = data
          this.getlistData()
          return
        }
        if (JSON.stringify(data) !== JSON.stringify(oldData) && oldData !== undefined) {
          this.institutionselecttree = data
          this.getlistData()
        }
        // this.institutionselecttree = data;
        // this.page = 1;
        // this.pageRow = 10;
        // this.getlistData();
      }
    },
    // 监听大搜索框
    '$store.state.hotwordsearch': {
      deep: true,
      // immediate: true,
      handler (data, oldData) {
        this.hotwordsearch = data.search
        console.log(document.querySelector('.institutionSearchBox'))
        if (document.querySelector('.institutionSearchBox')) {
          const insitSearchEl = document.querySelector('.institutionSearchBox')
          this.institusearch = insitSearchEl.children[0].value
          console.log('输入内容', this.institusearch)
        }
        // this.$store.commit('setInstitutionSearch', this.institusearch)
        this.page = 1
        this.pageRow = 10
        this.getlistData()
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-table th.el-table__cell>.cell {
  color: #333;
}

.el-table::before {
  height: 0;
}

.el-table {
  // width: 1210px;

  .topSchool {
    width: 88px;
    height: 21px;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 999;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.institutionRtBox {
  .tip {
    display: flex;
    justify-content: space-between;

    .institutionRtnum {
      .institutionRtnumActive {
        font-size: 14px;
        font-weight: bold;
        color: #9c2526;
      }
    }

    .hottip {
      display: flex;

      .tiptext {
        font-size: 12px;
        font-weight: 400;
        color: #fe0000;
      }

      .icon {
        width: 16px;
        height: 16px;
        background: url("../../assets/images/home/tip.png");
        background-size: cover;
        background-position: center center;
        margin-right: 9px;
        margin-top: 1px;
      }
    }
  }

  // padding: 0 20px;
  .authorsearchBox {
    margin: 15px 0;

    .searchBox {
      width: 400px;
      height: 40px;

      /deep/.el-input__inner {
        background: #ffffff;
        border-radius: 4px;
      }
    }

    .searchlistBtn {
      margin-left: 10px;
    }
  }

  .institutionList {
    margin: 20px 0;
    width: 1210px;

    .tableImg {
      width: 66px;
      height: 66px;
      object-fit: contain;
      border-radius: 50%;
    }

    .tableImg2 {
      // margin-left: 10px;
      display: inline-block;
      color: #fff;
      width: 66px;
      height: 66px;
      object-fit: contain;
      border-radius: 50%;
      font-size: 20px;
      line-height: 66px;
      text-align: center;
      border-radius: 50%;
      object-fit: cover;
      vertical-align: middle;
      font-weight: 500;
    }

    .distribution {
      border: 1px solid #e0e0e0;
      border-radius: 12px;
      padding: 0px 8px;
      display: inline-block;
      margin: 5px;
      box-sizing: border-box;
    }

    .distribution.active {
      border: 1px solid #fe0000;
      border-radius: 12px;
      padding: 0px 8px;
      display: inline-block;
      margin: 5px;
      box-sizing: border-box;
      color: #fe0000;
    }

    .institutiontitle {
      cursor: pointer;
    }
  }
}

/deep/.el-table__header {
  width: 100% !important;
}

/deep/.el-table__body {
  width: 100% !important;
}

/deep/.el-table__row {
  width: 100% !important;
}

/deep/.el-table__empty-block {
  width: 100% !important;
}

.noData {
  margin-top: 20px;
  // margin: 0 auto;
  // width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
