<template>
  <!-- 学科专题 -->
  <div class="characteristic">
    <img src="../../assets/images/main/characteristic/charactbanner.jpg" width="100%" alt />
    <div class="gradeBox">
      <div class="linelf"></div>
      <div>
        <img src="../../assets/images/main/characteristic/gradeimg.png" class="img" alt />
        <span class="gradeTitle">特定选题</span>
      </div>
      <div class="linert"></div>
    </div>
    <div class="characteristicontnt">
      <div class="characteristicList">
        <div class="item" v-for="(item,index) in characteristicList" :key="index">
          <!-- <div class="item"> -->
          <div class="imgdiv">
            <img :src="item.fdfsUrl+item.pic" v-if="ROUTER_BASE_URL+item.pic" class="img" alt />
            <!-- <img
              src="../../assets/images/main/characteristic/characteristicitem.png"
              class="img"
              alt
            />-->
          </div>
          <div class="textBox">
            <div class="itemtxt">{{item.name}}</div>
            <div class="itembottom">
              <div class="itemleft">
                <span class="rmb">¥</span>
                <span class="monthmoney">{{item.priceValue}}/{{item.unit}}</span>
              </div>
              <div class="itemright" @click="handlebuy(item.id)">立即购买</div>
            </div>
          </div>
        </div>
      </div>
      <div class="characteristicBottom">
        <div class="botttomtext">
          <div class="botttomtextlf">
            <img src="../../assets/images/main/characteristic/thiswriteicon.png" class="img" alt />
          </div>
          <div class="botttomtextrt">
            <div class="botttomtextrttitle">中国人民大学书报资料中心 特定选题服务</div>
            <div class="botttomtextrtTelbox">
              <div>
                <img src="../../assets/images/main/characteristic/phone.png" class="imgphone" alt />
                <span class="phonetxt">联系人：胡老师</span>
              </div>
              <div style="margin-left:40px;">
                <img src="../../assets/images/main/characteristic/tel.png" class="imgtel" alt />
                <span class="phonetxt">联系方式：010-64039458</span>
              </div>
            </div>
            <div class="botttomtextrtsearchBox">
              <span class="searchtxt">选题介绍：https://mp.weixin.qq.com/s/QHO9fSc8oH9XoMjgqKb4Rw</span>
              <span class="searchbtn">立即查看</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {},
  data() {
    return {
      characteristicList: [], //列表
      pageRow: 10, //每页记录数
      page: 1,
      total: 0
    };
  },
  mounted() {
    this.getcharacterlist();
  },
  methods: {
    // 点击跳转特色
    // handlecharacteristic(id) {
    //   const routeData = this.$router.resolve({
    //     path: "/characteristic?id=' + id"
    //     // query: params
    //   });
    //   window.open(routeData.href, "_blank");
    // },
    async getcharacterlist() {
      const { data: res } = await this.$http({
        url: "/featuredTopic/getList",
        method: "post",
        data: {
          page: this.page,
          pageRow: this.pageRow
        }
      });
      if (res.code != 200) return this.$message.error(res.msg);
      this.characteristicList = res.data.pageList;
      this.total = res.data.count;
    },
    // 立即购买
    handlebuy(id) {
      // console.log("id",id)
      // return
      const routeData = this.$router.resolve({
        path: "/characteristicdetail?id=" + id
      });
      window.open(routeData.href, "_blank");
    }
  },
  watch: {}
};
</script>

<style lang="less" scoped>
.characteristic {
  .gradeBox {
    margin-top: -3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .linelf {
      width: 760px;
      height: 50px;
      background: #f5f5f5;
      border-radius: 0px 0px 40px 0px;
    }
    .linert {
      width: 760px;
      height: 50px;
      background: #f5f5f5;
      border-radius: 0px 0px 0px 40px;
    }
    img {
      width: 27px;
      height: 24px;
      margin-right: 16px;
      vertical-align: sub;
      overflow: hidden;
      object-fit: cover;
    }
    .gradeTitle {
      font-weight: 500;
      font-size: 24px;
      color: #9c2526;
    }
  }
  .characteristicontnt {
    width: 1440px;
    margin: 0px auto;
    margin-top: 66px;
    .characteristicList {
      display: flex;
    justify-content: space-between;
      flex-wrap: wrap;

      .item {
        width: 272px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        margin-bottom: 40px;
        .imgdiv {
          padding: 10px;
          box-sizing: border-box;
          .img {
            width: 252px;
            height: 148px;
            border-radius: 8px;
            overflow: hidden;
            object-fit: cover;
          }
        }
        .textBox {
          padding: 5px 10px;
          box-sizing: border-box;
          .itemtxt {
            font-weight: 500;
            font-size: 18px;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
          .itembottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0;
            .itemleft {
              color: #ff7100;
              .rmb {
                font-size: 20px;
                color: #ff7100;
                line-height: 27px;
              }
              .monthmoney {
                padding-left: 5px;
                font-weight: 500;
                font-size: 22px;
                color: #ff7100;
                line-height: 27px;
              }
            }
            .itemright:hover {
              cursor: pointer;
            }
            .itemright {
              width: 80px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              background: #8d2b2b;
              border-radius: 13px;
              color: #fff;
            }
          }
        }
      }
    }
    .characteristicBottom {
      margin-bottom: 38px;
      width: 1486px;
      height: 140px;
      box-sizing: border-box;
      background: url("../../assets/images/main/characteristic/charabottomimg.png")
        no-repeat;
      .botttomtext {
        display: flex;
        padding: 0 20px;
        align-items: baseline;
        .botttomtextlf {
          margin-right: 10px;
          .img {
            width: 24px;
            height: 24px;
            margin-right: 16px;
            vertical-align: sub;
            overflow: hidden;
            object-fit: cover;
            vertical-align: text-bottom;
          }
        }
        .botttomtextrt {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 140px;
          .botttomtextrttitle {
            font-weight: 500;
            font-size: 20px;
            color: #ff7100;
            line-height: 27px;
          }
          .botttomtextrtTelbox {
            display: flex;
            padding: 10px 0;
            box-sizing: border-box;
            .phonetxt {
              margin-left: 10px;
              font-size: 16px;
              font-weight: 500;
              color: #ff7100;
            }
            .imgphone {
              width: 9px;
              height: 14px;
              vertical-align: baseline;
              overflow: hidden;
              object-fit: cover;
            }
            .imgtel {
              width: 15px;
              height: 13px;
              vertical-align: baseline;
              overflow: hidden;
              object-fit: cover;
            }
          }
          .botttomtextrtsearchBox {
            width: 500px;
            height: 22px;
            background: rgba(255, 255, 255, 0.6);
            border-radius: 11px;
            border: 1px solid #ff7100;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .searchtxt {
              font-size: 12px;
              color: #ff7100;
              margin-right: 10px;
            }
            .searchbtn {
              width: 60px;
              height: 18px;
              line-height: 18px;
              font-size: 10px;
              background: #ff7100;
              border-radius: 9px;
              text-align: center;
              color: #fff;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
</style>
