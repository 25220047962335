import { render, staticRenderFns } from "./signagureHead.vue?vue&type=template&id=40b15299&scoped=true&"
import script from "./signagureHead.vue?vue&type=script&lang=js&"
export * from "./signagureHead.vue?vue&type=script&lang=js&"
import style0 from "./signagureHead.vue?vue&type=style&index=0&id=40b15299&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b15299",
  null
  
)

export default component.exports