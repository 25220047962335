<template>
  <div class="contentAll">
    <div class="user" v-if="total > 0" v-loading="loading" element-loading-spinner=none
      element-loading-background="rgba(0, 0, 0, 0.1)">
      <div class="item" v-for="(item, i) in list" :key="i">
        <div class="avator">
          <img v-if="item.userImg" :src="imgPrefix + item.userImg" alt />
          <img v-else :src="avator" alt />
        </div>
        <div class="_right">
          <div class="username">
            <span v-if="item.isAnonymous == 0">{{ item.userName }}</span>
            <span v-else>匿名用户</span>
            <i class="author" v-if="item.userFlag == '1' && item.isAnonymous == 0">作者</i>
          </div>
          <div class="content wi8">
            {{ item.comment }}
            <i class="state shz" v-if="item.state == 0"></i>
            <i class="state wtg" v-if="item.state == 2"></i>
            <el-popover placement="top-start" trigger="hover" width="200" popper-class="popoverStyle"
              v-if="item.state == 2 && item.rejectReason">
              <div class="popcontent">
                <i class="rejectReason"></i>
                <p style="flex:1;">{{ item.rejectReason }}</p>
              </div>
              <i slot="reference" class="state wtgreason"></i>
            </el-popover>
          </div>
          <div class="bar">
            <div class="bar-left">{{ item.createTime }}</div>
            <div class="bar-right">
              <div @click="handleEdit(item, i)" v-if="item.state == 2 && item.myself == '1'">
                <i class="icon edit" :class="{ active: applyIndex == i && applyIndex2 == -1 }"></i>
                <span v-if="applyIndex == i && applyIndex2 == -1">取消编辑</span>
                <span v-else>编辑</span>
              </div>
              <div @click="handleDetail(item, i)" v-if="item.myself == '1'">
                <i class="icon delete"></i>
                <span>删除</span>
              </div>
              <div @click="handleReplayClick(item, i)" v-if="item.state != 2 && item.state != 0">
                <i class="icon hf" :class="{ active: applyIndex == i && applyIndex2 == -1 }"></i>
                <span v-if="applyIndex == i && applyIndex2 == -1">取消回复</span>
                <span v-else>回复</span>
              </div>
              <div @click="handleLike(item, i)" v-if="item.state != 0 && item.state != 2">
                <i class="icon dz" :class="{ active: item.isLike == 1 }"></i>
                <span>{{ item.isLikes }}</span>
              </div>
            </div>
          </div>
          <send :secCurrent='currentPage' :secSize='secSize' :ref="'send' + i" :opts="sendOpts"
            @emitSendSuccess="emitSendSuccess" :closeComment="closeComment">
          </send>
          <div class="children" v-if="item.twoSecondComment && item.twoSecondComment.length > 0">
            <div class="item _item" v-for="(item2, i2) in item.twoSecondComment || []" :key="i2">
              <div class="avator">
                <img v-if="item2.userImg" :src="imgPrefix + item2.userImg" alt />
                <img v-else :src="avator" alt />
              </div>
              <div class="_right">
                <div class="username">
                  <span class="_u">
                    {{ item2.userName }}
                    <i class="author" v-if="item2.userFlag == '1'">作者</i>
                  </span>
                  <span class="_t">回复</span>
                  <span class="_u">
                    <span>{{ item2.replyUserName }}</span>
                    <!-- <span>匿名用户</span> -->
                    <i class="author" v-if="item2.replayUserAuthor">作者</i>
                  </span>
                </div>
                <div class="content wi7">
                  {{ item2.comment }}
                  <i class="state shz" v-if="item2.state == 0"></i>
                  <i class="state wtg" v-if="item2.state == 2"></i>
                  <el-popover placement="top-start" trigger="hover" width="200" popper-class="popoverStyle"
                    v-if="item2.state == 2 && item2.rejectReason">
                    <div class="popcontent">
                      <i class="rejectReason"></i>
                      <p style="flex:1;">{{ item2.rejectReason }}</p>
                    </div>
                    <i slot="reference" class="state wtgreason"></i>
                  </el-popover>
                </div>
                <div class="bar">
                  <div class="bar-left">{{ item2.createTime }}</div>
                  <div class="bar-right">
                    <div @click="handleEdit2(item2, i2, item, i)" v-if="item2.state == 2 && item2.myself == 1">
                      <i class="icon edit" :class="{ active: applyIndex == i && applyIndex2 == i2 }"></i>
                      <span v-if="applyIndex == i && applyIndex2 == i2">取消编辑</span>
                      <span v-else>编辑</span>
                    </div>
                    <div @click="handleDetail(item2, i)" v-if="item2.myself == '1'">
                      <i class="icon delete"></i>
                      <span>删除</span>
                    </div>
                    <div @click="handleReplayClick2(item2, i2, item, i)" v-if="item2.state != 2 && item2.state != 0">
                      <i class="icon hf" :class="{ active: applyIndex == i && applyIndex2 == i2 }"></i>
                      <span v-if="applyIndex == i && applyIndex2 == i2">取消回复</span>
                      <span v-else>回复</span>
                    </div>
                    <div @click="handleLike(item2, i)" v-if="item2.state != 0 && item2.state != 2">
                      <i class="icon dz" :class="{ active: item2.isLike == 1 }"></i>
                      <span>{{ item2.isLikes }}</span>
                    </div>
                  </div>
                </div>
                <send :secCurrent='currentPage' :secSize='secSize' :ref="'send_' + i + '_' + i2" :opts="sendOpts"
                  @emitSendSuccess="emitSendSuccess" :closeComment="closeComment"></send>
              </div>
            </div>
            <div class="more" @click="handleMoreClick(item)" v-if="item.twoSecondComment.length >= 2">
              查看更多回复
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <el-pagination @current-change="handleCurrentChange" :current-page="currentPage"
        layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
      <commentDialog ref="dialog" @emitSendSuccess="emitSendSuccess" @emitlist='emitlistData'></commentDialog>
      <login ref="login" class='homeLoginBigBox' @registeropen="registeropen"></login>
      <register ref="register" @loginopen="loginopen" @emitSuccessRegister="$refs.login.modelopen = true"></register>
    </div>
    <div class="empty" v-else>
      <img src="../../assets/images/home/nodataside.png" alt />
      <span>暂无评论,快去发表您的评论吧</span>
    </div>
  </div>
</template>

<script>
import login from '../../page/login'
import register from '../../page/register'
import send from './send.vue'
import commentDialog from './dialog.vue'
import { threeHandleLogin } from '../../utils/threeLogin'
export default {
  props: ['sort'],
  components: {
    send,
    commentDialog,
    login,
    register
  },
  data () {
    return {
      // secCurrent: 1,
      secSize: 10,
      // 加载
      loading: false,
      // 头像前缀
      imgPrefix: '',
      // 文献编号
      referenceNumber: '',
      // 回复索引
      applyIndex: -1,
      applyIndex2: -1,
      // 默认头像
      avator: require('@/assets/images/main/comment/touxiang.png'),
      // 列表数据
      list: [],
      // 当前页
      currentPage: 1,
      // 总条数
      total: 0,
      // 评论列表参数
      sendOpts: {},
      // 关闭子评论(时间戳)
      closeComment: 0,
      showModal: false,
      islogin: false
    }
  },
  mounted () {
    this.subjectId = this.$route.query.id
    // this.getpersonal()
    this.getData()
  },

  methods: {
    // 发送到子组件
    emitlistData () {
      this.getData()
    },
    loginhandle () {
      this.$refs.login.modelopen = true
    },
    registeropen (value) {
      this.$refs.register.registershow = value
    },
    loginopen (value) {
      this.$refs.login.modelopen = value
    },
    // async getpersonal () {
    //   // 查询个人信息
    //   const { data: res } = await this.$http({
    //     url: '/userinfo',
    //     method: 'GET'
    //   })
    //   if (res.code === 115001 && localStorage.getItem('token')) {
    //     // 删除token
    //     localStorage.removeItem('expiration')
    //     localStorage.removeItem('userImage')
    //     localStorage.removeItem('userName')
    //     localStorage.removeItem('phone')
    //     localStorage.removeItem('token')
    //     this.$store.commit('secondLevelList', [])
    //     this.$store.commit('firstLevelList', [])
    //     this.islogin = false
    //     return this.$message.error('登录已失效,请重新登录')
    //   } else {
    //     if (res.code !== 200) return
    //   }
    //   if (res.code === 200) {
    //     this.islogin = true
    //     if (res.data.url == null) {
    //       res.data.url = ''
    //     }
    //     this.$store.commit('userName', res.data.nickName)
    //     this.$store.commit('setImage', res.data.url + res.data.photo)
    //     // this.$store.commit('imageUrl', res.data.url + res.data.photo)
    //     this.token = localStorage.getItem('token')
    //   }
    // },
    // 获取评论列表
    async getData () {
      this.loading = true
      const orderType = this.sort.type
      let upOrDown = null
      if (orderType == 1) {
        upOrDown = this.sort.time
      } else {
        upOrDown = this.sort.hot
      }
      const { data: res } = await this.$http({
        url: '/subjectComment/loadCommentPage',
        method: 'post',
        data: {
          current: this.currentPage,
          size: this.secSize,
          subjectId: this.subjectId,
          orderType: orderType,
          upOrDown: upOrDown,
          secCurrent: 1,
          secSize: 2
        }
      })
      this.loading = false
      if (res.code != 200) return this.$message.error(res.msg)
      this.list = res.data.rows || []
      this.total = res.data.total
      if (res.data.rows.length > 0) {
        this.imgPrefix = res.data.rows[0].imgPrefix
      }
    },
    // 删除评论
    async getDelete (item) {
      const { data: res } = await this.$http({
        url: 'subjectComment/delete',
        method: 'post',
        data: {
          id: item.id,
          parentId: item.parentId
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.$emit('emitSendSuccess')
    },
    // 点赞 & 取消点赞
    async handleLike (item, i) {
      const { data: res } = await this.$http({
        url: '/subjectComment/commentLike',
        method: 'post',
        data: {
          id: item.id,
          type: item.isLike == 0 ? 1 : 0,
          subjectId: this.subjectId
        }
      })
      if (res.code === 115001) {
        // return this.loginhandle()
        return threeHandleLogin(this.THREE_LOGIN_OR)
      }
      if (res.code != 200) return this.$message.error(res.msg)
      this.$emit('emitSendSuccess')
    },
    // 编辑
    handleEdit (item, index) {
      this.closeComment = new Date().getTime()
      if (this.applyIndex == index) {
        this.applyIndex = -1
        this.applyIndex2 = -1
        return
      }
      this.applyIndex = index
      const userName = item.isAnonymous == 0 ? item.userName : '匿名用户'
      this.sendedit = {
        id: item.id,
        textarea: item.comment,
        anonymous: item.isAnonymous,
        parentId: item.parentId,
        replyCommentId: item.replyCommentId,
        referenceNumber: item.referenceNumber
      }
      this.$nextTick(() => {
        this.$refs['send' + index][0].init(
          'edit',
          userName,
          item.id,
          item.comment
        )
      })
    },
    // 点击子评论编辑
    handleEdit2 (item2, index2, item, index) {
      // 先关闭其他评论
      this.closeComment = new Date().getTime()

      if (this.applyIndex2 == index2) {
        this.applyIndex = -1
        this.applyIndex2 = -1
        return
      }
      this.applyIndex = index
      this.applyIndex2 = index2
      const userName = item2.isAnonymous == 0 ? item2.userName : '匿名用户'
      this.sendedit = {
        id: item2.id,
        textarea: item2.comment,
        anonymous: item2.isAnonymous,
        parentId: item2.parentId,
        replyCommentId: item2.replyCommentId,
        referenceNumber: item2.referenceNumber
      }
      this.$nextTick(() => {
        this.$refs['send_' + index + '_' + index2][0].init(
          'edit',
          userName,
          item2.id,
          item2.comment
        )
      })
    },
    // 点击回复
    handleReplayClick (item, index) {
      if (this.islogin == false) {
        // 未登录
        // return this.loginhandle()
        return threeHandleLogin(this.THREE_LOGIN_OR)
      } else {
        // 先关闭其他评论
        this.closeComment = new Date().getTime()

        if (this.applyIndex == index) {
          this.applyIndex = -1
          this.applyIndex2 = -1
          return
        }
        this.applyIndex = index
        const userName = item.isAnonymous == 0 ? item.userName : '匿名用户'
        this.sendOpts = {
          parentId: item.id,
          replyCommentId: item.id
        }
        this.$nextTick(() => {
          this.$refs['send' + index][0].init('replay', userName)
        })
      }
    },
    // 点击子评论回复
    handleReplayClick2 (item2, index2, item, index) {
      if (this.islogin == false) {
        // 未登录
        // return this.loginhandle()
        return threeHandleLogin(this.THREE_LOGIN_OR)
      } else {
        // 先关闭其他评论
        this.closeComment = new Date().getTime()

        if (this.applyIndex2 == index2) {
          this.applyIndex = -1
          this.applyIndex2 = -1
          return
        }
        this.applyIndex = index
        this.applyIndex2 = index2
        const userName = item2.isAnonymous == 0 ? item2.userName : '匿名用户'
        this.sendOpts = {
          parentId: item.id,
          replyCommentId: item2.id
        }
        this.$nextTick(() => {
          this.$refs['send_' + index + '_' + index2][0].init(
            'replay',
            userName
          )
        })
      }
    },
    // 点击查看更多
    handleMoreClick (item) {
      if (this.islogin == false) {
        // 未登录
        // return this.loginhandle()
        return threeHandleLogin(this.THREE_LOGIN_OR)
      } else {
        this.$refs.dialog.init(item.id)
      }
    },
    // 分页切换
    handleCurrentChange (val) {
      this.currentPage = val
      this.getData()
    },
    // 监听评论成功
    emitSendSuccess () {
      this.applyIndex = -1
      this.applyIndex2 = -1
      this.$emit('emitSendSuccess')
    },
    // 点击删除
    handleDetail (item) {
      this.$confirm('您确定要删除当前评论吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.getDelete(item)
        })
        .catch(() => { })
    }
  },
  watch: {
    '$store.state.token': {
      immediate: true,
      handler (newData, oldData) {
        if (newData !== oldData) {
          if (newData) {
            this.islogin = true
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.popcontent {
  display: flex;
  max-height: 260px;
  overflow-y: scroll;
  background-color: #9c2526;
  color: #fff;

  .rejectReason {
    margin-right: 10px;
    vertical-align: bottom;
    width: 16px;
    height: 19px;
    display: inline-block;
    vertical-align: bottom;
    // margin-left: 10px;
    background: url("../../assets/images/main/comment/rejectReasonicon.png") no-repeat center;
    background-size: contain;
  }
}

.user {
  .item {
    margin-top: 30px;
    display: flex;

    .avator {
      width: 60px;
      height: 60px;
      object-fit: contain;
      border-radius: 50%;

      >img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    ._right {
      flex: 1;
      margin-left: 20px;

      .username {
        color: #000;
        font-size: 16px;
        margin-top: 8px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;

        .author {
          font-style: normal;
          height: 18px;
          background: #fabd49;
          border-radius: 4px;
          color: #fff;
          line-height: 18px;
          padding: 0 8px;
          text-align: center;
          margin-left: 10px;
          box-sizing: border-box;
          font-size: 12px;
        }
      }

      .wi7 {
        width: 770px;
      }

      .wi8 {
        width: 880px;
      }

      .content {
        position: relative;
        font-size: 16px;
        line-height: 24px;
        word-wrap: break-word;

        .state {
          width: 74px;
          height: 25px;
          display: inline-block;
          vertical-align: bottom;
          margin-left: 10px;

          &.shz {
            background: url("../../assets/images/main/comment/shenhezhong.png") no-repeat center;
            background-size: contain;
          }

          &.wtg {
            background: url("../../assets/images/main/comment/weitongguo.png") no-repeat center;
            background-size: contain;
          }
        }

        .wtgreason {
          position: relative;
          width: 18px;
          height: 18px;
          display: inline-block;
          vertical-align: bottom;
          margin-left: 10px;
          background: url("../../assets/images/main/comment/question.png") no-repeat center;
          background-size: contain;
        }
      }

      .bar {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bar-left {
          color: #999;
        }

        .bar-right {
          display: flex;
          align-items: center;
          color: #000000;

          >div {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-left: 28px;

            .icon {
              display: inline-block;
              margin-right: 10px;

              &.hf {
                width: 18px;
                height: 16px;
                background: url("../../assets/images/main/comment/reply.png") no-repeat center;
                background-size: contain;

                &.active {
                  background: url("../../assets/images/main/comment/reply-act.png") no-repeat center;
                  background-size: contain;
                }
              }

              &.dz {
                width: 17px;
                height: 17px;
                background: url("../../assets/images/main/comment/like.png") no-repeat center;
                background-size: contain;

                &.active {
                  width: 17px;
                  height: 17px;
                  background: url("../../assets/images/main/comment/like-act.png") no-repeat center;
                  background-size: contain;
                }
              }

              &.delete {
                width: 17px;
                height: 17px;
                background: url("../../assets/images/main/comment/delete.png") no-repeat center;
                background-size: contain;
              }

              &.edit {
                width: 17px;
                height: 17px;
                background: url("../../assets/images/main/comment/edit.png") no-repeat center;
                background-size: contain;
              }
            }
          }
        }
      }

      .children {
        background: #f5f5f7;
        border-radius: 10px;
        padding: 20px;
        margin-top: 16px;

        .item {
          margin-top: 0;
          margin-bottom: 20px;

          .avator {
            padding-top: 15px;
          }

          ._right {
            border-top: 1px solid #e3e3e6;
            padding-top: 15px;
          }

          ._u {
            font-size: 16px;
            color: #000;
            display: flex;
            align-items: center;
          }

          ._t {
            font-size: 16px;
            color: #666;
            margin: 0 13px;
            display: flex;
            align-items: center;
          }

          &._item:nth-of-type(1) {
            .avator {
              padding-top: 0;
            }

            ._right {
              border: none;
              padding-top: 0;
            }
          }
        }

        .more {
          font-size: 16px;
          color: #9c2526;
          cursor: pointer;
        }

        /deep/textarea {
          border: 1px solid #dcdfe6;
          background: #fff;
        }
      }
    }
  }
}

.contentAll {
  .empty {
    margin-top: 65px;
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      width: 232px;
      height: 114px;
    }

    span {
      color: #999;
      font-size: 14px;
    }
  }
}
</style>
