export default {
    install(Vue, options) {
        Vue.prototype.$meta = function (name, content) {
            const metas = document.getElementsByTagName('meta');
            for (let i = 0; i < metas.length; i++) {
                if (metas[i].getAttribute('name') === name) {
                    console.log(metas[i]);
                    metas[i].setAttribute('content', content);
                    break;
                }
            }
        };
    }
};