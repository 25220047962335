<template>
  <div class="main">
    <div class="searchBox" @keydown.enter="searchbtn">
      <!-- <el-input v-model="searchData.referenceNumber" clearable placeholder="请输入文献编号"></el-input> -->
      <el-input
        v-model="searchDatas.title"
        clearable
        placeholder="请输入文献题目"
      ></el-input>
      <el-input
        v-model="searchDatas.authorship"
        clearable
        placeholder="请输入文献作者"
      ></el-input>
      <el-date-picker
        :picker-options="pickerOptions"
        v-model="searchtimes"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="请选择认领时间"
      ></el-date-picker>
      <el-button
        type="primary"
        v-preventClick
        style="margin-left: 10px"
        @click="searchbtn"
        >查询</el-button
      >
      <div class="context">
        <div class="right">
          <el-button type="primary" @click="refund" v-if="tableData.length != 0"
            >退领文献</el-button
          >
          <el-button
            type="primary"
            @click="lookpdf"
            v-if="tableData.length != 0"
            >预览证书</el-button
          >
        </div>
      </div>
    </div>
    <div class="table">
      <el-table
        :row-key="getRowKey"
        :header-cell-style="tableHead"
        :data="tableData"
        class="elTab"
        ref="tables"
        align="left"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        v-if="pageData.total > 0"
      >
        <el-table-column
          align="center"
          width="60"
          type="selection"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column align="center" label="序号" width="60">
          <template #default="scoped">{{
            (pageData.current - 1) * pageData.size + scoped.$index + 1
          }}</template>
        </el-table-column>
        <el-table-column
          width="300"
          prop="title"
          label="文献题目"
          show-overflow-tooltip
          align="center"
        >
          <template #default="scoped">
            <span @click="handleGoToDetail(scoped.row)">{{
              scoped.row.title
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="90"
          prop="authorship"
          label="文献作者"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="levelInfo"
          label="文献所属学科"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          width="130"
          prop="claimTime"
          label="认领时间"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" align="center" label="操作">
          <template #default="scope">
            <el-button
              v-if="scope.row.preview === true"
              type="text"
              size="small"
              @click="
                downloadFile(
                  scope.row.id,
                  scope.row.path,
                  scope.row.referenceNumber,
                  scope.row.title
                )
              "
              >下载</el-button
            >
            <!-- <el-button type="text" size="small" v-if="scope.row.isReprint != 1"
            @click="handleread(scope.row.path, scope.row.referenceNumber)">在线阅读</el-button>-->
            <!-- <span class="onlineTolook1"> -->
            <el-button
              type="text"
              size="small"
              v-if="scope.row.preview === true"
              @click="
                handlereadOnline(scope.row.path, scope.row.referenceNumber)
              "
            >
              在线预览
              <!-- <div class="tootl">
                  <div class="top">此文数据正在获取中</div>
                  <div class="bottom"></div>
              </div>-->
            </el-button>
            <!-- </span> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="empty" v-else>
        <img src="../../assets/images/home/nodataside.png" alt />
        <span>暂无数据,快去认领您的文献吧</span>
      </div>
      <div class="elPagination" v-if="pageData.total > 0">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageData.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageData.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="35%"
      :style="{ minHeight: '200px' }"
    >
      <!-- 证书的页面 -->
      <div class="html2canvasbox" id="pdfDom">
        <div class="pdfcontent">
          <div class="padimg pdfitemcontent">
            <img src="../../assets/images/main/myclaim/myclaimtopimg.png" alt />
          </div>
          <div class="padtitleimg pdfitemcontent">
            <img
              src="../../assets/images/main/myclaim/myclaimbgtitleimg.png"
              alt
            />
          </div>
          <div class="pdfhead pdfitemcontent">
            <div class="pdfName">{{ pafname }}：</div>
            <div class="pdftype">
              <span>{{ pdfstartTime }}--{{ pafendTime }}</span>
              <span>年间，您的如下论文被收录进入哲学社会科 学主文献平台：</span>
            </div>
          </div>
          <div class="pdflistbox" ref="textContainer">
            <div class="pdflist">
              <div
                v-for="(item, i) in previewtableData"
                :key="i"
                class="pdfitem pdfitemcontent"
              >
                <span>{{ i + 1 }}.</span>
                <span>{{ pafname }}，</span>
                <span>《{{ item.title }}》</span>
                <span v-if="item.originInfo">原载于{{ item.originInfo }}</span>
                <span
                  v-if="item.journalInfo === '' || item.journalInfo === null"
                  >。</span
                >
                <span v-if="item.journalInfo"
                  >，转载于{{ item.journalInfo }}。</span
                >
              </div>
            </div>
            <div class="pdffooter">
              <div class="prove">特此证明。</div>
              <div class="footerrt">
                <div></div>
                <div class="footertext">
                  <img
                    src="../../assets/images/main/myclaim/seal1.png"
                    class="seal1"
                    alt
                  />
                  <!-- <img src="../../assets/images/main/myclaim/seal2.png" class="seal2" alt=""> -->
                  <p class="addressline">
                    中国人民大学中国哲学社会科学自主知识体系数字创新平台
                  </p>
                  <!-- <p>中国人民大学书报资料中心</p> -->
                  <p class="timeline">{{ today }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="padimg">
            <img
              src="../../assets/images/main/myclaim/myclaimbottomimg.png"
              alt
            />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="downloadCertificate"
          >下载证书</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import platform from "platform";
import { downloadPDF } from "@/utils/pdf.js"; // 工具方法，导出操作
export default {
  components: {
    // addDialog,
    // intoDialog
  },
  data() {
    return {
      preview: false,
      dialogVisible: false,
      selectionReferenceNumber: [], // 选取的数据
      pageData: {
        current: 1, // 当前页
        size: 10, // 每页数量
        total: 0, // 总数量
      },
      tableData: [],
      previewtableData: [],
      searchData: {
        referenceNumber: "",
        title: "",
        authorship: "",
      },
      searchDatas: {
        referenceNumber: "",
        title: "",
        authorship: "",
      },
      searchtime: "",
      searchtimes: "",
      multipleSelection: [],
      textheight: 0,
      pafname: "",
      pafendTime: "",
      pdfstartTime: "",
      pdfprinttitle: "_哲社主文献入选证书",
      today: "", // 当前年月日
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  mounted() {
    this.today = this.getCurrentDate();
    document.title = this.$route.meta.title;

    this.goSearch();
  },
  created() {},
  computed: {},
  methods: {
    // 跳转到文献详情
    handleGoToDetail(row) {
      // const routeData = this.$router.resolve({
      //   path: 'institutiondetail?name=' + code + '&page=' + 1 + '&pageRow=' + 10
      //   // query: params
      // })
      // window.open(routeData.href, '_blank')
      const routeData = this.$router.resolve({
        path:
          "/mainDetails?id=" +
          row.litersId +
          "&referenceNumber=" +
          row.referenceNumber +
          "&path=" +
          row.path,
      });
      window.open(routeData.href, "_blank");
    },
    getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      return year + "年" + month + "月" + day + "日";
    },
    tableHead() {
      return "background-color: #f2f2f2;text-align:center;color:#9c2526";
    },
    tableCellStyle() {
      return "border: 1px";
    },
    async goSearch() {
      const res = await this.$http({
        url: "/userinfo/mineClaim",
        method: "POST",
        data: {
          page: this.pageData.current,
          pageRow: this.pageData.size,
          referenceNumber: this.searchData.referenceNumber,
          title: this.searchData.title,
          authorName: this.searchData.authorship,
          claimTime: this.searchtime,
        },
      });
      this.pageData.total = res.data.data.count;
      this.tableData = res.data.data.list;
      this.pafname = res.data.data.realName;
      this.pafendTime = res.data.data.endTime;
      this.pdfstartTime = res.data.data.startTime; // 开始时间
      this.preview = res.data.preview;
      // 原发期刊
    },
    // 预览列表数据
    async previewlist() {
      const res = await this.$http({
        url: "/userinfo/mineAllClaim",
        method: "POST",
        data: this.selectionReferenceNumber,
      });
      this.previewtableData = res.data.data.list;
      this.pafname = res.data.data.realName;
      this.pafendTime = res.data.data.endTime;
      this.pdfstartTime = res.data.data.startTime; // 开始时间
    },
    // 每页显示数量改变时执行
    handleSizeChange(val) {
      this.pageData.current = 1;
      this.pageData.size = val;
      this.goSearch();
    },
    // 页数切换执行
    handleCurrentChange(val) {
      this.pageData.current = val;
      this.goSearch();
    },
    searchbtn() {
      this.pageData.size = 10;
      this.pageData.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.searchDatas));
      this.searchtime = this.searchtimes;
      // this.searchData.title = this.searchData.title.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')

      // this.searchData.authorship = this.searchData.authorship.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      this.goSearch();
    },
    refund() {
      if (!this.multipleSelection.length) {
        return this.$message.error("请选择需要退领的文献");
      } else {
        this.$confirm("是否确认退领文献?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const vallist = [];
            this.multipleSelection.forEach((val) => {
              vallist.push(val.id);
            });
            const { data: res } = await this.$http({
              url: "/userinfo/refundClaim",
              method: "POST",
              data: {
                list: vallist,
                operatingSystem: platform.os.family || "",
                browserType: platform.name || "",
              },
            });
            if (res.code !== 200) {
              return this.$message({
                message: res.msg,
                type: "warning",
              });
            }
            this.pageData.current = 1;
            this.$message({
              type: "success",
              message: "退领成功!",
            });
            this.goSearch();
            this.multipleSelection = [];
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.selectionReferenceNumber = val.map((item) => {
        return item.referenceNumber;
      });
    },
    getRowKey(row) {
      return row.id;
    },
    // 在线阅读
    handleread(path, number) {
      const routeData = this.$router.resolve({
        path: "/reading?path=" + path + "&referenceNumber=" + number,
      });
      window.open(routeData.href, "_blank");
    },
    handlereadOnline(path, number) {
      const routeData = this.$router.resolve({
        path: "/looking?referenceNumber=" + number,
      });
      window.open(routeData.href, "_blank");
    },
    // 点击下载数据
    async downloadFile(id, path, number, title) {
      const res = await this.$http({
        url:
          "/detail/downloadPdf?referenceNumber=" + number + "&title=" + title,
        method: "get",
        responseType: "blob",
      });
      const content = res.data;
      // 重点
      const blob = new Blob([content], {
        type: "application/pdf;charset=UTF-8", // word文档为msword,pdf文档为pdf
      });
      const objectURL = URL.createObjectURL(blob);
      const downEle = document.createElement("a");
      const fname = title; // 下载文件的名字
      downEle.href = objectURL;
      downEle.setAttribute("download", fname);
      document.body.appendChild(downEle);
      downEle.click();
    },

    lookpdf() {
      // 预览证书的数据
      this.previewlist();
      this.dialogVisible = true;
    },

    // 下载证书
    async downloadCertificate() {
      this.dialogVisible = false;
      window.scrollTo(0, 0);
      this.outPutPdfFn();
    },
    outPutPdfFn() {
      const vm = this;
      const A4_WIDTH = 592.28;
      const A4_HEIGHT = 841.89;
      vm.$nextTick(() => {
        // dom的id。
        const target = document.getElementById("pdfDom");
        const pageHeight = (target.scrollWidth / A4_WIDTH) * A4_HEIGHT;
        // 获取分割dom，此处为class类名为item的dom
        const lableListID = document.getElementsByClassName("pdfitemcontent");
        // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
        for (let i = 0; i < lableListID.length; i++) {
          const multiple = Math.ceil(
            (lableListID[i].offsetTop + lableListID[i].offsetHeight) /
              pageHeight
          );
          if (this.isSplit(lableListID, i, multiple * pageHeight)) {
            const divParent = lableListID[i].parentNode; // 获取该div的父节点
            const newNode = document.createElement("div");
            newNode.className = "emptyDiv";
            // newNode.style.background = "#ffffff";
            const _H =
              multiple * pageHeight -
              (lableListID[i].offsetTop + lableListID[i].offsetHeight);
            newNode.style.height = _H + 20 + "px";
            newNode.style.width = "100%";
            const next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
            // 判断兄弟节点是否存在
            if (next) {
              // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
              divParent.insertBefore(newNode, next);
            } else {
              // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
              divParent.appendChild(newNode);
            }
          }
        }
        // 传入title和dom标签，此处是 #content
        // 异步函数，导出成功后处理交互
        this.getPdf(this.pafname + this.pdfprinttitle)
          .then(() => {
            // 自定义等待动画关闭
            // this.$myLoading(false);
            this.$message({
              type: "success",
              message: "导出成功",
            });
            this.detailSHow = false;
          })
          .catch(() => {
            // this.$myLoading(false);
            this.$message({
              type: "error",
              message: "导出失败，请重试",
            });
          });
      });
    },
    isSplit(nodes, index, pageHeight) {
      // console.log('77', nodes, index, pageHeight)
      // console.log('66', nodes[index].offsetTop, nodes[index].offsetHeight)
      // 计算当前这块dom是否跨越了a4大小，以此分割
      if (
        nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight &&
        nodes[index + 1] &&
        nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped lang="less">
* {
  box-sizing: border-box;
}

/deep/.el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// 证书打印
.html2canvasbox {
  position: relative;
  // width: 427px;
  background: url("../../assets/images/main/myclaim/myclaimbg.png") center
    center;
  box-sizing: border-box;

  .pdfcontent {
    width: 600px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .padimg {
      > img {
        width: 580px;
      }
    }

    .padtitleimg {
      > img {
        width: 205px;
        height: 81px;
      }
    }

    .pdfhead {
      width: 413px;
      // padding-top: 15px;
      // padding: 40px;
      // padding: 15px 40px 0;
      box-sizing: border-box;

      .pdfName {
        font-size: 16px;
        font-weight: 600;
        color: #1a1a1a;
        padding-bottom: 15px;
      }

      .pdftype {
        line-height: 22px;
        font-size: 14px;
        font-weight: 600;
        text-indent: 24px;
        padding-bottom: 15px;

        > span {
          font-size: 14px;
          color: #1a1a1a;
        }
      }
    }

    .pdflistbox {
      width: 413px;
      // padding: 0px 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }

    .pdflist {
      .pdfitem {
        padding-bottom: 20px;
        text-indent: 24px;

        > span {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: #4d4d4d;
        }
      }
    }

    .pdffooter {
      .prove {
        text-indent: 24px;
        font-weight: 600;
        font-size: 14px;
        padding-bottom: 40px;
        padding-top: 5px;
        color: #1a1a1a;
      }

      .footerrt {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .footertext {
          text-align: center;
          position: relative;

          .seal1 {
            position: absolute;
            right: 75px;
            top: -25px;
            width: 70px;
          }

          .seal2 {
            position: absolute;
            right: 10px;
            top: -25px;
            width: 70px;
          }

          .addressline {
            line-height: 22px;
            font-size: 12px;
            color: #1a1a1a;
            font-weight: 600;
            margin-bottom: 13px;
          }

          .timeline {
            position: absolute;
            right: 66px;
            line-height: 22px;
            font-size: 12px;
            color: #1a1a1a;
            font-weight: 600;
            margin-bottom: 13px;
          }
        }
      }
    }
  }
}

.main {
  overflow-y: auto;
  background: #fff;
  height: calc(100%-80px);
  width: 1156px;
  padding: 20px;

  .searchBox {
    .el-input {
      width: 200px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .context {
    height: calc(100% - 40px);
    margin-top: 20px;

    .right {
      float: right;
      padding-bottom: 20px;
    }
  }

  .table {
    height: calc(100% - 90px);

    /deep/.el-table {
      height: calc(100% - 50px) !important;
    }

    .empty {
      margin-top: 65px;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        width: 232px;
        height: 114px;
      }

      span {
        color: #999;
        font-size: 14px;
      }
    }

    .elPagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
    }
  }
}

/deep/.el-tooltip {
  width: 100% !important;
}

/deep/.el-table__row:hover {
  color: #9c2526;
  cursor: pointer;
}
</style>
