<template>
  <div class="footerBox">
    <div class="footerContentBox">
      <div class="footerContent">
        <div class="footerContentlf">
          <ul>
            <li class='curpor' @click="gotoUni">
              <!-- 中国人民大学 -->
              <img src="../../assets/images/footer/school.png" alt="">
            </li>
            <li class='curpor' @click="gotoAcademic">
              <!-- 学术世界 -->
              <img src="../../assets/images/footer/sutdyWorld.png" alt="">
            </li>
            <li class='curpor' @click="gotoPlatform">
              <!-- 预印本 -->
              <img src="../../assets/images/footer/logoLittle.png" alt="">
            </li>
            <li class='curpor' @click="gotoOfficialWebsite">
              <!-- 书报中心 -->
              <img src="../../assets/images/footer/schoolCenter.png" alt="">
            </li>
          </ul>
        </div>
      </div>
      <div class="border"></div>
      <!-- </div> -->
      <div class="footerLine"></div>
      <div class="footerfilings">
        <div class="left">
          <div class="top">
            <span @click="introdution" style="cursor: pointer;">平台简介</span>
            <!-- <span class="footerstationline"></span> -->
            <span @click="coypright" style="cursor: pointer;">版权声明</span>
            <!-- <span class="footerstationline"></span> -->
            <span @click="answer" style="cursor: pointer;">常见问题解答</span>
          </div>
          <div class="bottom">
            <div class="bottomLeft">
              <img src="../../assets/images/footer/qrCode.png" alt="">
            </div>
            <div class="bottomRight">
              <ul>
                <li>
                  <span>主办：中国人民大学</span>
                </li>
                <li>
                  <span>承办：中国人民大学书报资料中心</span>
                </li>
                <li>
                  <span>Copyright 2024 中国人民大学 All Rights Reserved.</span>
                </li>
                <li class='showList'>
                  <img src="../../assets/images/footer/filingicon.png" alt="">
                  <span class='one'>京公网安备11040102700184号</span>
                  <span class='two'>京ICP备05066828号-46</span>
                </li>
                <li>
                  <span>（署）网出证（京）字第090号</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="bottom">
            <span class="footerText">
              Copyright {{ new Date().getFullYear() }} 中国人民大学 All Rights Reserved. -->
          <!-- <span class='hoverLink' @click='openWebNumber'>京公网安备11010102000001号</span> -->
          <!-- </span>
            <span class="filingtxt" @click="gotoNumber">
              <img src="../../assets/images/footer/filingicon.png" alt />
              <span>京公网安备11040102700184号</span>
            </span>

            <span @click="openWeb" class="filings">京ICP备05066828号-46</span>
          </div> -->
        </div>
        <div class="right">
          <div class="top imgLogo">
            <!-- <img src="../../assets/images/footer/footerlogo.png" alt=""> -->
          </div>
          <div class="bottom">
            <div class="bottomLeft">
              <ul>
                <!-- <li><span>电话：010-62519375</span></li> -->
                <li><span>邮箱：zszwx@ruc.edu.cn</span></li>
                <li><span>地址：北京市海淀区中关村大街甲59号文化大厦13-14层</span></li>
              </ul>
            </div>
            <div class="bottomRight">
              <div class="imgInstitutions">
                <img src="../../assets/images/footer/institutions.png" alt="">
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data () {
    return {

    }
  },
  components: {},
  methods: {
    // openWebNumber() {
    //   window.open("https://beian.mps.gov.cn/#/query/webSearch");
    // },
    gotoUni () {
      window.open('https://www.ruc.edu.cn/')
    },
    openWeb () {
      // window.open('https://beian.miit.gov.cn/#/Integrated/index')
    },
    // 去学术世界
    gotoAcademic () {
      window.open('https://xueshushijie.cn')
    },
    // 去预印本平台
    gotoPlatform () {
      window.open('https://zsyyb.cn/')
      // window.location.href = 'http://10.224.255.229/home.htm'
    },
    // 去书报中心
    gotoOfficialWebsite () {
      window.open('http://zlzx.ruc.edu.cn')
      // window.location.href = 'http://zlzx.ruc.edu.cn'
    },
    gotoNumber () {
      // window.open('https://beian.mps.gov.cn/#/query/webSearch?code=11040102700184')
    },
    introdution () {
      const routeData = this.$router.resolve({
        path: '/introduction'
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    coypright () {
      const routeData = this.$router.resolve({
        path: '/copyright'
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    answer () {
      const routeData = this.$router.resolve({
        path: '/answer'
        // query: params
      })
      window.open(routeData.href, '_blank')
    }
  },
  mounted () {
    // const targetElement = document.querySelector('.imgInstitutions')
    // if (targetElement) {
    //   // 创建一个 span 元素
    //   var spanElement = document.createElement('span')
    //   spanElement.id = '_ideConac'

    //   // 创建 script 元素
    //   var scriptElement = document.createElement('script')
    //   scriptElement.src = 'https://dcs.conac.cn/js/33/000/0000/61074140/CA330000000610741400007.js'
    //   scriptElement.type = 'text/javascript'

    //   // 将 span 和 script 元素添加到目标元素中
    //   targetElement.appendChild(spanElement)
    //   targetElement.appendChild(scriptElement)
    // } else {
    //   console.error('没有找到具有 .imgInstitutions 类的元素')
    // }
  }
}
</script>

<style scoped lang="less">
.footerBox {
  width: 100%;
  // height: 300px;
  background: #9c2526;
  position: relative;
  display: block;
  box-sizing: border-box;

  .border {
    margin: 28px auto 0;
    width: 1440px;
    height: 1px;
    background-color: #ba4748;
    // transform: translateX(15%);
    text-align: center;
  }

  .link {
    color: #fff;
    width: 369px;
    height: 14px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-left: 220px;
    margin-bottom: 21px;

    p {
      cursor: pointer;
    }
  }

  .footerContentBox {
    margin: 0 auto;
    width: 100%;

    .footerContent {
      padding-top: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 30px 12% 0;

      // border-bottom: 1px solid #BA4748;
      .footerContentlf {
        ul {
          display: flex;
          justify-content: space-between;

          li {
            box-sizing: border-box;
            width: 340px;
            height: 100px;
            background-color: #8a1d1e;
            margin-right: 27px;
            padding: 24px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              // width: 248px;
              height: 51px;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .footerContentrt {
        // cursor: pointer;
        height: 80px;
        // border-bottom: 1px solid #fff;

        img {
          height: 100%;
        }
      }

      .footerTitle {
        margin-bottom: 20px;
        font-size: 16px;
        color: #fff;

        .footerstationline {
          display: inline-block;
          margin: 0 25px;
          width: 1px;
          height: 14px;
          background: #ffffff;
        }
      }

      .footercontact {
        >ul li {
          padding: 3px 0;

          >span {
            font-weight: 300;
            color: #fff;
            font-size: 12px;
          }
        }
      }
    }

    .footerLine {
      margin: 15px 0 0 0;
      width: 100%;
      height: 1px;
      background: #9c2526;
    }
  }

  .footerfilings {
    width: 1440px;
    // padding-top: 15px;
    // text-align: center;
    // color: #fff;
    // background-color: #9c2526;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .left {
      display: flex;
      flex-direction: column;

      .top {
        span {
          color: #fff;
          padding-right: 24px;
          border-right: 2px solid #fff;
          margin-left: 24px;

          &:last-child {
            border: 0;
          }

          &:first-child {
            margin: 0;
          }
        }

      }

      .bottom {
        display: flex;
        margin-top: 20px;

        .bottomLeft {
          img {
            width: 127px;
            height: 127px;
          }
        }

        .bottomRight {
          margin-left: 20px;

          ul {

            li {
              color: #fff;
              margin-top: 5px;

              &:first-child {
                margin-top: 0;
              }
            }

            .showList {
              img {
                width: 17px;
                height: 18px;
                vertical-align: middle;
              }

              .one {
                margin-left: 10px;
              }

              .two {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;

      .top {
        img {
          width: 249px;
          // height: 52px;
        }
      }

      .bottom {
        display: flex;
        // margin-top: 50px;

        .bottomLeft {
          margin-top: 30px;

          ul {
            li {
              margin-top: 5px;

              span {
                color: #fff;
              }
            }
          }
        }

        .bottomRight {
          margin-left: 59px;
        }
      }
    }

    // align-items: center;
    // justify-content: center;
    // padding-bottom: 10px;

    // .filings {
    //   // margin-top: 15px;
    //   margin-left: 30px;
    //   //cursor: pointer;
    // }

    // .filingtxt {
    //   margin-left: 30px;
    //   // cursor: pointer;

    //   >img {

    //     height: 20px;
    //     vertical-align: bottom;
    //   }

    //   >span {
    //     margin-left: 10px;
    //     color: #fff;
    //     text-decoration: none;
    //   }
    // }
  }
}

.imgInstitutions {
  // display: inline-block;
  // width: 48px;
  // height: 58px;
  // margin-left: 80px;
  // margin-bottom: 10px;
  pointer-events: none;

  /deep/img {
    width: 60px !important;
    margin-top: 20px;
    // height: 74px !important;
  }
}

.curpor {
  cursor: pointer;
}
</style>
