<template>
  <div class="swiperBox">
    <div class="swiper-container" ref="headerBanner">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(img, index) in bannerList" :key="index">
          <img style='width: 100%' class="imgcenter" :src="require('../../assets/images/head/' + img.imgUrl)" />
        </div>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  data () {
    return {
      bannerList: [
        {
          imgUrl: 'headswiper01.jpg'
        },
        {
          imgUrl: 'headswiper02.jpg'
        },
        {
          imgUrl: 'headswiper03.jpg'
        }
        // {
        //   imgUrl: 'headswiper04.jpg'
        // }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // 下面是普通swiper模板
      new Swiper(this.$refs.headerBanner, {
        loop: true,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false // 解决滑动后不能轮播的问题

        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.swiper-container {
  text-align: center;

  .swiper-button-next,
  .swiper-button-prev {
    content: "";
    width: 48px;
    height: 48px;
    border-radius: 6px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .swiper-button-next {
    top: 40%;
    right: 12%;
    background-image: url("../../assets/images/head/rightjt.jpg");
    background-size: cover;
    /* 设置左箭头图标 */
  }

  .swiper-button-prev {
    top: 40%;
    left: 12%;
    background-image: url("../../assets/images/head/leftjt.jpg");
    background-size: cover;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    content: "";
  }

  @media screen and (min-width: 1600px) {
    .swiper-slide {
      height: 390px;
      position: relative;
    }

    .imgcenter {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      // width: 100% !important;
      // margin-left: calc(-1920px / 2);
    }
  }

  // 1300~1600
  @media screen and (max-width: 1600px) and (min-width: 1300px) {
    .swiper-slide {
      height: 390px;
      position: relative;
    }

    .imgcenter {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      // width: 100% !important;
      // margin-left: calc(-1920px / 2);
    }
  }

  // 小于1300
  @media screen and (max-width: 1300px) {
    .swiper-slide {
      height: 390px;
      position: relative;
    }

    .imgcenter {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      // margin-left: calc(-1920px / 2);
    }
  }
}

.swiperBox {}
</style>
