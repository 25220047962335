<template>
  <!-- 修改密码 -->
  <div class="container">
    <el-form ref="updata" :model="updata">
      <el-form-item prop="oldPassword">
        <label slot="label"><span style="color: red">*</span> 请输入旧密码:</label>
        <el-input :type='flagType1' v-model="updata.oldPassword"></el-input>
        <i :class="[this.flag1 ? 'iconfont icon-yanjing' : 'iconfont icon-biyanjing']" @click='getFlag1()'></i>
      </el-form-item>
      <el-form-item prop="password">
        <label slot="label"><span style="color: red">*</span> 请输入新密码:</label>
        <el-input :type='flagType2' v-model="updata.password"></el-input>
        <i :class="[this.flag2 ? 'iconfont icon-yanjing' : 'iconfont icon-biyanjing']" @click='getFlag2()'></i>
      </el-form-item>
      <el-form-item prop="surePassword">
        <label slot="label"><span style="color: red">*</span> 请确认新密码:</label>
        <el-input :type='flagType3' @blur="examinePassword" v-model="updata.surePassword"></el-input>
        <i :class="[this.flag3 ? 'iconfont icon-yanjing' : 'iconfont icon-biyanjing']" @click='getFlag3()'></i>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="onSubmit">提交</el-button>
  </div>
</template>
<script>
export default {
  data () {
    return {
      flag1: false,
      flag2: false,
      flag3: false,
      flagType1: 'password',
      flagType2: 'password',
      flagType3: 'password',
      updata: {
        oldPassword: '',
        password: '',
        surePassword: ''
      },
      flag: true
    }
  },
  methods: {
    getFlag1 () {
      this.flag1 = !this.flag1
      this.flagType1 = this.flag1 ? 'text' : 'password'// text为显示密码；password为隐藏密码
    },
    getFlag2 () {
      this.flag2 = !this.flag2
      this.flagType2 = this.flag2 ? 'text' : 'password'// text为显示密码；password为隐藏密码
    },
    getFlag3 () {
      this.flag3 = !this.flag3
      this.flagType3 = this.flag3 ? 'text' : 'password'// text为显示密码；password为隐藏密码
    },
    examinePassword () {
      if (this.updata.password !== this.updata.surePassword) {
        this.$message({
          message: '请确认两次密码输入一致',
          type: 'warning'
        })
        this.flag = false
      } else {
        this.flag = true
      }
    },
    async onSubmit () {
      if (!this.updata.oldPassword) return this.$message.error('请输入旧密码')
      if (!this.updata.password) return this.$message.error('请输入新密码')
      if (!this.updata.surePassword) return this.$message.error('请再次输入新密码')
      if (this.flag) {
        const { data: res } = await this.$http({
          url: '/userinfo/updatePassword',
          method: 'POST',
          data: {
            oldPassword: this.updata.oldPassword,
            password: this.updata.password
          }
        })
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message({
          type: 'success',
          message: '修改成功，请重新登录'
        })
        localStorage.removeItem('token')
        this.$store.commit('secondLevelList', [])
        this.$store.commit('firstLevelList', [])
        this.$router.push('/index')
      }
    }
  },
  mounted () {
    document.title = this.$route.meta.title
  }
}
</script>
<style lang='less' scoped>
.el-form-item {
  position: relative;

  i {
    position: absolute;
    left: 580px;
    color: #ccc
  }
}

.container {

  .el-button {
    // left: 50%;
    // margin: 0 auto;
    margin-left: 240px;
    // transform: translate(-50%);
  }
}

/deep/.el-form-item__label {
  padding: 0 !important;
}

/deep/.el-input {
  width: 500px;
  margin-left: 10px;
}
</style>
