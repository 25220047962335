<template>
  <div class="tenlistrt" @click="goView(item.id)"
    :style="isInfo ? '' : 'border-bottom: 1px solid #e6e6e6;margin-top: 15px;'">
    <div class="headerBox flex" :style="isInfo ? 'margin-left: 8px;' : 'cursor:pointer;margin-left: 2px;'">
      <span v-if="!isInfo">{{ index + 1 }}.</span>
      <div class="headerIcon flex" :style="isInfo ? 'margin-left:0;' : ''">
        <div class="hot" v-if="item.hot" :style="isInfo ? '' : 'margin-left: 5px;'">HOT</div>
        <div class="tj" v-if="item.recommend">推荐</div>
      </div>
      <h1 class="overflow-dot">{{ item.name }}</h1>
    </div>
    <div :class="isInfo ? 'context' : 'context2'">
      <div :class="isExpand ? 'overText' : ''">
        <div v-if="isInfo && isExpand" class="expend" @click="handleExpand">展开<i class="el-icon-arrow-down"></i></div>
        入选理由：{{ item.reason }}
        <div class="put" v-if="isInfo && isPut" @click="handlePut">收起<i class="el-icon-arrow-up"></i></div>
      </div>
    </div>
    <div class="hotcomment" v-if="isInfo">
      <div class="left" @click="setisClick">
        <img class="hotgif" src="../../assets/images/home/hot.gif" alt />
        <div class="content">
          <img class="debate" src="../../assets/images/home/hot.png" alt />
          <div class="text">诚邀您发表对学术热点的讨论~</div>
        </div>
        <img class="hand" src="../../assets/images/home/hand.gif" alt />
      </div>
      <div class="year" v-if="isInfo">入选年份：{{ item.year }}</div>
    </div>
    <div class="commentInfo" v-if="isInfo">
      <span class="title">专家点评</span>
      <div class="comment">{{ item.expertComment }}</div>
      <div class="userInfo">
        <img class="userImg" style="border-radius: 50%;" v-if="item.headPortrait" :src="item.headPortrait" />
        <img class="userImg" style="border-radius: 50%;" v-else
          src="../../assets/images/main/tensciencelist/userImg.png" />

        <div class="userName">
          <span>点评人：</span>
          <span class="commentcontent" style="color: #9c2526;">{{ item.commentPeople }}</span>
        </div>
      </div>
    </div>
    <div class="comm" v-if="!isInfo">
      <div class="userInfo">
        <div class="userImg">
          <img style="border-radius: 50%;" v-if="item.headPortrait" :src="item.headPortrait" />
          <img style="border-radius: 50%;" v-else src="../../assets/images/main/tensciencelist/userImg.png" />
        </div>
        <div class="userName">
          <span>点评人:</span>
          <span class="commentcontent" style="color: #9c2526;margin-left: 10px;">{{ item.commentPeople }}</span>
        </div>
      </div>
      <div class="time" v-if="!isInfo">入选年份: {{ item.year }}</div>
    </div>
    <!-- <div class="bottomBox flex padding">
    <div class="viewBtn" v-if="isInfo" @click="cardIsShow = !cardIsShow">查看专家点评</div>-->

    <!-- 专家点评弹窗 -->
    <!-- <el-card class="box-card" v-if="cardIsShow">
        <div class="text item">{{ item.expertComment }}</div>
    </el-card>-->
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    isInfo: {
      type: Boolean,
      default: false
    },
    item: {},
    index: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      cardIsShow: false,
      isClick: 'third',
      isExpand: false,
      isPut: false
    }
  },
  mounted () {
  },

  methods: {
    handleExpand () {
      this.isExpand = false
    },
    handlePut () {
      this.isExpand = true
    },
    async isClock (id) {
      const { data: res } = await this.$http({
        url: '/academic/click',
        method: 'post',
        data: {
          id
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
    },
    // 跳转十大热点详情
    goView (id) {
      if (!this.isInfo) {
        const routeData = this.$router.resolve({
          path: '/tenscienceInfo',
          query: {
            id
          }
        })
        window.open(routeData.href, '_blank')
      }
    },
    setisClick () {
      this.$emit('transfer', this.isClick)
    }
  },
  watch: {
    'item.reason' (newValue, oldValue) {
      if (newValue.length > 400) {
        this.isExpand = true
        this.isPut = true
      }
    }
  }
}
</script>

<style scoped lang="less">
.tenlistrt {
  // border-bottom: .0521vw solid #e6e6e6;

  .headerBox {
    span {
      font-size: 18px;
      font-weight: 500;
      color: #010101;
      line-height: 18px;
    }

    .headerIcon {
      .hot {
        width: 36px;
        height: 20px;
        background: #ff0000;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 20px;
      }

      .tj {
        width: 36px;
        height: 20px;
        background: #ff7100;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 20px;
        margin-left: 10px;
      }
    }

    h1 {
      font-size: 18px;
      font-weight: 500;
      color: #010101;
      margin-left: 10px;
      line-height: 18px;
    }
  }

  .context {
    margin-left: 8px;
    margin-top: 13px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    word-wrap: break-word;
    word-break: break-all;

    &::before {
      content: ' ';
      float: right;
      width: 0px;
      height: 72px;
      background: red;
    }

    .expend {
      display: inline-block;
      float: right;
      // margin-top: 72px;
      clear: both;
      cursor: pointer;
    }

    .put {
      float: right;
      cursor: pointer;
    }
  }

  .overText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    &::before {
      content: ' ';
      float: right;
      width: 0px;
      height: 72px;
      background: red;
    }

    .expend {
      display: inline-block;
      float: right;
      // margin-top: 72px;
      clear: both;
      cursor: pointer;
    }
  }

  .context2 {
    margin-top: 13px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    word-wrap: break-word;
    word-break: break-all;
  }

  .hotcomment {
    margin-top: 4px;
    height: 30px;
    width: 100%;
    cursor: pointer;

    .left {
      display: flex;
      position: absolute;

      .hotgif {
        width: 30px;
        height: 30px;
      }

      .content {
        margin-top: 7px;
        width: 250px;
        height: 20px;
        background: #f2f2f5;
        border-radius: 6px;
        display: flex;

        .debate {
          width: 60px;
          height: 12px;
          margin-top: 4px;
          margin-left: 8px;
        }

        .text {
          margin-left: 10px;
          font-size: 12px;
          font-weight: 400;
          color: #000000;
          line-height: 20px;
        }
      }

      .hand {
        position: relative;
        width: 29px;
        height: 22px;
        top: 10px;
        right: 14px;
      }
    }

    .year {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      text-align: right;
      line-height: 36px;
    }
  }

  .commentInfo {
    margin-left: 8px;
    margin-top: 9px;
    padding: 21px 19px 11px 20px;
    width: 1361px;
    background: #f5f5f5;
    border-radius: 10px;

    .title {
      font-size: 14px;
      font-weight: bold;
      color: #9c2526;
      margin-left: 2px;
    }

    .comment {
      margin-top: 16px;
      margin-left: 1px;
      font-size: 14px;
      color: #333333;
      line-height: 24px;
      text-indent: 2em;
      max-height: 196px;
      overflow: auto;
      word-break: break-all;
    }

    .userInfo {
      width: 100%;
      // height: 30px;
      display: flex;
      margin-top: 10px;

      .userImg {
        width: 30px;
        height: 30px;
      }

      .userName {
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        margin-left: 8px;

        .commentcontent {
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
  }

  .comm {
    margin-top: 9px;

    .userInfo {
      display: flex;

      .userImg {
        img {
          width: 30px;
          height: 30px;
        }
      }

      .userName {
        line-height: 30px;
        margin-left: 8px;

        .commentcontent {
          word-wrap: break-word;
          word-break: break-all;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
        }
      }
    }

    .time {
      margin-top: 10px;
      font-size: 14px;
      color: #666666;
    }
  }
}
</style>
