<template>
  <!-- 主文献列表 -->
  <div class="mainList" v-loading.fullscreen="isLoad" element-loading-spinner="none"
    element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="listSearch">
      <div class="yearSearch">
        <el-date-picker class='startYearSearch' style="width:157.5994px;" v-model="startTimes" type="year"
          placeholder="起始发表年份" value-format="yyyy" :picker-options="pickerOptions"
          @change="handleStaryears"></el-date-picker>
        <span>至</span>
        <el-date-picker class='endYearSearch' style="width:157.5994px;border: 0;" v-model="endTimes" type="year"
          placeholder="结束发表年份" value-format="yyyy" :picker-options="pickerOptions"
          @change="handleEndyears"></el-date-picker>
      </div>
      <el-input class="searchBox mainLisSearchBox" v-model.trim="resultContents"
        placeholder="请输入主文献题目、编号、作者名称、机构单位、期刊名称、摘要、关键词检索" clearable></el-input>
      <el-button type="primary" class="searchlistBtn" style="display: flex; justify-content: center"
        @click="handlesearchlistBtn">结果中搜索</el-button>
    </div>

    <div class="searchword">
      <div class="leftsearchword">
        <div class="searchwordItem" v-for="(item, index) in tabList" :key="index"
          :class="{ active: currentIndex == index }" @click="handleClick(item, index, currentIndex)">
          <span>{{ item.name }}</span>
          <div v-if="item.sortOrder === 'desc'" class="el-icon-bottom"></div>
          <div v-else-if="item.sortOrder === 'asc'" class="el-icon-top"></div>
          <!-- <i v-if="item.sortOrder === 'desc'" class="el-icon-bottom" style="font-size: 10px"></i>
          <i v-else-if="item.sortOrder === 'asc'" class="el-icon-top" style="font-size: 10px"></i>-->
        </div>
      </div>
    </div>
    <div class="nuntxt">
      <div>
        <span class="setFz">为您查到</span>
        <span class="searchNum">{{ total }}</span>
        <span class="setFz">条数据</span>
      </div>
      <div class="rtsearchword" @click="handleperson">
        <span class="dyrecommend">订阅兴趣学科，为您进行主文献个性化推荐。</span>
      </div>
    </div>
    <div v-if="total > 0">
      <div class="listgroup" v-for="(item, index) in mainList" :key="index" @mousedown="loopZoom()"
        @click="handlegodetail(item.referenceNumber, item.id, item.path)" @mouseup="clearLoopZoom()">
        <div class="listgroupLine"></div>

        <div class="itembox">
          <div class="idnexnunbox">
            <span class="indexNum">{{ page * pageRow + index - pageRow + 1 }}. &nbsp;</span>
          </div>
          <div class="itemright" style="overflow: hidden;">
            <div class="listgroupTitleBox overflow-dot" style="position: relative">
              <div class="recommend" v-if="item.isRecommend === '0'">推荐</div>
              <template v-if='item.authorNameList'>
                <template v-if="item.authorNameList && item.authorNameList.length <= 1">
                  <div class="listgroupauthor" v-for="(el, i) in item.authorNameList" :key="el + i + Math.random()">
                    {{ el
                    }}
                  </div>
                </template>
                <template v-if="item.authorNameList && item.authorNameList.length >= 2">
                  <div class="listgroupauthor" v-for="(el, i) in item.authorNameList.slice(0, 2)"
                    :key="el + i + Math.random()">{{ el }}</div>
                  <div class="listomit" v-if="item.authorNameList.length > 2">...</div>
                </template>
              </template>
              <template v-if='item.authorList'>
                <template v-if="item.authorList && item.authorList.length <= 1">
                  <div class="listgroupauthor" v-for="(el, i) in item.authorList" :key="el.name + i + Math.random()">
                    {{ el.name
                    }}
                  </div>
                </template>
                <template v-if="item.authorList && item.authorList.length >= 2">
                  <div class="listgroupauthor" v-for="(el, i) in item.authorList.slice(0, 2)"
                    :key="el.name + i + Math.random()">{{ el.name }}</div>
                  <div class="listomit" v-if="item.authorList.length > 2">...</div>
                </template>
              </template>
              <div :class="{ noTags: item.isAwards != '0' && (item.subjectTitles && item.subjectTitles.length == 0) }"
                class="listgroupTitle overflow-dot" v-if="item.authorship" :title="item.title">{{ item.title }}</div>
              <div :class="{ noTags: item.isAwards != '0' && (item.subjectTitles && item.subjectTitles.length == 0) }"
                class="listgroupTitle2 overflow-dot" v-else :title="item.title">{{ item.title }}</div>

              <div class="listgroupTitleaward" v-if="item.isAwards === '0'" :title="item.awardsList" slot="reference">获奖
              </div>
              <div class="listgroupTitleOrg" v-for="(el, index) in item.cnList" :key="index" :title="el">{{ el }}</div>
              <template v-if='item.subjectTitles'>
                <div class="listgroupTitleType" v-for="(el, index) in item.subjectTitles" :key="index" :title="el">
                  <span>{{ el }}</span>
                </div>
                <!-- <div class="listgroupTitleType">
                  <span>{{ item.subjectTitles }}</span>
                </div> -->
              </template>
              <!-- <span class="listgroupTitleType" v-if="item.subjectTitles.length > 1">...</span> -->

              <el-popover placement="top-start" trigger="hover" v-if="item.isAwards === '0'">
                <template>
                  <div v-for="(awardsItem, awardsIndex) in item.awardsList" :key="awardsIndex">{{ awardsItem }}</div>
                </template>
              </el-popover>
            </div>
            <el-button type="primary" size="mini" class="collect" v-if="collect"
              @click="handleCancelCollect(item.referenceNumber)">取消收藏</el-button>
            <div class="listgroupnameBox">
              <div class="listgroupnameItem"></div>
            </div>
            <div class="listgroupnameBox">
              <div class="listgroupnameItem" v-if="item.originInfo">
                <span class="listgroupname">原发期刊：</span>
                <span class="stationdrop">{{ item.originInfo }}</span>
              </div>
              <div class="listgroupnameItem" v-if="item.journalName">
                <span class="listgroupname">转载来源：</span>
                <span class="stationdrop">复印报刊资料 {{ item.journalName }}</span>
                <span class="period">{{ item.period }}</span>
              </div>
              <!-- <div class="listgroupnameItem" v-if="item.journalName">
              <span class="listgroupname">转载来源：</span>
              <span class="stationdrop">复印报刊资料---需后端添加字段</span>
              </div>-->
              <!-- 摘要 -->
              <div class="listgroupnameItem abstract overflow-dot1" v-if="item.isRecommend === '0' && item.mainContent">
                <span>内容摘要：</span>
                <span>{{ item.mainContent }}</span>
              </div>
            </div>
            <div class="listgroupTxt">{{ item.mainCantent }}</div>
            <!-- <div class="keyword" v-if="item.keyWords">
            <span>关键词：</span>
            <span
              class="semicolonFather"
              v-for="(item02, index) in item.keyWords.split(',')"
              :key="index"
            >
              {{item02}}
              <span class="semicolon">；</span>
            </span>
            </div>-->
            <div class="listBotttom">
              <div class="listBotttomLF" v-if="item.isReprint != 1">
                <span class="listBotttomtxt">主文献编号：</span>
                <span class="listBotttoomActive" v-if='item.referenceNumber'>{{ item.referenceNumber }}</span>
                <!-- <span class="stationline"></span>
                <span class="listBotttomtxt">发表年份：{{ item.pubYear }}</span>-->
              </div>
              <div class="islistBotttom" v-else>
                <span class="listBotttomtxt">主文献编号：</span>
              </div>
              <div class="listBotttomrf">
                <!-- <div class="listBotttomItem">
                <span class="listBotttomItemNun">被引</span>
                <span class="listBotttomItemactive">{{item.citeNum}}</span>
                </div>-->
                <div class="listBotttomItem">
                  <span class="listBotttomItemNun">分享</span>
                  <span class="listBotttomItemactive" v-if='item.shareNum'>{{ item.shareNum }}</span>
                  <span class="listBotttomItemactive" v-if='item.statistics'>{{ item.statistics.shareNum
                    }}</span>
                  <!-- <span class="listBotttomItemactive" v-if='item.statistics'>
                    <template v-if='page * pageRow + index - pageRow + 1 <= 14'>{{ Math.floor(Math.random() * 500 + 500) +
                      item.statistics.shareNum
                      }}</template>
                    <template v-else>{{ item.statistics.shareNum }}</template>

                  </span> -->
                </div>
                <div class="listBotttomItem">
                  <span class="listBotttomItemNun">收藏</span>
                  <span class="listBotttomItemactive">{{ item.collectNum }}</span>
                  <span class="listBotttomItemactive" v-if='item.statistics'>{{ item.statistics.collectNum
                    }}</span>
                  <!-- <span class="listBotttomItemactive" v-if='item.statistics'>
                    <template v-if='page * pageRow + index - pageRow + 1 <= 14'>
                      {{ Math.floor(Math.random() * 500 + 500) + item.statistics.collectNum }}
                    </template>
                    <template v-else>
                      {{ item.statistics.collectNum }}
                    </template>
                  </span> -->
                </div>
                <div class="listBotttomItem">
                  <span class="listBotttomItemNun">下载</span>
                  <span class="listBotttomItemactive"> {{ item.downloadNum }}</span>
                  <span class="listBotttomItemactive" v-if='item.statistics'>{{ item.statistics.downloadNum
                    }}</span>
                  <!-- <span class="listBotttomItemactive" v-if='item.statistics'>
                    <template v-if='page * pageRow + index - pageRow + 1 <= 14'>
                      {{ Math.floor(Math.random() * 500 + 500) + item.statistics.downloadNum }}
                    </template>
                    <template v-else>
                      {{ item.statistics.downloadNum }}
                    </template>
                  </span> -->
                </div>
                <div class="listBotttomItem">
                  <span class="listBotttomItemNun">点击</span>
                  <span class="listBotttomItemactive">{{ item.clickNum }}</span>
                  <span class="listBotttomItemactive" v-if='item.statistics'>{{ item.statistics.clickNum
                    }}</span>
                  <!-- <span class="listBotttomItemactive" v-if='item.statistics'>
                    <template v-if='page * pageRow + index - pageRow + 1 <= 14'>
                      {{ Math.floor(Math.random() * 500 + 500) + item.statistics.clickNum }}
                    </template>
                    <template v-else>
                      {{ item.statistics.clickNum }}
                    </template>
                  </span> -->
                </div>
                <div class="listBotttomItem">
                  <span class="listBotttomItemNun">点赞</span>
                  <span class="listBotttomItemactive">{{ item.likeNum }}</span>
                  <span class="listBotttomItemactive" v-if='item.statistics'>{{ item.statistics.likeNum
                    }}</span>
                  <!-- <span class="listBotttomItemactive" v-if='item.statistics'>
                    <template v-if='page * pageRow + index - pageRow + 1 <= 14'>
                      {{ Math.floor(Math.random() * 500 + 500) + item.statistics.likeNum }}
                    </template>
                    <template v-else>
                      {{ item.statistics.likeNum }}
                    </template>
                  </span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="empty" v-else v-show="showFlag">
      <img src="../../assets/images/home/nodataside.png" alt />
      <span>暂无数据</span>
    </div>

    <div class="pageBox" v-if="total > 0">
      <div class="listgroupLine"></div>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[5, 10, 20, 30, 40]" :page-size="pageRow"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
    <login ref="login" class="homeLoginBigBox" @registeropen="registeropen"></login>
    <register ref="register" @loginopen="loginopen" @emitSuccessRegister="$refs.login.modelopen = true"></register>
  </div>
</template>

<script>
import login from '../../page/login'
import register from '../../page/register'
import { threeHandleLogin } from '../../utils/threeLogin'
export default {
  components: { login, register },
  props: ['Listobj', 'collect', 'setSecond'],
  data () {
    return {
      maxSearch: '',
      sortName: 'PUB_YEAR',
      token: localStorage.getItem('token'),
      rnList: [],
      startTime: '',
      endTime: '',
      startTimes: '',
      endTimes: '',
      resultContents: '',
      subjectTitles: [],
      tabIndexOrder: 'desc', // 用来排序
      isLoad: false,
      currentIndex: 0,
      tabList: [
        {
          name: '发表年份',
          value: 0,
          sortOrder: 'desc', // 可以用 'asc' 或 'desc' 表示正序或倒序
          sortName: 'PUB_YEAR'
        },
        // {
        //   name: "被引",
        //   value: 1
        // },
        {
          name: '分享',
          value: 2,
          sortOrder: 'desc', // 可以用 'asc' 或 'desc' 表示正序或倒序
          sortName: 'SHARE'

        },
        // {
        //   name: "评论量",
        //   value: 3
        // },
        {
          name: '收藏',
          value: 4,
          sortOrder: 'desc', // 可以用 'asc' 或 'desc' 表示正序或倒序
          sortName: 'COLLECT'

        },
        {
          name: '下载',
          value: 5,
          sortOrder: 'desc', // 可以用 'asc' 或 'desc' 表示正序或倒序
          sortName: 'DOWNLOAD'
        },
        {
          name: '点击',
          value: 6,
          sortOrder: 'desc', // 可以用 'asc' 或 'desc' 表示正序或倒序
          sortName: 'CLICK'
        },
        {
          name: '点赞',
          value: 7,
          sortOrder: 'desc', // 可以用 'asc' 或 'desc' 表示正序或倒序
          sortName: 'LIKE'
        }
      ],
      mainList: [], // 主文献列表

      firstselectedTree: [], // 左侧选中的一级学科code列表
      hotwordsearch: '', // 热搜词

      orgCode: '', // 机构code
      page: 1, // 页码
      pageRow: 10, // 每页显示的条目数
      total: 0,
      period: '', // 期刊期次

      pubYear: [],
      // endYear: [],
      resultContent: '', // 结果搜索内容
      searchContent: '', // 检索内容
      likeNum: '',
      secondselecterTree: [], // 左侧选中的二级学科code列表
      tabIndex: 0, // tab切换 0-发表年份 1-被引频次 2-分享量 3-评论量 4-收藏量 5-下载量 6-点击量
      type: 0, // 0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅
      secondyear: [],
      firstyear: [],
      academicList: [], // 十大热词请求
      originJournalYearList: [],
      originJournalPeriodList: [],
      pickerOptions: {
        disabledDate: time => {
          const date = new Date().getFullYear()
          const yearEnd = time.getFullYear()
          return date < yearEnd
        }
      },
      showyear: '',
      isShow: false,
      flag: true,
      firstTime: '',
      lastTime: '',
      showFlag: false
    }
  },
  mounted () {
    // this.getDataList()
    // if(this.$store.state.h)
    //
    // const path = this.$route.fullPath
    // let subname = ''
    // if (path.split('?').length > 1) {
    //   subname = path.split('?')[1].split('=')[0]
    // }
    // if (this.$store.state.hotwordsearch.search === '' && subname !== 'subjectName' && this.$route.name === 'mainIndex') {
    //   this.getDataList()
    // }
    //
    // 一进来执行一下点击事件?
    if (document.querySelector('.searchBox .searchBtn')) {
      // document.querySelector('.searchBox .searchBtn').click()
    } else {
      // this.getDataList();
    }
    document.onkeydown = e => {
      var theEvent = window.event || e
      var code = theEvent.keyCode || theEvent.which || theEvent.charCode
      if (
        code === 13 &&
        document.activeElement.parentNode.className.indexOf('searchBox') !== -1
      ) {
        this.handlesearchlistBtn()
      }
      if (
        code === 13 &&
        document.activeElement.parentNode.className.indexOf('searchInput') !==
        -1
      ) {
        document.querySelector('.searchBox .searchBtn').click()
      }
    }
    // this.getDataList()
  },

  methods: {
    // 点击跳转个人信息
    handleperson () {
      if (!this.$store.state.token) {
        threeHandleLogin(this.THREE_LOGIN_OR)
        return
        // this.$refs.login.modelopen = true
        // return
      }
      this.$router.push('/user/personal')
      // const routeData = this.$router.resolve({
      //   path: '/user/personal'
      // })
      // window.open(routeData.href, '_blank')
    },
    async getDataList () {
      this.isLoad = true

      this.$emit('isLoadingShow', this.isLoad)
      if (this.Listobj.firstLevelList) {
        this.firstselectedTree = this.Listobj.firstLevelList
      }
      if (this.Listobj.secondLevelList) {
        this.secondselecterTree = this.Listobj.secondLevelList
      }
      if (this.Listobj.secondLevelList) {
        this.rnList = this.Listobj.rnList
      }
      if (!this.Listobj.url) {
        console.log('没有url')
        this.showFlag = true
        return
      }
      // try {
      this.mainList = []
      if (this.Listobj.url === '/literatureInfoList/search' || this.Listobj.url === '/userinfo/subscriptions' || this.Listobj.url === '/authorList/search/literature' || this.Listobj.url === '/hotWordStatistics/search/literature' || this.Listobj.url === '/organization/search/literature' || this.Listobj.url === '/academic/search/literature' || this.Listobj.url === '/topicList/search/literature') {
        const { data: res } = await this.$http({
          url: this.Listobj.url,
          method: 'post',
          data: {
            page: this.page,
            pageSize: this.pageRow,
            sortName: this.sortName,
            sort: this.tabIndexOrder,
            searchMap: {
              lv1dcList: this.firstselectedTree, // 一级学科
              lv2dcList: this.secondselecterTree, // 二级学科
              easySearch: this.hotwordsearch,
              search: this.resultContent,
              startTime: this.startTime,
              endTime: this.endTime,
              // authorCode: this.Listobj.authorCode
              rnList: this.Listobj.rnList,
              // idList: this.Listobj.rnList,
              tagContentList: this.Listobj.hotWords
            }
          }
        })
        if (res.code !== 200) return this.$message.error(res.msg)
        this.isLoad = false
        this.showFlag = true
        this.$emit('isLoadingShow', this.isLoad)
        if ((this.Listobj.url === '/academic/search/literature' && this.Listobj.hotWords.length === 0) || (this.Listobj.url === '/cn_academic_concept/search/literature' && this.Listobj.rnList.length === 0)) {
          this.mainList = []
          this.total = 0
        } else {
          this.mainList = res.data.rows
          this.total = res.data.total
        }
        if (res.data.rows.length > 0) {
          this.$emit('originalNum', true)
        }
        // this.mainList.subjectTitles = this.mainList.subjectTitles.split(',')
        // this.mainList.subjectTitles = this.mainList.subjectTitleList
        this.mainList.forEach(item => {
          if (item.subjectTitleList === null) {
            item.subjectTitles = []
          } else {
            if (item.subjectTitleList) {
              item.subjectTitles = item.subjectTitleList.map(item => item.name)
            }
          }
          if (item.cnList === null) {
            item.cnList = []
          } else {
            item.cnList = item.cnList.map(item => item.name)
          }
        })

        window.scrollTo(0, 0)
      } else if (this.Listobj.url === '/userinfo/collect') {
        // 我的收藏列表数据
        const { data: res } = await this.$http({
          url: this.Listobj.url,
          method: 'post',
          data: {
            page: this.page,
            pageSize: this.pageRow,
            sortName: this.sortName,
            sort: this.tabIndexOrder,
            searchMap: {
              search: this.resultContent,
              startTime: this.startTime,
              endTime: this.endTime
            }
          }
        })
        if (res.code !== 200) return this.$message.error(res.msg)
        this.isLoad = false
        this.showFlag = true
        this.$emit('isLoadingShow', this.isLoad)
        this.mainList = res.data.rows
        if (res.data.rows.length > 0) {
          this.$emit('originalNum', true)
        }
        this.total = res.data.total
        window.scrollTo(0, 0)
      } else if (this.Listobj.url === '/cn_academic_concept/search/literature') {
        // 原创概念的,由于传参idList和rnList不能同时存在,只能先这样分开了
        const { data: res } = await this.$http({
          url: this.Listobj.url,
          method: 'post',
          data: {
            page: this.page,
            pageSize: this.pageRow,
            sortName: this.sortName,
            sort: this.tabIndexOrder,
            searchMap: {
              lv1dcList: this.firstselectedTree, // 一级学科
              lv2dcList: this.secondselecterTree, // 二级学科
              easySearch: this.hotwordsearch,
              search: this.resultContent,
              startTime: this.startTime,
              endTime: this.endTime,
              // authorCode: this.Listobj.authorCode
              // rnList: this.Listobj.rnList,
              idList: this.Listobj.rnList,
              tagContentList: this.Listobj.hotWords
            }
          }
        })
        if (res.code !== 200) return this.$message.error(res.msg)
        this.isLoad = false
        this.showFlag = true
        this.$emit('isLoadingShow', this.isLoad)
        if (this.Listobj.url === '/cn_academic_concept/search/literature' && this.Listobj.rnList.length === 0) {
          this.mainList = []
          this.total = 0
        } else {
          this.mainList = res.data.rows
          this.total = res.data.total
        }
        if (res.data.rows.length > 0) {
          this.$emit('originalNum', true)
        }
        // this.mainList.subjectTitles = this.mainList.subjectTitles.split(',')
        // this.mainList.subjectTitles = this.mainList.subjectTitleList
        this.mainList.forEach(item => {
          if (item.subjectTitleList === null) {
            item.subjectTitles = []
          } else {
            if (item.subjectTitleList) {
              item.subjectTitles = item.subjectTitleList.map(item => item.name)
            }
          }
          if (item.cnList === null) {
            item.cnList = []
          } else {
            item.cnList = item.cnList.map(item => item.name)
          }
        })

        window.scrollTo(0, 0)
      } else {
        const { data: res } = await this.$http({
          url: this.Listobj.url,
          method: 'post',
          data: {
            rnList: this.rnList,
            firstLevelList: this.firstselectedTree,
            searchContent: this.hotwordsearch, // 大搜索内容
            page: this.page,
            pageRow: this.pageRow,
            pubYear: this.pubYear, // 发表年份
            startTime: this.startTime,
            endTime: this.endTime,
            resultContent: this.resultContent, // 搜索结果内容
            secondLevelList: this.secondselecterTree,
            tabIndex: this.tabIndex,
            type: this.Listobj.type,
            secondyear: this.Listobj.secondyear, // 期刊选中的二级
            firstyear: this.Listobj.firstyear, // 期刊选中的一级
            academicList: this.Listobj.academicList,
            hotWords: this.Listobj.hotWords,
            subjectList: this.Listobj.subjectList,
            authorCode: this.Listobj.authorCode,
            authorName: this.Listobj.authorName,
            orgName: this.Listobj.orgName,
            orgCode: this.Listobj.orgCode,
            originJournalName: this.Listobj.originJournalName,
            originJournalYearList: this.originJournalYearList,
            originJournalPeriodList: this.originJournalPeriodList,
            tabIndexOrder: this.tabIndexOrder,
            relatedLiteratureIdList: this.Listobj.relatedLiteratureIdList
          }
        })
        if (res.code !== 200) return this.$message.error(res.msg)
        this.isLoad = false
        this.$emit('isLoadingShow', this.isLoad)
        this.showFlag = true
        this.mainList = res.data.list
        if (res.data.list.length > 0) {
          this.$emit('originalNum', true)
        }
        // this.mainList.subjectTitles = this.mainList.subjectTitles.split(',')
        this.mainList.forEach(item => {
          if (item.subjectTitles === null) {
            item.subjectTitles = []
          } else {
            item.subjectTitles = item.subjectTitles.split(',')
          }
        })
        this.total = res.data.count
        window.scrollTo(0, 0)
      }
    },
    // 取消收藏
    async handleCancelCollect (referenceNumber) {
      //  阻止事件冒泡
      event.stopPropagation()
      const { data: res } = await this.$http({
        url: 'detail/delCollect',
        method: 'post',
        data: {
          referenceNumber
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.$message.success('已取消收藏！')
      this.getDataList()
    },
    handleSizeChange (val) {
      this.page = 1
      this.pageRow = val
      this.getDataList()
    },
    handleCurrentChange (val) {
      this.page = val
      this.getDataList()
    },
    // 点击搜索词
    handleClick (item, index, currentIndex) {
      if (currentIndex === index) {
        if (item.sortOrder === 'asc') {
          item.sortOrder = 'desc'
        } else {
          item.sortOrder = 'asc'
        }
      } else {
        this.tabList.forEach(tab => {
          tab.sortOrder = 'desc'
        })
      }
      this.sortName = item.sortName
      this.tabIndexOrder = item.sortOrder
      this.tabIndex = item.value
      this.currentIndex = index
      this.getDataList()
    },
    // 记录下载
    async isClock (referenceNumber) {
      await this.$http({
        url: '/detail/isClock',
        method: 'post',
        data: {
          referenceNumber
        }
      })
      this.getDataList()
    },
    loopZoom () {
      this.firstTime = new Date().getTime()
      // this.timeOut = setTimeout(() => {
      //   this.flag = false
      // }, 500)
    },
    clearLoopZoom () {
      this.lastTime = new Date().getTime()
      if (this.lastTime - this.firstTime < 200) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    // 点击列表标题去详情
    handlegodetail (referenceNumber, id, path) {
      if (this.flag) {
        this.isClock(referenceNumber)
        const routeData = this.$router.resolve({
          path:
            '/mainDetails?id=' +
            id +
            '&referenceNumber=' +
            referenceNumber
          // '&path=' +
          // path
        })
        this.flag = true
        window.open(routeData.href, '_blank')
      }
    },
    // 年份选择值变化
    handleStaryears (value) {
      this.startTimes = value
    },
    handleEndyears (value) {
      this.endTimes = value
    },
    // 点击搜索结果
    handlesearchlistBtn () {
      this.page = 1
      this.startTime = this.startTimes
      this.endTime = this.endTimes
      this.resultContent = this.resultContents
      // 存值
      // this.resultContent = this.resultContent.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      if (document.querySelector('.searchInput')) {
        this.maxSearch = document.querySelector('.searchInput').children[0].value
      }
      if (this.$store.state.hotwordsearch.search !== '' && this.maxSearch === '') {
        this.$store.commit('handlehotwordsearch', '')
        this.$store.commit('handlehotwordsearchTime', Date.now())
      }
      this.$store.commit('yearsearch', {
        pubYear: this.pubYear, // 搜索年份
        resultContent: this.resultContent, // 搜索词
        startTime: this.startTime,
        endTime: this.endTime,
        maintimestamp: Date.now()
      })
      // this.getDataList()
    },
    inputOnMouseOver () {
      let check = ''
      if (
        this.Listobj.type == 0 ||
        this.Listobj.type == 1 ||
        this.Listobj.type == 3 ||
        this.Listobj.type == 5
      ) {
        check = 3
      } else if (this.Listobj.type == 4) check = 5
      else check = 4
      if (this.pubYear.length >= check) {
        this.isShow = false
        this.showyear = this.pubYear.join(',')
      } else {
        this.isShow = true
      }
    },
    loginhandle () {
      this.$refs.login.modelopen = true
    },
    registeropen (value) {
      this.$refs.register.registershow = value
    },
    loginopen (value) {
      this.$refs.login.modelopen = value
    }
  },
  beforeDestroy () {
    if (
      this.$store.state.subjectName !== '' &&
      this.$store.state.subjectName !== undefined &&
      !localStorage.getItem('token')
    ) {
      this.$store.commit('setSubjectName', '')
      this.$store.commit('secondLevelList', [])
    }
  },
  watch: {
    pubYear: {
      handler (data) {
        if (this.pubYear === null) {
          this.pubYear = []
          this.showyear = ''
        }
      }
    },
    Listobj: {
      deep: true,
      handler (data) {
        if (data.url) {
          this.getDataList()
        }
      }
    },
    '$store.state.secondselectedTree': {
      deep: true,
      immediate: true,
      handler (data, oldData) {
        const path = this.$route.fullPath
        let sub = ''
        if (path.split('?').length > 1) {
          sub = path.split('?')[1].split('=')[0]
        }
        const _path = path.split('?')[0]
        this.page = 1
        if (
          _path === '/' &&
          sub !== 'subjectName' &&
          (this.$store.state.hotwordsearch.search === '' ||
            this.$store.state.hotwordsearch.search === null) &&
          !localStorage.getItem('token')
        ) {
          if (document.querySelector('.searchInput')) {
            const search = document.querySelector('.searchInput').children[0].value
            this.hotwordsearch = search
          }
          // 如果是首页,没有登录,并且不是从门户跳转过来,就执行一次
          this.secondselecterTree = data
          this.getDataList()
        } else if (
          JSON.stringify(data) !== JSON.stringify(oldData) &&
          oldData !== undefined &&
          sub === 'subjectName'
        ) {
          if (document.querySelector('.searchInput')) {
            const search = document.querySelector('.searchInput').children[0].value
            this.hotwordsearch = search
          }
          // 是首页,门户进
          this.secondselecterTree = data
          this.getDataList()
        } else if (
          _path === '/' &&
          localStorage.getItem('token') &&
          data.length === 0 &&
          oldData === undefined &&
          (this.$store.state.hotwordsearch.search === '' ||
            this.$store.state.hotwordsearch.search === null) &&
          this.setSecond !== null
        ) {
          if (document.querySelector('.searchInput')) {
            const search = document.querySelector('.searchInput').children[0].value
            this.hotwordsearch = search
          }
          // 登录了但是没有选择学科
          this.secondselecterTree = data
          this.getDataList()
        } else if (
          _path === '/' &&
          JSON.stringify(data) !== JSON.stringify(oldData) &&
          localStorage.getItem('token') &&
          (this.$store.state.hotwordsearch.search === null ||
            this.$store.state.hotwordsearch.search === '')
        ) {
          if (document.querySelector('.searchInput')) {
            const search = document.querySelector('.searchInput').children[0].value
            this.hotwordsearch = search
          }
          // 登录了并且选择了学科
          this.secondselecterTree = data
          this.getDataList()
        } else if (
          _path === '/' &&
          sub !== 'subjectName' &&
          this.$store.state.hotwordsearch.search === '' &&
          !localStorage.getItem('token')
        ) {
          if (document.querySelector('.searchInput')) {
            const search = document.querySelector('.searchInput').children[0].value
            this.hotwordsearch = search
          }
          // 如果是首页,没有登录,并且不是从门户跳转过来,就执行一次
          this.secondselecterTree = data
          this.getDataList()
        } else if (
          _path === '/' &&
          sub !== 'subjectName' &&
          this.$store.state.hotwordsearch.search !== '' &&
          this.$store.state.hotwordsearch.search !== null
        ) {
          if (document.querySelector('.searchInput')) {
            const search = document.querySelector('.searchInput').children[0].value
            this.hotwordsearch = search
          }
          // 如果是首页,没有登录,并且不是从门户跳转过来,就执行一次
          this.hotwordsearch = this.$store.state.hotwordsearch.search
          this.secondselecterTree = data
          this.getDataList()
        }
      }
    },
    '$store.state.mainsearch': {
      deep: true,
      handler (data) {
        if (data) {
          if (
            this.$store.state.secondselectedTree.length !== 0 &&
            this.$store.state.subjectName === ''
          ) {
            this.secondselecterTree = this.$store.state.secondselectedTree
          }
          if (data.startTime === '') {
            this.startTimes = data.startTime
          }
          if (data.endTime === '') {
            this.endTimes = data.endTime
          }
          if (data.resultContent === '') {
            this.resultContents = data.resultContent
          }
          this.startTime = data.startTime
          this.endTime = data.endTime
          this.resultContent = data.resultContent
          this.getDataList()
        }
      }
    },
    // 监听热搜词
    '$store.state.hotwordsearch.timestamp': {
      deep: true,
      // immediate: true,
      handler (data, oldData) {
        this.hotwordsearch = this.$store.state.hotwordsearch.search
        if (this.$store.state.secondselectedTree.length !== 0) {
          this.secondselecterTree = this.$store.state.secondselectedTree
        }
        const startYearEl = document.querySelector('.startYearSearch').children[0].value
        const endYearEl = document.querySelector('.endYearSearch').children[0].value
        const mainListBoxEl = document.querySelector('.mainLisSearchBox').children[0].value
        this.startTime = startYearEl
        this.endTime = endYearEl
        this.resultContent = mainListBoxEl
        this.page = 1
        this.pageRow = 10
        // this.startTime = this.startTimes
        // this.endTime = this.endTimes
        // this.resultContent = this.resultContents
        // // 存值
        // this.$store.commit('yearsearch', {
        //   pubYear: this.pubYear, // 搜索年份
        //   resultContent: this.resultContent, // 搜索词
        //   startTime: this.startTime,
        //   endTime: this.endTime
        // })
        // if (oldData !== undefined && data !== null && oldData !== null) {
        this.getDataList()
        // }
      }
    },

    // '$store.state.firstselectedTree': {
    //   deep: true,
    //   // immediate: true,
    //   handler (data, oldData) {
    //     this.page = 1
    //     this.pageRow = 10
    //     const path = this.$route.fullPath
    //     const _path = path.split('?')[0]
    //     if (
    //       _path === '/' &&
    //       JSON.stringify(data) !== JSON.stringify(oldData)
    //     ) {
    //       this.firstselectedTree = data
    //       this.getDataList()
    //       return
    //     }
    //     if (JSON.stringify(data) !== JSON.stringify(oldData) && oldData !== undefined) {
    //       this.firstselectedTree = data
    //       this.getDataList()
    //     }
    //   }
    // },
    '$store.state.secondselectedyear': {
      deep: true,
      handler (data) {
        this.originJournalPeriodList = data
      }
    },
    '$store.state.firstselectyear': {
      deep: true,
      handler (data) {
        this.page = 1
        this.pageRow = 10
        this.originJournalYearList = data
        this.getDataList()
      }
    }
  }
}
</script>

<style lang="less">
//最后一个分号不显示
.semicolonFather:last-child {
  .semicolon {
    display: none;
  }
}

.stationline {
  display: inline-block;
  width: 2px;
  height: 16px;
  background: #cccccc;
  margin: 0 10px;
  vertical-align: text-bottom;
}

.el-tooltip {
  width: 50% !important;

  &:focus {
    border: 1px solid #9c2526;
  }

  input {
    border: 0;
    cursor: pointer;
  }
}

.mainList {
  .listSearch {
    display: flex;
    align-items: center;
    width: 100%;

    .yearSearch {
      border: 1px solid #dcdfe6;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 380px;
      margin-right: 10px;

      /deep/input {
        border: 0 !important;
      }
    }

    .listSearchTimeTitle {
      font-size: 16px;
      color: #333333;
      // width: 8%;
      margin-right: 11px;
    }

    .yearsinput {
      border-radius: 4px;
      margin: 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .searchBox {
      width: 50%;
      height: 40px;

      /deep/.el-input__inner {
        background: #ffffff;
        border-radius: 4px;
      }
    }

    .searchlistBtn {
      width: 15%;
      margin-left: 10px;
    }
  }

  .searchword {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    .leftsearchword {
      flex: 1;
      display: flex;

      .searchwordItem {
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 20px;
        padding: 4px 11px;
        margin-right: 10px;
        font-size: 14px;
      }

      .active {
        transition: 1s all;
        background: @my-color;
        border: 1px solid @my-color;
        color: #fff;
      }

      .searchwordItem:last-child {
        margin-right: 0px;
      }
    }
  }

  .nuntxt {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchNum {
      font-size: 14px;
      font-weight: bold;
      color: @my-color;
    }
  }

  .listgroup {
    cursor: pointer;

    .listgroupLine {
      width: 100%;
      height: 1px;
      background: #e6e6e6;
      border-radius: 1px;
      margin: 10px 0;
    }

    .listgroupTitleBox {
      max-width: 750px;
      display: flex;
      align-items: center;
    }

    .itembox {
      display: flex;
      align-items: baseline;
    }

    .idnexnunbox {
      margin-right: 5px;

      .indexNum {
        color: #000;
        font-size: 16px;
      }
    }

    .itemright {
      flex: 1;
    }

    .recommend {
      text-align: center;
      padding: 0 5px;
      height: 20px;
      line-height: 20px;
      background: #ff7100;
      border-radius: 4px;
      font-size: 12px;
      color: #ffffff;
    }

    .listgroupTitleaward {
      box-sizing: border-box;
      display: inline-block;
      text-align: center;
      padding: 3px 10px;
      background: rgba(255, 0, 0, 0.12);
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #ff0000;
      margin: 0 10px 0 0;
      vertical-align: text-bottom;
    }

    .listgroupTitleOrg {
      box-sizing: border-box;
      text-align: center;
      padding: 3px 10px;
      background: rgba(49, 85, 134, 0.12);
      border-radius: 4px;
      font-size: 12px;
      color: #315586;
      margin: 0 10px 0 0;
      vertical-align: text-bottom;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .listgroupTitleType {
      box-sizing: border-box;
      text-align: center;
      padding: 3px 10px;
      background: rgba(255, 113, 0, 0.12);
      border-radius: 4px;
      font-size: 12px;
      color: #ff7100;
      margin: 0 10px 0 0;
      vertical-align: text-bottom;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .listgroupTitleType02 {
      box-sizing: border-box;
      height: 22px;
      line-height: 22px;
      display: inline-block;
      text-align: center;
      background: rgba(255, 113, 0, 0.12);
      border-radius: 4px;
      font-size: 12px;
      color: #ff7100;
      margin: 0 10px 0 0;
    }

    .overtext {
      display: none;
    }

    .listgroupTitle {
      margin: 0 10px;
      max-width: 500px;
      display: inline-block;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      color: @my-color;
      vertical-align: text-bottom;
    }

    .listgroupTitle2 {
      max-width: 630px;
      display: inline-block;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      color: @my-color;
      vertical-align: text-bottom;
      margin: 0 10px;
    }

    .noTags {
      max-width: 100%;
    }

    .listgroupauthor:first-child {
      margin-left: 0px;
    }

    .listgroupauthor {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      vertical-align: bottom;
      margin-left: 10px;
    }

    .listomit {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      height: 20px;
      line-height: 10px;
    }

    .listgroupnameBox {

      // margin: 15px 0;
      .listgroupnameItem {
        margin-top: 4px;

        .listgroupname {
          color: #000000;
          font-size: 14px;
        }

        .stationdrop {
          color: #000000;
          font-size: 14px;
        }

        .period {
          color: #000000;
          font-size: 14px;
        }
      }

      .abstract {
        margin: 4px 0;
        color: #666666;

        >span {
          font-size: 14px;
        }
      }
    }

    .listgroupTxt {
      color: #666;
    }

    .keyword {
      color: #000;
      margin: 6px 0;

      >span {
        font-size: 14px;
      }
    }

    .keywordtype {
      margin: 15px 0;

      >span {
        padding: 2px 4px;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        margin-right: 10px;
      }

      >span:last-child {
        margin-right: 0px;
      }
    }

    .listBotttom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .listBotttomLF {
        .listBotttomtxt {
          color: #666;
        }

        >span {
          font-size: 14px;
        }

        .listBotttoomActive {
          color: @my-color;
        }
      }

      .islistBotttom {
        .listBotttomtxt {
          color: rgba(0, 0, 0, 0);
        }

        >span {
          font-size: 14px;
        }

        .listBotttoomActive {
          color: rgba(0, 0, 0, 0);
        }
      }

      .listBotttomrf {
        display: flex;

        .listBotttomItem {
          margin: 0 5px;

          .listBotttomItemactive {
            color: @my-color;
            margin-left: 10px;
            font-size: 14px;
          }

          .listBotttomItemNun {
            color: #666;
            font-size: 14px;
          }
        }
      }
    }
  }

  .empty {
    margin-top: 65px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      width: 232px;
      height: 114px;
    }

    span {
      color: #999;
      font-size: 14px;
    }
  }

  .pageBox {
    margin-bottom: 20px;

    .listgroupLine {
      width: 100%;
      height: 1px;
      background: #e6e6e6;
      border-radius: 1px;
      margin: 10px 0;
    }
  }
}

.collect {
  float: right;
  height: 34px;
  font-size: 14px;
  border-radius: 4px;
}

.mySelectPopper .el-button {
  background-color: #9c2526 !important;
  color: #fff;
}

.mySelectPopper .el-button.is-plain:hover {
  opacity: 0.8;
  color: #fff;
}

.yearSearch {
  .el-input__inner {
    border: 0px solid #dcdfe6 !important;
  }
}

.rtsearchword {
  cursor: pointer;

  .dyrecommend {
    position: relative;
    box-sizing: border-box;
    // padding-right: 2%;
    display: inline-block;
    // padding: 3px 20px 3px 10px;
    // width: 280px;
    padding-left: 10px;
    padding-right: 25px;
    box-sizing: border-box;
    height: 26px;
    line-height: 26px;
    background: #ffd4b2;
    border-radius: 13px;
    font-size: 12px;
    font-weight: 400;
    color: #ff7100;
    white-space: nowrap;
  }

  .dyrecommend::after {
    content: "";
    width: 32px;
    height: 32px;
    background: url("../../assets/images/main/mainlist/tjrecommend.png") no-repeat;
    background-size: cover;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
  }
}

.setFz {
  font-size: 14px;
}
</style>
