<template>
  <div class="miandetailsBox">
    <!-- 文字 -->
    <detailtheme></detailtheme>
    <!-- 图谱 -->
    <detailatlas></detailatlas>
    <!-- 评论 -->
    <comment id="comment"></comment>
    <!-- 列表 -->
    <detailreconnend></detailreconnend>
  </div>
</template>

<script>
import detailtheme from '../../components/main/detailthemen.vue'
import detailatlas from '../../components/main/detailatlas'
import comment from '../../components/comment/list'
import detailreconnend from '../../components/main/detailreconnend'
export default {
  components: {
    detailtheme,
    detailatlas,
    comment,
    detailreconnend
  },
  mounted() {
    if (this.$route.query.path == 'comment') {
      setTimeout(() => {
        window.scrollTo(0, document.getElementById('comment').offsetTop)
      }, 0)
    }
  }
}

</script>
<style lang="less" scoped>
.miandetailsBox {
  padding: 20px 15% 0;
}
</style>
