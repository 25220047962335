<template>
  <div class="authordetaillfBox">
    <div class="authordetailTop">
      <div class="authordetailTophead">
        <div class="authorlf">
          <img :src="photo" class="image" v-if="photo" alt :class="{ borderLine: isSign }" />
          <span class="image" :style="randomRgb()" v-else :class="{ borderLine: isSign }">
            {{ name.substring(0, 1)
            }}
          </span>
          <!-- <img class="imgsing" v-if="isSign" src="../../assets/images/main/comment/Empower.png" alt /> -->
          <!-- <img src="../../assets/images/head/author.png" class="image" v-else alt /> -->
          <!-- <img src="../../assets/images/home/isSignFlag.png" v-if="isSign" class='isSignFlag' alt=""> -->
        </div>
        <div class="authorrt">
          <div>
            <span class="authorName">{{ name }}</span>
            <span class="authorAddress" @click="toCompany(companyCode)" v-if="company != '不详'">{{ company }}</span>
            <div class="isAuthorization">
              <!-- 是否授权 -->
              <img src="../../assets/images/home/clickToSign.png" alt @click="handleIsSign" />
              <!-- <img v-else src="../../assets/images/main/comment/Empower.png" alt=""> -->
            </div>
          </div>
          <div class="authorrtdiscipline">
            <span v-for="(item, index) in firstLevelDiscipline" :key="index">
              <span>{{ item }}</span>
              <span class="stationline"></span>
            </span>
          </div>
          <div class="authorTrpe">主文献数：{{ literatureNum }}</div>
        </div>
      </div>

      <div class="authorarea">
        <!-- <div class="authorareaTitle">研究领域：</div>
        <div class="authorareaConter">
          <span class="authorareaItem" v-for="(item,index) in researchField" :key="index">{{item}}</span>
        </div>-->
      </div>
    </div>
    <!-- <div class="authorleftsupport" v-if="fund.length">
      <div class="authorleftsupportTitle">获得支持基金</div>
      <div v-if="fund.length">
        <div v-for="(item, index) in fund" :key="index" style="line-height:26px;">
          <span class="authorleftsupportNum">[{{ index + 1 }}]</span>
          <span class="authorleftsupporname overflow-dot">{{ item }}</span>
        </div>
      </div>
      <div class="empty" v-else>
        <img src="../../assets/images/home/nodatasmall.png" alt />
        <p>没有查询到数据哦</p>
      </div>
    </div> -->
    <el-dialog :lock-scroll="false" custom-class="setDialog" :z-index="99999" :visible.sync="personAgree"
      :close-on-click-modal="false" center :modal-append-to-body="false">
      <signatures-contract class="agree"></signatures-contract>
      <span slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="checksure" type="primary" class="checksure">确认</el-button>
      </span>
    </el-dialog>
    <claim ref="claim" @refresh="refresh"></claim>
  </div>
</template>

<script>
// @ is an alias to /src
import claim from '../../components/author/claim'
import { threeHandleLogin } from '../../utils/threeLogin'
import signaturesContract from '@/page/digitalSignatures/signaturesContract'
export default {
  components: { claim, signaturesContract },
  data () {
    return {
      acList: [],
      rnList: [],
      personAgree: false,
      photoimg: '',
      photo: '',
      name: '',
      company: '',
      companyCode: '',
      literatureNum: '',
      firstLevelDiscipline: [],
      researchField: [], // 研究领域
      id: '',
      authorCode: '',
      fund: [], // 基金支持
      code: '',
      isSign: null // 作者是否授权
    }
  },
  async mounted () {
    this.id = this.$route.query.id
    this.authorCode = this.$route.query.authorCode
    await this.authorData()

    console.log(this.$route.query)
    document.title = this.name + this.$route.meta.title
  },
  methods: {
    checksure () {
      this.personAgree = false
      this.$refs.claim.claimbox = true
    },
    // 用暴露的方法监听一下
    refresh (data) {
      if (data) {
        this.authorData()
      }
    },
    // 点击待认领
    handleIsSign () {
      if (!this.$store.state.token) {
        return threeHandleLogin(this.THREE_LOGIN_OR)
        // return this.loginhandle()
      }
      this.personAgree = true
      // this.$refs.claim.claimbox = true
    },
    randomRgb (item) {
      const colorList = [
        '#f1af10',
        '#f1a871',
        '#23a1b8',
        '#82bcca',
        '#afb4db',
        '#78a355',
        '#df9464',
        '#7bbfea'
      ]
      const index = Math.floor(Math.random() * colorList.length)
      return {
        // backgroundImage: colorList[index]
        backgroundColor: colorList[index]
      }
    },
    // 左侧作者信息数据//老接口
    // async authorData () {
    //   const { data: res } = await this.$http({
    //     url: '/authorShow',
    //     method: 'post',
    //     data: {
    //       id: this.id
    //     }
    //   })
    //   if (res.code !== 200) return this.$message.error(res.msg)
    //   this.name = res.data.name
    //   this.code = res.data.code
    //   this.company = res.data.company
    //   this.companyCode = res.data.orgCode
    //   const emitdata = {
    //     code: this.code,
    //     name: this.name,
    //     orgName: this.company,
    //     orgCode: res.data.orgCode
    //   }
    //   this.literatureNum = res.data.literatureNum
    //   this.firstLevelDiscipline = res.data.firstLevelDiscipline
    //   this.photo = res.data.photo
    //   this.researchField = res.data.researchField
    //   this.fund = res.data.fund
    //   this.isSign = res.data.isSign
    //   // 需要将数据发送到父元素
    //   this.$emit('sendnamecode', emitdata)
    // },
    // 新接口
    async authorData () {
      const { data: res } = await this.$http({
        url: `/authorList/find-one/${this.authorCode}`,
        method: 'GET'

      })
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.name = res.data.name
      this.code = res.data.authorCode
      this.company = res.data.company
      this.companyCode = res.data.orgCode
      const emitdata = {
        code: this.code,
        name: this.name,
        orgName: this.company,
        orgCode: res.data.orgCode
      }
      this.literatureNum = res.data.literatureNum
      this.firstLevelDiscipline = res.data.firstLevelDiscipline
      this.photo = res.data.photo
      this.researchField = res.data.researchField
      this.fund = res.data.fund
      this.isSign = res.data.isSign
      this.rnList = res.data.rnList
      if (res.data.acList) {
        this.acList = res.data.acList
      }
      this.$emit('acListShow', this.acList)
      this.$emit('rnListShow', this.rnList)

      // 需要将数据发送到父元素
      this.$emit('sendnamecode', emitdata)
    },
    toCompany (code) {
      if (code !== 'to_be_improved') {
        const routeData = this.$router.resolve({
          path: 'institutiondetail?name=' + code + '&page=' + 1 + '&pageRow=' + 10
          // query: params
        })
        window.open(routeData.href, '_blank')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.stationline {
  display: inline-block;
  width: 2px;
  height: 16px;
  background: #cccccc;
  margin: 0 10px;
  vertical-align: text-bottom;
}

.authordetaillfBox {
  width: 320px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;

  .authordetailTop {
    padding: 55px 20px;
    box-sizing: border-box;
    // min-height: 200px;
    background: #f5f5f5;
    border-radius: 10px 10px 0 0;

    .authordetailTophead {
      display: flex;

      .authorlf {
        position: relative;
        display: flex;
        flex-direction: column;

        .imgsing {
          height: 24px;
        }

        .image {
          cursor: pointer;
          display: inline-block;
          color: #fff;
          font-size: 20px;
          width: 60px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border-radius: 50%;
          // object-fit: cover;
          // vertical-align: middle;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          align-content: center;
        }
      }

      .authorrt {
        .authorrtdiscipline {
          margin: 12px 0;
        }

        .authorrtdiscipline span:last-child {
          .stationline {
            width: 0;
          }
        }

        margin-left: 10px;

        .authorName {
          font-size: 18px;
          font-weight: 500;
          color: #000000;
        }

        .authorAddress {
          margin-left: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          cursor: pointer;
        }

        .authorAddress:hover {
          color: #9c2526;
        }

        .authorTrpe {
          border: 1px solid #e6e6e6;
          border-radius: 4px;
          padding: 4px 8px;
          font-size: 12px;
          font-weight: 400;
          color: @my-color;
          display: inline-block;
        }

        .claimBtn {
          box-sizing: border-box;
          cursor: pointer;
          text-align: center;
          // line-height: 28px;
          height: 28px;
          font-size: 14px;
          background: #9c2526;
          border-radius: 14px;
          color: #ffffff;
          padding: 6px 17px;
          width: 120px;
          // margin: 0 20px;
          margin-top: 10px;
        }
      }
    }

    .authorarea {
      display: flex;
      margin-top: 15px;

      .authorareaTitle {
        font-size: 14px;
        color: #000000;
        width: 70px;
      }

      .authorareaConter {
        flex: 1;

        .authorareaItem {
          border: 1px solid #e0e0e0;
          border-radius: 12px;
          padding: 4px 14px;
          margin-right: 10px;
          margin-bottom: 5px;
          font-size: 12px;
          font-weight: 400;
          color: #000000;
          display: inline-block;
        }
      }
    }
  }

  .authorleftsupport {
    padding: 20px;

    .authorleftsupportTitle {
      padding: 20px 0;
      font-size: 18px;
      font-weight: 500;
      color: #9c2526;
    }

    .authorleftsupportNum {
      margin-right: 6px;
    }

    .authorleftsupporname {
      width: 90%;
      display: inline-block;
      vertical-align: top;
    }

    .empty {
      margin: 80px 0;

      // img {
      //   width: 21.43vw;
      //   height: 7.135vw;
      // }

      img {
        width: 268.8px;
        height: 137px;
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
      }

      p {
        text-align: center;
        color: #666;
      }
    }
  }
}

.isAuthorization {
  width: 105px;
  height: 24px;
  cursor: pointer;

  img {
    // width: 105px;
    height: 24px;
  }
}

.borderLine {
  // border-radius: 50%;
  //border: 1px solid #fff000;
}

.isSignFlag {
  width: 22px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
}

/deep/.setDialog {
  width: 800px;
  height: 765px;
  background-image: url("../../assets/images/claim/agreebg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  margin-top: 5vh !important;

  .el-dialog__body {
    padding: 0 !important;
  }
}

.checksure {
  width: 140px;
  height: 40px;
}

/deep/.el-dialog__close.el-icon.el-icon-close {
  color: #fff !important;
}

/deep/.agree {
  .text {
    width: 680px;
    height: 566px;
    border-radius: 10px;
  }
}
</style>
