<template>
  <div class="modalclose" v-show="modelopen" @wheel.stop>
    <div class="loginBox">
      <div class="closeBtnBox" @click="handleLogincancel()">
        <img :src="require('../assets/images/login/closeBtn.png')" style="cursor: pointer;" alt />
      </div>
      <div class="logincontent" @keydown.enter="handleLogin()">
        <div class="loginrt">
          <div class="loginTitle">
            <div class="codebtnTxtBox" :class="{ active: state == 2 }" @click="btnClick(2)">
              <p class="codebtnTxt">密码登录</p>
              <div class="line"></div>
            </div>
            <div class="codebtnTxtBox" :class="{ active: state == 1 }" @click="btnClick(1)">
              <p class="codebtnTxt">验证码登录</p>
              <span class="line"></span>
            </div>
          </div>
          <div class="detail">
            <div v-if="state == 1">
              <div class="inputbox">
                <i class="usernameicon icon"></i>
                <el-input type="text" v-model="phone" class="inputboxwid" placeholder="请输入手机号"
                  autocomplete="off"></el-input>
              </div>
              <div class="codeBoxinput">
                <div class="codeBox">
                  <i class="codeicon icon"></i>
                  <el-input type="text" clearable v-model="imgCode" placeholder="请输入图形校验结果" autocomplete="off"></el-input>
                </div>
                <img @click='handleGraphVerification' :src="imgCodeVer" alt="">
              </div>
              <div class="codeBoxinput">
                <div class="codeBox">
                  <i class="codeicon icon"></i>
                  <el-input type="text" clearable v-model="codeinput" placeholder="请输入验证码" autocomplete="off"></el-input>
                </div>
                <el-button class="codeBtn" @click="showCode()" type="text" v-if="!showCodeTime">{{ codeMsg }}</el-button>
                <el-button type="text" class="codeBtn" v-if="showCodeTime" style="color: #cccfd6; cursor: inherit">
                  {{
                    codeTime }}秒后再试
                </el-button>
              </div>
            </div>
            <div v-if="state == 2">
              <div class="inputbox">
                <i class="usernameicon icon"></i>
                <el-input clearable type="text" v-model="phone" class="inputboxwid" placeholder="请输入手机号"
                  autocomplete="new-password"></el-input>
              </div>
              <div class="pswShow inputbox">
                <i class="password icon"></i>
                <el-input :type="flagType" :focus="readonly = false" :readonly="readonly" class="inputboxwid"
                  v-model="password" placeholder="请输入密码" autocomplete="new-password"></el-input>
                <i :class="[this.flag ? 'iconfont icon-yanjing' : 'iconfont icon-biyanjing']" @click="getFlag()"></i>
              </div>
              <SliderCheck :successFun="handleSuccessFun" :errorFun="handleErrorFun"></SliderCheck>
            </div>
          </div>
          <div class="forgetBox">
            <div class="forgetTxt"></div>
            <div class="registerTxt" @click="registerhandle">新用户注册</div>
          </div>
          <el-button @click="handleLogin()" class="loginBtn">登录</el-button>
          <!-- <el-button  class="closeloginBtn">取消登录</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import register from "../page/register";
// import Vcode from "vue-uzzle-vcode";
import SliderCheck from '../components/plugin/SliderCheck'

export default {
  name: 'LoginHome',
  components: {
    // Vcode,
    // register,
    SliderCheck
  },
  data() {
    return {
      // 密码小眼睛切换状态
      flag: false,
      flagType: 'password',
      readonly: true,
      state: 2, // 1：验证登录 2：密码登录
      SliderCheckState: false, // 滑块
      password: '', // 密码
      phone: '', // 手机号
      codeinput: '', // 验证
      codeTime: 60,
      showCodeTime: false,
      codeMsg: '获取验证码',
      modelopen: false, // 模态框显示隐藏
      registeropen: true, // 注册框值
      imgCodeVer: '',
      uuid: '',
      imgCode: ''
    }
  },
  mounted() {
    let maintenanceStartTime = this.START_TIME // 维护开始时间
    let maintenanceEndTime = this.END_TIME // 维护结束时间
    let currentTime = new Date()
    if (currentTime >= maintenanceStartTime && currentTime <= maintenanceEndTime) {
      location.reload();
    }
  },

  methods: {

    async handleGraphVerification() {
      const timer = new Date()

      const res = await this.$http({
        url: `/verify/getCode?time=${timer}`,
        method: 'GET'
      })
      this.imgCodeVer = res.data.data.img
      this.uuid = res.data.data.uuid
    },
    // 切换眼睛
    getFlag() {
      this.flag = !this.flag
      this.flagType = this.flag ? 'text' : 'password' // text为显示密码；password为隐藏密码
    },
    // 点击取消
    handleLogincancel() {
      this.modelopen = false
      document.querySelector('.homeLoginBigBox').style.display = 'none'
      this.$store.commit('handleLocalLogin', false)
    },
    // 点击去注册页面
    registerhandle() {
      // 点击登录向父组件传值
      this.$emit('registeropen', this.registeropen)
      this.modelopen = false
      document.querySelector('.homeLoginBigBox').style.display = 'none'
      this.$store.commit('handleLocalLogin', false)
    },
    btnClick(index) {
      this.state = index
      this.password = ''
      this.phone = ''
    },
    handleSuccessFun() {
      this.SliderCheckState = true // 标记验证通过
    },
    // 滑块验证失败回调
    handleErrorFun() { },
    showCode() {
      const phoneReg = /^1[3456789]\d{9}$/
      if (this.phone === '') return this.$message.error('请输入手机号')
      if (!phoneReg.test(this.phone)) {
        return this.$message.error('您的用户名格式错误')
      }
      if (!this.imgCode) {
        return this.$message.error('请输入图形验证')
      }
      this.testCode()
    },
    async testCode() {
      const flag = await this.handleCodeClick()
      console.log('flag', flag)
      if (flag) {
        this.showCodeTime = true
        this.codeTime = 60
        const codeTimer = setInterval(() => {
          this.codeTime--
          if (this.codeTime === 0) {
            clearInterval(codeTimer)
            this.showCodeTime = false
            this.codeTime = 60
            this.codeMsg = '获取验证码'
          }
        }, 1000)
      }
    },
    // 发送手机号
    async handleCodeClick() {
      const { data: res } = await this.$http({
        url: '/login/sendSms',
        method: 'post',
        data: {
          phone: this.phone,
          uuid: this.uuid,
          verifyCode: this.imgCode
        }
      })
      if (res.code !== 200) {
        this.handleGraphVerification()
        this.$message.error(res.msg)
        return false
      } else {
        return true
      }
    },
    // 点击登录
    handleLogin() {
      if (this.state === 1) {
        if (this.phone === '') return this.$message.error('请输入手机号')
        this.symbol = '@_@0'
        this.password = this.codeinput
      }
      if (this.state === 2) {
        if (this.phone === '') return this.$message.error('请输入手机号')
        this.symbol = '@_@1'
        if (this.password === '') return this.$message.error('请输入密码')
        if (!this.SliderCheckState) {
          return this.$message.error('请拖滑块到最右')
        }
      }
      this.LoginData()
    },

    // 登录
    async LoginData() {
      const { data: res } = await this.$http({
        url: '/login/doLogin',
        method: 'post',
        data: {
          phone: this.phone + this.symbol,
          password: this.password
        }
      })
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.code === 200) {
        if (res.data.photo !== '') {
          // localStorage.setItem("userImage", res.data.photo);
          // 将照片存到vuex
          this.$store.commit('setImage', res.data.photo)
        }
        // 手机号
        localStorage.setItem('phone', this.phone)
        // 用户名
        this.$store.commit('userName', res.data.userName)
        // 登录成功之后token
        localStorage.setItem('token', res.data.jwt)
        this.$store.commit('setUserToken', res.data.jwt)
        // 登录之后存储有效时间
        const date = new Date().getTime() + 7 * 24 * 60 * 60 * 1000 // 7*24小时*60分钟*60秒*1000毫秒(一周)
        localStorage.setItem('expiration', date)
        this.modelopen = false
        document.querySelector('.homeLoginBigBox').style.display = 'none'
        this.password = '' // 密码
        this.phone = '' // 手机号
        // location.reload();
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  watch: {
    '$store.state.localLogin': {
      // deep: true,
      // immediate: true,
      handler(newData, oldData) {
        if (newData === true) {
          this.$store.commit('handleLocalLogin', false)
          this.handleGraphVerification()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  border: none;
  height: 48px;
  line-height: 48px;
  box-sizing: border-box;
}

.pswShow {
  position: relative;

  .iconfont {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    color: #c0c4cc;
    font-size: 20px;
    cursor: pointer;

    &:hover {
      color: #9a9599;
    }
  }
}

.modalclose {
  transition: all 0.5s;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.2);
}

.loginBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 782px;
  height: 546px;
  background: #ffffff;
  border-radius: 10px;
  padding-top: 2%;
  z-index: 999;

  .closeBtnBox {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-right: 3%;
    padding-bottom: 5%;
  }

  .logincontent {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    .loginrt {
      .loginTitle {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .codebtnTxtBox {
          cursor: pointer;

          .codebtnTxt {
            font-size: 28px;
            font-weight: 400;
            color: #666666;
          }

          .line {
            width: 50px;
            height: 4px;
            background: transparent;
            border-radius: 2px;
            display: inline-block;
          }
        }

        .active {
          .codebtnTxt {
            transition: 1s all;
            font-size: 28px;
            font-weight: 400;
            color: #9c2526;
          }

          .line {
            transition: 1s all;
            width: 50px;
            height: 4px;
            background: #9c2526;
            border-radius: 2px;
          }
        }
      }

      width: 480px;

      .loginTitle {
        font-size: 26px;
        font-weight: 500;
        color: #fa5119;
        text-align: center;
        margin-bottom: 35px;
      }

      .inputbox {
        //  padding: 0px 2.6042vw;
        /*输入内容左右边距控制*/
        box-sizing: border-box;
        width: 480px;
        height: 50px;
        border: 1px solid #bdbdbd;
        border-radius: 6px;
        margin-bottom: 20px;
        position: relative;
        padding-left: 47px;
      }

      .codeBoxinput {
        display: flex;
        box-sizing: border-box;
        width: 480px;
        height: 50px;
        position: relative;
        margin-top: 20px;
      }

      .icon {
        position: absolute;
        top: 50%;
        left: 22px;
        transform: translateY(-50%);
        z-index: 1;
      }

      .usernameicon {
        width: 14px;
        height: 16px;
        background: url("../assets/images/login/phoneIcon.png") no-repeat center;
        background-size: contain;
      }

      .inputboxwid {
        width: 100%;
        height: auto;
        box-sizing: border-box;
      }

      .password {
        width: 14px;
        height: 16px;
        background: url("../assets/images/login/passwordIcon.png") no-repeat center;
        background-size: contain;
      }

      .codeicon {
        width: 14px;
        height: 18px;
        background-size: contain;
        background: url("../assets/images/login/code.png") no-repeat center;
      }

      .codeBtn {
        position: absolute;
        top: 0;
        right: 0;
        width: 130px;
        height: 50px;
        border: 1px solid #bdbdbd;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 400;
        color: #9c2526;
      }

      .codeBox {
        width: 337px;
        box-sizing: border-box;
        height: 50px;
        border: 1px solid #bdbdbd;
        border-radius: 6px;
        margin-bottom: 20px;
        position: relative;
        padding-left: 47px;
      }

      // /deep/ .el-input__inner {
      //   position: relative;
      //   font-size: 14px;
      //   display: inline-block;
      //   /* width: 100%; */
      //   width: 17.5vw;
      // }

      .loginBtn {
        width: 480px;
        height: 50px;
        background: #9c2526;
        border-radius: 6px;
        color: #fff;
        margin-top: 50px;
      }

      .closeloginBtn {
        margin-top: 15px;
        width: 480px;
        height: 50px;
        background: #9c2526;
        border-radius: 6px;
        color: #fff;
        margin-left: 0;
      }

      .forgetBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .forgetTxt {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }

        .registerTxt {
          font-size: 14px;
          font-weight: 400;
          color: #9c2526;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
