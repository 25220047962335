<template>
  <div>
    <div class="head">
      <signagure-head></signagure-head>
    </div>
    <div class="content">
      <div class="table">
        <el-table max-height="600" :data='authorList' :cell-style="tableCellStyle" width='100%'
          :header-cell-style="tableHead" class="elTab" ref="tables" align="center">
          <el-table-column label="序号" width="80">
            <template #default="scope">{{ (page - 1) * 8 + scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop='title' label="文献题目" align="center"></el-table-column>
        </el-table>
        <div style="margin-top: 10px;color: #666666;">温馨提示：以上是您认领的主文献，平台上线后，您还可以继续认领或退领。</div>
        <div class="elPagination" style="margin-top: 10px;">
          <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange"
            :current-page="page" :page-size="8">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="handleBtn">
      <el-button type="primary" @click="goback">返回</el-button>
    </div>
  </div>
</template>
<script>
import signagureHead from '@/components/head/signagureHead'
export default {
  components: {
    signagureHead
  },
  data() {
    return {
      total: 0,
      checked: '',
      tableData: {
        title: ''
      },
      sure: false,
      userCode: '',
      centerDialogVisible: false,
      authorList: [],
      mineCommentListReqDto: {
        page: 1,
        pageRow: 10
      },
      list: {
        referenceNumbers: [],
        userCode: ''
      },
      checkAll: false,
      page: 1,
    }
  },
  mounted() {
    document.title = '主文献-传播学术精品'
    this.userCode = this.$store.state.userCodeh5 || localStorage.getItem('userCodeh5')
    this.list.userCode = this.userCode
    this.getList()
  },
  // activated () {
  //   this.$bus.$on('pch5', val => {
  //     this.authorList.forEach(item => {
  //       if (item.referenceNumber == val) {
  //         item.checked = true
  //       }
  //     })
  //     console.log('callback', this.authorList)
  //     const newArr = this.authorList.filter(item => {
  //       return item.checked
  //     })
  //     if (newArr.length == this.authorList.length) {
  //       this.checkAll = true
  //     } else {
  //       this.checkAll = false
  //     }
  //   })
  // },
  methods: {
    handleCurrentChange(val) {
      console.log(val)
      this.page = val
      this.getList()
    },
    // handleSizeChange (val) {
    //   console.log(val)
    //   this.mineCommentListReqDto.page = 1
    //   this.mineCommentListReqDto.pageRow = val
    //   this.getList()
    // },
    tableHead() {
      return 'background-color: #f2f2f2;text-align:center;color:#9c2526'
    },
    tableCellStyle() {
      return 'border: 1px;text-align: center'
    },
    // goDetail (row) {
    //   console.log(row)
    //   this.$router.push('/signaturesDetail?referenceNumber=' + row.referenceNumber)
    // },
    async getList() {
      const { data: res } = await this.$http({
        url: '/claimApplet/litersClaimed',
        method: 'post',
        data: {
          userCode: this.userCode
        }
      })
      this.total = res.data.length
      this.authorList = res.data.splice((this.page - 1) * 8, 8)
    },
    goback() {
      this.$router.go(-1)
    }

  }
}
</script>
<style lang='less' scoped>
/deep/.el-table tr .el-table__cell .cell {
  width: 100% !important;
}
.content {
  width: 1440px;
  height: 800px;
  // background-color: orange;
  margin: 21px auto;

  .remind {

    background: #ffeee0;
    width: 1440px;
    height: 40px;
    line-height: 40px;

    .el-icon-warning {
      color: #ff7100;
      font-size: 16px;
      margin-left: 20px;
    }

    span {
      font-size: 16px;
      color: #ff7100;
      margin-left: 9px;
    }
  }

  .table {
    width: 1440px;
    margin-top: 10px;

    .promise {
      margin-top: 20px;
      margin-left: 9px;

      span {
        color: #999;
        font-size: 16px;
      }
    }
  }

  .elPagination {
    margin-top: 20px;
  }
}

.handleBtn {
  width: 460px;
  height: 50px;

  .el-button {
    width: 460px;
    height: 50px;
    position: fixed;
    bottom: 100px;
    left: 50%;
    border-radius: 25px;
    transform: translate(-50%);
  }
}
</style>
