<template>
  <div class="conterBox">
    <div class="conterTopchartBox">
      <div class="linechartTitlebox">
        <p>入选趋势</p>
        <div>
          <el-date-picker v-model="startyear" type="year" placeholder="开始时间" value-format="yyyy"
            @change="handlestartyear" :picker-options="pickerOptions"></el-date-picker>
          <span style="margin:0 10px;">-</span>
          <el-date-picker v-model="endtyear" type="year" placeholder="结束时间" value-format="yyyy" @change="handleendtyear"
            :picker-options="pickerOptions"></el-date-picker>
        </div>
      </div>
      <div id="SankeyDiagram" class="SankeyDiagram" ref="research"></div>
    </div>
    <div>
      <span class='mainListInfo'>入选主文献</span>
      <div>
        <mainList :Listobj="Listobj"></mainList>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import mainList from '../main/mainlist'
import elementResizeDetectorMaker from 'element-resize-detector'
const erd = elementResizeDetectorMaker()
export default {
  props: ['parentnamecode', 'rnList'],
  components: { mainList },
  data () {
    return {
      // 传给子组件
      rnl: [],
      Listobj: {
        type: 1, /// 0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅
        url: '/authorList/search/literature',
        authorCode: '',
        authorName: '',
        orgCode: '',
        firstLevelList: [],
        secondLevelList: [],
        rnList: []
      },
      startyear: new Date().getFullYear() - 10 + '', // 开始时间
      endtyear: new Date().getFullYear() - 1 + '', // 结束时间
      id: '',
      pickerOptions: {
        disabledDate: time => {
          const date = new Date().getFullYear()
          const yearEnd = time.getFullYear()
          return date < yearEnd
        }
      }
    }
  },
  mounted () {
    this.id = this.$route.query.id
    // this.gethotspots()
  },
  methods: {
    // 选择年份
    handlestartyear (start) {
      this.startyear = start
      if (this.startyear > this.endtyear) {
        return this.$message({
          type: 'warning',
          message: '建议开始时间选择大于结束时间,结束时间早于开始时间'
        })
      }
      this.gethotspots()
    },
    handleendtyear (end) {
      this.endtyear = end
      if (this.startyear > this.endtyear) {
        return this.$message({
          type: 'warning',
          message: '建议开始时间选择大于结束时间,结束时间早于开始时间'
        })
      }
      this.gethotspots()
    },
    // 获取研究学科的数据
    async gethotspots () {
      if (parseInt(this.endtyear) < parseInt(this.startyear)) {
        return this.$message.error('输入的日期不正确，请稍后重试!')
      }
      const { data: res } = await this.$http({
        url: '/authorShow/research',
        method: 'post',
        data: {
          endYear: parseInt(this.endtyear),
          initYear: parseInt(this.startyear),
          id: this.id,
          rnList: this.rnl
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      var researchElements = this.$refs.research // 绑定元素
      var themeStyle = this.$echarts.init(researchElements)
      // 图表自适应配置
      const chartNode = new ResizeObserver(() => {
        themeStyle.resize()
      })
      chartNode.observe(researchElements)
      function fontSize (res) {
        const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        if (!clientWidth) return
        const fontSize = 100 * (clientWidth / 1920)
        return res * fontSize
      }
      // 绘制图表
      var option = {
        responsive: true,
        // xAxis: {
        //   axisLabel: {
        //     textStyle: {
        //       fontSize: fontSize(0.14)
        //     }
        //   }
        // },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            var res =
              "<div style='margin-bottom:5px;padding:0 18px;width:100%;height:30px;color: #505D6F;margin-top:15px;'><p>" +
              params[0].data[0] +
              ' </p></div>'
            params.forEach(item => {
              res += `<div style="color: #505D6F;background-color:#fff;font-size: 14px; padding:0 12px;line-height: 30px">
              <span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:${[
                  item.color
                ]};"></span>
                        ${item.data[2]} :
                        ${item.data[1]}
                        </div>`
            })
            return res
          },
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: 'rgba(0,0,0,0.2)',
              width: fontSize(0.01),
              type: 'solid'
            }
          }
        },
        legend: {
          type: 'scroll', // 设置图例为滚动样式
          // bottom: '0%',
          top: '5%',
          left: 'left',
          data: res.data.discName,
          itemHeight: fontSize(0.06), // 修改icon图形大小
          itemGap: fontSize(0.14), // 修改间距
          textStyle: {
            fontSize: fontSize(0.14)
          },
          pageIconSize: fontSize(0.16),
          pageTextStyle: { // 图例页信息的文字样式
            fontSize: fontSize(0.14)
          }
        },
        singleAxis: {
          top: fontSize(0.5),
          bottom: fontSize(0.5),
          axisTick: {},
          axisLabel: {
            textStyle: {
              fontSize: fontSize(0.14)
            }
          },
          type: 'time',
          // axisPointer: {
          //   animation: true,
          //   label: {
          //     show: true
          //   }
          // },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              opacity: 0.2
            }
          }
        },
        series: [
          {
            type: 'themeRiver',
            emphasis: {
              itemStyle: {
                shadowBlur: fontSize(0.2),
                shadowColor: 'rgba(0, 0, 0, 0.8)'
              }
            },
            data: res.data.discData,
            label: {
              show: false
            }
          }
        ]
      }
      option && themeStyle.setOption(option)
      erd.listenTo(this.$refs.research, function () {
        themeStyle.resize()
      })
    }
  },
  beforeDestroy () {
    erd.uninstall(this.$refs.research)
  },
  watch: {
    parentnamecode: {
      handler (data) {
        this.Listobj.authorCode = data.code
        this.Listobj.authorName = data.name
        this.Listobj.orgCode = data.orgCode
        this.Listobj.orgName = data.orgName
      },
      deep: true // 深度监听，监听obj对象内部属性的变化
    },
    rnList: {
      handler (data) {
        this.Listobj.rnList = data
        this.rnl = data
        this.gethotspots()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.conterBox {
  .conterTopchartBox {
    width: 740px;
    height: 320px;
    box-sizing: border-box;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    margin-bottom: 20px;

    .SankeyDiagram {
      width: 100%;
      height: 250px;

      // /deep/canvas {
      //   width: 680px !important;
      //   height: 250px !important;
      // }
    }

    .linechartTitlebox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      >p {
        font-size: 18px;
        font-weight: 500;
        color: #9c2526;
      }

      /deep/.el-range-editor.el-input__inner {
        width: 200px;
      }
    }
  }
}

.mainListInfo {
  color: #9c2526;
  font-size: 17.9904px;
  // font-weight: 700;
  margin-bottom: 10px;
  display: block;
}
</style>
