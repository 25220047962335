<template>
  <div class="collectBox">
    <div class="listgroup">
      <mainList :Listobj="Listobj" :collect="true"></mainList>
    </div>
  </div>
</template>

<script>
import mainList from '../../components/main/mainlist'

export default {
  components: { mainList },
  data () {
    return {
      Listobj: {
        type: 7, /// 0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅
        url: ''
      },
      collectSearch: '' // 收藏搜索
    }
  },
  mounted () {
    document.title = this.$route.meta.title
    document.querySelector('.home').style.backgroundColor = '#fff'
    document.querySelector('.middleContainer').style.backgroundColor = '#fff'
    // this.Listobj.url = '/userinfo/mineCollect'
     this.Listobj.url = '/userinfo/collect'
  },
  methods: {},
  watch: {
    $route: 'updateTitle'
    // themeWord: {
    //   deep: true,
    //   handler(themeWord) {
    //     this.Listobj.url = themeWord;
    //     this.jgsidename.orgName = themeWord;
    //   }
    // }
  }
}
</script>

<style scoped lang="less">
/deep/.el-input {
  width: 550px;
  margin-right: 20px;
}

.collectBox {
  width: 1156px;

  .listgroup {
    .stationline {
      display: inline-block;
      width: 2px;
      height: 16px;
      background: #cccccc;
      margin: 0 10px;
      vertical-align: text-bottom;
    }

    .listgroupLine {
      width: 100%;
      height: 1px;
      background: #e6e6e6;
      border-radius: 1px;
      margin: 15px 0;
    }

    .recommend {
      vertical-align: text-bottom;
      text-align: center;
      display: inline-block;
      width: 36px;
      height: 18px;
      background: #ff7100;
      border-radius: 2px;
      font-size: 12px;
      color: #ffffff;
      margin-right: 10px;
    }

    .listgroupTitleType {
      box-sizing: border-box;
      vertical-align: text-bottom;
      display: inline-block;
      text-align: center;
      width: 60px;
      height: 20px;
      background: rgba(255, 113, 0, 0.1);
      border: 1px solid #ff7100;
      border-radius: 10px;
      font-size: 12px;
      color: #ff7100;
      margin: 0 10px;
    }

    .listgroupTitleactive {
      font-size: 20px;
      font-weight: 500;
      color: @my-color;
    }

    .listgroupTitle {
      font-size: 20px;
      font-weight: 500;
    }

    .listgroupnameBox {
      margin: 15px 0;

      .listgroupname {
        font-size: 16px;
        color: #000000;
      }

      .stationdrop {
        margin: 0 10px;
      }
    }

    .listgroupTxt {
      color: #666;
    }

    .keyword {
      color: #000;
      margin: 15px 0;
    }

    .keywordtype {
      margin: 15px 0;

      >span {
        padding: 2px 4px;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        margin-right: 10px;
      }

      >span:last-child {
        margin-right: 0px;
      }
    }

    .listBotttom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .listBotttomLF {
        display: flex;

        .listBotttomLFBox {
          .listBotttomtxt {
            color: #666;
          }

          .listBotttoomActive {
            color: @my-color;
          }
        }

        .listBotttomItem {
          margin-left: 20px;

          .listBotttomItemactive {
            color: @my-color;
            margin-left: 10px;
          }
        }
      }

      .listBotttomrf {
        margin-left: 20px;
      }
    }
  }

  .pageBox {
    .listgroupLine {
      width: 100%;
      height: 1px;
      background: #e6e6e6;
      border-radius: 1px;
      margin: 15px 0;
    }
  }
}
</style>
