<template>
  <div class="detailrinstitutiontBox">
    <div class="selectedjournal">
      <div class="journalTitle">
        <p>{{ journalsidelistData.name }}</p>
        <span class="journalTotal">({{ journalTotal }}种)</span>
      </div>
      <div class="box" v-if="journalTotal">
        <div style="height:100%;width:100%;overflow:scroll" v-infinite-scroll="load" infinite-scroll-disabled="busy"
          infinite-scroll-immediate='false' infinite-scroll-distance="5">
          <div class="selectedjournalBox">
            <div class="journalItem" v-for="(item, index) in journalList" :key="index"
              @click="handlegodetail(item.id, item.journalName)">
              <div class="journalItemimg">
                <img :src="item.path" v-if="item.path" alt />
                <div class="empjournal" v-else>
                  <img src="../../assets/images/home/jqdetailimg.png" style="width: 93%;height:41%;margin-top: 55%;"
                    alt />
                </div>
              </div>
              <p class="itemTxt overflow-dot">{{ item.journalName }}</p>
            </div>
            <div class="busyload" v-if='busy && journalList.length > 0'>加载中...</div>
          </div>
        </div>
      </div>
      <empty :authorlistSide="authorlistSide"></empty>
    </div>
  </div>
</template>

<script>
import empty from '../../components/common/emptyside.vue'
export default {
  // props: ["listname"],
  components: { empty },
  props: {
    journalsidelistData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      orgCode: '',
      busy: false,
      current: 1,
      size: 10,
      journalTotal: 0,
      journalList: [], // 机构列表
      authorlistSide: []
    }
  },
  mounted () { },
  methods: {
    load () {
      if (this.journalTotal === this.journalList.length) {
        return
      }
      this.current++
      this.getjournalData(this.orgCode)
    },
    // 点击跳转
    handlegodetail (id, orgName) {
      const routeData = this.$router.resolve({
        path: 'journaldetail?id=' + id + '&name=' + orgName
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    // 热词

    // 入选期刊分布
    async getjournalData (orgCode) {
      this.busy = true
      const { data: res } = await this.$http({
        url: this.journalsidelistData.url,
        method: 'POST',
        data: {
          orgCode: orgCode, // 机构名称
          current: this.current,
          size: this.size
        }
      })

      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.data != null) {
        this.busy = false
        this.journalList.push(...res.data.orgJournalBasicDto)
        if (res.data.journalCount !== '' && res.data.journalCount !== null) {
          this.journalTotal = parseInt(res.data.journalCount)
        }
        this.authorlistSide = res.data
      }
    }
  },
  watch: {
    journalsidelistData: {
      deep: true,
      handler (data) {
        // 主要学科分布
        this.orgCode = data.orgCode
        this.getjournalData(data.orgCode)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.detailrinstitutiontBox {

  // margin-bottom: 20px;
  .detailrinstitutiontTop {
    width: 340px;
    height: 260px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;

    .topTitle {
      margin-left: 19px;
      font-size: 18px;
      font-weight: 500;
      color: #9c2526;
      padding: 10px 0 0 20px;
    }

    .areachart {
      width: 340px;
      height: 250px;
    }
  }

  .detailrinstitutiontBottom {
    padding-top: 20px;
    padding-bottom: 10px;
    box-sizing: border-box;
    width: 340px;
    min-height: 570px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;

    // margin-top: 20px;
    .topTitle {
      margin-left: 19px;
      font-size: 18px;
      font-weight: 500;
      color: #9c2526;
      padding-bottom: 20px;
      // padding: 20px 0 0 20px;
    }
  }

  .selectedjournal {
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;

    .journalTitle {
      margin: 0 20px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      >p {
        font-size: 18px;
        font-weight: 500;
        color: #9c2526;
      }

      .journalTotal {
        margin-left: 10px;
        font-weight: 600;
        color: @my-color;
      }
    }

    .box {
      .selectedjournalBox {
        width: 100%;
        height: 556px;
        padding: 0 0 10px 0;

        .journalItem {
          padding: 0 20px;
          margin-top: 57px;
          height: 380px;
          width: 260px;
          // margin: 20px;
          box-sizing: border-box;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          display: block;
          margin: 15px auto;
          // overflow: hidden;

          .journalItemimg {
            height: 322px;
            width: 220px;
            margin: 20px auto 0;
            box-sizing: border-box;

            >img {
              width: 100%;
              height: auto;
              object-fit: contain;
              // object-fit: cover;
            }

            .empjournal {
              width: 220px;
              height: 296px;
              background: #FAFAFC;
              border-radius: 4px;
            }
          }

          .itemTxt {
            height: 38px;
            line-height: 38px;
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            text-align: center;
          }
        }
      }
    }
  }
}

// #region 解决el-scrollbar出现双滚动条bug
// 垂直方向

.el-main {
  overflow: hidden;
}

.busyload {
  height: 60px;
  text-align: center;
  color: #9c2526;
}
</style>
