<template>
  <div class="modalclose" v-if="registershow">
    <div class="registerBox">
      <div class="closeBtnBox" @click="handleregisterclose()">
        <img :src="require('../assets/images/login/closeBtn.png')" alt />
      </div>
      <div class="registergroupBox">
        <div class="registergroup">
          <div class="registerTxtBox">
            <p class="registerTxt">新用户注册</p>
            <span class="line"></span>
          </div>
          <div class="registerfrom">
            <!-- <el-input type="text" clearable v-model="nickname" class="nickname" placeholder="请输入用户昵称"></el-input> -->
            <el-input type="text" @blur="handlePhone" clearable v-model="phone" class="username" placeholder="请输入手机号"
              autocomplete="off"></el-input>
            <div class="passBox">
              <el-input type="text" style="width: 326.4px;" clearable v-model.trim="imgCode" class="codeicon"
                placeholder="请输入图形验证码" maxlength='6'></el-input>
              <img class='codeBtn' @click='handleGraphVerification' :src="imgCodeVer" alt="">
            </div>
            <div class="passBox">
              <el-input type="text" style="width: 326.4px;" clearable v-model="code" class="codeicon"
                placeholder="请输入验证码"></el-input>
              <el-button class="codeBtn" :disabled='phone && imgCode ? false : true' @click="showCode()" type="text"
                v-if="!showCodeTime">{{ codeMsg }}</el-button>
              <el-button type="text" class="codeBtn" v-if="showCodeTime" style="color: #cccfd6; cursor: inherit">
                {{
                  codeTime }}秒后再试
              </el-button>
            </div>
            <el-input type="text" :focus="readonly = false" :readonly="readonly" clearable show-password
              v-model="password" class="password" placeholder="密码长度8-20位字符，至少包括字母、数字两种字符元素"
              autocomplete="new-password"></el-input>
            <el-input type="text" :focus="readonly = false" :readonly="readonly" clearable show-password
              v-model="passwordtwo" class="password" placeholder="请再次输入密码" autocomplete="new-password"></el-input>
            <!-- <div class="selectUser">
              <el-radio v-model="selectUseritem" label="作者用户">作者用户</el-radio>
              <el-radio v-model="selectUseritem" label="读者用户">读者用户</el-radio>
            </div>-->
            <!-- <el-select v-model="institutionvalue" filterable placeholder="请查询机构单位" class="institutionvalue">
              <el-option v-for="item in institutionList" :key="item.code" :label="item.name"
                :value="item.code"></el-option>
            </el-select>-->
            <div>
              <el-checkbox v-model="isseen" class="rcheck"></el-checkbox>
              <span style="margin-left: 5px;">我已阅读并同意</span>
              <span class="agreement" @click="goAgree">《主文献平台使用协议》</span>、
              <span class="agreement" @click="goPerAgree">《隐私政策》</span>
            </div>
            <el-button @click="handleregister()" class="registerBtn">注册</el-button>
            <div class="handleLogingobox">
              <el-button type="text" @click="handleLogingo()">用户登录</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      readonly: true,
      // selectUseritem: '作者用户', // 选择用户
      // institutionList: [],
      // institutionvalue: '', // 请选择作者机构
      password: '', // 密码
      passwordtwo: '', // 二次确认密码
      phone: '',
      nickname: '', // 昵称
      code: '',
      codeTime: 60,
      showCodeTime: false,
      codeMsg: '获取验证码',
      registershow: false,
      loginopen: true,
      isseen: false,
      imgCodeVer: '',
      uuid: '',
      imgCode: ''
    }
  },
  mounted () {
    // if (this.registershow) {
    //   this.handleGraphVerification()
    // }
  },
  methods: {
    async handleGraphVerification () {
      const timer = new Date()
      const res = await this.$http({
        url: `/verify/getCode?time=${timer}`,
        method: 'GET'
      })
      this.imgCodeVer = res.data.data.img
      this.uuid = res.data.data.uuid
    },
    // 机构列表
    // async orgListData() {
    //   const { data: res } = await this.$http({
    //     url: '/login/orgList',
    //     method: 'post',
    //     data: {
    //       opt: ''
    //     }
    //   })
    //   if (res.code !== 200) return this.$message.error(res.msg)
    //   this.institutionList = res.data
    // },
    // 校验手机号是否存在
    async handlePhone () {
      const res = await this.$http({
        url: '/login/checkPhone',
        method: 'POST',
        data: {
          phone: this.phone
        }
      })
      if (res.data.code === 115040) {
        this.$message.error(res.data.msg)
      }
      if (res.data.code !== 200) {
        this.handleGraphVerification()
        return this.$message.error(res.data.msg)
      }
      console.log(res)
    },
    // 点击取消注册
    handleregisterclose () {
      this.registershow = false
    },
    showCode () {
      const phoneReg = /^1[3456789]\d{9}$/
      if (this.phone === '') return this.$message.error('请输入手机号')
      if (!phoneReg.test(this.phone)) {
        return this.$message.error('您的手机号格式错误')
      }
      if (!this.imgCode) {
        return this.$message.error('请输入图形验证')
      }
      this.testCode()
      // this.testCode();
      // this.isCode = true; //图像遮罩层显示
    },
    async testCode () {
      const flag = await this.handleCodeClick()
      if (flag) {
        this.showCodeTime = true
        this.codeTime = 60
        const codeTimer = setInterval(() => {
          this.codeTime--
          if (this.codeTime === 0) {
            clearInterval(codeTimer)
            this.showCodeTime = false
            this.codeTime = 60
            this.codeMsg = '获取验证码'
          }
        }, 1000)
      }
    },
    // 发送手机号
    async handleCodeClick () {
      const { data: res } = await this.$http({
        url: '/login/sendSms',
        method: 'post',
        data: {
          phone: this.phone,
          verifyCode: this.imgCode,
          uuid: this.uuid
        }
      })
      // console.log(res)
      if (res.code !== 200) {
        this.handleGraphVerification()
        this.$message.error(res.msg)
        return false
      } else {
        return true
      }
    },
    // 点击注册
    handleregister () {
      // 手机号
      if (this.phone === '') return this.$message.error('请输入手机号')
      // 验证码为空
      if (this.code === '') return this.$message.error('请输入验证码')
      if (this.password !== this.passwordtwo) {
        return this.$message.error('请保持两次密码相同')
      }
      if (this.isseen === false) { return this.$message.error('请确认阅读并同意注册协议') }
      this.registerData()
    },
    // 注册
    async registerData () {
      const { data: res } = await this.$http({
        url: '/login/toRegister',
        method: 'post',
        data: {
          phone: this.phone, // 账号
          password: this.password, // 密码
          smsCode: this.code // 短信验证
          // name: this.nickname,
          // company: this.institutionvalue // 机构
        }
      })
      // 注册成功
      if (res.code !== 200) {
        this.handleGraphVerification()
        return this.$message.error(res.msg)
      }
      this.$message('注册成功直接登录')
      // 如果成功
      this.registershow = false
      this.$emit('emitSuccessRegister')
      // 清空
      // this.selectUseritem = '' // '作者用户', // 选择用户
      // this.institutionList = []
      // this.institutionvalue = '' // 请选择作者机构
      this.password = '' // 密码
      this.passwordtwo = '' // 二次确认密码
      this.phone = ''
      this.nickname = '' // 昵称
      this.code = ''
    },
    // 点击用户登录
    handleLogingo () {
      this.$emit('loginopen', this.loginopen)
      this.registershow = false
    },
    // 跳转主文献平台使用协议
    goAgree () {
      const routeData = this.$router.resolve({
        path: '/agree'
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    // 跳转隐私协议
    goPerAgree () {
      const routeData = this.$router.resolve({
        path: '/personalagree'
      })
      window.open(routeData.href, '_blank')
    }
  },
  watch: {
    registershow: {
      handler (newData, oldData) {
        console.log('register', newData)
        if (newData === true) {
          this.handleGraphVerification()
        }
      }
    }
    // '$store.state.localLogin': {
    //   // immediate: true,
    //   deep: true,
    //   handler (newData) {
    //     console.log('this.registershow', this.registershow)
    //     if (newData === false) {
    //       this.handleGraphVerification()
    //     }
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
.messageIndex {
  z-index: 999999999 !important;
}

.modalclose {
  transition: all 0.5s;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.2);
}

.handleLogingobox {
  text-align: center;
}

.registerBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 780px;
  background: #ffffff;
  border-radius: 10px;
  padding: 2% 0;

  .closeBtnBox {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-right: 3%;
    // padding-bottom: 5%;
  }

  .registergroupBox {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    .registergroup {
      width: 480px;

      .registerTxtBox {
        text-align: center;
        padding: 3% 0 5% 0;

        .registerTxt {
          font-size: 28px;
          font-weight: 500;
          color: #9c2526;
          padding-top: 20px;
        }

        .line {
          width: 50px;
          height: 4px;
          background: #9c2526;
          border-radius: 2px;
          display: inline-block;
        }
      }
    }
  }

  .registerfrom {

    .nickname,
    .username,
    .password {
      /deep/.el-input__inner {
        padding: 0px 50px;
        /*输入内容左右边距控制*/
        width: 480px;
        height: 50px;
        border: 1px solid #bdbdbd;
        border-radius: 6px;
      }
    }

    .username {
      margin-top: 15px;

      /deep/.el-input__inner {
        background: url("../assets/images/login/phoneIcon.png") no-repeat 15px center;
      }
    }

    .nickname {
      /deep/.el-input__inner {
        background: url("../assets/images/login/usericon.png") no-repeat 15px center;
      }
    }

    .password {
      margin-top: 15px;

      /deep/.el-input__inner {
        background: url("../assets/images/login/passwordIcon.png") no-repeat 15px center;
      }
    }

    .passBox {
      margin-top: 15px;
      position: relative;

      .codeicon {
        /deep/.el-input__inner {
          background: url("../assets/images/login/code.png") no-repeat 18px center;
          padding: 0px 50px;
          /*输入内容左右边距控制*/
          width: 337px;
          height: 50px;
          border: 1px solid #bdbdbd;
          border-radius: 6px;
        }
      }

      .codeBtn {
        position: absolute;
        top: 0;
        right: 0;
        width: 130px;
        height: 50px;
        border: 1px solid #bdbdbd;
        border-radius: 6px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9c2526;
      }
    }

    .institutionvalue {
      margin: 15px 0;

      /deep/.el-input__inner {
        background: url("../assets/images/login/selectinstitution.png") no-repeat 15px center;
        padding: 0 50px;
        /*输入内容左右边距控制*/
        width: 480px;
        height: 50px;
      }
    }

    .rcheck {
      margin-top: 15px;
    }

    .agreement {
      color: #9c2526;
      cursor: pointer;
    }

    .agreement:hover {
      text-decoration: underline;
    }

    .registerBtn {
      width: 480px;
      height: 50px;
      background: #9c2526;
      border-radius: 6px;
      color: #fff;
      margin-top: 50px;
    }

    .closeregisterBtn {
      width: 480px;
      height: 50px;
      background: #9c2526;
      border-radius: 6px;
      color: #fff;
      margin-top: 15px;
      margin-left: 0;
    }

    .selectUser {
      padding-left: 15px;
      margin-top: 15px;
    }
  }
}
</style>
