<template>
  <div
    v-loading="loading"
    element-loading-spinner="none"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <div class="header">
      <signagureHead></signagureHead>
    </div>
    <div class="content">
      <div class="bglogo"></div>
      <div class="loginInfo">
        <div class="logoTitle">
          <img src="../../assets/images/head/logo1.png" alt="" />
          <img
            style="margin-left: 19.2px"
            src="../../assets/images/head/logo2_1.png"
            alt=""
          />
        </div>
        <div class="loginBtn">
          <div class="phone">
            <img src="../../assets/images/login/phoneIcon.png" alt="" />
            <el-input
              :disabled="flagPhone ? true : false"
              v-model.trim="form.phone"
              maxlength="11"
              @blur="handleCheckPhone"
              placeholder="请输入手机号码"
            ></el-input>
          </div>
          <span ref="alertPhone" class="alert alertPhone">请输入手机号</span>
          <div class="phone">
            <img src="../../assets/images/login/code.png" alt="" />
            <el-input
              @blur="handleCheckImg"
              v-model.trim="form.imgCode"
              maxlength="6"
              placeholder="请输入数字"
            ></el-input>
            <img
              class="imgCode"
              @click="handleGraphVerification"
              :src="imgCodeVer"
              alt=""
            />
          </div>
          <span ref="alertImgCode" class="alert alertImgCode">请输入数字</span>
          <div class="phone">
            <img src="../../assets/images/login/code.png" alt="" />
            <el-input
              @blur="handleCheckCode"
              v-model.trim="form.code"
              maxlength="6"
              placeholder="请输入验证码"
            ></el-input>
            <!-- <el-button type="primary" @click='handleSendCode'>{{
              time }}</el-button> -->
            <el-button
              type="primary"
              :disabled="sendCode && form.imgCode ? false : true"
              @click="handleSendCode"
              >{{ time }}</el-button
            >
          </div>
          <span ref="alertCode" class="alert alertCode">请输入验证码</span>
          <el-button class="next" type="primary" @click="handleUserInfo"
            >下一步</el-button
          >
        </div>
        <div class="bottom">
          <el-checkbox @change="handleCheckBox" v-model="checked"></el-checkbox>
          <span
            >&nbsp;&nbsp;已阅读并同意<span
              style="color: #9c2526; cursor: pointer"
              @click="$router.push('/signaturesAgree')"
              >《平台使用协议》</span
            >和<span
              style="color: #9c2526; cursor: pointer"
              @click="$router.push('/signaturesPrivacy')"
              >《隐私协议》</span
            ></span
          >
          <span ref="alertAgree" class="alert alertAgree">请同意协议</span>
        </div>
        <!-- <div @click='handleFeedBack' class="feedBackQuestion">
          <i class="el-icon-document"></i>
          <p>在线反馈问题</p>
        </div> -->
      </div>
    </div>
    <div @click="handleFeedBack" class="feedBackOnline">
      <i class="el-icon-document"></i>
      <p>在线反馈</p>
      <p>问题</p>
    </div>
  </div>
</template>
<script>
import signagureHead from "@/components/head/signagureHead";
export default {
  name: "signaturesPhone",
  components: {
    signagureHead,
  },
  data() {
    return {
      sider: false,
      SliderCheckState: false, // 滑块
      flagPhone: "",
      titleDia: "提示",
      diaInfo: "",
      secode: 3,
      sendCode: true,
      time: "获取验证码",
      userCode:
        this.$store.state.userCodeh5 || localStorage.getItem("userCodeh5"),
      flag: false,
      checked: false,
      title: "登录页",
      form: {
        phone: "",
        code: "",
        realName: "",
        nickName: "",
        imgCode: "",
      },
      email: "",
      centerDialogVisible: false,
      state: "",
      path: "",
      loading: true,
      uuid: "",
      imgCodeVer: "",
    };
  },
  mounted() {
    console.log("开始处理");
    // document.querySelector('body').style.backgroundColor = '#f7f7f7'
    // console.log(this.$route)
    // this.userCode = this.$route.query.userCode
    document.title = "登录" + this.$route.meta.title;
    var index = this.$route.fullPath.indexOf("?");
    this.path = this.$route.fullPath.substr(index + 1); // +1 目的是去除
    // let index = this.$route.fullPath.indexOf("/sg");
    // this.path = this.$route.fullPath.substr(index + 1); // +1 目的是去除 /
    console.log("处理的链接(pc)", this.path);

    if (this._isMobile() && this.BASE_URL != "/api/") {
      window.location.href = this.BASE_URL + "mobile/index.html#/" + this.path;
      this.loading = false;
    } else {
      this.loading = false;
    }
    this.handleTest(this.path);
    // this.handleBack()
    this.handleGraphVerification();
  },
  methods: {
    async handleGraphVerification() {
      const timer = new Date();
      const res = await this.$http({
        url: `/verify/spec/code?time=${timer}`,
        method: "GET",
      });
      this.imgCodeVer = res.data.data.img;
      this.uuid = res.data.data.uuid;
    },
    // 判断是否是手机
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 根据用户状态不同跳转到不同页面
    async handleTest(path) {
      const res = await this.$http({
        url: "/claimApplet/isClaim",
        method: "POST",
        data: {
          link: path,
        },
      });
      if (res.data.code === 210012) {
        return this.$message.error("路径错误，请复制短信/邮箱中的链接重新进入");
      }
      if (res.data.code !== 200 && res.data.code !== 210012) {
        return this.$message.error(res.data.msg);
      }
      this.state = res.data.data.status;
      this.userCode = res.data.data.userCode;
      this.$store.commit("setUserCodeh5", this.userCode);
      localStorage.setItem("userCodeh5", this.userCode);
      this.handleBack();
    },
    handleFeedBack() {
      this.$router.push("/signaturesFeedBack");
    },
    // 页面回填
    async handleBack() {
      const res = await this.$http({
        url: "/claimAuth/selectUserAuthInfoLogin",
        method: "POST",
        data: {
          usercode: this.userCode,
        },
      });

      if (res.data.data.phone) {
        this.flagPhone = res.data.data.phone;
        this.form.phone = res.data.data.phone;
      }
      // this.form.nickName = res.data.data.pseudonym
      // this.form.realName = res.data.data.realName
      // this.email = res.data.data.email
      console.log("回填", res);
    },
    handleCheckPhone() {
      if (this.form.phone === "" || this.form.phone === null) {
        this.$refs.alertPhone.style = "opacity: 1;";
        // this.$refs.alertPhone.style = 'transition: opacity 1s ease;'
        this.flag = false;
      } else {
        this.$refs.alertPhone.style = "opacity: 0;";
        this.flag = true;
      }
    },
    handleCheckImg() {
      if (this.form.imgCode === "") {
        this.$refs.alertImgCode.style = "opacity: 1;";
        // this.$refs.alertPhone.style = 'transition: opacity 1s ease;'
        this.flag = false;
      } else {
        this.$refs.alertImgCode.style = "opacity: 0;";
        this.flag = true;
      }
    },
    handleCheckCode() {
      if (this.form.code === "") {
        this.$refs.alertCode.style = "opacity: 1;";
        // this.$refs.alertPhone.style = 'transition: opacity 1s ease;'
        this.flag = false;
      } else {
        this.$refs.alertCode.style = "opacity: 0;";
        this.flag = true;
      }
    },
    handleCheckReanName() {
      if (this.form.realName === "" || this.form.realName === null) {
        this.$refs.alertRealName.style = "opacity: 1;";
        // this.$refs.alertPhone.style = 'transition: opacity 1s ease;'
        this.flag = false;
      } else {
        this.$refs.alertRealName.style = "opacity: 0;";
        this.flag = true;
      }
    },
    handleCheckBox() {
      if (this.checked) {
        this.$refs.alertAgree.style = "opacity: 0;";
        this.flag = true;
      } else {
        this.$refs.alertAgree.style = "opacity: 1;";
        this.flag = false;
      }
    },
    // 下一步按钮
    async handleUserInfo() {
      if (
        this.form.phone === "" ||
        this.form.phone === null ||
        this.form.code === "" ||
        !this.form.imgCode ||
        !this.checked
      ) {
        this.flag = false;
        this.handleCheckImg();
        this.handleCheckCode();
        if (this.checked === false) {
          this.$message.error("请同意协议");
        }
        this.handleCheckBox();
        return;
      }
      if (this.flag === true) {
        const res = await this.$http({
          url: "/claimApplet/checkSmsByCode",
          method: "POST",
          data: {
            userCode: this.userCode,
            smsCode: this.form.code,
          },
        });
        console.log("状态", this.state);
        if (res.data.code === 200) {
          // this.$store.commit('setUserPhoneh5', this.flagPhone)
          // localStorage.setItem('userPhoneh5', this.flagPhone)
          // localStorage.setItem('userCodeh5', this.userCode)
          // console.log('userCode', this.$store.state.userCode)
          if (this.state === "1") {
            // 跳转到认领列表
            this.$router.replace(`/signaturesLiteratureList?${this.path}`);
          } else if (this.state === "2") {
            this.$router.replace(`/signaturesLiteratureList?${this.path}`);
            // 跳转到输入身份证页面
          } else if (this.state === "3") {
            // 跳转到成功页面
            this.$router.replace("/signaturesSuccess");
          }
          // this.$router.push({ name: 'signaturesLiteratureList', params: { userCode: this.userCode } })
        } else {
          // this.centerDialogVisible = true
          // this.titleDia = '提示'
          // this.diaInfo = res.data.msg
          this.$message({
            type: "warning",
            message: res.data.msg,
          });
        }
      }
    },
    // 发送短信验证码
    async handleSendCode() {
      this.sendCode = false;
      const res = await this.$http({
        url: `/claimApplet/sendSmsByCode?time=${new Date().getTime()}`,
        method: "POST",
        data: {
          userCode: this.userCode,
          verifyCode: this.form.imgCode,
          uuid: this.uuid,
        },
      });
      if (res.data.code !== 200) {
        this.sendCode = true;
        this.handleGraphVerification();
        return this.$message({
          type: "warning",
          message: res.data.msg,
        });
      }
      this.secode = 60;
      const codeTimer = setInterval(() => {
        this.sendCode = false;
        this.time = this.secode + "秒后重新发送";
        this.secode--;
        if (this.secode <= 0) {
          this.sendCode = true;
          clearInterval(codeTimer);
          this.time = "获取验证码";
        }
      }, 1000);
    },
  },
};
</script>
<style lang='less' scoped>
.content {
  display: flex;
  align-items: center;
  // margin-top: 137px;
  // margin-bottom: 202px;
  height: 878px;

  .bglogo {
    width: 660px;
    height: 676px;
    background-image: url("../../assets/images/home/bglogo.png");
    background-size: cover;
    margin-left: 240px;
  }

  .loginInfo {
    width: 460px;
    height: 526px;
    margin-left: 182px;
    position: relative;

    .logoTitle {
      display: flex;
      width: 372px;
      // margin: 0 auto;

      img {
        height: 55px;
      }
    }

    .loginBtn {
      margin-top: 64px;

      .alert {
        color: #9c2526;
      }

      .next {
        // transform: translate(5%, 30px);
        width: 100%;
        height: 50px;
        border-radius: 25px;
        margin-top: 70px;
      }

      .phone {
        // margin-top: 10px;
        display: flex;
        align-items: center;
        background-color: #f6f6f6;
        border-radius: 30px;
        overflow: hidden;
        padding: 10px;

        .el-input__inner {
          border: 0;
          background-color: #f6f6f6;
        }

        img {
          margin-left: 10px;
          width: 20.5px;
          height: 23px;
        }

        .imgCode {
          width: 170px;
          height: 38.4px;
        }

        .el-button {
          border-radius: 20px;
        }
      }
    }

    .bottom {
      font-size: 16px;
      color: #999;
      margin-top: 10px;
    }
  }
}

.alert {
  transition: all 0.5s;
  color: #9c2526;
  opacity: 0;
  font-size: 16px;
  display: block;
  height: 20px;
  line-height: 20px;
}

.feedBackQuestion {
  display: flex;
  // height: (40/100rem);
  // position: absolute;
  // bottom: -12%;
  color: #9c2526;
  // left: 50%;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  // transform: translate(-50%);
  cursor: pointer;
}

/deep/input {
  border: 0 !important;
  background-color: #f7f7f7;
}

.feedBackOnline {
  position: absolute;
  right: 0;
  bottom: 10%;
  width: 80px;
  height: 80px;
  background-color: #f6f6f6;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px 0px 0px 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;

  i {
    font-size: 18px;
    color: #9c2526;
  }

  p {
    font-size: 14px;
    color: #9c2526;
  }
}

/deep/input::-webkit-outer-spin-button,
/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/input[type="number"] {
  line-height: 1;
  -moz-appearance: textfield !important;
}
</style>
