<template>
  <div class="detailBox">
    <div class="detailBoxTop">
      <div class="detailBoxTopLf">
        <div class="topbox">
          <img :src="listgroup.orgCover" v-if="listgroup.orgCover" alt class="imgbox" />
          <span class="imgbox2" :style="randomRgb()" v-else-if='listgroup.orgName'>{{ listgroup.orgName.substring(0, 1)
            }}</span>
          <img class='topSchool' v-if='listgroup.isFirst === 1' src="../../assets/images/home/985211.png" alt="">
        </div>
        <div class="TopLftxtBox">
          <p class="title">{{ listgroup.orgName }}</p>
          <p class="assistanttitle">主要学科分布</p>
          <div class='topTItleshow'>
            <span class="TopLftxtItem"
              :class='{ "active": listgroup.isFirstDiscName !== null && listgroup.isFirstDiscName.indexOf(item02) !== -1 }'
              v-for="(item02, index) in listgroup.prDiscOfLiterList" :key="index">{{ item02 }}</span>
          </div>
          <span class="hottip">
            <div class="icon"></div>
            <span class="tiptext">温馨提示：学科分布红色标签为机构的双一流学科</span>
          </span>
        </div>
      </div>
      <div class="detailBoxToprt">
        <div class="mainliteratureBox">
          <div class="mainliteratureNum">{{ listgroup.literatureSize }}</div>
          <div class="mainliteratureTxt">入选主文献数</div>
        </div>
        <div class="mainliteratureBox">
          <div class="mainliteratureNum">{{ listgroup.authorSize }}</div>
          <div class="mainliteratureTxt">代表作者数</div>
        </div>
        <div class="mainliteratureBox">
          <div class="mainliteratureNum">{{ listgroup.clickCount || 0 }}</div>
          <div class="mainliteratureTxt">主文献点击数</div>
        </div>
        <div class="mainliteratureBox">
          <div class="mainliteratureNum">{{ listgroup.downCount || 0 }}</div>
          <div class="mainliteratureTxt">主文献下载数</div>
        </div>
      </div>
    </div>
    <div class="detailBoxBottom">
      <div class="detailBoxBottomLeft">
        <detaillf :listname="listname" :chineseName='chineseName' :rnList='rnList'></detaillf>
      </div>
      <div class="detailBoxBottomright">
        <detailrt :listname="listname" :acList='acList'></detailrt>
      </div>
    </div>
  </div>
</template>

<script>
import detaillf from '../../components/institutio/detaillf'
import detailrt from '../../components/institutio/detailrt'
export default {
  components: { detaillf, detailrt },
  data () {
    return {
      institutionselecttree: [], // 选中学科树
      hotwordsearch: '', // 大搜索热词
      listgroup: [],
      listname: '', // 机构名称
      chineseName: '',
      acList: [],
      rnList: []
    }
  },
  async mounted () {
    // 机构名称
    this.listname = this.$route.query.name
    // 当前页
    this.page = this.$route.query.page
    // 每页多少条
    this.pageRow = this.$route.query.pageRow
    this.getlistData().then(() => {
      document.title = this.chineseName + this.$route.meta.title
    })
  },
  methods: {
    randomRgb (item) {
      const colorList = [
        '#f1af10',
        '#f1a871',
        '#23a1b8',
        '#82bcca',
        '#afb4db',
        '#78a355',
        '#df9464',
        '#7bbfea'
      ]
      const index = Math.floor(Math.random() * colorList.length)
      return {
        // backgroundImage: colorList[index]
        backgroundColor: colorList[index]
      }
    },
    async getlistData () {
      const { data: res } = await this.$http({
        url: `/organization/find-one/${this.listname}`,
        method: 'GET'
        // data: {
        //   // current: this.page,
        //   // opt: this.hotwordsearch, // 检索条件
        //   orgCode: this.listname // 机构名称
        //   // prDiscNamesList: this.institutionselecttree // 学科领域
        //   // size: this.pageRow
        // }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.listgroup = res.data

      this.chineseName = this.listgroup.orgName
      this.acList = this.listgroup.acList
      this.rnList = this.listgroup.rnList
    }
  },
  watch: {

    // 监听选择学科领域树
    '$store.state.selectedauthor': {
      deep: true,
      handler (data) {
        this.institutionselecttree = data
        this.getlistData()
      }
    },
    // 监听大搜索框
    '$store.state.hotwordsearch': {
      deep: true,
      // immediate: true,
      handler (data) {
        this.hotwordsearch = data
      }
    }
  }
}
</script>
<style lang="less" scoped>
.detailBox {
  width: 1440px;
  margin: 0 auto;

  .detailBoxTop {
    margin: 20px 0;
    width: 1440px;
    // 不设置高度让他自己往大撑
    // height: 176px;
    padding: 20px 20px 20px 40px;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .detailBoxTopLf {
      width: 900px;
      display: flex;
      align-items: center;
      position: relative;

      .topbox {
        width: 118px;
        height: 100%;
        position: relative;

        .topSchool {
          width: 88px;
          height: 21px;
          position: absolute;
          top: -10px;
          right: -29px;
        }

        .imgbox {
          width: 118px;
          height: 118px;
          object-fit: contain;
          border-radius: 50%;
        }

        .imgbox2 {
          display: inline-block;
          color: #fff;
          font-size: 30px;
          width: 118px;
          height: 118px;
          object-fit: contain;
          line-height: 118px;
          border-radius: 50%;
          text-align: center;
          border-radius: 50%;
          object-fit: cover;
          vertical-align: middle;
          font-weight: 500;
        }
      }

      .TopLftxtBox {
        margin-left: 39px;

        .title {
          font-size: 24px;
          font-weight: 500;
          color: #000000;
        }

        .assistanttitle {
          line-height: 28px;
          color: #3a3838;
        }

        .topTItleshow {
          width: 746.4px;

          .TopLftxtItem {
            background: #ffffff;
            border: 1px solid #e6e6e6;
            border-radius: 12px;
            padding: 3px 22px;
            margin-right: 10px;
            color: #333;
            display: inline-block;
            margin-top: 5px;
          }

          .TopLftxtItem.active {
            background: #ffffff;
            border: 1px solid #FF0000;
            border-radius: 12px;
            padding: 3px 22px;
            margin-right: 10px;
            color: #333;
            display: inline-block;
            margin-top: 5px;
            color: #FF0000;
          }
        }

        .hottip {
          display: flex;
          margin-top: 10px;

          .tiptext {
            font-size: 12px;
            font-weight: 400;
            color: #FE0000;
          }

          .icon {
            width: 16px;
            height: 16px;
            background: url('../../assets/images/home/tip.png');
            background-size: cover;
            background-position: center center;
            margin-right: 9px;
            margin-top: 1px;
          }
        }

      }
    }

    .detailBoxToprt {
      display: flex;
      flex: 1;

      .mainliteratureBox {
        // width: 45px;
        text-align: center;
        margin-right: 10px;
        background: #F7F7F7;
        border-radius: 10px;
        padding: 12px 15px;
        height: 68px;
        // box-sizing: border-box;

        .mainliteratureNum {
          font-size: 24px;
          font-weight: bold;
          // width: auto;
          color: #9c2526;
        }

        .mainliteratureTxt {
          font-size: 14px;
          color: #828282;
          white-space: nowrap;
        }
      }
    }
  }

  .detailBoxBottom {
    display: flex;
    margin-top: 20px;

    .detailBoxBottomLeft {
      flex: 1;
    }

    .detailBoxBottomright {
      width: 340px;
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
