<template>
  <div class="authordetailBox">
    <div class="authordetailLf">
      <authordetailLf @sendnamecode="handleChildEvent" @acListShow='acListShow' @rnListShow='rnListShow'>
      </authordetailLf>
    </div>
    <div class="authordetailrt">
      <authordetailrt :rnList='rnList' :acList='acList' :parentnamecode="parentnamecode"></authordetailrt>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import authordetailLf from '../../components/author/authordetaillf'
import authordetailrt from '../../components/author/authordetailrt'
export default {
  components: { authordetailLf, authordetailrt },
  data () {
    return {
      rnList: [],
      acList: [],
      parentnamecode: {}
    }
  },
  mounted () { },
  methods: {
    rnListShow (data) {
      this.rnList = data
      // console.log('rnList', this.rnList)
    },
    acListShow (data) {
      this.acList = data
    },
    // 监听子组件的值
    handleChildEvent (data) {
      // 将值传到中间的子元素
      this.parentnamecode = data
    }
  }
}
</script>
<style lang="less" scoped>
.authordetailBox {
  width: 1440px;
  margin: 20px auto 0;
  display: flex;

  .authordetailLf {
    width: 320px;
  }

  .authordetailrt {
    flex: 1;
  }
}
</style>
