<template>
  <!-- 学科详情 -->
  <div class="disciplinedetailBox">
    <div class="disciplinedetailTop">
      <div class="toplf">
        <div class="itemdiscipline" v-if="literatureNum">主文献量：{{ literatureNum }}</div>
        <img :src="cover" v-if="cover" class="imgcover" alt />
        <img src="../../assets/images/home/xuekedetail.png" v-else alt class="imgcover" />
      </div>
      <div class="toprt">
        <p class="toprtTitle">{{ name }}</p>
        <div class="theme">主题词：{{ themeWord }}</div>
        <div class="toprtword">
          <span class="toprtwordfl" v-for="(item, index) in themeWordarray" :key="index">{{ item }}</span>
        </div>
      </div>
    </div>
    <!-- 学科专题的评论 -->

    <div class="disciplinedetailBottom">
      <div class="bottomLF">
        <p class="detailtitle">相关主文献</p>
        <mainList :Listobj="Listobj"></mainList>
        <div style="height: 30px;"></div>
        <commentdiscipine></commentdiscipine>
      </div>
      <div class="bottomrt">
        <div class="authorsidelist">
          <!-- <p class="authorTitle">主要作者</p> -->
          <authorsidelist :authorsidedata="authorsidedata"></authorsidelist>
        </div>
        <institutionrtside :jgsidename="jgsidename"></institutionrtside>
        <journalsidelist :journalsidelistData="journalsidelistData"></journalsidelist>
        <!-- <div class="selectedjournal">
          <journalsidelist :journalsidelistData="journalsidelistData"></journalsidelist>
        </div> -->
        <!-- <div class="selectedjournal">
          <div class="journalTitle">
            <p>相关期刊</p>
            <div>
              <span>入选期刊:</span>
              <span class="journalTotal">{{ journalTotal }}册</span>
            </div>
          </div>
          <div class="box">
            <div style="width: 100%;height: 100%;overflow: scroll;">
              <div class="selectedjournalBox">
                <div class="journalItem" v-for="(item, index) in journalList" :key="index"
                  @click="handlegodetail(item.id, item.name)">
                  <div class="journalItemimg">
                    <img :src="item.path" v-if="item.path" alt />
                    <img src="../../assets/images/home/jqdetailimg.png" v-else alt />
                  </div>
                  <div class="itemTxt overflow-dot">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import mainList from '../../components/main/mainlist'
import authorsidelist from '../../components/author/authorsidelsit'
// 代表机构
import institutionrtside from '../../components/institutio/institutionrightList'
import commentdiscipine from '../../components/commentdiscipine/list'
import journalsidelist from '../../components/journal/journalsidelist'
export default {
  components: {
    mainList,
    authorsidelist,
    institutionrtside,
    commentdiscipine,
    journalsidelist
  },
  data () {
    return {
      Listobj: {
        type: 4, /// 0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅
        url: '/topicList/search/literature',
        subjectList: [],
        hotWords: []
      },
      authorsidedata: {
        url: '/topicList/getAuthorList',
        orgName: '',
        name: '主要作者'
      },
      // 期刊
      journalsidelistData: {
        url: '/topicList/getPeriodicalList',
        orgCode: '',
        name: '相关期刊'
      },
      // 机构
      jgsidename: {
        url: '/topicList/getOrgList',
        orgName: '',
        name: '相关机构'
      },
      id: '',
      name: '',
      literatureNum: 0,
      themeWord: '', // 主题词
      themeWordarray: [],
      cover: '',
      firstLevelDisciplineName: '',
      journalList: ''
    }
  },
  beforeCreate () {

  },
  async mounted () {
    this.id = this.$route.query.id
    await this.getdisciplinedetail()
    document.title = this.name + this.$route.meta.title
  },
  methods: {
    async getdisciplinedetail () {
      const { data: res } = await this.$http({
        url: '/topicList/getSubjectDetail',
        method: 'post',
        data: {
          id: this.id
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      // console.log("res32", res);
      this.name = res.data.name
      this.literatureNum = res.data.count
      this.themeWord = res.data.theme_word.join('；')
      this.cover = res.data.cover
      this.themeWordarray = res.data.disciplineList
      // console.log("them", this.themeWord.split("；"));
      this.Listobj.secondLevelList = []
      // this.Listobj.subjectList = this.themeWord.split('；')
      this.Listobj.subjectList = this.themeWord
      this.Listobj.hotWords = res.data.theme_word
      this.firstLevelDisciplineName = res.data.firstLevelDisciplineName
    }
    // async getPeriodicalList() {
    //   const { data: res } = await this.$http({
    //     url: "/topicList/getPeriodicalList",
    //     method: "post",
    //     data: {
    //       orgName: this.themeWord.split("；").join(",")
    //     }
    //   });
    //   if (res.code != 200) return this.$message.error(res.msg);
    //   this.journalList = res.data;
    // }
  },
  watch: {
    themeWord: {
      deep: true,
      handler (themeWord) {
        this.authorsidedata.orgName = themeWord.split('；').join(',')
        this.jgsidename.orgName = themeWord.split('；').join(',')
        this.journalsidelistData.orgCode = themeWord.split('；').join(',')
        console.log('222', this.journalsidelistData.orgCode)
        // this.getPeriodicalList();
      }
    }
  }
}
</script>

<style lang="less" scoped>
.disciplinedetailBox {
  .detailtitle {
    margin: 20px 0 15px 0;
    font-size: 18px;
    font-weight: 500;
    color: #9c2526;
  }

  width: 1440px;
  margin: 0 auto;

  .disciplinedetailTop {
    height: 200px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;

    .toplf {
      margin: 0 20px;
      position: relative;

      .itemdiscipline {
        // height: 24px;
        background: #ff7100;
        border-radius: 0px 4px 0px 4px;
        color: #fff;
        display: inline-block;
        padding: 4px 10px;
        position: absolute;
        top: 8px;
        right: 10px;
        font-size: 12px;
      }

      .imgcover {
        width: 350px;
        height: 150px;
        object-fit: cover;
        overflow: hidden;
        border-radius: 10px;
      }
    }

    .toprt {
      .toprtTitle {
        font-size: 22px;
        font-weight: 500;
        color: #000000;
      }

      .theme {
        background: #ffffff;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        margin: 16px 15px 0 0;
        line-height: 24px;
      }

      .toprtNum {
        border: 1px solid #9c2526;
        border-radius: 4px;
        font-weight: 400;
        color: #9c2526;
        padding: 3px 6px;
        display: inline-block;
        margin-top: 15px;
      }

      .toprtword {
        margin-top: 14px;

        .toprtwordfl {
          margin-left: 10px;
          padding: 4px 7px 4px 9px;
          background: #f7f7f7;
          border-radius: 4px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #9c2526;
          display: inline-block;
          margin-bottom: 10px;
        }

        .toprtwordfl:first-child {
          margin-left: 0px;
        }
      }
    }
  }

  .disciplinedetailBottom {
    display: flex;

    .bottomLF {
      flex: 1;
      padding: 0 20px;
    }

    .bottomrt {
      width: 340px;
      padding-bottom: 20px;

      .authorsidelist {
        margin: 20px 0;
        background: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
        border-radius: 10px;
        padding-top: 20px;
        padding-bottom: 10px;

        .authorTitle {
          margin-left: 19px;
          font-size: 18px;
          font-weight: 500;
          color: #9c2526;
        }
      }

      .selectedjournal {
        background: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
        border-radius: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 20px;

        .journalTitle {
          margin: 0 20px;
          display: flex;
          justify-content: space-between;

          >p {
            font-size: 18px;
            font-weight: 500;
            color: #9c2526;
          }

          .journalTotal {
            font-weight: 600;
            color: @my-color;
          }
        }

        .box {

          // overflow: hidden;
          // overflow-x: auto;
          // width: 1000px;
          // white-space: nowrap;
          .selectedjournalBox {
            width: 100%;
            height: 960px;
            padding: 10px 0;

            .journalItem {
              padding: 0 20px;
              margin-top: 57px;
              height: 380px;
              width: 260px;
              // margin: 20px;
              box-sizing: border-box;
              border: 1px solid #e0e0e0;
              border-radius: 4px;
              display: block;
              margin: 15px auto;
              // overflow: hidden;

              .journalItemimg {
                height: 322px;
                width: 220px;
                margin: 20px auto 0;
                box-sizing: border-box;
                // overflow: hidden;

                >img {
                  width: 100%;
                  height: auto;
                  object-fit: contain;
                  // object-fit: cover;
                }
              }

              .itemTxt {
                height: 38px;
                line-height: 38px;
                font-size: 16px;
                font-weight: 500;
                color: #000000;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>
