<template>
  <div class="collect">
    <div class="_title">原创学术理论征集</div>
    <div class="_content">
      <p class="noIndent">尊敬的学者：</p>
      <p>您好！</p>
      <p>本轮原创学术理论征集已过截止时间，感谢您的参与，后续进展请关注“中国人民大学评价研究中心”微信公众号。</p>
      <!-- <p>为梳理中国学者自主提出的原创学术理论，尝试开展原创导向的中国哲学社会科学评价实践，进一步推动中国自主的知识体系建构进程，现公开征集中国哲学社会科学“原创学术理论”示例。所征集的“原创学术理论”示例，后续将组织评议活动并开展如下活动：</p>
      <p>1. 向学术共同体公布遴选出的中国自主的“原创学术理论”示例。</p>
      <p>2. 研制并发布《中国哲学社会科学“原创学术理论”分析报告》。</p>
      <p>3. 向相关部门提交中国自主的“原创学术理论”研究成果与示例材料。</p>
      <p>请学界同仁不吝提供所了解的“原创学术理论”信息，以便提升“原创学术理论”遴选与研究工作的全面性和科学性。</p>
      <p>具体情况如下：</p>
      <h5>一、征集内容</h5>
      <p>“原创学术理论”是指由中国哲学社会科学工作者基于规范的学术研究过程和方法，首次提出、发现或归纳得出的，具有较高水平自主创新特征且彰显中国特色的，与国内外同类学术研究成果相比作出独特学术贡献的理论。推荐的“原创学术理论”应具有较高的独创性与学术价值，能够为学术研究提供新的分析框架或范式，有助于开辟新的研究领域或方向，促进中国自主知识体系建构与完善。</p>
      <h5>二、征集方式</h5> -->
      <!-- <p>登录“哲社主文献”（<a target="__blank" href="https://zszwx.cn" style="color: #9c2526">https://zszwx.cn</a>），点击<a href="#content" style="color: #9c2526">链接</a>填写相关内容并提交。</p> -->
      <!-- <p>点击<a href="#content" style="color: #9c2526">链接</a>，到“哲社主文献”填写相关内容并提交，填写大约需要3分钟。</p>

      <h5>三、征集时间</h5>
      <p>即日起至2024年8月31日。</p>
      <h5>四、相关内容介绍</h5>
      <p>中国人民大学书报资料中心一直致力于探索原创导向的中国特色哲学社会科学学术评价体系建设，积极尝试有助于“中国自主知识体系”建构的创新活动。2023年书报资料中心开始探索中国自主的“原创学术概念、理论、方法”的遴选，2023年12月17日发布《标准·方法·示例：中国哲学社会科学原创学术概念分析报告》，引发了广泛反响，获得了中宣部、教育部等有关部门和主流媒体的高度关注。</p>
      <br />
      <br /> -->
      <!-- <img v-if="!mobile" :src="require('@/assets/images/head/erweima.jpg')" alt="" style="width:100px;height: 100px;"> -->
      <!-- <p class="_right">中国特色哲学社会科学自主知识体系数字创新平台</p>
      <p class="_right">中国人民大学书报资料中心</p>
      <p class="_right">2024年7月</p> -->
    </div>
    <!-- <div class="_title2" id="content">原创学术理论征集推荐填写</div>
    <el-form :model="form" :rules="rules" ref="form1" label-width="100px" :label-position="mobile ? 'top' : 'left'">
      <el-form-item label="您所推荐的“原创学术理论”名称(必填)：" prop="name" label-width="330px">
        <el-input v-model="form.name" maxlength="100" placeholder="请输入原创理论名称"></el-input>
      </el-form-item>
      <h5>该“原创学术理论”提出者基本信息：</h5>
      <el-form-item label="姓名(必填)：" prop="basicName" label-width="270px" :style="{ width: mobile ? '100%' : '50%' }">
        <el-input v-model="form.basicName" maxlength="50" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="单位(必填)：" prop="basicCompany" label-width="120px" :style="{ width: mobile ? '100%' : '50%' }">
        <el-input v-model="form.basicCompany" maxlength="50" placeholder="请输入单位名称"></el-input>
      </el-form-item>
      <el-form-item label="学术理论提出时间：" prop="basicTime" label-width="270px" :style="{ width: mobile ? '100%' : '50%' }">
        <el-date-picker value-format="yyyy-MM-dd" v-model="form.basicTime" type="date" placeholder="选择日期" style="width: 100%"> </el-date-picker>
      </el-form-item>
      <el-form-item label="联系方式：" prop="basicContact" label-width="120px" :style="{ width: mobile ? '100%' : '50%' }">
        <el-input v-model="form.basicContact" maxlength="50" placeholder="请输入联系方式"></el-input>
      </el-form-item>
      <el-form-item label="该“原创学术理论“所属学科领域：" prop="basicField" label-width="270px" :style="{ width: mobile ? '100%' : '50%' }">
        <el-input v-model="form.basicField" maxlength="50" placeholder="请输入学科领域"></el-input>
      </el-form-item>
    </el-form>
    <el-form class="form2" :model="form" :rules="rules" ref="form2" label-width="100px" label-position="top">
      <el-form-item label="该“原创学术理论”的核心含义、主要内容：" prop="mainContent">
        <el-input type="textarea" resize="none" rows="4" maxlength="500" show-word-limit v-model="form.mainContent" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="该“原创学术理论”的理论价值和实践意义：" prop="theoreticalPractical">
        <el-input type="textarea" resize="none" rows="4" maxlength="500" show-word-limit v-model="form.theoreticalPractical" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="该“原创学术理论”的论文、论著发表清单：" prop="publicList">
        <el-input type="textarea" resize="none" rows="4" maxlength="500" show-word-limit v-model="form.publicList" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="该“原创学术理论”的国际同类研究的比较与优势：" prop="advantage">
        <el-input type="textarea" resize="none" rows="4" maxlength="500" show-word-limit v-model="form.advantage" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <el-form :model="form" :rules="rules" ref="form3" label-width="100px" :label-position="mobile ? 'top' : 'left'">
      <el-form-item label="推荐者姓名：" prop="nominateName" label-width="150px" :style="{ width: mobile ? '100%' : '50%' }">
        <el-input v-model="form.nominateName" maxlength="50" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="推荐者联系方式：" prop="nominateContact" label-width="150px" :style="{ width: mobile ? '100%' : '50%' }">
        <el-input v-model="form.nominateContact" maxlength="50" placeholder="请输入联系方式"></el-input>
      </el-form-item>
      <el-form-item label="推荐者所在单位：" prop="nominateCompany" label-width="150px" :style="{ width: mobile ? '100%' : '50%' }">
        <el-input v-model="form.nominateCompany" maxlength="50" placeholder="请输入单位名称"></el-input>
      </el-form-item>
      <el-form-item label="推荐者职务：" prop="nominatePost" label-width="150px" :style="{ width: mobile ? '100%' : '50%' }">
        <el-input v-model="form.nominatePost" maxlength="50" placeholder="请输入推荐者职务"></el-input>
      </el-form-item>
    </el-form> -->
    <!-- <div class="btns">
      <el-button type="primary" @click="handleSubmit()">确认提交</el-button>
    </div> -->
    <login ref="login" class="homeLoginBigBox" @registeropen="registeropen"></login>
    <register ref="register" @loginopen="loginopen" @emitSuccessRegister="$refs.login.modelopen = true"></register>
  </div>
</template>
  
<script>
import { IsLoginV2, threeHandleLogin } from '../../utils/threeLogin';
import platform from 'platform';
import login from '../login.vue';
import register from '../register';
export default {
  data() {
    return {
      mobile: false,
      form: {
        name: '',
        basicName: '',
        basicCompany: '',
        basicTime: '',
        basicContact: '',
        basicField: '',
        mainContent: '',
        theoreticalPractical: '',
        publicList: '',
        advantage: '',
        nominateName: '',
        nominateContact: '',
        nominateCompany: '',
        nominatePost: '',
      },
      rules: {
        name: [{ required: true, message: '请输入原创理论名称', trigger: 'blur' }],
        basicName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        basicCompany: [{ required: true, message: '请输入单位名称', trigger: 'blur' }],
      },
      isKeyboardVisible: false,
    };
  },
  async created() {
    if (window.innerWidth < 1000) {
      this.initMobileSize();
    }
    // 页面加载判断

    // const isLogin = await IsLoginV2(this.THREE_LOGIN_OR);
    // // console.log('自动登传入的值', isLogin)
    // if (isLogin) {
    //   console.log('自动登录成功', isLogin);
    //   threeHandleLogin(this.THREE_LOGIN_OR);
    // } else {
    //   console.log('自动登录失败');
    // }
    // this.mainbrowse = this.$route.query;
    // if (this.$route.query.code && this.$route.query.code !== localStorage.getItem('thirdcode')) {
    //   // 访问认证登录接口
    //   await this.thirdLogin();
    // }
  },
  mounted() {
    if (!localStorage.getItem('token')) {
      // 未登录
      // this.loginhandle();
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1000) {
        this.initMobileSize();
      }
    });
    // document.querySelectorAll('input').forEach((input) => {
    //   input.addEventListener('focus', () => {
    //     this.isKeyboardVisible = true;
    //   });
    //   input.addEventListener('blur', () => {
    //     this.isKeyboardVisible = false;
    //   });
    // });
  },
  components: {
    login,
    register,
  },
  methods: {
    // 表单提交
    async getSubmit() {
      const { data: res } = await this.$http({
        url: `/originalAcademic/add`,
        method: 'post',
        data: {
          name: this.form.name,
          basicName: this.form.basicName,
          basicCompany: this.form.basicCompany,
          basicTime: this.form.basicTime,
          basicContact: this.form.basicContact,
          basicField: this.form.basicField,
          mainContent: this.form.mainContent,
          theoreticalPractical: this.form.theoreticalPractical,
          publicList: this.form.publicList,
          advantage: this.form.advantage,
          nominateName: this.form.nominateName,
          nominateContact: this.form.nominateContact,
          nominateCompany: this.form.nominateCompany,
          nominatePost: this.form.nominatePost,
        },
      });
      if (res.code != 200) return this.$message.error(res.msg);
      this.$message.success('提交成功！');
      this.$refs['form1'].resetFields();
      this.$refs['form2'].resetFields();
      this.$refs['form3'].resetFields();
    },
    // 访问认证登录接口:
    async thirdLogin() {
      const { data: res } = await this.$http({
        url: '/authentication/login',
        method: 'post',
        data: {
          code: this.mainbrowse.code,
          state: this.mainbrowse.state,
          operatingSystem: platform.family,
          browserType: platform.name,
        },
      });
      if (res.code !== 200) return this.$message.error(res.msg);
      // 登录成功之后token
      localStorage.setItem('thirdcode', this.mainbrowse.code);
      localStorage.setItem('token', res.data.jwt);
      this.$store.commit('setUserToken', res.data.jwt);
      this.$store.commit('userName', res.data.userName);
      // history.pushState({}, '11112', '/')
      // this.$router.push(this.$store.state.threeUrl)
      // location.reload()
      history.pushState({}, '11112', this.$store.state.threeUrl);
      // location.href = this.$store.state.threeUrl
      // 人中中心修改密码
      this.$store.commit('setthreeReset', true);
    },
    registeropen(value) {
      this.$refs.register.registershow = value;
    },
    loginhandle() {
      threeHandleLogin(this.THREE_LOGIN_OR);
    },
    loginopen(value) {
      this.$refs.login.modelopen = value;
    },
    initMobileSize() {
      this.$meta('viewport', 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0');
      this.mobile = true;
      const timer = setInterval(() => {
        document.querySelector('html').style.fontSize = '180px';
      }, 20);
      setTimeout(() => {
        clearInterval(timer);
      }, 1000);
    },
    // 点击提交
    // handleSubmit() {
    //   let flag = false;
    //   this.$refs['form1'].validate((valid) => {
    //     if (valid) {
    //       flag = true;
    //     } else {
    //       this.$message.error('表单必填项未填写，请检查！');
    //       return false;
    //     }
    //   });

    //   if (flag) {
    //     this.$confirm('您确定要提交此表单数据吗？', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning',
    //     })
    //       .then(() => {
    //         this.getSubmit();
    //       })
    //       .catch(() => {});
    //   }
    // },
  },
};
</script>
  
<style lang="less" scoped>
.collect {
  width: 100vw;
  height: auto;
  background: #fffaf2;
  padding: 40px;
  box-sizing: border-box;

  ._title {
    font-size: 36px;
    color: #9c2526;
    text-align: center;
    font-weight: 700;
  }
  ._content {
    background: #fff;
    padding: 22px 30px;
    border-radius: 8px;
    border: 1px solid #f7c2c3;
    margin: 40px 0;
    p,
    a {
      font-size: 16px;
      color: #333;
      line-height: 27px;
      text-indent: 2em;
      letter-spacing: 1px;
    }
    .noIndent {
      text-indent: 0;
    }
    h5 {
      color: #000;
      font-size: 16px;
      line-height: 42px;
      letter-spacing: 1px;
    }
    ._right {
      text-align: right;
    }
  }
  ._title2 {
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: 700;
    margin-bottom: 30px;
  }
  /deep/.el-form {
    label {
      color: #000;
      font-size: 16px;
    }
    h5 {
      font-size: 16px;
      color: #000;
      margin-bottom: 30px;
      font-weight: 400;
    }
    .el-form-item {
      padding-right: 30px;
      //   padding-left: 10px;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
    }
  }

  /deep/.form2 {
    .el-form-item {
      padding-left: 0;
    }
  }

  .btns {
    margin: 40px 0;
    text-align: center;
    .el-button {
      width: 140px;
    }
  }
}
@media (max-width: 750px) {
  .collect {
    padding: 20px;
    ._content {
      margin: 20px 0;
    }
    .el-form {
      .el-form-item {
        padding-right: 0;
      }
    }
    /deep/.el-button {
      span {
        font-size: 14px;
      }
    }
  }
}
</style>
  