<template>
  <div class="main">
    <div class="header">
      <div class="headerlf">
        {{ hotWords }}
        <span v-if="firstLevelNameList.length != 0" class="typeposition">
          <span v-for="i in firstLevelNameList" class="headertype" :key="i">
            {{ i }}
          </span>
        </span>
      </div>
      <div class="headerrt">
        <div class="statistics_count_class">
          <div class="statistics_count_title">{{ literatureCount }}</div>
          <div class="statistics_count_txt">发文量</div>
        </div>
        <div class="statistics_count_class">
          <div class="statistics_count_title">{{ authorCount }}</div>
          <div class="statistics_count_txt">代表作者</div>
        </div>
        <div class="statistics_count_class">
          <div class="statistics_count_title">{{ orgCount }}</div>
          <div class="statistics_count_txt">主要机构</div>
        </div>
      </div>
    </div>
    <div class="context">
      <div class="left">
        <hotstatistics></hotstatistics>

        <div class="mainListBox">
          <div class="mainListName">相关主文献</div>

          <mainList :Listobj="Listobj"></mainList>
        </div>
      </div>
      <div class="right">
        <div class="echartBox">
          <span class='word'>相关词</span>
          <div class="echarthot" ref="echarthot"></div>
        </div>
        <div class="authorlist">
          <!-- <p class="topTitle">代表作者</p> -->
          <authorsidelist :authorsidedata="authorsidedata"></authorsidelist>
          <!-- <div style="height:100%;width:100%;overflow:scroll">
            <div class="scroolbox">
              <div class="authorSidebottomGroup" v-for="(item, index) in authorlistSide" :key="index">
                <div class="authorSidebottomItem">
                  <div class="authorlf">
                    <span class="sidelistindex">{{ index + 1 }}</span>
                    <img :src="item.photoPrefix + item.photo" class="imgmiddle" v-if="item.photo" alt />
                    <span class="imgmiddle" :style="randomRgb()" v-else>{{ item.name.substring(0, 1) }}</span>
                  </div>
                  <div class="authorrt">
                    <div class="authorrtTitle">
                      <span class="authorName" :title='item.name' @click="handleauthorside(item.id, item.company)">{{
                        item.name }}</span>
                      <span class="authorTrpe">主文献数：{{ item.count }}</span>
                    </div>
                    <div class="authorrtdiscipline overflow-dot">
                      <span :title="item02" v-for="(item02, index) in item.discString.split(',')" :key="index">
                        {{ item02 }}
                        <span class="stationline"></span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="line" style="margin:.7813vw 0;"></div>
              </div>
              <empty :authorlistSide="authorlistSide"></empty>
            </div>
          </div> -->
        </div>
        <div class="jigoulist">
          <institutionrtside :jgsidename="jgsidename"></institutionrtside>
          <!-- <div class="sidelistBox">
            <p class="sidelistBoxTitle">主要机构</p>
            <div style="height:100%;width:100%;overflow:scroll">
              <div class="sidelistltgroup">
                <div v-for="(item, index) in institutionSide" :key="index">
                  <div class="sidelistltItem">
                    <div class="sidelistlt">
                      <span class="sidelistindex">{{ index + 1 }}</span>
                      <img class="imgmiddle" :src="item.photoPrefix + item.photo" v-if="item.photo" alt />
                      <img class="imgmiddle" src="../../assets/images/home/institution.png" v-else alt />
                    </div>
                    <div class="sidelistrt">
                      <p :title='item.companyName' class="sidelistrtTitle overflow-dot"
                        @click="handleinstitutiondetail(item.company)">{{ item.companyName }}
                      </p>
                      <p class="sidelistrtNum">主文献数：{{ item.count }}</p>
                    </div>
                  </div>
                  <div class="line"></div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import empty from '../../components/common/emptyside.vue'
import hotstatistics from '@/components/main/hotstatistics.vue'
import mainList from '../../components/main/mainlist'
import authorsidelist from '../../components/author/authorsidelsit'
import institutionrtside from '../../components/institutio/institutionrightList'
import elementResizeDetectorMaker from 'element-resize-detector'
const erd = elementResizeDetectorMaker()
export default {
  components: {
    hotstatistics,
    mainList,
    empty,
    authorsidelist,
    institutionrtside
  },
  beforeDestroy () {
    erd.uninstall(this.$refs.echarthot)
  },
  data () {
    return {
      Listobj: {
        type: 6, /// 0-文献列表 1-作者详情 2-机构详情 3-期刊详情 4-学科详情 5-学术热点详情 6-热词详情 7-我的收藏 8-我的订阅
        url: '',
        hotWords: this.$route.query.name,
        firstLevelList: [],
        secondLevelList: [] // 详情热词名称
      },
      authorList: [],
      hotWords: '', // 热词
      secondtenhot: '', //
      firstenhot: '',
      hotDate: [], // 热词演变时间选择器
      authorlistSide: [],
      institutionSide: [],
      literatureCount: '', // 发文
      authorCount: '', // 涉及作者
      orgCount: '', // 涉及机构
      firstLevelName: '',
      firstLevelNameList: [],
      authorsidedata: {
        url: '/keyWordDetail/keyWordMatchAuthor',
        keyWord: '',
        name: '代表作者'
      },
      jgsidename: {
        url: '/keyWordDetail/keyWordMatchOrg',
        keyWord: '',
        name: '主要机构'
      }
    }
  },
  mounted () {
    // 从跳转页拿来的参数（正常应该根据id获取详细数据）
    this.secondtenhot = this.$route.query.secondLevelDiscipline
    this.firstenhot = this.$route.query.firstLevelDiscipline
    this.hotWords = this.$route.query.name
    this.authorsidedata.keyWord = this.$route.query.name
    this.jgsidename.keyWord = this.hotWords
    // this.Listobj.hotWords = this.$route.query.name
    this.Listobj.url = '/literatureInfoList/getList'
    this.getarea()
    // this.getauthorData()
    document.title = this.hotWords + this.$route.meta.title
    // 代表作者
    // this.jigou()
    // this.getrepresentativeauthor()
  },
  methods: {
    randomRgb (item) {
      const colorList = [
        '#f1af10',
        '#f1a871',
        '#23a1b8',
        '#82bcca',
        '#afb4db',
        '#78a355',
        '#df9464',
        '#7bbfea'
      ]
      const index = Math.floor(Math.random() * colorList.length)
      return {
        // backgroundImage: colorList[index]
        backgroundColor: colorList[index]
      }
    },
    // 热词
    async getarea () {
      const res = await this.$http({
        url: '/keyWordDetail/keyWordInfo',
        method: 'POST',
        data: {
          keyWord: this.hotWords
        }
      })
      if (res.data.code !== 200) return this.$message.error(res.msg)
      this.orgCount = res.data.data.matchOrgNum
      this.authorCount = res.data.data.matchAuthorNum
      this.literatureCount = res.data.data.matchLiterNum
      // this.firstLevelName = res.data.firstLevelName
      this.firstLevelNameList = res.data.data.discs?.split(',') || []
      var themeStyle = this.$echarts.init(this.$refs.echarthot)
      var _that = this
      const re = await this.$http({
        url: '/keyWordDetail/relatedWords',
        method: 'POST',
        data: {
          keyWord: this.hotWords
        }
      })
      // console.log('12', re)
      var option = {
        title: {
          // text: '相关词',
          textStyle: {
            // 文字颜色
            color: '#9c2526',
            // 字体风格,'normal','italic','oblique'
            fontStyle: 'normal',
            // 字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
            fontWeight: '500',
            // 字体系列
            fontFamily: 'sans-serif',
            // 字体大小
            fontSize: 18
          }
        },
        series: [
          {
            type: 'wordCloud',
            // 要绘制的“云”的形状。任意极坐标方程都可以表示为a吗
            // 回调函数，或关键字存在。可用的礼物为圆形(默认)，
            // 心形(苹果或心形曲线，最著名的极坐标方程)，菱形(
            // alias of square)， triangle-forward, triangle， (alias of triangle- standing, pentagon, and star)
            shape: 'circle',
            transform: 'scale', // 通过缩放来实现类似椭圆的效果
            // 保持maskImage或1:1的形状的纵横比
            // echarts-wordcloud@2.1.0支持该选项
            keepAspect: false,
            // 一个轮廓图像，白色区域将被排除在绘制文本之外。
            // 当云的形状增长时，形状选项将继续应用。
            // maskImage: maskImage,
            // 跟随左/顶/宽/高/右/底是用来定位字云
            // 默认放置在中心，大小为75% x 80%。
            left: 'center',
            top: 'center',
            width: '100%',
            height: '100%',
            right: null,
            bottom: null,
            // 文本大小范围，数据中的值将被映射到。
            // 默认最小12px，最大60px大小。
            sizeRange: [14, 45],
            // 文本旋转范围和步进度。文本将被rotationStep 45在[- 90,90]范围内随机旋转
            rotationRange: [-90, 90],
            rotationStep: 45,
            // 网格的大小(以像素为单位)，用于标记画布的可用性
            // 网格大小越大，单词之间的间距越大。
            gridSize: 8,
            // 设置为true允许文字部分绘制在画布外。
            // 允许文字大于画布的大小
            drawOutOfBound: false,
            // 如果执行布局动画。
            // 注意当有很多字的时候禁用它会导致UI阻塞。
            layoutAnimation: true,
            // Global text style
            textStyle: {
              fontFamily: 'sans-serif',
              fontWeight: 'bold',
              // Color can be a callback function or a color string
              color: function () {
                // Random color
                var colors = [
                  '#D24132',
                  '#FF7100',
                  '#37768F',
                  '#179A80',
                  '#FEA01E',
                  '#9C2526'
                ] // 自定义字体颜色
                return colors[parseInt(Math.random() * 5)]
              }
            },
            emphasis: {
              focus: 'self'
            },
            // 内容，
            data: re.data.data
          }
        ]
      }
      themeStyle.on('click', function (params) {
        const routeData = _that.$router.resolve({
          path: '/keyWord?name=' + params.data.name
        })
        window.open(routeData.href, '_blank')
      })
      themeStyle.setOption(option)
      erd.listenTo(this.$refs.echarthot, function () {
        themeStyle.resize()
      })
    },
    // async getauthorData () {
    //   const { data: res } = await this.$http({
    //     url: '/authorList',
    //     method: 'post',
    //     data: {
    //       evolutionYear: [],
    //       firstLevelDiscipline: '',
    //       hotWord: '',
    //       page: 1,
    //       pageRow: 5,
    //       secondLevelDiscipline: '',
    //       years: []
    //     }
    //   })
    //   this.authorList = res.data.list
    //   this.total = res.data.count
    // },
    // 代表作者
    async getrepresentativeauthor () {
      const { data: res } = await this.$http({
        url: '/keyWordDetail/keyWordMatchAuthor',
        method: 'post',
        data: {
          keyWord: this.hotWords
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.authorlistSide = res.data
    },
    // 点击跳转详情
    handleauthorside (id, origin) {
      const routeData = this.$router.resolve({
        path: '/authorDetail?id=' + id + '&name=' + origin
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    // 代表机构
    async jigou () {
      const { data: res } = await this.$http({
        url: '/keyWordDetail/keyWordMatchOrg',
        method: 'post',
        data: {
          // firstLevelDiscipline: this.firstenhot,
          // secondLevelDiscipline: this.secondtenhot,
          keyWord: this.hotWords
          // orgName: this.hotWords
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.institutionSide = res.data
    },
    // 跳转详情
    handleinstitutiondetail (name) {
      const routeData = this.$router.resolve({
        path:
          '/institutiondetail?name=' +
          name +
          '&page=' +
          1 +
          '&pageRow=' +
          10
        // query: params
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
<style scoped lang="less">
/deep/.el-date-editor.el-input {
  width: 100px;
}

* {
  // border-box:box-sizing;
  box-sizing: border-box;
}

.main {
  width: 1440px;
  margin: 0 auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
    border-radius: 10px;
    padding: 20px 20px 20px 36px;
    margin: 20px 0;

    .headerlf {
      font-size: 38px;
      font-weight: 500;
      color: #9c2526;
      position: relative;

      .typeposition {
        .headertype {
          display: inline-block;
          margin-right: 5px;
          // vertical-align: text-top;
          padding: 3px 10px;
          background: #ff7100;
          border-radius: 4px;
          color: #ffffff;
          font-size: 12px;
          // position: absolute;
          // top: 15%;
          // right: 0;
          // transform: translate(100%);
        }
      }
    }

    .headerrt {
      display: flex;

      .statistics_count_class {
        margin-left: 10px;
        padding: 10px 20px;
        box-sizing: border-box;
        width: 140px;
        height: 68px;
        background: #f7f7f7;
        border-radius: 10px;

        .statistics_count_title {
          font-size: 24px;
          font-weight: bold;
          color: #9c2526;
        }

        .statistics_count_txt {
          font-size: 14px;
          font-weight: 400;
          color: #828282;
        }
      }
    }
  }

  .context {
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    .left {
      width: 1080px;
      flex: 1;
      margin-right: 20px;
      box-sizing: border-box;

      .statistics,
      .hot {
        background: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 15px;
        box-sizing: border-box;

      }

      .mainListBox {
        background: #ffffff;
        // box-shadow: 0px .1042vw .5208vw 0px rgba(1, 32, 121, 0.12);
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 15px;

        .mainListName {
          height: 50px;
          font-size: 18px;
          font-weight: 500;
          color: #9c2526;
          padding: 15px 0px;
        }
      }
    }

    .right {
      width: 340px;

      .echartBox {
        width: 340px;
        height: 330px;
        overflow: hidden;
        padding: 20px;
        box-sizing: border-box;

        /deep/canvas {
          width: 300px !important;
          height: 260px !important;
        }

        .word {
          color: #9c2526;
          // 字体风格,'normal','italic','oblique'
          // fontStyle: 'normal',
          // 字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
          font-weight: normal;
          // 字体系列
          font-family: sans-serif;
          font-size: 18px;
          margin-top: 8px;
          // margin-left: 8px;
          // 字体大小
          // fontSize: 18px;
        }

        .echarthot {
          width: 340px;
          height: 280px;
          padding: 10px;
          box-sizing: border-box;
          // overflow: auto;
        }

        background: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
        border-radius: 10px;
        margin-bottom: 20px;
      }

      // 右侧两个代表作者的样式start
      .authorlist {
        width: 340px;
        min-height: 570px;
        background: #ffffff;
        box-shadow: 0 2px 10px 0 rgba(1, 32, 121, 0.12);
        border-radius: 10px;
        padding-top: 20px;
        padding-bottom: 10px;
        box-sizing: border-box;

        .topTitle {
          margin-left: 19px;
          font-size: 18px;
          font-weight: 500;
          color: @my-color;
          padding-bottom: 20px;
        }

        .scroolbox {
          height: 600px;
        }

        .authorSidebottomGroup {
          .authorSidebottomItem {
            display: flex;

            .authorlf {
              .sidelistindex {
                padding-right: 20px;
              }

              .imgmiddle {
                cursor: pointer;
                display: inline-block;
                color: #fff;
                font-size: 20px;
                line-height: 60px;
                text-align: center;
                vertical-align: middle;
                font-weight: 500;
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 50%;
              }
            }

            .authorrt {
              margin-left: 20px;
              flex: 1;

              .authorrtTitle {
                display: flex;
                justify-content: space-between;

                .authorName {
                  cursor: pointer;
                  font-size: 18px;
                  font-weight: 500;
                  color: #000000;
                }

                .authorAddress {
                  margin-left: 10px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #000000;
                }

                .authorTrpe {
                  margin-left: 20px;
                  border: 1px solid #e6e6e6;
                  border-radius: 4px;
                  padding: 4px 8px;
                  font-size: 12px;
                  font-weight: 400;
                  color: @my-color;
                }
              }

              .authorrtdiscipline {
                margin: 12px 0;
                width: 190px;
              }

              .authorrtdiscipline span {
                .stationline {
                  display: inline-block;
                  width: 2px;
                  height: 16px;
                  background: #cccccc;
                  margin: 0 10px;
                  vertical-align: text-bottom;
                }
              }

              .authorrtdiscipline span:last-child {
                .stationline {
                  width: 0;
                }
              }
            }
          }

          .line {
            width: 100%;
            height: 1px;
            background: #e6e6e6;
            border-radius: 1px;
          }
        }
      }

      .jigoulist {
        margin-top: 20px;

        .sidelistBox {
          padding-top: 20px;
          padding-bottom: 10px;
          background: #ffffff;
          box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
          border-radius: 10px;

          .sidelistBoxTitle {
            padding-bottom: 10px;
            margin-left: 19px;
            font-size: 18px;
            font-weight: 500;
            color: #9c2526;
          }

          .sidelistltgroup {
            margin-left: 19px;
            margin-right: 20px;
            height: 600px;

            .line {
              width: 100%;
              margin: 15px 0;
              height: 1px;
              background: #e6e6e6;
              border-radius: 1px;
            }

            .sidelistltItem {
              overflow: hidden;
              white-space: nowrap;
              // padding: 15px 0;
              display: flex;
              align-items: center;

              .sidelistlt {
                .sidelistindex {
                  padding-right: 20px;
                }

                .imgmiddle {
                  cursor: pointer;
                  display: inline-block;
                  color: #fff;
                  font-size: 20px;
                  line-height: 60px;
                  text-align: center;
                  vertical-align: middle;
                  font-weight: 500;
                  width: 60px;
                  height: 60px;
                  object-fit: cover;
                  border-radius: 50%;
                }
              }

              .sidelistrt {
                width: 100%;
                padding-left: 20px;
                cursor: pointer;

                .sidelistrtTitle {
                  width: 80%;
                  font-size: 18px;
                  font-weight: 500;
                  color: #000000;
                }

                .sidelistrtNum {
                  margin-top: 10px;
                  border: 1px solid #e6e6e6;
                  border-radius: 4px;
                  font-size: 12px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #9c2526;
                  padding: 3px 6px;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }

  .detailmiddlert {
    width: 340px;
  }
}
</style>
