<template>
  <div class="authordetailrtBox">
    <div class="authordetailrtconter">
      <authordetailrtconter :parentnamecode="parentnamecode" :rnList='rnList'></authordetailrtconter>
    </div>
    <div class="authordetailrtSide">
      <authordetailrtSide :acList='acList'></authordetailrtSide>
    </div>
  </div>
</template>

<script>
import authordetailrtconter from '../author/authordetailrtconter'
import authordetailrtSide from '../author/authordetailrtSide'
export default {
  components: { authordetailrtconter, authordetailrtSide },
  props: ['parentnamecode', 'rnList', 'acList'],
  data () {
    return {}
  },
  mounted () {

  },
  methods: {

  },
  watch: {
    parentnamecode: {
      handler (data) {
      },
      deep: true // 深度监听，监听obj对象内部属性的变化
    },
    rnList: {
      handler (data) {
        // console.log('rnList', this.rnList)
      }
    },
    acList: {
      handler (data) {
        console.log('authordetailrt', data)
      }
      // 监听
    }
  }
}
</script>
<style lang="less" scoped>
.authordetailrtBox {
  display: flex;

  .authordetailrtconter {
    flex: 1;
    padding: 0 20px;
  }

  .authordetailrtSide {
    width: 340px;
  }
}
</style>
