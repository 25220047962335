<template>
  <div class="textInput">
    <el-input :rows="rows" maxlength='200' :placeholder="placeholder" v-model="localValue" :change="inputChange"
      type="textarea" />
    <i v-show="clearable && localValue" class="clearButton el-input__icon el-icon-circle-close el-input__clear"
      @click.prevent="localValue = ''" />
  </div>
</template>

<script>
export default {
  props: {
    values: {
      type: String,
      default: function () {
        return ''
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 1
    },
    inputChange: {
      type: Function,
      default: null
    }
  },
  computed: {
    localValue: {
      get () {
        return this.values
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }

}
</script>

<style lang="less" scoped>
.textInput {
  position: relative;

  .clearButton {
    position: absolute;
    border-radius: 5px;
    right: 3px;
    z-index: 2;
    border: none;
    top: -3px;
    height: 40px;
    cursor: pointer;
    color: #ccc;
    transform: translateX(2px);
  }

  .clearButton:hover {
    color: #878d99;
  }
}
</style>
