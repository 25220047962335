<template>
  <!-- 主文献详情文字 -->
  <div>
    <div class="detailthemenBox">
      <div class="detailTxtBox">
        <!-- <span v-if="isRecommend == '0'">推荐</span> -->
        <span class="recommend" v-if="isRecommend == '0'">推荐</span>
        <span class="detailTitle">{{ title }}</span>

        <!-- <span class="listgroupTitleType" v-if="isHighlyCited == '0'">高被引主文献</span> -->
        <el-popover
          placement="top-start"
          trigger="hover"
          v-if="isAwards === '0'"
        >
          <template>
            <div
              v-for="(awardsItem, awardsIndex) in awardsList"
              :key="awardsIndex"
            >
              {{ awardsItem }}
            </div>
          </template>
          <span
            class="listgroupTitleType"
            v-if="isAwards === '0'"
            slot="reference"
            >获奖</span
          >
        </el-popover>
      </div>
      <div class="secondTitle" v-if="secondTitle">{{ secondTitle }}</div>
      <div class="detailsubtitleBox">
        <div class="detailsubtitlert">
          <div class="detailsubtitleLf" v-if="isReprint != 1">
            <span class="listBotttomtxt">主文献编号：</span>
            <span class="listBotttoomActive">{{ referenceNumber }}</span>
            <!-- <span class="stationline"></span>
            <span class="listBotttomtxt">发表年份：{{ year }}</span>-->
          </div>
          <div class="detailsubtitleitem">
            <span class="detailsubtitletltle">分享</span>
            <span class="detailsubtitlenum">{{ shareNum }}</span>
          </div>
          <div class="detailsubtitleitem">
            <span class="detailsubtitletltle">收藏</span>
            <span class="detailsubtitlenum">{{ collectNum }}</span>
          </div>
          <div class="detailsubtitleitem">
            <span class="detailsubtitletltle">下载</span>
            <span class="detailsubtitlenum">{{ downloadNum }}</span>
          </div>
          <div class="detailsubtitleitem">
            <span class="detailsubtitletltle">点击</span>
            <span class="detailsubtitlenum">{{ clickNum }}</span>
          </div>
          <div class="detailsubtitleitem">
            <span class="detailsubtitletltle">点赞</span>
            <span class="detailsubtitlenum">{{ likeNum }}</span>
          </div>
        </div>
        <!-- </div> -->
        <div class="detailsubtitlert">
          <img
            v-if="isClaim === true"
            class="claimImg"
            :src="require('../../assets/images/main/comment/claim.png')"
            alt
          />
          <img
            v-if="isClaim === false"
            class="claimImg"
            :src="require('../../assets/images/main/comment/noClaim.png')"
            alt
          />
          <!-- v-if="isClaim === 0"  -->
          <span class="claimBtn" @click="goClaim">文献认领</span>
        </div>
      </div>
      <div class="line"></div>
      <div class="themenTxtBox">
        <el-row class="themenTxtItem">
          <el-col :span="2">
            <div class="grid-content bg-purple">
              <div class="itemTitle">作者：</div>
            </div>
          </el-col>
          <el-col :span="20">
            <div class="grid-content bg-purple-light">
              <template v-if="authorList">
                <span
                  class="itemContet authorName"
                  v-for="(item, index) in authorList"
                  :key="index"
                >
                  <span @click="handleGoDetail(item)" class="hyperlink">{{
                    item.name
                  }}</span>
                  <span>
                    <!-- <img v-if="item.isSign === '0' || item.isSign == null" class="claimImg" :src="require('../../assets/images/main/comment/noEmpower.png')
          " alt /> -->
                    <img
                      v-if="item.isClaim === true"
                      class="claimImg"
                      :src="
                        require('../../assets/images/main/comment/claim.png')
                      "
                      alt
                    />
                  </span>
                  <span
                    class="stationline"
                    :class="{ overtext: index == authorList.length - 1 }"
                  ></span>
                </span>
              </template>
            </div>
          </el-col>
        </el-row>
        <el-row class="themenTxtItem">
          <el-col :span="2">
            <div class="grid-content bg-purple">
              <div class="itemTitle">作者机构：</div>
            </div>
          </el-col>
          <el-col :span="20">
            <div class="grid-content bg-purple-light">
              <template v-if="companyLink">
                <span
                  class="itemContet"
                  v-for="(item, index) in companyLink"
                  :key="index"
                >
                  <template v-if="item.name">
                    <span
                      class="hyperlink"
                      v-if="item.name !== '待完善'"
                      @click="handleCompanyDetail(companyLink[index])"
                      >{{ item.name }}</span
                    >
                  </template>
                  <template v-if="item.name">
                    <span
                      class="stationline"
                      v-if="item.name !== '待完善'"
                    ></span>
                  </template>
                </span>
              </template>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="mainContent" class="themenTxtItem">
          <el-col :span="2">
            <div class="grid-content bg-purple">
              <div class="itemTitle">内容摘要：</div>
            </div>
          </el-col>
          <el-col :span="22">
            <div class="grid-content bg-purple-light">
              <div class="itemContet itemContet02">{{ mainContent }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="keywords.length > 0" class="themenTxtItem">
          <el-col :span="2">
            <div class="grid-content bg-purple">
              <div class="itemTitle">关键词：</div>
            </div>
          </el-col>
          <el-col :span="22">
            <div
              class="grid-content bg-purple-light"
              v-if="keywords.length > 0"
            >
              <span
                class="itemContet highlight overflow-dot"
                @click="handleGoHotword(item)"
                :title="item"
                v-for="(item, index) in keywords"
                :key="index"
              >
                <span class="keywords">{{ item }}</span>
                <span class="fenhao">；</span>
              </span>
            </div>
          </el-col>
        </el-row>
        <el-row class="themenTxtItem themenTxtItemflex">
          <el-col :span="2">
            <div class="grid-content bg-purple">
              <span class="itemTitle">所属学科：</span>
            </div>
          </el-col>
          <el-col :span="20">
            <div class="grid-content bg-purple-light">
              <div class="themenTxtItemgl">
                <div
                  v-for="(item, index) in disc"
                  :key="index"
                  class="itemmargin"
                >
                  <span
                    class="itemContet"
                    v-for="(el, i) in item.disciplineList"
                    :key="i"
                  >
                    {{ item.name + "->" + el.name }}
                    <!-- {{
                      item.firstLevelDiscipline
                    }}
                  </span>
                  <span class="themenTxtItemgljt">></span>
                  <span class="itemContet">
                    {{
                      item.secondLevelDiscipline
                    }} -->
                    <!-- {{ formatterFirstAndSecond(disc) }} -->
                  </span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row class="themenTxtItem" v-if="fundProjects.length != 0">
          <el-col :span="2">
            <div class="grid-content bg-purple">
              <span class="itemTitle">基金资助：</span>
            </div>
          </el-col>
          <el-col :span="20">
            <div class="grid-content bg-purple-light">
              <span
                class="itemContet"
                v-for="(item, index) in fundProjects"
                :key="index"
                >{{ item }}</span
              >
            </div>
          </el-col>
        </el-row>
        <el-row class="themenTxtItem" v-if="originJournal">
          <el-col :span="2">
            <div class="grid-content bg-purple">
              <span class="itemTitle">原发期刊：</span>
            </div>
          </el-col>
          <el-col :span="20">
            <div class="grid-content bg-purple-light">
              <span
                @click="goJournalDetail"
                v-if="isReprint != 1"
                class="itemContet originJournal hyperlink"
              >
                {{ originJournal }}
              </span>
              <span v-else class="itemContet">{{ originJournal }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="themenTxtItem" v-if="reprintJournal">
          <el-col :span="2">
            <div class="grid-content bg-purple">
              <span class="itemTitle">转载来源：</span>
            </div>
          </el-col>
          <el-col :span="20">
            <div class="grid-content bg-purple-light">
              <span class="itemContet"
                >复印报刊资料{{ reprintJournal }}{{ period }}</span
              >
            </div>
          </el-col>
        </el-row>
        <!-- <el-row class="themenTxtItem" v-if="reprintJournal && awardsList && awardsList.length > 0"> -->
        <el-row
          class="themenTxtItem"
          v-if="awardsList && awardsList.length > 0"
        >
          <el-col :span="2">
            <div class="grid-content bg-purple">
              <span class="itemTitle">获奖说明：</span>
            </div>
          </el-col>
          <el-col :span="20">
            <div class="grid-content bg-purple-light">
              <span
                class="itemContet"
                v-if="awardsList && awardsList.length > 0"
              >
                <span
                  v-for="(item, index) in awardsList"
                  class="itemContet awards"
                  :key="index"
                >
                  {{ item }}
                  <span class="fenhao">；</span>
                </span>
              </span>
            </div>
          </el-col>
        </el-row>
        <el-row
          class="themenTxtItem"
          v-if="subjectTopics && subjectTopics.length > 0"
        >
          <el-col :span="2">
            <div class="grid-content bg-purple">
              <span class="itemTitle">相关专题：</span>
            </div>
          </el-col>
          <el-col :span="20">
            <div
              class="grid-content bg-purple-light"
              v-if="subjectTopics.length > 0"
            >
              <span
                class="itemContet highlight overflow-dot"
                @click="handleGoSubject(item.id)"
                :title="item.name"
                v-for="(item, index) in subjectTopics"
                :key="index"
              >
                <span class="keywords">{{ item.name }}</span>
                <span class="fenhao">；</span>
              </span>
            </div>
          </el-col>
        </el-row>
        <el-row
          class="themenTxtItem"
          v-if="originalConcept && originalConcept.length > 0"
        >
          <el-col :span="2">
            <div class="grid-content bg-purple">
              <span class="itemTitle">原创概念：</span>
            </div>
          </el-col>
          <el-col :span="20">
            <div
              class="grid-content bg-purple-light"
              v-if="originalConcept.length > 0"
            >
              <span
                class="itemContet highlight overflow-dot"
                @click="handleGoOriginal(item.id)"
                :title="item.name"
                v-for="(item, index) in originalConcept"
                :key="index"
              >
                <span class="keywords">{{ item.name }}</span>
                <span class="fenhao">；</span>
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="line"></div>
      <div class="thementopBtn">
        <div class="reactify">
          <span class="itemtitleType flexCenter" @click="handleClaimCorrection">
            <img
              class="btnImg"
              :src="require('../../assets/images/main/comment/feedback.png')"
              alt
            />
            信息纠正
          </span>
        </div>
        <div class="thementopBtn2">
          <div class="shareBox01 collectSpan">
            <span class="lickIcon btnIcon"></span>
            <span
              class="btnTxt"
              :class="{ isdisabled: likeFlag === true }"
              @click="handleLike(0)"
              v-if="
                isLike === '0' ||
                isLike === undefined ||
                isLike === null ||
                isLike === ''
              "
              >点赞</span
            >
            <span
              class="btnTxt"
              :class="{ isdisabled: likeFlag === true }"
              @click="handleLike(1)"
              v-else
              >已点赞</span
            >
          </div>
          <div
            class="shareBox01 shareText"
            @click="handleShowShare"
            id="sharetxt"
          >
            <span class="shareIcon btnIcon shareIcon"></span>
            <span class="btnTxt shareBtn">分享</span>
            <div class="qrCodeimg" ref="qrCodeimg" v-show="qrCodeImgShow">
              <!-- 二维码内容 -->
              <div class="shareBox">
                <div class="shareimgBox">
                  <img
                    src="../../assets/images/head/logo.png"
                    class="titleimg"
                    style="height: 44px"
                  />
                  <p class="shareTitle" :title="title">{{ title }}</p>
                  <div class="authortxt">
                    <template>
                      <span
                        v-for="(item, index) in authorList"
                        :key="index"
                        class="authorlist"
                      >
                        {{ item.name }}({{ item.organization.name }})
                        <span class="fenge">&nbsp;&nbsp;|</span>
                        <!-- <span class="stationline" v-if="authorBio"></span> -->
                      </span>
                    </template>
                  </div>
                  <div class="shareContent">
                    <div class="padContent">
                      <p
                        v-if="mainContent !== '' && mainContent !== null"
                        class="shareContentTitle"
                      >
                        摘要：
                      </p>
                      <div
                        v-if="mainContent !== '' && mainContent !== null"
                        class="shareContenttxt"
                      >
                        {{ mainContent || "wu" }}
                      </div>
                      <el-row
                        class="shareContentkey"
                        v-if="keywords.length !== 0"
                      >
                        <span class="shareContentkeytxt">【关键词】</span>
                        <span style="margin-left: 10px">
                          <span
                            class="shareContentkeytxt"
                            v-for="(item, index) in keywords"
                            :key="index"
                          >
                            {{ item || "无" }}
                            <span class="fenhao">；</span>
                          </span>
                        </span>
                      </el-row>
                      <el-row
                        class="shareContentdiscipline"
                        v-if="disc.length !== 0"
                      >
                        <el-col :span="5">
                          <div class="grid-content bg-purple">
                            <span class="shareContentkeytxt">所属学科：</span>
                          </div>
                        </el-col>
                        <el-col :span="19">
                          <div class="grid-content bg-purple-light">
                            <div class="overflow-dot2">
                              <span
                                v-for="(item, index) in disc"
                                :key="index"
                                class="itemmargin"
                              >
                                <span
                                  class="itemContet"
                                  v-for="(el, i) in item.disciplineList"
                                  :key="i"
                                >
                                  {{ item.name + "->" + el.name }}
                                </span>
                                <!-- <span class="itemContet">
                                  {{
                                    item.firstLevelDiscipline
                                  }}
                                </span>
                                <span class="themenTxtItemgljt">></span>
                                <span class="itemContet">
                                  {{
                                    item.secondLevelDiscipline
                                  }}
                                </span> -->
                              </span>
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row
                        v-if="year !== '' && year !== null"
                        class="shareContentdiscipline"
                      >
                        <el-col :span="5">
                          <div class="grid-content bg-purple">
                            <span class="shareContentkeytxt">发表年份：</span>
                          </div>
                        </el-col>
                        <el-col :span="19">
                          <div class="grid-content bg-purple-light">
                            <span class="shareContentkeytxt">{{ year }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <div class="shareContenimg">
                        <img v-if="periodCover" :src="periodCover" alt />
                        <img
                          src="../../assets/images/home/jqdetailimg.png"
                          style="width: 54px; height: 80px"
                          v-else
                          alt
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bottomshareimg">
                  <div class="sharefooter">
                    <div class="sharefooterlf">
                      <!-- <p class="footerTzt">点击复制链接到浏览器打开</p> -->
                      <span class="copyBtn" @click="handlecopylink"
                        >复制链接</span
                      >
                    </div>
                    <div class="sharefooterrt">
                      <div class="codetxt">
                        <div>长按二维码</div>
                        <div>识别查看>></div>
                      </div>
                      <div class="qrcode" ref="qrCodeUrl"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <textarea class="textArea" id="textArea"></textarea>
          </div>
          <div class="shareBox01 collectSpan">
            <span class="collectIcon btnIcon"></span>
            <span
              class="btnTxt"
              :class="{ isdisabled: colletFlag === true }"
              @click="collectBtn"
              v-if="collect === 0 || collect === undefined"
              >收藏</span
            >
            <span
              class="btnTxt"
              :class="{ isdisabled: colletFlag === true }"
              @click="cancelcollectBtn"
              v-else
              >已收藏</span
            >
          </div>
          <!-- <div class="shareBox">
          <span class="noteIcon btnIcon"></span>
          <span class="btnTxt">笔记</span>
          </div>-->
          <div
            v-if="isAuthorized === true || isClaim === true"
            class="shareBox02 onlineTolook1"
          >
            <a class="btnTxt02 btnTxt02a" @click="downloadFile(isPreview)"
              >下载</a
            >
            <div
              class="tootl"
              v-if="isPreview === false && this.$store.state.token"
            >
              <div class="top">此文数据正在获取中</div>
              <div class="bottom"></div>
            </div>
          </div>
          <!-- <div v-if="isAuthorized === 1 && isReprint === 0" class="shareBox02"> -->
          <!-- <div v-if="isAuthorized === true  && isReprint === 0" class="shareBox02">
            <a class="btnTxt02 btnTxt02a" @click="handleread">在线阅读</a>
          </div> -->
          <div
            v-if="isAuthorized === true || isClaim === true"
            class="shareBox02 onlineTolook"
          >
            <a class="btnTxt02 btnTxt02a" @click="handlook(isPreview)"
              >在线预览</a
            >
            <div
              class="tootl"
              v-if="isPreview === false && this.$store.state.token"
            >
              <div class="top">此文数据正在获取中</div>
              <div class="bottom"></div>
            </div>
          </div>
          <!-- <div  class="shareBox02">
            <a class="btnTxt02 btnTxt02a" @click="handlook">在线记笔记</a>
          </div> -->
        </div>

        <!-- 二维码图片 -->
      </div>
    </div>
    <!-- <el-dialog :visible.sync="dialogFormVisible" customClass="customdialog" width="56%"></el-dialog> -->
    <el-dialog
      :lock-scroll="false"
      title="信息纠正"
      :visible.sync="dialogFormVisible"
      width="60%"
      customClass="customdialog"
      :before-close="close"
    >
      <el-form
        label-position="left"
        label-width="100px"
        style="
          font-size: 20px;
          font-weight: 550;
          padding-left: 40px;
          padding-right: 30px;
        "
      >
        <el-form-item label="*问题类型:">
          <label class="titleInfo" slot="label">
            <span style="color: red">*</span> 问题类型:
          </label>
          <el-radio-group v-model="errorCorrecting.type">
            <el-radio label="功能问题" name="type"></el-radio>
            <el-radio label="数据问题" name="type"></el-radio>
            <el-radio label="侵权申述" name="type"></el-radio>
            <el-radio label="其他意见反馈" name="type"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="*问题描述:">
          <label slot="label">
            <span style="color: red">*</span> 问题描述:
          </label>
          <el-input
            type="textarea"
            v-model="form.problemDesc"
            :rows="2"
            placeholder="请输入内容"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="添加图片:">
          <label slot="label">
            <span style="opacity: 0">*</span> 添加图片:
          </label>
          <el-upload
            :action="action"
            list-type="picture-card"
            :on-remove="handleImg"
            :auto-upload="false"
            :on-change="handleChange"
            :limit="5"
            :file-list="fileList"
            :class="{ hide: hideUpload }"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot-scope="{ file }">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
            </div>
          </el-upload>
          <p class="uploadprompt">
            注：支持jpg/png/jpeg格式，图片大小控制在5MB以内，最多可上传5张
          </p>
        </el-form-item>
        <el-form-item label="*手机账号:">
          <label slot="label">
            <span style="color: red; opacity: 0">*</span> 手机账号:
          </label>
          <el-input
            v-model="form.phone"
            placeholder="请输入您的手机号码,以便与您联系"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱账号:">
          <label slot="label">
            <span style="opacity: 0">*</span> 邮箱账号:
          </label>
          <el-input
            v-model.trim="form.email"
            placeholder="请输入您的邮箱号码,以便与您联系"
            maxlength="30"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="handleSaveBtn">确 定</el-button>
      </div>
    </el-dialog>

    <login
      ref="login"
      class="homeLoginBigBox"
      @registeropen="registeropen"
    ></login>
    <register
      ref="register"
      class="registerBtn"
      @loginopen="loginopen"
      @emitSuccessRegister="$refs.login.modelopen = true"
    >
    </register>
    <el-dialog
      :lock-scroll="false"
      custom-class="setDialog"
      :z-index="99999"
      :visible.sync="personAgree"
      :close-on-click-modal="false"
      center
      :modal-append-to-body="false"
    >
      <signatures-contract class="agree"></signatures-contract>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="checksure" type="primary" class="checksure"
          >确认</el-button
        >
      </span>
    </el-dialog>
    <claim ref="claim"></claim>
  </div>
</template>

<script>
import claim from "../../components/author/claim";
import login from "../../page/login";
import register from "../../page/register";
import QRCode from "qrcodejs2";
import { threeHandleLogin } from "../../utils/threeLogin";
import signaturesContract from "@/page/digitalSignatures/signaturesContract";
export default {
  name: "detailTheMen",
  components: {
    QRCode,
    login,
    register,
    claim,
    signaturesContract,
  },
  data() {
    return {
      authorList: [],
      personAgree: false,
      form: {
        attachUrlList: [], // 头像
        phone: "", // 手机号
        email: "", // 邮箱
        type: "", // 类型
        problemDesc: "", // 问题描述
        referenceNumber: "",
        title: "",
      },
      errorCorrecting: {
        type: [],
      },
      isSign: "",
      dialogFormVisible: false,
      isAuthorized: false, // 是否认领
      awardsList: [], // 获奖列表
      shareshow: false,
      id: "", // 文献编号
      title: "", // 标题
      secondTitle: "", // 副标题
      referenceNumber: "", // 文献编号
      year: "", // 年份
      keywords: [], // 关键词
      mainContent: "", // 摘要
      periodCover: "", // 分享图片
      authorBio: "", // 作者简介
      disc: [], // 所属学科
      fundProjects: [],
      originJournal: "", // 原发期刊
      reprintJournal: "",
      period: "",
      citeNum: "",
      collectNum: "",
      shareNum: "",
      commentNum: "",
      isHighlyCited: 0,
      isAwards: "1",
      isRecommend: 0,
      isClaim: false, // 1，未认领，0已认领
      isPreview: false,
      authorship: "",
      authorLink: "",
      companyLink: [],
      companyCodeLink: [],
      path: "", // 列表链接
      link: "",
      collectstate: false, // 收藏状态
      isCollect: 0, // 8:未被收藏，1: 被收藏
      collect: 0,
      downloadNum: 0,
      clickNum: 0,
      periodLink: "",
      action: "",
      imageUrl: "",
      // awardsList: []
      isLike: "",
      likeNum: "", // 点赞数量
      isHandleLike: "",
      likeFlag: false, // 用来判断点赞过程中按钮是否可用
      colletFlag: false, // 用来判断点赞过程中按钮是否可用
      fileList: [],
      isReprint: "",
      hideUpload: false,
      imglist: [],
      subjectTopics: [],
      originalConcept: [], // 原创概念
      qrCodeImgShow: false, // 控制分享页显示和隐藏
      authorName: [],
    };
  },

  methods: {
    formatterFirstAndSecond(data) {
      // const subject = data
      // // const arr = subject.map((item) => {
      // //   return item.name + '->' + item.disciplineList[0].name
      // // })
      // const transformedArray = subject.map(item => {
      //   // 从每个元素中提取 fathername 和 childrenname
      //   const fathername = item.name
      //   const childrenNames = item.disciplineList.map(child => child.name)
      //   // 返回新的对象，包含 fathername 和 childrenNames
      //   return { fathername, childrenNames }
      // })
      // console.log('transformedArray', transformedArray)
      // const arr = subject.map((item, index) => {
      //   return item.name + '->' + subject[index].disciplineList.forEach(el => {
      //     return el.name
      //   })
      // })
      // // for (let i = 0; i < subject.length; i++) {
      // //   for(let j)
      // // }
      // console.log('subject', subject)
      // return arr
    },
    checksure() {
      this.personAgree = false;
      this.$refs.claim.claimbox = true;
    },
    openClickShare() {
      document.addEventListener("click", this.closeShare, false);
    },
    closeShare(e) {
      const boxEl = document.querySelector(".qrCodeimg");
      if (
        this.$refs.qrCodeimg &&
        !boxEl.contains(e.target) &&
        e.target.className !== "shareBox01 shareText" &&
        e.target.className !== "btnTxt shareBtn" &&
        e.target.className !== "shareIcon btnIcon shareIcon"
      ) {
        if (this.qrCodeImgShow === true) {
          this.qrCodeImgShow = false;
        }
      }
    },
    removeListener() {
      document.removeEventListener("click", this.closeShare, false);
    },
    handleShowShare() {
      this.qrCodeImgShow = !this.qrCodeImgShow;
    },
    // 认领文献
    goClaim() {
      // this.$router.push("/about");
      if (!this.$store.state.token) {
        return threeHandleLogin(this.THREE_LOGIN_OR);
        // this.$refs.login.modelopen = true
        // return
      }
      // this.$refs.claim.claimbox = true
      this.personAgree = true;
    },
    handleCompanyDetail(item) {
      const routeData = this.$router.resolve({
        path:
          "/institutiondetail?name=" +
          item.code +
          "&page=" +
          1 +
          "&pageRow=" +
          10,
        // query: params
      });
      window.open(routeData.href, "_blank");
    },
    // 点击点赞按钮
    async handleLike(typeLike) {
      this.likeFlag = true;
      if (!this.$store.state.token) {
        this.likeFlag = false;
        return threeHandleLogin(this.THREE_LOGIN_OR);
        // this.$refs.login.modelopen = true
        // return
      }
      const res = await this.$http({
        url: "/detail/like",
        method: "POST",
        data: {
          referenceNumbers: this.referenceNumber,
          type: typeLike,
        },
      });
      if (res.data.code !== 200) {
        return this.$message({
          type: "warning",
          message: res.data.msg,
        });
      }

      this.handleIsLike();
      this.getmaindetailTitle();
    },
    // 获取是否点赞
    async handleIsLike() {
      const res = await this.$http({
        url: "/detail/queryLike",
        method: "post",
        data: {
          referenceNumbers: this.referenceNumber,
        },
      });
      if (res.code === 115001) {
        this.isLike = false;
      } else {
        this.isLike = res.data.data;
      }
      // this.likeFlag = false
    },
    close() {
      this.dialogFormVisible = false;
      var phonelist = "";
      this.form = {
        phone: phonelist,
        attachUrlList: [],
        email: "",
        title: this.title,
      };
      this.imglist = [];
      this.errorCorrecting.type = [];
      this.fileList = [];
      this.hideUpload = false;
    },
    async handleSaveBtn() {
      this.form.referenceNumber = this.referenceNumber;
      this.form.type = this.errorCorrecting.type;
      if (this.errorCorrecting.type.length == 0) {
        return this.$message({
          type: "warning",
          message: "问题类型不能为空",
        });
      }
      if (this.form.problemDesc === "") {
        return this.$message({
          type: "warning",
          message: "问题描述不能为空",
        });
      }
      // if (this.form.attachUrlList.length === 0) {
      //   return this.$message({
      //     type: 'warning',
      //     message: '图片不能为空'
      //   })
      // }
      if (this.form.phone != "") {
        const phoneReg = /^1[3456789]\d{9}$/;
        if (!phoneReg.test(this.form.phone)) {
          return this.$message.error("手机号格式错误");
        }
        // return this.$message({
        //   type: 'warning',
        //   message: '手机号不能为空'
        // })
      }

      const checkEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.form.email) {
        this.form.title = this.title;
        if (!checkEmail.test(this.form.email)) {
          return this.$message.error("邮箱格式错误");
        }
      }
      const res = await this.$http({
        url: "/correction/saveCorrection",
        method: "POST",
        data: {
          ...this.form,
        },
      });
      if (res.data.code !== 200) {
        return this.$message({
          type: "warning",
          message: "保存失败",
        });
      }
      this.dialogFormVisible = false;
      var phonelist = "";
      this.form = {
        phone: phonelist,
        attachUrlList: [],
        email: "",
        title: this.title,
      };
      this.imglist = [];
      this.errorCorrecting.type = [];
      this.fileList = [];
      this.hideUpload = false;
      this.$message({
        type: "success",
        message: "提交成功",
      });
    },
    handleImg(file, fileList) {
      this.imglist = this.imglist.filter((item) => item.uid !== file.uid);
      this.form.attachUrlList = [];
      this.imglist.forEach((val) => {
        this.form.attachUrlList.push(val.path);
      });
      if (this.form.attachUrlList.length >= 5) {
        this.hideUpload = true;
      } else {
        this.hideUpload = false;
      }
    },
    async handleChange(file, filelist) {
      if (this.form.attachUrlList.length >= 4) {
        this.hideUpload = true;
      }
      const isJPG = file.raw.type === "image/jpeg";
      const isPNG = file.raw.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJPG && !isPNG) {
        file = "";
        filelist.pop();
        if (filelist.length == 4) {
          this.hideUpload = false;
        }
        // this.fileList.pop()
        this.$message({
          type: "warning",
          message: "上传头像图片只能是 JPG/JPEG/PNG 格式!",
        });
        // this.$message.error('上传头像图片只能是 JPG或PNG 格式!')
      } else {
        if (!isLt2M) {
          file = "";
          filelist.pop();
          if (filelist.length == 4) {
            this.hideUpload = false;
          }
          // this.fileList.pop()
          this.$message({
            type: "warning",
            message: "上传头像图片大小不能超过 5MB!",
          });
          // this.$message.error('上传头像图片大小不能超过 1MB!')
        } else {
          const upfile = new FormData(); // formData实例对象 内部是空的
          upfile.append("file", file.raw);
          const re = await this.$http({
            url: "/correction/uploadImage",
            method: "POST",
            data: upfile,
          });
          if (re.data.code != 200) {
            file = "";
            filelist.pop();
            // this.fileList.pop()
            this.$message.error(re.msg);
          } else {
            this.imglist.push({ path: re.data.data.path, uid: file.uid });
            this.form.attachUrlList.push(re.data.data.path);
          }
        }
      }
    },
    // 认领纠错
    async handleClaimCorrection() {
      if (!this.$store.state.token) {
        // 未登录
        return threeHandleLogin(this.THREE_LOGIN_OR);
        // this.$refs.login.modelopen = true
      } else {
        // 登录
        this.dialogFormVisible = true;
        // this.form.phone = localStorage.getItem('phone')
        await this.$http({
          url: "/correction/getUserBasicInfo",
          method: "POST",
        });
      }
    },

    // 跳转
    handleGoSubject(id) {
      // 点击去详情
      const routeData = this.$router.resolve({
        path: "/disciplinedetail?id=" + id,
        // query: params
      });
      window.open(routeData.href, "_blank");
    },
    handleGoOriginal(id) {
      const routeData = this.$router.resolve({
        path: "/originalDetail?id=" + id,
        // query: params
      });
      window.open(routeData.href, "_blank");
    },
    // 跳转
    handleGoHotword(item) {
      // 点击去详情
      const routeData = this.$router.resolve({
        path: "/keyWord?name=" + item,
        // query: params
      });
      window.open(routeData.href, "_blank");
    },
    // 跳转
    handleGoDetail(author) {
      // 点击去详情
      const routeData = this.$router.resolve({
        path:
          "/authorDetail?id=" +
          author.id +
          "&name=" +
          author.organization.code +
          "&authorCode=" +
          author.code,
        // query: params
      });
      window.open(routeData.href, "_blank");
    },
    goJournalDetail() {
      const journal = Object.keys(this.periodLink);
      const routeData = this.$router.resolve({
        path:
          "journaldetail?id=" +
          this.periodLink[journal] +
          "&name=" +
          journal[0],
        // query: params
      });
      window.open(routeData.href, "_blank");
    },
    async collectBtn() {
      if (!this.$store.state.token) {
        this.colletFlag = false;
        return threeHandleLogin(this.THREE_LOGIN_OR);
      }
      this.colletFlag = true;
      if (this.collect === undefined) {
        this.isCollectAPI();
      }

      if (this.collect === 0) {
        this.collectBtnData();
        this.collect = 1;
        // this.$message('已经收藏')
      }
    },
    // 点击收藏
    async collectBtnData() {
      const { data: res } = await this.$http({
        url: "/detail/addCollect",
        method: "post",
        data: {
          referenceNumber: this.referenceNumber,
        },
      });

      if (res.code !== 200) return this.$message.error(res.msg);
      this.getmaindetailTitle();
      this.$message.success(res.msg);
      // this.colletFlag = false
      // this.collectstate = false
    },
    // 取消收藏
    cancelcollectBtn() {
      if (this.collect === 1) {
        this.collect = 0;
        this.colletFlag = true;
        this.cancelcollect();
      }
    },
    // 取消收藏
    async cancelcollect() {
      const { data: res } = await this.$http({
        url: "/detail/delCollect",
        method: "post",
        data: {
          referenceNumber: this.referenceNumber,
        },
      });
      if (res.code !== 200) return this.$message.error(res.msg);
      this.getmaindetailTitle();
      this.$message.success(res.msg);

      this.collectstate = true;
    },
    // 获取二维码
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.link, // 需要转换为二维码的内容
        width: 50,
        height: 50,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    // 点击分享按钮
    async handlecopylink() {
      const textArea = document.getElementById("textArea");
      textArea.value = `诚邀您阅读\n${this.title}(${this.authorName})\n来自：【哲学社会科学主文献】${this.link}`;
      textArea.select();
      document.execCommand("copy");
      const res = await this.$http({
        url: "/detail/addShare",
        method: "POST",
        data: {
          referenceNumber: this.referenceNumber,
        },
      });
      this.$message.success("文献链接已复制，快去分享吧");
      this.getmaindetailTitle();
    },
    // 获取全部数据
    // async getmaindetailTitle (){

    // },
    async getmaindetailTitle() {
      // this.companyLink = []
      const { data: res } = await this.$http({
        url: `/literatureInfoList/find-one/${this.referenceNumber}`,
        method: "POST",
        // data: {
        //   id: this.id,
        //   comment: '',
        //   path: '',
        //   referenceNumber: '',
        //   title: ''
        // }
      });
      if (res.code !== 200) return this.$message.error(res.msg);
      // this.colletFlag = false
      // this.colletFlag = false
      this.title = res.data.title;
      this.secondTitle = res.data.subtitle; // 副标题
      // // 存值
      this.$store.commit("maindetailTitle", this.title);
      this.authorList = res.data.authorList;
      this.authorList.forEach((item) => {
        this.companyLink.push({
          ...item.organization,
        });
      });
      // this.companyLink = Array.from(new Set(this.companyLink))
      // 去重,不闪
      this.companyLink = Array.from(
        new Set(this.companyLink.map(JSON.stringify)),
        JSON.parse
      );
      this.companyLink = this.companyLink.filter((item) => {
        return item.name !== "待完善";
      });
      // console.log(' this.companyLink', this.companyLink)
      this.referenceNumber = res.data.referenceNumber;
      this.awardsList = res.data.awardsList; // 获奖说明
      this.year = res.data.pubYear;
      this.keywords = res.data.keywordList;
      this.likeNum = res.data.statistics.likeNum; // 喜欢
      this.mainContent = res.data.mainContent;
      this.periodCover = res.data.journalPath; // 文献封面
      // this.authorBio = res.data.authorBio//
      this.disc = res.data.disciplineList;
      // this.fundProjects = res.data.fundProjects
      this.originJournal = res.data.originInfo;
      this.reprintJournal = res.data.journalName;
      this.period = res.data.period;
      // this.citeNum = res.data.statistics.citeNum
      this.clickNum = res.data.statistics.clickNum;
      this.downloadNum = res.data.statistics.downloadNum;
      this.collectNum = res.data.statistics.collectNum;
      this.shareNum = res.data.statistics.shareNum;
      this.commentNum = res.data.statistics.commentNum;
      // this.isHighlyCited = res.data.isHighlyCited
      this.isAwards = res.data.isAwards;
      this.isAuthorized = res.data.isAuthorized; // 暂时没有返回
      // this.isSign = res.data.authorLink[0].isSign
      this.isRecommend = res.data.isRecommend;
      this.isClaim = res.data.isClaim;
      this.isPreview = res.data.isPreview;
      // this.authorship = res.data.authorship
      // this.authorLink = res.data.authorLink

      this.authorList.forEach((val) => {
        this.authorName.push(val.name);
      });
      this.authorName = Array.from(new Set(this.authorName));
      // this.companyLink = Array.from(new Set(this.companyLink))
      // this.companyCodeLink = Array.from(new Set(this.companyCodeLink))

      this.periodLink = res.data.originJournal;
      this.form.title = res.data.title;
      this.isReprint = res.data.isReprint;
      this.subjectTopics = res.data.subjectTitleList;
      this.originalConcept = res.data.cnList;
      this.isCollectAPI();
      // this.isCollect = res.data.isCollect // 是否收藏
    },
    // 获取是否收藏
    async isCollectAPI() {
      const res = await this.$http({
        url: "/detail/isCollect",
        method: "POST",
        data: {
          referenceNumber: this.referenceNumber,
        },
      });
      this.colletFlag = false;
      this.likeFlag = false;
      this.collect = res.data.data;
    },
    // 点击下载数据
    async downloadFile(data) {
      if (!this.$store.state.token) {
        return threeHandleLogin(this.THREE_LOGIN_OR);
      }
      if (data === false) {
        return;
      }
      const res = await this.$http({
        url:
          "/detail/downloadPdf?referenceNumber=" +
          this.referenceNumber +
          "&title=" +
          this.title,
        method: "get",
        responseType: "blob",
      });
      const content = res.data;

      // 重点
      const blob = new Blob([content], {
        type: "application/pdf;charset=UTF-8", // word文档为msword,pdf文档为pdf
      });
      const objectURL = URL.createObjectURL(blob);
      const downEle = document.createElement("a");
      const fname = this.title; // 下载文件的名字
      downEle.href = objectURL;
      downEle.setAttribute("download", fname);
      document.body.appendChild(downEle);
      downEle.click();

      // const fileName = decodeURIComponent(
      //   res.headers['content-disposition'].split('=')[1]
      // )
      // if ('download' in document.createElement('a')) {
      //   // 非IE下载
      //   const elink = document.createElement('a')
      //   elink.download = fileName
      //   elink.style.display = 'none'
      //   elink.href = URL.createObjectURL(blob)
      //   document.body.appendChild(elink)
      //   elink.click()
      //   URL.revokeObjectURL(elink.href) // 释放URL 对象
      //   document.body.removeChild(elink)
      // } else {
      //   // IE10+下载
      //   navigator.msSaveBlob(blob, fileName)
      // }
      this.getmaindetailTitle();
    },
    // async downloadFile (name) {
    //   const { data: res, headers } = await this.$http({
    //     url:
    //       '/detail/download?id=' +
    //       this.id +
    //       '&path=' +
    //       this.path +
    //       '&referenceNumber=' +
    //       this.referenceNumber +
    //       '&title=' +
    //       this.title,
    //     method: 'get'
    //   })
    //   // 判断是否登录
    //   if (res.code === 115001) {
    //     return this.loginhandle()
    //   }
    //   const blob = new Blob([res], {
    //     type: 'application/octet-stream;charset=utf-8'
    //   })

    //   const fileName = decodeURIComponent(
    //     headers['content-disposition'].split('=')[1]
    //   )
    //   if ('download' in document.createElement('a')) {
    //     // 非IE下载
    //     const elink = document.createElement('a')
    //     elink.download = fileName
    //     elink.style.display = 'none'
    //     elink.href = URL.createObjectURL(blob)
    //     document.body.appendChild(elink)
    //     elink.click()
    //     URL.revokeObjectURL(elink.href) // 释放URL 对象
    //     document.body.removeChild(elink)
    //   } else {
    //     // IE10+下载
    //     navigator.msSaveBlob(blob, fileName)
    //   }
    //   this.getmaindetailTitle()
    // },
    // 在线预览
    handlook(data) {
      if (!this.$store.state.token) {
        return threeHandleLogin(this.THREE_LOGIN_OR);
      }
      if (data === false) {
        return;
      }
      const routeData = this.$router.resolve({
        path: "/looking",
        query: { referenceNumber: this.referenceNumber },
      });
      window.open(routeData.href, "_blank");
    },
    // 在线阅读
    handleread() {
      if (!this.$store.state.token) {
        return threeHandleLogin(this.THREE_LOGIN_OR);
        // return false
        // this.$refs.login.modelopen = true
        // return
      }
      const routeData = this.$router.resolve({
        path:
          "/reading?path=" +
          this.path +
          "&referenceNumber=" +
          this.referenceNumber,
        // query: params
      });
      window.open(routeData.href, "_blank");
    },
    loginhandle() {
      this.$refs.login.modelopen = true;
    },
    registeropen(value) {
      this.$refs.register.registershow = value;
    },
    loginopen(value) {
      this.$refs.login.modelopen = value;
    },
  },
  async mounted() {
    this.link = window.location.href;

    // 取链接的值-文献编号
    this.id = this.$route.query.id;
    this.referenceNumber = this.$route.query.referenceNumber;
    // 取链接的值-文献id
    this.creatQrCode();

    this.path = this.$route.query.path;
    await this.getmaindetailTitle();
    this.action = this.BASE_URL + "correction/uploadImage";
    this.handleIsLike();
    document.title = this.title + this.$route.meta.title;
    this.openClickShare();
  },
  beforeDestroy() {
    this.removeEventListener();
  },
  watch: {
    shareshow: {
      handler(data) {
        if (data) {
          this.$nextTick(() => {});
        }
      },
    },
    // 监听当前手机号是不是超级号
    "$store.state.superPhone": {
      handler(data) {
        if (data === "13468831893") {
          this.getmaindetailTitle();
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.hide .el-upload--picture-card {
  display: none;
}

// 已认领,未授权图标
.claimImg {
  width: 64px;
  height: 22px;
  margin-left: 5px;

  // img {
  //   width: 100%;
  //   height: 100%;
  // }
}

// 文献认领入口
.claimBtn {
  color: #9c2526;
  padding-left: 10px;
  cursor: pointer;
}

.flexCenter {
  display: flex;
  // align-items: center;
}

.customdialog {
  width: 1070px !important;
  height: 616px !important;
  border-radius: 4px !important;
}

.hyperlink {
  cursor: pointer;
  font-size: 16px;

  &:hover {
    color: #9c2526 !important;
    font-weight: 500;
    border-bottom: 1px solid #951617;
  }
}

.textArea {
  position: absolute;
  top: -999px;
  height: 0;
}

// 分享
.qrCodeimg {
  // display: none;
  position: absolute;
  top: -384px;
  right: 64px;
  z-index: 999;
}

#sharetxt {
  width: 66px;
  position: relative;

  user-select: none;

  &:hover {
    .qrCodeimg {
      // display: block;
    }
  }
}

.shareBox {
  width: 390px;
  background-color: #951617;

  .shareimgBox {
    background: url("../../assets/images/home/shareimgbg.png") no-repeat;
    // background-size: contain;
    height: auto;
    // padding: 65px 0;
    box-sizing: border-box;
    text-align: left;

    .titleimg {
      margin-top: 30px;
      margin-left: 26px;
    }

    .shareTitle {
      padding: 0 34px 0;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 2px 4px 6px rgba(117, 10, 11, 0.66);
    }

    .authortxt {
      margin: 15px 0;
      padding: 0 34px 0;
      line-height: 10px;

      > span {
        // padding: 0 65px;
        color: #ffffff;
        font-size: 10px;
      }

      .authorlist {
        display: inline-block;
        margin: 5px 5px 0 0;
      }

      .authorlist:last-child {
        .fenge {
          display: none;
        }
      }
    }

    .shareContent {
      box-shadow: 10px 9px 36px 0px rgba(127, 0, 1, 0.81);
      border-radius: 9px;
      background-color: #fff;
      width: 322px;
      padding: 5px;
      box-sizing: border-box;
      margin: 0 auto;
      margin-top: 19px;
      text-align: left;

      .padContent {
        border: 1px solid rgba(127, 0, 1, 0.33);
        border-radius: 6px;
        padding: 15px 11px 0 13px;
        min-height: 350px;

        .shareContentTitle {
          height: 10px;
          line-height: 10px;
          font-size: 10px;
          font-weight: 400;
          color: #951617;
          // margin-bottom: 20px;
        }

        .shareContenttxt {
          margin-top: 5px;
          max-height: 80px;
          overflow: hidden;
          text-indent: 2em;
          font-size: 10px;
          font-weight: 400;
          color: #727272;
          line-height: 19px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }

        .shareContentkey {
          line-height: 2;

          .shareContentkeytxt {
            font-size: 10px;
            font-weight: 400;
            color: #951617;
            display: inline-block;
            // line-height: 30px;
          }

          .shareContentkeytxt:last-child {
            .fenhao {
              display: none;
            }
          }
        }

        .shareContentdiscipline {
          display: flex;

          .shareContentkeytxt {
            width: 65px;
            display: inline-block;
            font-size: 10px;
            color: #666666;
            // line-height: 30px;
          }

          .shareContentkeytxt:last-child {
            .fenhao {
              display: none;
            }
          }

          .spanline {
            line-height: 2;
          }

          .itemmargin {
            > span {
              font-size: 10px;
              color: #666666;
            }
          }
        }

        .shareContenimg {
          text-align: center;
          margin: 0px auto;

          > img {
            width: 94px;
            height: 138px;
            object-fit: cover;
          }
        }
      }
    }
  }

  .bottomshareimg {
    background: url("../../assets/images/home/shareimgbottom.png") no-repeat;
    background-size: cover;
    width: 390px;
    height: 120px;
    box-sizing: border-box;
    position: absolute;
    bottom: -60px;

    .sharefooter {
      margin-top: 16%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .sharefooterlf {
        .footerTzt {
          font-size: 10px;
          color: #ffffff;
          line-height: 20px;
        }

        .copyBtn {
          cursor: pointer;
          padding: 5px 10px;
          font-size: 10px;
          background: #f66a27;
          border-radius: 4px;
          color: #ffffff;
          text-align: center;
        }
      }

      .sharefooterrt {
        display: flex;
        align-items: center;
        display: none;

        .codetxt {
          margin-right: 10px;

          > div {
            line-height: 15px;
            font-size: 10px;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.detailthemenBox {
  margin: 20px 0;
  padding: 20px 54px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;

  .tagBox {
    .tagitem {
      text-align: center;
      padding: 5px 14px;
      background: #9c2526;
      border-radius: 15px;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }

    .jt {
      margin: 0 8px;
      color: #9c2526;
    }
  }

  .detailTxtBox {
    display: flex;
    padding: 0;
    justify-content: center;
    text-align: center;

    .detailTitle {
      margin-left: 10px;
      font-size: 36px;
      font-weight: 500;
      color: #000000;
    }

    .recommend {
      text-align: center;
      display: inline-block;
      width: 36px;
      height: 20px;
      line-height: 20px;
      background: #ff7100;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 11px;
    }

    .listgroupTitleType {
      box-sizing: border-box;
      // vertical-align: super;
      display: inline-block;
      text-align: center;
      padding: 3px 10px;
      background: rgba(255, 0, 0, 0.12);
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      color: #ff0000;
      margin: 16px 10px 0 5px;
      white-space: nowrap;
      vertical-align: text-bottom;
    }
  }

  .secondTitle {
    font-size: 20px;
    color: #666;

    text-align: center;
  }

  .stationline {
    display: inline-block;
    width: 2px;
    height: 10px;
    background: #cccccc;
    margin: 0 10px;
    vertical-align: text-bottom;
  }

  .overtext {
    display: none;
  }

  .detailsubtitleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .detailsubtitleLf {
      margin-right: 8px;

      .listBotttomtxt {
        color: #666;
      }

      .listBotttoomActive {
        color: @my-color;
      }
    }

    .detailsubtitlert {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .detailsubtitleitem {
        margin: 0 10px;

        .detailsubtitletltle {
          margin-right: 10px;
          color: #666666;
        }

        .detailsubtitlenum {
          color: #9c2526;
        }
      }
    }
  }

  .line {
    margin: 20px 0;
    width: 100%;
    height: 1px;
    background: #e6e6e6;
    border-radius: 1px;
  }

  .themenTxtBox {
    .themenTxtItemflex {
      display: flex;

      .themenTxtItemgl {
        display: flex;
        flex-wrap: wrap;

        .themenTxtItemgljt {
          color: #666666;
          margin: 0 8px;
        }

        .itemmargin {
          margin-right: 30px;
        }
      }
    }

    .themenTxtItem {
      width: 100%;
      margin-bottom: 10px;
      display: flex;

      .authorName:hover {
        color: #9c2526;
        font-weight: 500;
        cursor: pointer;
      }

      .originJournal:hover {
        color: #9c2526;
        font-weight: 500;
        cursor: pointer;
      }

      .itemContet {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        // display: flex;
        // align-items: center;

        .awards:last-child {
          span {
            display: none;
          }
        }
      }

      .itemContet:last-child {
        .fenhao {
          display: none;
        }

        .stationline {
          display: none;
        }
      }

      .highlight:hover {
        color: @my-color;
        font-weight: 500;
        cursor: pointer;
      }

      .keywords {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
      }

      .keywords:hover {
        color: @my-color;
        font-size: 500;
        cursor: pointer;
        border-bottom: 1px solid #951617;
      }

      .itemTitle {
        width: 100px;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        display: inline-block;
      }

      .itemContet02 {
        flex: 1;
        text-align: justify;
      }

      // .itemtitleType {
      //   background: @my-color;
      //   color: #fff;
      //   border-radius: 4px;
      //   font-size: 14px;
      //   padding: 4px 12px;
      //   margin-left: 10px;
      //   cursor: pointer;
      // }
    }
  }

  .thementopBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .reactify {
      .itemtitleTyp {
        display: block;
        background-color: #951617;
      }
    }

    .shareBox01 {
      margin-left: 10px;
      text-align: center;
      padding: 0 28px;
      height: 36px;
      line-height: 36px;
      background: #f5e9e9;
      border-radius: 4px;
      user-select: none;
      cursor: pointer;

      .btnIcon {
        background-size: cover;
        display: inline-block;
      }

      .shareIcon {
        width: 13px;
        height: 13px;
        background: url("../../assets/images/home/shareactive.png") no-repeat;
        background-size: cover;
      }

      .collectIcon {
        width: 15px;
        height: 13px;
        background: url("../../assets/images/home/collectIcon.png") no-repeat;
        background-size: cover;
      }

      .noteIcon {
        width: 12px;
        height: 14px;
        background: url("../../assets/images/home/note.png") no-repeat;
        background-size: cover;
      }

      .btnTxt {
        cursor: pointer;
        padding-left: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #9c2526;
      }
    }

    .shareBox02 {
      > a {
      }

      margin-left: 10px;
      text-align: center;
      width: 100px;
      height: 36px;
      line-height: 36px;
      background: #9c2526;
      border-radius: 4px;

      .btnTxt02 {
        cursor: pointer;
        color: #fff;
      }

      .btnTxt02a {
        width: 100%;
        display: inline-block;
        height: auto;
      }
    }

    .thementopBtn2 {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .shareBox01 {
        margin-left: 10px;
        text-align: center;
        padding: 0 28px;
        height: 36px;
        line-height: 36px;
        background: #f5e9e9;
        border-radius: 4px;

        .btnIcon {
          background-size: cover;
          display: inline-block;
        }

        .shareIcon {
          width: 13px;
          height: 13px;
          background: url("../../assets/images/home/shareactive.png") no-repeat;
          background-size: cover;
        }

        .collectIcon {
          width: 15px;
          height: 13px;
          background: url("../../assets/images/home/collectIcon.png") no-repeat;
          background-size: cover;
        }

        .lickIcon {
          width: 14px;
          height: 13px;
          background: url("../../assets/images/home/like.png") no-repeat;
          background-size: cover;
        }

        .noteIcon {
          width: 12px;
          height: 14px;
          background: url("../../assets/images/home/note.png") no-repeat;
          background-size: cover;
        }

        .btnTxt {
          cursor: pointer;
          padding-left: 10px;
          font-size: 16px;
          font-weight: 500;
          color: #9c2526;
        }
      }

      .shareBox02 {
        > a {
        }

        margin-left: 10px;
        text-align: center;
        width: 100px;
        height: 36px;
        line-height: 36px;
        background: #9c2526;
        border-radius: 4px;

        .btnTxt02 {
          cursor: pointer;
          color: #fff;
        }

        .btnTxt02a {
          width: 100%;
          display: inline-block;
          height: auto;
        }
      }
    }
  }
}

//收藏按钮
.collectSpan {
  position: relative;
  width: 60px;

  // &::after {
  //   content: '';
  //   display: block;
  //   clear: both;
  // }
  .btnIcon {
    position: absolute;
    left: 20%;
    top: 50%;
    transform: translateY(-50%);
  }

  .btnTxt {
    // background-color: red;
    display: inline-block;
    position: absolute;
    width: 100%;
    left: 0;
  }
}

.uploadprompt {
  color: #999999;
  font-size: 14px;
  margin-top: 10px;
  font-weight: normal;
  width: 459px;
  height: 15px;
  line-height: 15px;
}

/deep/ .avatar-uploader {
  width: 110px;
  height: 110px;
  display: inline-block;
  vertical-align: top;

  .el-upload {
    width: 100%;
    height: 100%;
    border: 1px dashed #d9d9d9;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.itemtitleType {
  // display: block;
  // background-color: #951617;
  color: #8e8e8e;
  width: 100px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;

  // line-height: 36px;
  .btnImg {
    // display: inline;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    margin-top: 5px;
  }
}

// .titleInfo {
//   font-size: 14px;
//   font-weight: 550;
// }
.el-radio {
  font-size: 14px;
  margin-right: 41px !important;
}

.isdisabled {
  pointer-events: none;
}

/deep/.setDialog {
  width: 800px;
  height: 765px;
  background-image: url("../../assets/images/claim/agreebg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  margin-top: 5vh !important;

  .el-dialog__body {
    padding: 0 !important;
  }
}

.checksure {
  width: 140px;
  height: 40;
}

/deep/.el-dialog__close.el-icon.el-icon-close {
  color: #fff !important;
}

/deep/.agree {
  .text {
    width: 680px;
    height: 566px;
    border-radius: 10px;
  }
}

.onlineTolook {
  position: relative;

  .tootl {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: -100%;
    transform: translate(50%, -100%);
    transform: scale(0);
    transition: all 0.5s;

    .top {
      width: 150px;
      height: 25px;
      line-height: 25px;
      background-color: #ff7100;
      color: #fff;
      border-radius: 5px;
    }

    .bottom {
      // width: 5px;
      // height: 5px;
      // background-color: green;
      border: 5px solid #ff7100;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }
}

.onlineTolook1 {
  position: relative;

  .tootl {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: -100%;
    transform: translate(50%, -100%);
    transform: scale(0);
    transition: all 0.5s;

    .top {
      width: 150px;
      height: 25px;
      line-height: 25px;
      background-color: #ff7100;
      color: #fff;
      border-radius: 5px;
    }

    .bottom {
      // width: 5px;
      // height: 5px;
      // background-color: green;
      border: 5px solid #ff7100;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }
}

.onlineTolook:hover .tootl,
.onlineTolook1:hover .tootl {
  position: absolute;
  top: 0;
  left: -100%;
  transform: scale(1);
  transform: translate(50%, -100%);
}

.itemContet:last-child .stationline {
  display: none;
}
</style>
