<template>
  <div>
    <div>
      <!-- <headerComponent></headerComponent> -->
    </div>
    <div class="titleimg">
      <div class="t1"></div>
      <div>中国特色哲学社会科学自主知识体系数字创新平台</div>
      <div class="t2">哲学社会科学主文献平台简介</div>
    </div>
    <!-- <img style="width: 100%;height:100%;" src="../../assets/images/home/inttitle.png" alt=""> -->
    <div class="introduct">
      <div class="inttitle">平台简介</div>
      <div style="text-align: center;">
        <img src="../../assets/images/home/intimg.png" class="intimg" />
      </div>
      <div class="mainlit">
        <div class="maintext">
          <div class="maintitle">哲社主文献</div>
          <p class="maincontent">
            哲学社会科学主文献平台（简称“哲社主文献”）是中国特色哲学社会科学自主知识体系数字创新平台（简称“学术世界”）的子平台，由中国人民大学书报资料中心具体承建，是一个高效研读学术精品的指南，辅助学习、教学、科研的工具。
          </p>
        </div>
        <img src="../../assets/images/home/Imagescopy.png" class="mainimg" />
      </div>
    </div>
    <div class="introduct">
      <div class="inttitle top">平台定位</div>
      <div style="text-align: center;">
        <img src="../../assets/images/home/intimg.png" class="intimg" />
      </div>
      <div class="poslit">
        <img src="../../assets/images/home/posimg.png" class="posimg" />
        <p class="poscontent">
          “哲社主文献”以马克思主义为指导，面向哲学社会科学自主知识体系建构和高质量教学科研创新的需求，通过一套科学标准和规范流程，基于学术共同体的专业评审，遴选和荟萃各学科主流、经典、必读的文献，进而汇集形成的精品学术资源典藏库和重要文献参考体系。
          “哲社主文献”构建了一个全学科覆盖、动态更新的学术资源精品库，既是一个主流、经典、必读的精选学术文献参考体系，也是一个智能挖掘、协同创新的精加工知识服务产品。“哲社主文献”在建设导向上坚持价值引领理念，在遴选流程上坚持全量优选原则、在成果展现上坚持数智组织方式，在服务目标上坚持创新推动宗旨，力争获得学术共同体的广泛认可，彰显文献精选、人才培养、知识传播、学术评价、导向指引和原创助力六大应用价值。
        </p>
      </div>
    </div>
    <div class="resources">
      <div class="inttitle">平台资源</div>
      <div style="text-align: center;">
        <img src="../../assets/images/home/intimg.png" class="intimg" />
      </div>
      <div class="third">
        <div class="title1">
          <div class="icon1">
            <img src="../../assets/images/home/mianliterature.png" alt="">
          </div>
          <div class="text">共遴选{{ mianliteratureNum }}篇文献</div>
        </div>
        <div class="title2">
          <div class="icon">
            <img src="../../assets/images/home/disciplinelike.png" alt="">
          </div>
          <div class="text">25个一级学科</div>
        </div>
        <div class="title2">
          <div class="icon">
            <img src="../../assets/images/home/seconddisciplineNum.png" alt="">
          </div>
          <div class="text">{{ seconddisciplineNum }}个二级学科</div>
        </div>
      </div>
      <div class="text" style="margin: 0 auto;width: 60%;">
        <p class="texP" style="">
          “哲社主文献”一期建设中的主文献以学术论文（2013-2022年）为主，共遴选{{ mianliteratureNum
          }}篇文献。涵盖哲学、理论经济学、应用经济学（含统计学）、法学（含公安学）、政治学、社会学、民族学、马克思主义理论、中共党史党建学、教育学、心理学、体育学、中国语言文学、外国语言文学、新闻传播学、艺术学、考古学、中国史、世界史、地理学、生态学、工商管理、农林经济管理、公共管理、信息资源管理等哲学社会科学25个一级学科、{{
            seconddisciplineNum }}个二级学科。
        </p>
        <p class="texP mt30">
          <!-- “哲社主文献”客观展示主文献遴选结果，以学科为基础，从作者、机构、期刊、学术热点、原创概念、热门专题、热词等多个维度为用户提供信息检索、资源导航、快速筛选、文献导读、数据统计、个性化推荐与订阅等及时、动态的精选信息资源和精加工知识服务。 -->
        </p>
      </div>
    </div>
    <div class="introduct">
      <div class="inttitle">平台服务</div>
      <div style="text-align: center;">
        <img src="../../assets/images/home/intimg.png" class="intimg" />
      </div>
      <div class="content mt38">
        “哲社主文献”客观展示主文献遴选结果，以学科为基础，从作者、机构、期刊、学术热点、原创概念、热门专题、热词等多个维度为用户提供信息检索、资源导航、快速筛选、文献导读、数据统计、个性化推荐与订阅等及时、动态的精选信息资源和精加工知识服务。
      </div>
      <!-- <div class="content mt30">“哲社主文献”学术交流社区为用户提供针对主文献的分享、收藏、点赞、批注、评论等服务，并依据用户兴趣，提供个性化推荐与订阅服务。
      </div> -->
    </div>
    <div class="serve">
      <div class="sertitle">
        <span class="sericon"></span>
        <span class="sertitletext">“哲社主文献”一期遴选标准</span>
      </div>
      <table border="1" cellspacing="0">
        <tr>
          <th width="21%" class="tabth">遴选标准</th>
          <th class="tabth">说明</th>
        </tr>
        <tr>
          <td class="tabtd1">政治立场</td>
          <td class="tabtd2">坚持马克思主义，坚持以习近平新时代中国特色社会主义思想为指导。 </td>
        </tr>
        <tr>
          <td class="tabtd1">学术诚信</td>
          <td class="tabtd2">遵循学术规范，恪守学术道德，无知识产权问题。</td>
        </tr>
        <tr>
          <td class="tabtd1">理论创新</td>
          <td class="tabtd2">注重学理研究，发现新问题，提出新概念、新观点、新方法、新学说，揭示新规律，体现原创性、时代性，引领学术研究的创新性发展。</td>
        </tr>
        <tr>
          <td class="tabtd1">知识贡献</td>
          <td class="tabtd2">对知识发现和知识创新有较大贡献，具有重要学术价值，是学科领域知识体系的必要组成部分，助力中国哲学社会科学自主知识体系建构。</td>
        </tr>
        <tr>
          <td class="tabtd1">学科影响</td>
          <td class="tabtd2">在学科领域教学科研中被广泛关注和引用，被公认为必读文献，对学科的建设、发展和国际学术影响力的提升具有重要作用。</td>
        </tr>
        <tr>
          <td class="tabtd1">实践引导</td>
          <td class="tabtd2">具有较高应用价值，对解决中国经济社会发展中的重要问题起到理论支撑和实践引领作用。</td>
        </tr>
        <!-- <tr height="17.5%">
          <td class="tabtd1">实践引导力</td>
          <td class="tabtd2">具有较高应用价值，对解决中国经济社会发展中的重要问题起到理论支撑和实践引领作用。</td>
        </tr> -->
      </table>
      <div class="tip" @click='goSelect'>注：各学科参照上述基本标准形成本学科的遴选标准（具体可见各学科主文献遴选标准说明页面）。</div>
      <div class="sertitle">
        <span class="sericon mt44"></span>
        <span class="sertitletext mt38">“哲社主文献”一期遴选过程</span>
      </div>
      <div class="sertxt">
        <p class="txtp">
          依据国务院学位委员会、教育部发布的《研究生教育学科专业目录(2022年)》并结合遴选实际需要设置主文献学科分类方案。“哲社主文献”一期的遴选范围是2013—2022年间公开发表的约400万篇哲学社会科学学术论文，覆盖所有一级、二级学科。<br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过大数据筛选，参考复印报刊资料和《新华文摘》等二次文献、各大学术数据库高被引论文、高等学校科学研究优秀成果奖（人文社会科学）获奖论文等，并通过专家推荐、机构调研等形式广泛征集学界意见，遴选出约14万篇初始备选文献。<br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;主文献一期建设，共邀请全国范围内1300余名专家组成各学科主文献评审组，分别进行了通讯评审、会议评审、终评审定，共遴选出2万余篇文献。
        </p>
      </div>
      <div class="sertitle">
        <span class="sericon mt44"></span>
        <span class="sertitletext mt38">入选更新</span>
      </div>
      <div class="sertxt">
        <p class="txtp">
          “哲社主文献”将根据学术发展动态，每1—3年对主文献资源进行更新、完善：（1）对2013年以前的论文追溯遴选，对2022年以后的论文逐年补选。（2）将学术著作纳入主文献遴选范围。（3）将作者、读者推荐给平台的文献纳入遴选范围。（4）已入选文献如果存在观点错误、学术不端等不宜列为主文献的情况，发现、核实后将从平台上撤销。
        </p>
      </div>
      <div class="sertitle">
        <span class="sericon mt44"></span>
        <span class="sertitletext mt38">联系方式</span>
      </div>
      <div class="sertxt pb20">
        <p class="txtp">
          欢迎广大读者对“哲社主文献”的遴选和平台功能提出意见与建议。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话：010-62519375<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;电子邮箱： zszwx@ruc.edu.cn
        </p>
      </div>
    </div>
    <footerComponent></footerComponent>
  </div>
</template>

<script>

// import headerComponent from '../../components/head/headlogin.vue'
// import footerComponent from '../../components/common/footer.vue'
export default {
  components: {
    // headerComponent,
    // footerComponent
  },
  data () {
    return {
      firstselectedTree: [],
      mianliteratureNum: '',
      seconddisciplineNum: ''
    }
  },
  mounted () {
    this.mianRightnum()
    document.title = '平台简介' + this.$route.meta.title
  },
  methods: {
    async mianRightnum () {
      const { data: res } = await this.$http({
        url: '/literatureInfoList/getLiteratureStatistics',
        method: 'post',
        data: this.firstselectedTree
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.mianliteratureNum = res.data.literatureCount // 主文献数量
      this.seconddisciplineNum = res.data.secondDisciplineCount // 二级学科数量
    },
    goSelect () {
      const routeData = this.$router.resolve({
        path: '/selectlx'
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.mt30 {
  margin-top: 30px;
}

.mt38 {
  margin-top: 38px;
}

.mt44 {
  margin-top: 44px;
}

.pb20 {
  padding-bottom: 20px;
}

.titleimg {
  width: 1920px;
  height: 300px;
  background: url('../../assets/images/home/inttitle.png') no-repeat;
  background-size: cover;
  background-position: center center;
  text-align: center;

  div {
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }

  .t1 {
    height: 66px;
  }

  .t2 {
    margin-top: 30px;
  }
}

.introduct {
  width: 100%;

  .top {
    margin-top: 71px;
  }

  .inttitle {
    text-align: center;
    margin-top: 80px;
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    letter-spacing: 15px;
  }

  .intimg {
    margin-top: 23px;
    width: 88px;
    height: 7px;
  }

  .mainlit {
    display: flex;

    .maintext {
      margin-left: 380px;
      margin-top: 111px;
      width: 415px;

      .maintitle {
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9C2526;
      }

      .maincontent {
        margin-top: 38px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        text-indent: 2em;
      }
    }

    .mainimg {
      margin-top: 33px;
      margin-left: 76px;
      width: 678px;
      height: 339px;
    }
  }

  .poslit {
    display: flex;

    .posimg {
      margin-top: 40px;
      margin-left: 240px;
      width: 270px;
      height: 257px;
    }

    .poscontent {
      margin-top: 102px;
      margin-left: 63px;
      width: 1170px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      text-indent: 2em;
    }
  }

  .content {
    margin-left: 241px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    width: 1428px;
    text-indent: 2em;
  }
}

.resources {
  margin-top: 87px;
  width: 100%;
  height: 700px;
  background: url('../../assets/images/home/12.png');
  background-size: cover;
  background-position: center center;
  padding-top: 59px;

  .inttitle {
    text-align: center;
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    letter-spacing: 15px;
  }

  .intimg {
    margin-top: 23px;
    width: 88px;
    height: 7px;
  }

  .third {
    display: flex;
    margin-bottom: 55px;

    .title1 {
      margin-left: 489px;
      margin-top: 54px;

      .icon1 {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background-color: #9C2526;
        text-align: center;

        img {
          margin-top: 60px;
          width: 81px;
          height: 81px;
        }
      }

      .text {
        text-align: center;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9C2526;
        margin-top: 28px;
      }
    }

    .title2 {
      margin-left: 170px;
      margin-top: 54px;

      .icon {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.3);
        text-align: center;

        img {
          margin-top: 60px;
          width: 81px;
          height: 81px;
        }
      }

      .text {
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9C2526;
        margin-top: 28px;
        text-align: center;
      }

    }
  }

  .texP {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    text-indent: 2em;
  }

}

.serve {
  margin-top: 98px;
  background: #F7F7F7;

  .sertitle {
    display: flex;
    margin-left: 240px;

    .sericon {
      width: 18px;
      height: 18px;
      background: #9C2526;
      border-radius: 50%;
      margin-top: 55px;
      line-height: 5px;
    }

    .sertitletext {
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #9C2526;
      margin-top: 50px;
      margin-left: 20px;
    }
  }

  table {
    border-color: #CCCCCC;
    width: 1340px;
    // height: 339px;

    margin: 0 auto;
    margin-top: 40px;

    tr {
      height: 45px;
    }

    .tabth {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #9C2526;
    }

    .tabtd1 {
      // padding-left: 49px;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }

    .tabtd2 {
      padding-left: 79px;
      padding-right: 80px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }

  .tip {
    margin-top: 10px;
    margin-left: 291px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    cursor: pointer;

    &:hover {
      color: #9C2526;
    }
  }

  .sertxt {
    margin-left: 291px;
    width: 1335px;

    .txtp {
      margin-top: 40px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      text-indent: 2em;
    }
  }
}
</style>
