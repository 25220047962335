<template>
  <div class="opinionsClose" v-if="recommendopen">
    <div class="loginBox">
      <div class="box">
        <div class="title">荐稿</div>
        <div class="closeBtnBox" @click="handleLogincancel">
          <img :src="require('../../assets/images/login/closeBtn.png')" alt />
        </div>
      </div>
      <el-form label-position="right" label-width="90px" class="myform">
        <el-row>
          <el-col :span="24">
            <el-form-item label="*真实姓名:" label-width="90px">
              <label slot="label">
                <span style="color: red;">*</span>
                真实姓名:
              </label>
              <el-input v-if='realName' disabled v-model="form.realName" placeholder="请输入真实姓名" maxlength="20" clearable>
              </el-input>
              <el-input v-else v-model="form.realName" placeholder="请输入真实姓名" maxlength="20" clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="笔名(选填):" label-width="90px">
              <el-input v-if='nickName' disabled v-model="form.nickName" placeholder="请输入笔名" maxlength="11" clearable>
              </el-input>
              <el-input v-else v-model="form.nickName" placeholder="请输入笔名" maxlength="11" clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="*文献题目:" label-width="90px">
              <label slot="label">
                <span style="color: red;">*</span>
                文献题目:
              </label>
              <el-input v-model="form.title" maxlength="100" type="textarea" :rows="1" placeholder="请输入文献题目">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="*上传附件:" label-width="90px">
              <label slot="label"><span style="color: red;">*</span> 上传附件:</label>
              <el-upload ref="upload" class="upload-demo" action="action" :limit="1" :http-request="uploadFile"
                :on-preview="handlePreview" :on-exceed="handleExceed" :on-remove="handleRemove" :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">请您上传pdf格式文档,每次推荐只能上传一篇</div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="*期刊/载体:" label-width="90px">
              <label slot="label">
                <span style="color: red;">*</span>
                期刊/载体:
              </label>
              <el-input v-model="form.journal" placeholder="请输入已发表的期刊或载体" maxlength="30" clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="*学科/领域:" label-width="90px">
              <label slot="label">
                <span style="color: red;">*</span>
                学科/领域:
              </label>
              <el-input v-model="form.subject" placeholder="请输入所属学科/领域" maxlength="30" clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="*荐稿理由:" label-width="90px">
              <label slot="label"><span style="color: red">*</span>荐稿理由:</label>
              <el-input v-model="form.problemDesc" clearable maxlength="500" type="textarea" :rows="3"
                placeholder="请输入荐稿理由">
              </el-input>

            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span class='pad'>如果您在平台上有入选的文献，请进行文献认领,我们会优先处理认领文献的作者提供的稿件。</span>
      <div slot="footer" class="dialogfooter">
        <el-button @click="handleLogincancel">取 消</el-button>
        <el-button type="primary" @click="handleSaveBtn">确 定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import clearableTextInput from '../common/ClearableTextInput.vue'
export default {
  components: {

  },
  data () {
    return {
      fileName: '',
      recommendopen: false,
      nickName: '',
      realName: '',
      form: {
        realName: '', // 真实姓名
        nickName: '', // 笔名
        title: '', // 文献题目
        journal: '', // 已发表的期刊或载体
        subject: '', // 所属学科/领域
        // email: '', // 邮箱
        problemDesc: '' // 问题描述
      },
      acceptType: ['pdf'],
      fileList: [],
      attach: '',
      file: '',
      listName: [],
      placeholder: '请输入内容'
    }
  },
  mounted () {
    this.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('token') || ''
    }
  },

  methods: {
    // 获取荐稿人信息
    async hndleRecommendInfo () {
      const res = await this.$http({
        url: '/recommend/getUser',
        method: 'POST'
      })
      this.form.realName = res.data.data.realName
      this.realName = res.data.data.realName
      if (res.data.data.pseudonym) {
        this.form.nickName = res.data.data.pseudonym
        this.nickName = res.data.data.pseudonym
      }
    },
    handleExceed () {
      this.$message.error('最多上传1个附件')
    },
    handlePreview (file) {
      const link = document.createElement('a')
      link.href = this.file
      link.setAttribute('download', file.name)
      link.click()
    },
    // 点击取消
    handleLogincancel () {
      this.recommendopen = false
      this.form = {
        realName: '', // 真实姓名
        nickName: '', // 笔名
        title: '', // 文献题目
        journal: '', // 已发表的期刊或载体
        subject: '', // 所属学科/领域
        // email: '', // 邮箱
        problemDesc: '' // 问题描述
      }
      this.fileList = []
      this.file = ''
      this.listName = []
      this.$refs.upload.uploadFiles = []
      this.attach = ''
    },
    handleRemove (file, fileList) {
      var num = this.listName.findIndex(item => item == file.name)
      this.fileList.splice(num, 1)
      this.listName.splice(num, 1)
      this.attach = ''
    },
    // 上传附件
    async uploadFile (item) {
      this.fileName = item.file.name.replace('.pdf', '')
      this.fileList = []
      // 获取文件后缀
      const fileSuffix = item.file.name.substring(item.file.name.lastIndexOf('.') + 1)
      if (this.acceptType.indexOf(fileSuffix) === -1) {
        this.$message.error('只能上传pdf文件')
        this.$refs.upload.uploadFiles.filter(item => item.name !== item.file.name)
        return
      }
      const up = item.file
      const upfile = new FormData()
      upfile.append('file', up)
      const res = await this.$http({
        url: '/literSuggestion/upload',
        method: 'POST',
        data: upfile
      })
      if (res.data.code !== 200) {
        // 清除缓存
        this.$refs.upload.uploadFiles.filter(item => item.name !== item.file.name)
        return this.$message.error(res.data.msg)
      }
      this.attach = res.data.data.attach
      // this.file = res.data.data.url + res.data.data.attach
      this.fileList.push({ name: item.file.name, url: res.data.data.attach })
      // this.listName.push(item.file.name)
      // this.$message.success(res.data.msg)
    },
    async handleSaveBtn () {
      if (this.attach === '') {
        return this.$message.error('请输入必填项')
      }
      for (const key in this.form) {
        if (this.form[key] === '' && key !== 'nickName') {
          return this.$message.error('请输入必填项')
        }
      }

      const res = await this.$http({
        url: '/recommend/addRecommend',
        method: 'POST',
        data: {
          path: this.attach, // 附件地址
          disc: this.form.subject, // 所属学科领域
          penName: this.form.nickName, // 笔名
          periodical: this.form.journal, // 已发表的期刊或载体
          realName: this.form.realName, // 真实姓名
          recommentReason: this.form.problemDesc, // 荐稿理由
          title: this.form.title, // 文献题目
          fileName: this.fileName
        }
      })
      if (res.data.code !== 200) {
        this.attach = ''
        this.fileList = []
        this.file = ''
        this.listName = []
        this.$refs.upload.uploadFiles = []
        return this.$message.error(res.data.msg)
      }
      this.$message.success('稿件提交成功,感谢您对主文献的支持!')
      this.recommendopen = false
      this.form = {
        realName: '', // 真实姓名
        nickName: '', // 笔名
        title: '', // 文献题目
        journal: '', // 已发表的期刊或载体
        subject: '', // 所属学科/领域
        // email: '', // 邮箱
        problemDesc: '' // 问题描述
      }
      this.fileList = []
      this.file = ''
      this.listName = []
      this.$refs.upload.uploadFiles = []
      this.attach = ''
    }

  },
  watch: {
    recommendopen: {
      immediate: true,
      handler (data) {
        if (data === true) {
          this.hndleRecommendInfo()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.opinionsClose {
  transition: all 0.5s;
  position: fixed;
  top: 550px;
  right: 250px;
  // bottom: 0;
  // left: 0;
  z-index: 2000;
  border-radius: 10px;
  // background: rgba(0, 0, 0, 0.2);
}

.loginBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-65%, -60%);
  width: 500px;
  background: #ffffff;
  border-radius: 4px;
  padding-top: 20px;
  z-index: 999;
  box-shadow: 0px 2px 10px 0px rgba(1, 32, 121, 0.12);
  border-radius: 10px;

  .box {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
      margin-left: 20px;
    }

    .closeBtnBox {
      display: flex;
      justify-content: flex-end;
      margin-right: 21px;
      padding-bottom: 28px;

      img {
        width: 11px;
        height: 11px;
      }

      img:hover {
        content: url('../../assets/images/login/close.png')
      }
    }
  }

  .myform {
    font-size: 20px;
    font-weight: 550;
    padding-left: 10px;
    padding-right: 50px;
    margin-top: 20px;
  }

  .dialogfooter {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
  }

}

.uploadprompt {
  color: #999999;
  font-size: 14px;
  margin-top: 10px;
  font-weight: normal;
  width: 459px;
  height: 15px;
  line-height: 15px;
}

.pad {
  display: inline-block;
  padding: 0 10px 0 10px;
}
</style>
