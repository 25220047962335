<template>
  <div class="content">
    <img src="../../assets/images/home/original.png" width="100%" alt="">
    <div class="banner">
      <div class="authorsearchBox">
        <el-input class="searchBox" maxlength='20' @keyup.enter.native="handleauthorBtn" v-model="searchMaps.easy"
          placeholder="请输入学术概念名称、提出者" clearable></el-input>
        <el-date-picker v-model="searchMaps.proposedDate" type="year" value-format="yyyy年" placeholder="年份检索"
          :picker-options="pickerOptions"></el-date-picker>
        <el-button type="primary" class="searchlistBtn" @click="handleauthorBtn">结果中搜索</el-button>
      </div>
      <div class="search">
        <span>为您查到</span>
        <span class="searchNum">{{ total }}</span>
        <span>条数据</span>
      </div>
    </div>
    <div class="banner">
      <div class="ListgroupBox" v-if="originalList && originalList.length">
        <div class="Listgroup" v-for="(item, index) in originalList" @click="handlego(item.id)" :key="index">
          <div class="itemimgbox">
            <div class="itemdiscipline">{{ item.proposedDate }}</div>
            <img :src="item.filePrefix + item.conceptCoverUrl" v-if="item.conceptCoverUrl" class="imgdis" alt />
            <img src="../../assets/images/home/xuekeimg.png" class="imgdis" v-else alt />
          </div>
          <div class="itemtexBox">
            <p class="itemtitle" :title="item.conceptName">{{ item.conceptName }}</p>
            <p class="subtitle" :title="item.conceptEnglish">{{ item.conceptEnglish }}</p>
            <p class="word">提出者：
              <span v-for="item2 in item.proposerList" class="people">{{ item2.name }}
                <span class="fenhao">、</span>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        <img src="../../assets/images/home/nodataside.png" alt />
        <span>暂无数据</span>
      </div>
      <div class="pageBox" v-if="originalList && originalList.length">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="page" :page-sizes="[12, 24, 36, 48]" :page-size="pageRow"
          layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      originalList: [],
      page: 1,
      pageRow: 12,
      total: '',
      searchMap: {
        easy: '',
        proposedDate: ''
      },
      searchMaps: {
        easy: '',
        proposedDate: ''
      },
      pickerOptions: {
        disabledDate: (time) => {
          const date = new Date().getFullYear()
          const yearEnd = time.getFullYear()
          return date < yearEnd
        }
      }
    }
  },
  mounted () {
    document.title = '中国原创学术概念' + this.$route.meta.title
    this.getoriginalList()
  },
  methods: {
    handleSizeChange (val) {
      this.page = 1
      this.pageRow = val
      this.getoriginalList()
    },
    handleCurrentChange (val) {
      this.page = val
      this.getoriginalList()
    },
    handlego (id) {
      const routeData = this.$router.resolve({
        path: '/originalDetail?id=' + id
        // query: params
      })
      window.open(routeData.href, '_blank')
    },
    async getoriginalList () {
      const { data: res } = await this.$http({
        url: '/cn_academic_concept/search',
        method: 'post',
        data: {
          page: this.page,
          pageSize: this.pageRow,
          searchMap: this.searchMap
        }
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.total = res.data.total
      this.originalList = res.data.rows
    },
    handleauthorBtn () {
      this.searchMap = JSON.parse(JSON.stringify(this.searchMaps))
      // this.searchMap.easy = this.searchMap.easy.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      this.page = 1
      this.getoriginalList()
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  min-height: 580px;

  .banner {
    width: 75%;
    margin: 0 auto;

    .authorsearchBox {
      margin-top: 17px;
      margin-bottom: 15px;

      .searchBox {
        width: 400px;
        height: 40px;
        margin-right: 10px;

        /deep/.el-input__inner {
          background: #ffffff;
          border-radius: 4px;
        }
      }

      .searchlistBtn {
        margin-left: 10px;
      }
    }

    .search {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;

      .searchNum {
        color: #9C2526;
      }
    }

    .ListgroupBox {
      display: flex;
      flex-wrap: wrap;
      width: 1468px;
      margin-top: 15px;

      .Listgroup {
        margin: 0 20px 20px 0;
        width: 22%;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding: 11px 10px 19px 10px;
        position: relative;
        cursor: pointer;

        .itemimgbox {
          .itemdiscipline {
            width: 60px;
            height: 20px;
            line-height: 20px;
            background: #ff7100;
            border-radius: 4px;
            color: #fff;
            text-align: center;
            position: absolute;
            top: 21px;
            right: 20px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }

          .imgdis {
            width: 325px;
            height: 161px;
            border-radius: 4px;
            overflow: hidden;
            object-fit: cover;
            display: block;
          }
        }

        .itemtexBox {

          .itemtitle {
            margin-top: 20px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .subtitle {
            margin-top: 10px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .word {
            margin-top: 18px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .people:last-child {
              .fenhao {
                display: none;
              }
            }
          }
        }
      }
    }

    .empty {
      margin-top: 65px;
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        width: 232px;
        height: 114px;
      }

      span {
        color: #999;
        font-size: 14px;
      }
    }

    .pageBox {
      margin-bottom: 20px;
    }
  }
}
</style>
